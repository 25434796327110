import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import * as _ from 'lodash';

import { DataService } from '../../services/other/data/data.service';
import { HtmlToolsService } from '../../services/other/html-tools/html-tools.service';
import { RetailerService } from '../../services/middleware/retailer/retailer.service';
import { APIResponse } from '../../models/api-response';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
@Component({
    selector: 'rs-edit-bill-to-dialog',
    templateUrl: './edit-bill-to-dialog.component.html',
    styleUrls: ['./edit-bill-to-dialog.component.scss']
})
export class EditBillToDialogComponent implements OnInit, OnDestroy {

    public domainData: any = (<any>window).domainData;
    public selectedRetailer: any;
    public countries: any[];
    public states: any[];
    public statusList: any[];
    public editRetailerForm: FormGroup;
    public fromPage: string;
    repgroupDivisionList: any;
    selectedRepGroupRetailer: any;
    repGroupDivision: any;
    countriesStateJSON: any;
    private readonly destroyRepgroupRetailer = new Subject<void>();
    private readonly destroyDivisionsByCompany = new Subject<void>();
    private readonly destroyZipCodeList = new Subject<void>();
    constructor(public config: DynamicDialogConfig,
        private fb: FormBuilder,
        public ref: DynamicDialogRef,
        private msgService: MessageService,
        private retailerService: RetailerService,
        private htmlTools: HtmlToolsService,
        private dataService: DataService) {
    }

    ngOnDestroy() {
        this.destroyZipCodeList.next();
        this.destroyRepgroupRetailer.next();
        this.destroyDivisionsByCompany.next();
    }

    ngOnInit() {
        this.selectedRetailer = this.config.data.selectedRetailer;
        this.fromPage = this.config.data.fromPage;
        if (this.selectedRetailer) {
            this.getRepGroupRetailer(this.selectedRetailer);
            this.destroyDivisionsByCompany.next();

            this.retailerService.getDivisionsByCompany(this.domainData.repGroup.recordID)
                .pipe(takeUntil(this.destroyDivisionsByCompany)).subscribe((t: any) => {
                    this.repgroupDivisionList = t ? t.data.map((d) => {
                        if (this.selectedRetailer.repGroupRetailers &&
                            this.selectedRetailer.repGroupRetailers[0].repGroupDivisionID === d.recordID) {
                            this.repGroupDivision = d;
                        }
                        const c: any = {};
                        c.label = d.name;
                        c.value = d;
                        return c;
                    }) : [];

                    this.editRetailerForm.patchValue({
                        repGroupDivision: this.repGroupDivision
                    });
                });
        }
        this.initForm();
        this.setFormFromObject();
    }

    getRepGroupRetailer(retailer) {
        this.destroyRepgroupRetailer.next();
        this.retailerService.getRepGroupRetailerByRepGroupAndRetailer(retailer.recordID, this.domainData.repGroup.recordID)
            .pipe(takeUntil(this.destroyRepgroupRetailer)).subscribe((data: any) => {
                this.selectedRepGroupRetailer = data;
            });
    }

    initForm() {
        this.editRetailerForm = this.fb.group({
            name: ['', Validators.required],
            address1: [''],
            address2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', Validators.required],
            country: [environment.countryAbrv],
            phone: ['', Validators.required],
            phoneExtension: [''],
            fax: ['', Validators.pattern('^[0-9]*$')],
            faxExtension: [''],
            retailerStatus: ['ACTIVE', Validators.required],
            website: ['', [Validators.pattern
                ('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$')]],
            repGroupDivision: [this.repGroupDivision ? this.repGroupDivision : ''],
            federalTaxNumber: ['', [Validators.maxLength(12)]],
            salesTaxNumber: ['', [Validators.maxLength(20)]],
            stateTaxNumber: ['', [Validators.maxLength(20)]],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]

        });
        this.dataService.getCountryList().subscribe((data: any[]) => {
            this.countries = data;
        });
        // this.dataService.getStateList().subscribe((data: any[]) => {
        //     this.states = data;
        // });
        this.dataService.setCountriesState().then(res => {
            this.countriesStateJSON = res;
        });
        this.statusList = [
            {
                label: 'Active',
                value: 'ACTIVE'
            },
            {
                label: 'Lead',
                value: 'LEAD'
            },
            {
                label: 'Inactive',
                value: 'INACTIVE'
            }];
    }

    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
        if (field.errors.minlength && field.errors.minlength.actualLength) {
            return `${name} must be minimum of ${field.errors.minlength.requiredLength} length`;
        }
        if (field.errors.maxlength && field.errors.maxlength.actualLength) {
            return `${name} must be maximum of ${field.errors.maxlength.requiredLength} length`;
        }
    }
    setValidatorOnCountry(formGroup: any) {
        if (formGroup.get('country').value === 'US') {
            formGroup.get('zip').setValidators([Validators.required, Validators.maxLength(5)]);
            formGroup.get('state').setValidators([Validators.required, Validators.maxLength(50)]);
            formGroup.get('zip').updateValueAndValidity();
            formGroup.get('state').updateValueAndValidity();
        } else {
            formGroup.get('zip').setValidators([Validators.maxLength(20)]);
            formGroup.get('state').clearValidators();
            formGroup.get('zip').updateValueAndValidity();
            formGroup.get('state').updateValueAndValidity();
        }
    }
    countrySpecific() {
        // if (this.validateChk.country.value === 'US') {
        //     this.editRetailerForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
        //     this.editRetailerForm.get('zip').updateValueAndValidity();
        // } else {
        //     this.validateChk.zip.setValue('');
        //     this.validateChk.city.setValue('');
        //     this.validateChk.state.setValue('');
        //     this.editRetailerForm.get('zip').clearValidators();
        //     this.editRetailerForm.get('zip').updateValueAndValidity();
        // }
        this.setValidatorOnCountry(this.editRetailerForm);
        this.updateChange();
    }
    // RC-588 (SG 25 MAR 2020): set state and city value
    updateChange() {
        const zipcode = this.validateChk.zip.value.trim();
        if (zipcode.length >= 5) {

            const searchParams: any = {};
            let size = 3000;
            let offset = 0;
            let sort = 'country';
            let sortOrder = 1;
            searchParams.country = this.validateChk.country.value;
            searchParams.zipCode = this.validateChk.zip.value.trim();
            this.destroyZipCodeList.next();
            this.dataService.getZipCodeList(offset, size, sort, sortOrder, searchParams)
                .pipe(takeUntil(this.destroyZipCodeList)).subscribe((res: APIResponse) => {
                    if (res.success) {
                        if (res.data[0]) {
                            this.validateChk.state.setValue(res.data[0].stateAbbreviation);
                            this.validateChk.city.setValue(res.data[0].city);
                        } else {
                            this.validateChk.state.setValue('');
                            this.validateChk.city.setValue('');
                        }
                    } else {
                        this.validateChk.state.setValue('');
                        this.validateChk.city.setValue('');
                    }
                }, () => {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
                });
        }
    }
    setFormFromObject() {
        this.editRetailerForm.reset();
        this.editRetailerForm.patchValue(this.selectedRetailer);
        if (!this.selectedRetailer.retailerStatus) {
            this.editRetailerForm.patchValue({ retailerStatus: 'ACTIVE' });
        }
        if (!this.selectedRetailer.recordID) {
            this.editRetailerForm.patchValue({ country: environment.countryAbrv });
        }
        // if (this.validateChk.country.value === 'US') {
        //     this.editRetailerForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
        //     this.editRetailerForm.get('zip').updateValueAndValidity();
        // } else {
        //     this.editRetailerForm.get('zip').clearValidators();
        //     this.editRetailerForm.get('zip').updateValueAndValidity();
        // }
        this.setValidatorOnCountry(this.editRetailerForm);
    }

    get validateChk() {
        // Convenience getter for easy access to form fields
        return this.editRetailerForm.controls;
    }

    saveRetailerBillToLocation() {
        this.retailerService.saveRetailer(this.selectedRetailer)
            .subscribe((data: any) => {
                if (data) {
                    this.createRepgroupRetailerObject(this.selectedRetailer);
                    this.saveRepGroupRetailer(this.selectedRetailer);
                    const updateData: any = {
                        isOrderOnly: false,
                        data
                    };
                    this.ref.close(updateData);
                } else {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error saving bill to location' });
                }
            });
    }

    saveOnlyForOrder() {
        if (this.editRetailerForm.valid) {
            this.setObjectFromForm();
            const updateData: any = {
                isOrderOnly: true,
                data: this.editRetailerForm.getRawValue()
            };
            this.ref.close(updateData);
        } else {
            this.htmlTools.validateAllFields(this.editRetailerForm);
        }
    }

    createRepgroupRetailerObject(retailer) {
        this.selectedRepGroupRetailer.retailerID = retailer.recordID;
        this.selectedRepGroupRetailer.name = retailer.name;
        this.selectedRepGroupRetailer.repGroupDivision = retailer.repGroupDivision;
        this.selectedRepGroupRetailer.active = true;
        this.selectedRepGroupRetailer.retailerStatus = this.selectedRetailer.retailerStatus;
    }

    private saveRepGroupRetailer(selectedRetailer) {
        this.retailerService.saveRepGroupRetailer(this.selectedRepGroupRetailer,
            selectedRetailer.recordID, this.domainData.repGroup.recordID)
            .subscribe((data: any) => {
                console.log('daadddadadadda');

                this.selectedRepGroupRetailer.recordID = data.recordID;
                this.msgService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Customer Info Saved Successfully'
                });
            });
    }

    saveRetailerInfo() {
        if (this.editRetailerForm.valid) {
            this.setObjectFromForm();
            this.saveRetailerBillToLocation();
        } else {
            this.htmlTools.validateAllFields(this.editRetailerForm);
        }
    }

    private setObjectFromForm() {
        _.forEach(this.editRetailerForm.controls, (key, value) => {
            this.selectedRetailer[value] = this.editRetailerForm.controls[value].value;
        });
    }

}
