<div class="lightovation-footer-main">
    <footer style="margin: 0px;">
        <div class="lightovation-footer-first">
            <div class="small-layout">
                <div class="lightovation-footer-first-inner d-flex">
                    <div class="f-light-logo">
                        <img class="logo" [src]="logo" alt="{{domainData.domainName}}"
                            onError="this.src='https://via.placeholder.com/125x50?text=NO LOGO';" />
                    </div>
                    <div class="lightovation-contact-info d-flex">
                        <div class="lightovation-contact-info-block d-flex" *ngIf="footerData?.phone">
                            <div class="contact-info-icon">
                                <img src="/assets/images/lightovation/phone-call.svg" alt="lightovation logo" />
                            </div>
                            <a href="tel:{{footerData?.phone | phone}}" class="contact-info-text">
                                <span>Customer Service</span>
                                {{footerData?.phone | phone}}
                            </a>
                        </div>
                        <div class="lightovation-contact-info-block d-flex" *ngIf="footerData?.fax">
                            <div class="contact-info-icon">
                                <img src="/assets/images/lightovation/fax.svg" alt="lightovation logo" />
                            </div>
                            <a [href]="sanitize(footerData?.fax | phone)" class="contact-info-text">
                                <span>Fax Us</span>
                                {{footerData?.fax | phone}}
                            </a>
                        </div>
                        <div class="lightovation-contact-info-block d-flex" *ngIf="footerData?.email">
                            <div class="contact-info-icon">
                                <img src="/assets/images/lightovation/l-mail-icons.svg" alt="lightovation logo" />
                            </div>
                            <a href="mailto:{{footerData?.email}}" class="contact-info-text">
                                <span>Email Us</span>
                                {{footerData?.email}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lightovation-footer-sec">
            <div class="small-layout">
                <div class="lightovation-footer-sec-inner d-flex justify-content-between">
                    <p class="d-flex">Copyright 2021 powered by &nbsp; <a href="https://markettime.com" target="_blank">
                            <b>MARKETTIME LLC. </b></a>All Rights Reserved</p>
                    <a href="https://markettime.com/privacy-policy/privacy" target="_blank">Terms & Conditions</a>
                </div>
            </div>
        </div>
    </footer>
</div>