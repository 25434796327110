<form [formGroup]="searchForm" (submit)="onSubmit();$event.stopPropagation();$event.stopImmediatePropagation();">
    <div class="input-group position-relative search-icon search-area" #attachHere>
        <i class="pi pi-search"></i>
        <i class="pi pi-times search-reset-icon" *ngIf="searchActive" pTooltip="Reset"
           (click)="onReset()"></i>
        <input type="text" class="form-control search-input" formControlName="searchText" id="adv-search-input" #searchInput
               onfocus="this.select()"
               [placeholder]=placeHolderSearchBar>
        <button class="advance-btn" type="button" *ngIf="hideAdavanceBtn"
                iconPos="right"
                [icon]="'pi pi-fw pi-chevron-down'"
                pButton label="Advanced&nbsp;&nbsp;&nbsp;"
                
                (click)="$event.stopPropagation();$event.stopImmediatePropagation();op.toggle($event);"></button>
    </div>
    <p-overlayPanel #op styleClass="advanced-search-overlay-panel" [dismissable]="false" showCloseIcon="true" [appendTo]="attachHere">
        <div class="advance-search-container p-grid"
             [ngClass]="{'extra-large':isManufactureStatusActive||isRepGroupStatusActive}">
            <div class="p-col-12" *ngIf="isSearchByActive">
                <label for="searchBy">Search by</label>
                <p-dropdown id="searchBy" 
                            [style]="{'width':'100%'}"
                            class="control-dropdown"
                            [options]="advanceOptions"
                            formControlName="searchBy"></p-dropdown>
            </div>
            <div class="p-col-12" *ngIf="isStatusActive">
                <label for="active">Status</label>
                <p-dropdown id="active"
                            [style]="{'width':'100%'}"
                            class="control-dropdown" [options]="statusList"
                            formControlName="active"></p-dropdown>
            </div>
            <!--TODO: Active this after territory wizard done-->
            <!-- <div class="p-col-12" *ngIf="isTerritoriesList">
                 <label for="territoriesIds">Select Territories</label>
                 <p-multiSelect [filter]="false" id="territoriesIds" maxSelectedLabels="2"
                                class="control d-block" [options]="territoriesList"
                                formControlName="territoriesIds">
                     <ng-template let-car let-i="index" pTemplate="item">
                         <span class="mx-5">{{car.label}}</span>
                     </ng-template>
                 </p-multiSelect>
             </div>-->
            <div class="p-col-12" *ngIf="isSalespersonTypeList">
                <label for="salespersonType">Salesperson Type</label>
                <p-dropdown id="salespersonType" [style]="{'width':'100%'}"
                            class="control-dropdown" [options]="salespersonTypeList"
                            formControlName="salespersonType"></p-dropdown>
            </div>

            <div class="p-col-12" *ngIf="isAvailable">
                <label for="isAvailable">Available</label>
                <p-dropdown id="isAvailable"
                            [style]="{'width':'100%'}"
                            class="control-dropdown" [options]="availableList"
                            formControlName="isAvailable"></p-dropdown>
            </div>

            <div class="p-col-12" *ngIf="dateRangeLabel">
                <label for="dateRange">{{dateRangeLabel ? dateRangeLabel : 'Date Range' }}</label>
                <p-calendar id="dateRange" styleClass="control" [style]="{'width':'100%'}" formControlName="dateRange"
                            dateFormat="{{env.rtDateCalender}}" selectionMode="range" showOnFocus="true" [showIcon]="true"
                            placeholder="{{dateRangeLabel ? dateRangeLabel : 'Date Range' }}"></p-calendar>
            </div>
            <div class="p-col-6" *ngIf="isRepGroupStatusActive">
                <label>RepGroup Status</label>
                <rs-order-status-dropdown class="bordered"
                                          [orderStatuses]="repGroupOrderStatusList()"
                                          formControlName="repGroupOrderStatus">
                </rs-order-status-dropdown>
            </div>
            <div [ngClass]="{'p-col-12':!isRepGroupStatusActive,'p-col-6':isRepGroupStatusActive}"
                 *ngIf="isManufactureStatusActive">
                <label>Manufacturer Status</label>
                <rs-order-status-dropdown class="bordered"
                                          [orderStatuses]="manufacturerOrderStatusList()"
                                          formControlName="manufacturerOrderStatus">
                </rs-order-status-dropdown>
            </div>
            <div class="p-col-12">
                <label for="searchType">Search Type</label>
                <p-dropdown id="searchType"
                            [style]="{'width':'100%'}"
                            class="control-dropdown"
                            [options]="typeList"
                            formControlName="searchType"></p-dropdown>
            </div>
            <div class="p-col-12" *ngIf="isAssignList">
                <label for="assignType">Assign Status</label>
                <p-dropdown id="assignType"
                            [style]="{'width':'100%'}"
                            class="control-dropdown"
                            [options]="assignList"
                            formControlName="assign"></p-dropdown>
            </div>
            <div class="p-col-12">
                <div class="btn-group float-right">
                    <button class="ui-button-success btn-theme-color"
                            [icon]="isResetLoading?'pi pi-spin pi-spinner':'fa fa-refresh'"
                            [disabled]="isSubmitLoading|| isResetLoading || isIframeWindow"
                            label="Reset" pButton type="button" (click)="!isIframeWindow ? onReset() : ''"></button>
                    <button type="submit" class="ui-button-info theme-maroon-btn"
                            label="Search"
                            [icon]="isSubmitLoading?'pi pi-spin pi-spinner':'fa fa-search'"
                            [disabled]="isSubmitLoading|| isResetLoading || isIframeWindow"
                            pButton title="Search"></button>
                </div>
            </div>
        </div>
    </p-overlayPanel>
</form>
