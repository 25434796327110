import { Retailer } from './../../models/retailer';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationService, DynamicDialogConfig, MessageService} from 'primeng/api';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import {APIResponse} from '../../models/api-response';
import {BasketItemService} from '../../services/middleware/basket-item/basket-item.service';
import {ItemsAPIService} from '../../services/api/items-api/items-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RTSharedService } from '../../services/rtshared/rtshared.service';

@Component({
    selector: 'rs-items-list-modal',
    templateUrl: './items-list-modal.component.html',
    styleUrls: ['./items-list-modal.component.scss']
})
export class ItemsListModalComponent implements OnInit, OnDestroy {

    public items: any;
    public cols: any[];
    public totalPOs: any;
    public totalItems: any;
    loadingTableData: boolean;
    private readonly destroyItemHistory = new Subject<void>();

    constructor(private msgService: MessageService,
                private confirmationService: ConfirmationService,
                public config: DynamicDialogConfig,
                public itemsAPIService: ItemsAPIService,
                public basketItemService: BasketItemService,
                public rtSharedService: RTSharedService) {
    }

    // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    // RC-343 (MG 06-FEB-2020): removed item summary fn call when not meeded
    ngOnInit() {
        if (!this.config.data.itemsHistory) {
            this.items = this.config.data.items.map((o: any) => {
                o.minimumQuantity = o.item.minimumQuantity;
                o.itemNumber = o.item.itemNumber;
                o.name = o.item.name;
                o.category = o.item.category;
                o.itemPriceCodes = o.item.itemPriceCodes;
                _.forEach(o.itemPriceCodes, (priceCodeObj) => {
                    priceCodeObj.label = priceCodeObj.manufacturerPriceCode ? `${priceCodeObj.manufacturerPriceCode} (${priceCodeObj.priceCode})` : priceCodeObj.priceCode;
                });
                o.upc1 = o.item.upc1;
                o.itemDiscount = o.itemDiscount ? o.itemDiscount : 0;
                o.selectedPriceCode = o.priceCode ? o.priceCode : '';
                return o;
            });
            this.cols = [
                {field: 'itemNumber', header: 'Item #', width: '86px', spinner: true, sortField: ''},
                {field: 'name', header: 'Name', width: 'auto', spinner: true, sortField: ''},
                {field: 'upc1', header: 'UPC', width: '125px', spinner: true, sortField: ''},
                {field: 'category', header: 'Category', width: 'auto', spinner: true, sortField: ''},
                {field: 'minimumQuantity', header: 'Min. Qty', width: '120px', spinner: true, sortField: ''},
                {field: 'unitPrice', header: 'Unit Price($)', width: '150px', spinner: true, sortField: ''},
                // {field: 'itemDiscount', header: 'Disc.', width: '100px', spinner: false, sortField: ''},
                {field: 'itemPriceCodes', header: 'Price Code', width: '110px', spinner: false, sortField: ''},
                {field: 'quantity', header: 'Qty.', width: '100px', spinner: false, sortField: ''},
                {field: 'itemTotal', header: 'Total', width: '100px', spinner: false, sortField: ''}
            ];
            this.updateSummary();
        } else {
            this.cols = [
                {field: 'itemNumber', header: 'Item Number', width: '120px', spinner: true, sortField: ''},
                {field: 'size', header: 'Size', width: '90px', spinner: true, sortField: ''},
                {field: 'color', header: 'Color', width: '90px', spinner: true, sortField: ''},
                {field: 'style', header: 'Style', width: '90px', spinner: true, sortField: ''},
                {field: 'qtySold', header: 'QTY Ordered', width: '120px', spinner: true, sortField: ''},
                {field: 'orderedDate', header: 'Last Order Date', width: '135px', spinner: true, sortField: ''},
                {field: 'itemDescription', header: 'Description', width: '200px', spinner: true, sortField: ''},
                {field: 'unitPrice', header: 'Unit Price', width: '150px', spinner: true, sortField: ''},
                {field: 'purchaseOrderNumber', header: 'PO#', width: '160px', spinner: false, sortField: ''},
            ];
            this.loadingTableData = true;
            let ISCPayload = {};
            if (this.config.data.selectedISC) {
                ISCPayload = {
                    size: this.config.data.selectedISC.size,
                    style: this.config.data.selectedISC.style,
                    color: this.config.data.selectedISC.color,
                };
            }
            const retailerID = this.config.data.retailer.retailerID ? this.config.data.retailer.retailerID :
            this.config.data.retailer.recordID;

            const repGroupID = this.rtSharedService.getRepGroupID();

            this.destroyItemHistory.next();

            this.itemsAPIService.getItemsHistory(this.config.data.stringParam, retailerID, repGroupID,
                this.config.data.selectedItem.manufacturerID, this.config.data.selectedItem.itemNumber,
                this.config.data.entityId, ISCPayload).pipe(takeUntil(this.destroyItemHistory)).subscribe((response: APIResponse) => {
                    // this.updateSummary();
                    if (response.success) {
                        this.items = response.data;
                        this.totalItems = _.sumBy(this.items, 'qtySold');
                        this.totalPOs = this.items.length;
                        this.loadingTableData = false;
                    } else {
                        this.loadingTableData = false;
                        this.msgService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                        });
                    }
            }, error => {
                // this.updateSummary();
                this.loadingTableData = false;
                console.log('ERROR:', error);
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error occurred while updating table'
                });
            });
        }
    }

    ngOnDestroy() {
        this.destroyItemHistory.next();
    }
    // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    async saveItemToCart(itemObj, rowIndex) {
        this.updateSummary();
        this.items[rowIndex].selectedPriceCode = itemObj.selectedPriceCode;
        this.config.data.items[rowIndex].selectedPriceCode = itemObj.selectedPriceCode;
        const resp = await this.basketItemService.saveItemToBasket(itemObj, this.config.data.retailer);
        this.msgService.clear();
        this.msgService.add({severity: 'info', summary: 'Item Saved', detail: `${itemObj.itemNumber} was saved successfully.`});
    }

    deleteItemFromCart(itemObj: any) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this item?',
            header: 'Delete Cart Item',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                await this.basketItemService.deleteBasketItemItem([itemObj.recordID]);
                this.items = _.remove(this.items, (o: any) => o.recordID !== itemObj.recordID);
            },
            reject: () => {
            }
        });
    }

    // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    updateSummary() {
        this.config.data.total = 0;
        this.config.data.items.forEach((item, index) => {
            const itemDiscObj = (item.selectedPriceCode !== null && item.selectedPriceCode !== undefined) ?
                item.itemPriceCodes.find(o => o.priceCode === item.selectedPriceCode) : null;
            const unitPrice = (itemDiscObj !== null && itemDiscObj !== undefined) ? itemDiscObj.unitPrice : item.item.unitPrice;
            const quantity = item.quantity;
            const itemGrossTotal = +unitPrice * +quantity;
            this.items[index].discountedTotal = itemGrossTotal;
            this.config.data.total += +itemGrossTotal;
        });
    }

    // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    getUnitPrice(item) {
        let unitPrice = (item.selectedPriceCode !== null && item.selectedPriceCode !== undefined) ?
            item.itemPriceCodes.find(o => o.priceCode === item.selectedPriceCode) : null;
        unitPrice = (unitPrice !== null && unitPrice !== undefined) ? unitPrice.unitPrice : item.item.unitPrice;
        return unitPrice;
    }

}
