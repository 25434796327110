import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import { RTSharedService } from '../../rtshared/rtshared.service';
import { RetailerShipToLocation } from '../../../models/retailer-ship-to-location';
import { RetailerPaymentInfo } from '../../../models/retailer-payment-info';
import { RepGroupSalespersonRetailer } from '../../../models/rep-group-salesperson-retailer';
import { AuthService } from '../../middleware/auth/auth.service';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RetailerAPIService {
    public baseUrl = environment.apiUrl;
    autoassignedRetailerSalespersonURL = this.baseUrl + 'reptime/api/autoassignedRetailerSalesperson/{repGroupID}/{retailerID}/{shipToID}';
    constructor(
        private http: HttpClient,
        public auth: AuthService,
        private rtSharedService: RTSharedService
    ) {
    }

    async getRetailer(retailerID: string): Promise<any> {
        console.log('getRetailer');
        const url = `${environment.apiUrl}reptime/api/retailer/${retailerID}`;
        return await this.http.get<any>(url).toPromise();
    }

    // B2B-52 (MG 31-JAN-2020): modified to filter MFR's promotions
    async getManufacturersPromotions(retailerID: string, companyID: string): Promise<any> {
        const payload: any = {};
        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
            payload.retailerShipToLocationID = null;
            if (JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID) {
                payload.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
            }
        }
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/manufacturers/showmanufacturerspromotions/${retailerID}/${companyID}`;
        if (payload && !_.isEmpty(payload)) {
            const params: any = new URLSearchParams();
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) {
                    payload[key] = encodeURIComponent(payload[key]);
                    params.set(key, payload[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return await this.http.get<any>(url).toPromise();
    }

    async getRetailerAssignmentsByRetailerID(searchParams?: any): Promise<any> {
        const url = `${environment.apiUrl}reptime/api/retailer/assignments`;
        return await this.http.post<any>(url, searchParams).toPromise();
    }

    getRetailerQickReport(retailer: any, repGroupID: any) {
        const url = `${environment.apiUrl}reptime/api/${repGroupID}/retailer/getRetailerDetails`;
        return this.http.post<any>(url, retailer);
    }

    saveRetailerShipTo(retailerID: any, selectedShipToLocation: any) {
        const url = `${environment.apiUrl}reptime/api/retailer/${retailerID}/retailerShipToLocation/save`;
        return this.http.post(url, selectedShipToLocation);
    }

    saveRetailer(retailer: any) {
        const url = `${environment.apiUrl}reptime/api/retailer/save`;
        return this.http.post<any>(url, retailer);
    }

    saveRepGroupRetailer(repGroupRetailer, retailerID: any, repGroupID: any) {
        const url = `${environment.apiUrl}reptime/api/retailer/repgroup/${repGroupID}/${retailerID}/save`;
        return this.http.post<any>(url, repGroupRetailer);
    }

    getDivisionsByCompany(repGroupID: string) {
        const url = `${environment.apiUrl}reptime/api/${repGroupID}/divisions`;
        return this.http.get(url);
    }

    getRepGroupRetailerByRepGroupAndRetailer(retailerID: string, repGroupID: string) {
        const url = `${environment.apiUrl}reptime/api/retailer/${retailerID}/${repGroupID}`;
        return this.http.get(url);
    }

    getRetailerByRepGroup(queryStringParam?: any, apiPayload?: any, mode?: string) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/search`;
        const entityType = this.rtSharedService.getEntityType();
        const payload = _.cloneDeep(apiPayload);
        if (entityType === 'Salesperson' && mode === 'road') {
            const salesPersonID = this.rtSharedService.getSalesPersonID();
            payload.salespersonID = salesPersonID;
            payload.roadMode = true;
        }
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.post<any>(url, payload);
    }

    getRetailerShipToByRetailerID(retailerID: string, payload?: any) {

        const searchTypeParam = objectPath.get(payload, 'searchType', 'startswith');
        const params = new HttpParams()
            .append('searchType', searchTypeParam);

        const url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/${retailerID}/shiptos`;

        // return this.http.post<any>(url, payload);
        return this.http.post(url, payload, { params });
    }

    getRetailerContactsByRetailerID(retailerID: string, payload?: any) {
        const searchTypeParam = objectPath.get(payload, 'searchType', 'startswith');
        const params = new HttpParams()
            .append('searchType', searchTypeParam);

        const url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/${retailerID}/contacts`;
        return this.http.post<any>(url, payload, { params });
    }

    saveRetailerContact(selectedContact: any, retailerID: string) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/${retailerID}/contacts/save`;
        return this.http.post(url, selectedContact);
    }

    getPaymentTermsForRetailer(retailerID: string) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const entityType = this.rtSharedService.getEntityType();
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/paymentinfo/${repGroupID}/${retailerID}`;
        if (entityType === 'Salesperson') {
            const salesPersonID = this.rtSharedService.getSalesPersonID();
            url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/paymentinfo/${repGroupID}/${salesPersonID}/${retailerID}`;
        }
        return this.http.get(url);
    }

    saveRetailerPaymentInfo(repGroupID: string, retailerID: string, retailerPaymentInfo: RetailerPaymentInfo) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/${retailerID}/${repGroupID}`;
        return this.http.post(url, retailerPaymentInfo);
    }

    saveRetailerPaymentInfoOrderSetting(repGroupID: string, retailerID: string, retailerPaymentInfo: RetailerPaymentInfo) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/paymentinfo/${repGroupID}/${retailerID}/save`;
        return this.http.post(url, retailerPaymentInfo);
    }

    getRepGroupRetailerByIdNameAndEmail(search, repGroupID: string) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/retailer/repgroup/${repGroupID}/all?searchBy=${search}`;
        return this.http.get(url);
    }

    saveAssignmentList(selectedAssignment: RepGroupSalespersonRetailer[]) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/salespersonretailerlink/repGroup/${repGroupID}/save`;
        return this.http.post(url, selectedAssignment).toPromise();
    }


    autoAssigned(repGroupID: string, retailerID: string, shipToID: number) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/autoassignedsalesperson/${repGroupID}/${retailerID}/${shipToID}`;
        return this.http.get<any>(url);
    }

    getOrderSummary(retailerID) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/orderSummary/${retailerID}`;
        const payload = { repGroupID: this.rtSharedService.getRepGroupID() };
        return this.http.post<any>(url, payload).toPromise();
    }

    autoassignedRetailerSalesperson(repGroupID: string, retailerID: string, shipToID: number) {
        return this.http.get(this.addTokens(this.autoassignedRetailerSalespersonURL, { repGroupID, retailerID, shipToID }));
    }

    addTokens(url: string, params) {
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }

    getPaymentTermsForRetailerCard(paymentID: string) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/mtPay/${paymentID}/getRetailerPaymentInfo`;
        return this.http.get(url);
    }
}
