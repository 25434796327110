import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfirmationService, DialogService, MessageService } from 'primeng/api';
import { OrderService } from '../../../services/middleware/order/order.service';
import { OrderDetail } from '../../../models/order-detail';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BrowseItemsDialogComponent } from '../../../modals/browse-items-dialog/browse-items-dialog.component';
import { OrderDetailNoteDialogComponent } from '../../../modals/order-detail-note-dialog/order-detail-note-dialog.component';
import { RTSharedService } from '../../../services/rtshared/rtshared.service';
import { ItemsAPIService } from 'src/app/services/api/items-api/items-api.service';
import { environment } from '../../../../environments/environment';
import { RtDatePipe } from '../../../pipes/rt-date.pipe';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { RetailerService } from '../../../services/middleware/retailer/retailer.service';
@Component({
    selector: 'rs-order-detail-table',
    templateUrl: './order-detail-table.component.html',
    styleUrls: ['./order-detail-table.component.scss']
})
export class OrderDetailTableComponent implements OnInit, OnDestroy {

    @Input() items: any[];
    @Output() itemsChange = new EventEmitter();
    @Output() itemsUpdatedObj = new EventEmitter();
    @Input() order: any;
    @Input() disabled: boolean;
    @Input() editableItemsDetails: boolean;
    @Output() getOrder = new EventEmitter();
    selectedTableItemArray: any = [];
    focuseItemMfgObj: any = [];
    addItemSubscription: Subscription;
    projectName: string;
    public logoBucketUrl = environment.logoBucketUrl;
    logoPath = this.logoBucketUrl + 'logo/{companyID}.png';
    oldQty = 0;
    constructor(
        private msgService: MessageService,
        private confirmationService: ConfirmationService,
        private rtSharedService: RTSharedService,
        public dialogService: DialogService,
        private orderService: OrderService,
        private itemsAPIService: ItemsAPIService,
        private retailerService: RetailerService,
        public dp: DatePipe) {
        this.projectName = this.rtSharedService.getProjectName();
        this.addItemSubscription = this.orderService.getAddOrderItemSub()
            .subscribe(async (eventObj) => {
                if (eventObj && eventObj.orderID === this.order.recordID && eventObj.itemObj && eventObj.itemObj.recordID) {
                    const index = _.findIndex(this.items, (o) => eventObj.itemObj.recordID === o.recordID);
                    if (index === -1) {
                        this.items.push(this.getObjForTable(eventObj.itemObj));
                    } else {
                        this.items[index] = this.getObjForTable(eventObj.itemObj);
                    }
                    this.setOrderDetailColumns();
                    this.getOrder.emit({ items: this.items, isDelete: eventObj.isDelete ? eventObj.isDelete : false });
                    this.itemsChange.emit(this.items);
                    this.itemsUpdatedObj.emit({ itemsUpdatedObj: eventObj.itemObj});
                }
            });
    }

    private async setOrderDetailColumns() {
        this.order.itemTable = {
            showSizeColumn: false,
            showColorColumn: false,
            showStyleColumn: false
        };
        this.items.some((d: OrderDetail) => {
            if (!d.recordDeleted) {
                if (d.size) {
                    this.order.itemTable.showSizeColumn = true;
                }
                if (d.color) {
                    this.order.itemTable.showColorColumn = true;
                }
                if (d.style) {
                    this.order.itemTable.showStyleColumn = true;
                }
                if (this.order.itemTable.showSizeColumn && this.order.itemTable.showColorColumn && this.order.itemTable.showStyleColumn) {
                    return true;
                }
            } else {
                return false;
            }
        });
        this.order.itemTable.orderDetailCols = [
            { field: 'picturePath', header: 'Image', width: '80px', spinner: false, sortField: '' },
            { field: 'itemNumber', header: 'Item #', width: '80px', spinner: false, sortField: '' },
            { field: 'name', header: 'Name', width: '100px', spinner: false, sortField: '' },
            { field: 'description', header: 'Description', width: '150px', spinner: false, sortField: '' }];
        // {field: 'productCode', header: 'UPC', width: 'auto', spinner: true, sortField: 'productCode'}];
        if (this.order.itemTable.showSizeColumn) {
            this.order.itemTable.orderDetailCols.push({
                field: 'size',
                header: 'Size',
                width: '60px',
                spinner: true,
                sortField: ''
            });
        }
        if (this.order.itemTable.showColorColumn) {
            this.order.itemTable.orderDetailCols.push({
                field: 'color',
                header: 'Color',
                width: '60px',
                spinner: true,
                sortField: ''
            });
        }
        if (this.order.itemTable.showStyleColumn) {
            this.order.itemTable.orderDetailCols.push({
                field: 'style',
                header: 'Style',
                width: '60px',
                spinner: true,
                sortField: ''
            });
        }
        this.order.itemTable.orderDetailCols.push({
            field: 'unitPrice',
            header: 'Unit Price($)',
            width: '125px',
            spinner: false,
            sortField: '',
            class: 'price'
        });
        // this.order.itemTable.orderDetailCols.push({
        //     field: 'pack',
        //     header: 'Pack',
        //     width: 'auto',
        //     spinner: true,
        //     sortField: 'pack'
        // });
        if (this.projectName !== 'B2B') {
            this.order.itemTable.orderDetailCols.push({
                field: 'itemDiscount',
                header: 'Disc.',
                width: '80px',
                spinner: false,
                sortField: ''
            });
        }
        if (this.projectName !== 'B2B') {
            this.order.itemTable.orderDetailCols.push({
                field: 'priceCodes',
                header: 'Price Code',
                width: '80px',
                spinner: false,
                sortField: ''
            });
        }
        this.order.itemTable.orderDetailCols.push({
            field: 'quantity',
            header: 'Qty.',
            width: '100px',
            spinner: false,
            sortField: ''
        });
        this.order.itemTable.orderDetailCols.push({
            field: 'total',
            header: 'Total',
            width: '80px',
            spinner: false,
            sortField: '',
            class: 'price'
        });
        // this.order.itemTable.orderDetailCols.push({
        //     field: 'requestDate',
        //     header: 'Ship Date',
        //     width: 'auto',
        //     spinner: true,
        //     sortField: 'requestDate'
        // });
    }


    ngOnInit() {
        console.log(this.order);
        this.items = this.items.map((o: any) => {
            return this.getObjForTable(o);
        });
        this.setOrderDetailColumns();
    }

    getObjForTable(o: any) {
        o.itemDiscount = o.itemDiscount ? o.itemDiscount : 0;
        o.total = ((o.quantity * o.unitPrice) - ((o.quantity * o.unitPrice) * (o.itemDiscount / 100)));
        o.picturePath = o.picturePath;
        o.fullPicturePath = this.itemsAPIService.getFullPicturePath(o.picturePath, this.order.manufacturer.recordID);
        o.fullPicturePath64PX = this.itemsAPIService.getFullPicturePath(o.picturePath, this.order.manufacturer.recordID, 64);
        const rand = Math.floor(Math.random() * 20) + 1;
        o.profilePic = this.getLogoPath(this.order.manufacturer.recordID) + '?time=' + rand + 5;
        o.imageError = false;
        o.imageFullError = false;
        console.log('o.profilePic>>>>>>>>>>>', o.profilePic);
        // o.selectedPriceCode = o.priceCode ? o.priceCode : '';
        return o;
    }

    onclickSaveOldQty(itemObj: any) {
        this.oldQty = 0;
        this.oldQty = itemObj.quantity;
    }

    async setQuantity(detailObj, index) {
        const nearset = Math.round(detailObj.quantity / detailObj.quantityIncrement) * detailObj.quantityIncrement;
        if (detailObj.quantity > 0 && detailObj.quantity < detailObj.minimumQuantity) {
            detailObj.quantity = detailObj.minimumQuantity;
            this.updateOrderDetail(detailObj, index);
        } else if (detailObj.quantity > 0 &&
            nearset.toString() !== detailObj.quantity.toString() &&
            detailObj.quantity.toString() !== detailObj.minimumQuantity.toString()) {
            // <br>Click 'Ignore' to set quantity as ${detailObj.quantity}.
            setTimeout(() => {
                if (document.getElementById('YesButton')) {
                    document.getElementById('YesButton').focus();
                }
            }, 0);
            this.confirmationService.confirm({
                message: `This process will break the manufacturer set increment quantity for this item.
                    <br><br>Click 'Yes' to accept quantity of ${nearset}.
                    <br>Click 'No' to go back.`,
                header: 'Add Cart Item',
                icon: 'pi pi-exclamation-triangle',
                key: 'breckincrement5',
                accept: async () => {
                    detailObj.quantity = nearset;
                    this.updateOrderDetail(detailObj, index);
                },
                reject: (type) => {
                    if (type === 1) {
                        detailObj.quantity = detailObj.quantity;
                        this.updateOrderDetail(detailObj, index);
                    } else {
                        detailObj.quantity = !detailObj.recordID ? detailObj.minimumQuantity : this.oldQty;
                    }
                },
            });
        } else {
            this.updateOrderDetail(detailObj, index);
        }
    }
    async updateOrderDetail(detailObj, index, isDelete?: boolean) {
        // console.log('2044444', detailObj, detailObj.quantity, nearset, );
        if (detailObj.quantity < detailObj.minimumQuantity) {
            detailObj.quantity = detailObj.minimumQuantity;
        }
        if (!this.disabled) {
            if (detailObj.itemDiscount) {
                if (detailObj.itemDiscount > 100) {
                    detailObj.itemDiscount = 100;
                } else if (detailObj.itemDiscount < 0) {
                    detailObj.itemDiscount = 0;
                }
            } else {
                detailObj.itemDiscount = 0;
            }
            if (detailObj.quantity) {
                if (detailObj.quantity < 1) {
                    detailObj.quantity = 1;
                }
            } else {
                detailObj.quantity = 1;
            }

            const updateDetailObj: any = _.cloneDeep(detailObj);
            if (isDelete) {
                updateDetailObj.recordDeleted = 1;
            }
            if (updateDetailObj.priceCodes) {
                delete updateDetailObj.priceCodes;
            }
            delete updateDetailObj.total.delete;
            if (updateDetailObj.itemTable) {
                delete updateDetailObj.itemTable.delete;
            }
            const updateDetail: any = await this.orderService.updateOrderDetail(this.order.recordID,
                updateDetailObj, this.order.manufacturer.recordID);
            detailObj.recordDeleted = updateDetail.recordDeleted;
            updateDetail.priceCodes = detailObj.priceCodes;
            _.forEach(updateDetail.priceCodes, (priceCodeObj) => {
                priceCodeObj.label = priceCodeObj.manufacturerPriceCode ? `${priceCodeObj.manufacturerPriceCode} (${priceCodeObj.priceCode})` : priceCodeObj.priceCode;
            });
            updateDetail.picturePath = detailObj.picturePath;
            updateDetail.fullPicturePath = this.itemsAPIService.getFullPicturePath(detailObj.picturePath, this.order.manufacturer.recordID);
            this.items[index] = this.getObjForTable(updateDetail);
            this.setOrderDetailColumns();
            this.getOrder.emit({ items: this.items, isDelete });
            this.itemsUpdatedObj.emit({ itemsUpdatedObj: updateDetail});
            this.msgService.add({
                severity: 'info',
                summary: isDelete ? 'Item deleted' : 'Item Saved',
                detail: `${detailObj.itemNumber} was ${isDelete ? 'deleted' : 'saved'} successfully.`
            });
            this.retailerService.setRetailerDetailsEvent(true);
        }
    }

    async deleteOrderDetail(detailObj, index) {
        if (!this.disabled) {
            this.confirmationService.confirm({
                message: 'Are you sure you want to delete this item?',
                header: 'Delete Cart Item',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: async () => {
                    await this.updateOrderDetail(detailObj, index, true);
                },
                reject: () => {
                }
            });
        }
    }

    deleteItem() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete selected items?',
            header: 'Delete Cart Item',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                const ids = this.selectedTableItemArray.map(o => o.recordID);
            },
            reject: () => {
            }
        });
    }

    getItemWithMfg() {
        const itemIDs: any = [];
        _.forEach(this.items, (itemObj: any) => {
            itemIDs.push({
                manufacturerID: this.order.manufacturer.recordID,
                itemNumber: itemObj.itemNumber,
                recordDeleted: itemObj.recordDeleted,
                size: itemObj.size,
                color: itemObj.color,
                style: itemObj.style,
                recordID: itemObj.recordID,
                quantity: itemObj.quantity,
                sequenceID: itemObj.sequenceID,
                itemID: itemObj.itemID,
                itemColorStyleID: itemObj.itemSCSID
            });
        });
        return itemIDs;
    }

    openItemSearchModal(order) {
        const itemMfgIDs: any = this.getItemWithMfg();
        console.log(itemMfgIDs);
        let sequenceID = 0;
        if (order.orderDetails && order.orderDetails.length) {
            sequenceID = order.orderDetails[order.orderDetails.length - 1].sequenceID;
        }
        console.log('orderorder', order);
        const data = {
            searchText: '',
            repGroupID: order.repGroup.recordID,
            selectedManufacturer: { recordID: order.manufacturer.recordID, name: order.manufacturer.name, refrenceName: order.repGroupManufacturerName },
            fromPage: 'ORDER_PAGE',
            orderID: order.recordID,
            retailer: order.retailer,
            manufacturerID: order.manufacturer.recordID,
            sequenceID,
            itemMfgIDs
        };

        const ref: any = this.dialogService.open(BrowseItemsDialogComponent, {
            header: 'Browse Items',
            width: this.projectName === 'B2B' ? '986px' : '97%',
            height: 'auto',
            closable: true,
            styleClass: 'overflow-auto cut-dialog-height browse-hr-scroll',
            baseZIndex: 0,
            data
        });

        ref.onClose.subscribe((dataObj: any) => {
            if (dataObj && dataObj.type === 'EXIST') {
                this.focuseItemMfgObj = dataObj.itemMfgObj;
                document.getElementById(`qty${this.focuseItemMfgObj.recordID}`).focus();
            }
        });
    }


    openOrderItemNote(detailObj, index) {
        if (!this.disabled) {
            const ref: any = this.dialogService.open(OrderDetailNoteDialogComponent, {
                header: `Edit note for #${detailObj.itemNumber}`,
                width: '40%',
                closable: true,
                styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
                data: {
                    detailObj
                }
            });
            ref.onClose.subscribe((notes: any) => {
                if (notes !== undefined) {
                    detailObj.notes = notes;
                    this.updateOrderDetail(detailObj, index);
                }
            });
        }
    }

    ngOnDestroy() {
        this.addItemSubscription.unsubscribe();
    }

    addTokens(url: string, params) {
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }

    // B2B-191 (MG-23-MAR-2020) modified to show MFR list
    getLogoPath(recordID) {
        return this.addTokens(this.logoPath, { companyID: recordID });
    }

    validateDateBetweenTwoDates(data) {
        const from = new Date(new RtDatePipe(this.dp).transform(data.discountStartDateString));
        const to   = new Date(new RtDatePipe(this.dp).transform(data.discountEndDateString));
        const check = new Date(new RtDatePipe(this.dp).transform(moment(new Date()).format('MM-DD-YYYY')));
        return check <= to && check >= from;
      }
}
