import {Salesperson} from './salesperson';
import {Retailer} from './retailer';
import {Manufacturer} from './manufacturer';
import {RetailerShipToLocation} from './retailer-ship-to-location';
import {OrderDetail} from './order-detail';
import {RetailerContact} from './retailerContact';

export class Order {
    public static readonly ORDER_TYPE_OPTIONS = [
        {label: 'Open', value: 'OPEN'},
        {label: 'Quote', value: 'QUOTE'}
    ];

    public static readonly MANUFACTURER_DROPDOWN_STATUSES = [
        {label: 'NOT TRANSMITTED', value: 'NOT TRANSMITTED', styleClass: 'nottransmitted'},
        {label: 'OPEN', value: 'OPEN', styleClass: 'open'},
        {label: 'RECEIVED', value: 'RECEIVED', styleClass: 'received'},
        {label: 'PENDING', value: 'PENDING', styleClass: 'pending'},
        {label: 'PROCESSED', value: 'PROCESSED', styleClass: 'processed'},
        {label: 'SHIPPED', value: 'SHIPPED', styleClass: 'shipped'},
        {label: 'BACKORDER', value: 'BACKORDER', styleClass: 'backorder'},
        {label: 'CANCELLED', value: 'CANCELLED', styleClass: 'cancelled'},
        {label: 'COMPLETE', value: 'COMPLETE', styleClass: 'complete'}
    ];

    public static readonly REPGROUP_DROPDOWN_STATUSES = [
        {label: 'INCOMPLETE', value: 'INCOMPLETE', styleClass: 'incomplete'},
        {label: 'OPEN', value: 'OPEN', styleClass: 'open'},
        {label: 'QUOTE', value: 'QUOTE', styleClass: 'quote'},
        {label: 'TRANSMITTED', value: 'TRANSMITTED', styleClass: 'transmitted'},
        {label: 'CONFIRMED', value: 'CONFIRMED', styleClass: 'confirmed'},
        {label: 'CANCELLED', value: 'CANCELLED', styleClass: 'cancelled'},
        {label: 'INVOICED', value: 'INVOICED', styleClass: 'invoiced'},
        {label: 'PAID', value: 'PAID', styleClass: 'paid'},
        {label: 'REP PAID', value: 'REPPAID', styleClass: 'reppaid'},
        {label: 'COMPLETE', value: 'COMPLETE', styleClass: 'complete'}
    ];

    public static readonly REPGROUP_ORDER_DROPDOWN_STATUSES = [
        {label: 'INCOMPLETE', value: 'INCOMPLETE', styleClass: 'incomplete'},
        {label: 'OPEN', value: 'OPEN', styleClass: 'open'},
        {label: 'QUOTE', value: 'QUOTE', styleClass: 'quote'}
    ];

    recordID: number;
    manufacturerOrderNotes: string;
    acceptBackOrder: boolean;
    attachDocuments: boolean;
    billToAddress1: string;
    billToAddress2: string;
    billToCity: string;
    billToCountry: string;
    billToName: string;
    billToState: string;
    billToZip: string;
    cancelDate: Date;
    dateAdded: number;
    dateModified: number;
    estimatedHandlingCost: number;
    estimatedShippingCost: number;
    exportFileHeader: string;
    exportFileDetail: string;
    exportFilePDF: string;
    externalID: string;
    FOBLocation: string;
    manufacturerCommissionPercent: number;
    manufacturerDateSent: number;
    manufacturerMethodSent: string;
    manufacturerOrderNumber: string;
    manufacturerOrderStatus: string;
    newAccountToManufacturer: boolean;
    notes: string;
    OOTCommissionReason: number;
    orderCode: string;
    orderDate: Date;
    orderDiscount: number;
    orderTotal: number;
    orderType: string;
    paymentTerm: string;
    paymentToken: string;
    poNumber: string;
    recordDeleted: boolean;
    repGroupID: string;
    repGroupOrderStatus: string;
    requestDate: Date;
    retailerCarrierAccountID: number;
    retailerDateSent: number;
    retailerMethodSent: string;
    salespersonCommissionPercent: number;
    salespersonSplit1CommissionPercent: number;
    salespersonSplit2CommissionPercent: number;
    sentTrackingNumber: string;
    shipDate: Date;
    shippingMethod: string;
    shippingNotes: string;
    shipToAddress1: string;
    shipToAddress2: string;
    shipToCity: string;
    shipToCountry: string;
    shipToName: string;
    shipToState: string;
    shipToZip: string;
    specialInstructions: string;
    tags: string;
    userAdded: number;
    userModified: number;
    // orderDetails: OrderDetail[];
    // retailerContact: RetailerContact;
    // retailerShipToLocation: RetailerShipToLocation;
    manufacturer: Manufacturer;
    retailer: Retailer;
    retailerShipToLocation: RetailerShipToLocation;
    retailerContact: RetailerContact;
    salesperson1: Salesperson;
    salesperson2: Salesperson;
    salesperson3: Salesperson;
    salesperson4: Salesperson;
    salesperson5: Salesperson;
    orderDetails: OrderDetail[];
}
