import { Directive, Input, ElementRef, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[appCustomValidation]'
})
export class CustomValidationDirective {
  @Input() allowStartWithSpace = true;
  private InputSpecialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private AllowControlPluseKey: Array<string> = ['a', 'c', 'v', 'x', 'A', 'C', 'V', 'X'];
  private StartWithSpaceRegex: RegExp = new RegExp(/^\S/g);

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.allowStartWithSpace) {
      const current: string = this.el.nativeElement.value;
      const position = this.el.nativeElement.selectionStart;
      const next: string = [current.slice(0, position), event.key, current.slice(position)].join('');
      if (this.InputSpecialKeys.indexOf(event.key) !== -1 || (this.AllowControlPluseKey.indexOf(event.key) !== -1 && event.ctrlKey)) {
        return;
      }
      if (next && !String(next).match(this.StartWithSpaceRegex)) {
        event.preventDefault();
      }
    }
  }
  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (!this.allowStartWithSpace) {
      const current: string = this.el.nativeElement.value;
      if (current && !String(current).match(this.StartWithSpaceRegex)) {
        this.el.nativeElement.value = current.trim();
      }
    }
  }
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (!this.allowStartWithSpace) {
      const clipboardData = event.clipboardData;
      const pastedText = this.el.nativeElement.value.concat(clipboardData.getData('text').toString());
      if (pastedText && !String(pastedText).match(this.StartWithSpaceRegex)) {
        this.el.nativeElement.value = pastedText.trim();
        event.preventDefault();
      }
    }
  }
}

@NgModule({
  declarations: [CustomValidationDirective],
  exports: [CustomValidationDirective]
})
export class CustomValidationDirectiveModule { }
