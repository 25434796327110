<div class="reptime-theme">
    <h2 class="text-center" *ngIf="!manufacturers.length">Select items to see available promotions</h2>
    <ng-container *ngFor="let mfg of manufacturers">
        <!--  -->
        <p-fieldset 
            legend="{{rtSharedService.getSeletedLanguage(mfg.name, 
                mfg['repGroupManufacturerName'],
                mfg['repGroupManufacturerName_json'] )}}" 
            [toggleable]="true" class="mx-5">
            <h3 class="text-center my-0 no-promo-text" *ngIf="!mfg.promotions.length">No promotions available currently</h3>
            <rs-promotions-table *ngIf="mfg.promotions && mfg.promotions.length"
                                 [promotions]="mfg.promotions"></rs-promotions-table>
        </p-fieldset>
    </ng-container>
</div>
