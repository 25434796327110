import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, AfterViewInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as objectPath from 'object-path';
import $ from 'jquery';

import * as _ from 'lodash';

import { ItemBasic } from '../../../models/item-basic';
import { ItemSearch } from '../../../models/item-search';
import { APIResponse } from '../../../models/api-response';
import { Calendar, MessageService, DialogService } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { DataView } from 'primeng/dataview';
import { ItemsAPIService } from '../../../services/api/items-api/items-api.service';
import { RTSharedService } from '../../../services/rtshared/rtshared.service';
import { Subscription } from 'rxjs';
import { OrderService } from '../../../services/middleware/order/order.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ItemViewModelComponent } from 'src/app/modals/item-view-model/item-view-model.component';
// [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
import { AuthService } from '../../../services/middleware/auth/auth.service';
import { BasketItemService } from 'src/app/services/middleware/basket-item/basket-item.service';
import { CompanyService } from '../../../services/api/company.service';
import { environment } from '../../../../environments/environment';
import { ConfirmationService } from 'primeng/api';
declare function launchAR();
@Component({
    selector: 'rs-items-table',
    templateUrl: './items-table.component.html',
    styleUrls: ['./items-table.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class ItemsTableComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() manufacturerID: string;
    @Input() itemMfgIDs: any;
    @Input() manufacturersArr: any;
    @Input() calloutData: any;
    @Input() fromPage: string;
    @Input() selectedManufacturer: any;
    @Input() selectedCustomer: any;
    @Input() repGroupID: string;
    @Input() existCartData: any;
    @Input() existItemsData: any;
    @Input() rows = 20;
    // (RK 25-Aug-2020): pass null like all: null;
    @Input() searchText: string;
    @Input() onlyOneAdd: boolean;
    @Input() customer: any;
    @Output() onlyOneAddChange = new EventEmitter();
    @Output() total?= new EventEmitter<number>();
    @Output() addToCart?= new EventEmitter<any>();
    @Output() cartDataEvent?= new EventEmitter<any>();
    @Output() viewHistory?= new EventEmitter<any>();
    @Output() viewISC?= new EventEmitter<any>();
    @Output() promotionsClickEvent = new EventEmitter<any>();
    @Output() metClickEvent = new EventEmitter<any>();
    @Output() checkoutClickEvent = new EventEmitter<any>();
    @Output() updateExistCardEvent = new EventEmitter<any>();
    @Output() viewItemDetailTileEvent = new EventEmitter<any>();
    // @Output() addToCartEvent: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('dateModifiedFromCal', { static: false }) dateModifiedFromCal: Calendar;
    @ViewChild('dateModifiedToCal', { static: false }) dateModifiedToCal: Calendar;
    @ViewChild('itemListingTable', { static: false }) itemListingTable: Table;
    @ViewChild('itemListingGrid', { static: false }) itemListingGrid: DataView;

    public cols: any[];
    public tableEvent: any = { rows: this.rows, first: 1 };
    public items: any[] = [];
    public showTable: boolean;
    public searchItem = new ItemSearch();
    public baseImageUrl: string;
    public searchForm: any;
    public loadingTableData = true;
    public isSubmitLoading: boolean;
    public isResetLoading: boolean;
    public advanceOptions = [];
    public dateRangeOptions = [];
    public totalItemCount = -1;
    public selectedView: string;
    public addDisbaleObj: any = {};
    addBtnDisableSub: Subscription;
    itemTableSubscribe: Subscription;
    heartStatusArray: boolean[] = [];
    public initSelectorItemTable: boolean;
    public displayItemViewDialog: boolean;
    public activeRouteQP: any;
    public activeViewItem: any;
    public activeViewIndex: any;
    public calloutValues = [];
    public shopItemShimmer = true;
    shimmercounts = ['1', '1', '1', '1', '1', '1', '1', '1'];
    public showDisplayViewOption = true;
    public isQuickOrder: any;
    public cartData: any;
    cartSidebar: any;
    win: any = (window);
    public domainData: any = this.win.domainData;
    header: any;
    headerHeight: any;
    public cartClickSubscription: Subscription;
    public cartQtyUpdateSubscription: Subscription;
    public selectedCartItemSubscription: Subscription;
    public itemSearchSubscription: Subscription;
    // placeholderForSearchBar = 'Search for products or brands';
    placeholderForSearchBar = 'Search for items';
    public subscriptionItemsData: Subscription;
    oldQty = 0;
    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    // RC-1243 (MK-2-OCT-2020) add exact search type
    typeList = [{ label: 'Start', value: 'startswith' }, { label: 'Contain', value: 'contains' }, { label: 'Exact Match', value: 'exactmatch' }];
    stickyTop;
    selecteditems = [];
    visibledownload = false;
    isShowDownload = false;
    @Input() pageType: any;
    public logoBucketUrl = environment.logoBucketUrl;
    public cartBarPosition = 'sliding';
    logoPath = this.logoBucketUrl + 'logo/{companyID}.png';
    searchFormData: any;
    isLogin = true;
    public isIframeWindow: any = (window as any).iframeData;
    public colorOptions = this.auth.getColorSelectionSettings();
    @Input() whichPage: any;
    mfgCategory1: any[] = [];
    mfgCategory2: any[] = [];
    mfgCategory3: any[] = [];
    manufacturerCategory1 = [];
    manufacturerCategory2 = [];
    manufacturerCategory3 = [];
    
    constructor(
        private itemsAPIService: ItemsAPIService,
        private fb: FormBuilder,
        private activeRoute: ActivatedRoute,
        public router: Router,
        private orderService: OrderService,
        public rtSharedService: RTSharedService,
        public dialogService: DialogService,
        public auth: AuthService,
        private msgService: MessageService,
        private basketItemService: BasketItemService,
        public companyService: CompanyService,
        private confirmationService: ConfirmationService,
    ) {
        this.advanceOptions = [
            { label: 'All', value: { searchByName: 'all', key: 'all' } },
            { label: 'Name', value: { searchByName: 'name', key: 'name' } },
            { label: 'Description ', value: { searchByName: 'description', key: 'description' } },
            { label: 'Item #', value: { searchByName: 'item number', key: 'itemNumber' } },
            { label: 'UPC1', value: { searchByName: 'UPC1', key: 'upc' } },
            { label: 'UPC2', value: { searchByName: 'UPC2', key: 'upc2' } },
            { label: 'UPC3', value: { searchByName: 'UPC3', key: 'upc3' } }
        ];
        this.dateRangeOptions = [
            { label: 'Order Date From', value: { key: 'dateModifiedFrom', type: 'startDate' } },
            { label: 'Order Date to', value: { key: 'dateModifiedTo', type: 'endDate' } }
        ];

        this.addBtnDisableSub = this.orderService.getAddBtnDisableSub()
            .subscribe(async (eventObj) => {
                this.addDisbaleObj = eventObj; // subject for disbale/enable add button
            });
        this.cartClickSubscription = this.basketItemService.getCartClickEvent().subscribe(async (flag) => {
            this.cartSidebar = flag ? true : false;
            console.log('this.cartSidebar >>', this.cartSidebar);
        });
        this.cartQtyUpdateSubscription = this.basketItemService.getCartQtyUpdateEvent().subscribe(async (data) => {
            this.cartData = await this.getCart(this.selectedCustomer);
            this.existCartData = this.cartData;
            await this.setItemQtyTable();
        });
        this.itemSearchSubscription = this.basketItemService.getItemSearchEvent().subscribe(async (searchText) => {
            this.searchText = searchText;
            this.searchForm.all = searchText;
            console.log('searchText >>>>', searchText);
            this.search(this.searchForm);
        });
    }

    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    ngOnInit() {
        this.isLogin = this.auth.getIDToken();
        this.isShowDownload = this.domainData && this.domainData.enableItemImageDownload;
        if (sessionStorage.getItem('ItemSearch')) {
            this.searchText = sessionStorage.getItem('ItemSearch');
            sessionStorage.removeItem('ItemSearch');
        }
        if (sessionStorage.getItem('ItemAdvanceSearch') && sessionStorage.getItem('ItemAdvanceSearch') !== 'undefined') {
            this.searchFormData = JSON.parse(sessionStorage.getItem('ItemAdvanceSearch'));
            sessionStorage.removeItem('ItemAdvanceSearch');
        }
        if (this.domainData && this.auth.getProjectType() === 2) {
            this.stickyTop = document.getElementById('lightovation-header');
            this.headerHeight = '80px';
        } else {
            this.stickyTop = document.getElementById('b2bheader');
            this.headerHeight = '123px';
        }
         if( this.isLogin &&  this.activeRouteQP.mfg && this.whichPage == 'vendor'){
            this.getMFGCategory1();
        }
        // RM (29-02-2020): Finalize screen >> Add item btn >> browse items popup >> set default list view & remove grid-view
        // if (this.fromPage === 'ORDER_PAGE') {
        //     this.displayTable();
        //     this.showDisplayViewOption = true; // selectbutton hide when come to order page
        //     this.selectedView = 'TableView';    // default TableView when come to order page
        //     this.shopItemShimmer = false;       // Shimmer effect hide for grid view
        // } else {
        console.log('fromPage >> ngOnInit >> ', this.fromPage);
        console.log('existCartData', this.existCartData);
        console.log('existItemsData', this.existItemsData);

        const projectName = this.rtSharedService.getProjectName();
        this.isQuickOrder = this.activeRoute.snapshot.queryParams.quick;
        this.showTable = projectName !== 'B2B';
        if (this.showTable) {
            this.selectedView = 'TableView';
        } else {
            // this.rows = (this.auth.getCartStyle()) ? 48 : 15;
            this.rows = (this.auth.getCartStyle() === 0 || this.auth.getCartStyle() === 3) ? 15 : 48;
            this.tableEvent = { rows: this.rows, first: 1 };
            this.selectedView = 'GridView';
        }
        // }
        this.searchItem.isAvailable = true;
        if (!this.pageType) {
            this.cols = [
                { field: 'itemNumber', header: 'Item #', width: '90px', spinner: true, sortField: 'itemNumber' },
                { field: 'name', header: 'Name', width: 'auto', spinner: true, sortField: 'name' },
                {
                    field: 'repGroupManufacturerName',
                    header: 'Manufacturer',
                    width: '160px',
                    spinner: false,
                    sortField: ''
                },
                { field: 'upc1', header: 'UPC1', width: '106px', spinner: true, sortField: 'upc1' },
                { field: 'upc2', header: 'UPC2', width: '106px', spinner: true, sortField: 'upc2' },
                { field: 'upc3', header: 'UPC3', width: '106px', spinner: true, sortField: 'upc3' },
                { field: 'nextAvailableDate', header: 'Next Available', width: '145px', spinner: true, sortField: 'nextAvailableDate' },
                { field: 'minimumQuantity', header: 'Min.', width: '84px', spinner: true, sortField: 'minimumQuantity' },
                {
                    field: 'unitPrice',
                    header: 'Price($)',
                    width: '86px',
                    spinner: true,
                    sortField: 'unitPrice',
                    class: 'price'
                },
                { field: 'quantity', header: 'Qty.', width: '50px', spinner: false, sortField: '' },
                { field: 'total', header: 'Ext. Total', width: '85px', spinner: false, sortField: '', class: 'price' }
            ];
        } else {
            this.cols = [
                { field: 'itemNumber', header: 'Item #', width: '90px', spinner: true, sortField: 'itemNumber' },
                { field: 'name', header: 'Name', width: 'auto', spinner: true, sortField: 'name' },
                {
                    field: 'repGroupManufacturerName',
                    header: 'Manufacturer',
                    width: 'auto',
                    spinner: false,
                    sortField: ''
                },
                // { field: 'upc1', header: 'UPC1', width: '106px', spinner: true, sortField: 'upc1' },
                // { field: 'upc2', header: 'UPC2', width: '106px', spinner: true, sortField: 'upc2' },
                // { field: 'upc3', header: 'UPC3', width: '106px', spinner: true, sortField: 'upc3' },
                // { field: 'minimumQuantity', header: 'Min.', width: '84px', spinner: true, sortField: 'minimumQuantity' },
                // {
                //     field: 'unitPrice',
                //     header: 'Price($)',
                //     width: '86px',
                //     spinner: true,
                //     sortField: 'unitPrice',
                //     class: 'price'
                // },
                // { field: 'quantity', header: 'Qty.', width: '50px', spinner: false, sortField: '' },
                // { field: 'total', header: 'Ext. Total', width: '50px', spinner: false, sortField: '', class: 'price' }
            ];
        }

        this.baseImageUrl = '';
        this.initSelectorItemTable = true;
    }
    ngAfterViewInit() {
        this.isIframeWindow = this.win.iframeData;
        this.itemTableSubscribe = this.activeRoute.queryParams.subscribe((qp) => {
            console.log('>>>>>>>>qp', qp, this.fromPage, this.pageType, this.searchForm);
            if (!this.initSelectorItemTable && (!qp.itemdetails)) {
                // B2B-75 (RK 17-FEB-2020): When filter route change reinitialize iteam table
                this.loadingTableData = true;
                this.isResetLoading = true;
                const defaultEvent = { rows: this.rows, first: 0 };
                const queryParam = this.activeRoute.snapshot.queryParams;
                this.isQuickOrder = queryParam.quick;

                if (this.isQuickOrder !== 'true' || (this.isQuickOrder === 'true' && _.size(queryParam) > 1)) {
                    if (!this.pageType) {
                        this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
                    } else {
                        this.loadItemsLazyPublic(defaultEvent, this.searchForm, this.selectedView);
                    }
                } else {
                    this.items = [];
                    this.totalItemCount = 0;
                    this.loadingTableData = false;
                    this.isSubmitLoading = false;
                    this.isResetLoading = false;
                }
            } else {
                const queryParam = this.activeRoute.snapshot.queryParams;
                this.isQuickOrder = queryParam.quick;
                if (this.isQuickOrder === 'true' && _.size(queryParam) > 1) {
                    const qpObject = Object.assign({});
                    qpObject.quick = true;
                    this.router.navigate([`/shop`], { queryParams: qpObject });
                }
            }
            this.initSelectorItemTable = false;
        });
        launchAR();
    }
    ngOnChanges(changes) {
        // B2B-75 (RK 15-FEB-2020): Get active route queryParams when navigate route
        let selectedCalloutIdArray = [];
        this.activeRoute.queryParams.subscribe((qp) => {
            selectedCalloutIdArray = [];
            if (qp.callout && qp.callout.length) {
                selectedCalloutIdArray = qp.callout.split(',');
            } else {
                selectedCalloutIdArray = [];
            }
            this.activeRouteQP = qp;
            this.updateCalloutDropdown(selectedCalloutIdArray);
        });
        
    }
    updateCalloutDropdown(selectedCalloutIdArray) {
        this.calloutValues = [];
        Object.keys(selectedCalloutIdArray).forEach(k => {
            if (this.calloutData && this.calloutData.length) {
                const record = this.calloutData.find(x => x.recordID.toString() === selectedCalloutIdArray[k]);
                if (record) {
                    this.calloutValues.push(_.cloneDeep(record));
                }
            }
        });
    }
    initSearchObj($event) {
        this.searchForm = $event;
        const defaultEvent = { rows: this.rows, first: 0 };
        this.searchFormData = _.cloneDeep($event);
        // this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
    }

    async displayTable() {
        if (!this.pageType) {
            await this.setItemQtyTable();
        }
        this.showTable = true;
    }

    displayGrid() {
        this.showTable = false;
        this.SetSelectedShowGrid();
    }
    SetSelectedShowGrid() {
        this.items.find(o => {
            o['isChecked'] = this.selecteditems.find(r => r.recordID === o['recordID']) ? true : false;
        });
    }

    search($event) {
        this.searchFormData = _.cloneDeep($event);
        if (!this.loadingTableData) {
            this.isSubmitLoading = true;
            this.searchForm = $event;
            const defaultEvent = { rows: this.rows, first: 0 };
            //   this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
            if (!this.pageType) {
                this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
            } else {
                this.loadItemsLazyPublic(defaultEvent, this.searchForm, this.selectedView);
            }
        }
    }
    searchCallout($event) {

        // B2B-75 (RK 15-FEB-2020): callout filter navigate
        const calloutIdArray: any = [];
        Object.keys($event.value).forEach(k => {
            calloutIdArray.push($event.value[k].recordID);
        });
        const qpObject = Object.assign({}, this.activeRouteQP);
        if (calloutIdArray && calloutIdArray.length) {
            qpObject.callout = calloutIdArray.toString();
        } else {
            delete qpObject.callout;
        }
        this.router.navigate(['.'], { relativeTo: this.activeRoute, queryParams: qpObject });
    }

    reset($event) {
        this.searchFormData = _.cloneDeep($event);
        this.isResetLoading = true;
        this.searchForm = $event;
        const defaultEvent = { rows: this.rows, first: 0 };
        if (this.isQuickOrder !== 'true') {
            // this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
            if (!this.pageType) {
                this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
            } else {
                this.loadItemsLazyPublic(defaultEvent, this.searchForm, this.selectedView);
            }
        } else {
            this.items = [];
            this.totalItemCount = 0;
            this.loadingTableData = false;
            this.isSubmitLoading = false;
            this.isResetLoading = false;
        }
    }

    // [B2B-232] (MG-08-APR-2020) modified to do ui hotfix
    loadItemsLazy(e, searchParams, view) {
        if (this.subscriptionItemsData) {
            this.subscriptionItemsData.unsubscribe();
        }
        this.isQuickOrder = this.activeRoute.snapshot.queryParams.quick;

        if (!this.initSelectorItemTable || this.isQuickOrder !== 'true') {
            if (e.first !== 1) {
                window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            }
            this.tableEvent = e;
            if (view !== this.selectedView) {
                setTimeout(() => {
                    this.loadingTableData = false;
                });
                this.selectedView = view;
                return;
            } else {
                this.selectedView = view;
            }
            let size = e.rows;
            let offset = e.first;
            let sort = e.sortField;
            let sortOrder = e.sortOrder;
            const searchType = searchParams.searchType;
            if (size === undefined) {
                size = 20;
            }
            if (offset === undefined) {
                offset = 0;
            }
            if (sort === undefined) {
                sort = 'sequenceTopItem';
            }
            if (sortOrder === undefined) {
                sortOrder = 1;
            }
            const spID = this.rtSharedService.getSalesPersonID();
            if (this.selectedManufacturer) {
                searchParams.manufacturerID = this.selectedManufacturer.recordID;
            } else {
                if (searchParams.manufacturerID) {
                    delete searchParams.manufacturerID;
                }
            }
            if (this.repGroupID) {
                searchParams.repGroupID = this.repGroupID;
            }
            if (spID) {
                searchParams.salespersonID = spID;
            }
            // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
            if (this.activeRouteQP.g) {
                searchParams.repGroupCategoryGroupID = Number(this.activeRouteQP.g);
            } else {
                delete searchParams.repGroupCategoryGroupID;
            }
            // B2B-391 (RK 24-MAY-2021) => B2B and Marketplace Add color filter
            searchParams.colorIDSet = [];
            if(this.whichPage == 'vendor'){
                searchParams.manufacturerCategory1 = this.manufacturerCategory1 ? this.manufacturerCategory1 : [];
                searchParams.manufacturerCategory2 = this.manufacturerCategory2 ? this.manufacturerCategory2 : [];
                searchParams.manufacturerCategory3 = this.manufacturerCategory3 ? this.manufacturerCategory3 : [];
            }

            if (this.activeRouteQP.color && this.colorOptions && this.colorOptions.colorsList.length) {
                const colorModel = this.activeRouteQP.color.split(',');
                Object.keys(colorModel).forEach((k: any) => {
                    const mapData = this.colorOptions.colorsList.find(o => (o.colorlabel === colorModel[k]));
                    if (mapData) {
                        searchParams.colorIDSet.push(colorModel[k]);
                    }
                });
            }
            // B2B-81 (RK 17-FEB-2020): Update new Item list API in shopping page and pass relevant payload data
            searchParams.manufacturerIDSet = this.activeRouteQP.mfg ? this.activeRouteQP.mfg.split(',') : [];
            searchParams.calloutIDSet = this.activeRouteQP.callout ? this.activeRouteQP.callout.split(',') : [];
            _.forEach(searchParams.calloutIDSet, (element, index) => {
                searchParams.calloutIDSet[index] = +searchParams.calloutIDSet[index];
            });
            this.activeRouteQP.fromprice ? searchParams.priceFrom = this.activeRouteQP.fromprice : delete searchParams.priceFrom;
            this.activeRouteQP.toprice ? searchParams.priceTo = this.activeRouteQP.toprice : delete searchParams.priceTo;
            // B2B-268 (MJ 23-Jun-2020) Groups category filter added
            if (this.activeRouteQP.c) {
                const categoryIDSet = this.activeRouteQP.c.split(',');
                searchParams.repGroupCategoryIDSet = _.map(categoryIDSet, (o) => Number(o));
                // this.activeRouteQP.c ? searchParams.repGroupCategoryID = Number(this.activeRouteQP.c) : delete searchParams.repGroupCategoryID;
            } else {
                delete searchParams.repGroupCategoryIDSet;
            }
            this.activeRouteQP.s ? searchParams.repGroupSubCategoryID =
                Number(this.activeRouteQP.s) : delete searchParams.repGroupSubCategoryID;
            // MM-138 (RK 07-APRIL-2021) => B2B and Marketplace Group Redirection
            this.activeRouteQP.g || this.activeRouteQP.c || this.activeRouteQP.s ? searchParams.includeCatItems = true : delete searchParams.includeCatItems;
            this.activeRouteQP.new ? searchParams.daysLastModified =
                this.auth.getDaysLastModified() : delete searchParams.daysLastModified;

            this.activeRouteQP.bestseller ? searchParams.isBestSeller =
                this.auth.getbestSallerData() : delete searchParams.isBestSeller;

            if (searchParams.calloutIDSet && searchParams.calloutIDSet.length) {
                searchParams.assigned = true;
            } else {
                delete searchParams.assigned;
            }
            this.loadingTableData = true;
            this.shopItemShimmer = true;
            this.subscriptionItemsData = this.itemsAPIService.getb2baccessItems(searchParams, { offset, size, sort, sortOrder, searchType })
                .subscribe(async (response: APIResponse) => {
                    this.shopItemShimmer = false;
                    if (response.success) {
                        if (sessionStorage.getItem('ItemSearch')) {
                            sessionStorage.removeItem('ItemSearch');
                        }
                        if (sessionStorage.getItem('ItemAdvanceSearch') && sessionStorage.getItem('ItemAdvanceSearch') !== 'undefined') {
                            sessionStorage.removeItem('ItemAdvanceSearch');
                        }
                        if ((searchParams.all !== null && searchParams.all !== '') && this.isQuickOrder) {
                            this.items = response.data;
                            this.totalItemCount = response.total;
                        } else {
                            this.items = [];
                            this.totalItemCount = 0;
                        }
                        // if (!this.isQuickOrder) {
                        // this.items = response.data;
                        this.items = _.map(_.cloneDeep(response.data), (o) => {
                            if (!o.showLockedB2bItems && (o.b2bItemPricingPolicy === 'NeedBrandApproval' || o.b2bItemPricingPolicy === 'HideItemPrice')) {
                                o.b2bItemPricingPolicyFlag = true;
                            } else {
                                o.b2bItemPricingPolicyFlag = false;
                            }
                            return o;
                        });
                        this.totalItemCount = response.total;
                        // }
                        await this.setItemQtyTable();
                        // B2B-167 (CB 16-MAR-2020): Add quick order functionality
                        if (this.items.length === 1 && this.isQuickOrder) {
                            const existFind = this.itemMfgIDs.find(o => (this.items[0].itemNumber === o.itemNumber
                                && this.items[0].manufacturerID === o.manufacturerID && !o.recordDeleted));
                            if (this.items[0].isc.length > 0) {
                                this.viewItemDetail(this.items[0], 0);
                            }
                            if (existFind === undefined && this.items[0].isc.length === 0) {
                                this.items[0].quantity = this.items[0].minimumQuantity;
                                await this.basketItemService.saveItemToBasket(this.items[0], this.selectedCustomer);
                                this.msgService.clear();
                                this.msgService.add({
                                    key: 'addItem',
                                    severity: 'info',
                                    summary: 'Item Added to Cart',
                                    detail: `${this.items[0].itemNumber} was saved successfully.`
                                });
                                this.cartData = await this.getCart(this.selectedCustomer);
                                this.existCartData = this.cartData;
                                this.setMfgMinimumMet(this.existCartData.manufacturer);
                                this.itemMfgIDs = this.getItemWithMfg(this.existCartData);
                                this.addToCartEvent({ cartData: this.cartData, itemMfgIDs: this.itemMfgIDs });
                            } else if (this.items[0].isc.length === 0) {
                                this.msgService.clear();
                                this.msgService.add({
                                    key: 'addItem',
                                    severity: 'info',
                                    summary: 'Item Already Added in Cart',
                                    detail: `${this.items[0].itemNumber} was already in cart.`
                                });
                            }
                        }
                        if (this.total) {
                            this.total.emit(this.totalItemCount);
                        }

                        // ONLY ONE ADD
                        if (this.onlyOneAdd && this.items && this.items.length && this.items.length === 1) {
                            const addItem: any = this.items[0];
                            if (addItem.isc && addItem.isc.length) {
                                setTimeout(() => {
                                    const elem = document.getElementById(`iscButton${addItem.recordID}`);
                                    elem.click();
                                }, 0);
                            } else {
                                this.addToCart.next(addItem);
                            }
                        } else {
                            this.onlyOneAdd = false;
                            this.onlyOneAddChange.emit(this.onlyOneAdd);
                        }
                    } else {
                        this.totalItemCount = 0;
                        if (this.total) {
                            this.total.emit(0);
                        }
                        this.msgService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                        });
                    }
                    this.loadingTableData = false;
                    this.isSubmitLoading = false;
                    this.isResetLoading = false;
                }, error => {

                    this.loadingTableData = false;
                    this.shopItemShimmer = false;
                    this.totalItemCount = 0;
                    if (this.total) {
                        this.total.emit(0);
                    }
                    this.msgService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error occurred while updating table'
                    });
                });
        } else {
            setTimeout((res) => {
                this.items = [];
                this.totalItemCount = 0;
                this.loadingTableData = false;
                this.isSubmitLoading = false;
                this.isResetLoading = false;
                this.shopItemShimmer = false;
            }, 0);
        }
    }
    async setItemQtyTable() {
        this.items.forEach((d: any) => {
            d.basicUnitPrice = _.cloneDeep(d.unitPrice);
            d.itemDiscount = 0;
            d.quantity = 0; // d.quantity ? d.quantity : 0;
            d.selectedPriceCode = '';
            d.imageError = false;
            d.imageLoader = true;
            d.fullPicturePath = this.itemsAPIService.getFullPicturePath(d.picturePath, d.manufacturerID);
            d.fullPicturePath256PX = this.itemsAPIService.getFullPicturePath(d.picturePath, d.manufacturerID, 256);
            const rand = Math.floor(Math.random() * 20) + 1;
            d.profilePic = this.getLogoPath(d.manufacturerID) + '?time=' + rand + 5;
            this.applyVolumePrice(d);
            // (RM 10-08-2020): Condition Added B2B-294, Searching & adding same item from finalize screen should auto fill-up qty from cart
            if (this.fromPage === 'ORDER_PAGE') {
                if (d.isc.length === 0) {
                    const existFind = this.existItemsData.find(o => d.recordID === o.itemID && !o.recordDeleted);
                    d.quantity = existFind && existFind.quantity ? existFind.quantity : d.quantity;
                } else if (d.isc.length !== 0) {
                    d.isc.forEach((value: any) => {
                        const existFind = this.existItemsData.find(o => value.itemID === o.itemID && value.recordID === o.itemColorStyleID && !o.recordDeleted);
                        value.quantity = existFind && existFind.quantity ? existFind.quantity : 0;
                    });
                }
            } else {
                if (d.isc.length === 0) {
                    const existFind = this.existCartData.items.find(o => d.recordID === o.item.recordID);
                    d.quantity = existFind && existFind.quantity ? existFind.quantity : d.quantity;
                } else if (d.isc.length !== 0) {
                    d.isc.forEach((value: any) => {
                        const existFind = this.existCartData.items.find(o => d.recordID === o.item.recordID);
                        const existIscObj = existFind && existFind.basketItemVariants.find((v) => value.itemID === v.itemID
                            && value.recordID.toString() === v.itemColorStyleID);
                        value.quantity = existIscObj && existIscObj.quantity ? existIscObj.quantity : 0;
                    });
                }
            }
        });
    }

    getExportFilename() {
        return 'ItemsExport-' + new Date().getMonth() + 1 + '_' + new Date().getDay() + '_' + new Date().getFullYear();
    }

    setPriceCode(itemObj: any) {
        if (itemObj.selectedPriceCode) {
            const selectedCodeObj = itemObj.itemPriceCodes.find(o => o.priceCode === itemObj.selectedPriceCode);
            itemObj.itemDiscount = 0;
            this.applyVolumePrice(itemObj);
            itemObj.unitPrice = selectedCodeObj.unitPrice;
        } else {
            this.applyVolumePrice(itemObj);
        }
    }

    checkIsPriceCodeAndDiscountApplied(item) {
        if (item.selectedPriceCode) {
            return true;
        } else {
            return false;
        }
    }

    applyDiscount(itemObj: any) {
        if (itemObj.itemDiscount) {
            itemObj.unitPrice = itemObj.basicUnitPrice;
            itemObj.isVolumePriceApplied = false;
        } else {
            this.applyVolumePrice(itemObj);
        }
    }
    onclickSaveOldQty(itemObj: any) {
        this.oldQty = 0;
        this.oldQty = itemObj.quantity;
        console.log('66666', this.oldQty);
    }
    setQuantity(itemObj: any) {
        const nearset = Math.round(itemObj.quantity / itemObj.quantityIncrement) * itemObj.quantityIncrement;
        if (itemObj.quantity > 0 && nearset.toString() !== itemObj.quantity.toString() && itemObj.quantity.toString() !== itemObj.minimumQuantity.toString()) {
            // <br>Click 'Ignore' to set quantity as ${itemObj.quantity}.
            setTimeout(() => {
                if (document.getElementById('YesButton')) {
                    document.getElementById('YesButton').focus();
                }
            }, 0);
            this.confirmationService.confirm({
                message: `This process will break the manufacturer set increment quantity for this item.
                <br><br>Click 'Yes' to accept quantity of ${nearset}.
                <br>Click 'No' to go back.`,
                header: 'Add Cart Item',
                icon: 'pi pi-exclamation-triangle',
                key: 'breckincrement2',
                accept: async () => {
                    itemObj.quantity = nearset;
                    this.addToCart.emit(itemObj);
                },
                reject: (type) => {
                    if (type === 1) {
                        itemObj.quantity = itemObj.quantity;
                        this.addToCart.emit(itemObj);
                    } else {
                        itemObj.quantity = !itemObj.recordID ? itemObj.minimumQuantity : this.oldQty;
                    }
                },
            });
        } else {
            this.addToCart.emit(itemObj);
        }
    }
    applyVolumePrice(itemObj: any) {
        if (!itemObj.selectedPriceCode) {
            if (!itemObj.itemDiscount) {
                if (itemObj.itemVolumePricings && itemObj.itemVolumePricings.length) {
                    const volumePrices = itemObj.itemVolumePricings.filter((o: any) => {
                        return o.volumeQuantity <= itemObj.quantity;
                    });
                    if (volumePrices.length) {
                        const volumePrice = volumePrices.reduce(
                            (max, vPrice) => max.volumeQuantity > vPrice.volumeQuantity ? max : vPrice);
                        if (volumePrice) {
                            itemObj.unitPrice = volumePrice.unitPrice;
                            itemObj.isVolumePriceApplied = true;
                        } else {
                            itemObj.unitPrice = itemObj.basicUnitPrice;
                            itemObj.isVolumePriceApplied = false;
                        }
                    } else {
                        itemObj.unitPrice = itemObj.basicUnitPrice;
                        itemObj.isVolumePriceApplied = false;
                    }
                } else {
                    itemObj.unitPrice = itemObj.basicUnitPrice;
                    itemObj.isVolumePriceApplied = false;
                }
            } else {
                itemObj.unitPrice = itemObj.basicUnitPrice;
                itemObj.isVolumePriceApplied = false;
            }
        }

    }

    ngOnDestroy() {
        this.addBtnDisableSub.unsubscribe();
        this.itemTableSubscribe.unsubscribe();
        this.subscriptionItemsData.unsubscribe();
        this.itemSearchSubscription.unsubscribe();
        this.cartClickSubscription.unsubscribe();
        this.cartQtyUpdateSubscription.unsubscribe();
    }
    onHeaderCheckboxToggle($event: any) {
        console.log($event, this.selecteditems);
    }
    SelectItemsChange(item, $event) {
        item.isChecked = $event;
        if ($event) {
            this.selecteditems.push(item);
        } else {
            const index = this.selecteditems.findIndex(o => o.recordID === item.recordID);
            this.selecteditems.splice(index, 1);
        }
    }

    downloadimageszip() {
        this.visibledownload = true;
    }

    heartClickEvent(index: number): void {
        this.heartStatusArray[index] = !this.heartStatusArray[index];
    }
    viewItemDetail(item, index) {
        if (this.auth.getItemDetailPage() && (_.includes(this.router.url, '/shop') || _.includes(this.router.url, '/store') ||
         _.includes(this.router.url, '/vendor?mfg=') || _.includes(this.router.url, '/vendor-details?mfg='))) {
            if (this.isLogin && (_.includes(this.router.url, '/shop') || _.includes(this.router.url, '/vendor?mfg=') || _.includes(this.router.url, '/vendor-details?mfg='))) {
                sessionStorage.setItem('ItemAdvanceSearch', JSON.stringify(this.searchFormData));
                this.router.navigate([`/shop`], { queryParams: { itemdetails: item.recordID } });
            } else {
                if(this.auth.getProjectType() === 1) {
                    this.activeViewItem = item;
                    this.activeViewIndex = index;
                    this.displayItemViewDialog = true;
                } else {
                    this.router.navigate([`/store`], { queryParams: { itemdetails: item.recordID } });
                }
            }
        } else {
            this.activeViewItem = item;
            this.activeViewIndex = index;
            console.log(item);
            console.log(index);
            this.displayItemViewDialog = true;
        }
    }
    addToCartEvent(dataEvent) {
        this.itemMfgIDs = dataEvent.itemMfgIDs;
        this.cartDataEvent.next(dataEvent);
        // RM (29-02-2020): Item view popup close out after click add to cart button
        // CB (14-08-2020): add condition to hide popup when linkeditems is available
        if (dataEvent.linkedItemsData && dataEvent.linkedItemsData.length > 0) {
        } else {
            this.displayItemViewDialog = false;
        }
    }

    async getCart(customer) {
        console.log('getCart 1');
        const cartData = await this.basketItemService.getBasketItemData(customer);
        this.auth.setCartItemCount(cartData.totalItems);
        return cartData;
    }

    setMfgMinimumMet(manufacturers) {
        return manufacturers.map((mfg: any) => {
            mfg.isMinimumMet = (mfg.discountedTotal >= mfg.minimumOrderAmount);
            mfg.totalQty = this.getMfgTotalQty(mfg.items);
            return mfg;
        });
    }

    getMfgTotalQty(mfgItems) {
        return _.sumBy(mfgItems, 'quantity');
    }

    getItemWithMfg(cartData) {
        const itemIDs: any = [];
        if (objectPath.get(cartData, 'manufacturer', null)) {
            _.forEach(cartData.manufacturer, (o) => {
                _.forEach(o.items, (itemObj: any) => {
                    if (itemObj.basketItemVariants && itemObj.basketItemVariants.length) {
                        _.forEach(itemObj.basketItemVariants, (biv: any) => {
                            itemIDs.push({
                                manufacturerID: itemObj.manufacturerID,
                                itemNumber: itemObj.itemNumber ? itemObj.itemNumber : itemObj.item.itemNumber,
                                itemColorStyleID: biv.itemColorStyleID
                            });
                        });
                    } else {
                        itemIDs.push({
                            manufacturerID: itemObj.manufacturerID,
                            itemNumber: itemObj.item.itemNumber
                        });
                    }

                });
            });
        }
        return itemIDs;
    }

    promotionsClickCartEvent($event) {
        this.promotionsClickEvent.emit(true);
    }

    metClickCartEvent($event) {
        this.metClickEvent.emit(true);
    }
    checkoutClickCartEvent($event) {
        this.checkoutClickEvent.emit(true);
    }
    updateExistCardCartEvent(dataEvent) {
        this.existCartData = dataEvent.existCartData;
        this.customer = dataEvent.selectedCustomer;
        this.updateExistCardEvent.emit({
            existCartData: dataEvent.existCartData,
            selectedCustomer: dataEvent.selectedCustomer, itemMfgIDs: dataEvent.itemMfgIDs
        });

    }

    viewItemDetailTileData($event) {
        this.viewItemDetailTileEvent.emit($event);
    }

    incrQtyEvent(rowData) {
        console.log(typeof (rowData.quantity));
        rowData.quantity = (Number(rowData.quantity) + rowData.quantityIncrement);
        this.addToCart.next(rowData);
    }

    // show sidebar
    show_sidebar() {
        $('.b2b-sidebar').addClass('show_sidebar');
        $('body,html').addClass('overflow-hidden');
    }

    loadItemsLazyPublic(e, searchParams, view) {
        if (this.subscriptionItemsData) {
            this.subscriptionItemsData.unsubscribe();
        }
        this.isQuickOrder = this.activeRoute.snapshot.queryParams.quick;

        if (!this.initSelectorItemTable || this.isQuickOrder !== 'true') {
            if (e.first !== 1) {
                window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            }
            this.tableEvent = e;
            if (view !== this.selectedView) {
                setTimeout(() => {
                    this.loadingTableData = false;
                });
                this.selectedView = view;
                return;
            } else {
                this.selectedView = view;
            }
            let size = e.rows;
            let offset = e.first;
            let sort = e.sortField;
            let sortOrder = e.sortOrder;
            const searchType = searchParams.searchType;
            if (size === undefined) {
                size = 20;
            }
            if (offset === undefined) {
                offset = 0;
            }
            if (sort === undefined) {
                sort = 'sequencePublic';
            }
            if (sortOrder === undefined) {
                sortOrder = 1;
            }
            const spID = this.rtSharedService.getSalesPersonID();
            if (this.selectedManufacturer) {
                searchParams.manufacturerID = this.selectedManufacturer.recordID;
            } else {
                if (searchParams.manufacturerID) {
                    delete searchParams.manufacturerID;
                }
            }
            if (this.repGroupID) {
                searchParams.repGroupID = this.repGroupID;
            }
            if (spID) {
                searchParams.salespersonID = spID;
            }
            // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
            if (this.activeRouteQP.g) {
                searchParams.repGroupCategoryGroupID = Number(this.activeRouteQP.g);
            } else {
                delete searchParams.repGroupCategoryGroupID;
            }
            // B2B-391 (RK 24-MAY-2021) => B2B and Marketplace Add color filter
            searchParams.colorIDSet = this.activeRouteQP.color ? this.activeRouteQP.color.split(',') : [];
            // B2B-81 (RK 17-FEB-2020): Update new Item list API in shopping page and pass relevant payload data
            searchParams.manufacturerIDSet = this.activeRouteQP.mfg ? this.activeRouteQP.mfg.split(',') : [];
            searchParams.calloutIDSet = this.activeRouteQP.callout ? this.activeRouteQP.callout.split(',') : [];
            _.forEach(searchParams.calloutIDSet, (element, index) => {
                searchParams.calloutIDSet[index] = +searchParams.calloutIDSet[index];
            });
            this.activeRouteQP.fromprice ? searchParams.priceFrom = this.activeRouteQP.fromprice : delete searchParams.priceFrom;
            this.activeRouteQP.toprice ? searchParams.priceTo = this.activeRouteQP.toprice : delete searchParams.priceTo;
            // B2B-268 (MJ 23-Jun-2020) Groups category filter added
            if (this.activeRouteQP.c) {
                const categoryIDSet = this.activeRouteQP.c.split(',');
                searchParams.repGroupCategoryIDSet = _.map(categoryIDSet, (o) => Number(o));
                // this.activeRouteQP.c ? searchParams.repGroupCategoryID = Number(this.activeRouteQP.c) : delete searchParams.repGroupCategoryID;
            } else {
                delete searchParams.repGroupCategoryIDSet;
            }
            this.activeRouteQP.s ? searchParams.repGroupSubCategoryID =
                Number(this.activeRouteQP.s) : delete searchParams.repGroupSubCategoryID;
            this.activeRouteQP.g || this.activeRouteQP.c || this.activeRouteQP.s ? searchParams.includeCatItems = true : delete searchParams.includeCatItems;
            this.activeRouteQP.new ? searchParams.daysLastModified =
                this.auth.getDaysLastModified() : delete searchParams.daysLastModified;

            this.activeRouteQP.bestseller ? searchParams.isBestSeller =
                this.auth.getbestSallerData() : delete searchParams.isBestSeller;

            if (searchParams.calloutIDSet && searchParams.calloutIDSet.length) {
                searchParams.assigned = true;
            } else {
                delete searchParams.assigned;
            }
            this.loadingTableData = true;
            this.shopItemShimmer = true;
            // return
            if (this.isIframeWindow) {
                searchParams.mfrPreview = true;
            }
            this.subscriptionItemsData = this.companyService.getTopTeaserORPreviewItems(searchParams, { offset, size, sort, sortOrder, searchType }, this.isIframeWindow)
                .subscribe(async (response: APIResponse) => {
                    this.shopItemShimmer = false;
                    if (response.success) {
                        if ((searchParams.all !== null && searchParams.all !== '') && this.isQuickOrder) {
                            this.items = response.data;
                            this.totalItemCount = response.total;
                        } else {
                            this.items = [];
                            this.totalItemCount = 0;
                        }
                        // if (!this.isQuickOrder) {
                        this.items = response.data;
                        /* this.items = [{
                            allowDiscontinuedItemsShopping: false,
                            assortmentID: null,
                            b2bDiscountShow: 50,
                            b2bItemPricingPolicy: "ShowWholeSalePrice",
                            b2bPriceCode: null,
                            caseQuantity: 1,
                            catalogPageNumber: null,
                            category: "No Category",
                            commissionPercent: 20,
                            dateAdded: 1601112367974,
                            dateIntroduced: "2021-03-31T00:00:00.000Z",
                            dateModified: 1618563959980,
                            defaultCancelDate: "2021-07-21T00:00:00.000Z",
                            defaultShipDate: "2021-04-22T00:00:00.000Z",
                            description: "Sarong patterned passport bag.",
                            detailedDescription: "Lorem",
                            discontinued: false,
                            discountEndDate: "2021-04-30T00:00:00.000Z",
                            discountEndDateString: "2021-04-30",
                            discountPercent: 50,
                            discountStartDate: "2021-04-01T00:00:00.000Z",
                            discountStartDateString: "2021-04-01",
                            discountedTotal: 5,
                            height: "40",
                            isAvailable: true,
                            isCommissionable: null,
                            isOneToOne: null,
                            isParentItem: null,
                            isPublic: 1,
                            isTopItem: 1,
                            isWishedItem: false,
                            itemImagePaths: [{
                                imageNumber: 1,
                                picturePath: "1157932__1.jpg"
                            }],
                            itemNumber: "1157932",
                            itemPackages: [],
                            length: "15",
                            linkedItem: null,
                            managedBy: "M28769",
                            manufacturerID: "M28769",
                            manufacturerName: "Rahul Manufacturer Self Managed",
                            minimumQuantity: 1,
                            name: "Sarong Patterned Passport Bag",
                            nextAvailableDate: "2021-04-30T00:00:00.000Z",
                            noFurtherDiscountApplied: null,
                            notes: "Lorem ipsum",
                            picturePath: "1157932.jpg",
                            qtyAvailable: 8,
                            qtyAvailableUpdated: "2021-04-16T00:00:00.000Z",
                            quantityIncrement: 1,
                            recordDeleted: false,
                            recordID: 1157932,
                            repGroupCategorySubCategoryItems: null,
                            repGroupManufacturerName: "Rahul Manufacturer Self Managed (RK)!",
                            retailPrice: 5,
                            retailerRequestStatus: null,
                            season: null,
                            sequencePublic: 6,
                            sequenceTopItem: 4,
                            showLockedB2bItems: false,
                            showOnWebsite: true,
                            sizeUnitOfMeasure: "inches",
                            tags: null,
                            unitOfMeasure: null,
                            unitPrice: 10,
                            unitQty: 1,
                            upc1: "7546170003271157932",
                            upc2: "123456789652",
                            upc3: "KELS1157932",
                            userAdded: 359,
                            userModified: 359,
                            weight: "80",
                            weightUnitOfMeasure: "kgs",
                            width: "20",
                            itemVolumePricings: [{
                                dateAdded: 1601112367980,
                                dateModified: 1618563960045,
                                itemID: 1157932,
                                recordDeleted: false,
                                recordID: 93528,
                                unitPrice: 250,
                                volumeQuantity: 12,
                            }],
                            itemPriceCodes: [{
                                dateAdded: 1601112367976,
                                dateModified: 1618563959955,
                                itemID: 1157932,
                                manufacturerPriceCode: null,
                                priceCode: "A",
                                recordDeleted: false,
                                recordID: 60408,
                                unitPrice: 200,
                            }],
                            isc: [{
                                color: "Black",
                                dateAdded: 1618563960155,
                                dateModified: 1618563960155,
                                discontinued: false,
                                isAvailable: true,
                                itemID: 1157932,
                                nextAvailableDate: "2021-04-21T00:00:00.000Z",
                                notes: "Test",
                                price: null,
                                qtyAvailable: 20,
                                qtyAvailableDateUpdated: "2021-04-16T09:06:00.000Z",
                                recordDeleted: false,
                                recordID: 61296,
                                showOnWebsite: true,
                                size: "18M",
                                style: "X",
                                upc: null,
                            }]
                        }]; */
                        this.items = _.map(_.cloneDeep(response.data), (o) => {
                            if (!o.showLockedB2bItems && (o.b2bItemPricingPolicy === 'NeedBrandApproval' || o.b2bItemPricingPolicy === 'HideItemPrice')) {
                                o.b2bItemPricingPolicyFlag = true;
                            } else {
                                o.b2bItemPricingPolicyFlag = false;
                            }
                            return o;
                        });
                        this.items.forEach((d: any) => {
                            d.imageError = false;
                            d.imageLoader = true;
                            d.fullPicturePath = this.itemsAPIService.getFullPicturePath(d.picturePath, d.manufacturerID);
                            d.fullPicturePath256PX = this.itemsAPIService.getFullPicturePath(d.picturePath, d.manufacturerID, 256);

                        });
                        this.totalItemCount = response.total;
                        // }
                        //  await this.setItemQtyTable();
                        // B2B-167 (CB 16-MAR-2020): Add quick order functionality
                        // if (this.items.length === 1 && this.isQuickOrder) {
                        //     const existFind = this.itemMfgIDs.find(o => (this.items[0].itemNumber === o.itemNumber
                        //         && this.items[0].manufacturerID === o.manufacturerID && !o.recordDeleted));
                        //     if (this.items[0].isc.length > 0) {
                        //         this.viewItemDetail(this.items[0], 0);
                        //     }
                        //     if (existFind === undefined && this.items[0].isc.length === 0) {
                        //         this.items[0].quantity = this.items[0].minimumQuantity;
                        //         await this.basketItemService.saveItemToBasket(this.items[0], this.selectedCustomer);
                        //         this.msgService.clear();
                        //         this.msgService.add({
                        //             key: 'addItem',
                        //             severity: 'info',
                        //             summary: 'Item Added to Cart',
                        //             detail: `${this.items[0].itemNumber} was saved successfully.`
                        //         });
                        //         this.cartData = await this.getCart(this.selectedCustomer);
                        //         this.existCartData = this.cartData;
                        //         this.setMfgMinimumMet(this.existCartData.manufacturer);
                        //         this.itemMfgIDs = this.getItemWithMfg(this.existCartData);
                        //         this.addToCartEvent({ cartData: this.cartData, itemMfgIDs: this.itemMfgIDs });
                        //     } else if (this.items[0].isc.length === 0) {
                        //         this.msgService.clear();
                        //         this.msgService.add({
                        //             key: 'addItem',
                        //             severity: 'info',
                        //             summary: 'Item Already Added in Cart',
                        //             detail: `${this.items[0].itemNumber} was already in cart.`
                        //         });
                        //     }
                        // }
                        // if (this.total) {
                        //     this.total.emit(this.totalItemCount);
                        // }

                        // ONLY ONE ADD
                        // if (this.onlyOneAdd && this.items && this.items.length && this.items.length === 1) {
                        //     const addItem: any = this.items[0];
                        //     if (addItem.isc && addItem.isc.length) {
                        //         setTimeout(() => {
                        //             const elem = document.getElementById(`iscButton${addItem.recordID}`);
                        //             elem.click();
                        //         }, 0);
                        //     } else {
                        //         this.addToCart.next(addItem);
                        //     }
                        // } else {
                        //     this.onlyOneAdd = false;
                        //     this.onlyOneAddChange.emit(this.onlyOneAdd);
                        // }
                    } else {
                        this.totalItemCount = 0;
                        if (this.total) {
                            this.total.emit(0);
                        }
                        this.msgService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                        });
                    }
                    this.loadingTableData = false;
                    this.isSubmitLoading = false;
                    this.isResetLoading = false;
                }, error => {

                    this.loadingTableData = false;
                    this.shopItemShimmer = false;
                    this.totalItemCount = 0;
                    if (this.total) {
                        this.total.emit(0);
                    }
                    this.msgService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error occurred while updating table'
                    });
                });
        } else {
            setTimeout((res) => {
                this.items = [];
                this.totalItemCount = 0;
                this.loadingTableData = false;
                this.isSubmitLoading = false;
                this.isResetLoading = false;
                this.shopItemShimmer = false;
            }, 0);
        }
    }

    itemload($event, searchForm, viewType) {
        this.isIframeWindow = this.win.iframeData;
        if (window.self !== window.top) {
            this.isIframeWindow = true;
        }
        console.log('Rahul....itemload', this.isIframeWindow, window.self !== window.top);
        if (this.pageType) {
            this.loadItemsLazyPublic($event, searchForm, viewType);
        } else {
            this.loadItemsLazy($event, searchForm, viewType);
        }
    }

    addTokens(url: string, params) {
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }

    // B2B-191 (MG-23-MAR-2020) modified to show MFR list
    getLogoPath(recordID) {
        return this.addTokens(this.logoPath, { companyID: recordID });
    }

    getMFGCategory1() {
        if (this.activeRouteQP.mfg) {
            this.itemsAPIService.getMFGCategory1List(this.activeRouteQP.mfg).subscribe((res: any) => {
                if (res.success && res.data) {
                    this.mfgCategory1 = res.data.map(category1 => {
                        return { 'value': category1, 'label': category1 };
                    });
                    this.mfgCategory2 = [];
                    this.mfgCategory3 = [];
                    this.manufacturerCategory2 = [];
                    this.manufacturerCategory3 = [];
                } else {
                    //this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while request' });
                }
            }, (error) => {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while request' });
            });
        }
    }

    getMFGCategory2(e?) {
        if (this.activeRouteQP.mfg && this.manufacturerCategory1.length > 0) {
            this.itemsAPIService.getMFGCategory2List(this.activeRouteQP.mfg, this.manufacturerCategory1).subscribe((res: any) => {
                if (res.success && res.data) {

                    this.mfgCategory2 = res.data.map(category2 => {
                        return { 'value': category2, 'label': category2 };
                    });
                    this.mfgCategory3 = [];
                    this.manufacturerCategory3 = [];
                    this.getMFGGroup();
                } else {
                    //this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while request' });
                }
            }, (error) => {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while request' });
            });
        } else {
            this.mfgCategory2 = [];
            this.mfgCategory3 = [];
            this.manufacturerCategory2 = [];
            this.manufacturerCategory3 = [];
            this.getMFGGroup();
        }
    }
    getMFGCategory3(e?) {
        if (this.activeRouteQP.mfg) {
            const resBody = {
                manufacturerCategory1: this.manufacturerCategory1 ? this.manufacturerCategory1 : [],
                manufacturerCategory2: this.manufacturerCategory2 ? this.manufacturerCategory2 : [],
            }
            if (this.manufacturerCategory2.length > 0) {
                this.itemsAPIService.getMFGCategory3List(this.activeRouteQP.mfg, resBody).subscribe((res: any) => {
                    if (res.success && res.data) {
                        this.mfgCategory3 = res.data.map(category3 => {
                            return { 'value': category3, 'label': category3 };
                        });
                        this.getMFGGroup();
                    } else {
                        //  this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while request' });
                    }
                }, (error) => {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while request' });
                });
            } else {
                this.mfgCategory3 = [];
                this.manufacturerCategory3 = [];
                this.getMFGGroup();
            }
        }
    }
    getMFGGroup() {
        const defaultEvent = { rows: this.rows, first: 0 };
        this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
    }
    blankValue(num) {
        if (num === 1) {
            this.manufacturerCategory2 = [];
        }
        this.manufacturerCategory3 = [];
    }
}

