import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyOrdersComponent } from './my-orders.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
// const routes = [{ path: '', component: MyOrdersComponent }];

@NgModule({
  declarations: [MyOrdersComponent],
  imports: [
    CommonModule,
    SharedModule,
    // RouterModule.forChild(routes),
  ],
  entryComponents: [
    MyOrdersComponent,
  ],
  exports: [
    MyOrdersComponent,
  ],
})
export class MyOrdersModule { }
