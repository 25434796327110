<!-- // B2B-41 (MG 05-FEB-2020): modified to show order items summary -->
<!-- // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic -->
<div *ngIf="containerOrderLoader" class="page new-order-page reptime-theme ">
    <div style="padding: 10px; vertical-align: middle; margin: 0 auto; text-align: center;">
        <h1></h1>
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>
<div *ngIf="!containerOrderLoader" class="page new-order-page reptime-theme ">
    <p-toast position="top-right"></p-toast>
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style.width]="'425px'" appendTo="body">
    </p-confirmDialog>
    <div class="ui-g">
        <div class="ui-g-9 main-new-order-list">
            <!-- <rs-order-tile [repGroupID]="repGroupID"
                           [(customer)]="selectedCustomer"
                           [existCartData]="cartData"
                           (customerChange)="customerChange($event)"
                           (promotionsClickEvent)="promotionsClickEvent($event)"
                           (metClickEvent)="metClickEvent($event)"
                           (checkoutClickEvent)="checkoutClickEvent($event)"
                           [openSidebarStatus]="openSidebarStatus"
                           (updateExistCardEvent)="updatedCartDataEvent($event)"
                           (viewItemDetailTileEvent)="openItemDetails($event)"> -->
            <!-- this.updateExistCardEvent.emit({existCartData : this.existCartData,
                            selectedCustomer: this.selectedCustomer, itemMfgIDs: this.itemMfgIDs}); -->
            <!-- </rs-order-tile> -->
            <div class="wid-100 mb-15 quick-order-title" *ngIf='isQuickOrder'>
                <h3 class="mb-5 theme-grayclr" style="font-size: 24px; font-weight: normal;">Quick Order</h3>
                <span>Need items fast? Select your items and checkout in minutes. We will get your order processing
                    right away! </span>
            </div>
            <div class="wid-100 filter-chips-row mb-15"
                *ngIf="chipsValues && chipsValues.length && !activeRouteQP?.itemdetails">
                <strong class="mr-10" style="color:#585958">Filters Selected: </strong>
                <div>
                    <p-chips [(ngModel)]="chipsValues" field='name' styleClass="custom-chips-button"
                        (onRemove)='removeFilter($event)'></p-chips>
                </div>
            </div>
            <ng-container *ngIf="activeRouteQP && !activeRouteQP?.itemdetails">
                <rs-items-table [manufacturersArr]="manufacturersArr" [calloutData]="calloutData"
                    [repGroupID]="repGroupID" [itemMfgIDs]="itemMfgIDs" [(customer)]="selectedCustomer"
                    [selectedCustomer]="selectedCustomer" (addToCart)="addToCart($event)"
                    (customerChange)="customerChange($event)" [existCartData]="cartData"
                    (cartDataEvent)="cartDataEvent($event)" (promotionsClickEvent)="promotionsClickEvent($event)"
                    (metClickEvent)="metClickEvent($event)" (checkoutClickEvent)="checkoutClickEvent($event)"
                    (viewISC)="openItemStyleColorModal($event)" (updateExistCardEvent)="updatedCartDataEvent($event)"
                    (viewHistory)="openHistoryItem($event)" (viewItemDetailTileEvent)="openItemDetails($event)"
                    [pageType]="pageType" [whichPage]="whichPage">
                </rs-items-table>
            </ng-container>
            <ng-container *ngIf="activeRouteQP && activeRouteQP?.itemdetails">
                <app-item-details [activeRouteQP]="activeRouteQP" [itemMfgIDs]="itemMfgIDs"
                    [manufacturersArr]="manufacturersArr" [selectedCustomer]="this.selectedCustomer"
                    [existCartData]="cartData" [fromPage]="fromPage" (addToCartEvent)="cartDataEvent($event)"
                    (checkoutClickEvent)="checkoutClickEvent($event)" (metClickEvent)="metClickEvent($event)"
                    (promotionsClickEvent)="promotionsClickEvent($event)"
                    (updateExistCardEvent)="updatedCartDataEvent($event)"
                    (viewItemDetailTileEvent)="openItemDetails($event)"></app-item-details>
                <!-- <app-item-view-model [activeViewIndex]="activeViewIndex" [activeViewItem]="this.activeViewItem"
                    [itemMfgIDs]="itemMfgIDs" [manufacturersArr]="manufacturersArr" [selectedCustomer]="this.selectedCustomer"
                    [existCartData]="existCartData" [fromPage]="fromPage" (addToCartEvent)="cartDataEvent($event)">
                </app-item-view-model> -->
            </ng-container>
        </div>
        <!-- // (RM 28-02-2020): Cart details open item view details -->
        <app-cartbar *ngIf="auth.getCartStyle() === 2 && innerWidth > 1300" [confirmkey]="'new-order-confirmkey'"
            [selectedCustomer]="selectedCustomer" [existCartData]="cartData"
            (promotionsClickEvent)="promotionsClickEvent($event)" (checkoutClickEvent)="checkoutClickEvent($event)"
            (metClickEvent)="metClickEvent($event)" (updateExistCardEvent)="updatedCartDataEvent($event)"
            (viewItemDetailEvent)="openItemDetails($event)" class="hide-basket" [pageType]="pageType"
            [cartBarPosition]="cartBarPosition"></app-cartbar>
        <!-- <div class="ui-g-3 item-cart-list" style="display: none;">
            <p-card class="card sticky sticky-basket" *ngIf="cartData && cartData.manufacturer">
                <p-header class="card-header d-block">
                    <h2 class="m-0">
                        <i class="fa fa-shopping-basket "></i> Cart
                        <i *ngIf="basketSyncLoader" class="fa fa-spinner fa-spin" style="float: right;" ></i>
                    </h2>
                    <br/>
                    Search and select items to add to order and when ready click checkout to finalize.
                </p-header>
                <div class="cart-content-box" style="padding-top: 5px;padding-bottom: 5px;">
                    <p-scrollPanel [style]="{width: '100%', height: '600px'}">
                        <ng-container>
                            <div class="cart-item-list-main">
                                <div class="cart-item-img">
                                    <img src="https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28617/1001.jpg" alt="Cart Image" />
                                </div>
                                <div class="cart-item-detail">
                                    <div class="cart-item-desc one-linedesc" tooltipPosition="top" pTooltip="Stories from Hans Christian Andersen Stories from Hans Christian Andersen">Stories from Hans Christian Andersen Stories from Hans Christian Andersen</div>
                                    <div class="cart-item-num-block">
                                        <strong style="font-weight: normal;">#0-000-9</strong>
                                        <div class="flex-spacer"></div>
                                        <strong style="font-weight: normal;" class="cart-item-mfr">A-Z Manufacturers</strong>
                                    </div>
                                    <div class="cart-item-num-block" style="margin-bottom: 0px;">
                                        <div class="cart-item-qty-block">                                            
                                            <div class="cart-item-inc-block">
                                                <div class="cart-item-inc-inner">
                                                    <button pButton type="button" icon="fas fa-minus" class="cart-minus cart-btn"></button>    
                                                    <div class="cart-item-inc">10</div>
                                                    <button pButton type="button" icon="fas fa-plus" class="cart-add  cart-btn"></button>      
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-spacer"></div>
                                        <span class="cart-unit-price">$720.00</span>
                                    </div>
                                    <div class="cart-item-delete">
                                        <i class="fa fa-trash" pTooltip="Delete" tooltipPosition="top"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="cart-item-list-main">
                                <div class="cart-item-img">
                                    <img src="https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28617/1001.jpg" alt="Cart Image" />
                                </div>
                                <div class="cart-item-detail">
                                    <div class="cart-item-desc one-linedesc" tooltipPosition="top" pTooltip="Stories from Hans Christian Andersen Stories from Hans Christian Andersen">Stories from Hans Christian Andersen Stories from Hans Christian Andersen</div>
                                    <div class="cart-item-num-block">
                                        <strong style="font-weight: normal;">#0-000-9</strong>
                                        <div class="flex-spacer"></div>
                                        <strong style="font-weight: normal;" class="cart-item-mfr">A-Z Manufacturers</strong>
                                    </div>
                                    <div class="cart-item-num-block" style="margin-bottom: 0px;">
                                        <div class="cart-item-qty-block">                                            
                                            <div class="cart-item-inc-block">
                                                <div class="cart-item-inc-inner">
                                                    <button pButton type="button" icon="fas fa-minus" class="cart-minus cart-btn"></button>    
                                                    <div class="cart-item-inc">10</div>
                                                    <button pButton type="button" icon="fas fa-plus" class="cart-add  cart-btn"></button>      
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-spacer"></div>
                                        <span class="cart-unit-price">$720.00</span>
                                    </div>
                                    <div class="cart-item-delete">
                                        <i class="fa fa-trash" pTooltip="Delete" tooltipPosition="top"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="cart-item-list-main">
                                <div class="cart-item-img">
                                    <img src="https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28617/1001.jpg" alt="Cart Image" />
                                </div>
                                <div class="cart-item-detail">
                                    <div class="cart-item-desc one-linedesc" tooltipPosition="top" pTooltip="Stories from Hans Christian Andersen Stories from Hans Christian Andersen">Stories from Hans Christian Andersen Stories from Hans Christian Andersen</div>
                                    <div class="cart-item-num-block">
                                        <strong style="font-weight: normal;">#0-000-9</strong>
                                        <div class="flex-spacer"></div>
                                        <strong style="font-weight: normal;" class="cart-item-mfr">A-Z Manufacturers</strong>
                                    </div>
                                    <div class="cart-item-num-block" style="margin-bottom: 0px;">
                                        <div class="cart-item-qty-block">                                            
                                            <div class="cart-item-inc-block">
                                                <div class="cart-item-inc-inner">
                                                    <button pButton type="button" icon="fas fa-minus" class="cart-minus cart-btn"></button>    
                                                    <div class="cart-item-inc">10</div>
                                                    <button pButton type="button" icon="fas fa-plus" class="cart-add  cart-btn"></button>      
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-spacer"></div>
                                        <span class="cart-unit-price">$720.00</span>
                                    </div>
                                    <div class="cart-item-delete">
                                        <i class="fa fa-trash" pTooltip="Delete" tooltipPosition="top"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="cart-item-list-main">
                                <div class="cart-item-img">
                                    <img src="https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28617/1001.jpg" alt="Cart Image" />
                                </div>
                                <div class="cart-item-detail">
                                    <div class="cart-item-desc one-linedesc" tooltipPosition="top" pTooltip="Stories from Hans Christian Andersen Stories from Hans Christian Andersen">Stories from Hans Christian Andersen Stories from Hans Christian Andersen</div>
                                    <div class="cart-item-num-block">
                                        <strong style="font-weight: normal;">#0-000-9</strong>
                                        <div class="flex-spacer"></div>
                                        <strong style="font-weight: normal;" class="cart-item-mfr">A-Z Manufacturers</strong>
                                    </div>
                                    <div class="cart-item-num-block" style="margin-bottom: 0px;">
                                        <div class="cart-item-qty-block">                                            
                                            <div class="cart-item-inc-block">
                                                <div class="cart-item-inc-inner">
                                                    <button pButton type="button" icon="fas fa-minus" class="cart-minus cart-btn"></button>    
                                                    <div class="cart-item-inc">10</div>
                                                    <button pButton type="button" icon="fas fa-plus" class="cart-add  cart-btn"></button>      
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-spacer"></div>
                                        <span class="cart-unit-price">$720.00</span>
                                    </div>
                                    <div class="cart-item-delete">
                                        <i class="fa fa-trash" pTooltip="Delete" tooltipPosition="top"></i>
                                    </div>
                                </div>
                            </div>
                            
                        </ng-container>
                        <ng-container *ngFor="let mfg of cartData.manufacturer;let i = index">
                            <div class="p-grid p-justify-between" style="font-size: 16px">
                                <div class="p-col-12 p-2">
                                    <div>PO#{{i + 1}}:<strong> {{mfg.name}}</strong></div>
                                </div>
                            </div>
                            <div class="p-grid p-justify-between" style="font-size: 16px">
                                <div class="p-col-6 p-2">
                                    <div>Ship: As Ready</div>
                                </div>
                                <div class="p-col-6 p-0">
                                    <div class="pull-right">${{(mfg.discountedTotal ? mfg.discountedTotal : 0) | number : '1.2-2'}}</div>
                                </div>
                            </div>
                            <div class="p-grid p-justify-between">
                                <div class="p-col-6 p-2">
                                    <div class="cart-content-link m-0" style="color:#628292;font-size: 14px"
                                         (click)="openItemsModal(mfg.name, mfg.items, mfg.total)">
                                        <a href="javascript:void(0);">View Items</a></div>
                                </div>
                                <div class="p-col-6 p-0">
                                    <div class="pull-right">{{mfg.quantity}} items</div>
                                </div>
                            </div>
                        </ng-container>
                    </p-scrollPanel>
                </div>
                <p-footer class="card-footer">
                    <div class="wid-100 d-flex justify-content-between p-5">
                        <button class="ui-button-info btn-theme-color" pButton type="button" label="Promotions"
                                icon="fa fa-fw fa-commenting" (click)="openSpecialModal()"></button>
                        <button class="ui-button-info btn-theme-color"
                                [ngClass]="{'no-min-met': checkMinimumMet(cartData.manufacturer)}"
                                pButton type="button" label=" Minimum Met"
                                (click)="openMimMetModal()"
                                icon="fa fa-fw fa-exclamation"></button>
                    </div>
                    <div class="m-5 d-flex justify-content-between align-items-center">
                        <h2 class="mb-0"><strong>{{cartData.totalItems}} Items/${{(cartData.cartDiscountedTotal ?
                            cartData.cartDiscountedTotal : 0) | number : '1.2-2'}}</strong></h2>
                        <button pButton type="button" label=" CHECKOUT NOW" class="ui-button-success theme-maroon-btn" icon="fa fa-fw fa-shopping-basket"
                                [disabled]="basketSyncLoader || !cartData.cartTotal || !selectedCustomer || (selectedCustomer && !selectedCustomer.selectedShipTo)"
                                (click)="checkout()"></button>
                    </div>
                </p-footer>
            </p-card>
        </div> -->
    </div>
    <p-dialog header="Item already exist in basket." [modal]="true" [draggable]="false" [blockScroll]="true"
        [(visible)]="isItemAlreadyAvailable" [resizable]="false">
        <div class="p-5">Selected item already on order. Please update from basket.</div>
        <div class="text-center m-5">
            <button (click)="isItemAlreadyAvailable = false" [label]="'OK'" class="ui-button-raised" pButton
                type="button"></button>
        </div>
    </p-dialog>
    <!--<rs-add-retailer [(isOpen)]="isNewRetailerWizard"-->
    <!--(loadListEvent)="lazyLoadFunction($event)"-->
    <!--(saveEvent)="lazyLoadFunction($event)"-->
    <!--[repGroupID]="repGroupID"></rs-add-retailer>-->
</div>
<!-- // (RM 28-02-2020): Cart details open item view details -->
<app-item-view-model [activeViewIndex]="activeViewIndex" [activeViewItem]="this.activeViewItem"
    [itemMfgIDs]="itemMfgIDs" [manufacturersArr]="manufacturersArr" [selectedCustomer]="this.selectedCustomer"
    [existCartData]="existCartData" [fromPage]="fromPage" (addToCartEvent)="cartDataEvent($event)"
    [(isOpen)]="displayItemViewDialog" *ngIf="displayItemViewDialog">
</app-item-view-model>