import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  Router, ActivatedRoute, NavigationEnd, NavigationStart, GuardsCheckEnd, GuardsCheckStart,
  RouterPreloader, ActivationEnd, ResolveEnd
} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APIResponse } from '../../../models/api-response';
import { DataService } from 'src/app/services/other/data/data.service';
import {  MessageService, SelectItem, DialogService } from 'primeng/api';
import { HtmlToolsService } from '../../../services/html-tools/html-tools.service';
import { RetailerAPIService } from '../../../services/api/retailer-api/retailer-api.service';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { ManufacturerShipToLocation } from 'src/app/models/manufacturer-ship-to-location';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { RetailerService } from 'src/app/services/middleware/retailer/retailer.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
@Component({
  selector: 'app-shipping-location-not-found',
  templateUrl: './shipping-location-not-found.component.html',
  styleUrls: ['./shipping-location-not-found.component.scss']
})
export class ShippingLocationNotFoundComponent implements OnInit, OnDestroy {
  selectedShipToLocation: any = {};
  retailerID: any;
  public shipToLocationForm: FormGroup;
  public countries: any[];
  public states: any[];
  saveLoading = false;
  primaryOptions: SelectItem[];

  searchForm: any;
  loading = true;
  private readonly onDestroy = new Subject<void>();
  shipToLocations: Array<ManufacturerShipToLocation> = [];
  retailerObj: any;
  copyBillToAddress = false;
  getRetailer: any;
  countriesStateJSON: any;
  private readonly destroyZipCodeList = new Subject<void>();
  constructor(
    private router: Router,
    public dialogService: DialogService,
    private dataService: DataService,
    private msgService: MessageService,
    private htmlTools: HtmlToolsService,
    private fb: FormBuilder,
    private retailerAPIService: RetailerAPIService,
    private rtSharedService: RTSharedService,
    public auth: AuthService,
    private retailerService: RetailerService,
  ) {
    this.retailerID = this.rtSharedService.getRetailerID();
    // if (sessionStorage.getItem('username') === null || sessionStorage.getItem('username') === undefined || sessionStorage.getItem('username') === '' ) {
    //   this.router.navigate(['/login']);
    // }
  }

  ngOnInit() {
    console.log('588888888');
    
    this.initForm();
    this.primaryOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false, disabled: true }];
    console.log('retailerID', this.retailerID);
    this.loadShipToLocations();
  }

  ngOnDestroy() {
    this.destroyZipCodeList.next();
  }

  async loadShipToLocations() {

    const getAuthRetailer = this.auth.getAuthRetailer.value;
    if (getAuthRetailer && getAuthRetailer.loggedInRetailerID) {
      try {
        this.getRetailer = await this.retailerService
          .getRetailerData(getAuthRetailer.loggedInRetailerID + '?companyID=' + getAuthRetailer.loggedInRetailerID);

        if (this.getRetailer.shipToLocations.length > 0) {
          this.router.navigate(['/home']);
        }
      } catch (e) {
        this.auth.removeAllToken();
        //this.router.navigate(['/login']);
        this.auth.moveToAfterLogin();
        return false;
      }
    }
  }

  countrySpecific() {
    if (this.validateChk.country.value === 'US') {
      this.shipToLocationForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
      this.shipToLocationForm.get('zip').updateValueAndValidity();
    } else {
      this.validateChk.zip.setValue('');
      this.validateChk.city.setValue('');
      this.validateChk.state.setValue('');
      this.shipToLocationForm.get('zip').clearValidators();
      this.shipToLocationForm.get('zip').updateValueAndValidity();
    }
    this.updateChange();
  }

  get validateChk() {
    // Convenience getter for easy access to form fields
    return this.shipToLocationForm.controls;
  }

  updateChange() {
    const zipcode = this.validateChk.zip.value.trim();
    if (zipcode.length >= 5) {

      const searchParams: any = {};
      let size = 3000;
      let offset = 0;
      let sort = 'country';
      let sortOrder = 1;
      searchParams.country = this.validateChk.country.value;
      searchParams.zipCode = this.validateChk.zip.value.trim();

      this.destroyZipCodeList.next();
      this.dataService.getZipCodeList(offset, size, sort, sortOrder, searchParams)
      .pipe(takeUntil(this.destroyZipCodeList)).subscribe((res: APIResponse) => {
          if (res.success) {
            if (res.data[0]) {
              this.validateChk.state.setValue(res.data[0].stateAbbreviation);
              this.validateChk.city.setValue(res.data[0].city);
            } else {
              this.validateChk.state.setValue('');
              this.validateChk.city.setValue('');
            }
          } else {
            this.validateChk.state.setValue('');
            this.validateChk.city.setValue('');
          }
        }, () => {
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });
    }
  }

  initForm() {
    this.shipToLocationForm = this.fb.group({
      name: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.maxLength(20)]],
      country: [environment.countryAbrv, Validators.required],
      phone: [''],
      phoneExtension: [''],
      fax: ['', Validators.pattern('^[0-9]*$')],
      faxExtension: [''],
      email: [''],
      notes: [''],
      isPrimary: [true]
    });
    this.dataService.getCountryList().subscribe((data: any[]) => {
      this.countries = data;
    });
    // this.dataService.getStateList().subscribe((data: any[]) => {
    //   this.states = data;
    // });
    this.dataService.setCountriesState().then(res => {
      this.countriesStateJSON = res;
    });
    if (this.validateChk.country.value === 'US') {
      this.shipToLocationForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
      this.shipToLocationForm.get('zip').updateValueAndValidity();
    } else {
      this.shipToLocationForm.get('zip').clearValidators();
      this.shipToLocationForm.get('zip').updateValueAndValidity();
    }
  }

  getErrorMsg(field, name) {
    if (field.errors.required) {
      return name + ' is required';
    }
    if (field.errors.email) {
      return 'Enter valid email';
    }
  }

  saveSelectedShipTo() {
    if (this.shipToLocationForm.valid) {
      this.setObjectFromForm();
      this.saveRetailerShipToLocation();
    } else {
      this.htmlTools.validateAllFields(this.shipToLocationForm);
    }
  }

  private setObjectFromForm() {
    _.forEach(this.shipToLocationForm.controls, (key, value) => {
      this.selectedShipToLocation[value] = this.shipToLocationForm.controls[value].value;
    });
  }

  saveRetailerShipToLocation() {
    this.saveLoading = true;
    this.retailerAPIService.saveRetailerShipTo(this.retailerID, this.selectedShipToLocation)
      .subscribe((data: any) => {
        this.saveLoading = false;
        if (data) {
          const sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
          // if (sessionLocationId.recordID === data.recordID) {
          this.rtSharedService.setSelectedShipTo(data);
          // }
          const updateData: any = { isOrderOnly: false, data };
          // this.ref.close(updateData);
          this.router.navigate(['/home']);
        } else {
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error saving ship to location' });
        }
      });
  }

  setFormFromObject() {
    this.shipToLocationForm.reset();
    this.shipToLocationForm.patchValue(this.selectedShipToLocation);
    // if (!this.selectedShipToLocation.recordID) {
    //     this.shipToLocationForm.patchValue({ country: 'US' });
    //     this.shipToLocationForm.patchValue({ isPrimary: false });
    // }
    if (this.validateChk.country.value === 'US') {
      this.shipToLocationForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
      this.shipToLocationForm.get('zip').updateValueAndValidity();
    } else {
      this.shipToLocationForm.get('zip').clearValidators();
      this.shipToLocationForm.get('zip').updateValueAndValidity();
    }
  }



  copyAddress() {
    const patchObj: any = {
      name: '',
      address1: '',
      address2: '',
      country: '',
      zip: '',
      state: '',
      city: '',
      phone: '',
      fax: '',
      email: '',
    };
    if (this.copyBillToAddress) {
      patchObj.name = this.getRetailer.name;
      patchObj.address1 = this.getRetailer.address1;
      patchObj.address2 = this.getRetailer.address2;
      patchObj.country = this.getRetailer.country;
      patchObj.zip = this.getRetailer.zip;
      patchObj.state = this.getRetailer.state;
      patchObj.city = this.getRetailer.city;
      patchObj.phone = this.getRetailer.phone;
      patchObj.fax = this.getRetailer.fax;
      patchObj.email = this.getRetailer.email;
      this.shipToLocationForm.patchValue(patchObj);
    } else {
      this.shipToLocationForm.reset();
      this.initForm();
    }
}


}
