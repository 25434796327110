import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PhoneNumberPipe } from 'src/app/pipes/phone-number.pipe';

@Component({
  selector: 'app-lightovation-footer',
  templateUrl: './lightovation-footer.component.html',
  styleUrls: ['./lightovation-footer.component.scss'],
  providers: [
    PhoneNumberPipe
],
})
export class LightovationFooterComponent implements OnInit {
  @Input() domainData: any;
  @Input() logo: any;
  footerData: any;
  constructor(private sanitizer: DomSanitizer, private phone: PhoneNumberPipe) { }

  ngOnInit() {
    console.log(this.domainData);
    if (this.domainData && this.domainData.repGroup) {
      this.footerData = this.domainData.repGroup;
    }
    // this.domainData.repGroup.phone = '607-687-4724';
    if (this.domainData.repGroup.phone) {
        const phone = this.domainData.repGroup.phone.replace(/-/g,"");
        this.footerData.phone = phone;
    }
    const rg = this.domainData.repGroup;
    if (rg) {
      this.domainData.rgName = rg.name;
      this.domainData.rgWebsite = rg.website;
      console.log('website>>>>>>>', this.domainData.rgWebsite);
      const rgAddress: any[] = [];
      if (rg.address1) {
        rgAddress.push(rg.address1);
      }
      if (rg.city) {
        rgAddress.push(rg.city);
      }
      if (rg.state) {
        rgAddress.push(rg.state);
      }
      if (rg.country) {
        rgAddress.push(rg.country);
      }
      if (rg.zip) {
        rgAddress.push(rg.zip);
      }
      this.domainData.rgAddress = rgAddress.join(', ');
      const rgContact: any[] = [];
      if (rg.phone) {
        rgContact.push('Tel. ' + this.phone.transform(rg.phone));
        this.domainData.rgPhone = rg.phone;
      }
      if (rg.fax) {
        rgContact.push('Fax. ' + this.phone.transform(rg.fax));
        this.domainData.rgFax = rg.fax;
      }
      if (rg.email) {
        // rgContact.push(rg.email);
        this.domainData.rgEmail = rg.email;
      }
      this.domainData.rgContact = rgContact.join('  •  ');
    }
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl('fax:' + url);
  }

}
