import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { ManufacturerService } from 'src/app/services/api/manufacturer.service';
import { SelectItem, MessageService } from 'primeng/api';
import { Tooltip } from 'primeng/primeng';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import { CompanyService } from 'src/app/services/middleware/company/company.service';
import { environment } from 'src/environments/environment';
import { finalize, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-document-table',
    templateUrl: './document-table.component.html',
    styleUrls: ['./document-table.component.scss']
})
export class DocumentTableComponent implements OnInit {
    public navigatedURL: any;
    manufacturerID: any;
    selectedManufacturerArray: any;
    coverImgPreview: any;
    logoPreview: any;
    showLoader = true;
    showCoverImg = false;
    showLogoImg = false;
    showTable: boolean;
    documentsTableCols: any[];
    documentsTableValue: any[];
    loadingTableData = true;
    tableEvent = { rows: 50, first: 0 };
    docs = [];
    totalDocuments = -1;
    isSubmitLoading = false;
    isResetLoading = false;
    public domainData: any = (window as any).domainData;
    @ViewChildren('copyLinks', { read: Tooltip }) copyLinks;
    @ViewChildren('copyLinksGrid', { read: Tooltip }) copyLinksGrid;
    @Output() getData?= new EventEmitter<any>();
    @Input() notMfgIdFoundSidebar = true;
    @Input() fromPage: any;
    @Input() isShowFolder: any = false;
    catalogManufacture: SelectItem[];
    catalogCallout: SelectItem[];

    // [B2B-121] (RM 03-03-2020): pdf viewer
    public pdfSrc = '';
    showPdfDialog = false;
    showPdfDialogLoader = true;
    dialogHeight: any;
    pdfHeight: any;
    selectedManufactureID = '';
    selectedPdfName = '';
    errorMsg = false;
    pdfViewPercent: any;
    private readonly destroyMFRDocData = new Subject<void>();
    isLogin = false;
    public itemSearchForm: FormGroup;
    finaldocumentsTableValue: any[];
    searchCatlog = '';
    searchText = '';
    placeholderForSearchBar = 'Search by Catalogs';
    searchFormData: any;
    public advanceOptions: any[];
    public dateRangeOptions: any[];
    static blobDownloadHelper(data, fileName) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
    }
    openDigitalCatalog = false;
    catalogData: any;

    fileElements: any[] = [];
    canNavigateUp = false;
    prevArray: any = [];
    fileManagment: any[] = [];
    backArrowDisable = true;
    rootFileStore: any[] =[];
    docFolderImage = environment.documetFolderImage;
    manufacturerName = '';
    private readonly onDestroyGetDocumentByFolder = new Subject<void>();
    showFolderLoader = false;

    constructor(
        private router: Router,
        public auth: AuthService,
        private manufacturerService: ManufacturerService,
        private activeRoute: ActivatedRoute,
        private rtSharedService: RTSharedService,
        public companyService: CompanyService,
        public msgService: MessageService,
        private formBuilder: FormBuilder,
    ) {
        this.isLogin = this.auth.getIDToken();
        this.selectedManufactureID = this.activeRoute.snapshot.queryParams.mfg;
        this.docFolderImage = this.docFolderImage + this.selectedManufactureID;
        this.showTable = true;

        this.advanceOptions = [
            { label: 'All', value: { searchByName: 'all', key: 'all' } },
            { label: 'Name', value: { searchByName: 'name', key: 'name' } },
            { label: 'Description ', value: { searchByName: 'description', key: 'description' } },
            { label: 'Item #', value: { searchByName: 'item number', key: 'itemNumber' } },
            { label: 'UPC1', value: { searchByName: 'UPC1', key: 'upc' } },
            { label: 'UPC2', value: { searchByName: 'UPC2', key: 'upc2' } },
            { label: 'UPC3', value: { searchByName: 'UPC3', key: 'upc3' } }
        ];

        this.dateRangeOptions = [
            { label: 'Order Date From', value: { key: 'dateModifiedFrom', type: 'startDate' } },
            { label: 'Order Date to', value: { key: 'dateModifiedTo', type: 'endDate' } }
        ];
        this.catalogCallout = [
            { label: 'All Callouts', value: 'All Callouts' },
            { label: 'Callout1', value: 'Callout' },
            { label: 'Callout2', value: 'Callout' },
            { label: 'Callout3', value: 'Callout' },
            { label: 'Callout4', value: 'Callout' },
            { label: 'Callout5', value: 'Callout' }
        ];
        this.catalogManufacture = [
            { label: 'All Manufacturers', value: '' }
        ];
        // [B2B-53] (RM 03-03-2020): get manufacture list
        this.getManufactureData();
    }

    ngOnDestroy() {
        this.destroyMFRDocData.next();
        this.getData.emit();
    }

    async ngOnInit() {
        this.documentsTableCols = [
            { field: 'manufacturerName', header: 'Name', width: '200px' },
            { field: 'name', header: 'File Name', width: '200px' },
            { field: 'description', header: 'Description', width: '200px' },
            { field: 'fileSize', header: 'Size', width: '150px' },
            { field: 'documentType', header: 'Type', width: '180px' },
            // { field: 'email', header: 'Email', width: 'auto' },
            // { field: 'phone', header: 'Phone', width: 'auto' },
            // { field: 'dateAdded', header: 'Date Added', width: 'auto' },
            // { field: 'dateModified', header: 'Date Modified', width: 'auto' },
        ];
        // [B2B-121] (RM 03-03-2020): pdf viewer dialog height set
        this.dialogHeight = `${window.innerHeight - 85}px`;
        this.pdfHeight = `${window.innerHeight - 165}px`;

        this.itemSearchForm = this.formBuilder.group({
            txtItemSearch: ['', Validators.required]
        });
        if(this.isShowFolder){
            this.showFolderLoader = true;
            this.updateTable(this.tableEvent);
            setTimeout(() => {
                this.getFiles(this.selectedManufactureID);
            }, 1000);
            this.backArrowDisable = false;
        }
       
    }

    displayTable() {
        this.showTable = false;
    }

    displayGrid() {
        this.showTable = true;
    }

    // [B2B-109] (MG-27-FEB-2020) modified to show MFR logo when no thumb
    updateTable(e) {
        this.loadingTableData = true;
        this.tableEvent = e;
        const rg: any = this.domainData.repGroup;
        const searchParams: any = {
            searchType: 'startswith',
            repGroupID: rg && rg.recordID ? rg.recordID : '',
            showOnWebsite: 1,
            all: this.searchCatlog,
            documentFetchFrom: 'RPG',
            filterByTerritory: this.auth.getRetailerTerritoryFilterFlag(),
            retailerShipToLocationID: (JSON.parse(sessionStorage.getItem
                ('retailerShipToLocationID')).recordID) ? JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID : null
        };
        // [B2B-53] (RM 03-03-2020): filter manufacture ID
        if (this.selectedManufactureID) {
            Object.assign(searchParams, { manufacturerID: this.selectedManufactureID });
        }
        const offset = (e.first === undefined) ? 0 : e.first;
        const size = (e.rows === undefined) ? 50 : e.rows;
        const sort = (e.sortField === undefined) ? 'name' : e.sortField;
        const sortOrder = (e.sortOrder === undefined) ? 1 : e.sortOrder;
        console.log('isShowFolder', this.isShowFolder);

        const isRootFile = this.isShowFolder ? this.isShowFolder : false;

        const queyParams = { offset, size, sort, sortOrder, searchType: 'startswith', isRootFile };
        if (this.fromPage && this.fromPage === 'digital-catalog') {
            searchParams.documentType = 'Digital Book';
        }
        this.destroyMFRDocData.next();
        this.companyService.getMFRDocumentsData(searchParams, queyParams).pipe(takeUntil(this.destroyMFRDocData)).subscribe((response: any) => {
            if (response.success) {
                console.log('getMFRDocumentsData', response);
                response.data.forEach(async (d: any) => {
                    const mfrImage = environment.logoBucketUrl + 'logo/' + d.companyID + '.png';
                    const thumb = environment.mfgDocBucketUrl + d.companyID + '/' + d.thumbnailImage;
                    d.imageError = false;
                    d.imageLoader = true;
                    d.fullPicturePath = (d.thumbnailImage) ? thumb : mfrImage;
                    d.viewAllowed = await this.checkPdfFile(d.fileNamePath);
                });
                this.documentsTableValue = response.data;
                console.log(this.documentsTableValue);
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 0);
                this.finaldocumentsTableValue = this.documentsTableValue;
                this.totalDocuments = response.total;
                this.rootFileStore = response.data;
                console.log('this.rootFileStore', this.rootFileStore);
            } else {
                this.totalDocuments = 0;
                this.msgService.add({
                    severity: 'error', summary: 'Error',
                    detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                });
            }
            this.loadingTableData = false;
            this.isSubmitLoading = false;
            this.isResetLoading = false;
        }, error => {
            console.log('ERROR:', error);
            this.loadingTableData = false;
            this.isSubmitLoading = false;
            this.isResetLoading = false;
            this.totalDocuments = 0;
            this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while updating table' });
        });
    }

    copyDownloadUrl(fileHash, recordId, companyID, view) {
        // Create invisible text area to copy link to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        // selBox.value = this.urlTools.addTokens(this.manufacturerService.getDocUrl, {fileHash: fileHash, companyID: companyID});
        selBox.value = this.companyService.getDocURL({ fileHash, companyID });
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        // Show tooltip to notify user that link was copied
        if (view === 'table') {
            this.copyLinks.forEach((child) => {
                if (child.el.nativeElement.id === 'copy-link-' + recordId) {
                    child.deactivate();
                    child.activate();
                }
            });
        } else {
            this.copyLinksGrid.forEach((child) => {
                if (child.el.nativeElement.id === 'copy-grid-link-' + recordId) {
                    child.deactivate();
                    child.activate();
                }
            });
        }
    }

    downloadDoc(fileHash, fileName, companyID) {
        this.companyService.downloadDoc(fileHash, companyID).pipe(finalize(() => {
            //
        })).subscribe(data => {
            DocumentTableComponent.blobDownloadHelper(data, fileName);
            const url = window.URL.createObjectURL(new Blob([data]));
            this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Item catalog is downloading' });
        }, error => {
            this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during request' });
        });
    }

    // [B2B-121] (RM 03-03-2020): Open pdf file
    async openPdfDialog(fileHash, fileName, companyID) {
        this.errorMsg = false;
        this.pdfViewPercent = 0;
        const rand = Math.floor(Math.random() * 20) + 1;
        this.pdfSrc = await this.companyService.getDocURL({ fileHash, companyID }) + '?time=' + rand + 5;
        this.showPdfDialog = true;
        if (this.selectedPdfName !== fileName) {
            this.showPdfDialogLoader = true;
            this.selectedPdfName = fileName;
        }
        console.log('check >>>>>>>> fir', this.showPdfDialogLoader);
    }
    // [B2B-121] (RM 03-03-2020): check extenstion validation
    async checkPdfFile(fileName) {
        const allowExtension = ['pdf'];
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
        return _.includes(allowExtension, ext);
    }
    // [B2B-121] (RM 03-03-2020): pdf viewer events
    async callBackFnPdfViewer($event) {
        // do anything with "pdf"
        console.log('check >>>>>>>>', $event, this.showPdfDialogLoader);
        this.showPdfDialogLoader = false;
    }
    // [B2B-121] (RM 03-03-2020): pdf viewer events
    async onProgressPdfViewer($event) {
        // do anything with progress data. For example progress indicator
        // RC-766 (MJ 13-APRIL-2020) Error message and View loading percentage added
        this.showPdfDialogLoader = true;
        // tslint:disable-next-line: prefer-const
        var percent = $event.loaded / $event.total * 100;
        this.pdfViewPercent = Math.round(percent);
        console.log($event);
    }

    // [B2B-53] (RM 03-03-2020): Get all manufacture list and set dropdown filter
    async getManufactureData() {
        console.log('getManufactureData');
        this.manufacturerService.getManufacturersByRepGroup().subscribe(async (res) => {
            console.log('Response >>', res);
            if (this.rtSharedService.getProjectName() === 'B2B') {
                const manufacturersArr = res.data.filter(o => {
                    if (o.repGroupManufacturers && o.repGroupManufacturers.length) {
                        if (o.repGroupManufacturers[0].showOnWebsite === true) {
                            return { recordID: o.recordID, name: o.name };
                        }
                    }
                });
                console.log(manufacturersArr);

                const catalogManufacture = _.chain(manufacturersArr).map((o, i) => {
                    return {
                        label: o.name,
                        value: o.recordID,
                        fullPicturePath: environment.logoBucketUrl + 'logo/' + o.recordID + '.png',
                        minimumOrderAmount: o.minimumOrderAmount
                    };
                }).value();
                catalogManufacture.forEach(o => {
                    this.catalogManufacture.push(o);
                });
                console.log('catalog', this.catalogManufacture);
            }
        });
    }
    // RC-766 (MJ 13-APRIL-2020) Error message and View loading percentage added
    onError(error: any) {
        this.errorMsg = true;
    }


    search($event?: any): any {
        this.isSubmitLoading = true;
        this.searchFormData = _.cloneDeep($event);
        this.searchCatlog = $event.all;
        const defaultEvent = { rows: 50, first: 0 };
        this.updateTable(defaultEvent);
    }

    reset($event: any): any {
        this.searchFormData = _.cloneDeep($event);
        this.isResetLoading = true;
        const defaultEvent = { rows: 50, first: 0 };
        this.searchCatlog = '';
        this.updateTable(defaultEvent);
    }

    goToShoppingPage() {
        const mfg = {
            mfg: this.selectedManufactureID
        };
        if (this.isLogin) {
            this.router.navigate(['/shop'], { queryParams: mfg });
        } else {
            this.router.navigate(['/store'], { queryParams: mfg });
        }
    }
    gotoHome() {
        this.router.navigate(['/']);
    }

    getClass(catalog) {
        if (catalog.documentType === 'Digital Book') {
            if (this.fromPage && this.fromPage === 'digital-catalog') {
                return 'pdf-digital-full';
            } else {
                return 'pdf-digital';
            }
        } else if (catalog.viewAllowed) {
            return 'pdf-option';
        } else {
            return '';
        }
    }

    openDigitalDialog(catalog) {
        this.openDigitalCatalog = true;
        this.catalogData = _.cloneDeep(catalog);
    }

    closeDialog(event) {
        this.openDigitalCatalog = event;
    }

    getFiles(mfgID) {
        this.showFolderLoader = true;
        this.onDestroyGetDocumentByFolder.next();
        let repGroupID = this.domainData.repGroup.recordID;
        this.manufacturerService.getDocumentByFolder(mfgID, repGroupID).pipe(takeUntil(this.onDestroyGetDocumentByFolder)).subscribe((response: any) => {
            if (response && response.success) {
                this.fileElements = response.data;
                this.fileElements = this.fileElements.concat(this.rootFileStore);
                console.log('root', this.fileElements);
                this.fileManagment = this.fileElements;
                this.flatten(this.fileElements);
                this.showFolderLoader = false;
            } else {
                this.fileElements = this.rootFileStore;
                this.fileManagment = this.fileElements;
                this.flatten(this.fileElements);
                this.showFolderLoader = false;
                console.log('root', this.fileElements);
            }
        }, (error) => {
            this.showFolderLoader = false;
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
        });
    }

    flatten(ary) {
        for (let item of ary) {
            item = DocumentTableComponent.buildNode(item);
            if (item.type === 'folder') {
                this.manufacturerName = item.manufacturer.name;
            }
            if (item.type === 'file' && !item.manufacturerName) {
                item['manufacturerName'] = this.manufacturerName;
            }
            if (item.subFolders !== undefined) {
                item.children = item.subFolders.concat(item.files);
                this.flatten(item.children);
            }
        }
    }

    static buildNode(company) {
        console.log('nnnnnnnnnn');
        let findType = company.fileNamePath;
        if (findType) {
            company.icon = "pi pi-file";
            const mfrImage = environment.logoBucketUrl + 'logo/' + company.companyID + '.png';
            const thumb = environment.mfgDocBucketUrl + company.companyID + '/' + company.thumbnailImage;
            company.imageError = false;
            company.imageLoader = true;
            company.fullPicturePath = (company.thumbnailImage) ? thumb : mfrImage;
            company.viewAllowed = _.includes(['pdf'], company.fileNamePath.substr(company.fileNamePath.lastIndexOf('.') + 1));
            
        } else {
            company.expandedIcon = "pi pi-folder-open";
            company.collapsedIcon = "pi pi-folder";
        }
        company.type = findType ? 'file' : 'folder';
        return company;
    }

    navigateIn(element: any, i: number) {
        console.log('in', element, this.fileElements);
        if (element.type === "folder") {
            this.queryInFolder(element, 'navigateIn');
            this.prevArray.push({
                index: i,
                folderName: element.name
            });
            console.log('this.prevArray', this.prevArray);
        }
    }

    navigateOut() {
        this.prevArray.splice(-1);
        let currentVal: any;
        _.forEach(this.prevArray, (i: any, j: number) => {
            if (j === 0) {
                currentVal = this.fileElements[i.index];
            } else {
                currentVal = currentVal['children'][i.index];
            }
        });
        console.log('currentVal', currentVal);
        this.queryInFolder(currentVal, 'navigateOut');
    }
    goToSelectedFolder(element: any, i: any) {
        console.log();
        this.prevArray = this.prevArray.slice(0, (i + 1));
        let currentVal: any;
        _.forEach(this.prevArray, (i: any, j: number) => {
            if (j === 0) {
                currentVal = this.fileElements[i.index];
            } else {
                currentVal = currentVal['children'][i.index];
            }
        });
        console.log('currentVal', currentVal);
        this.queryInFolder(currentVal, 'navigateOut');

    }
    queryInFolder(folderDetails: any, event: string) {
        let result = [];
        console.log('folderDetails', folderDetails);
        if (folderDetails === undefined) {
            result = this.fileElements;
            this.backArrowDisable = true;
        } else {
            if (folderDetails.children && folderDetails.children.length) {
                folderDetails.children.forEach((item: any) => {
                    result.push(item);
                });
            }
            this.backArrowDisable = false;
        }
        this.fileManagment = result;
        console.log('result', this.fileManagment);
    }
}
