<p-dialog [(visible)]="visible" [contentStyle]="{'width': '450px'}" [maximizable]="false" [blockScroll]="true"
    appendTo="body" [closeOnEscape]="true" header="Select Image Size" (onHide)="hide.emit(false)" [modal]="true"  [resizable]="false">
    <div class="p-grid center">
        <div class="p-col-12">
            <div class="ui-g-12" *ngFor="let list of sizeList" style="text-align: left;">
                <p-radioButton [disabled]="isLoading" value="{{list.value}}" label="{{list.title}}"
                    [(ngModel)]="selectedsize">
                </p-radioButton>
            </div>

        </div>
        <div class="p-col-12 " style="text-align: right;">
            <button (click)="downloadfile()" [disabled]="isLoading" type="button" icon="fa fa-download ui-button-icon-left"
                class="ui-button ui-button-success theme-maroon-btn" pButton label="Download Zip"></button>
        </div>
    </div>
</p-dialog>