import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ein-number-dialog',
  templateUrl: './ein-number-dialog.component.html',
  styleUrls: ['./ein-number-dialog.component.scss']
})
export class EinNumberDialogComponent implements OnInit {
  @Input() federalTaxNumber = false;
  @Output() closeFederalTaxDialog = new EventEmitter();
  constructor(private router: Router) { }

  ngOnInit() {
    console.log('Rahul....federalTaxNumber', this.federalTaxNumber);
  }
  clickOkToRedirctMange() {
    this.federalTaxNumber = false;
    this.router.navigate(['/manage-company']);
    this.closeFederalTaxDialog.emit(true);
  }
  cancel() {
    this.closeFederalTaxDialog.emit(false);
    this.federalTaxNumber = false;
    sessionStorage.setItem('federalTaxNumbersession', 'true');
  }
}
