<div class="reptime-theme browse-item-tbl">
    <p-card class="card" *ngIf="!config.data.itemsHistory">
        <!--  B2B-63 (NL 31-01-2020) add table footer part-->
        <p-table id="items-table" [columns]="cols" [value]="items" dataKey="recordID"
                 #itemListingTable [totalRecords]="items.length" [paginator]="false"
                 [responsive]="false" [rowsPerPageOptions]="[10, 20, 50, 100]" [rows]="10">

            <ng-template pTemplate="paginatorright">
                <div class="per-page-text">Per page :</div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <div class="per-page-text px-10">Total :</div>
                {{items.length}}
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" [style.width]="col.width">
                        {{col.header}}
                        <!--<p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>-->
                    </th>
                    <th style="width: 50px"></th>
                </tr>
            </ng-template>
            <!-- B2B-41 (MG 05-FEB-2020): modified to show order items summary -->
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchCase="'itemnumber'">
                                <a style="color:#333;"> {{rowData[col.field]}}</a>
                            </div>
                            <div *ngSwitchCase="'name'" class="Bold">
                                <a style="color:#333;"> {{rowData[col.field]}} </a>
                            </div>
                            <div *ngSwitchCase="'minimumQuantity'">
                                {{(rowData[col.field] ? rowData[col.field] : null) | number}}
                            </div>
                            <div *ngSwitchCase="'unitPrice'">
                                {{ ((rowData.selectedPriceCode) ? getUnitPrice(rowData) : items[rowIndex].item.unitPrice) | number: '1.2-2' }}
                            </div>
                            <div *ngSwitchCase="'itemTotal'">
                                <!-- {{ ((rowData.selectedPriceCode) ? getUnitPrice(rowData) : items[rowIndex].item.unitPrice) | number: '1.2-2' }} -->
                                {{ items[rowIndex].discountedTotal | number: '1.2-2' }}
                            </div>
                            <div *ngSwitchCase="'upc1'">
                                <a style="color:#333;">{{rowData[col.field]}}</a>
                            </div>
                            <div *ngSwitchCase="'isavailable'" style="text-align:center;">
                                <i *ngIf="rowData[col.field] === 1" class="fa fa-check green"></i>
                                <div *ngIf="rowData[col.field] === 0 && rowData['nextavailabledate'] !== null">
                                    {{rowData['nextavailabledate'] | rtDate : 'UTC'}}
                                </div>
                                <i *ngIf="rowData[col.field] === 0 && rowData['nextavailabledate'] === null"
                                   class="fa fa-times red"></i>
                            </div>
                            <div *ngSwitchCase="'qtyavailable'">{{(rowData[col.field] ? rowData[col.field] : null) |
                                number}}
                            </div>
                            <div *ngSwitchCase="'datemodified'">{{rowData[col.field] | rtDate: 'UTC'}}
                            </div>
                            <div *ngSwitchCase="'itemDiscount'">
                                <input type="number" pInputText [(ngModel)]="rowData[col.field]"
                                       (blur)="saveItemToCart(rowData, rowIndex)"
                                       style="width: 75px;" max="100" min="0"/>
                            </div>
                            <div *ngSwitchCase="'itemPriceCodes'">
                                <select [(ngModel)]="rowData.selectedPriceCode" class="price-code-select"
                                        (change)="saveItemToCart(rowData, rowIndex)"
                                        *ngIf="rowData[col.field] && rowData[col.field].length" style="width: 85px;">
                                    <option [value]="''">Select</option>
                                    <option *ngFor="let obj of rowData[col.field]" [value]="obj.priceCode">
                                        <!-- {{obj.priceCode}} -->
                                        {{obj.label}}
                                    </option>
                                </select>
                            </div>
                            <div *ngSwitchCase="'quantity'">
                                <input type="number" pInputText [(ngModel)]="rowData[col.field]"
                                       (blur)="saveItemToCart(rowData, rowIndex)"
                                       step="rowData.quantityIncrement" style="width: 75px;" min="1"/>
                            </div>
                            <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                        </div>
                    </td>
                    <td width="">
                        <!--<button (click)="saveItemToCart(rowData, rowIndex)" label="Save" class="ui-button-info ui-button mr-2"-->
                        <!--icon="fa fa-save" pButton></button>-->
                        <button (click)="deleteItemFromCart(rowData)"
                                pTooltip="Delete"
                                tooltipPosition="left"
                                class="ui-button-danger ui-button"
                                icon="fa fa-trash" pButton></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" class="custom-footer" let-rowData>
                <tr>
                    <td [attr.colspan]="cols && cols.length + 1" style="border: none;">
                        <div style="text-align: right;">
                            <h1 class="m-0 mx-5 px-5">
                                Total: <strong>{{items.length}} Item(s) / {{ config.data.total | rtCurrency }} </strong></h1>
                        </div>                        
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
    <p-card class="card" *ngIf="config.data.itemsHistory">
        
        <p-table id="items-table" [columns]="cols" [value]="items" dataKey="recordID"
                 #itemHistoryListingTable [totalRecords]="items?.length" [paginator]="true"
                 [responsive]="false" [loading]="loadingTableData" [rowsPerPageOptions]="[10, 20, 50, 100]" [rows]="10" class="sales-item-history">
            <ng-template pTemplate="paginatorright">
                <div class="per-page-text">Per page :</div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <div class="per-page-text px-10">Total :</div>
                {{items?.length}}
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" [style.width]="col.width">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchCase="'itemNumber'">{{rowData[col.field]}}</div>
                            <div *ngSwitchCase="'size'">{{(rowData[col.field] ? rowData[col.field] :
                                null)}}</div>
                            <div *ngSwitchCase="'color'">{{(rowData[col.field] ? rowData[col.field] :
                                null)}}</div>
                            <div *ngSwitchCase="'style'">{{(rowData[col.field] ? rowData[col.field] :
                                    null)}}</div>
                            <div *ngSwitchCase="'qtySold'">{{(rowData[col.field] ? rowData[col.field] :
                                null) | number}}</div>
                            <div *ngSwitchCase="'orderedDate'">{{rowData[col.field] | rtDate : 'UTC'}}
                            </div>
                            <div *ngSwitchCase="'itemDescription'">{{rowData[col.field]}}</div>
                            <div *ngSwitchCase="'unitPrice'">{{rowData[col.field] | rtCurrency}}</div>
                            <div *ngSwitchCase="'purchaseOrderNumber'">{{rowData[col.field]}}</div>
                            <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" class="custom-footer" let-rowData>
                <tr *ngIf="items?.length && !loadingTableData">
                    <td [attr.colspan]="cols && cols.length">
                        <div style="text-align: right;">
                            Total Number of POs  # :
                            <span style="font-weight: 400; font-size: 14px;display: inline-block;">{{this.totalPOs}}</span>
                        </div>                        
                    </td>
                </tr>
                <tr *ngIf="items?.length && !loadingTableData">
                    <td [attr.colspan]="cols && cols.length">                        
                        <div style="text-align: right;">
                            Total Number of Items # :
                            <span style="font-weight: 400; font-size: 14px;display: inline-block;">{{this.totalItems}}</span>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="table-empty" *ngIf="!items?.length">
                    <td [attr.colspan]="cols?.length + 1" class="text-center">{{loadingTableData ? 'Loading Items' : 'You have no items History.'}}</td>
                </tr>
            </ng-template>

        </p-table>
    </p-card>
</div>
