import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';

import {RTSharedService} from '../../rtshared/rtshared.service';

@Injectable({
    providedIn: 'root'
})
export class BasketItemVariantAPIService {

    constructor(private http: HttpClient,
                private rtSharedService: RTSharedService) {
    }

    saveItemVariantToBasket(queryStringParam: any, payload: any) {
        // payload.repGroupID = this.rtSharedService.getRepGroupID();
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/basketitemvariant/save`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            params.set('repGroupID', this.rtSharedService.getRepGroupID());
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.post<any>(url, payload).toPromise();
    }

    deleteItemVariant(IDs: any[]) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/basketitemvariant/delete`;
        return this.http.post<any>(url, IDs).toPromise();
    }
}
