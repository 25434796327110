import {Injectable} from '@angular/core';

import {RetailerAPIService} from '../../api/retailer-api/retailer-api.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RetailerService {
    private getRetailerDataSubject = new Subject<any>();
    private getRetailerDetailsSubject = new Subject<any>();
    constructor(private retailerAPIService: RetailerAPIService) {
    }

    async getRetailerData(retailerID: string) {
        return this.retailerAPIService.getRetailer(retailerID);
    }
    setRetailerDataEvent(retailer: any) {
        this.getRetailerDataSubject.next(retailer);
    }

    getRetailerDataEvent(): Observable<any> {
        return this.getRetailerDataSubject.asObservable();
    }

    // B2B-52 (MG 31-JAN-2020): modified to filter MFR's promotions
    async getManufacturersPromotions(retailerID: string, companyID: string) {
        return this.retailerAPIService.getManufacturersPromotions(retailerID, companyID);
    }

    async getRetailerAssignmentsByRetailerID(payload: any) {
        return this.retailerAPIService.getRetailerAssignmentsByRetailerID(payload);
    }

    getRetailerQickReport(retailerID: any, repGroupID: any) {
        return this.retailerAPIService.getRetailerQickReport(retailerID, repGroupID);
    }

    getDivisionsByCompany(repGroupID: string) {
        return this.retailerAPIService.getDivisionsByCompany(repGroupID);
    }

    saveRetailerShipTo(retailerID: any, selectedShipToLocation: any) {
        return this.retailerAPIService.saveRetailerShipTo(retailerID, selectedShipToLocation);
    }

    getRepGroupRetailerByRepGroupAndRetailer(retailerID: any, repGroupID: any) {
        return this.retailerAPIService.getRepGroupRetailerByRepGroupAndRetailer(retailerID, repGroupID);
    }

    saveRetailer(retailer: any) {
        return this.retailerAPIService.saveRetailer(retailer);
    }

    saveRepGroupRetailer(repGroupRetailer: any, retailerID: any, repGroupID: any) {
        return this.retailerAPIService.saveRepGroupRetailer(repGroupRetailer, retailerID, repGroupID);
    }

    async getOrderSummaryData(retailerID: string) {
        return this.retailerAPIService.getOrderSummary(retailerID);
    }

    setRetailerDetailsEvent(flag: any) {
        this.getRetailerDetailsSubject.next(flag);
    }

    getRetailerDetailsEvent(): Observable<any> {
        return this.getRetailerDetailsSubject.asObservable();
    }
}
