import {Pipe, PipeTransform} from '@angular/core';
import {SelectItem} from 'primeng/api';

@Pipe({
    name: 'selectItemPipe'
})
export class SelectItemPipe implements PipeTransform {

    transform(value: any, labelProperty: string): SelectItem[] {
        if (value) {
            return value.map((item) => {
                return {
                    label: item.value[labelProperty] ? item.value[labelProperty] : item.value.name,
                    value: item.value[labelProperty] ? item.value[labelProperty] : item.value.name,
                    disabled: item.value['disabled'] ? true : false,
                };
            });
        } else {
            return [];
        }
    }

}
