<div class="page-not-found-page h-100 w-75 resp-login" style="margin: 0 auto">
    <div class="p-grid m-0">
        <div class="p-col-12 text-center">
            <h1 style="color:#628292; margin: .83em 0;font-weight: normal;" class="text-capitalize regi-title">{{domainData.repGroup && domainData.repGroup.name}} Buyer Login</h1>
            <!-- <h2 style="color:#cfcece; margin: -.02em 0; font-weight: normal;" class="text-capitalize">Customer Order Portal</h2> -->
        </div>
        <div class="p-grid m-0" [ngClass]="(authService.getLoginDashboardWidgetSwap()) ? 'flex-row-reverse':''">
            <div class="p-lg-6 p-md-6">
                <p-card styleClass="h-100">
                    <h2 class="text-center">CUSTOMER LOGIN</h2>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="ui-g p-justify-center">
                        <div class="ui-g ui-fluid">
                            <div class="ui-g-12">
                                <div class="ui-inputgroup">
                                    <div class="ui-inputgroup-addon">
                                        <i class="fa fa-fw fa-user"></i>
                                    </div>
                                    <input class="w-100" type="text" pInputText placeholder="Enter Username or MarketTime Portal Username"
                                           formControlName="username" appCustomValidation [allowStartWithSpace]="false"
                                           [ngClass]="{ 'ng-dirty ng-invalid': submitted && loginForm.get('username').errors?.required}"/>
                                </div>
                                <div class="mt-2" *ngIf="submitted && loginForm.get('username').errors?.required">
                                    <p-message severity="error" text="Username is required"
                                               style="line-height: 1"></p-message>
                                </div>
                            </div>
                            <div class="ui-g-12 text-right pt-0 pb-0">
                                <a [routerLink]="['/forgot-username']">Forgot your username?</a>
                            </div>
                            <div class="ui-g-12">
                                <div class="ui-inputgroup">
                                    <div class="ui-inputgroup-addon">
                                        <i class="fa fa-fw fa-lock"></i>
                                    </div>
                                    <input class="w-100" type="password" pInputText placeholder="Enter Password"
                                           autocomplete="new-password" formControlName="password"
                                           [ngClass]="{ 'ng-dirty ng-invalid': submitted && loginForm.get('password').errors?.required}"/>
                                </div>
                                <div class="mt-2" *ngIf="submitted && loginForm.get('password').errors?.required">
                                    <p-message severity="error" text="Password is required"
                                               style="line-height: 1"></p-message>
                                </div>
                            </div>
                            <div class="ui-g-12 text-right pt-0 pb-0">
                                <a [routerLink]="['/forgot-password']">Forgot your password?</a>
                            </div>
                            <div class="ui-g-12 text-center">
                                <p-button type="submit" [styleClass]="'ui-button-success theme-maroon-btn'" label="LOGIN" [disabled]="isLoading"
                                          [icon]="isLoading ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-fw fa-sign-in'"></p-button>
                            </div>
                        </div>
                    </form>
                </p-card>
            </div>
            <div class="p-lg-6 p-md-6">
                <p-card styleClass="h-100">
                    <div class="ui-g ui-fluid">
                        <div class="ui-g-12 text-center">
                            <h2 class="text-center">{{loginDashboardCustomSignupWidget?.title ? loginDashboardCustomSignupWidget?.title : 'NEW CUSTOMERS?'}}</h2>
                            <p class="px-5" [innerHtml]="loginDashboardCustomSignupWidget?.desc ? loginDashboardCustomSignupWidget?.desc : 'Thank you for visiting our B2B ordering website!! <br/><br/> We welcome you to our secure portal to see the latest products and specials offered. Sign up today to see how easy it is order with us online! Enjoy!'"></p>
                        </div>
                        <div class="ui-g-12 text-center" *ngIf="loginDashboardCustomSignupWidget?.showSignupBtn">
                            <p-button styleClass="ui-button-primary btn-theme-color" class="ui-button-raised ui-button-rounded"
                                    (click)="getSignupUrl()" label="{{loginDashboardCustomSignupWidget?.btnName ? loginDashboardCustomSignupWidget?.btnName : 'REGISTER NOW' }}" icon="fa fa-fw fa-user-plus"></p-button>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
        <div class="p-col-12 rep-promo-logo mt-20">
            <img class="w-100" alt="Promo"
                 [src]="promo"
                 onError="this.src='https://reptime.com/img/backgrounds/promo.png';"/>
        </div>
        <div class="p-col-12" *ngIf="domainData.aboutCompany">
            <h3 style="font-weight: normal;" class="px-5 text-center">{{domainData.aboutCompany}}</h3>
        </div>

        <div *ngIf="notUsingChrome" class="center" style="font-size:14px;color:#FFF">

            <a href="https://www.google.com/chrome/" class="d-flex align-items-center" style="color:#BDBDBD" target="_blank"><i class="fab fa-chrome fa-2x mr-5"></i>
                For the best experience use <b>Google Chrome</b></a>
        </div>
    </div>
</div>
<p-toast position="top-right"></p-toast>
