
<p-steps [activeIndex]="activeIndex" class="wizard-steps" [readonly]="false" [model]="stepItems"></p-steps>

<div class="p-5 mt-0" *ngIf="activeIndex === 0">
    <h3 class="my-0 title-text"> </h3>
    <div class="mt-10 mb-10 sticky-first-step" >
        <div [innerHtml]="savedGetIntegrationData && savedGetIntegrationData?.retailerInstructions ? savedGetIntegrationData?.retailerInstructions : '' "> </div>
        
    </div>
    <div class="p-col-12 d-flex justify-content-end">
        <p-footer>
            <p-button icon="fas fa-save" label="Register Now" (click)="registerAction()" [styleClass]="'btn-theme-color'"></p-button>
        </p-footer>
    </div>
</div>
<div class="p-5 mt-15" *ngIf="activeIndex === 1">
    <app-retailer-onboarding-form [getIntegrationData]="getIntegrationData"  [selectedRetailer]="selectedRetailer" (skipToStep)="skipToStep($event)" (closeDialog)="skipAction($event)" (saveRetailerData)="saveRetailerData($event)" [registerFormDisable]="registerFormDisable"></app-retailer-onboarding-form>
</div>
<div class="p-5 mt-15 text-center" *ngIf="activeIndex === 2">
    <h3 class="my-0 title-text mb-20">You are successfully registered! Do you want to apply for net term offerings from the brands you love?</h3>
    <p class="mb-10">Click the “Apply for Net Terms” button below to get approved for net terms from the brands you love.  Get approved in less than 10 seconds, and then use your credit limit to shop the brands you love!</p>
    <p-footer>
    <div class="p-col-12 d-flex  justify-content-between pt-50" style="padding: 5px 0; ">
        <div>
            <p-button icon="fas fa-backward" label="Back" (click)="backBtn()" [disabled]="applyForNetTermsLoader" [styleClass]="'mr-5 ui-btn ui-button-primary ui-button'"></p-button>
        </div>
        <div>
            <p-button icon="fas fa-forward" label="Skip" (click)="cancelRequest()" [disabled]="applyForNetTermsLoader" [styleClass]="'ui-btn ui-button-primary ui-button'"></p-button>
            <!-- <p-button [icon]="applyForNetTermsLoader? 'fa fa-spin fa-spinner' : 'fas fa-paper-plane'" label="Apply for Net Terms" [disabled]="applyForNetTermsLoader" (click)="sendRequest()" [styleClass]="'btn-theme-color ml-5'"></p-button> -->
            <p-button [icon]="applyForNetTermsLoader? 'fa fa-spin fa-spinner' : 'fas fa-university'" label="Apply for Net Terms via Bank" [disabled]="applyForNetTermsLoader" (click)="sendRequest(true)" [styleClass]="'btn-theme-color ml-5'"></p-button>
        </div>
    </div>
    </p-footer>
</div>
<div class="p-5 mt-15 text-center" *ngIf="activeIndex === 3">
    <h3 class="my-0 title-text mb-20" *ngIf="status === 'completed'">Congratulations!  <br/>You are now registered with mtPay. Enjoy a streamlined checkout experience the next time you place an order.</h3>
    <h3 class="my-0 title-text mb-20" *ngIf="status === 'denied' || status === 'declined'">We were not able to verify your details.  Please reach out to <a href="mailto:sales@markettime.com">Sales@markettime.com</a> for further assistance.</h3>
    <h3 class="my-0 title-text mb-20" *ngIf="status === 'inReview'">Congratulations! <br/>You are now registered with mtPay. Your account is under review, we are verifying your details.</h3>
    <h3 class="my-0 title-text mb-20" *ngIf="!status">Congratulations! <br/>You are now registered with mtPay.</h3>
    <div class="p-col-12 d-flex justify-content-between align-items-center">
        <p-button icon="fas fa-backward" label="Back" (click)="backBtn()" [styleClass]="'mr-5 ui-btn ui-button-primary ui-button'"></p-button>
        <a href="javascript:;" style="text-decoration: underline;" (click)="goToManageCompany()">Go to Manage Company >></a>
    </div>
</div>

<p-dialog [(visible)]="showQualificationBankDialog" [baseZIndex]="10001" [closeOnEscape]="false"
    [contentStyle]="{'width': '96vw'}" header="Qualify for terms via Bank"
    modal="true" [maximizable]="false" (onHide)="onHideQualificationBankDialog($event)" [blockScroll]="true" [closeOnEscape]="true" [resizable]="false" styleClass="resp-bill-info overflow-auto showQualificationBankDialogClass" [appendTo]="'body'">
    <div class="reptime-theme">
        <iframe style="width: 100%;height: calc(100vh - 200px);border-width: 0px;" [src]="iframeSrc"></iframe>
    </div>
</p-dialog>