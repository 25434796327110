<p-dialog [(visible)]="displayCarrierAccountDialog" (onHide)="onHide($event)" [closable]="true" [closeOnEscape]="true"
          [contentStyle]="{'width': '800px'}" [blockScroll]="true"
          [maximizable]="false" [modal]="true" [showHeader]="false"
          [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'" class="responsive-ship-loc res-edit-carrier"  [resizable]="false">
    <div class="d-flex mt-5 mb-10">
        <h3 class="my-0 flex-grow-1 title-text" *ngIf="openFrom !== 'FINALIZE'">{{selectedCarrierAccount && selectedCarrierAccount.recordID ? 'Edit ': 'Add '}} Carrier Account</h3>
        <h3 class="my-0 flex-grow-1 title-text" *ngIf="openFrom === 'FINALIZE'">Carrier Account</h3>
        <a class="text-black" (click)="displayCarrierAccountDialog= false; OnCloseDialog.emit(editCarrierAccountsForm.valid);"><i class="pi pi-times fs-22"></i></a>
    </div>
    <form [formGroup]="editCarrierAccountsForm" autocomplete="off" (submit)="saveCarrierAccounts()">
        <div class="p-grid p-5">
            <div class="p-col-12" *ngIf="openFrom === 'FINALIZE'">
                <p-dropdown name="retailerShipMethodDropdown" [options]="carrierAccounts"
                         id="shipMethod" [(ngModel)]="selectionCarrierAccount"
                         [ngModelOptions]="{standalone:true}"
                         class="control-dropdown" [style]="{'width':'100%'}"
                            (onChange)="onCarrierAccountChange($event)">
                </p-dropdown>
            </div>
            <div class="p-col-12">
                <label for="carrierTypeDropdown">Shipping Method</label><br/>
                <div class="input-error-container">
                <p-dropdown [style]="{'width':'100%'}" [options]="carrierTypeOptions" formControlName="shippingMethod" appendTo="body" class="control-dropdown" id="carrierTypeDropdown">
                </p-dropdown>
                <i *ngIf="validateChk.shippingMethod.invalid && (validateChk.shippingMethod.dirty || validateChk.shippingMethod.touched)"
                   class="multiSelect-error error-icon pi pi-info-circle"
                   [pTooltip]="getErrorMsg(validateChk.shippingMethod,'Shipping Method')" tooltipPosition="left"
                   tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="accountHolderName">Account Holder Name*</label> <br/>
                <div class="input-error-container">
                <input class="control" formControlName="accountHolderName" id="accountHolderName" maxlength="50"
                        appCustomValidation [allowStartWithSpace]="false"
                        pInputText type="text" autocomplete="off"/>
                <i *ngIf="validateChk.accountHolderName.invalid && (validateChk.accountHolderName.dirty || validateChk.accountHolderName.touched)"
                   class="error-icon pi pi-info-circle"
                   [pTooltip]="getErrorMsg(validateChk.accountHolderName,'Name')" tooltipPosition="left"
                   tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="accountNumber">Account Number*</label> <br/>
                <div class="input-error-container">
                    <input class="control" formControlName="accountNumber" id="accountNumber" maxlength="50"
                        appCustomValidation [allowStartWithSpace]="false"
                        pInputText type="text" autocomplete="off"/>
                    <i *ngIf="validateChk.accountNumber.invalid && (validateChk.accountNumber.dirty || validateChk.accountNumber.touched)"
                       class="error-icon pi pi-info-circle"
                       [pTooltip]="getErrorMsg(validateChk.accountNumber,'Account number')" tooltipPosition="left"
                       tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-12 RAlign">
                <button class="ui-button ui-button-info btn-theme-color" [disabled]="loading" label="Save"
                        name="save-carrier-type" pButton 
                        type="submit" [icon]="loading ? 'pi pi-spin pi-spinner' : 'fa fa-save'"  icon="fa fa-save"></button>
            </div>
        </div>
    </form>
</p-dialog>
