import { async, TestBed } from '@angular/core/testing';
import { Subject, Subscription } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, ViewChild, Renderer2, AfterViewInit, HostListener } from '@angular/core';
import { MessageService, ConfirmationService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { BasketItemVariantService } from 'src/app/services/middleware/basket-item-variant/basket-item-variant.service';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import { OrderService } from '../../services/middleware/order/order.service';

import { BasketItemService } from 'src/app/services/middleware/basket-item/basket-item.service';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { NgxImgZoomService } from 'ngx-img-zoom';
import { ItemsAPIService } from 'src/app/services/api/items-api/items-api.service';
import { trigger, transition, animate, style } from '@angular/animations'
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import { DynamicDialogComponent } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { ManufacturerService } from '../../services/api/manufacturer.service';
import {
  Router, ActivatedRoute, NavigationEnd, NavigationStart, GuardsCheckEnd, GuardsCheckStart,
  RouterPreloader, ActivationEnd, ResolveEnd, NavigationCancel
} from '@angular/router';
import { RtDatePipe } from '../../pipes/rt-date.pipe';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { ThreeSixtyComponent } from '@mediaman/angular-three-sixty';

@Component({
  selector: 'app-item-view-model',
  templateUrl: './item-view-model.component.html',
  styleUrls: ['./item-view-model.component.scss']
})
export class ItemViewModelComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  value: number = 0;
  @Input() isOpen: boolean;
  @Input() activeViewIndex: any;
  @Input() activeViewItem: any;
  @Input() itemMfgIDs: any;
  @Input() manufacturersArr: any;
  @Input() selectedCustomer: any;
  @Input() fromPage: any;
  @Input() existCartData: any;
  @Input() existItemsData?: any;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addToCartEvent: EventEmitter<any> = new EventEmitter<any>();
  public config: any = {
    data: {},
  };
  public configData: any;
  public singleItemCols: any[];
  public singleItemValue: any[];
  public heartStatusArray: boolean[] = [];
  public disableBtnObj: any = {};
  public sequenceID: any;
  public cartData: any;
  public onCartMatchItem: any;
  public onCartMatchedItem: any;
  public isItemAlreadyAvailable: boolean;
  disableBtnSub: Subscription;
  public subscription: Subscription;
  enableZoom = true;
  public wishListItem: any[];
  public heartStatus = false;
  public isAddButtonDisable = true;
  public showLoader = false;
  public currentActiveViewItemStatus = true;
  public currentActiveViewItem: any;
  // [B2B-196] (RM 26-03-2020): Display linkedItems
  linkedItemsLoader = true;
  linkedItemsData: any[];
  selectedLinkedItemsData: any;
  isLogin = true;
  b2bItemPricingPolicy = false;

  // [B2B-179] (RM 20-MAR-2020): Multiple item image for use variable
  thumbImageData: any[];
  selectedThumbImageData: any;

  public linkItemCols: any[];
  oldQty = 0;
  oldQtySimillar = 0;
  minimumQtyMeetToVariantItem: any[] = [];

  commets_Dialog: boolean = false;
  edit_commets: boolean = false;
  public onDestroy = new Subject<void>();
  public checkAvailability = true;
  public avgItemsRating = 0;
  public myItemsRating = 0;
  public myItemsRatingObj: any = null;
  loading = false;
  saveLoading = false;
  getAllReviews: any[] = [];
  manufacturerID: any;
  reviewRecordID: any;
  itemReview: any;
  itemGetReview: any;
  ratingLoader = false;
  reviewDeleteLoader = false;
  showReviewLoader = false;
  repGroupID: any;
  retailerID: any;
  public isIframeWindow: any = (window as any).iframeData;
  public totalRate = 0;
  public totalReview = 0;
  public oneStarPerc = 0;
  public twoStarPerc = 0;
  public threeStarPerc = 0;
  public fourStarPerc = 0;
  public fiveStarPerc = 0;
  item360Images: any;
  images360Preview: any;
  imageAngles = 0;
  display360Preview = false;
  is360ViewLoaded = false;
  innerWidth: any;
  innerHeight: any;
  @Input() sortOrder = {
    sort: 'sequenceID',
    order: 'DESC'
  };

  @ViewChild('threeSixty', { static: false }) threeSixty!: ThreeSixtyComponent;
  constructor(
    private msgService: MessageService, private confirmationService: ConfirmationService,
    private orderService: OrderService, public auth: AuthService, private router: Router,
    private basketItemService: BasketItemService,
    private rtSharedService: RTSharedService,
    private itemsAPIService: ItemsAPIService,
    private manufacturerService: ManufacturerService,
    public bivService: BasketItemVariantService, private ngxImgZoom: NgxImgZoomService, public renderer: Renderer2,
    public dp: DatePipe,
  ) {
    this.ngxImgZoom.setZoomBreakPoints([{ w: 100, h: 100 }, { w: 150, h: 150 }, { w: 200, h: 200 }, { w: 250, h: 250 }, { w: 300, h: 300 }]);
    this.disableBtnSub = this.orderService.getAddBtnDisableSub()
      .subscribe(async (eventObj) => {
        this.disableBtnObj = eventObj; // subject for disbale/enable add button
      });
  }
  async ngOnInit() {
    this.isLogin = this.auth.getIDToken();
    this.innerWidth = window.innerWidth - 40;
    this.innerHeight = window.innerHeight - 200;
    this.activeViewItem.manufacturerPath = environment.logoBucketUrl + 'logo/' + this.activeViewItem.manufacturerID + '.png';
    if (this.currentActiveViewItemStatus) {
      this.currentActiveViewItem = _.cloneDeep(this.activeViewItem);
      this.currentActiveViewItemStatus = false;
    }
    this.selectedThumbImageData = null;
    this.wishListItem = this.basketItemService.getWishListItems();
    this.getThumbnailImageData(this.activeViewItem); // [B2B-179] (RM 20-MAR-2020): Multiple item image for use variable
    if (this.isLogin) {
      await this.getlinkedItemsData(this.activeViewItem); // [B2B-196] (RM 26-MAR-2020): Display linkedItems
      this.manufacturerID = this.activeViewItem.manufacturerID;
      this.repGroupID = this.rtSharedService.getRepGroupID();
      this.retailerID = this.rtSharedService.getRetailerID();
      this.getAvgItemsRating();
      this.checkReviewOrRateAvailability();
      this.get360Images(this.activeViewItem.recordID);
    } else {
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.linkedItemsLoader = false;
    }
    this.activeViewItem.repGroupManufacturers = null;
    this.b2bItemPricingPolicy = this.activeViewItem && this.activeViewItem.b2bItemPricingPolicyFlag ? true : false;
    // console.log(this.activeViewItem.manufacturerID);
    // console.log(this.manufacturersArr);
    // console.log(this.b2bItemPricingPolicy);
    // const b2bItemPricingPolicyArr = _.filter(this.manufacturersArr, (o, i) => {
    //   if (o.recordID === this.activeViewItem.manufacturerID) {
    //     return o;
    //   }
    // });
    // if (b2bItemPricingPolicyArr && b2bItemPricingPolicyArr.length) {
    //   console.log(b2bItemPricingPolicyArr);
    // }
    this.config.data = {
      isOpen: this.isOpen,
      activeViewIndex: this.activeViewIndex,
      activeViewItem: this.activeViewItem,
      itemMfgIDs: this.itemMfgIDs,
      manufacturersArr: this.manufacturersArr,
      selectedCustomer: this.selectedCustomer,
      linkedItemsData: this.linkedItemsData
    };
    this.cartData = this.existCartData;
    if (this.config) {
      this.configData = this.config.data;
      // console.log(this.configData);
      // [B2B-115] (RM 04-03-2020): get all item from wish list
      // if (this.configData.activeViewItem.isWishedItem) {
      // } else {
      this.configData.activeViewItem.isWishedItem = false;
      this.configData.activeViewItem.itemDiscount = this.configData.activeViewItem.itemDiscount ? this.configData.activeViewItem.itemDiscount : 0;
      if (this.isLogin) {
        this.configData.activeViewItem.unitPrice = this.configData.activeViewItem.b2bItemPricingPolicy === 'ShowRetailPrice' ? this.configData.activeViewItem.retailPrice ? this.configData.activeViewItem.retailPrice : this.configData.activeViewItem.unitPrice : this.configData.activeViewItem.unitPrice;
      }
      setTimeout(() => {
        if (this.configData.activeViewItem.quantity === 0) {
          console.log('linkedItemsData134', this.configData.linkedItemsData);
          // this.configData.activeViewItem.quantity = this.configData.activeViewItem.minimumQuantity;
          this.configData.activeViewItem.quantity = 0;
        }
        this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0 && !this.b2bItemPricingPolicy;
        console.log('1444', this.isAddButtonDisable);
      }, 0);
      const itemRecordID = this.configData.activeViewItem.recordID;
      const chkIndex = _.findIndex(this.wishListItem, (o) => {
        return o.item.recordID === itemRecordID;
      });
      if (chkIndex >= 0) {
        this.configData.activeViewItem.isWishedItem = true;
      } else {
        this.configData.activeViewItem.isWishedItem = false;
      }
      // }

      const UPCArr = [];
      UPCArr.push(this.configData.activeViewItem.upc1, this.configData.activeViewItem.upc2, this.configData.activeViewItem.upc3);
      this.configData.activeViewItem.UPCObjString = UPCArr.filter(x => {
        return x && x.length && x !== null && x !== undefined;
      }).join(', ');
      this.configData.activeViewItem.modelimageError = false;
      // this.configData.activeViewItem.modelimageLoader = true;
      this.configData.activeViewItem.quantity = !this.configData.activeViewItem.quantity ? 0 : this.configData.activeViewItem.quantity;
      if (this.configData.activeViewItem.isc && this.configData.activeViewItem.isc.length) {
        this.configData.activeViewItem.isc.forEach((d: any) => {
          d.quantity = d.quantity ? d.quantity : 0;
        });
      }
      // B2B-80 (NL 15-FEB-2020) add single item size array
      this.singleItemCols = [
        { field: 'fullPicturePath', header: 'Image', width: '100px' },
        { field: 'size', header: 'Size', width: 'auto' },
        { field: 'color', header: 'Color', width: 'auto' },
        { field: 'style', header: 'Style', width: 'auto' },
      ];
      this.singleItemValue = [
        { fullPicturePath: 'https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28617/1001.jpg', size: 'S', color: 'White', style: 'Glass' },
        { fullPicturePath: 'https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28617/1001.jpg', size: 'M', color: 'White', style: 'Glass' },
        { fullPicturePath: 'https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28617/1001.jpg', size: 'L', color: 'White', style: 'Glass' }
      ];

      // B2B-327 (NL 05-OCT-2020) add similar item array
      this.linkItemCols = [
        { field: 'fullPicturePath', header: 'Image', width: '100px' },
        { field: 'name', header: 'Name', width: '170px' },
        { field: 'description', header: 'Description', width: '210' },
        { field: 'minimumQuantity', header: 'Min Qty', width: '80px' },
        { field: 'unitPrice', header: 'Unit Price($)', width: '130px' },
      ];

      // (RM 27-02-2020): Condition Added B2B-95 When open dialog from finialize screen that time not check cart data
      if (objectPath.get(this.existCartData, 'items', null)) {
        // console.log('existCartData >>>>> ', this.existCartData.items);
        // console.log('activeViewItem >>>>> ', this.configData.activeViewItem);
        let itemExistRecordID = this.configData.activeViewItem.recordID;
        if (objectPath.get(this.configData.activeViewItem, 'item', null)) {
          itemExistRecordID = this.configData.activeViewItem.item.recordID;
        }
        // console.log('Current item recordID >>>>> ', itemExistRecordID);
        this.onCartMatchItem = _.cloneDeep(this.existCartData.items.find(o => o.item.recordID === itemExistRecordID));
        if (this.onCartMatchItem) {
          this.configData.activeViewItem.quantity = this.onCartMatchItem.quantity;
          if (this.configData.activeViewItem.isc && this.configData.activeViewItem.isc.length) {
            this.configData.activeViewItem.isc.forEach((value: any) => {
              const matchBasketItem = this.onCartMatchItem.basketItemVariants.find(o => o.itemColorStyleID === value.recordID.toString());
              if (matchBasketItem) {
                value.quantity = matchBasketItem.quantity;
              }
            });
          }
        } else {
          this.configData.activeViewItem.quantity = 0;
          if (this.configData.activeViewItem.isc && this.configData.activeViewItem.isc.length) {
            this.configData.activeViewItem.isc.forEach((value: any) => {
              // [B2B-239-hotfix] (RM 01-05-2020): set default zero
              value.quantity = 0; // this.configData.activeViewItem.minimumQuantity;
            });
          }
        } // End
      }
    }
    // (RM 10-08-2020): Condition Added B2B-294 Searching & adding same item from finalize screen should auto fill-up qty from cart
    if (this.existItemsData && this.existItemsData.length && this.fromPage === 'ORDER_PAGE') {
      if (this.configData.activeViewItem.isc && this.configData.activeViewItem.isc.length === 0) {
        const existFind = this.existItemsData.find(o => this.configData.activeViewItem.recordID === o.itemID && !o.recordDeleted);
        this.configData.activeViewItem.quantity = existFind && existFind.quantity ? existFind.quantity : this.configData.activeViewItem.quantity;
      } else if (this.configData.activeViewItem.isc && this.configData.activeViewItem.isc.length !== 0) {
        this.configData.activeViewItem.quantity = 0;
        this.configData.activeViewItem.isc.forEach((value: any) => {
          const existFind = this.existItemsData.find(o => value.itemID === o.itemID && value.recordID === o.itemColorStyleID && !o.recordDeleted);
          value.quantity = existFind && existFind.quantity ? existFind.quantity : 0;
          this.configData.activeViewItem.quantity = this.configData.activeViewItem.quantity + value.quantity;
        });
      }
    } // END
    this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0;
    console.log('1444', this.isAddButtonDisable);
    console.log('this.activeViewItem', this.activeViewItem);
    // // console.log(this.configData.activeViewItem);
    // console.log(this.configData.activeViewItem.quantity);

  }
  ngAfterViewInit() {
    this.isIframeWindow = (window as any).iframeData;
  }
  ngOnChanges(changes) {
    if (this.existCartData) {
      this.cartData = this.existCartData;
    }

    this.innerWidth = window.innerWidth - 40;
    this.innerHeight = window.innerHeight - 200;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth - 40;
    this.innerHeight = window.innerHeight - 200;
  }
  setOldCartData(cartData: any) {
    if (cartData && cartData.manufacturer && cartData.manufacturer.length) {
      cartData.manufacturer = _.map(cartData.manufacturer, (m: any) => {
        if (m.items && m.items.length) {
          m.items = _.map(m.items, (i: any) => {
            if (this.cartData && this.cartData.manufacturer && this.cartData.manufacturer.length) {
              _.forEach(this.cartData.manufacturer, (om) => {
                if (om.items && om.items.length) {
                  _.forEach(om.items, (oi) => {
                    if (oi.recordID === i.recordID && oi.isTreeActive) {
                      i.isTreeActive = oi.isTreeActive;
                    }
                  });
                }
              });
            }
            return i;
          });
        }
        return m;
      });
    }
    // console.log('cartData', cartData);
    return cartData;
  }
  setMfgMinimumMet(manufacturers) {
    return manufacturers.map((mfg: any) => {
      mfg.isMinimumMet = (mfg.discountedTotal >= mfg.minimumOrderAmount);
      mfg.totalQty = this.getMfgTotalQty(mfg.items);
      return mfg;
    });
  }
  getMfgTotalQty(mfgItems) {
    return _.sumBy(mfgItems, 'quantity');
  }
  ngOnDestroy() {
    this.config.data = {};
    this.disableBtnSub.unsubscribe();
    this.onDestroy.unsubscribe();
  }
  // [B2B-115] (RM 04-03-2020): Save & Delete item from wish list
  async heartClickEvent(heartStatus) {
    this.showLoader = true;
    this.configData.activeViewItem.isWishedItem = !heartStatus;
    const activeItemObj = this.configData.activeViewItem;
    await this.basketItemService.saveItemToWishList(activeItemObj, heartStatus);
    this.showLoader = false;
    this.msgService.add({
      key: 'addItem2',
      severity: 'info',
      summary: heartStatus ? 'Item Removed to Wish list' : 'Item Added to Wish list',
      detail: `${this.configData.activeViewItem.itemNumber} was saved successfully.`
    });
  }

  onclickSaveOldQty(quantity: any) {
    console.log('mousedown', quantity);
    this.oldQty = 0;
    this.oldQty = quantity;
  }
  decrQtyEvent(val) {
    if (val > 0) {
      this.updateItemQty(val, 'decr');
    }
  }
  incrQtyEvent(val) {
    this.updateItemQty(val, 'incr');
  }
  updateItemQty(val, btnType) {
    // console.log('3044444', val);
    if (this.configData.activeViewItem.isc && this.configData.activeViewItem.isc.length === 0) {
      console.log('30666666');
      if (btnType === 'decr') {
        if (val > this.configData.activeViewItem.minimumQuantity) {
          this.configData.activeViewItem.quantity = val - this.configData.activeViewItem.quantityIncrement;
        }
        this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0;
        console.log('1444', this.isAddButtonDisable);
      } else if (btnType === 'incr') {
        this.configData.activeViewItem.quantity = val + this.configData.activeViewItem.quantityIncrement;
        this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0;
        console.log('1444', this.isAddButtonDisable);
      } else if (btnType === 'self') {
        if (val < this.configData.activeViewItem.minimumQuantity) {
          this.configData.activeViewItem.quantity = this.configData.activeViewItem.minimumQuantity;
        } else {
          this.configData.activeViewItem.quantity = val;
        }
        this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0 || this.configData.activeViewItem.quantity === null;
        console.log('1444', this.isAddButtonDisable);
      }
      this.configData.activeViewItem.quantity = this.configData.activeViewItem.quantity ? this.configData.activeViewItem.quantity : 0;
      this.configData.activeViewItem.quantity = this.configData.activeViewItem.quantity < 0 ? 0 : this.configData.activeViewItem.quantity;
      console.log('3499999?', this.configData.activeViewItem);
      this.commonQtyManageFun(this.configData.activeViewItem);
      this.onCartMatchItemFun(this.configData.activeViewItem.quantity);

      // if (this.onCartMatchItem) {
      //   this.onCartMatchItem.quantity = this.configData.activeViewItem.quantity;
      //   this.onCartMatchItem.item.quantity = this.configData.activeViewItem.quantity;
      // }
    } else {
      console.log('30666666');
      let qty;
      if (btnType === 'decr') {
        if (val > this.configData.activeViewItem.minimumQuantity) {
          qty = val - this.configData.activeViewItem.quantityIncrement;
        } else {
          qty = this.configData.activeViewItem.minimumQuantity;
        }
        this.isAddButtonDisable = qty === 0;
        console.log('1444', this.isAddButtonDisable);
      } else if (btnType === 'incr') {
        qty = val + this.configData.activeViewItem.quantityIncrement;
        this.isAddButtonDisable = qty === 0;
        console.log('1444', this.isAddButtonDisable);
      } else if (btnType === 'self') {
        if (val === 0) {
          qty = 0;
        } else if (val < this.configData.activeViewItem.minimumQuantity) {
          qty = this.configData.activeViewItem.minimumQuantity;
        } else {
          qty = val;
        }
        this.isAddButtonDisable = qty === 0;
        console.log('1444', this.isAddButtonDisable);
      }
      if (qty === 0) {
        this.configData.activeViewItem.quantity = 0;
      }
      this.commonQtyManageFun(this.configData.activeViewItem);
      this.configData.activeViewItem.quantity = this.configData.activeViewItem.quantity ? this.configData.activeViewItem.quantity : 0;
      this.configData.activeViewItem.quantity = qty < 0 ? 0 : qty;

      if (this.configData.activeViewItem.quantity !== 0 && this.configData.activeViewItem.quantity < this.configData.activeViewItem.minimumQuantity) {
        this.configData.activeViewItem.quantity = this.configData.activeViewItem.minimumQuantity;
        qty = this.configData.activeViewItem.quantity;
      }
      Object.keys(this.configData.activeViewItem.isc).forEach(k => {
        this.configData.activeViewItem.isc[k].quantity = qty < 0 ? 0 : qty;
      });
    }
  }
  decrSCSEvent(obj, index) {
    // [B2B-237] (RM 17-04-2020): condition removed
    // if (obj.quantity > this.configData.activeViewItem.minimumQuantity) {
    this.configData.activeViewItem.quantity = 0;
    this.configData.activeViewItem.isc[index].quantity = this.configData.activeViewItem.isc[index].quantity -
      this.configData.activeViewItem.quantityIncrement;
    this.configData.activeViewItem.isc[index].quantity = this.configData.activeViewItem.isc[index].quantity < 0 ?
      0 : this.configData.activeViewItem.isc[index].quantity;
    // [B2B-237] (RM 17-04-2020): condition added, set minimum qty
    // [B2B-239-hotfix] (RM 01-05-2020): Condition comment
    // if (this.configData.activeViewItem.isc[index].quantity !== 0 &&
    //   this.configData.activeViewItem.isc[index].quantity < this.configData.activeViewItem.minimumQuantity) {
    //   this.configData.activeViewItem.isc[index].quantity = this.configData.activeViewItem.minimumQuantity;
    // } // END
    _.forEach(this.configData.activeViewItem.isc, (value, key) => {
      this.configData.activeViewItem.quantity = this.configData.activeViewItem.quantity + value.quantity;
      this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0;
      console.log('1444', this.isAddButtonDisable);
    });
    this.commonQtyManageFunVariation(this.configData.activeViewItem, index);
    if (this.linkedItemsData && this.linkedItemsData.length) {
      let qty = 0;
      _.forEach(this.linkedItemsData, (value, key) => {
        qty = qty + value.quantity;
      });
      if (qty) {
        this.isAddButtonDisable = false;
        console.log('1444', this.isAddButtonDisable);
      }
    }
    // }
  }

  decrLinkedEvent(obj, index) {
    this.linkedItemsData[index].quantity = this.linkedItemsData[index].quantity -
      this.linkedItemsData[index].quantityIncrement;
    this.linkedItemsData[index].quantity = this.linkedItemsData[index].quantity < 0 ?
      0 : this.linkedItemsData[index].quantity;
    this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0 && this.linkedItemsData[index].quantity === 0;
    console.log('1444', this.isAddButtonDisable);
    let qty = 0;
    this.commonQtyManageFunSimilar(this.linkedItemsData, index);

    _.forEach(this.linkedItemsData, (value, key) => {
      qty = qty + value.quantity;
    });
    if (qty) {
      this.isAddButtonDisable = false;
      console.log('1444', this.isAddButtonDisable);
    }
  }
  updateSCSEvent(obj, index) {
    // [B2B-237] (RM 17-04-2020): condition added
    if (obj.quantity === null) { obj.quantity = 0; }
    // [B2B-239-hotfix] (RM 01-05-2020): Condition comment
    // if (obj.quantity !== 0 && obj.quantity < this.configData.activeViewItem.minimumQuantity) {
    //   this.configData.activeViewItem.isc[index].quantity = this.configData.activeViewItem.minimumQuantity;
    // } // END
    this.configData.activeViewItem.quantity = 0;
    _.forEach(this.configData.activeViewItem.isc, (value, key) => {
      this.configData.activeViewItem.quantity = this.configData.activeViewItem.quantity + value.quantity;
      // [B2B-296] (RM 17-07-2020): Bug Fixed
      if (index === key) {
        this.commonQtyManageFunVariation(this.configData.activeViewItem, index);
      }
      this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0;
      console.log('1444', this.isAddButtonDisable);
    });
  }
  updateLinkedEvent(obj, index) {
    // [B2B-237] (RM 17-04-2020): condition added
    if (obj.quantity === null) { obj.quantity = 0; }
    if (obj.quantity !== 0 && obj.quantity < obj.minimumQuantity) {
      this.linkedItemsData[index].quantity = obj.minimumQuantity;
    }
    this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0 && this.linkedItemsData[index].quantity === 0;
    console.log('1444', this.isAddButtonDisable);
    let qty = 0;
    this.commonQtyManageFunSimilar(this.linkedItemsData, index);
    _.forEach(this.linkedItemsData, (value, key) => {
      qty = qty + value.quantity;
    });
    if (qty) {
      this.isAddButtonDisable = false;
      console.log('1444', this.isAddButtonDisable);
    }
  }
  incrSCSEvent(obj, index) {
    this.configData.activeViewItem.quantity = 0;
    this.configData.activeViewItem.isc[index].quantity = this.configData.activeViewItem.isc[index].quantity +
      this.configData.activeViewItem.quantityIncrement;
    this.configData.activeViewItem.isc[index].quantity = this.configData.activeViewItem.isc[index].quantity < 0 ?
      0 : this.configData.activeViewItem.isc[index].quantity;
    _.forEach(this.configData.activeViewItem.isc, (value, key) => {
      this.configData.activeViewItem.quantity = this.configData.activeViewItem.quantity + value.quantity;
      this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0;
      console.log('1444', this.isAddButtonDisable);
    });

    this.commonQtyManageFunVariation(this.configData.activeViewItem, index);
  }

  incrLinkedEvent(obj, index) {
    this.linkedItemsData[index].quantity = this.linkedItemsData[index].quantity +
      this.linkedItemsData[index].quantityIncrement;
    this.linkedItemsData[index].quantity = this.linkedItemsData[index].quantity < 0 ?
      0 : this.linkedItemsData[index].quantity;
    this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0 && this.linkedItemsData[index].quantity === 0;
    console.log('1444', this.isAddButtonDisable);
    let qty = 0;
    this.commonQtyManageFunSimilar(this.linkedItemsData, index);
    _.forEach(this.linkedItemsData, (value, key) => {
      qty = qty + value.quantity;
    });
    if (qty) {
      this.isAddButtonDisable = false;
      console.log('1444', this.isAddButtonDisable);
    }
  }
  async addOrder(obj) {
    // console.log('this.fromPage: ', this.fromPage, this.configData);
    // (RM 27-02-2020): Condition Added B2B-95 When open dialog from finialize screen that time not check cart data
    if (this.fromPage === 'ORDER_PAGE' || this.fromPage === 'FINALIZE_PAGE') {
      this.addToCartEvent.emit(this.configData);
    } else {
      if (this.disableBtnObj && !this.disableBtnObj[this.configData.activeViewItem.recordID]) {
        // console.log('addOrder >> isc check >> ', this.configData.activeViewItem.isc);
        if (this.configData.activeViewItem.isc === null) {
          this.saveItemToBasketEndpoint();
        } else if (this.configData.activeViewItem.isc.length === 0) {
          this.saveItemToBasketEndpoint();
        } else {
          // [B2B-239-hotfix] (RM 01-05-2020): ISC Quantity total with compare minimum  Quantity
          let totalIscQuantity = 0;
          _.forEach(this.configData.activeViewItem.isc, (value, key) => {
            totalIscQuantity = totalIscQuantity + value.quantity;
          });
          // [B2B-364] (RK 08 FEB 2021): Fix Minimum met variant type items validation issue when parent item has variant + linked item
          this.minimumQtyMeetToVariantItem = [];
          _.forEach(this.configData.activeViewItem.isc, (data, i) => {
            if (data.quantity > 0 && data.quantity < this.configData.activeViewItem.minimumQuantity) {
              this.minimumQtyMeetToVariantItem.push(true);
            } else {
              this.minimumQtyMeetToVariantItem.push(false);
            }
          });
          let finalSaved = false;
          if (this.minimumQtyMeetToVariantItem.some((item) => item === true)) {
            finalSaved = true;
          }
          if (finalSaved) {
            this.msgService.clear();
            this.msgService.add({
              key: 'addItem2',
              severity: 'warn',
              summary: 'Item Added to Cart',
              detail: 'Sorry, but your quantity is not matching to a minimum quantity of variations type item.',
            });
            return true;

          } else if (totalIscQuantity === 0 && this.linkedItemsData && this.linkedItemsData.length) {
            this.saveItemToBasketEndpoint();
          } else if (totalIscQuantity < this.configData.activeViewItem.minimumQuantity) {
            this.msgService.clear();
            this.msgService.add({
              key: 'addItem2',
              severity: 'warn',
              summary: 'Item Added to Cart',
              detail: 'Sorry, but your quantity is not matching to a minimum quantity of variations type item.'
            });
          } else {
            const index = 0;
            this.saveItemVariantToBasketEndpoint(index);
          }
          // END
        }
        // const checkMinQty =  await _.find(this.linkedItemsData, (value, key) => {
        //   return value.quantity && value.quantity < value.minimumQuantity;
        // });
        // if (checkMinQty) {
        //   this.msgService.clear();
        //   this.msgService.add({
        //       key: 'addItem2',
        //       severity: 'warn',
        //       summary: 'Item Added to Cart',
        //       detail: 'Sorry, but your quantity is not matching to a minimumquantity.'
        //   });
        // } else {
        // }
      }
    }
  }
  getItemWithMfg(cartData) {
    const itemIDs: any = [];
    if (objectPath.get(cartData, 'manufacturer', null)) {
      _.forEach(cartData.manufacturer, (o) => {
        _.forEach(o.items, (itemObj: any) => {
          if (itemObj.basketItemVariants && itemObj.basketItemVariants.length) {
            _.forEach(itemObj.basketItemVariants, (biv: any) => {
              itemIDs.push({
                manufacturerID: itemObj.manufacturerID,
                itemNumber: itemObj.itemNumber ? itemObj.itemNumber : itemObj.item.itemNumber,
                itemColorStyleID: biv.itemColorStyleID
              });
            });
          } else {
            itemIDs.push({
              manufacturerID: itemObj.manufacturerID,
              itemNumber: itemObj.item.itemNumber
            });
          }

        });
      });
    }
    return itemIDs;
  }
  async saveItemToBasketEndpoint() {
    const existFind = this.itemMfgIDs.find(o => (this.configData.activeViewItem.itemNumber === o.itemNumber
      && this.configData.activeViewItem.manufacturerID === o.manufacturerID && !o.recordDeleted));
    this.orderService.setAddBtnDisable(`${this.configData.activeViewItem.recordID}`);
    if (this.existCartData && this.existCartData.items && existFind) {
      if (this.configData.activeViewItem.quantity && this.configData.activeViewItem.quantity !== 0) {
        await this.updateItemFromCart(this.onCartMatchItem);
      } else if (this.configData.activeViewItem.quantity === 0) {
        await this.basketItemService.deleteBasketItemItem([this.onCartMatchItem.recordID]);
      }
    } else {
      if (this.configData.activeViewItem.quantity && this.configData.activeViewItem.quantity !== 0) {
        console.log('6255 1');
        if (this.validateDateBetweenTwoDates(this.configData.activeViewItem)) {
          this.configData.activeViewItem.itemDiscount = this.configData.activeViewItem.discountPercent;
        }
        await this.basketItemService.saveItemToBasket(this.configData.activeViewItem, this.selectedCustomer);
        this.msgService.clear();
        this.msgService.add({
          key: this.fromPage === 'WHATS_TRENDING_PAGE' ? 'addItem2' : 'addItem',
          severity: 'info',
          summary: 'Item Added to Cart',
          detail: `${this.configData.activeViewItem.itemNumber} was saved successfully.`
        });
      }
    }
    if (this.linkedItemsData && this.linkedItemsData.length) {
      const index = 0;
      this.saveItemsToBasketEndpoint(index);
    } else {
      this.cartData = await this.getCart(this.selectedCustomer);
      this.existCartData = this.cartData;
      this.setMfgMinimumMet(this.existCartData.manufacturer);
      this.itemMfgIDs = this.getItemWithMfg(this.existCartData);
      this.addToCartEvent.emit({ cartData: this.cartData, itemMfgIDs: this.itemMfgIDs, linkedItemsData: this.linkedItemsData });
      this.onCartMatchItem = _.cloneDeep(this.existCartData.items.find(o => o.item.recordID === this.configData.activeViewItem.recordID));
      this.orderService.removeAddBtnDisable(`${this.configData.activeViewItem.recordID}`);
    }
    // console.log('ending saveItemToBasketEndpoint');
  }
  async updateItemFromCart(itemObj: any) {
    if (this.validateDateBetweenTwoDates(this.configData.activeViewItem)) {
      itemObj['itemDiscount'] = this.configData.activeViewItem.discountPercent;
    }
    if (itemObj && itemObj.itemDiscount) {
      if (itemObj.itemDiscount > 100) {
        itemObj.itemDiscount = 100;
      } else if (itemObj.itemDiscount < 0) {
        itemObj.itemDiscount = 0;
      }
    } else {
      itemObj.itemDiscount = 0;
    }

    if (itemObj.quantity) {
      if (itemObj.quantity < 1) {
        itemObj.quantity = 1;
      }
    } else {
      itemObj.quantity = 1;
    }
    console.log('6255 2');
    await this.basketItemService.saveItemToBasket(itemObj, this.selectedCustomer).then(async (res) => {
      this.msgService.clear();
      this.msgService.add({
        key: this.fromPage === 'WHATS_TRENDING_PAGE' ? 'addItem2' : 'addItem',
        severity: 'info',
        summary: 'Item Updated to Cart',
        detail: `${itemObj.item.itemNumber} was saved successfully.`
      });
    });
  }
  async updatescsItemFromCart(itemObj: any, itemVariant: any) {
    const retailerID: string = this.selectedCustomer.recordID;
    const selectedShipTo: string = this.selectedCustomer.selectedShipTo.recordID;
    itemObj.item.b2bPriceCode = itemObj.b2bPriceCode;
    await this.bivService.saveItemVariantToBasket(retailerID, selectedShipTo, itemObj.item, itemVariant).then(async (res) => {
      this.msgService.clear();
      this.msgService.add({
        key: this.fromPage === 'WHATS_TRENDING_PAGE' ? 'addItem2' : 'addItem',
        severity: 'info',
        summary: 'Item Updated to Cart',
        detail: `${this.configData.activeViewItem.itemNumber} was saved successfully.`
      });
    });
  }
  async saveItemVariantToBasketEndpoint(i) {
    if (this.configData.activeViewItem.isc && this.configData.activeViewItem.isc.length > i) {
      const iscObj = this.configData.activeViewItem.isc[i];
      const index = i;
      const existFind = this.configData.itemMfgIDs.find(o => (this.configData.activeViewItem.itemNumber === o.itemNumber &&
        this.configData.activeViewItem.manufacturerID === o.manufacturerID && o.itemColorStyleID === iscObj.recordID.toString()));
      this.orderService.setAddBtnDisable(`${this.configData.activeViewItem.recordID}`);
      console.log('this.onCartMatchItemthis>>>>>', _.cloneDeep(this.onCartMatchItem), _.cloneDeep(this.configData.itemMfgIDs), _.cloneDeep(this.configData.activeViewItem));
      if (this.existCartData && this.existCartData.items && existFind) {
        this.onCartMatchItem.quantity = this.configData.activeViewItem.quantity;
        if (iscObj && this.onCartMatchItem.basketItemVariants) {
          this.onCartMatchItem.basketItemVariants.forEach((o) => {
            if (o.itemColorStyleID === iscObj.recordID.toString()) {
              o.quantity = iscObj.quantity;
            }
          });
        }
        const basketitemvariants = this.onCartMatchItem.basketItemVariants.find((o) => o.itemColorStyleID === iscObj.recordID.toString());
        console.log(basketitemvariants);
        if (iscObj.quantity && iscObj.quantity !== 0 && basketitemvariants) {
          await this.updatescsItemFromCart(this.onCartMatchItem, basketitemvariants).then((res) => { });
        } else if (iscObj.quantity === 0 && basketitemvariants) {
          await this.bivService.deleteItemVariant([basketitemvariants.recordID]);
        }
        this.saveItemVariantToBasketEndpoint(index + 1);
      } else {
        if (iscObj.quantity && iscObj.quantity !== 0) {
          const retailerID: string = this.configData.selectedCustomer.recordID;
          const selectedShipTo: string = this.configData.selectedCustomer.selectedShipTo.recordID;
          await this.bivService.saveItemVariantToBasket(retailerID, selectedShipTo, this.configData.activeViewItem, iscObj);
          this.configData.itemMfgIDs.push({
            itemNumber: this.configData.activeViewItem.itemNumber,
            manufacturerID: this.configData.activeViewItem.manufacturerID,
            itemColorStyleID: iscObj.recordID.toString()
          });
          this.msgService.clear();
          this.msgService.add({
            key: this.fromPage === 'WHATS_TRENDING_PAGE' ? 'addItem2' : 'addItem',
            severity: 'info',
            summary: 'Item Added to Cart',
            detail: `${this.configData.activeViewItem.itemNumber} was saved successfully.`
          });
        }
        this.saveItemVariantToBasketEndpoint(index + 1);
      }
      if (this.configData.activeViewItem.isc && this.configData.activeViewItem.isc.length - 1 === index) {
        if (this.linkedItemsData && this.linkedItemsData.length) {
          const index = 0;
          this.saveItemsToBasketEndpoint(index);
        } else {
          this.cartData = await this.getCart(this.selectedCustomer);
          this.existCartData = this.cartData;
          this.setMfgMinimumMet(this.existCartData.manufacturer);
          this.itemMfgIDs = this.getItemWithMfg(this.existCartData);
          this.configData.itemMfgIDs = this.itemMfgIDs;
          // console.log('saveItemVariantToBasketEndpoint final', this.configData.activeViewItem, this.existCartData, this.itemMfgIDs);
          this.addToCartEvent.emit({ cartData: this.cartData, itemMfgIDs: this.itemMfgIDs });
          this.onCartMatchItem = _.cloneDeep(this.existCartData.items.find(o => o.item.recordID
            === this.configData.activeViewItem.recordID));
          this.orderService.removeAddBtnDisable(`${this.configData.activeViewItem.recordID}`);
        }
      }
      // console.log('ending saveItemVariantToBasketEndpoint');
    }
  }
  async getCart(customer) {
    console.log('getCart 2');
    const cartData = await this.basketItemService.getBasketItemData(customer, this.sortOrder);
    // console.log(cartData);
    this.itemMfgIDs = this.getItemWithMfg(cartData);
    // console.log(this.itemMfgIDs);
    this.configData.itemMfgIDs = this.itemMfgIDs;
    // console.log(this.configData);
    this.auth.setCartItemCount(cartData.totalItems);
    return cartData;
  }
  async deleteItemFromCart(itemObj: any, existCartData, isItemLinked = false) {
    const cloneExistCartData = _.cloneDeep(existCartData);
    // console.log('existCartData:', this.existCartData, cloneExistCartData, itemObj);
    await this.basketItemService.deleteBasketItemItem([itemObj.recordID]).then((res) => {
      this.existCartData = _.remove(cloneExistCartData.items, (o: any) => o.recordID === itemObj.recordID);
      this.itemMfgIDs = this.getItemWithMfg(cloneExistCartData);
      this.configData.itemMfgIDs = this.itemMfgIDs;
      if (!isItemLinked) {
        this.saveItemToBasketEndpoint();
      }
    });
    this.addToCartEvent.emit({ cartData: cloneExistCartData, itemMfgIDs: this.itemMfgIDs });
  }

  // [B2B-179] (RM 20-MAR-2020): store thumbnail image list
  async getThumbnailImageData(data) {
    const thumbImageData = _.cloneDeep(data.itemImagePaths);
    this.thumbImageData = _.filter(thumbImageData, (o, i) => {
      if (o.picturePath && !o.recordDeleted) {
        o.fullPicturePath = this.itemsAPIService.getFullPicturePath(o.picturePath, data.manufacturerID); // + '?' + new Date().getTime();
        o.activeImgThumb = false;
        o.activeImgThumbLoader = true;
        o.activeImgThumbError = true;
        return o;
      }
    });
  }

  // [B2B-179] (RM 20-MAR-2020) onclick on thumb image >> image path set big screen
  async selectThumbImageData(thumbImage) {
    if (thumbImage) {
      if (!thumbImage.activeImgThumb) {
        await this.resetThumbImageData();
        thumbImage.activeImgThumb = true;
        this.selectedThumbImageData = thumbImage;
      } else {
        thumbImage.activeImgThumb = false;
        this.selectedThumbImageData = null;
      }
    } else {
      this.resetThumbImageData();
      this.selectedThumbImageData = null;
    }
  }
  // [B2B-179] (RM 20-MAR-2020) reset value >> activeImgThumb = false
  resetThumbImageData() {
    this.thumbImageData = _.map(this.thumbImageData, (o, i) => { o.activeImgThumb = false; return o; });
    this.selectedThumbImageData = null;
  }

  // [B2B-196] (RM 26-03-2020): Display linkedItems
  // [B2B-219] (RM 02-04-2020): Get itemlist api calling.
  async getlinkedItemsData(data) {
    this.linkedItemsData = [];
    const repGroupID = this.rtSharedService.getRepGroupID();
    const retailerID = this.rtSharedService.getRetailerID();
    const payload: any = {
      repGroupID,
      retailerID,
      manufacturerID: data.manufacturerID,
      itemNumber: data.itemNumber,
    };
    if (this.auth.getRetailerTerritoryFilterFlag() === true) {
      payload.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
    }
    this.linkedItemsLoader = true;
    this.itemsAPIService.getLinkedItems(payload)
      .subscribe((response: any) => {
        this.linkedItemsLoader = false;
        if (response.success) {
          const linkedItems = response.data;
          this.linkedItemsData = _.map(linkedItems, (o: any) => {
            o.fullPicturePath = this.itemsAPIService.getFullPicturePath(o.picturePath, o.manufacturerID);
            o.activeImglinked = false;
            o.activeImglinkedLoader = true;
            o.activeImglinkedError = false;
            o.quantity = o.quantity ? o.quantity : 0;
            if (!o.showLockedB2bItems && (o.b2bItemPricingPolicy === 'NeedBrandApproval' || o.b2bItemPricingPolicy === 'HideItemPrice')) {
              o.b2bItemPricingPolicyFlag = true;
            } else {
              o.b2bItemPricingPolicyFlag = false;
            }
            if (this.fromPage === 'ORDER_PAGE') {
              this.onCartMatchedItem = this.existItemsData.find(k => o.recordID === k.itemID && o.manufacturerID === k.manufacturerID && !k.recordDeleted);
              if (this.onCartMatchedItem && this.onCartMatchedItem.quantity) {
                o.quantity = this.onCartMatchedItem.quantity;
              }
            } else {
              this.onCartMatchedItem = this.existCartData && this.existCartData.items.find(k => k.item.recordID === o.recordID);
              if (this.onCartMatchedItem && this.onCartMatchedItem.recordID) {
                o.quantity = this.onCartMatchedItem.quantity;
                o.item = _.cloneDeep(o);
                o.recordID = this.onCartMatchedItem.recordID;
              }
            }
            return o;
          });
          this.configData.linkedItemsData = this.linkedItemsData;
          if (this.configData.linkedItemsData && this.configData.linkedItemsData.length === 0) {
            this.configData.activeViewItem.quantity = this.configData.activeViewItem.minimumQuantity;
            if (this.onCartMatchItem && this.onCartMatchItem.quantity && this.onCartMatchItem.quantity > 0) {
              this.configData.activeViewItem.quantity = this.onCartMatchItem.quantity;
            }
            this.isAddButtonDisable = this.configData.activeViewItem.quantity === 0;
            console.log('1444', this.isAddButtonDisable);
          }
        } else {
          this.msgService.add({
            severity: 'error', summary: 'Error',
            detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
          });
        }
      }, error => {
        this.linkedItemsLoader = false;
        this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while get linkitems' });
      });
  }

  // [B2B-196] (RM 26-03-2020): selected linked item data show on popup
  async selectlinkedItemsData(linkedItems, linkedItemsStatus) {
    // this.selectedLinkedItemsData = linkedItems;
    this.activeViewItem = linkedItems;
    this.ngOnInit();
  }

  async saveItemsToBasketEndpoint(i) {
    if (this.linkedItemsData && this.linkedItemsData.length > i) {
      const iscObj = this.linkedItemsData[i];
      const index = i;
      const existFind = this.configData.itemMfgIDs.find(o => (iscObj.itemNumber === o.itemNumber &&
        iscObj.manufacturerID === o.manufacturerID));
      this.orderService.setAddBtnDisable(`${iscObj.recordID}`);
      if (this.existCartData && this.existCartData.items && existFind) {
        // this.onCartMatchItem.quantity = iscObj.quantity;
        if (iscObj.quantity && iscObj.quantity !== 0) {
          if (this.validateDateBetweenTwoDates(iscObj)) {
            iscObj['itemDiscount'] = iscObj.discountPercent;
          }
          await this.basketItemService.saveItemToBasket(iscObj, this.selectedCustomer).then(async (res) => {
            // this.msgService.clear();
            this.msgService.add({
              key: this.fromPage === 'WHATS_TRENDING_PAGE' ? 'addItem2' : 'addItem',
              severity: 'info', summary: 'Item Updated to Cart',
              detail: `${iscObj.itemNumber} was saved successfully.`
            });
          }).catch((response) => {
            this.msgService.add({
              key: this.fromPage === 'WHATS_TRENDING_PAGE' ? 'addItem2' : 'addItem',
              severity: 'error', summary: 'Error',
              detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
            });
          });
        } else if (iscObj.quantity === 0) {
          const cloneExistCartData = _.cloneDeep(this.existCartData);
          await this.basketItemService.deleteBasketItemItem([iscObj.recordID]);
        }
        this.saveItemsToBasketEndpoint(index + 1);
      } else {
        if (iscObj.quantity && iscObj.quantity !== 0) {
          const retailerID: string = this.configData.selectedCustomer.recordID;
          const selectedShipTo: string = this.configData.selectedCustomer.selectedShipTo.recordID;
          console.log('6255 4', iscObj);
          if (this.validateDateBetweenTwoDates(iscObj)) {
            iscObj['itemDiscount'] = iscObj.discountPercent;
          }
          await this.basketItemService.saveItemToBasket(iscObj, this.selectedCustomer).then(async (res) => {
            this.msgService.clear();
            this.msgService.add({
              key: this.fromPage === 'WHATS_TRENDING_PAGE' ? 'addItem2' : 'addItem',
              severity: 'info',
              summary: 'Item Added to Cart',
              detail: `${iscObj.itemNumber} was saved successfully.`
            });
          });
        }
        this.saveItemsToBasketEndpoint(index + 1);
      }
      if (this.linkedItemsData && this.linkedItemsData.length - 1 === index) {
        this.cartData = await this.getCart(this.selectedCustomer);
        this.existCartData = this.cartData;
        this.setMfgMinimumMet(this.existCartData.manufacturer);
        this.itemMfgIDs = this.getItemWithMfg(this.existCartData);
        this.configData.itemMfgIDs = this.itemMfgIDs;
        this.addToCartEvent.emit({ cartData: this.cartData, itemMfgIDs: this.itemMfgIDs });
        this.onCartMatchedItem = _.cloneDeep(this.existCartData.items.find(o => o.item.recordID
          === this.linkedItemsData[index].recordID));
        this.orderService.removeAddBtnDisable(`${this.linkedItemsData[index].recordID}`);
      }
    }
  }

  sendRequest() {
    this.isAddButtonDisable = true;
    console.log('1444', this.isAddButtonDisable);
    const mfgid = this.activeViewItem.manufacturerID;
    this.manufacturerService.sendMfrRequest({ recordID: mfgid }).subscribe((res: any) => {
      this.isAddButtonDisable = false;
      console.log('1444', this.isAddButtonDisable);
      this.configData.activeViewItem.retailerRequestStatus = 0;
      this.msgService.add({
        key: this.fromPage === 'WHATS_TRENDING_PAGE' ? 'addItem2' : 'addItem',
        severity: 'info',
        summary: 'Send Request',
        detail: `Send Request successfully.`
      });
    }, (error) => {
      this.isAddButtonDisable = false;
      console.log('1444', this.isAddButtonDisable);
      this.msgService.add({ key: 'request', severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    });
  }

  commonQtyManageFun(itemObj) {
    const nearsetItemObj = Math.round(itemObj.quantity / itemObj.quantityIncrement) * itemObj.quantityIncrement;

    if (itemObj.quantity > 0 && itemObj.minimumQuantity > itemObj.quantity) {
      this.configData.activeViewItem.quantity = itemObj.minimumQuantity;
    } else if (itemObj.quantity > 0 &&
      nearsetItemObj.toString() !== itemObj.quantity.toString() &&
      itemObj.quantity.toString() !== itemObj.minimumQuantity.toString()) {
      console.log('--------->1');
      setTimeout(() => {
        if (document.getElementById('YesButton')) {
          document.getElementById('YesButton').focus();
        }
      }, 0);
      this.confirmationService.confirm({
        message: `This process will break the manufacturer set increment quantity for this item.
        <br><br>Click 'Yes' to accept quantity of ${nearsetItemObj}.
        <br>Click 'No' to go back.`,
        header: 'Add Cart Item',
        icon: 'pi pi-exclamation-triangle',
        key: 'breckincrement1',
        accept: async () => {
          this.configData.activeViewItem.quantity = nearsetItemObj;
          _.forEach(this.configData.activeViewItem.isc, (data, inx) => {
            this.configData.activeViewItem.isc[inx].quantity = nearsetItemObj;
          });
          this.onCartMatchItemFun(this.configData.activeViewItem.quantity);
        },
        reject: () => {
          this.configData.activeViewItem.quantity = this.oldQty;
          _.forEach(this.configData.activeViewItem.isc, (data, inx) => {
            this.configData.activeViewItem.isc[inx].quantity = this.oldQty;
          });
          this.onCartMatchItemFun(this.configData.activeViewItem.quantity);
        },
      });
    }
  }

  onCartMatchItemFun(qty) {
    if (this.onCartMatchItem) {
      this.onCartMatchItem.quantity = qty;
      this.onCartMatchItem.item.quantity = qty;
    }
  }
  commonQtyManageFunVariation(itemObj, index) {
    const nearsetItemObj = Math.round(itemObj.isc[index].quantity / itemObj.quantityIncrement) * itemObj.quantityIncrement;
    if (itemObj.isc[index].quantity > 0 && itemObj.minimumQuantity > itemObj.isc[index].quantity) {
      console.log('--------->2');
      setTimeout(() => {
        if (document.getElementById('YesButton')) {
          document.getElementById('YesButton').focus();
        }
      }, 0);
      this.confirmationService.confirm({
        // <br>Click 'Ignore' to set quantity as ${itemObj.isc[index].quantity}.
        message: `This process will break the manufacturer set increment quantity for this item.
        <br><br>Click 'Yes' to accept quantity of ${itemObj.minimumQuantity}.
        <br>Click 'No' to go back.`,
        header: 'Add Cart Item',
        icon: 'pi pi-exclamation-triangle',
        key: 'breckincrement1',
        accept: async () => {
          this.configData.activeViewItem.isc[index].quantity = itemObj.minimumQuantity;
          let calculatAllQty = 0;
          _.forEach(itemObj.isc, (data, inx) => {
            calculatAllQty += data.quantity;
          });
          this.configData.activeViewItem.quantity = calculatAllQty;
        },
        reject: (type) => {
          this.configData.activeViewItem.isc[index].quantity = 0;
          let calculatAllQty = 0;
          _.forEach(itemObj.isc, (data, inx) => {
            calculatAllQty += data.quantity;
          });
          this.configData.activeViewItem.quantity = calculatAllQty;
        },
      });

    } else if (itemObj.isc[index].quantity > 0 &&
      nearsetItemObj.toString() !== itemObj.isc[index].quantity.toString() &&
      itemObj.isc[index].quantity.toString() !== itemObj.minimumQuantity.toString()) {
      // <br>Click 'Ignore' to set quantity as ${itemObj.isc[index].quantity}.
      console.log('--------->3');
      setTimeout(() => {
        if (document.getElementById('YesButton')) {
          document.getElementById('YesButton').focus();
        }
      }, 0);
      this.confirmationService.confirm({
        message: `This process will break the manufacturer set increment quantity for this item.
        <br><br>Click 'Yes' to accept quantity of ${nearsetItemObj}.
        <br>Click 'No' to go back.`,
        header: 'Add Cart Item',
        icon: 'pi pi-exclamation-triangle',
        key: 'breckincrement1',
        accept: async () => {
          this.configData.activeViewItem.isc[index].quantity = nearsetItemObj;
          let calculatAllQty = 0;
          _.forEach(itemObj.isc, (data, inx) => {
            calculatAllQty += data.quantity;
          });
          this.configData.activeViewItem.quantity = calculatAllQty;
        },
        reject: (type) => {
          if (type === 1) {
            this.configData.activeViewItem.isc[index].quantity = itemObj.isc[index].quantity;
          } else {
            this.configData.activeViewItem.isc[index].quantity = this.oldQty;
            let calculatAllQty = 0;
            _.forEach(itemObj.isc, (data, inx) => {
              calculatAllQty += data.quantity;
            });
            this.configData.activeViewItem.quantity = calculatAllQty;
          }
        },
      });
    }
  }

  navigateItemDetails(e, id) {
    if (this.auth.getIDToken()) {
      this.router.navigate([`/shop`], { queryParams: { itemdetails: id } });
    } else {
      this.router.navigate([`/store`], { queryParams: { itemdetails: id } });
    }
  }

  onclickSaveOldQtysimillar(quantity: any) {
    this.oldQtySimillar = 0;
    this.oldQtySimillar = quantity;
  }
  commonQtyManageFunSimilar(itemObj, index) {
    const nearsetItemObj = Math.round(itemObj[index].quantity / itemObj[index].quantityIncrement) * itemObj[index].quantityIncrement;
    if (itemObj[index].quantity > 0 && itemObj[index].minimumQuantity > itemObj[index].quantity) {
      console.log('--------->4');
      setTimeout(() => {
        if (document.getElementById('YesButton')) {
          document.getElementById('YesButton').focus();
        }
      }, 0);
      this.confirmationService.confirm({
        message: `This process will break the manufacturer set increment quantity for this item.
        <br><br>Click 'Yes' to accept quantity of ${itemObj[index].minimumQuantity}.
        <br>Click 'No' to go back.`,
        header: 'Add Cart Item',
        icon: 'pi pi-exclamation-triangle',
        key: 'breckincrement1',
        accept: async () => {
          this.linkedItemsData[index].quantity = itemObj[index].minimumQuantity;
        },
        reject: (type) => {
          if (type === 1) {
          } else {
            this.linkedItemsData[index].quantity = this.oldQtySimillar;
          }
        },
      });

    } else if (this.linkedItemsData[index].quantity > 0 &&
      nearsetItemObj.toString() !== this.linkedItemsData[index].quantity.toString() &&
      this.linkedItemsData[index].quantity.toString() !== this.linkedItemsData[index].minimumQuantity.toString()) {
      console.log('--------->5');
      setTimeout(() => {
        if (document.getElementById('YesButton')) {
          document.getElementById('YesButton').focus();
        }
      }, 0);
      this.confirmationService.confirm({
        message: `This process will break the manufacturer set increment quantity for this item.
        <br><br>Click 'Yes' to accept quantity of ${nearsetItemObj}.
        <br>Click 'No' to go back.`,
        header: 'Add Cart Item',
        icon: 'pi pi-exclamation-triangle',
        key: 'breckincrement1',
        accept: async () => {
          this.linkedItemsData[index].quantity = nearsetItemObj;
        },
        reject: (type) => {
          if (type === 1) {
            // this.linkedItemsData[index].quantity = itemObj.isc[index].quantity;
          } else {
            this.linkedItemsData[index].quantity = this.oldQtySimillar;
          }
        },
      });
    }
  }

  validateDateBetweenTwoDates(data) {
    const from = new Date(new RtDatePipe(this.dp).transform(data.discountStartDateString));
    const to = new Date(new RtDatePipe(this.dp).transform(data.discountEndDateString));
    const check = new Date(new RtDatePipe(this.dp).transform(moment(new Date()).format('MM-DD-YYYY')));
    return check <= to && check >= from;
  }

  commetsDialog() {
    this.commets_Dialog = true;
    this.showReviewLoader = true;
    const data = _.cloneDeep(this.activeViewItem);
    const repGroupID = this.rtSharedService.getRepGroupID();
    const retailerID = this.rtSharedService.getRetailerID();
    const payload: any = {
      repGroupID,
      manufacturerID: data.manufacturerID,
      itemNumber: data.itemNumber,
    };
    this.itemsAPIService.getAllReviews(payload).subscribe((res: any) => {
      if (res.success) {
        this.getAllReviews = res.data
      }
      this.showReviewLoader = false;
    }, (error: any) => {
      this.saveLoading = false;
      this.checkAvailability = false;
      this.showReviewLoader = false;
      this.msgService.add({ key: 'top-right', severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred while get item all review') });
    });
  }
  editCommetsDialog() {
    this.edit_commets = true;
  }
  async checkReviewOrRateAvailability(): Promise<any> {
    console.log('checkReviewOrRateAvailability');
    const data = _.cloneDeep(this.activeViewItem);
    const repGroupID = this.rtSharedService.getRepGroupID();
    const retailerID = this.rtSharedService.getRetailerID();
    const payload: any = {
      repGroupID,
      retailerID,
      manufacturerID: data.manufacturerID,
      itemNumber: data.itemNumber,
      type: 'item'
    };
    this.itemsAPIService.checkReviewOrRateAvailability(payload).subscribe((response: any) => {
      if (response && response.success) {
        this.checkAvailability = true;
        this.getReviewMfg();
        this.getItemsRating();
      } else {
        this.checkAvailability = false;
      }
    }, (error: any) => {
      console.log(error);
      this.msgService.add({ key: 'top-right', severity: 'error', summary: 'Error', detail: 'Error occurred while get Items Rating' });
    });
  }
  async getAvgItemsRating(): Promise<any> {
    const data = _.cloneDeep(this.activeViewItem);
    const repGroupID = this.rtSharedService.getRepGroupID();
    const payload: any = {
      repGroupID,
      manufacturerID: data.manufacturerID,
      itemNumber: data.itemNumber,
    };
    this.itemsAPIService.getAvgItemsRating(payload).subscribe((response: any) => {
      if (response && response.success) {
        this.avgItemsRating = response.data.avarageRate;
        this.totalRate = response.data.totalRate;
        this.totalReview = response.data.totalReview;
        this.oneStarPerc = response.data.star1;
        this.twoStarPerc = response.data.star2;
        this.threeStarPerc = response.data.star3;
        this.fourStarPerc = response.data.star4;
        this.fiveStarPerc = response.data.star5;
      } else {
        this.avgItemsRating = 0;
        this.totalRate = 0;
        this.totalReview = 0;
        this.oneStarPerc = 0;
        this.twoStarPerc = 0;
        this.threeStarPerc = 0;
        this.fourStarPerc = 0;
        this.fiveStarPerc = 0;
      }
    }, (error: any) => {
      this.avgItemsRating = 0;
      this.totalRate = 0;
      this.totalReview = 0;
      this.oneStarPerc = 0;
      this.twoStarPerc = 0;
      this.threeStarPerc = 0;
      this.fourStarPerc = 0;
      this.fiveStarPerc = 0;
      this.msgService.add({ key: 'top-right', severity: 'error', summary: 'Error', detail: 'Error occurred while get Avg Items Rating' });
    });
  }
  commafy(num: any) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  async getItemsRating(): Promise<any> {
    this.ratingLoader = true;
    const data = _.cloneDeep(this.activeViewItem);
    const repGroupID = this.rtSharedService.getRepGroupID();
    const retailerID = this.rtSharedService.getRetailerID();
    const payload: any = {
      repGroupID,
      retailerID,
      manufacturerID: data.manufacturerID,
      itemNumber: data.itemNumber,
    };
    this.itemsAPIService.getItemsRating(payload).subscribe((response: any) => {
      console.log('getItemsRating', response);
      if (response && response.success) {
        this.myItemsRating = response.data.rating;
        this.myItemsRatingObj = response.data;
      } else {
        this.myItemsRating = 0;
        this.myItemsRatingObj = null;
      }
      this.ratingLoader = false;
    }, (error: any) => {
      this.myItemsRating = 0;
      this.myItemsRatingObj = null;
      this.ratingLoader = false;
      this.msgService.add({ key: 'top-right', severity: 'error', summary: 'Error', detail: 'Error occurred while get Items Rating' });
    });
  }
  async saveItemsRating(rating: any): Promise<any> {
    this.ratingLoader = true;
    const data = _.cloneDeep(this.activeViewItem);
    const repGroupID = this.rtSharedService.getRepGroupID();
    const retailerID = this.rtSharedService.getRetailerID();
    const payload: any = {
      repGroupID,
      retailerID,
      manufacturerID: data.manufacturerID,
      itemNumber: data.itemNumber,
      rating: rating
    };
    if (this.myItemsRatingObj && this.myItemsRatingObj.recordID) {
      payload.recordID = this.myItemsRatingObj.recordID;
    }
    this.onDestroy.next();
    this.itemsAPIService.saveItemsRating(payload).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      console.log('saveItemsRating', response);
      if (response.success) {
        // this.msgService.add({
        //   severity: 'info', key: 'top-right',
        //   summary: 'Save Rating',
        //   detail: `Rating save successfully.`
        // });
        this.getAvgItemsRating();
        this.getItemsRating();
      } else {
        this.msgService.add({
          severity: 'error', summary: 'Error', key: 'top-right',
          detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
        });
      }
      this.ratingLoader = false;
    }, (error: any) => {
      this.ratingLoader = false;
      this.msgService.add({ key: 'top-right', severity: 'error', summary: 'Error', detail: 'Error occurred while save Items Rating' });
    });
  }
  isCloseReviews(eventObj: any) {
    if (eventObj) {
      this.itemReview = _.cloneDeep(this.itemGetReview);
    }
  }
  getReviewMfg() {
    const data = _.cloneDeep(this.activeViewItem);
    const repGroupID = this.rtSharedService.getRepGroupID();
    const retailerID = this.rtSharedService.getRetailerID();
    const payload: any = {
      repGroupID,
      retailerID,
      manufacturerID: data.manufacturerID,
      itemNumber: data.itemNumber,
    };
    this.itemsAPIService.getReview(payload).subscribe((res: any) => {
      if (res.success) {
        this.itemGetReview = res.data.review;
        this.itemReview = res.data.review;
        this.reviewRecordID = res.data.recordID;
      }
      else {
        this.reviewRecordID = '';
        this.itemGetReview = '';
        this.itemReview = '';
      }
    }, (error: any) => {
      this.reviewRecordID = '';
      this.saveLoading = false;
      this.msgService.add({ key: 'top-right', severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred while get item review') });
    });
  }
  saveReviewMfg(isdelete = 0) {
    if (this.itemReview) {
      this.saveLoading = true;
      if (isdelete) {
        this.reviewDeleteLoader = true;
      }
      const data = _.cloneDeep(this.activeViewItem);
      const repGroupID = this.rtSharedService.getRepGroupID();
      const retailerID = this.rtSharedService.getRetailerID();
      const payload: any = {
        recordID: this.reviewRecordID ? this.reviewRecordID : '',
        repGroupID,
        retailerID,
        manufacturerID: data.manufacturerID,
        itemNumber: data.itemNumber,
        review: this.itemReview,
      };
      if (isdelete) {
        payload.recordDeleted = 1;
      }
      this.itemsAPIService.saveReview(payload).subscribe((res: any) => {
        if (res.success) {
          this.msgService.add({
            key: 'top-right',
            severity: 'info',
            summary: isdelete ? 'Delete Review' : 'Save Review',
            detail: isdelete ? `Review deleted successfully.` : `Review saved successfully.`
          });
          this.edit_commets = false;
          this.getReviewMfg();
          this.getAvgItemsRating();
        }
        else {
          this.msgService.add({ key: 'top-right', severity: 'error', summary: 'Error', detail: 'Error occurred while save item review' });
        }
        this.saveLoading = false;
        this.reviewDeleteLoader = false;
      }, (error: any) => {
        this.saveLoading = false;
        this.reviewDeleteLoader = false;
        this.msgService.add({ key: 'top-right', severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred while save item review') });
      });
    } else {
      this.msgService.clear();
      this.msgService.add({ key: 'top-right', detail: 'Review is required', severity: 'warn' });
      this.saveLoading = false;
      this.reviewDeleteLoader = false;
    }
  }
  formatDate(dateValue: any): any {
    if (dateValue !== '' && dateValue !== null) {
      return moment(dateValue).format('MM-DD-YYYY');
    }
    return '';
  }
  removeReview() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this review ?',
      header: 'Delete',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      key: 'review-delete-dialog',
      accept: () => {
        this.saveReviewMfg(1);
      },
      reject: () => {
      }
    });
  }

  isVideo(path) {
    try {
      if (path && typeof (path) === 'object') {
        if (path.fullPicturePath && path.fullPicturePath.includes('.mp4') || path.fullPicturePath && path.fullPicturePath.includes('video/mp4')) {
          return true;
        } else {
          return false;
        }
      } else {
        if (path && (path.includes('.mp4') || path.includes('video/mp4'))) {
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {

    }
  }

  get360Images(itemRecordId: any) {
    this.imageAngles = 0;
    this.itemsAPIService.get360Images(itemRecordId).subscribe((res: any) => {
      if (res['success']) {
        this.item360Images = [];
        _.forEach(_.cloneDeep(res['data']), (o, i) => {
          const picturePath = `${environment.image360BucketUrl}${this.manufacturerID}/${o.picturePath}`;
          this.item360Images.push(picturePath);
        });
        this.images360Preview = { 0: this.item360Images };
        this.imageAngles = this.item360Images.length;
      }
    }, (error) => { });
  }
  view360Preview() {
    this.display360Preview = true;
  }
  onPreloaded() {
    this.is360ViewLoaded = true;
  }
}
