<div class="reptime-theme success-dialog-pad">
    <!-- Add RepgroupName to the listing {{domainData.rgName}} -->
    <h3 class="text-center" style="font-size: 18px;"><i class="fa fa-check-square-o" style="font-size: 60px;"></i><br>Thank you for ordering with {{domainData.rgName}}.</h3>
    <div class="text-center" style="font-size: 16px;">Please allow 24-48 hours for order processing. Contact your {{domainData.rgName}} sales representative if you have any 
        questions or need to alter {{thanksMessage}}. Thank you again!</div><br><br>

    <p-card class="card">
        <p-table [columns]="cols" [value]="orderList" class="success-tbl-dialog" [responsive]="false">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                    <th width="50px"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchCase="'manufacturer'" style="min-width: 110px">
                                {{rtSharedService.getSeletedLanguage(rowData.manufacturer)}} 
                            </div>
                            <div *ngSwitchDefault>
                                {{rowData[col.field]}}
                            </div>
                            <div *ngSwitchCase="'total'">
                                {{rowData[col.field] | rtCurrency}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <i class="fa fa-fw fa-file-pdf-o" (click)="previewOrder(rowData)" pTooltip="Download PDF" tooltipPosition="left" style="font-size:20px;color:red;"></i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>

    <div class="text-center p-5">
        <button class="ui-button-info m-5 btn-theme-color"
                (click)="close();"
                icon="fa fa-home"
                label="GO TO DASHBOARD"
                pButton type="button"></button>
    </div>

</div>
