<div class="reptime-theme edit-bilto">
    <form (submit)="saveRetailerInfo()" [formGroup]="editRetailerForm" autocomplete="off">
        <div style="position: fixed; top:0;left:0;z-index: 5000" #multiDropdownContainer></div>
        <div class="p-grid" style="padding: 5px;">
            <div class="p-col-6">
                <label for="name">Name*</label>
                <div class="input-error-container">
                    <input class="control" formControlName="name" id="name" maxlength="50" pInputText
                        appCustomValidation [allowStartWithSpace]="false" autocomplete="new-password" type="text" />
                    <i *ngIf="validateChk.name.invalid && (validateChk.name.dirty || validateChk.name.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.name,'Name')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6"><label for="email">Email*</label>
                <div class="input-error-container">
                    <input class="control" formControlName="email" id="email" pInputText autocomplete="new-password"
                        appCustomValidation [allowStartWithSpace]="false" type="text" />
                    <i *ngIf="validateChk.email.invalid && (validateChk.email.dirty || validateChk.email.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.email,'Email')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="address1">Address 1</label>
                <input class="control" formControlName="address1" id="address1" pInputText autocomplete="new-password"
                    appCustomValidation [allowStartWithSpace]="false" type="text" maxlength="100" />
            </div>
            <div class="p-col-6">
                <label for="address2">Address 2</label>
                <input class="control" formControlName="address2" id="address2" pInputText autocomplete="new-password"
                    appCustomValidation [allowStartWithSpace]="false" type="text" maxlength="50" />
            </div>

            <div class="p-col-6">
                <label for="country">Country*</label> <br />
                <div class="input-error-container">
                    <p-dropdown (onChange)="countrySpecific()" [filter]="true" [options]="countries"
                        [style]="{'width':'100%'}" class="control-dropdown" formControlName="country" id="country">
                    </p-dropdown>
                    <i *ngIf="validateChk.country.invalid && (validateChk.country.dirty || validateChk.country.touched)"
                        class="error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.country,'country Name')" tooltipPosition="left"
                        tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="zip">{{validateChk['country'].value === 'US' ? 'Zip Code*' : 'Zip Code'}}</label>
                <div class="input-error-container">
                    <input class="control" formControlName="zip" id="zip"
                        maxlength="{{validateChk['country'].value === 'US'?5:20}}" pInputText appCustomValidation
                        [allowStartWithSpace]="false" autocomplete="new-password" type="text" (blur)="updateChange()">
                    <i *ngIf="validateChk.zip.invalid && (validateChk.zip.dirty || validateChk.zip.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.zip,'Zip')"
                        tooltipPosition="left" tooltipEvent="hover">
                    </i>
                </div>
            </div>
            <div class="p-col-6">
                <!-- <div *ngIf="validateChk['country'].value !== 'US';then content else other_content"></div> -->
                <div
                    *ngIf="countriesStateJSON && countriesStateJSON[validateChk['country'].value] && countriesStateJSON[validateChk['country'].value].length;then other_content else content">
                </div>
                <ng-template #content>
                    <label for="non-us-state">State</label>
                    <input class="control" formControlName="state" appCustomValidation [allowStartWithSpace]="false"
                        autocomplete="new-password" id="non-us-state" maxlength="50" name="state" pInputText
                        type="text">
                </ng-template>

                <ng-template #other_content>
                    <label for="us-state">State*</label>
                    <p-dropdown [filter]="true" [options]="countriesStateJSON[validateChk['country'].value]"
                        [style]="{'width':'100%'}" class="control-dropdown" formControlName="state" id="us-state">
                    </p-dropdown>
                </ng-template>
            </div>
            <div class="p-col-6">
                <label for="city">City*</label>
                <input class="control" formControlName="city" id="city" maxlength="50" pInputText appCustomValidation
                    [allowStartWithSpace]="false" autocomplete="new-password" type="text">
            </div>
            <div class="p-col-3 resp-e50"><label for="phone">Phone*</label>
                <div class="input-error-container">
                    <p-inputMask [unmask]="true" formControlName="phone" styleClass="control" minlength="10" maxlength="12"  placeholder="Phone" id="phone" mask="999-999-9999?99" autocomplete="off" type="text"></p-inputMask>
                    <!-- <input class="control" formControlName="phone" id="phone" maxlength="12" minlength="10" pInputText
                        pKeyFilter="pnum" autocomplete="off" type="text"> -->
                    <i *ngIf="validateChk.phone.invalid && (validateChk.phone.dirty || validateChk.phone.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.phone,'Phone')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>

            </div>
            <div class="p-col-3 resp-e50"><label for="phoneExtension"> Ext #</label>
                <input [maxlength]="10" autocomplete="off" class="control" formControlName="phoneExtension"
                    appCustomValidation [allowStartWithSpace]="false" id="phoneExtension" pInputText size="3"
                    autocomplete="new-password" type="text" />
            </div>
            <div class="p-col-3 resp-e50"><label for="fax">Fax </label>
                <p-inputMask [unmask]="true" formControlName="fax"
                    [style]="{'width':'100%','border':'1px solid #628292'}" id="fax" mask="999-999-9999"
                    autocomplete="off"></p-inputMask>
            </div>
            <div class="p-col-3 resp-e50"><label for="faxExtension">Ext # </label>
                <input [maxlength]="10" class=" control" formControlName="faxExtension" id="faxExtension" pInputText
                    appCustomValidation [allowStartWithSpace]="false" size="3" autocomplete="new-password"
                    type="text" />
            </div>
            <div class="p-col-4 resp-e50">
                <label for="federalTaxNumber">Resale ID/Federal Tax Number (EIN)</label>
                <div class="input-error-container">
                    <input class="control" formControlName="federalTaxNumber" autocomplete="new-password"
                        appCustomValidation [allowStartWithSpace]="false" id="federalTaxNumber" pInputText
                        type="text" />
                    <i *ngIf="validateChk.federalTaxNumber.invalid && (validateChk.federalTaxNumber.dirty || validateChk.federalTaxNumber.touched)"
                        class="error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.federalTaxNumber,'Federal Tax Number')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-4 resp-e50">
                <label for="salesTaxNumber">Sales Tax Number</label>
                <div class="input-error-container">
                    <input class="control" formControlName="salesTaxNumber" autocomplete="new-password"
                        appCustomValidation [allowStartWithSpace]="false" id="salesTaxNumber" pInputText type="text" />
                    <i *ngIf="validateChk.salesTaxNumber.invalid && (validateChk.salesTaxNumber.dirty || validateChk.salesTaxNumber.touched)"
                        class="error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.salesTaxNumber,'Sales Tax Number')" tooltipPosition="left"
                        tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-4 resp-e50">
                <label for="stateTaxNumber">State Tax Number</label>
                <div class="input-error-container">
                    <input class="control" formControlName="stateTaxNumber" autocomplete="new-password"
                        appCustomValidation [allowStartWithSpace]="false" id="stateTaxNumber" pInputText type="text" />
                    <i *ngIf="validateChk.stateTaxNumber.invalid && (validateChk.stateTaxNumber.dirty || validateChk.stateTaxNumber.touched)"
                        class="error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.stateTaxNumber,'State Tax Number')" tooltipPosition="left"
                        tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6" *ngIf="fromPage !== 'ORDER_PAGE'"><label for="website">Website</label>
                <div class="input-error-container">
                    <input class=" control" formControlName="website" id="website" pInputText appCustomValidation
                        [allowStartWithSpace]="false" autocomplete="new-password" type="text" />
                    <i *ngIf="validateChk.website.invalid && (validateChk.website.dirty || validateChk.website.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.website,'Website')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <!-- <div class="p-col-6">
                <label for="retailerToDivision">Assign Retailer To Division</label><br>
                <p-dropdown autoWidth="false" [style]="{'width':'100%'}" id="retailerToDivision"
                            formControlName="repGroupDivision"
                            [options]="repgroupDivisionList"
                            placeholder="Select Division"></p-dropdown>
            </div>
            <div class="p-col-6"><label for="status">Status*</label> <br/>
                <p-selectButton [options]="statusList" formControlName="retailerStatus" id="status"></p-selectButton>
            </div> -->
        </div>
        <div class="p-col-12 RAlign" *ngIf="fromPage !== 'ORDER_PAGE'">
            <button type="submit" class="ui-button ui-button-info btn-theme-color" pButton label="Save"
                icon="fa fa-save"></button>
        </div>
        <div class="p-col-12 RAlign" *ngIf="fromPage === 'ORDER_PAGE'">
            <button type="submit" class="ui-button ui-button-info mx-5 btn-theme-color" pButton
                label="Update Existing"></button>
            <button type="button" (click)="saveOnlyForOrder()" class="ui-button ui-button-success btn-theme-color"
                pButton label="Save Only For Order"></button>
        </div>
    </form>
</div>