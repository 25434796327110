import {MessageService, ConfirmationService, DialogService, Message} from 'primeng/api';
import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, DatePipe, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CookieModule, CookieService} from 'ngx-cookie';
import {environment} from '../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeModule} from './modules/pages/home/home.module';
import {PageNotFoundModule} from './modules/pages/page-not-found/page-not-found.module';
import {FooterComponent} from './components/footer/footer.component';
import {InterceptorService} from './services/other/interceptor/interceptor.service';
// import {RepTimeSharedModule} from 'reptime-shared-modules';
import { DocumentListModule } from './modules/pages/document-list/document-list.module';
import { RTSharedService } from './services/rtshared/rtshared.service';
import { OptionsProviderService, OPTIONS } from 'src/app/services/options-provider/options-provider.service';
import { DataService } from './services/other/data/data.service';
import { HtmlToolsService } from './services/html-tools/html-tools.service';

import { SharedModule } from 'src/app/shared/shared.module';
import { ItemsAPIService } from './services/api/items-api/items-api.service';
import { RetailerAPIService } from './services/api/retailer-api/retailer-api.service';
import { OrderService } from './services/middleware/order/order.service';
import { OrderAPIService } from './services/api/order-api/order-api.service';
import { SalespersonService } from './services/api/salesperson.service';
import { DataAPIService } from './services/api/data-api/data-api.service';
import { RepgroupService } from './services/api/repgroup.service';
import { RetailerService } from './services/api/retailer.service';
import { IntegrationService } from './services/api/integration.service';
import { ManufacturerService } from './services/api/manufacturer.service';
import { BasketItemAPIService } from './services/api/basket-item-api/basket-item-api.service';
import { BasketItemService } from './services/middleware/basket-item/basket-item.service';
import { FinalizeOrderModule } from './modules/pages/finalize-order/finalize-order.module';
import { SidebarComponent } from './modules/pages/sidebar/sidebar.component';
import { CalloutApiService } from './services/api/callout-api/callout-api.service';
import { SidebarApiService } from './services/api/sidebar-api/sidebar-api.service';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {MenuModule} from 'primeng/menu';
import { LoginModule } from './modules/pages/login/login.module';
import { HeaderComponent } from './components/header/header.component';
import { ConfirmDialogModule } from 'primeng/components/confirmdialog/confirmdialog';
import {ShippingLocationNotFoundModule} from './modules/pages/shipping-location-not-found/shipping-location-not-found.module';
import { CustomValidationDirectiveModule } from './directives/custom-validation.directive';
import { LightovationHeaderComponent } from './components/lightovation-header/lightovation-header.component';
import { LightovationFooterComponent } from './components/lightovation-footer/lightovation-footer.component';
import { RtCustomConfirmDialogModule } from './modules/pages/rt-custom-confirmdialog/confirmdialog';
import { RtLanguagePipeModule } from '../app/pipes/rt-language.pipe';

// import { UserInfoComponent } from './modals/user-info/user-info.component';
// B2B-53 (MG 04-FEB-2020): modified to save filtered documents
@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        LightovationHeaderComponent,
        LightovationFooterComponent
        // UserInfoComponent
        // SidebarComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        SharedModule,
        AppRoutingModule,
        LoginModule,
        HomeModule,
        FinalizeOrderModule,
        PageNotFoundModule,
        HttpClientModule,
        DocumentListModule,
        CommonModule,
        FormsModule, ReactiveFormsModule,
        ProgressSpinnerModule,
        MenuModule,
        CookieModule.forRoot(),
        ConfirmDialogModule,
        ShippingLocationNotFoundModule,
        CustomValidationDirectiveModule,
        RtCustomConfirmDialogModule,
        RtLanguagePipeModule
        // RepTimeSharedModule.forRoot({
        //     apiUrl: environment.apiUrl,
        //     imageUrl: environment.imageBucketUrl,
        //     projectName: 'B2B'
        // })
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
        {provide: OPTIONS, useValue: {}},
        DatePipe,
        RTSharedService,
        MessageService,
        OptionsProviderService,
        InterceptorService,
        CookieService,
        DataService,
        HtmlToolsService,
        ItemsAPIService,
        RetailerAPIService,
        MessageService,
        ConfirmationService,
        DialogService,
        OrderService,
        OrderAPIService,
        SalespersonService,
        DataAPIService,
        RepgroupService,
        RetailerService,
        IntegrationService,
        ManufacturerService,
        BasketItemService,
        BasketItemAPIService,
        CalloutApiService,
        SidebarApiService,
        Title
    ],
    bootstrap: [AppComponent],
    entryComponents: []
})
export class AppModule {}
