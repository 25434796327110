<div class="reptime-theme edit-contactto">
    <form [formGroup]="editContactsForm" autocomplete="off" (ngSubmit)="saveContact()">
        <div class="p-grid p-5" >
            <div class="p-col-4">
                <label for="firstName" class="space-btwn">First Name*</label>
                <div class="input-error-container">
                    <input class="control" formControlName="firstName" id="firstName" maxlength="50"
                        appCustomValidation [allowStartWithSpace]="false"
                        autocomplete="new-password" pInputText type="text" />
                    <i *ngIf="validateChk.firstName.invalid && (validateChk.firstName.dirty || validateChk.firstName.touched)"
                        class="error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.firstName,'First Name')" tooltipPosition="left"
                        tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-4">
                <label for="middleName" class="space-btwn" >Middle Name</label>
                <input class="control" formControlName="middleName" id="middleName" pInputText
                    appCustomValidation [allowStartWithSpace]="false"
                    autocomplete="new-password" type="text" maxlength="50" />
            </div>
            <div class="p-col-4">
                <label for="lastName" class="space-btwn">Last Name</label>
                <div class="input-error-container">
                    <input class="control" formControlName="lastName" id="lastName" autocomplete="new-password"
                        appCustomValidation [allowStartWithSpace]="false"
                        pInputText type="text" maxlength="50" />
                    <i *ngIf="validateChk.lastName.invalid && (validateChk.lastName.dirty || validateChk.lastName.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.lastName,'Last Name')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="title" class="space-btwn">Title</label> <br />
                <input class="control" formControlName="title" id="title" maxlength="50" pInputText
                    appCustomValidation [allowStartWithSpace]="false"
                    autocomplete="new-password" type="text" />
            </div>

            <div class="p-col-6"><label for="cell" class="space-btwn">Cell</label> <br />
                <p-inputMask [unmask]="true" formControlName="cell" autocomplete="new-password" id="cell"
                    [style]="{'border':'1px solid #628292', width:'100%'}" mask="(999) 999-9999"></p-inputMask>
            </div>
            <div class="p-col-3"><label for="phone" class="space-btwn">Phone</label>
                <div class="input-error-container">
                    <!-- <input class="control" formControlName="phone" id="phone" maxlength="12" minlength="10" pInputText pKeyFilter="pnum"
                        autocomplete="off" type="text"> -->
                    <p-inputMask [unmask]="true" formControlName="phone" styleClass="control" minlength="10" maxlength="12"  placeholder="Phone" id="phone" mask="999-999-9999?99" autocomplete="off" type="text"></p-inputMask>
                </div>
            </div>
            <div class="p-col-3"><label for="phoneExtension" class="space-btwn"> Ext #</label><br />
                <input [maxlength]="10" class="control" formControlName="phoneExtension" id="phoneExtension" pInputText
                    appCustomValidation [allowStartWithSpace]="false"
                    autocomplete="new-password" type="text" />
            </div>
            <div class="p-col-3"><label for="fax" class="space-btwn">Fax </label><br />
                <p-inputMask class="inputmask-control" [unmask]="true" formControlName="fax"
                    [style]="{'border':'1px solid #628292'}" id="fax" mask="999-999-9999"></p-inputMask>
            </div>
            <div class="p-col-3"><label for="faxExtension" class="space-btwn">Ext # </label><br />
                <input [maxlength]="10" class="control" formControlName="faxExtension" id="faxExtension" pInputText
                    appCustomValidation [allowStartWithSpace]="false"
                    type="text" autocomplete="new-password" />
            </div>
            <div class="p-col-6" [class.p-col-12]="!retailerID"><label for="email" class="space-btwn">Email*</label>
                <div class="input-error-container">
                    <input class="control" formControlName="email" id="email" pInputText autocomplete="new-password"
                        appCustomValidation [allowStartWithSpace]="false"
                        type="text" />
                    <i *ngIf="validateChk.email.invalid && (validateChk.email.dirty || validateChk.email.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.email,'')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            
            <div class="p-col-6" *ngIf="retailerID">
                <label class="space-btwn" for="primary">Primary</label> <br />
                <p-selectButton  [options]="primaryOptions" id="primary" formControlName="isPrimary"></p-selectButton>
            </div>

            <div class="p-col-12"><label for="notes" class="space-btwn">Notes <span class="cc-error" *ngIf="!isAbleToSaveNote"> (You can't enter CC number)</span></label><br />
                <textarea id="notes" maxlength="5000" class="control" autocomplete="new-password" formControlName="notes"
                    appCustomValidation [allowStartWithSpace]="false" [class.isnotvalid]="!isAbleToSaveNote" (input)="checkCCNumberInString()"
                    pInputTextarea rows="3" type="text"></textarea>
                <div class="set-notes-length"> {{notes?notes.length:0}}/5000</div>
            </div>
        </div>
        <div class="p-col-12 RAlign">
            <button type="submit" [disabled]=loadingSave class="ui-button ui-button-info btn-theme-color" icon="{{loadingSave? 'fa fa-spinner': 'fa fa-save'}} " pButton
                label="Save"></button>
        </div>
    </form>
</div>