import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../services/other/data/data.service';
import { HtmlToolsService } from '../../../services/html-tools/html-tools.service';
import { RepgroupService } from '../../../services/api/repgroup.service';
import * as objectPath from 'object-path';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';

@Component({
    selector: 'rs-retailer-billing',
    templateUrl: './retailer-billing.component.html',
    styleUrls: ['./retailer-billing.component.scss']
})
export class RetailerBillingComponent implements OnInit, OnChanges, OnDestroy {

    editRetailerForm: FormGroup;
    @Output() previousStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() selectedRetailer: any;
    @Input() selectedRepGroupRetailer: any;
    @Input() isRetailerCreated: boolean;
    @Input() isRetailer: boolean;
    @Input() fromPage: string;
    @Input() retailer: any;
    @Input() repGroupID: string;
    countries: any[];
    states: any[];
    statusList: any[];
    repgroupDivisionList: any;
    countriesStateJSON: any;
    private readonly getDivisionsByCompanyDestroy = new Subject<void>();
    constructor(private fb: FormBuilder, private dataService: DataService, private htmlTools: HtmlToolsService,
        private repgroupService: RepgroupService) {
        this.initForm();
    }

    ngOnDestroy() {
        this.getDivisionsByCompanyDestroy.next();
    }
    ngOnInit() {
        if (!this.isRetailer) {
            this.getDivisionsByCompanyDestroy.next();
            this.repgroupService.getDivisionsByCompany(this.repGroupID)
                .pipe(takeUntil(this.getDivisionsByCompanyDestroy)).subscribe((t: any) => {
                    this.repgroupDivisionList = t ? t.data.map((d) => {
                        const c: any = {};
                        c.label = d.name;
                        c.value = d;
                        return c;
                    }) : [];
                });
        }
    }

    initForm() {
        this.editRetailerForm = this.fb.group({
            name: ['', Validators.required],
            address1: ['', [Validators.required, Validators.maxLength(100)]],
            address2: ['', Validators.maxLength(50)],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.required, Validators.maxLength(20)]],
            country: [environment.countryAbrv, Validators.required],
            phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            phoneExtension: ['', Validators.pattern('^[0-9]*$')],
            fax: ['', [Validators.pattern('^[0-9]*$')]],
            faxExtension: ['', Validators.pattern('^[0-9]*$')],
            retailerStatus: ['ACTIVE'],
            website: ['', [Validators.maxLength(150),
            Validators.pattern('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$')]],
            repGroupDivision: [''],
            federalTaxNumber: ['', [Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
            salesTaxNumber: ['', [Validators.maxLength(20)]],
            stateTaxNumber: ['', [Validators.maxLength(20)]],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.maxLength(100)]]

        });
        this.dataService.getCountryList().subscribe((data: any[]) => {
            this.countries = data;
        });
        // this.dataService.getStateList().subscribe((data: any[]) => {
        //     this.states = data;
        // });
        this.dataService.setCountriesState().then(res => {
            this.countriesStateJSON = res;
        });
        this.statusList = [
            {
                label: 'Active',
                value: 'ACTIVE'
            },
            {
                label: 'Lead',
                value: 'LEAD'
            },
            {
                label: 'Inactive',
                value: 'INACTIVE'
            }];
        if (!this.isRetailer) {
            this.editRetailerForm.get('retailerStatus').setValidators([Validators.required]);
            this.editRetailerForm.get('retailerStatus').updateValueAndValidity();
        }
    }

    ngOnChanges(changes: any) {
        if (changes && changes.retailer && !_.isEmpty(changes.retailer.currentValue) && changes.retailer.currentValue.name) {
            this.retailer = changes.retailer.currentValue;
            this.setFormFromRetailer();
        }
        if (changes && changes.isRetailerCreated && changes.isRetailerCreated.currentValue) {
            this.isRetailerCreated = changes.isRetailerCreated.currentValue;
            if (this.isRetailerCreated) {
                this.editRetailerForm.disable();
            }
        }
        if (changes && changes.repGroupID && changes.repGroupID.currentValue) {
            this.repGroupID = changes.repGroupID.currentValue;
        }
    }

    setFormFromRetailer() {
        this.editRetailerForm.patchValue(this.retailer);
        if (objectPath.get(this.selectedRepGroupRetailer, 'repGroupDivision')) {
            this.editRetailerForm.patchValue({ repGroupDivision: this.selectedRepGroupRetailer.repGroupDivision });
        }
        if (!this.editRetailerForm.get('retailerStatus').value) {
            this.editRetailerForm.patchValue({ retailerStatus: 'ACTIVE' });
        }
        if (!this.editRetailerForm.get('country').value) {
            this.editRetailerForm.patchValue({ country: environment.countryAbrv });
        }
        this.countrySpecific();
    }

    get validateChk() {
        // Convenience getter for easy access to form fields
        return this.editRetailerForm.controls;
    }


    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.pattern && field.errors.pattern.requiredPattern === '^[0-9]*$') {
            return 'Enter numeric value';
        } else if (field.errors.pattern && field.errors.pattern.requiredPattern
            === '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$') {
            return 'Enter website url e.g. http://www.eg.com or https://www.eg.com';
        } else if (field.errors.pattern) {
            return 'Enter valid value';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
        if (field.errors.maxlength) {
            return 'Maximum length exceed for ' + name;
        }
    }

    countrySpecific() {
        if (this.validateChk.country.value === 'US') {
            this.editRetailerForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
            this.editRetailerForm.get('zip').updateValueAndValidity();
        } else {
            this.editRetailerForm.get('state').setValue(objectPath.get(this.editRetailerForm.get('state').value, ''));
            this.editRetailerForm.get('zip').clearValidators();
            this.editRetailerForm.get('zip').updateValueAndValidity();
        }
    }

    private setRetailerFromForm() {
        _.forEach(this.editRetailerForm.controls, (key, value) => {
            this.retailer[value] = this.editRetailerForm.controls[value].value;
        });
    }

    saveRetailerInfo() {
        if (this.editRetailerForm.valid || this.isRetailerCreated) {
            this.setRetailerFromForm();
            this.nextStepEvent.next({ selectedRetailer: this.editRetailerForm.value, retailer: this.retailer });
        } else {
            this.htmlTools.validateAllFields(this.editRetailerForm);
        }
    }
}
