import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintPreviewDialogComponent } from './print-preview-dialog.component';
import { DropdownModule, ProgressSpinnerModule, DialogModule, ButtonModule, InputTextModule, InputSwitchModule } from 'primeng/primeng';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TooltipModule} from 'primeng/tooltip';
import {RadioButtonModule} from 'primeng/radiobutton';


@NgModule({
  declarations: [PrintPreviewDialogComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ProgressSpinnerModule,
    PdfViewerModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    InputSwitchModule,
    SelectButtonModule,
    TooltipModule,
    RadioButtonModule
  ],
  entryComponents: [PrintPreviewDialogComponent],
  exports: [PrintPreviewDialogComponent]
})
export class PrintPreviewDialogModule { }
