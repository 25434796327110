<div class="reptime-theme">
    <p-card class="card">
        <p-table [autoLayout]="true" [columns]="contactCols" [loading]="loading" [scrollable]="true"
            scrollHeight="300px" [responsive]="false" [rows]="25" [value]="contactArr" dataKey="recordID"
            exportFilename="contactArr" sortField="name" #contactTable>
            <ng-template let-columns pTemplate="header">
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field" [pSortableColumn]="col.field"
                        [style.width]="col.width">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template let-columns="columns" let-rowData pTemplate="body">
                <tr [pSelectableRow]="rowData" (click)="selectContact(rowData)"
                    [style.background]="selectedContact && (selectedContact.recordID === rowData.recordID) ? '#fad961': ''">
                    <td *ngFor="let col of columns" [style.width]="col.width" style="word-break: break-all;">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchDefault>{{rowData[col.field]}}
                                <span style=" color: darkorange;" pTooltip="Primary Contact"
                                    *ngIf="col.field === 'firstName' && rowData['isPrimary'] === true"><i
                                        class="fa fa-star"></i></span>
                            </div>
                            <div *ngSwitchCase="'phone'">{{rowData[col.field] | phone}}
                                <span style=" color: darkorange;" pTooltip="Primary Contact"
                                    *ngIf="col.field === 'firstName' && rowData['isPrimary'] === true"><i
                                        class="fa fa-star"></i></span>
                            </div>
                            <div *ngSwitchCase="'cell'">{{rowData[col.field] | phone}}
                                <span style=" color: darkorange;" pTooltip="Primary Contact"
                                    *ngIf="col.field === 'firstName' && rowData['isPrimary'] === true"><i
                                        class="fa fa-star"></i></span>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="!loading" class="table-empty">
                    <td [attr.colspan]="contactCols && contactCols.length + 3" class="text-center">
                        You have no Contact Info yet.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</div>