import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar } from 'primeng/components/calendar/calendar';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';


@Component({
    selector: 'rs-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalendarComponent),
            multi: true
        }
    ]
})
export class CalendarComponent implements ControlValueAccessor, OnInit {

    @Input() whiteBg = false;
    @Input() date;
    @Input() id = '';
    @Input() required: boolean;
    @Input() dateFormat = environment.rtDateFormat;
    @Input() readonly = false;
    // RC-594 (MJ 28-FEB-2020): Add disable attribute
    @Input() disabled: boolean;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Output() dateChange = new EventEmitter();
    @Output() dateTextChange = new EventEmitter();
    @ViewChild('customCal', { static: true }) calendar: Calendar;
    @Input() placeholder = '';
    dateMask = '';
    @Output() DateChangeOnBlur = new EventEmitter();
    @Input() olddate;
    isblur = false;
    env = environment;

    constructor() {
    }

    ngOnInit() {
    }

    propagateChange(newValue) {
        this.date = newValue;
        if (this.date) {
            this.dateChange.emit(newValue);
        } else {
            this.dateChange.emit(null);
        }
    }

    registerOnChange(fn: any): void {
        this.calendar.registerOnChange(fn);
    }

    registerOnTouched(fn: any): void {
        this.calendar.registerOnTouched(fn);
    }

    setDisabledState(isDisabled: boolean): void {
        this.calendar.setDisabledState(isDisabled);
    }

    writeValue(obj: any): void {
        this.date = obj;
        this.calendar.writeValue(obj);
    }

    dateInputTextChange(event) {
        // console.log('dateTypeChange1', event, event.target.value);
        // console.log('dateTypeChange2', event.target.value);

        let cursorPosition = event.target.selectionEnd;

        if (event.inputType === 'deleteContentBackward' && (cursorPosition === 2 || cursorPosition === 5)) {
            event.target.value = event.target.value.substring(0, cursorPosition - 1) + event.target.value.substring(cursorPosition);
            cursorPosition--;
        }
        if (event.inputType === 'insertText' && (event.target.value.length > 10)) {
            event.target.value = event.target.value.substring(0, event.target.value.length - 1);
        }

        this.dateMask = event.target.value.toString();
        this.dateMask = this.dateMask.replace(/\D/g, '');

        let mask = '';
        for (let i = 0; i < this.dateMask.length; i++) {
            mask += this.dateMask[i];
            if (i === 1 || i === 3) {
                mask += '/';
                if (cursorPosition === 2 || cursorPosition === 5) { cursorPosition++; }
            }
        }
        event.target.value = mask.toString();
        event.target.selectionStart = cursorPosition;
        event.target.selectionEnd = cursorPosition;

        if (event.target.value.length === 10) {
            if (this.isValidDate()) {
                this.date = event.target.value;
            }
        }
    }

    // RC-1179 (MK 08 AUG 2020): single time api call
    onBlurDate(): void {
        this.isblur = true;
        setTimeout(() => {
            if (!!this.date && !this.isValidDate(this.date)) {
                console.log('invalid');
                this.date = null;
            }
            if (!moment(new Date(this.date)).isSame(new Date(this.olddate)) && this.date !== this.olddate) {
                this.DateChangeOnBlur.emit(this.date ? new Date(this.date) : null);
            }
            this.isblur = false;
        }, 150);
    }
    onSelectDate() {
        if (!this.isblur) {
            if (!!this.date && !this.isValidDate(this.date)) {
                console.log('invalid');
                this.date = null;
            }
            if (!moment(new Date(this.date)).isSame(new Date(this.olddate)) && this.date !== this.olddate) {
                this.DateChangeOnBlur.emit(this.date ? new Date(this.date) : null);
            }
        }
        this.isblur = false;
    }

    isValidDate(date = this.date) {
        return Date.parse(date) ? moment(new Date(date), this.dateMask, true).isValid() : false;
    }

}
