import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessageService} from 'primeng/api';
import {APIResponse} from '../../models/api-response';
import * as objectPath from 'object-path';
import {SalespersonService} from '../../services/api/salesperson.service';

@Component({
    selector: 'rs-salesperson-repgroup-dropdown',
    templateUrl: './salesperson-repgroup-dropdown.component.html',
    styleUrls: ['./salesperson-repgroup-dropdown.component.css']
})
export class SalespersonRepgroupDropdownComponent implements OnInit {
    @Input() salespersonID: string;
    @Output() repGroupSelect = new EventEmitter<any>();
    repGroups = [];
    selectedRepGroupID: string;

    constructor(private msgService: MessageService,
                private salespersonService: SalespersonService) {
    }

    ngOnInit() {
        this.salespersonService.getRepGroupsBySalespersonID(this.salespersonID)
            .subscribe((response: APIResponse) => {
                if (response.success) {
                    this.repGroups = response.data.map((d) => {
                        const obj: any = {};
                        obj.label = `${d.name} [${d.recordID}]`;
                        obj.value = d.recordID;
                        return obj;
                    });
                    this.selectedRepGroupID = objectPath.get(this.repGroups, '0.value', null);
                    this.repGroupSelect.emit(this.selectedRepGroupID);
                } else {
                    this.repGroups = [];
                    this.msgService.add({
                        severity: 'error',
                        summary: 'error',
                        detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                    });
                }
            }, (error) => {
                this.repGroups = [];
                this.msgService.add({
                    severity: 'error',
                    summary: 'error',
                    detail: objectPath.get(error, 'error.message', 'Error occurred during services request')
                });
            });
    }

}
