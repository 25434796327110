import { Component, OnInit, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { DataService } from '../../services/other/data/data.service';
import { FormGroup, FormBuilder, FormsModule, Validators, ValidationErrors } from '@angular/forms';
import { MessageService, DynamicDialogRef } from 'primeng/api';
import { APIResponse } from 'src/app/models/api-response';
import { HtmlToolsService } from '../../services/html-tools/html-tools.service';
import { CompanyService } from '../../services/api/company.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnDestroy {

  countries: any[];
  states: any[];
  companyInfoForm: FormGroup;
  who = 'Brand';
  usewebsite = 'Yes';
  multilineCompany = 'Yes';
  countriesStateJSON: any;
  sendedArray = {};
  saveLoading = false;
  private readonly destroyZipCodeList = new Subject<void>();

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private msgService: MessageService,
    public ref: DynamicDialogRef,
    private htmlTools: HtmlToolsService,
    public companyService: CompanyService
  ) { }

  ngOnInit() {
    // console.log('msgService', this.msgService);
    // this.msgService.add({key: 'success', summary: 'Success', severity: 'success', detail: 'Company info updated successfully'});
    // console.log('ngOnInit????');
    this.createCompanyInfoForm();
    this.dataService.getCountryList().subscribe((data: any[]) => {
      this.countries = data;
    });
    // this.dataService.getStateList().subscribe((data: any[]) => {
    //   this.states = data;
    // });
    this.dataService.setCountriesState().then(res => {
      this.countriesStateJSON = res;
    });
  }

  closeUserInfo() {
    this.ref.close();
  }
  createCompanyInfoForm() {
    // Create company-info-form form using FormBuilder
    this.companyInfoForm = this.fb.group({
      companyName: ['', [Validators.required, Validators.maxLength(50)]],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      address: ['', [Validators.required, Validators.maxLength(100)]],
      country: [environment.countryAbrv, [Validators.required, Validators.maxLength(50)]],
      state: ['', [Validators.required, Validators.maxLength(50)]],
      city: ['', [Validators.required, Validators.maxLength(50)]],
      zip: ['', [Validators.required, Validators.maxLength(20)]],
      website: ['', []],
      who: [''],
      usewebsite: [''],
      multilineCompany: [''],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.maxLength(80)]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12)]],
    });
  }

  get f() {
    // Convenience getter for easy access to form fields
    return this.companyInfoForm.controls;
  }

  get validateChk() {
    return this.companyInfoForm && this.companyInfoForm.controls;
  }

  getErrorMsg(field, name) {
    if (field.errors.required) {
      return name + ' is required';
    }
    if (field.errors.email) {
      return 'Enter valid email';
    }
  }

  onSubmit() {
    const formIsValid = this.companyInfoForm.valid;

    if (!formIsValid) {
      this.htmlTools.validateAllFields(this.companyInfoForm);
      return;
    }
    if (this.companyInfoForm.valid) {
      const formJson = {
        'Company Name': (this.companyInfoForm.value.companyName) ? this.companyInfoForm.value.companyName : '',
        'Name': (this.companyInfoForm.value.name) ? this.companyInfoForm.value.name : '',
        'Address': (this.companyInfoForm.value.address) ? this.companyInfoForm.value.address : '',
        'Country': (this.companyInfoForm.value.country) ? this.companyInfoForm.value.country : '',
        'State': (this.companyInfoForm.value.state) ? this.companyInfoForm.value.state : '',
        'City': (this.companyInfoForm.value.city) ? this.companyInfoForm.value.city : '',
        'Zip Code': (this.companyInfoForm.value.zip) ? this.companyInfoForm.value.zip : '',
        'Website': (this.companyInfoForm.value.website) ? this.companyInfoForm.value.website : '',
        'Phone Number': (this.companyInfoForm.value.phone) ? this.companyInfoForm.value.phone : '',
        'What are you?': (this.companyInfoForm.value.who) ? this.companyInfoForm.value.who : '',
        'Do you currently use b2b website?': (this.companyInfoForm.value.usewebsite) ? this.companyInfoForm.value.usewebsite : '',
        'Are you a multiline company?': (this.companyInfoForm.value.multilineCompany) ? this.companyInfoForm.value.multilineCompany : '',
        'email': (this.companyInfoForm.value.email) ? this.companyInfoForm.value.email : '',
      };
      this.sendedArray['emailSubject'] = 'B2B Access for Better Service';
      this.sendedArray['source'] = 'b2baccess';
      this.sendedArray['sendEmailAddress'] = 'sales@markettime.com';
      this.sendedArray['content'] = formJson;

      this.saveLoading = true;
      this.companyService.sendContactUsEmail(this.sendedArray).subscribe((data) => {
        if (data.success === true) {
          this.saveLoading = false;
          this.ref.close();
          this.msgService.clear();
          this.msgService.add({ key: 'success', summary: 'Success', severity: 'success', detail: 'Request send successfully' });
        } else {
          this.saveLoading = false;
          this.msgService.clear();
          this.msgService.add({ key: 'success', summary: 'Error', severity: 'error', detail: 'Error occurred while saving' });
        }
      });
    } else {
      this.saveLoading = false;
      this.msgService.clear();
      this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred while saving' });
      this.htmlTools.validateAllFields(this.companyInfoForm);
    }
  }

  countrySpecific() {
    if (this.f.country.value === 'US') {
      this.companyInfoForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
      this.companyInfoForm.get('zip').updateValueAndValidity();
    } else {
      this.f.state.setValue('');
      this.f.zip.setValue('');
      this.f.city.setValue('');
      this.companyInfoForm.get('zip').clearValidators();
      this.companyInfoForm.get('zip').updateValueAndValidity();
    }
    this.updateChange();
  }

  updateChange() {
    const zipcode = this.f.zip.value.trim();
    if (zipcode.length >= 5) {

      const searchParams: any = {};
      let size = 3000;
      let offset = 0;
      let sort = 'country';
      let sortOrder = 1;
      searchParams.country = this.f.country.value;
      searchParams.zipCode = this.f.zip.value.trim();
      this.destroyZipCodeList.next();

      this.dataService.getZipCodeList(offset, size, sort, sortOrder, searchParams)
      .pipe(takeUntil(this.destroyZipCodeList)).subscribe((res: APIResponse) => {
          if (res.success) {
            if (res.data[0]) {
              this.f.state.setValue(res.data[0].stateAbbreviation);
              this.f.city.setValue(res.data[0].city);
            } else {
              this.f.state.setValue('');
              this.f.city.setValue('');
            }
          } else {
            this.f.state.setValue('');
            this.f.city.setValue('');
          }
        }, () => {
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.companyInfoForm || !this.companyInfoForm.controls) {
      return;
    }
  }

  onItemChange(item) {
  }

  ngOnDestroy() {
    this.destroyZipCodeList.next();
  }
}
