<p-toast key="searchItemTable" position="top-right" [baseZIndex]="110000"></p-toast>
<div class="custom-dialog" id="custom-dialog">
    <span class="search-pdf btn-hide" (click)="openItemSearchModal()">
        <i class="fas fa-search"></i>
    </span>
    <span class="close-pdf" (click)="closePdfDialog()">
        <i class="fas fa-close"></i>
    </span>
    <span class="dialog-cart-btn" (click)="openCart()">
        <span class="bellicon-counter items-count" *ngIf="cartData?.totalItems">{{cartData?.totalItems}}</span>
        <span class="bellicon-counter items-total"
            *ngIf="cartData?.cartDiscountedTotal">{{(cartData?.cartDiscountedTotal ?
            cartData?.cartDiscountedTotal : 0) | rtCurrency}}</span>
        <i class="fa fa-shopping-cart"></i>
    </span>
    <div id="flipbookContainer"></div>
    <div class="reptime-table-loader" *ngIf="showSearchLoader" style="z-index: 11000;background: rgba(0, 0, 0, 0.5);">
        <p-progressSpinner></p-progressSpinner>
        <p>Loading Data...</p>
    </div>
</div>
<app-item-view-model [activeViewIndex]="activeViewIndex" [activeViewItem]="this.activeViewItem"
    [itemMfgIDs]="itemMfgIDs" [manufacturersArr]="manufacturersArr" [selectedCustomer]="this.selectedCustomer"
    [existCartData]="existCartData" [existItemsData]="existItemsData" [fromPage]="fromPage"
    (addToCartEvent)="addToCartEvent($event, this.activeViewItem)" [(isOpen)]="showCatalogShopDialog" *ngIf="showCatalogShopDialog">
</app-item-view-model>
<p-sidebar [(visible)]="cartSidebar" position="right" [baseZIndex]="1000" appendTo="body" [dismissible]="true" [showCloseIcon]="false"
    class="cart-prime-sidebar shop-cart-static" styleClass="append-cart-body">
    <div class="cart-right-sidebar reptime-theme">
        <app-cartbar [selectedCustomer]="selectedCustomer" [confirmkey]="'item-table-confirmkey'"
            [existCartData]="existCartData" (promotionsClickEvent)="promotionsClickCartEvent($event)"
            (metClickEvent)="metClickCartEvent($event)" (checkoutClickEvent)="checkoutClickCartEvent($event)"
            (updateExistCardEvent)="updateExistCardCartEvent($event)" (closeSidebar)="cartSidebar=!$event" [cartBarPosition]="cartBarPosition"></app-cartbar>
    </div>
</p-sidebar>
<p-dialog [(visible)]="showSearchDialog" [baseZIndex]="10001"
    [contentStyle]="{'width': '70vw', 'max-width': '90vw', 'overflow': 'hidden'}" header="Select Item"
    modal="true" [maximizable]="false" [blockScroll]="true" [closeOnEscape]="true" [resizable]="false" styleClass="resp-bill-info overflow-auto cut-dialog-height" [appendTo]="'body'">
    <div class="reptime-theme">
        <app-search-item-table *ngIf="showSearchDialog" [repGroupID]="catalogData.repGroupId" 
            [fromPage]="fromPage" [manufacturersArr]="manufacturersArr"
            [selectedCustomer]="selectedCustomer" [existCartData]="existCartData" [rows]="30" (hidePopup)="getAllInfo($event)"></app-search-item-table>
    </div>
</p-dialog>
<!-- [(onlyOneAdd)]="onlyOneAdd"  -->
<!-- [searchText]="searchText" -->
<!-- [selectedManufacturer]="selectedManufacturer" -->