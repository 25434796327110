<p-dialog header="Shipment Details" [modal]="true" [(visible)]="orderTrackingDialog" [maximizable]="false" [blockScroll]="true" [closeOnEscape]="true"
[resizable]="false" (onHide)="onHide()">
    <div class="h-100 py-10">
        <p-table [columns]="orderTrackingCols" [value]="orderTrackingDataa"  [autoLayout]="true"
            [paginator]="false" [loading]="orderTrackingLoading" [responsive]="false" [sortOrder]="-1">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.sortField">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchCase="'estimatedShippingCost'">
                                {{rowData[col.field] | rtCurrency}}
                            </div>
                            <div *ngSwitchCase="'invoiceDate'">
                                {{rowData[col.field] | rtDate}}
                            </div>
                            <div *ngSwitchCase="'trackinglink'">
                                <a href="{{rowData[col.field]}}" target="_blank">{{rowData[col.field] }}</a>
                            </div>
                            <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr class="table-empty">
                    <td [attr.colspan]="orderTrackingCols.length + 2" class="text-center">
                        {{orderTrackingLoading ? 'Loading tracking orders' : 'You have no shipment details yet.' }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>