import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';

import {PageNotFoundComponent} from './page-not-found.component';


const routes = [{path: '', component: PageNotFoundComponent}];

@NgModule({
    declarations: [PageNotFoundComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        CardModule,
        ButtonModule
    ],
    exports: [PageNotFoundComponent]
})
export class PageNotFoundModule {
}
