// B2B-53 (MG 04-FEB-2020): modified to save filtered documents
import { Component, OnInit, ViewChildren, AfterViewInit } from '@angular/core';
import { CompanyService } from 'src/app/services/middleware/company/company.service';
import { Tooltip } from 'primeng/primeng';
// import { MessageService } from 'primeng/primeng';
import { MessageService, SelectItem } from 'primeng/api';
import * as objectPath from 'object-path';
import { finalize } from 'rxjs/operators';
// [B2B-109] (MG-27-FEB-2020) modified to show MFR logo when no thumb
import { environment } from 'src/environments/environment';
import { ManufacturerService } from 'src/app/services/api/manufacturer.service';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
    showTable: boolean;
    documentsTableCols: any[];
    documentsTableValue: any[];
    loadingTableData = true;
    tableEvent = { rows: 50, first: 0 };
    docs = [];
    totalDocuments = -1;
    isSubmitLoading = false;
    isResetLoading = false;
    public domainData: any = (<any>window).domainData;
    @ViewChildren('copyLinks', { read: Tooltip }) copyLinks;
    @ViewChildren('copyLinksGrid', { read: Tooltip }) copyLinksGrid;
    catalogManufacture: any[] = [];
    catalogCallout: SelectItem[];
    selectedManufactureID = '';

    static blobDownloadHelper(data, fileName) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
    }

    constructor(
        private manufacturerService: ManufacturerService,
        private rtSharedService: RTSharedService,
        public companyService: CompanyService,
        public msgService: MessageService,
        private router: Router
    ) {
        // [B2B-53] (RM 03-03-2020): get manufacture list
        this.getManufactureData();
    }
    ngOnInit() {
    }
    // [B2B-53] (RM 03-03-2020): Dropdown filter dynamically working
    search($event) {
        const defaultEvent = { rows: 50, first: 0 };
        this.selectedManufactureID = $event.value;
    }

    // [B2B-53] (RM 03-03-2020): Get all manufacture list and set dropdown filter
    async getManufactureData() {
       // this.loadingTableData = true;
        console.log('getManufactureData');
        const filterByCatalog = true;
        this.manufacturerService.getManufacturersByRepGroup(null, filterByCatalog).subscribe(async (res) => {
            console.log('Response >>', res);
            if (this.rtSharedService.getProjectName() === 'B2B') {
                const manufacturersArr = res.data.filter(o => {
                    if (o.repGroupManufacturers && o.repGroupManufacturers.length) {
                        if (o.repGroupManufacturers[0].showOnWebsite === true) {
                            return { recordID: o.recordID, name: o.name };
                        }
                    }
                });

                const catalogManufacture =  _.chain(manufacturersArr).map((o, i) => {
                    return {
                        label: o.name,
                        value: o.recordID,
                        fullPicturePath:  environment.logoBucketUrl + 'logo/' + o.recordID + '.png',
                        minimumOrderAmount: o.minimumOrderAmount
                    };
                }).value();
                if (catalogManufacture && catalogManufacture.length) {
                    // const allmfg = {label: 'All Manufacturers', value: ''};
                    // this.catalogManufacture.push(allmfg);
                    catalogManufacture.forEach(o => {
                        this.catalogManufacture.push(o);
                    });
                } else {
                    this.catalogManufacture = [];
                }
            }
            this.loadingTableData = false;
        });
    }

    viewcatalog(vendor) {
        const url = 'document?mfg=' + vendor.value;
        if (vendor.value) {
            this.router.navigate([`/document`], { queryParams: {mfg: vendor.value} });
        }
    }
}
