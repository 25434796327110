<footer class="container-fluid footer-dynamic-theme">
    <div class="container flex-rw resf-pad-remove">
        <!-- <ul class="footer-list-top">-->
            <!--<li><h4 class="footer-list-header">About MarketTime</h4></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">GET TO KNOW US</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">PROMOS</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">BECOME A RETAILER</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">JOB OPENINGS</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">EVENTS</a></li>-->
        <!--</ul>-->
        <!--<ul class="footer-list-top">-->
            <!--<li><h4 class="footer-list-header">The Gift Selection</h4></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">ANGEL FIGURINES</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">HOME DECOR</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">MUGS</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">PET LOVER</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor">HANDBAGS & JEWELRY</a></li>-->
        <!--</ul>-->
        <!--<ul class="footer-list-top">-->
            <!--<li id='help'><h4 class="footer-list-header">Please Help Me</h4></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor"-->
                   <!--itemprop="significantLink">CONTACT</a></li>-->
            <!--<li><a href='javascript:void(0);' class="generic-anchor footer-list-anchor"-->
                   <!--itemprop="significantLink">FAQ</a>-->
            <!--</li>-->
            <!--<li id='find-a-store'><a href='javascript:void(0);' class="generic-anchor footer-list-anchor"-->
                                     <!--itemprop="significantLink">STORE LOCATOR</a></li>-->
            <!--<li id='user-registration'><a href='javascript:void(0);' class="generic-anchor footer-list-anchor"-->
                                          <!--itemprop="significantLink">NEW USERS</a></li>-->
            <!--<li id='order-tracking'><a href='javascript:void(0);' itemprop="significantLink"-->
                                       <!--class="generic-anchor footer-list-anchor">ORDER STATUS</a></li>-->
        <!--</ul>-->
        <!--<section class="footer-social-section flex-rw">-->
            <!--<div class="footer-social-overlap footer-social-connect">-->
                <!--CONNECT <span class="footer-social-small">with</span> US-->
            <!--</div>-->
            <!--<div class="footer-social-overlap footer-social-icons-wrapper">-->
                <!--<a href="javascript:void(0);" class="generic-anchor" title="Pinterest"><i-->
                    <!--class="fa fa-pinterest"></i></a>-->
                <!--<a href="javascript:void(0);" class="generic-anchor" title="Facebook"><i class="fa fa-facebook"></i></a>-->
                <!--<a href="javascript:void(0);" class="generic-anchor" title="Twitter"><i class="fa fa-twitter"></i></a>-->
                <!--<a href="javascript:void(0);" class="generic-anchor" title="Instagram"><i-->
                    <!--class="fa fa-instagram"></i></a>-->
                <!--<a href="javascript:void(0);" class="generic-anchor" title="Youtube"><i class="fa fa-youtube"></i></a>-->
                <!--<a href="javascript:void(0);" class="generic-anchor" title="Google Plus"><i-->
                    <!--class="fa fa-google-plus"></i></a>-->
            <!--</div>-->
        <!--</section> -->
        <section class="p-col-12" *ngIf="domainData.repGroup"><br>
            <p style="font-size: 1.25em; margin: .83em 0;font-weight: normal;" class="px-5 text-center m-0 text-white">{{domainData.rgName}}</p>
            <!-- <p style="font-size: 1.19em; margin: .83em 0;font-weight: normal;" class="px-5 text-center m-0 text-white">{{domainData.rgAddress}}</p> -->
            <!-- <p style="font-size: 1.19em; margin: .83em 0;font-weight: normal;" class="px-5 text-center m-0 text-white">{{domainData.rgContact}}</p> -->
            <p style="font-size: 1.19em; margin: .83em 0;font-weight: normal;" class="px-5 text-center m-0 text-white d-flex align-items-center justify-content-center res-tel-info">
                <span *ngIf="domainData.rgPhone">Tel. {{domainData.rgPhone | phone}}</span>
                <span class="ml-3 mr-3" *ngIf="domainData.rgFax && domainData.rgPhone">  •  </span>              
                <span *ngIf="domainData.rgFax">Fax. {{domainData.rgFax | phone}}</span>
            </p>                    
            <p style="font-size: 1.19em; margin: .83em 0;font-weight: normal;" class="px-5 text-center m-0 text-white">{{domainData.rgEmail}}</p>
            <a href="{{domainData.rgWebsite}}" target="_blank" style="font-size: 1.19em; margin: .83em 0;font-weight: normal;display: block;" class="px-5 text-center m-0 text-white">{{domainData.rgWebsite}}</a>
        </section>
        <section class="footer-bottom-section flex-rw">
            <div style="font-size: 1.17em; font-weight: normal;" class="footer-bottom-wrapper">
                <i class="fa fa-copyright" role="copyright">
                </i> 2021 powered by <a href="https://markettime.com" target="_blank" style="color:#ffffff;"><b>MARKETTIME LLC</b></a>
                <address class="footer-address" role="company address"></address>
                <span class="footer-bottom-rights"> - All Rights Reserved </span>
            </div>
            <div style="font-size: 1.17em; font-weight: normal;" class="footer-bottom-wrapper">
                <a href="https://markettime.com/privacy-policy/privacy" target="_blank" class="generic-anchor" rel="nofollow" style="color: #fff;">Terms & Conditions</a> 
            </div>
        </section>
    </div>
</footer>
