import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
// import { RTSharedService } from '../../../services/rtshared/rtshared.service';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service' 
@Component({
    selector: 'rs-promotions-dialog',
    templateUrl: './promotions-dialog.component.html',
    styleUrls: ['./promotions-dialog.component.scss']
})
export class PromotionsDialogComponent implements OnInit {

    public manufacturers: any;


    constructor(public config: DynamicDialogConfig,
                public ref: DynamicDialogRef,
                public rtSharedService : RTSharedService ) {
    }

    ngOnInit() {
        this.manufacturers = this.config.data.manufacturers;
        console.log('this.manufacturers', this.manufacturers);
    }

}
