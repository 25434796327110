import {
  Component, OnInit, Input, QueryList, ViewChildren, HostListener, OnChanges, SimpleChanges, Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import $ from 'jquery';
import { ConfirmationService } from 'primeng/api';
import { RTSharedService } from '../../services/rtshared/rtshared.service';
import * as objectPath from 'object-path';
import { Subject, Subscription } from 'rxjs';
import { RetailerService } from '../../services/middleware/retailer/retailer.service';
import { filter, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit {
  win: any = (window);
  public isIframeWindow: any = (window as any).iframeData;
  public domainData: any = this.win.domainData;
  @Input() loggedInUser: any;
  @Input() mobMenuList: any;
  @Input() items: any;
  @Input() menuShimmerAction: any;
  @Input() notApprovedByRepGroup: any;
  @Input() groups: any;
  @Input() logo: any;
  @Input() logoClickURL: any;
  @Input() hidemenutoggle: any;
  public qpObject: any;
  public activeRouteQP: any;
  @ViewChildren('getmenu_width') getmenuwidth: QueryList<any>;
  @ViewChildren('menubutton') menubutton: QueryList<any>;
  @Output() fullPageLoader = new EventEmitter<any>();
  private readonly onDestroy = new Subject<void>();
  buyerQickReport: any;
  yourYTDSaving: any;
  yourLYSaving: any;
  yourLTTaving: any;
  stickyTop;
  headerHeight: any;
  public getRetailerDataSubscription: Subscription;
  public subscriptionRetailerDetails: Subscription;
  constructor(
    public auth: AuthService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public rtSharedService: RTSharedService,
    private retailerService: RetailerService,

  ) {
    this.activeRoute.queryParams.subscribe((qp) => {
      this.activeRouteQP = qp;
    });

    this.subscriptionRetailerDetails = this.retailerService.getRetailerDetailsEvent().subscribe(async (flag) => {
      if (this.auth.getshowDiscountTickerButtonFlag() && flag) {
        this.getRetailerQickReportAll();
      }
    })

    if (this.domainData && this.auth.getProjectType() === 2) {
      this.stickyTop = document.getElementById('lightovation-header');
      this.headerHeight = '80px';
    } else {
      this.stickyTop = document.getElementById('b2bheader');
      if (this.groups && this.groups.length) {
        this.headerHeight = '123px';
      } else {
        this.headerHeight = '80px';
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getCategoryMenuData(event.target.innerWidth);
  }

  ngOnInit() {
    console.log('>>>isIframeWindow', this.isIframeWindow);
    if (this.auth.getshowDiscountTickerButtonFlag() && this.loggedInUser) {
      this.getRetailerQickReportAll();
    }
  }
  ngAfterViewInit() {
    this.isIframeWindow = this.win.iframeData;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.groups && changes.groups.currentValue.length) {
      const mainWidth = $(window).width();
      this.getCategoryMenuData(mainWidth);
    }
  }
  ngOnDestroy() {
    this.onDestroy.next();
    this.getRetailerDataSubscription.unsubscribe();
    this.subscriptionRetailerDetails.unsubscribe();
  }
  mouseEnterMainList(e) {
    const getBoundingClientRect = $(e.target)[0].getBoundingClientRect();
    const subCategoryBlockWidth = $(e.target).find('.sub-category-block').outerWidth();
    const subCategoryBlockHeight = $(e.target).find('.sub-category-block').outerHeight();
    const targetOuterWidth = $(e.target).outerWidth();
    const targetOuterHeight = $(e.target).outerHeight();
    const windowInnerWidth = window.innerWidth;
    const windowInnerHeight = window.innerHeight;
    if (subCategoryBlockWidth) {
      if (windowInnerWidth <= +(targetOuterWidth + getBoundingClientRect.x + subCategoryBlockWidth).toFixed(2)) {
        $(e.target).find('.sub-category-block').css({
          'top': getBoundingClientRect.y,
          'left': getBoundingClientRect.x - subCategoryBlockWidth,
          'bottom': 'auto'
        });
      } else {
        $(e.target).find('.sub-category-block').css({
          'top': getBoundingClientRect.y,
          'left': targetOuterWidth + getBoundingClientRect.x,
          'bottom': 'auto'
        });
      }

      if (windowInnerHeight <= +(targetOuterHeight + getBoundingClientRect.y + subCategoryBlockHeight).toFixed(2)) {
        $(e.target).find('.sub-category-block').css({
          'top': 'auto',
          'bottom': windowInnerHeight - getBoundingClientRect.y - targetOuterHeight
        });
      }
    }
  }

  // mouseEnterSubList(e) {
  //   const getBoundingClientRect = $(e.target)[0].getBoundingClientRect();
  //   const subCategoryBlockWidth = $(e.target).find('.sub-sub-category-block').outerWidth();
  //   const subCategoryBlockHeight = $(e.target).find('.sub-sub-category-block').outerHeight();
  //   const targetOuterWidth = $(e.target).outerWidth();
  //   const targetOuterHeight = $(e.target).outerHeight();
  //   const windowInnerWidth = window.innerWidth;
  //   const windowInnerHeight = window.innerHeight;
  //   if (subCategoryBlockWidth) {
  //     if (windowInnerWidth <= +(targetOuterWidth + getBoundingClientRect.x + subCategoryBlockWidth).toFixed(2)) {
  //       $(e.target).find('.sub-sub-category-block').css({
  //         'top': getBoundingClientRect.y,
  //         'left': getBoundingClientRect.x - subCategoryBlockWidth,
  //         'bottom': 'auto'
  //       });
  //     } else {
  //       $(e.target).find('.sub-sub-category-block').css({
  //         'top': getBoundingClientRect.y,
  //         'left': targetOuterWidth + getBoundingClientRect.x,
  //         'bottom': 'auto'
  //       });
  //     }

  //     if (windowInnerHeight <= +(targetOuterHeight + getBoundingClientRect.y + subCategoryBlockHeight).toFixed(2)) {
  //       $(e.target).find('.sub-sub-category-block').css({
  //         'top': 'auto',
  //         'bottom': windowInnerHeight - getBoundingClientRect.y - targetOuterHeight
  //       });
  //     }
  //   }
  // }

  getCategoryMenuData(mainWidth) {
    // [B2B-234] (MK 13-04-2020) group display in one line
    const interval = setInterval(() => {
      if (this.getmenuwidth['_results'].length !== 0 && interval) {
        let totalcalculatedwidth = window.innerWidth > 1780 ? 1780 : window.innerWidth;
        totalcalculatedwidth = totalcalculatedwidth - (17 + 50);
        let currentwidth = $(this.menubutton['_results'][0].nativeElement).outerWidth(true);
        this.hidemenutoggle = true;
        this.groups.forEach((d, i) => {
          currentwidth = currentwidth + $(this.getmenuwidth['_results'][i].nativeElement).outerWidth(true);
          d['isHide'] = totalcalculatedwidth >= currentwidth ? false : true;
          this.hidemenutoggle = ((this.groups.length - 1) === i) &&
            totalcalculatedwidth < currentwidth ? false : true;
        });

        setTimeout(() => {
          if (this.domainData && this.auth.getProjectType() === 2) {
            this.stickyTop = document.getElementById('lightovation-header');
            this.headerHeight = '80px';
          } else {
            this.stickyTop = document.getElementById('b2bheader');

            if (this.groups && this.groups.length) {
              this.headerHeight = '123px';
            } else {
              this.headerHeight = '80px';
            }
          }
        }, 500);
        clearInterval(interval);
      }
      this.fullPageLoader.emit(false);
    }, 1000);
  }

  // gotoExternalLink(type) {
  //   if (type === 'catalog') {
  //     window.open(this.auth.getCustomCatalogButtonData()['url'], '_blank');
  //     // window.location.href = this.auth.getCustomCatalogButtonData()['url'];
  //   } else if (type === 'promotion') {
  //     window.open(this.auth.getCustomPromotionButtonData()['url'], '_blank');
  //     // window.location.href = this.auth.getCustomPromotionButtonData()['url'];
  //   } else {
  //     alert('Invalid Link');
  //   }
  // }
  getQpObject(catlist, subcatlist?: any) {
    this.qpObject = Object.assign({}, this.activeRouteQP);
    // this.qpObject.group = 'true';
    if (catlist.recordID) {
      this.qpObject.c = catlist.recordID;
      delete this.qpObject.s;
    }
    if (subcatlist && subcatlist.recordID) {
      this.qpObject.c = catlist.recordID;
      this.qpObject.s = subcatlist.recordID;
      // delete this.qpObject.ss;
    }
    // if ((subcatlist && subsubcatelist) && subcatlist.recordID && subsubcatelist.recordID) {
    //   this.qpObject.c = catlist.recordID;
    //   this.qpObject.s = subcatlist.recordID;
    //   this.qpObject.ss = subsubcatelist.recordID;
    // }
    if (this.qpObject && this.qpObject.itemdetails) {
      delete this.qpObject.itemdetails;
    }
    return this.qpObject;
  }
  getQpGroupObject(grouplist) {
    // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
    this.qpObject = Object.assign({}, this.activeRouteQP);
    if (grouplist && grouplist.groupID) {
      this.qpObject.g = grouplist.groupID;
    } else {
      delete this.qpObject.g;
    }
    return this.qpObject;
    /* if (this.auth.getCategoryGroupType() === 'vendors') {
     this.qpObject = Object.assign({}, this.activeRouteQP);
     if (grouplist.repGroupCategoryManufacturers && grouplist.repGroupCategoryManufacturers.length) {
       const groupCatStr = grouplist.repGroupCategoryManufacturers.reduce((a, o) =>
         ((o.showLockedB2bItems || o.b2bItemPricingPolicy !== 'NeedBrandApproval') && a.push(o.manufacturerID), a), []).join(',');
       this.qpObject.mfg = groupCatStr;
     } else {
       delete this.qpObject.mfg;
     }
     if (this.qpObject && this.qpObject.itemdetails) {
       delete this.qpObject.itemdetails;
     }
     return this.qpObject;
   } else {
     this.qpObject = Object.assign({}, this.activeRouteQP);
     if (grouplist.repGroupCategoryResponse && grouplist.repGroupCategoryResponse.length) {
       const groupCatStr = _.map(grouplist.repGroupCategoryResponse, (o) => {
         return o.recordID;
       }).join(',');
       this.qpObject.c = groupCatStr;
       delete this.qpObject.s;
     } else {
       delete this.qpObject.c;
       delete this.qpObject.s;
     }
     if (this.qpObject && this.qpObject.itemdetails) {
       delete this.qpObject.itemdetails;
     }
     return this.qpObject;
   } */
  }

  allBtnClick($event, group, optionalparams, subcategory) {
    this.routeAllBtn($event, group, optionalparams, subcategory);
    // if (!this.rtSharedService.getRepgroupOrderStatus()) {
    //     this.routeAllBtn($event, group, optionalparams, subcategory);
    // } else {
    //     this.confirmationService.confirm({
    //         // tslint:disable-next-line: max-line-length
    //         message: 'Your order process is not yet finished. You are leaving unfinished order(s) in your account. Click ‘Yes’ to exit or ‘No’ to go back and finish. ',
    //         header: 'Alert',
    //         icon: 'pi pi-exclamation-triangle',
    //         accept: async () => {
    //             this.routeAllBtn($event, group, optionalparams, subcategory);
    //             if (optionalparams !== 'catalog' && optionalparams !== 'promotion') {
    //                 this.rtSharedService.setRepgroupOrderStatus(false);
    //             }
    //         },
    //         reject: () => {
    //         },
    //         key: 'unfinishedOrder'
    //     });
    // }
  }
  routeAllBtn($event, group, optionalparams, subcategory) {
    console.log('Hiiiiiiiiiiiiiiiiiii', optionalparams);
    if (optionalparams === 'categorylist') {
      this.router.navigateByUrl('/category');
    } else if ($event.target.innerText === 'Shop by Brand' || (group && $event.target.innerText === group.text && optionalparams === 'Shop by Brand')) {
      this.router.navigateByUrl('/vendors');
    } else if ($event.target.innerText === 'Shop by Items' || (group && $event.target.innerText === group.text && optionalparams === 'Shop by Items')) {
      this.router.navigateByUrl('/shop');
    } else if ($event.target.innerText === 'Quick Order') {
      this.router.navigate([`/shop`], { queryParams: { quick: true } });
    } else if ($event.target.innerText === 'Promotions') {
      this.router.navigateByUrl('/promotions');
    } else if ($event.target.innerText === 'Catalogs') {
      this.router.navigate([`/vendors`], { queryParams: { catalogs: true } });
      // this.router.navigateByUrl('/documentlist');
    } else if ($event.target.innerText === '') {
      this.router.navigateByUrl('/' + this.logoClickURL);
    } else if (optionalparams === 'group') {
      this.router.navigate([`/shop`], { queryParams: this.getQpGroupObject(group) });
    } else if (optionalparams === 'category') {
      this.router.navigate([`/shop`], { queryParams: this.getQpObject(group) });
    } else if (optionalparams === 'subcategory') {
      this.router.navigate([`/shop`], { queryParams: this.getQpObject(group, subcategory) });
    } else if (optionalparams === 'vendors') {
      this.router.navigate([`/vendor`], { queryParams: { mfg: group.manufacturerID } });
    } else if (optionalparams === 'catalog') {
      window.open(this.auth.getCustomCatalogButtonData().url, '_blank');
    } else if (optionalparams === 'promotion') {
      window.open(this.auth.getCustomPromotionButtonData().url, '_blank');
    }
  }

  getRetailerQickReportAll() {

    this.onDestroy.next();
    const repGroupID = this.rtSharedService.getRepGroupID();
    const retailerID = this.rtSharedService.getRetailerID();
    this.retailerService.getRetailerQickReport([retailerID], repGroupID).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      if (response && response.success) {
        this.buyerQickReport = response.data[0];
        if (this.buyerQickReport && this.auth.getTickerText()) {
          this.buyerQickReport.orderSummary.yearToDate
          this.yourYTDSaving = (this.buyerQickReport.orderSummary.yearToDate * this.auth.getTickerText()) / 100;
          this.yourLYSaving = (this.buyerQickReport.orderSummary.lastYearTotal * this.auth.getTickerText()) / 100;
          this.yourLTTaving = (this.buyerQickReport.orderSummary.lifetimeTotal * this.auth.getTickerText()) / 100;
        }
      }
    }, (error) => {
      //  this.msgService.clear();
      //  this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    });
  }

}
