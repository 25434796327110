<p-dialog [(visible)]="displayNumberDialog" [closeOnEscape]="true" [contentStyle]="{'width': '300px'}"
          [header]="header" [maximizable]="false" [blockScroll]="true" [modal]="true" (onHide)="hideDialog()"  [resizable]="false">
    <!-- RC-509 (MG 17 JAN 2020): modified -->
    <div class="p-grid center">
        <div class="p-col-12">
            {{instructions}}
        </div>
        <div class="p-col-12">
            <label for="numberValue">{{label}}</label> <br/>
            <input id="numberValue" (ngModelChange)="discountChanged('per', $event)"
                   pInputText [(ngModel)]="order.orderDiscount" min="0" step="0.10" type="number"/>
        </div>
        <div class="p-col-12" *ngIf="label === 'Discount(%)'">
            <label for="discountAmt">Discount (&#36;)</label> <br/>
            <input id="discountAmt" (ngModelChange)="discountChanged('amt', $event)"
                   [(ngModel)]="discountAmt" pInputText type="number" step="0.10" min="0" value="0"/>
        </div>
        <div class="p-col-12 center" >
            <button label="Save" (click)="onSubmit()" pButton type="submit"></button>
        </div>
    </div>
</p-dialog>
