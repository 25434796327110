<div class="reptime-theme">
    <form [formGroup]="itemNoteForm" (ngSubmit)="saveItemNote()">
        <div class="d-flex mt-5 mb-10">
            <h3 class="my-0 flex-grow-1 title-text">Edit notes</h3>
        </div>
        <div class="p-grid p-5">
            <div class="p-col-12">
                <textarea style="width:100%;height: 110px;border-radius: 4px;" rows="5" pInputTextarea
                          placeholder="Enter notes" autoResize="autoResize"
                          formControlName="notes" id="notes" maxlength="250"></textarea>
            </div>
            <div class="p-col-12 RAlign">
                <button type="submit" class="ui-button ui-button-success btn-theme-color" icon="fa fa-save" pButton label="Save"></button>
            </div>
        </div>
    </form>

</div>
