import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';

@Component({
    selector: 'rs-discount-dialog',
    templateUrl: './discount-dialog.component.html',
    styleUrls: ['./discount-dialog.component.css']
})
export class DiscountDialogComponent implements OnInit, OnChanges {
    @Input() displayNumberDialog = false;
    @Input() order: any;
    @Input() header: string;
    @Input() label: string;
    @Input() instructions: string;
    @Output() saveEvent = new EventEmitter<any>();
    @Output() hideEvent: EventEmitter<any> = new EventEmitter<any>();
    discountAmt: any = 0;

    constructor() {
    }

    ngOnInit() {
        this.refreshValues();
    }

    ngOnChanges() {
        this.refreshValues();
    }

    refreshValues() {
        if (!this.order.orderDiscount) {
            this.order.orderDiscount = 0;
        } else {
            this.discountChanged('per', this.order.orderDiscount);
        }
    }

    onSubmit() {
        this.saveEvent.next(this.order.orderDiscount);
    }

    hideDialog() {
        this.hideEvent.emit(this.displayNumberDialog);
    }

    // RC-509 (MG 17 JAN 2020): modified to fix negative & 100 limit validation
    // RC-538 (MG 22-JAN-2020): modified to fix discount issue
    discountChanged(type, ev) {
        if (type === 'per') {
            setTimeout(() => {
                this.order.orderDiscount = ev = (ev === null || ev === '' ) ? 0 : ev;
                this.order.orderDiscount = +((+ev < 0) ? 0 : this.order.orderDiscount).toFixed(2);
                this.order.orderDiscount = +((+ev > 100) ? 100 : this.order.orderDiscount).toFixed(2);
                // this.discountAmt = (+this.order.orderDiscount / 100 * this.order.orderTotal).toFixed(2); // old code
                this.discountAmt = (+this.order.orderDiscount / 100 * this.order.orderGrossTotal).toFixed(2);
            }, 0);
        } else if (type === 'amt') {
            setTimeout(() => {
                ev = (+ev < 0) ? 0 : ev;
                // this.discountAmt = +((+ev > +this.order.orderTotal) ? +this.order.orderTotal : +ev).toFixed(2); // old code
                // this.order.orderDiscount = ((+this.discountAmt / this.order.orderTotal) * 100).toFixed(2);      // old code
                this.discountAmt = +((+ev > +this.order.orderGrossTotal) ? +this.order.orderGrossTotal : +ev).toFixed(2);
                this.order.orderDiscount = ((+this.discountAmt / this.order.orderGrossTotal) * 100).toFixed(2);
            }, 0);
        }
    }
}
