import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { OrderTileComponent } from 'src/app/components/common/order-tile/order-tile.component';
import { OrderTrackingTableComponent } from 'src/app/components/common/order-tracking-table/order-tracking-table.component';
import { PhoneNumberPipe } from 'src/app/pipes/phone-number.pipe';
import { FileSizePipe } from 'src/app/pipes/filesize.pipe';
import { SelectItemPipe } from 'src/app/pipes/selectItem.pipe';
import {
  AccordionModule,
  AutoCompleteModule,
  CalendarModule,
  CheckboxModule,
  FieldsetModule,
  InplaceModule,
  InputMaskModule,
  InputTextModule,
  OverlayPanelModule,
  PanelModule,
  ProgressSpinnerModule,
  ScrollPanelModule,
  SelectButtonModule,
  TabViewModule,
  TooltipModule,
  PanelMenuModule,
  SliderModule,
  MessageModule,
  MultiSelectModule,
  KeyFilterModule,
  SidebarModule,
  ChipsModule,
  RadioButtonModule,
} from 'primeng/primeng';
import { DataViewModule } from 'primeng/dataview';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { StepsModule } from 'primeng/steps';
import { MenubarModule } from 'primeng/menubar';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { SalespersonAutocompleteComponent } from 'src/app/widgets/salesperson-autocomplete/salesperson-autocomplete.component';
import { AddRetailerComponent } from '../wizard/add-retailer/add-retailer.component';
import { RetailerBillingComponent } from '../wizard/add-retailer/retailer-billing/retailer-billing.component';
import { WizardButtonsComponent } from '../widgets/wizard-buttons/wizard-buttons.component';
import { RetailerShippingComponent } from '../wizard/add-retailer/retailer-shipping/retailer-shipping.component';
import { RetailerContactComponent } from '../wizard/add-retailer/retailer-contact/retailer-contact.component';
import { RetailerAdvancedComponent } from '../wizard/add-retailer/retailer-advanced/retailer-advanced.component';
import { SalespersonAssignTableComponent } from '../components/common/salesperson-assign-table/salesperson-assign-table.component';
import { ItemsTableComponent } from '../components/common/items-table/items-table.component';
import { AdvancedSearchComponent } from '../widgets/advanced-search/advanced-search.component';
import { OrderStatusDropdownComponent } from '../widgets/order-status-dropdown/order-status-dropdown.component';
import { RetailersTableComponent } from '../components/common/retailers-table/retailers-table.component';
import { PromotionsTableComponent } from '../components/common/promotions-table/promotions-table.component';
import { RetailerShipToLocationTableComponent } from '../components/common/retailer-ship-to-location-table/retailer-ship-to-location-table.component';
import { DiscountDialogComponent } from '../components/discount-dialog/discount-dialog.component';
import { EditShipToDialogComponent } from '../modals/edit-ship-to-dialog/edit-ship-to-dialog.component';
import { CalendarComponent } from '../widgets/calendar/calendar.component';
import { ItemsListModalComponent } from '../modals/items-list-modal/items-list-modal.component';
import { EditBillToDialogComponent } from '../modals/edit-bill-to-dialog/edit-bill-to-dialog.component';
import { EditContactDialogComponent } from '../modals/edit-contact-dialog/edit-contact-dialog.component';
import { SelectContactDialogComponent } from '../modals/select-contact-dialog/select-contact-dialog.component';
import { PromotionsDialogComponent } from '../modals/promotions-dialog/promotions-dialog.component';
import { MinimumMetDialogComponent } from '../modals/minimum-met-dialog/minimum-met-dialog.component';
import { BasketItemTableComponent } from '../components/common/basket-item-table/basket-item-table.component';
import { BrowseItemsDialogComponent } from '../modals/browse-items-dialog/browse-items-dialog.component';
import { AddPaymentInfoDialogComponent } from '../modals/add-payment-info-dialog/add-payment-info-dialog.component';
import { EditCarrierAccountDialogComponent } from '../modals/edit-carrier-account-dialog/edit-carrier-account-dialog.component';
import { OrderDetailTableComponent } from '../components/common/order-detail-table/order-detail-table.component';
import { SalespersonRepgroupDropdownComponent } from '../widgets/salesperson-repgroup-dropdown/salesperson-repgroup-dropdown.component';
import { ISCDialogComponent } from '../modals/isc-dialog/isc-dialog.component';
import { OrderDetailNoteDialogComponent } from '../modals/order-detail-note-dialog/order-detail-note-dialog.component';
import { OrderSuccessDialogComponent } from '../modals/order-success-dialog/order-success-dialog.component';
import { CartbarComponent } from '../modules/pages/cartbar/cartbar.component';
import { ItemViewModelComponent } from '../modals/item-view-model/item-view-model.component';
import { LikeListDialogComponent } from '../modals/like-list-dialog/like-list-dialog.component';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { PasswordRequirementsComponent } from '../components/password-requirements/password-requirements.component';
import { NewOrderComponent } from '../modules/pages/new-order/new-order.component';
import { SidebarComponent } from '../modules/pages/sidebar/sidebar.component';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { UserInfoComponent } from '../modals/user-info/user-info.component';

import { VendorDetailComponent } from '../modules/pages/vendor-detail/vendor-detail.component';
import { AsyncPanelComponent } from '../widgets/async-panel/async-panel.component';

// Add for Manufacturer Ship To Location Table
import { RetailerShipToLocationListTableComponent } from '../modals/retailer-ship-to-location-table/retailer-ship-to-location-table.component';
import { RetailerContactTableComponent } from '../modals/retailer-contact-table/retailer-contact-table.component';
import { CarrierAccountsTableComponent } from '../modals/carrier-accounts-table/carrier-accounts-table.component';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService } from 'primeng/api';
import { PaymentInfoTableComponent } from '../modals/payment-info-table/payment-info-table.component';
import { MtPaymentInfoTableComponent } from '../modals/mt-payment-info-table/mt-payment-info-table.component';
import { MtPaymentBankDialogComponent } from '../modals/mt-payment-bank-dialog/mt-payment-bank-dialog.component';
import { MtPaymentCreditCardDialogComponent } from '../modals/mt-payment-credit-card-dialog/mt-payment-credit-card-dialog.component';
// import { AddManagePaymentInfoDialogComponent } from '../modals/add-manage-payment-info-dialog/add-manage-payment-info-dialog.component';
import { AddOrderSettingPaymentInfoDialogComponent } from 'src/app/components/dialogs/add-order-setting-payment-info-dialog/add-order-setting-payment-info-dialog.component';
import { CustomValidationDirectiveModule } from '../directives/custom-validation.directive';
import { DownloadImageZipComponent } from '../modals/download-image-zip/download-image-zip.component';
import { ItemDetailsComponent } from '../modules/pages/item-details/item-details.component';
import { ResizedImageComponent } from '../widgets/resized-image/resized-image.component';
import { RtCustomConfirmDialogModule } from '../modules/pages/rt-custom-confirmdialog/confirmdialog';
import { RtDatePipe } from '../pipes/rt-date.pipe';
import {ProgressBarModule} from 'primeng/progressbar';
import { PromotionDetailDialogComponent } from '../modals/promotion-detail-dialog/promotion-detail-dialog.component';
import { ThreeSixtyModule } from '../components/angular-three-sixty/angular-three-sixty';
import { RtCurrencyPipe } from '../pipes/rt-currency.pipe';
import { PrintPreviewDialogModule } from '../modals/print-preview-dialog/print-preview-dialog.module';
import { DigitalCatalogComponent } from '../components/common/digital-catalog/digital-catalog.component';
import { SearchItemTableComponent } from '../components/common/digital-catalog/search-item-table/search-item-table.component';

import { EinNumberDialogComponent } from '../modals/ein-number-dialog/ein-number-dialog.component';
import { DocumentTableComponent } from '../modules/pages/document-table/document-table.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RetailerOnboardingFormComponent } from '../components/common/retailer-onboarding-form/retailer-onboarding-form.component';
import { RetailerOnboardingComponent } from '../modals/retailer-onboarding/retailer-onboarding.component';
import { RtLanguagePipeModule } from '../pipes/rt-language.pipe';

@NgModule({
  declarations: [
    ItemsTableComponent,
    AdvancedSearchComponent,
    OrderStatusDropdownComponent,
    CalendarComponent,
    SalespersonAutocompleteComponent,
    RetailersTableComponent,
    PromotionsTableComponent,
    OrderTileComponent,
    OrderTrackingTableComponent,
    ItemsListModalComponent,
    ItemViewModelComponent,
    RetailerShipToLocationTableComponent,
    FileSizePipe,
    PhoneNumberPipe,
    SelectItemPipe,
    RetailerShipToLocationListTableComponent,
    EditShipToDialogComponent,
    EditBillToDialogComponent,
    EditContactDialogComponent,
    SelectContactDialogComponent,
    PromotionsDialogComponent,
    MinimumMetDialogComponent,
    BasketItemTableComponent,
    BrowseItemsDialogComponent,
    DiscountDialogComponent,
    AddPaymentInfoDialogComponent,
    EditCarrierAccountDialogComponent,
    AddRetailerComponent,
    OrderDetailTableComponent,
    RetailerBillingComponent,
    WizardButtonsComponent,
    SalespersonRepgroupDropdownComponent,
    RetailerShippingComponent,
    RetailerContactComponent,
    RetailerAdvancedComponent,
    SalespersonAssignTableComponent,
    ISCDialogComponent,
    OrderDetailNoteDialogComponent,
    OrderSuccessDialogComponent,
    CartbarComponent,
    LikeListDialogComponent,
    PasswordRequirementsComponent,
    NewOrderComponent,
    SidebarComponent,
    UserInfoComponent,
    VendorDetailComponent,
    AsyncPanelComponent,
    RetailerContactTableComponent,
    CarrierAccountsTableComponent,
    PaymentInfoTableComponent,
    MtPaymentInfoTableComponent,
    MtPaymentBankDialogComponent,
    MtPaymentCreditCardDialogComponent,
    AddOrderSettingPaymentInfoDialogComponent,
    // AddManagePaymentInfoDialogComponent,
    DownloadImageZipComponent,
    ItemDetailsComponent,
    ResizedImageComponent,
    RtDatePipe,
    PromotionDetailDialogComponent,
    RtCurrencyPipe,
    DigitalCatalogComponent,
    SearchItemTableComponent,
    EinNumberDialogComponent,
    DocumentTableComponent,
    RetailerOnboardingComponent,
    RetailerOnboardingFormComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    ButtonModule,
    CardModule,
    ChartModule,
    CheckboxModule,
    DataViewModule,
    InputTextModule,
    InplaceModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    CalendarModule,
    PanelModule,
    OverlayPanelModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    DialogModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    TooltipModule,
    RouterModule,
    ScrollPanelModule,
    InputMaskModule,
    SelectButtonModule,
    ToastModule,
    AccordionModule,
    FieldsetModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    StepsModule,
    MenubarModule,
    PanelMenuModule,
    SliderModule,
    MessageModule,
    KeyFilterModule,
    SidebarModule,
    NgxImgZoomModule,
    ChipsModule,
    CustomValidationDirectiveModule,
    RadioButtonModule,
    RtCustomConfirmDialogModule,
    ProgressBarModule,
    ThreeSixtyModule,
    PrintPreviewDialogModule,
    PdfViewerModule,
    RtLanguagePipeModule,

  ],
  exports: [
    FileSizePipe,
    PhoneNumberPipe,
    SelectItemPipe,
    ItemsTableComponent,
    AdvancedSearchComponent,
    OrderStatusDropdownComponent,
    CalendarComponent,
    SalespersonAutocompleteComponent,
    RetailersTableComponent,
    PromotionsTableComponent,
    OrderTileComponent,
    OrderTrackingTableComponent,
    ItemsListModalComponent,
    ItemViewModelComponent,
    RetailerShipToLocationTableComponent,
    EditShipToDialogComponent,
    EditBillToDialogComponent,
    EditContactDialogComponent,
    SelectContactDialogComponent,
    PromotionsDialogComponent,
    MinimumMetDialogComponent,
    BasketItemTableComponent,
    BrowseItemsDialogComponent,
    DiscountDialogComponent,
    AddPaymentInfoDialogComponent,
    EditCarrierAccountDialogComponent,
    AddRetailerComponent,
    OrderDetailTableComponent,
    RetailerBillingComponent,
    WizardButtonsComponent,
    SalespersonRepgroupDropdownComponent,
    RetailerShippingComponent,
    RetailerContactComponent,
    RetailerAdvancedComponent,
    SalespersonAssignTableComponent,
    ISCDialogComponent,
    OrderDetailNoteDialogComponent,
    OrderSuccessDialogComponent,
    CartbarComponent,
    LikeListDialogComponent,
    PasswordRequirementsComponent,
    NewOrderComponent,
    SidebarComponent,
    UserInfoComponent,
    VendorDetailComponent,
    AsyncPanelComponent,
    RetailerShipToLocationListTableComponent,
    RetailerContactTableComponent,
    CarrierAccountsTableComponent,
    PaymentInfoTableComponent,
    MtPaymentInfoTableComponent,
    MtPaymentBankDialogComponent,
    MtPaymentCreditCardDialogComponent,
    AddOrderSettingPaymentInfoDialogComponent,
    PromotionDetailDialogComponent,
    DigitalCatalogComponent,
    SearchItemTableComponent,
    DocumentTableComponent,
    RetailerOnboardingComponent,
    RetailerOnboardingFormComponent,
    // AddManagePaymentInfoDialogComponent,

    MessageModule,
    KeyFilterModule,
    ButtonModule,
    CardModule,
    ChartModule,
    CheckboxModule,
    DataViewModule,
    InputTextModule,
    InplaceModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    CalendarModule,
    PanelModule,
    OverlayPanelModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    DialogModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    TooltipModule,
    RouterModule,
    ScrollPanelModule,
    InputMaskModule,
    SelectButtonModule,
    ToastModule,
    AccordionModule,
    FieldsetModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    StepsModule,
    MenubarModule,
    PanelMenuModule,
    SliderModule,
    FileSizePipe,
    PhoneNumberPipe,
    SelectItemPipe,
    NgxImgZoomModule,
    ChipsModule,
    DownloadImageZipComponent,
    ItemDetailsComponent,
    ResizedImageComponent,
    RtDatePipe,
    ProgressBarModule,
    RtCurrencyPipe,
    EinNumberDialogComponent,
    RtLanguagePipeModule
  ],
  entryComponents: [
    ItemsListModalComponent,
    ItemViewModelComponent,
    RetailersTableComponent,
    PromotionsTableComponent,
    RetailerShipToLocationTableComponent,
    EditShipToDialogComponent,
    EditBillToDialogComponent,
    EditContactDialogComponent,
    SelectContactDialogComponent,
    PromotionsDialogComponent,
    MinimumMetDialogComponent,
    BrowseItemsDialogComponent,
    AddPaymentInfoDialogComponent,
    EditCarrierAccountDialogComponent,
    AddRetailerComponent,
    RetailerBillingComponent,
    WizardButtonsComponent,
    SalespersonRepgroupDropdownComponent,
    RetailerShippingComponent,
    RetailerContactComponent,
    RetailerAdvancedComponent,
    SalespersonAssignTableComponent,
    ISCDialogComponent,
    OrderDetailNoteDialogComponent,
    OrderSuccessDialogComponent,
    AdvancedSearchComponent,
    OrderStatusDropdownComponent,
    LikeListDialogComponent,
    PasswordRequirementsComponent,
    UserInfoComponent,
    RetailerShipToLocationListTableComponent,
    RetailerContactTableComponent,
    CarrierAccountsTableComponent,
    AddOrderSettingPaymentInfoDialogComponent,
    DownloadImageZipComponent,
    DigitalCatalogComponent,
    SearchItemTableComponent,
    PromotionDetailDialogComponent,
    EinNumberDialogComponent,
    DocumentTableComponent,
    RetailerOnboardingComponent,
    RetailerOnboardingFormComponent
  ],
  providers: [
    DatePipe,
    ConfirmationService,
    MessageService,
    RtDatePipe,
    RtCurrencyPipe
  ]
})
export class SharedModule { }
