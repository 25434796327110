<div class="reptime-theme">
<p-card class="card ">
    <p-header class="card-header search-tool-box has-other-options">

        <div class="flex-1 search-box">
            <rs-advanced-search [advanceOptions]="advanceOptions" [isResetLoading]="isResetLoading" [placeholderForSearchBar]="placeholderForSearchBar"
                [isSubmitLoading]="isSubmitLoading" [isStatusActive]="false" (submitEvent)="search($event)"
                (resetEvent)="reset($event)" (ready)="initSearchObj($event)"></rs-advanced-search>
        </div>

        <div class="other-options" *ngIf="auth.getEnableProfileEditing()">
            <div class="btn-group">
                <button class="ui-button ui-button-info" (click)="addShipToLocation()" pButton icon="fas fa-plus"
                    label="New"></button>
                    <!-- -->
                <button class="ui-button-danger ui-button" (click)="confirmDelete()" pButton icon="fas fa-minus"
                [disabled]="checkBoxPrimaryTrueOrFalse === false ||  (!selectedShippingArray || selectedShippingArray.length==0 ||
                selectedShippingArray.length === shipToLocations.length || selectedShippingArray[0].isPrimary === true)"
                    label="Delete{{(selectedShippingArray  && selectedShippingArray.length !== 0) ? ' (' + selectedShippingArray.length + ')' : ''}}"></button>
                <button class="ui-button-success ui-button ship-acc-success"
                    (click)="shippingLocationsTable.exportCSV({selectionOnly:true})" type="button" pButton
                    icon="fas fa-download" iconPos="left" label="Export{{selectedShippingArray && selectedShippingArray.length ?
                   ' (' + selectedShippingArray.length + ')' : ' All'}}"
                    [disabled]="!selectedShippingArray || selectedShippingArray.length==0"></button>
            </div>
        </div>

    </p-header>

    <p-table [autoLayout]="true" [columns]="shipToCols" [loading]="loading" [responsive]="false" [scrollable]="true"
        scrollHeight="350px" [value]="shipToLocations"
        dataKey="recordID" [(selection)]="selectedShippingArray" exportFilename="ShipToLocations" (selectionChange)="changeTableCheckbox($event)"
        #shippingLocationsTable (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event, shippingLocationsTable.rows)">

        <ng-template let-columns pTemplate="header">
            <tr>
                <th style="width:45px;" class="reptimesticky-possition reptimesticky-possition-left-0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" [style.width]="col.width">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                </th>
                <th style="width:90px;" class="reptimesticky-possition reptimesticky-possition-right-0" *ngIf="auth.getEnableProfileEditing()">
                </th>
            </tr>
        </ng-template>

        <ng-template let-columns="columns" let-rowData pTemplate="body" let-i="index">
            <tr [pSelectableRow]="rowData" [style.background]="sessionLocationId && (sessionLocationId?.recordID === rowData?.recordID) ? '#fad961':''" >
                <td class="center reptimesticky-possition reptimesticky-possition-left-0" style="width:45px;">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of columns" [style.width]="col.width">
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                        <div *ngSwitchCase="'phone'">{{rowData[col.field] | phone}}</div>
                        <div *ngSwitchCase="'name'">{{rowData[col.field]}}
                            <span style="color: darkorange;" *ngIf="rowData['isPrimary'] === true">
                                <i pTooltip="This is primary shipping locations" tooltipPosition="right" tooltipEvent="hover"
                                   class="fas fa-star"></i></span>
                        </div>
                    </div>

                </td>
                <td class="icon-cell reptimesticky-possition reptimesticky-possition-right-0" style="width:90px;" *ngIf="auth.getEnableProfileEditing()">
                    <div class="d-flex action-btns">
                        <a (click)="editShipToLocation($event,rowData, 'EDIT', i);" pTooltip="Edit" tooltipPosition="top"
                            title="Edit">
                            <i class="fas fa-pencil-alt"></i>
                        </a>
                        <a (click)="removeShipToLocation($event,rowData);" *ngIf="rowData['isPrimary'] === false" pTooltip="Delete" tooltipPosition="top"
                            title="Delete" class="ml-10 button-danger">
                            <i class="fas fa-trash-alt"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr class="table-empty">
                <td [attr.colspan]="shipToCols && shipToCols.length + 3" class="text-center">
                    {{loading ?'Loading ship to locations':'You have no locations yet. Use the "New" button to add one.'}}
                </td>
            </tr>
        </ng-template>

    </p-table>



</p-card>
<!-- <p-confirmDialog  key="mfrDialog" [appendTo]="'body'" [baseZIndex]="10000"></p-confirmDialog> -->
</div>
<RtCustom-confirmDialog key="shipToDeleteDialog" #shipToDeleteDialog header="Drop database" acceptLabel="Yes" rejectLabel="No" [baseZIndex]="10000" [appendTo]="'body'">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="Yes" class="ui-button" (click)="shipToDeleteDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="No" class="ui-button"
            (click)="shipToDeleteDialog.reject()"></button>
        <button type="button" class="ui-button" pButton icon="pi pi-angle-double-left" label="Back"
            (click)="shipToDeleteDialog.close($event)"></button>
    </p-footer>
</RtCustom-confirmDialog>
