import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {RetailerAPIService} from '../../services/api/retailer-api/retailer-api.service';

@Component({
    selector: 'rs-select-contact-dialog',
    templateUrl: './select-contact-dialog.component.html',
    styleUrls: ['./select-contact-dialog.component.scss']
})
export class SelectContactDialogComponent implements OnInit, OnDestroy {

    public selectedContact: any;
    public retailerID: string;
    public contactArr: any;
    public contactCols: any;
    public loading: boolean;
    private readonly destroyContactByRetailer = new Subject<void>();

    constructor(public config: DynamicDialogConfig,
                public ref: DynamicDialogRef,
                private retailerAPIService: RetailerAPIService) {
    }

    ngOnInit() {
        this.loading = true;
        this.selectedContact = this.config.data.selectedContact;
        this.retailerID = this.config.data.retailerID;
        this.getContactByRetailerID(this.retailerID);
        this.contactCols = [
            {field: 'firstName', header: 'First Name', width: '150px', spinner: true},
            {field: 'lastName', header: 'Last Name', width: '150px'},
            {field: 'email', header: 'Email', width: '150px'},
            {field: 'phone', header: 'Phone', width: '150px'},
            {field: 'cell', header: 'Cell', width: '150px'}
        ];
    }

    getContactByRetailerID(retailerID) {
      this.destroyContactByRetailer.next();
      this.retailerAPIService.getRetailerContactsByRetailerID(retailerID)
        .pipe(takeUntil(this.destroyContactByRetailer)).subscribe((data: any) => {
                this.contactArr = data.data;
                this.loading = false;
            });
    }

    selectContact(contact: any) {
        this.ref.close(contact);
    }

    ngOnDestroy() {
        this.destroyContactByRetailer.next();
    }
}
