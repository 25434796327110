export class RetailerShipToLocation {

    recordID: number;
    retailerID: string;
    active: boolean;
    address1: string;
    address2: string;
    city: string;
    country: string;
    email: string;
    fax: string;
    faxCountryCode: string;
    faxExtension: string;
    federalTaxNumber: string;
    hasCompanyLogo: boolean;
    name: string;
    notes: string;
    phone: string;
    phoneCountryCode: string;
    phoneExtension: string;
    recordDeleted: boolean;
    state: string;
    status: string;
    website: string;
    zip: string;
    isPrimary: boolean;
}
