import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-password-requirements',
    templateUrl: './password-requirements.component.html',
    styleUrls: ['./password-requirements.component.css']
})
export class PasswordRequirementsComponent implements OnInit {


    @Input() password;
    @Input() confirmPassword;

    constructor() {
    }

    ngOnInit() {
    }

}
