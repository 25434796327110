export class RepGroupRetailer {
    recordID: number;
    active: boolean;
    defaultCarrierType: string;
    flagNote: string;
    name: string;
    notes: string;
    orderSendMethod: string;
    retailerID: string;
    repGroupID: string;
    recordDeleted: boolean;
    retailerCategory: string;
    repGroupDivision: any;
    repGroupDivisionID: number;
    repGroupRetailerChainID: number;
    dateAdded: number;
    abbreviation: string;
    retailerStatus: string;
}
