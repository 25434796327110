import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../services/other/data/data.service';
import { environment } from '../../../../../src/environments/environment';
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import { HtmlToolsService } from '../../../services/other/html-tools/html-tools.service';
import { Subject } from 'rxjs';
import { APIResponse } from 'src/app/models/api-response';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { IntegrationService } from '../../../services/api/integration.service';
import { AuthService } from '../../../services/middleware/auth/auth.service';
import * as objectPath from 'object-path';

@Component({
  selector: 'app-retailer-onboarding-form',
  templateUrl: './retailer-onboarding-form.component.html',
  styleUrls: ['./retailer-onboarding-form.component.scss']
})
export class RetailerOnboardingFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() getIntegrationData: any;
  @Input() selectedRetailer: any;
  @Input() fromPage: any;
  @Input() getBuyerDetailsData: any;
  @Input() registerFormDisable = false;
  @Output() skipToStep = new EventEmitter<any>();
  @Output() saveRetailerData = new EventEmitter<any>();
  @Output() closeDialog = new EventEmitter<any>();
  public retailerGetBalanceForm: FormGroup;
  public countries: any[];
  countriesStateJSON: any;
  termAndConditionDisabled = false;
  saveFormLoader = false;
  formDataFromAPIResponse: any;
  copyToRetailer = false;
  private readonly destroyZipCodeList = new Subject<void>();
  private readonly onDestroySaveBuyerService = new Subject<void>();
  mtpayBuyeremailcCheckLoading: boolean;
  isReadonlyField = true;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private integrationService: IntegrationService,
    private msgService: MessageService,
    public authService: AuthService,
    private htmlTools: HtmlToolsService) { }

  ngOnInit() {
    this.initForm();
  }
  ngOnChanges(changes: any) {
    console.log('Rahul....159', changes, this.fromPage);
    console.log('DSP48', this.getBuyerDetailsData);

    // this.insertFormIntoRegisterData();
    // this.registerFormDisable = true;
    if (this.fromPage === 'manageCompany') {
      this.isReadonlyField = false;
    }
  }

  insertFormIntoRegisterData() {
    console.log('DSP555', this.retailerGetBalanceForm, this.getBuyerDetailsData);

    if (this.retailerGetBalanceForm && this.getBuyerDetailsData) {
      this.retailerGetBalanceForm.patchValue({ firstName: this.getBuyerDetailsData.firstName });
      this.retailerGetBalanceForm.patchValue({ businessName: this.getBuyerDetailsData.businessName });
      // this.retailerGetBalanceForm.patchValue({ businessURL: this.getBuyerDetailsData.businessUrl });
      this.retailerGetBalanceForm.patchValue({ lastName: this.getBuyerDetailsData.lastName });
      this.retailerGetBalanceForm.patchValue({ address1: this.getBuyerDetailsData.billingAddress && this.getBuyerDetailsData.billingAddress.addressLine1 });
      // this.retailerGetBalanceForm.patchValue({ address2: this.getBuyerDetailsData.billingAddress && this.getBuyerDetailsData.billingAddress.addressLine2 });
      this.retailerGetBalanceForm.patchValue({ city: this.getBuyerDetailsData.billingAddress && this.getBuyerDetailsData.billingAddress.city });
      this.retailerGetBalanceForm.patchValue({ state: this.getBuyerDetailsData.billingAddress && this.getBuyerDetailsData.billingAddress.state });
      this.retailerGetBalanceForm.patchValue({ zip: this.getBuyerDetailsData.billingAddress && this.getBuyerDetailsData.billingAddress.zipCode });
      this.retailerGetBalanceForm.patchValue({ country: this.getBuyerDetailsData.billingAddress && this.getBuyerDetailsData.billingAddress.countryCode });
      this.retailerGetBalanceForm.patchValue({ phone: this.getBuyerDetailsData.phone });
      this.retailerGetBalanceForm.patchValue({ email: this.getBuyerDetailsData.email });
      if (this.fromPage !== 'manageCompany') {
        this.mtPayEmailCheck();
      }
    }

  }

  ngOnDestroy() {
    this.destroyZipCodeList.next();
    this.onDestroySaveBuyerService.next();
  }
  initForm() {
    this.retailerGetBalanceForm = this.fb.group({
      firstName: [''],
      // businessURL: ['', [Validators.required, Validators.pattern('^(www)\\.([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      lastName: [''],
      address1: [''],
      // address2: ['', Validators.required],
      city: [''],
      state: [''],
      zip: [''],
      country: [environment.countryAbrv],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      businessName: ['', Validators.required],
      federalTaxNumber: ['', [Validators.required, Validators.maxLength(12)]],
    });
    this.dataService.getCountryList().subscribe((data: any[]) => {
      this.countries = data;
    });
    this.dataService.setCountriesState().then(res => {
      this.countriesStateJSON = res;
    });

    this.copyAddress(true);
    this.insertFormIntoRegisterData();
  }
  get validateChk() {
    return this.retailerGetBalanceForm.controls;
  }
  getErrorMsg(field, name) {
    if (field.errors.required) {
      return name + ' is required';
    }
    if (field.errors.email) {
      return 'Enter valid email';
    }

    if (field.errors.pattern && field.errors.pattern.requiredPattern ===
      '^(www)\\.([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?$') {
      return 'Enter website url e.g. www.eg.com';
    }

    if (field.errors.minlength && field.errors.minlength.actualLength) {
      return `${name} must be minimum of ${field.errors.minlength.requiredLength} length`;
    }
    if (field.errors.maxlength && field.errors.maxlength.actualLength) {
      return `${name} must be maximum of ${field.errors.maxlength.requiredLength} length`;
    }
    if (field.errors.emailIsTaken) {
      return 'The email is already registered';
  }
  }
  saveRetailerGetBalanceInfo($event) {
    if (this.retailerGetBalanceForm.valid) {

      const requestPayload = {
        email: this.validateChk.email.value,
        firstName: this.validateChk.firstName.value,
        lastName: this.validateChk.lastName.value,
        businessName: this.validateChk.businessName.value,
        // businessURL: this.validateChk.businessURL.value,
        phone: this.validateChk.phone.value,
        ein: this.validateChk.federalTaxNumber.value,
        billingAddress: {
          addressLine1: this.validateChk.address1.value,
          // addressLine2: this.validateChk.address2.value,
          countryCode: this.validateChk.country.value,
          state: this.validateChk.state.value,
          zipCode: this.validateChk.zip.value,
          city: this.validateChk.city.value,
        },
        isRegistered: true
      };

      // this.onDestroySaveBuyerService.next();

      console.log('DSP147', this.fromPage);
      this.saveFormLoader = true;
      if (this.getBuyerDetailsData) {
        this.integrationService.updateBuyerDetails(this.authService.getCompanyID(), requestPayload).subscribe((response: any) => {
          if (response && response.success) {
            this.saveFormLoader = false;
            this.formDataFromAPIResponse = response.data;
            this.msgService.clear();
            this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Retailer registered for MtPay' });
            this.saveRetailerInfo(this.formDataFromAPIResponse);
          } else {
            this.saveFormLoader = false;
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response.error, 'message', 'Error occurred during services request') });
          }
        }, (error) => {
          this.saveFormLoader = false;
          this.msgService.clear();
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });
      } else {
        this.integrationService.mtPayBuyerSave(this.authService.getCompanyID(), requestPayload).subscribe((response: any) => {
          if (response && response.success) {
            this.saveFormLoader = false;
            this.formDataFromAPIResponse = response.data;
            this.msgService.clear();
            this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Retailer registration successfully submitted' });
            this.saveRetailerInfo(this.formDataFromAPIResponse);
            // console.log('DSP : --- ', response);
          } else {
            this.saveFormLoader = false;
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response.error, 'message', 'Error occurred during services request') });
          }
        }, (error) => {
          this.saveFormLoader = false;
          this.msgService.clear();
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });
      }


    } else {
      this.htmlTools.validateAllFields(this.retailerGetBalanceForm);
    }
  }
  setObjectFromForm() {
    this.retailerGetBalanceForm.patchValue(this.selectedRetailer);
    const loginDetails = JSON.parse(sessionStorage.getItem('logindetail'));
    console.log('Rahul....$event1', this.selectedRetailer, this.retailerGetBalanceForm, loginDetails);
    this.retailerGetBalanceForm.patchValue({ businessName: (this.selectedRetailer && this.selectedRetailer.name) ? this.selectedRetailer.name : '' });
    this.retailerGetBalanceForm.patchValue({ firstName: (loginDetails && loginDetails.firstName) ? loginDetails.firstName : '' });
    this.retailerGetBalanceForm.patchValue({ lastName: (loginDetails && loginDetails.lastName) ? loginDetails.lastName : ''});
    if (this.fromPage !== 'manageCompany') {
      this.mtPayEmailCheck();
    }
    Object.keys(this.retailerGetBalanceForm.controls).forEach(key => {
      this.retailerGetBalanceForm.get(key).markAsDirty();
      this.retailerGetBalanceForm.get(key).markAsTouched({onlySelf: true});
    });
  }
  saveRetailerInfo($event) {
    this.saveRetailerData.emit($event);
  }
  countrySpecific() {
    this.setValidatorOnCountry(this.retailerGetBalanceForm);
    this.updateChange();
  }
  setValidatorOnCountry(formGroup: any) {
    if (formGroup.get('country').value === 'US') {
      formGroup.get('zip').setValidators([Validators.required, Validators.maxLength(5)]);
      formGroup.get('state').setValidators([Validators.required, Validators.maxLength(50)]);
      formGroup.get('zip').updateValueAndValidity();
      formGroup.get('state').updateValueAndValidity();
    } else {
      formGroup.get('zip').setValidators([Validators.maxLength(20)]);
      formGroup.get('state').clearValidators();
      formGroup.get('zip').updateValueAndValidity();
      formGroup.get('state').updateValueAndValidity();
    }
  }
  updateChange() {
    const zipcode = this.validateChk.zip.value.trim();
    if (zipcode.length >= 5) {
      const searchParams: any = {};
      const size = 3000;
      const offset = 0;
      const sort = 'country';
      const sortOrder = 1;
      searchParams.country = this.validateChk.country.value;
      searchParams.zipCode = this.validateChk.zip.value.trim();
      this.destroyZipCodeList.next();
      this.dataService.getZipCodeList(offset, size, sort, sortOrder, searchParams)
        .pipe(takeUntil(this.destroyZipCodeList)).subscribe((res: APIResponse) => {
          if (res.success) {
            if (res.data[0]) {
              this.validateChk.state.setValue(res.data[0].stateAbbreviation);
              this.validateChk.city.setValue(res.data[0].city);
            } else {
              this.validateChk.state.setValue('');
              this.validateChk.city.setValue('');
            }
          } else {
            this.validateChk.state.setValue('');
            this.validateChk.city.setValue('');
          }
        }, () => {
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });
    }
  }
  backToStep($event) {
    this.termAndConditionDisabled = true;
    this.skipToStep.emit($event);
  }
  copyAddress($event) {
    if ($event) {
      this.copyToRetailer = true;
      this.setObjectFromForm();
    } else {
      this.copyToRetailer = false;
      this.retailerGetBalanceForm.reset();
      this.retailerGetBalanceForm.patchValue({ country: environment.countryAbrv });
      this.setValidatorOnCountry(this.retailerGetBalanceForm);
    }
  }
  mtPayEmailCheck() {
    const getemail = this.retailerGetBalanceForm.get('email');
    if (getemail.invalid) {
        return;
    }
    if (this.fromPage !== 'manageCompany') {
      this.mtpayBuyeremailcCheckLoading = true;
      this.integrationService.checkmtpayemailreg(getemail.value).subscribe((r: any) => {
          this.mtpayBuyeremailcCheckLoading = false;
          if (r && (r.status !== 200 || r.message !== 'The email is available')) {
              getemail.setErrors({'emailIsTaken': true});
          } else {
              getemail.setErrors(null);
          }
      }, err => {
          this.mtpayBuyeremailcCheckLoading = false;
          getemail.setErrors(null);
      });
    }
  }
}
