import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})

export class CompanyAPIService {

    constructor(private http: HttpClient) {
    }

    async getCompanyCategories(): Promise<any> {
        const url = `${environment.apiUrl}reptime/company/categories`;
        return await this.http.get<any>(url).toPromise();
    }

    // B2B-53 (MG 04-FEB-2020): modified to save filtered documents
    getMFRDocuments(payload: any, queryStringParam: any) {
        let url = `${environment.apiUrl}reptime/api/company/document/search`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.post<any>(url, payload);
    }

    // B2B-53 (MG 04-FEB-2020): modified to save filtered documents
    downloadDoc(url) {
        return this.http.get(url, {responseType: 'blob' as 'blob'});
    }
}
