export class RetailerContact {

    recordID: number;
    retailerID: string;
    isPrimary: boolean;
    cell: string;
    cellCountryCode: string;
    email: string;
    fax: string;
    faxCountryCode: string;
    faxExtension: string;
    firstName: string;
    imagePath: string;
    lastName: string;
    middleName: string;
    notes: string;
    phone: string;
    phoneCountryCode: string;
    phoneExtension: string;
    recordDeleted: boolean;
    title: string;
}
