<p-toast position="top-right" class="reachout-toast"></p-toast>
<!-- B2B-57 (MG 30-JAN-2020): added loader -->
<!-- h-100 d-flex align-items-center -->
<div *ngIf="containerRetailer" class="home-page reptime-theme py-20 w-100" style="margin: 0 auto">
    <div style="padding: 10px; vertical-align: middle; margin: 0 auto; text-align: center;">
        <h1></h1>
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>
<!-- B2B-71 (NL 11-FEB-2020): added accordion inside information block and Past Order History Table -->
<div *ngIf="!containerRetailer" class="home-page reptime-theme pt-15 pb-20 w-100 resp-home res-home-myord">
    <div class="p-grid m-0">
        <div class="p-grid m-0 w-100 resp-main-info">
            <div class="p-sm-12 p-2 bill-to resp-rmpd-info" [ngClass]="getIntegrationData && getIntegrationData.length && mtPayAvilableForRetailer ? 'p-md-6 p-lg-3' : 'p-md-4'">
                <p-card class="card h-100 view-info-dash" styleClass="h-100 innr-info-dash">
                    <p-header class="card-header justify-content-between ">
                        <h3 class="my-0 dash-bigger theme-grayclr mr-10 card-text"><strong><i class="fa fa-fw fa-map-marker"></i> My
                                Bill To Location</strong></h3>
                        <div class="my-order-icon">
                            <a href="javascript:;" class="mr-10 icon-theme-color" pTooltip="Edit Bill To"
                                tooltipPosition="bottom" (click)="openEditRetailerBillToLocationModal(retailerObj)"><i
                                    class="fa fa-edit"></i></a>
                            <a href="javascript:;" class="icon-theme-color" pTooltip="View Bill Location"
                                tooltipPosition="bottom" (click)="showBillLocation()"><i class="fa fa-eye"></i></a>
                            <!-- <button class="mr-5" pTooltip="Edit Bill To" tooltipPosition="bottom" icon="fa fa-edit"
                                (click)="openEditRetailerBillToLocationModal(retailerObj)" pButton></button>
                            <button pTooltip="View Bill Location" tooltipPosition="bottom" icon="fa fa-eye"
                                (click)="showBillLocation()" pButton></button> -->
                        </div>
                    </p-header>
                    <!-- <div class="h-100 py-10 px-20" *ngIf="retailerObj">
                        <p><strong>Name:</strong>&nbsp;<span *ngIf="retailerObj.name">{{retailerObj.name}}</span></p>
                        <p><strong>Address:</strong>&nbsp;
                            <span *ngIf="retailerObj.address1">{{retailerObj.address1}}, </span>
                            <span *ngIf="retailerObj.city">{{retailerObj.city}}, </span>
                            <span *ngIf="retailerObj.state">{{retailerObj.state}}, </span>
                            <span *ngIf="retailerObj.country">{{retailerObj.country}}, </span>
                            <span *ngIf="retailerObj.zip">{{retailerObj.zip}}.</span>
                        </p>
                        <p><strong>Phone:</strong>&nbsp;<span *ngIf="retailerObj.phone">{{retailerObj.phone}}</span></p>
                        <p><strong>Email:</strong>&nbsp;<span *ngIf="retailerObj.email">{{retailerObj.email}}</span></p>
                    </div>
                    <ul class="list-group px-20" *ngIf="!retailerObj">
                        <li class="p-0 pb-15">
                            <h3 class="my-0">No Bill To</h3>
                        </li>
                    </ul> -->
                </p-card>
            </div>
            <div class="p-sm-12 p-2 resp-rmpd-info" [ngClass]="getIntegrationData && getIntegrationData.length && mtPayAvilableForRetailer ? 'p-md-6 p-lg-3' : 'p-md-4'">
                <p-card class="card h-100 view-info-dash" styleClass="h-100 innr-info-dash">
                    <p-header class="card-header justify-content-between ">
                        <h3 class="my-0 dash-bigger  theme-grayclr mr-10 card-text"><strong><i class="fa fa-fw fa-truck"></i> My Ship To
                                Location</strong></h3>
                        <div class="my-order-icon">
                            <a href="javascript:;" class="mr-10 icon-theme-color" pTooltip="Edit Ship To"
                                tooltipPosition="bottom" *ngIf="rShipToObj"
                                (click)="openAddEditRetailerShipToLocationModal(rShipToObj, 'EDIT')"><i
                                    class="fa fa-edit"></i></a>
                            <a href="javascript:;" class="mr-10 icon-theme-color" pTooltip="View Ship Location"
                                tooltipPosition="bottom" (click)="showShipLocation()"><i class="fa fa-eye"></i></a>
                            <a href="javascript:void(0);" class="icon-theme-color" (click)="openChangeShipToModal()"
                                pTooltip="Exchange Ship Location" tooltipPosition="bottom"><i
                                    class="fa fa-exchange"></i></a>
                            <!-- <button pTooltip="Edit Ship To" class="mr-5" tooltipPosition="bottom" icon="fa fa-edit" *ngIf="rShipToObj"
                                    (click)="openAddEditRetailerShipToLocationModal(rShipToObj, 'EDIT')" pButton></button>
                            <button pTooltip="View Ship Location" tooltipPosition="bottom" icon="fa fa-eye"
                                (click)="showShipLocation()" pButton></button> -->
                        </div>
                    </p-header>
                    <!-- <div class="h-100 py-10 px-20" *ngIf="rShipToObj">
                        <p><strong>Name:</strong>&nbsp;<span *ngIf="rShipToObj.name">{{rShipToObj.name}}</span></p>
                        <p><strong>Address:</strong>&nbsp;
                            <span *ngIf="rShipToObj.address1">{{rShipToObj.address1}}, </span>
                            <span *ngIf="rShipToObj.city">{{rShipToObj.city}}, </span>
                            <span *ngIf="rShipToObj.state">{{rShipToObj.state}}, </span>
                            <span *ngIf="rShipToObj.country">{{rShipToObj.country}}, </span>
                            <span *ngIf="rShipToObj.zip">{{rShipToObj.zip}}.</span>
                        </p>
                        <p><strong>Phone:</strong>&nbsp;<span *ngIf="rShipToObj.phone">{{rShipToObj.phone}}</span></p>
                        <p><strong>Email:</strong>&nbsp;<span *ngIf="rShipToObj.email">{{rShipToObj.email}}</span></p>
                    </div>
                    <ul class="list-group px-20" *ngIf="!rShipToObj">
                        <li class="p-0 pb-15">
                            <h3 class="my-0">No Ship To</h3>
                        </li>
                    </ul> -->
                </p-card>
            </div>
            <div class="p-sm-12 p-2" [ngClass]="getIntegrationData && getIntegrationData.length && mtPayAvilableForRetailer ? 'p-md-6 p-lg-3' : 'p-md-4'">
                <p-card class="card h-100 view-info-dash" styleClass="h-100 innr-info-dash order-currency-menu-wpr">
                    <p-header class="card-header justify-content-between ">
                        <h3 class="my-0 dash-bigger theme-grayclr dis-flex mr-10 card-text">
                            <strong><i class="{{showWidget ? 'fa fa-line-chart' : 'fa fa-fw fa-users'}}"></i>
                                {{showWidget ? 'My Order Details' : 'My Salesperson'}}
                            </strong>
                            <div class="ml-20" *ngIf='salesPersonLoading'>
                                <i class="fas fa-spinner fa-spin"></i>
                            </div>
                        </h3>
                        <!-- <button icon="fa fa-edit" style="visibility: hidden;" pButton></button> -->

                        <div class="order-detail-currency my-order-icon">
                             <a href="javascript:;" class="icon-theme-color"
                                pTooltip="{{showWidget ? 'Show Order Details' : 'View Salesperson detail'}}"
                                tooltipPosition="bottom" (click)="showSalesperson()"><i class="fa fa-eye" *ngIf="!showWidget"></i></a>
                            <!-- <button pTooltip="View Salesperson detail" tooltipPosition="bottom" icon="fa fa-eye"
                                (click)="showSalesperson()" pButton></button> -->
                            <a href="javascript:;" class="icon-theme-color" *ngIf="showWidget"><i class="fa fa-eye"></i></a>
                            <!-- currency menu -->
                            <div class="currency-dropdown-menu"  *ngIf="showWidget">
                                <div class="saving-sec">                                    
                                    <div class="currency-value">{{showWidget && buyerQickReport ? buyerNameID : 'Order Detail'}}</div>
                                </div>
                                <ul class="timeline-list" *ngIf="buyerQickReport">
                                    <li>
                                        <span>Last Order Date</span>
                                        <span class="currency-value">{{buyerQickReport.orderSummary.lastOrderDate | rtDate}}</span>
                                    </li>
                                    <li>
                                        <span>YTD</span>
                                        <span class="currency-value">{{buyerQickReport.orderSummary.yearToDate | rtCurrency}}</span>
                                    </li>
                                    <li>
                                        <span>LYTD</span>
                                        <span class="currency-value">{{buyerQickReport.orderSummary.lastYearToDate | rtCurrency}}</span>
                                    </li>
                                    <li>
                                        <span>LYT</span>
                                        <span class="currency-value">{{buyerQickReport.orderSummary.lastYearTotal | rtCurrency}}</span>
                                    </li>
                                </ul>
                                <ul class="timeline-list" *ngIf="!buyerQickReport">
                                    <li>
                                        <h3 class="my-0">No any Order Details</h3>
                                    </li>
                                 </ul>   
                            </div>
                            <!-- End -->
                        </div>
                    </p-header>
                    <!-- <p-scrollPanel [style]="{width: '100%', height: '143px'}">
                        <ul class="list-group px-20" *ngIf="salesperson.length">
                            <li *ngFor="let sp of salesperson" class="p-0 pb-15">
                                <h3 class="m-0">{{sp.salesperson.name}}
                                    <span class="chip bg-warning float-right">{{sp.salespersonType}}</span></h3>
                                <p><strong>Phone:</strong> {{sp.salesperson.phone}}</p>
                                <p><strong>Email:</strong> {{sp.salesperson.email}}</p>
                            </li>
                        </ul>
                        <ul class="list-group px-20" *ngIf="!salesperson.length">
                            <li class="p-0 pb-15">
                                <h3 class="my-0">No Salesperson</h3>
                            </li>
                        </ul>
                    </p-scrollPanel> -->
                </p-card>
            </div>
            <div class="p-md-6 p-lg-3 p-sm-12 p-2" *ngIf="getIntegrationData && getIntegrationData.length && mtPayAvilableForRetailer">
                <p-card class="card h-100 view-info-dash" styleClass="h-100 innr-info-dash">
                    <p-header class="card-header justify-content-between ">
                        <h3 class="my-0 dash-bigger theme-grayclr dis-flex mr-10 card-text">
                            <strong><i class="fas fa-concierge-bell mr-5"></i>mtServices</strong>
                        </h3>
                        <div class="my-order-icon">
                            <a href="javascript:;" class="icon-theme-color" pTooltip="mtServices"
                                tooltipPosition="bottom" (click)="integrationOverlay.toggle($event)"><i
                                    class="fa fa-eye"></i></a>
                        </div>
                    </p-header>
                </p-card>
            </div>

            <!-- <div class="p-md-4 p-sm-12 p-2 bill-to info-accor-card">
                <p-accordion class="card dashboard-accor-tbl">
                    <p-accordionTab>
                        <p-header class="card-header justify-content-between">
                            <h3 class="my-0"><strong><i class="fa fa-fw fa-map-marker"></i> Bill To Location</strong></h3>
                            <button pTooltip="Edit Bill To" tooltipPosition="bottom" icon="fa fa-edit"
                            (click)="openEditRetailerBillToLocationModal(retailerObj)" pButton></button>
                        </p-header>
                        <div class="h-100 py-10 px-20" *ngIf="retailerObj">
                            <p><strong>Name:</strong>&nbsp;<span *ngIf="retailerObj.name">{{retailerObj.name}}</span></p>
                            <p><strong>Address:</strong>&nbsp;
                                <span *ngIf="retailerObj.address1">{{retailerObj.address1}}, </span>
                                <span *ngIf="retailerObj.city">{{retailerObj.city}}, </span>
                                <span *ngIf="retailerObj.state">{{retailerObj.state}}, </span>
                                <span *ngIf="retailerObj.country">{{retailerObj.country}}, </span>
                                <span *ngIf="retailerObj.zip">{{retailerObj.zip}}.</span>
                            </p>
                            <p><strong>Phone:</strong>&nbsp;<span *ngIf="retailerObj.phone">{{retailerObj.phone}}</span></p>
                            <p><strong>Email:</strong>&nbsp;<span *ngIf="retailerObj.email">{{retailerObj.email}}</span></p>
                        </div>
                        <ul class="list-group px-20" *ngIf="!retailerObj">
                            <li class="p-0 pb-15">
                                <h3 class="my-0">No Bill To</h3>
                            </li>
                        </ul>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <div class="p-md-4 p-sm-12 p-2 info-accor-card">
                <p-accordion class="card dashboard-accor-tbl">
                    <p-accordionTab>
                        <p-header class="card-header justify-content-between">
                            <h3 class="my-0"><strong><i class="fa fa-fw fa-truck"></i> Ship To Locations</strong></h3>
                            <button pTooltip="Edit Ship To" tooltipPosition="bottom" icon="fa fa-edit" *ngIf="rShipToObj"
                                    (click)="openAddEditRetailerShipToLocationModal(rShipToObj, 'EDIT', $event)" pButton></button>
                        </p-header>
                        <div class="h-100 py-10 px-20" *ngIf="rShipToObj">
                            <p><strong>Name:</strong>&nbsp;<span *ngIf="rShipToObj.name">{{rShipToObj.name}}</span></p>
                            <p><strong>Address:</strong>&nbsp;
                                <span *ngIf="rShipToObj.address1">{{rShipToObj.address1}}, </span>
                                <span *ngIf="rShipToObj.city">{{rShipToObj.city}}, </span>
                                <span *ngIf="rShipToObj.state">{{rShipToObj.state}}, </span>
                                <span *ngIf="rShipToObj.country">{{rShipToObj.country}}, </span>
                                <span *ngIf="rShipToObj.zip">{{rShipToObj.zip}}.</span>
                            </p>
                            <p><strong>Phone:</strong>&nbsp;<span *ngIf="rShipToObj.phone">{{rShipToObj.phone}}</span></p>
                            <p><strong>Email:</strong>&nbsp;<span *ngIf="rShipToObj.email">{{rShipToObj.email}}</span></p>
                        </div>
                        <ul class="list-group px-20" *ngIf="!rShipToObj">
                            <li class="p-0 pb-15">
                                <h3 class="my-0">No Ship To</h3>
                            </li>
                        </ul>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <div class="p-md-4 p-sm-12 p-2 info-accor-card">
                <p-accordion class="card dashboard-accor-tbl">
                    <p-accordionTab>
                        <p-header class="card-header justify-content-between">
                            <h3 class="my-0"><strong><i class="fa fa-fw fa-users"></i> Your Salesperson</strong></h3>
                            <button icon="fa fa-edit" style="visibility: hidden;" pButton></button>
                        </p-header>
                        <div>
                            <ul class="list-group px-20" *ngIf="salesperson.length">
                                <li *ngFor="let sp of salesperson" class="p-0 pb-15">
                                    <h3 class="m-0">{{sp.salesperson.name}}
                                        <span class="chip bg-warning float-right">{{sp.salespersonType}}</span></h3>
                                    <p><strong>Phone:</strong> {{sp.salesperson.phone}}</p>
                                    <p><strong>Email:</strong> {{sp.salesperson.email}}</p>
                                </li>
                            </ul>
                            <ul class="list-group px-20" *ngIf="!salesperson.length">
                                <li class="p-0 pb-15">
                                    <h3 class="my-0">No Salesperson</h3>
                                </li>
                            </ul>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div> -->
        </div>
        <div class="p-col-12">
            <p-accordion class="card dashboard-accor-tbl">
                <p-accordionTab [selected]="entityType ==='my-orders'? true : false">
                    <p-header class="dash-bigger theme-grayclr order-history-header" style="font-weight: normal;">
                        <span>
                            <i class="fa fa-eye" style="font-size: 18px;"></i> View All Orders
                            <i class="fas fa-spinner fa-spin ml-20" *ngIf="loading"></i>
                        </span>
                        <span class="unfinished-order" *ngIf="retailerUnfinishedOrders"><i
                                class="fa fa-exclamation-circle" style="font-size: 18px;"></i> You have
                            {{retailerUnfinishedOrders}} unfinished orders</span>
                    </p-header>
                    <p-card class="card h-100" styleClass="h-100">
                        <!-- <p-header class="card-header search-tool-box">
                            <h3 class="my-0"><strong><i class="fa fa-fw fa-pencil-square-o"></i> Past Order History</strong>
                            </h3>
                        </p-header> -->
                        <p-table [columns]="cols" [value]="retailerOrders" dataKey="recordID" [lazy]="true"
                            [autoLayout]="true" [totalRecords]="total" [paginator]="true" [loading]="loading"
                            [responsive]="false" (onLazyLoad)="loadOrdersLazy($event)" class="paginator991 res-assing-item"
                            [rowsPerPageOptions]="[20, 50, 100, 250]" [(first)]="tableEvent.first" [pageLinks]="rtSharedService.getPageLinks(5)"
                            [rows]="tableEvent.rows" [sortField]="'orderDate'" [sortOrder]="-1">
                            <ng-template pTemplate="paginatorleft">
                                <div class="per-page-text px-10">Total:</div>
                                {{total}}
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" [pSortableColumn]="col.sortField">
                                        {{col.header}}
                                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th style="min-width: 85px;"
                                        class="reptimesticky-possition reptimesticky-possition-right-0"></th>
                                    <!-- <th width="50px"></th> -->
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <div [ngSwitch]="col.field">
                                            <div *ngSwitchCase="'repGroupManufacturerName'" class="d-inline" style="vertical-align: middle;">
                                                {{rtSharedService.getSeletedLanguage(rowData['repGroupManufacturerName'])}}
                                                <!-- {{rowData['repGroupManufacturerName'] ? rowData['repGroupManufacturerName'] : null}} -->
                                                <a href="javascript:;" *ngIf="rowData.paymentTerm && rowData.paymentTerm.toLowerCase() === 'mtpay'" class="ml-5" style="color: red;display: inline-block;vertical-align: middle;" pTooltip="mtPay Shipping Details" (click)="openShippingOP($event, rowData); shippingOverlay.toggle($event);" tooltipPosition="right"><i class="fas fa-exclamation-circle fs-20"></i></a>
                                            </div>
                                            <div *ngSwitchCase="'manufacturerOrderStatus'">
                                                <span *ngIf="rowData['manufacturerOrderStatus'] === 'NOT TRANSMITTED'">
                                                    <b>IN PROGRESS</b>
                                                </span>
                                                <span *ngIf="rowData['manufacturerOrderStatus'] !== 'NOT TRANSMITTED'">
                                                    <b>{{rowData[col.field]}}</b>
                                                </span>
                                            </div>
                                            <div *ngSwitchCase="'salesperson1.name'">
                                                {{rowData['salesperson1'] ? rowData['salesperson1']['name'] : null}}
                                            </div>
                                            <div *ngSwitchCase="'orderTotal'" class="text-right">
                                                {{rowData[col.field] ? (rowData[col.field] | rtCurrency): null}}
                                            </div>
                                            <div *ngSwitchCase="'orderDate'">
                                                {{rowData[col.field] | rtDate}}
                                            </div>
                                            <div *ngSwitchCase="'requestDate'">
                                                {{rowData[col.field] | rtDate}}
                                            </div>
                                            <div *ngSwitchCase="'poNumber'">
                                                {{rowData['poNumber']}}<span class="px-3" *ngIf="rowData.paymentTerm && rowData.paymentTerm.toLowerCase() === 'mtpay'"
                                                    style="background: #ffba01;margin-left: 5px;font-weight: 600;color:#fff;border-radius: 2px;">mt</span>
                                            </div>
                                            <div *ngSwitchCase="'paymentTerm'">
                                                {{rowData['paymentTerm']}}<span *ngIf="rowData['paymentTerm'] && rowData['paymentTerm'] === 'mtPay' && rowData['mtPayOrderPaymentDetailsJson'] && rowData['mtPayOrderPaymentDetailsJson'].paymentTerms">
                                                    {{rowData['mtPayOrderPaymentDetailsJson']?.paymentTerms === '0' ? '(Pay Upon Shipment via '+ (rowData['mtPayOrderPaymentDetailsJson']?.paymentMethod === 'creditCard' ? 'CC' : 'Bank') +')' : '(Net ' + rowData['mtPayOrderPaymentDetailsJson']?.paymentTerms + ' Days via ' + (rowData['mtPayOrderPaymentDetailsJson']?.paymentMethod === 'creditCard' ? 'CC' : 'Bank') + ')'}}
                                                </span>
                                            </div>
                                            <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                                        </div>
                                    </td>
                                    <!-- RC-719 (MK 02-APR-2020) stcky column -->
                                    <td class="reptimesticky-possition reptimesticky-possition-right-0">
                                        <div class="reptime-action-grid">
                                            <i class="fa fa-fw fa-truck  reptime-action-col"
                                                (click)="orderTracking(rowData)" pTooltip="Shipping Details"
                                                tooltipPosition="left" style="font-size:20px;"></i>
                                            <i class="fa fa-fw fa-file-pdf-o  reptime-action-col"
                                                (click)="previewOrder(rowData)" pTooltip="Download PDF"
                                                tooltipPosition="left" style="font-size:20px;color:red;"></i>
                                            <!-- [B2B-278] (RK 29-JUN-2020): Retailer can edit only INCOMPLETE order -->
                                            <!-- RC-1179 (MK 08 AUG 2020): single time api call -->
                                            <i class="fa fa-fw fa-edit reptime-action-col" pTooltip="Edit Order"
                                                tooltipPosition="left" style="font-size:21px;color:#444;"
                                                [routerLink]="['/finalize-order']"
                                                *ngIf="(rowData.repGroupOrderStatus === 'INCOMPLETE' || rowData.repGroupOrderStatus === 'OPEN' || rowData.repGroupOrderStatus === 'QUOTE') && rowData.manufacturerOrderStatus === 'NOT TRANSMITTED'"
                                                [queryParams]="createEditQueryParams(rowData)"></i>

                                            <!-- MG-fix icon replaced condition from >> !(rowData.repGroupOrderStatus === 'INCOMPLETE' || rowData.repGroupOrderStatus === 'OPEN' || rowData.repGroupOrderStatus === 'QUOTE') && !(rowData.manufacturerOrderStatus === 'NOT TRANSMITTED') -->
                                            <i class="fa fa-fw fa-eye reptime-action-col" pTooltip="View Order"
                                                tooltipPosition="left" style="font-size:21px;color:#444;"
                                                [routerLink]="['/finalize-order']"
                                                *ngIf="(rowData.repGroupOrderStatus !== 'INCOMPLETE' && rowData.repGroupOrderStatus !== 'OPEN' && rowData.repGroupOrderStatus !== 'QUOTE')"
                                                [queryParams]="createEditQueryParams(rowData)"></i>

                                            <i class="fa fa-fw fa-copy  reptime-action-col" pTooltip="Duplicate Order"
                                                tooltipPosition="left" style="font-size:21px;color:#444;"
                                                (click)="initiateOrderDuplicate(rowData)"></i>
                                        </div>
                                    </td>
                                    <!-- <td>
                                        <i class="fa fa-fw fa-edit" pTooltip="Edit Order" tooltipPosition="left"
                                            style="font-size:21px;color:#444;" [routerLink]="['/finalize-order']"
                                            *ngIf="rowData.repGroupOrderStatus === 'INCOMPLETE' && rowData.orderDate"
                                            [queryParams]="createEditQueryParams(rowData)"></i>

                                            <i class="fa fa-fw fa-eye reptime-action-col" pTooltip="View Order"
                                                tooltipPosition="left" style="font-size:21px;color:#444;"
                                                [routerLink]="['/finalize-order']"
                                                *ngIf="isOrderViewByRepGroup(rowData)"
                                                [queryParams]="createEditQueryParams(rowData)"></i>-->

                                    <!-- </div>
                                    </td> -->
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr class="table-empty">
                                    <td [attr.colspan]="cols.length + 2" class="text-center">
                                        {{loading ? 'Loading orders' : 'You have no orders yet.' }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-card>
                </p-accordionTab>
            </p-accordion>
            <!-- <p-card class="card h-100" styleClass="h-100">
                <p-header class="card-header search-tool-box">
                    <h3 class="my-0"><strong><i class="fa fa-fw fa-pencil-square-o"></i> Past Order History</strong>
                    </h3>
                </p-header>
                <p-table [columns]="cols" [value]="retailerOrders" dataKey="recordID" [lazy]="true" [autoLayout]="true"
                        [totalRecords]="total" [paginator]="true" [loading]="loading" [responsive]="true"
                        (onLazyLoad)="loadOrdersLazy($event)" [rowsPerPageOptions]="[20, 50, 100, 250]"
                        [(first)]="tableEvent.first" [rows]="tableEvent.rows" [sortField]="'orderDate'"
                        [sortOrder]="-1">
                    <ng-template pTemplate="paginatorleft">
                        <div class="per-page-text px-10">Total:</div>
                        {{total}}
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.sortField">
                                {{col.header}}
                                <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                            </th>
                            <th width="50px"></th>
                            <th width="50px"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns">
                                <div [ngSwitch]="col.field">
                                    <div *ngSwitchCase="'repGroupManufacturerName'">
                                        {{rowData['manufacturer'] ? rowData['manufacturer']['name'] : null}}
                                    </div>
                                    <div *ngSwitchCase="'manufacturerOrderStatus'">
                                            <span *ngIf="rowData['manufacturerOrderStatus'] === 'NOT TRANSMITTED'">
                                                IN PROGRESS
                                            </span>
                                            <span *ngIf="rowData['manufacturerOrderStatus'] !== 'NOT TRANSMITTED'">
                                                {{rowData[col.field]}}
                                            </span>                                                                                
                                    </div>
                                    <div *ngSwitchCase="'salesperson1.name'">
                                        {{rowData['salesperson1'] ? rowData['salesperson1']['name'] : null}}
                                    </div>
                                    <div *ngSwitchCase="'orderTotal'" class="text-right">
                                        {{rowData[col.field] ? (rowData[col.field] | rtCurrency): null}}
                                    </div>
                                    <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                                </div>
                            </td>
                            <td>
                                <i class="fa fa-fw fa-file-pdf-o" (click)="previewOrder(rowData)" pTooltip="Download PDF" tooltipPosition="left" style="font-size:20px;color:red;"></i>
                            </td>
                            <td>
                                <i class="fa fa-fw fa-edit" (click)="previewOrder(rowData)" pTooltip="Edit Order" tooltipPosition="left" style="font-size:21px;color:#007ad9;"
                                        [routerLink]="['/finalize-order']"
                                        *ngIf="rowData.repGroupOrderStatus === 'INCOMPLETE' && rowData.orderDate"
                                        [queryParams]="createEditQueryParams(rowData)"></i>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr class="table-empty" *ngIf="!loading">
                            <td [attr.colspan]="cols.length+2">You have no orders yet.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-card> -->
        </div>
        <!-- End Shimmer -->
        <!-- Curated Collections Start -->
        <!-- start shimmer effect -->
        <div class="offer-sec" *ngIf="calloutDataLoader && projectType == '2'">
            <div class="mid-layout">
                <div class="offer-sec-inner">
                    <h3 class="shimmer-effect p-5 Wid30 d-block mb-25" style="height: 35px;margin: 0px auto;"></h3>
                </div>
                <div class="offer-box-wpr">
                    <div class="offer-box" style="pointer-events: none;">
                        <div class="offer-image shimmer-effect" style="height: 200px;"></div>
                        <div class="offer-content">
                            <h4 class="offer-title shimmer-effect p-10 Wid80 "></h4>
                            <h6 class="offer-sub-title shimmer-effect p-5 Wid80 "></h6>
                        </div>
                    </div>
                    <div class="offer-box" style="pointer-events: none;">
                        <div class="offer-image shimmer-effect" style="height: 200px;"></div>
                        <div class="offer-content">
                            <h4 class="offer-title shimmer-effect p-10 Wid80 "></h4>
                            <h6 class="offer-sub-title shimmer-effect p-5 Wid80 "></h6>
                        </div>
                    </div>
                    <div class="offer-box" style="pointer-events: none;">
                        <div class="offer-image shimmer-effect" style="height: 200px;"></div>
                        <div class="offer-content">
                            <h4 class="offer-title shimmer-effect p-10 Wid80 "></h4>
                            <h6 class="offer-sub-title shimmer-effect p-5 Wid80 "></h6>
                        </div>
                    </div>
                    <div class="offer-box" style="pointer-events: none;">
                        <div class="offer-image shimmer-effect" style="height: 200px;"></div>
                        <div class="offer-content">
                            <h4 class="offer-title shimmer-effect p-10 Wid80 "></h4>
                            <h6 class="offer-sub-title shimmer-effect p-5 Wid80 "></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Shimmer -->
        <div class="offer-sec" *ngIf="calloutData?.length && loggedInUser && !calloutDataLoader && projectType == '2'">
            <div class="mid-layout">
                <div class="offer-sec-inner">
                    <h3 class="title title-highlights">Curated Collections</h3>
                </div>
                <div class="offer-box-wpr">
                    <ng-container *ngFor="let item of calloutData; index as i">
                        <a [routerLink]="['/shop']" [queryParams]="{callout: item?.recordID}" class="offer-box">
                            <div class="offer-image">
                                <img src="{{bucketUrl}}{{item?.picturePath}}"
                                    onerror="this.onerror=null;this.src='assets/images/no-found-image.jpg';"
                                    alt="callout-img">
                            </div>
                            <div class="offer-content">
                                <h4 class="offer-title" *ngIf="item?.title">{{item?.title}}</h4>
                                <h6 class="offer-sub-title" *ngIf="item?.subTitle">{{item?.subTitle}}</h6>
                                <p *ngIf="item?.description">{{item?.description}}</p>
                            </div>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- Curated Collections End -->
        <!-- callout design -->
        <!-- <div class="callout-main w-100" *ngIf="calloutData?.length">
            <div class="callout-main-inner p-grid m-0 w-100 justify-content-center">
                <ng-container *ngFor="let item of calloutData; index as i">
                    <div class="p-col-3" *ngIf="item?.showOnWebsite"
                        [class.callout-hover-off]="!item?.description?.trim()">
                        <p-card styleClass="ui-card-shadow">
                            <a [routerLink]="['/shop']" [queryParams]="{callout: item?.recordID}">
                                <p-header class="callout-img">
                                    <img src="{{bucketUrl}}{{item?.picturePath}}"
                                        onerror="this.onerror=null;this.src='assets/images/no-image-available.png';"
                                        alt="callout-img">
                                    <div class="callout-layer"></div>
                                   </p-header>
                                <div class="callout-content-block">
                                    <h2 class="dash-bigger theme-grayclr" style="font-weight: normal; font-size: 19px;"
                                        *ngIf="item?.title">{{item?.title}}</h2>
                                    <span class="callout-sub-title"
                                        style="font-weight: normal; font-size: 14px; color:#585958"
                                        *ngIf="item?.subTitle">{{item?.subTitle}}</span>
                                    <div class="callout-desc" *ngIf="item?.description">
                                        <div>{{item?.description}}</div>
                                    </div>
                                </div>
                            </a>
                        </p-card>
                    </div>
                </ng-container>
            </div>
        </div> -->
        <!-- calout end -->
    </div>
</div>
<!-- B2B-71 (NL 12-FEB-2020): added dialog inside information block -->
<!-- Start Dialog -->
<p-dialog [(visible)]="showBillLocationDialog"
    [contentStyle]="{'width': '450px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}"
    header="Bill To Location" modal="true" [maximizable]="false" [closeOnEscape]="true" [draggable]="false"
    styleClass="resp-bill-info overflow-auto cut-dialog-height" [blockScroll]="true" [resizable]="false">
    <div class="h-100 py-10" *ngIf="retailerObj">
        <p class="mb-5 word-break-all"><strong>Name:</strong>&nbsp;<span
                *ngIf="retailerObj.name">{{retailerObj.name}}</span></p>
        <p class="mb-5 word-break-all"><strong>Address:</strong>&nbsp;
            <span *ngIf="retailerObj.address1">{{retailerObj.address1}}, </span>
            <span *ngIf="retailerObj.city">{{retailerObj.city}}, </span>
            <span *ngIf="retailerObj.state">{{retailerObj.state}}, </span>
            <span *ngIf="retailerObj.country">{{retailerObj.country}}, </span>
            <span *ngIf="retailerObj.zip">{{retailerObj.zip}}.</span>
        </p>
        <p class="mb-5"><strong>Phone:</strong>&nbsp;<span *ngIf="retailerObj.phone">{{retailerObj.phone |
                phone}}</span></p>
        <p class="mb-5"><strong>Email:</strong>&nbsp;<span *ngIf="retailerObj.email">{{retailerObj.email}}</span></p>
    </div>
    <ul class="list-group" *ngIf="!retailerObj">
        <li class="p-0 pb-15">
            <h3 class="my-0">No Bill To</h3>
        </li>
    </ul>
</p-dialog>
<p-dialog [(visible)]="showShipLocationDialog"
    [contentStyle]="{'width': '450px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}"
    header="Ship To Locations" modal="true" [maximizable]="false" [closeOnEscape]="true" [draggable]="false"
    styleClass="resp-ship-info overflow-auto cut-dialog-height" [resizable]="false">
    <div class="h-100 py-10" *ngIf="rShipToObj">
        <p class="mb-5 word-break-all"><strong>Name:</strong>&nbsp;<span
                *ngIf="rShipToObj.name">{{rShipToObj.name}}</span></p>
        <p class="mb-5 word-break-all"><strong>Address:</strong>&nbsp;
            <span *ngIf="rShipToObj.address1">{{rShipToObj.address1}}, </span>
            <span *ngIf="rShipToObj.city">{{rShipToObj.city}}, </span>
            <span *ngIf="rShipToObj.state">{{rShipToObj.state}}, </span>
            <span *ngIf="rShipToObj.country">{{rShipToObj.country}}, </span>
            <span *ngIf="rShipToObj.zip">{{rShipToObj.zip}}.</span>
        </p>
        <p class="mb-5"><strong>Phone:</strong>&nbsp;<span *ngIf="rShipToObj.phone">{{rShipToObj.phone | phone}}</span>
        </p>
        <p class="mb-5"><strong>Email:</strong>&nbsp;<span *ngIf="rShipToObj.email">{{rShipToObj.email}}</span></p>
    </div>
    <ul class="list-group" *ngIf="!rShipToObj">
        <li class="p-0 pb-15">
            <h3 class="my-0">No Ship To</h3>
        </li>
    </ul>
</p-dialog>
<p-dialog [(visible)]="showsalepersonDialog"
    [contentStyle]="{'width': '500px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}"
    header="{{!showWidget ? 'Your Salesperson' : showWidget && buyerQickReport ? buyerNameID : 'Order Detail'}}"
    modal="true" [maximizable]="false" [closeOnEscape]="true" [draggable]="false"
    styleClass="resp-salesperson-info overflow-auto cut-dialog-height" [blockScroll]="true" [resizable]="false">
    <div *ngIf="!showWidget">
        <ul class="list-group" *ngIf="salesperson.length" style="padding: 0px;">
            <li *ngFor="let sp of salesperson" class="p-0 pb-15">
                <h3 class="m-0 mb-5 d-flex justify-content-between">
                    <span class="d-sales-personname">{{sp.salesperson.name}}</span>
                    <span class="" style="position: relative;">
                        <a href="javascript:;" class="chip bg-warning mr-5" (click)="showEmailDialog(sp)"
                            style="color: #58595b"><i class="far fa-envelope" style="color: #58595b"></i> Contact</a>
                        <div class="sales-typetext">{{sp.salespersonType}}</div>
                    </span>
                </h3>
                <p class="mb-5"><strong>Phone:</strong> {{sp.salesperson.phone | phone}}</p>
                <p class="mb-5"><strong>Email:</strong> {{sp.salesperson.email}}</p>
            </li>
        </ul>
        <ul class="list-group" *ngIf="!salesperson.length">
            <li class="p-0 pb-15">
                <h3 class="my-0">No Salesperson</h3>
            </li>
        </ul>
    </div>

    <!-- Show Widgets start -->
    <!-- <div *ngIf="showWidget">
        <div class="list-group" *ngIf="buyerQickReport" style="padding: 0px;">
            <div class="retailer-report">
                <div class="report">
                    <b>Last Order Date</b>
                    <span>{{buyerQickReport.orderSummary.lastOrderDate | rtDate}}</span>
                </div>
                <div class="report">
                    <b>YTD</b>
                    <span>{{buyerQickReport.orderSummary.yearToDate | rtCurrency}}</span>
                </div>
                <div class="report">
                    <b>LYTD</b>
                    <span>{{buyerQickReport.orderSummary.lastYearToDate | rtCurrency}}</span>
                </div>
                <div class="report">
                    <b>LYT</b>
                    <span>{{buyerQickReport.orderSummary.lastYearTotal | rtCurrency}}</span>
                </div>
            </div>
        </div>
        <ul class="list-group" *ngIf="!buyerQickReport">
            <li class="p-0 pb-15">
                <h3 class="my-0">No any Order Details</h3>
            </li>
        </ul>
    </div> -->
    <!-- Show Widgets over -->
</p-dialog>

<p-dialog [(visible)]="showEmailDialogs"
    [contentStyle]="{'width': '450px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}"
    [blockScroll]="true" header="Contact Salesperson" modal="true" [maximizable]="false" [closeOnEscape]="true"
    [draggable]="false" class="emaildialog" styleClass="emaildialog overflow-auto cut-dialog-height"
    [resizable]="false">
    <div>
        <div class="">
            <div class="p-grid p-5">
                <div class="p-col-12">
                    <label for="name">Salesperson Email*</label>
                    <div class="input-error-container">
                        <input [(ngModel)]="salespersonEmail" [required]="true" [disabled]="true" autocomplete="off"
                            class="control" pInputText type="text" />

                    </div>
                </div>
                <div class="p-col-12">
                    <label for="name">Your Message*</label>
                    <div class="input-error-container">
                        <textarea pInputTextarea class="control Wid100 email-msg" [required]="true"
                            [(ngModel)]="emailBody" (blur)="checkMessage(emailBody)"></textarea>
                    </div>
                    <label for="name" *ngIf="errorMsg" class="errorMsg">Please enter a message</label>
                </div>
                <div class="p-col-12 d-flex justify-content-end">
                    <p-button (click)="reachOut()" type="button" [styleClass]="'btn-theme-color mr-5'" label="Reach Out"
                        [disabled]="disableBtn"
                        [icon]="disableBtn ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-paper-plane'"></p-button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- End Dialog -->

<!-- // [B2B-127] (MG 15-APR-2020) modified to duplicate order -->
<p-dialog header="Duplicate Order" [(visible)]="displayOrderDuplicate" [closable]="true" [draggable]="false"
    [blockScroll]="true" [closeOnEscape]="true" [showHeader]="true" [style]="{'width': '650px'}" [positionTop]="60"
    [maximizable]="false" [modal]="true" [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'"
    class="responsive-ship-loc cut-dialog-height add-cat-dialog dup-order-dialog" [resizable]="false">
    <form (ngSubmit)="saveDuplicateOrderCheckPO()" [formGroup]="orderDuplicateForm">
        <!-- <div class="p-col-12"> -->

        <div class="p-col-4" style="float: left;">
            <label for="">Contact: <br><b>{{selectedOrderToDuplicate?.retailerContact?.firstName}}
                    {{selectedOrderToDuplicate?.retailerContact?.middleName}}
                    {{selectedOrderToDuplicate?.retailerContact?.lastName}}</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">BillTo: <br><b>{{ selectedOrderToDuplicate?.billToName }}</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">ShipTo: <br><b>{{ selectedOrderToDuplicate?.shipToName }}</b></label>
        </div>
        <div style="clear: both;"></div>
        <div class="p-col-4" style="float: left;">
            <label for="">Salesperson:
                <br><b>{{selectedOrderToDuplicate?.salesperson1 ? selectedOrderToDuplicate?.salesperson1?.name :
                    'N/A'}}</b></label>
        </div>


        <!-- {{selectedOrderToDuplicate?.}} -->

        <div class="p-col-4" style="float: left;">
            <label for="">PO Number: <br>
                <input id="accountNoDeactiveDup" type="text" (input)="updateChangesMade()"
                    formControlName="selectedOrderToDuplicatePoNumber" pInputText class="wid-100 h-30">
            </label>
            <!-- <label for="">PO Number: <br><b>{{selectedOrderToDuplicate?.poNumber}}-D</b></label> -->
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Order Total:
                <br><b>{{ (selectedOrderToDuplicate?.orderGrossTotal - (selectedOrderToDuplicate?.orderDiscount / 100 *
                    selectedOrderToDuplicate?.orderGrossTotal)) | rtCurrency }}</b></label>
        </div>
        <div style="clear: both;"></div>
        <div class="p-col-4" style="float: left;">
            <label for="">Discount:
                <br><b>{{(selectedOrderToDuplicate?.orderDiscount) ? selectedOrderToDuplicate?.orderDiscount :
                    '0'}}%</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for=""># of Items: <br><b>{{selectedOrderToDuplicate?.orderDetails?.length}}</b></label>
        </div>



        <div class="p-col-4" style="float: left;">
            <label for="">Order Code:
                <br><b>{{(selectedOrderToDuplicate?.orderCode) ? selectedOrderToDuplicate?.orderCode :
                    'N/A'}}</b></label>
        </div>
        <!-- <div class="p-col-4" style="float: left;" >
                <label for="">Ship Method: <br><b>{{(selectedOrderToDuplicate?.shippingMethod) ? selectedOrderToDuplicate?.shippingMethod : 'N/A'}}</b></label>
            </div>
            <div class="p-col-4" style="float: left;" >
                <label for="">Payment Method: <br><b>{{(selectedOrderToDuplicate?.paymentTerm) ? selectedOrderToDuplicate?.paymentTerm : 'N/A'}}</b></label>
            </div> -->

        <div style="clear: both;"></div>

        <div class="p-col-4" style="float: left;">
            <label for="">Order Date*</label>
            <rs-calendar (dateChange)="updateChangesMade($event, 'orderDateD')" formControlName="orderDateD"
                id="orderDateD" [required]="true"></rs-calendar>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Request Ship Date*</label>
            <rs-calendar (dateChange)="updateChangesMade($event, 'shipDateD')" id="shipDateD"
                formControlName="shipDateD" [required]="true" [minDate]="orderDuplicateForm.value.orderDateD">
            </rs-calendar>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Cancel Date</label>
            <rs-calendar (dateChange)="updateChangesMade($event, 'cancelDateD')" formControlName="cancelDateD"
                id="cancelDateD" [minDate]="orderDuplicateForm.value.shipDateD"></rs-calendar>
        </div>
        <!-- <div class="p-col-6" style="float: left;">
                <label for="">Order Code*</label> <br />
                <p-dropdown required="true" placeholder="Select a Code"></p-dropdown>
            </div> -->
        <!-- </div> -->
        <!-- (dateChange)="datesUpdate(order, 'ORDER_DATE')" [disabled]="!editable" [maxDate]="dateConvert(re.limitedOrderDate)" id="orderDate{{i}}" [(date)]="order.orderDate" -->
        <div style="clear: both"></div>
        <br /><br />
        <div class="" style="text-align: right;">

            <p-button type="submit" [styleClass]="'ui-button-success theme-maroon-btn'" label="Duplicate Order"
                [disabled]="!isValidateDuplicate" [icon]="isSavingData ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-save'">
            </p-button>
            <!-- <button pButton icon="fa fa-save" class="theme-maroon-btn" label="Duplicate Order" [disabled]="!isValidateDuplicate"  type="submit" ></button> -->
            <!-- <app-async-button [icon]="'fas fa-save'" [disabled]="!isValidateDuplicate" [loading]="isSavingData"
            type="submit" class="ui-button ui-button-success" [label]="'Duplicate Order'"></app-async-button> -->
        </div>
    </form>
</p-dialog>
<p-confirmDialog key="duplicate" [appendTo]="'body'" [baseZIndex]="10000"></p-confirmDialog>

<app-order-tracking-table *ngIf="orderTrackingDialog" (isOpenChange)="isOpenChange()"
    [orderTrackingDialog]="orderTrackingDialog" [orderTrackingData]="orderTrackingData">

</app-order-tracking-table>

<p-dialog header="mtPay Retailer Onboarding" [(visible)]="showGetBalancePopup" [closable]="true" [draggable]="false"
    [blockScroll]="true" [closeOnEscape]="true" [showHeader]="true" [style]="{'width': '650px'}" [positionTop]="60"
    [maximizable]="false" [modal]="true" [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'" [contentStyle]="{'overflow':'auto'}"
    class="responsive-ship-loc cut-dialog-height add-cat-dialog dup-order-dialog" [resizable]="false">
    <app-retailer-onboarding *ngIf="showGetBalancePopup" [getIntegrationData]="getIntegrationData" [selectedRetailer]="retailerObj"
        [getBuyerDetailsData]="getBuyerDetailsData" [getUserIntegrationData]="getUserIntegrationData"
        (skipDialog)="eventSkipDialog($event)" (loadBackgroundServices)="loadBackgroundServicesOnSuccess($event)"></app-retailer-onboarding>
</p-dialog>

<p-overlayPanel #integrationOverlay [appendTo]="'body'" [style]="{'width': 'calc(100% - 20px)', 'max-width': '350px', 'margin-left': '10px'}" [dismissable]="true"
    [showCloseIcon]="true">
    <ul class="p-0 m-0 customOPlist">
        <li *ngFor="let item of getIntegrationData;let i = index;" [ngClass]="{'disabledService': !mtPayAvilableForRetailer}" tooltipPosition="left" (click)="openIntegrationDailog(item?.serviceName)">
            <a class="set-integrat-img-a" href="javascript:;" >
                <img class="set-integrat-img" src="{{integrationServiceImg}}{{item.picturePath}}"  alt="{{item?.integrationServiceDescription}}" />
                <span>{{item?.integrationServiceDescription === 'mtPay' ? 'mtPay: Access Net Terms' : item?.integrationServiceDescription}}</span>
            </a> 
            <span class="is-new" *ngIf="item?.isNew"> NEW </span>
        </li>
    </ul>
</p-overlayPanel>
<div class="blank-div" *ngIf='isOpenOverlay'></div>
<p-overlayPanel #shippingOverlay [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [baseZIndex]="12001" styleClass="reptime-theme-overlay shipping-op" [style]='{width: "85vw"}' [dismissable]="false" [showCloseIcon]="true" (onHide)="hideShippingOp($event)">
    <div class="shipping-sec">                                    
        <div class="shipping-req">mtPay Shipping Details</div>
    </div>
    <div class="shipping-req-sec">
        <p-table [columns]="shippingCols" [value]="shippingValue" dataKey="recordID" sortField="invoiceNumber" [autoLayout]="true" paginatorPosition="bottom"  scrollHeight="400px" [lazy]="false" scrollable="true" [showLoader]="true" class="rt-tbltitle-vcenter shipping-table" [columnResizeMode]="true" [loading]="shippingSpinner" [loadingIcon] = "'fa fa-spinner fa-spin'" [paginator]="false"  [responsive]="false" [rowHover]="true">
            <!-- <ng-template pTemplate="caption">
                <div class="clearfix"><span style="float:left;font-size:16px;">Pending Shipping Requests</span> </div>
            </ng-template> -->
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">{{col.header}}<p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                    </th>
                    <th [style.width]="'75px'">Notes</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns" [style.width]="col.width">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchCase="'invoiceAmount'" [class]="col.class">{{(rowData[col.field] ? rowData[col.field] : 0) | rtCurrency}}</div>
                            <div *ngSwitchCase="'estimatedHandlingCost'" [class]="col.class">{{(rowData[col.field] ? rowData[col.field] : 0) | rtCurrency}}</div>
                            <div *ngSwitchCase="'estimatedMiscellaneousCost'" [class]="col.class">{{(rowData[col.field] ? rowData[col.field] : 0) | rtCurrency}}</div>
                            <div *ngSwitchCase="'estimatedShippingCost'" [class]="col.class">{{(rowData[col.field] ? rowData[col.field] : 0) | rtCurrency}}</div>
                            <div *ngSwitchCase="'shipDate'" [class]="col.class">{{(rowData[col.field] ? rowData[col.field] : 0) | rtDate: 'UTC'}}</div>
                            <div *ngSwitchCase="'trackinglink'" [class]="col.class"><a target="_blank" [href]="(rowData[col.field] ? rowData[col.field] : 'javascript:;')">{{(rowData[col.field] ? rowData[col.field] : null)}}</a> </div>
                            <div *ngSwitchCase="'mtPayTransactionsChargeDate'" [class]="col.class">
                                {{(rowData[col.field] ? rowData[col.field] : null) | rtDate: 'UTC'}}
                            </div>
                            <div *ngSwitchDefault class="center">{{rowData[col.field]}}</div>
                        </div>
                    </td>
                    <td style="text-align: center;" [style.width]="'75px'">
                        <div class="d-flex justify-content-center">
                        <!-- <p-button type="button" class="ml-5 mr-5" [styleClass]="'ui-button-success ui-button'" [disabled]="iconLoadStates['approve-'+rowData['recordID']]" (click)="processRequest(rowData, true)"  [icon]="iconLoadStates['approve-'+rowData['recordID']] ? 'fa fa-fw fa-spinner fa-spin' : 'fas fa-check'"></p-button>
                        <p-button type="button" class="ml-5 mr-5" [styleClass]="'ui-button-danger ui-button'" [disabled]="iconLoadStates['reject-'+rowData['recordID']]" (click)="processRequest(rowData, false)"  [icon]="iconLoadStates['reject-'+rowData['recordID']] ? 'fa fa-fw fa-spinner fa-spin' : 'fas fa-times'"></p-button> -->
                        <p-button type="button" class="ml-5 mr-5" [styleClass]="rowData?.notes ? 'ui-button ui-button-warning' : 'ui-button '" (click)="openShippingNote(rowData, $event);"  [icon]="'fas fa-sticky-note'"></p-button>
                        <!-- <a href="{{ rowData?.payPath }}" target="_blank" style="color: #fff;display: flex;justify-content: center;align-items: center;" class="ml-5 mr-5 ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only" >
                            <i class="fa fa-dollar-sign"></i>
                        </a> -->
                        <!-- <p-button type="button" class="ml-5 mr-5" [styleClass]="'ui-button ui-button-info'" (click)="openTxn(rowData)"  [icon]="'fas fa-dollar-sign'"></p-button> -->
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="table-empty">
                    <td [attr.colspan]="shippingCols.length + 1" class="text-center">
                        {{shippingSpinner ? 'Loading Data...' : 'You have no orders yet.' }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-overlayPanel>
<p-overlayPanel #shippingNotesOverlay [baseZIndex]="12002" [style]="{'width': '300px'}" [showCloseIcon]="true"  styleClass="reptime-theme-overlay" [dismissable]="false" [appendTo]="'body'">
    <b class="py-5 d-block">Shipping Notes</b>
    <div class="d-block">
        {{selectedShippingRow?.notes}}
    </div>
</p-overlayPanel>
<p-confirmDialog key="shippingDetails" [appendTo]="'body'" [baseZIndex]="12002"></p-confirmDialog>
