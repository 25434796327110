import { async } from '@angular/core/testing';
import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter, SimpleChanges, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BasketItemService } from '../../../services/middleware/basket-item/basket-item.service';
import * as objectPath from 'object-path';
import * as _ from 'lodash';
import { RTSharedService } from '../../../services/rtshared/rtshared.service';
import { RetailerService } from '../../../services/middleware/retailer/retailer.service';
import { ItemsAPIService } from '../../../services/api/items-api/items-api.service';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { OrderService } from '../../../services/middleware/order/order.service';
import { BasketItemVariantService } from '../../../services/middleware/basket-item-variant/basket-item-variant.service';
import { AuthService } from '../../../services/middleware/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { SidebarApiService } from '../../../services/api/sidebar-api/sidebar-api.service';
@Component({
  selector: 'app-cartbar',
  templateUrl: './cartbar.component.html',
  styleUrls: ['./cartbar.component.scss']
})
export class CartbarComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  basketSyncLoader = false;
  basketIscStatus = false;
  public subscription: Subscription;
  public cartData: any;
  public itemMfgIDs: any = [];
  public visibleConfirmModel = false;
  public showLoader = false;
  msgs: Message[] = [];
  public wishListItem: any[];
  @Input() existCartData: any;
  @Input() selectedCustomer: any;
  @Output() promotionsClickEvent = new EventEmitter<any>();
  @Output() metClickEvent = new EventEmitter<any>();
  @Output() checkoutClickEvent = new EventEmitter<any>();
  @Output() updateExistCardEvent = new EventEmitter<any>();
  // (RM 28-02-2020): Cart details open item view details
  @Output() viewItemDetailEvent = new EventEmitter<any>();
  @Input() pageType: any;
  @Input() cartBarPosition: any;
  @Input() confirmkey: any = 'app-cards';
  @Output() closeSidebar = new EventEmitter<any>();


  public logoBucketUrl = environment.logoBucketUrl;
  logoPath = this.logoBucketUrl + 'logo/{companyID}.png';
  oldQty = 0;
  oldQtyVariant = 0;
  public wholeMfrArray: any;
  public isIframeWindow: any = (window as any).iframeData;
  win: any = (window);
  public domainData: any = this.win.domainData;
  stickyTop;
  headerHeight: any;
  constructor(
    public basketItemService: BasketItemService,
    private rtSharedService: RTSharedService,
    public auth: AuthService,
    private msgService: MessageService,
    private orderService: OrderService,
    public bivService: BasketItemVariantService,
    private confirmationService: ConfirmationService,
    private sidebarApiService: SidebarApiService,
    private itemsAPIService: ItemsAPIService, private retailerService: RetailerService) {
  }

  async ngOnInit() {
    if (this.domainData && this.auth.getProjectType() === 2) {
      this.stickyTop = document.getElementById('lightovation-header');
      this.headerHeight = '80px';
    } else {
      this.stickyTop = document.getElementById('b2bheader');
      this.headerHeight = '123px';
    }
  }
  ngAfterViewInit() {
    this.isIframeWindow = (window as any).iframeData;
  }
  ngOnChanges(changes: SimpleChanges) {
    // [B2B-239-hotfix] (RM 01-05-2020): ISC Quantity total with compare minimumQuantity
    this.checkMininumItemIsc(this.existCartData);
    // if (changes.existCartData.currentValue !== changes.existCartData.previousValue && changes.existCartData.previousValue !== undefined) {
    if (this.existCartData && this.existCartData.items) {
      Object.keys(this.existCartData.items).forEach((k) => {
        this.existCartData.items[k].item.fullPicturePath = this.itemsAPIService.getFullPicturePath(
          this.existCartData.items[k].item.picturePath, this.existCartData.items[k].manufacturerID);
        this.existCartData.items[k].item.imageError = false;
        this.existCartData.items[k].item.imageLoader = true;
        this.existCartData.items[k].item.quantity = !this.existCartData.items[k].quantity ? 0 : this.existCartData.items[k].quantity;
        const rand = Math.floor(Math.random() * 20) + 1;
        this.existCartData.items[k].item.profilePic = this.getLogoPath(this.existCartData.items[k].manufacturerID) + '?time=' + rand + 5;
        if (this.existCartData.items[k].item.isc.length) {
          this.existCartData.items[k].item.isc.forEach((d: any) => {
            d.quantity = d.quantity ? d.quantity : 0;
          });
        }
      });
    }
  }
  ngOnDestroy() {
    console.log('>>>>>>>>ngOnDestroy');
  }
  setOldCartData(cartData: any) {
    if (cartData && cartData.manufacturer && cartData.manufacturer.length) {
      cartData.manufacturer = _.map(cartData.manufacturer, (m: any) => {
        if (m.items && m.items.length) {
          m.items = _.map(m.items, (i: any) => {
            if (this.cartData && this.cartData.manufacturer && this.cartData.manufacturer.length) {
              _.forEach(this.cartData.manufacturer, (om) => {
                if (om.items && om.items.length) {
                  _.forEach(om.items, (oi) => {
                    if (oi.recordID === i.recordID && oi.isTreeActive) {
                      i.isTreeActive = oi.isTreeActive;
                    }
                  });
                }
              });
            }
            return i;
          });
        }
        return m;
      });
    }

    return cartData;
  }
  // B2B-41 (MG 05-FEB-2020): modified to show order items summary
  async getCart(customer) {
    this.basketSyncLoader = true;
    console.log('getCart 3');
    const cartData = await this.basketItemService.getBasketItemData(customer);
    this.itemMfgIDs = this.getItemWithMfg(cartData);
    this.basketSyncLoader = false;
    this.auth.setCartItemCount(cartData.totalItems);
    // [B2B-239-hotfix] (RM 01-05-2020): ISC Quantity total with compare minimumQuantity
    this.checkMininumItemIsc(cartData);
    return cartData;
  }

  // [B2B-239-hotfix] (RM 01-05-2020): ISC Quantity total with compare minimumQuantity
  async checkMininumItemIsc(cartData) {
    if (cartData && cartData.length) {
      this.basketIscStatus = false;
      // [B2B-239-hotfix] (RM 01-05-2020): ISC Quantity total with compare minimumQuantity
      _.forEach(cartData.items, (itemData, key) => {
        itemData['basketIscStatus'] = false;
        if (itemData && itemData.basketItemVariants && itemData.basketItemVariants.length) {
          let totalIscQuantity = 0;
          _.forEach(itemData.basketItemVariants, (value, key) => {
            totalIscQuantity = totalIscQuantity + value.quantity;
          });
          if (totalIscQuantity < itemData.item.minimumQuantity) {
            itemData['basketIscStatus'] = true;
            this.basketIscStatus = true;
          }
        }
      });
    }
  } // END

  getItemWithMfg(cartData) {
    const itemIDs: any = [];
    if (objectPath.get(cartData, 'manufacturer', null)) {
      _.forEach(cartData.manufacturer, (o) => {
        _.forEach(o.items, (itemObj: any) => {
          if (itemObj.basketItemVariants && itemObj.basketItemVariants.length) {
            _.forEach(itemObj.basketItemVariants, (biv: any) => {
              itemIDs.push({
                manufacturerID: itemObj.manufacturerID,
                itemNumber: itemObj.itemNumber ? itemObj.itemNumber : itemObj.item.itemNumber,
                itemColorStyleID: biv.itemColorStyleID
              });
            });
          } else {
            itemIDs.push({
              manufacturerID: itemObj.manufacturerID,
              itemNumber: itemObj.item.itemNumber
            });
          }

        });
      });
    }
    return itemIDs;
  }
  setMfgMinimumMet(manufacturers) {
    return manufacturers.map((mfg: any) => {
      mfg.isMinimumMet = (mfg.discountedTotal >= mfg.minimumOrderAmount);
      mfg.totalQty = this.getMfgTotalQty(mfg.items);
      return mfg;
    });
  }

  getIsNewRetailer() {
    this.wholeMfrArray = this.sidebarApiService.mfgData;
    _.forEach(this.wholeMfrArray, (o) => {
      _.forEach(this.existCartData.manufacturer, (m) => {
        if (o.recordID === m.recordID) {
          m.minimumOrderAmount = o.minimumOrderAmount;
          m.minimumReorderAmount = o.minimumReorderAmount;
          m.isNewRetailer = o.isNewRetailer;
        }
      })
    });
  }
  getMfgTotalQty(mfgItems) {
    return _.sumBy(mfgItems, 'quantity');
  }
  getMfgName(item, mfgArr) {
    return mfgArr.find(x => x.recordID === item.manufacturerID).name;
  }
  decrQtyEvent(itemObj, itemVariant?: any) {
    if (itemVariant && itemVariant.quantity > itemObj.item.minimumQuantity) {
      itemVariant.quantity = itemVariant.quantity - itemObj.item.quantityIncrement;
      itemVariant.quantity = itemVariant.quantity < 0 ? 0 : itemVariant.quantity;
      // const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
      // this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
      this.commonQtyManageFunVariant(itemVariant, itemObj);
    } else if (!itemVariant && itemObj.item.quantity > itemObj.item.minimumQuantity) {
      itemObj.item.quantity = itemObj.item.quantity - itemObj.item.quantityIncrement;
      itemObj.item.quantity = itemObj.item.quantity < 0 ? 0 : itemObj.item.quantity;
      itemObj.quantity = itemObj.item.quantity;
      this.commonQtyManageFun(itemObj);
      const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
      // this.updateItemFromCart(itemObj);
    }
  }
  incrQtyEvent(itemObj, itemVariant?: any) {
    console.log('mouseup', itemObj);
    if (itemVariant) {
      itemVariant.quantity = itemVariant.quantity + itemObj.item.quantityIncrement;
      itemVariant.quantity = itemVariant.quantity < 0 ? 0 : itemVariant.quantity;
      // const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
      // this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
      this.commonQtyManageFunVariant(itemVariant, itemObj);

    } else {
      itemObj.item.quantity = itemObj.item.quantity + itemObj.item.quantityIncrement;
      itemObj.item.quantity = itemObj.item.quantity < 0 ? 0 : itemObj.item.quantity;
      itemObj.quantity = itemObj.item.quantity;
      const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
      this.commonQtyManageFun(itemObj);
      // this.updateItemFromCart(itemObj);
    }
  }
  // [B2B-237] (RM 20-04-2020): if condition added, set old minimum qty
  updateQtyEventDown(itemObj, itemVariant?: any) {
    itemVariant.oldQuantity = itemVariant.oldQuantity ? itemVariant.oldQuantity : itemVariant.quantity;
  } // END
  updateQtyEvent(itemObj, itemVariant?: any) {
    if (itemVariant && itemVariant.quantity <= itemObj.item.minimumQuantity) {
      console.log('21555');
      // [B2B-237] (RM 17-04-2020): if condition added, set minimum qty
      if (itemVariant.quantity === 0 || itemVariant.quantity === null) {
        console.log('21888');
        this.visibleConfirmModel = true;
        this.confirmationService.confirm({
          message: 'Are you sure you want to delete this item variant?',
          header: 'Delete Cart Item',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Yes',
          rejectLabel: 'No',
          accept: async () => {
            itemVariant.quantity = 0;
            const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
            this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
            itemVariant.oldQuantity = null;
          },
          reject: () => {
            this.visibleConfirmModel = false;
            itemVariant.quantity = itemVariant.oldQuantity ? itemVariant.oldQuantity : itemObj.item.minimumQuantity;
            itemVariant.oldQuantity = null;
          },
          key: this.cartBarPosition
        });
      } else {
        console.log('214000', itemVariant);
        // [B2B-239-hotfix] (RM 01-05-2020): comment this line
        // itemVariant.quantity = itemObj.item.minimumQuantity;
        // const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
        this.commonQtyManageFunVariant(itemVariant, itemObj);
        // this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
        itemVariant.oldQuantity = null;
      } // END
    } else if (!itemVariant && itemObj.item.quantity <= itemObj.item.minimumQuantity) {
      console.log('214888');
      itemObj.quantity = itemObj.item.minimumQuantity;
      this.updateItemFromCart(itemObj);
    }
    if (itemVariant && itemVariant.quantity > itemObj.item.minimumQuantity) {
      console.log('2153333');
      itemVariant.quantity = itemVariant.quantity < 0 ? 0 : itemVariant.quantity;
      this.commonQtyManageFunVariant(itemVariant, itemObj);
      // const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
      // this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
    } else if (!itemVariant && itemObj.item.quantity > itemObj.item.minimumQuantity) {
      console.log('2158888');
      // const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
      itemObj.quantity = itemObj.item.quantity;
      itemObj.quantity = itemObj.quantity < 0 ? 0 : itemObj.quantity;
      this.commonQtyManageFun(itemObj);
      // this.updateItemFromCart(itemObj);
    }
  }

  commonQtyManageFun(itemObj) {
    console.log('26222', itemObj.quantity);
    const nearsetItemObj = Math.round(itemObj.quantity / itemObj.item.quantityIncrement) * itemObj.item.quantityIncrement;
    if (itemObj.quantity === 0) {
      itemObj.quantity = 0;
      this.updateItemFromCart(itemObj);
    } else if (itemObj.quantity > 0 && itemObj.quantity < itemObj.item.minimumQuantity) {
      itemObj.quantity = itemObj.item.minimumQuantity;
      this.updateItemFromCart(itemObj);
    } else if (itemObj.quantity > 0 &&
      nearsetItemObj.toString() !== itemObj.quantity.toString() &&
      itemObj.quantity.toString() !== itemObj.item.minimumQuantity.toString()) {
      console.log('2855');
      setTimeout(() => {
        if (document.getElementById('YesButton')) {
          document.getElementById('YesButton').focus();
        }
      }, 0);
      this.confirmationService.confirm({
        message: `This process will break the manufacturer set increment quantity for this item.
        <br><br>Click 'Yes' to accept quantity of ${nearsetItemObj}.
        <br>Click 'No' to go back.`,
        header: 'Add Cart Item',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        accept: () => {
          itemObj.quantity = nearsetItemObj;
          this.updateItemFromCart(itemObj);
        },
        reject: () => {
          itemObj.item.quantity = !itemObj.recordID ? itemObj.item.minimumQuantity : this.oldQty;
        },
      });
    } else {
      this.updateItemFromCart(itemObj);
    }
  }
  onclickSaveOldQty(itemObj: any) {
    console.log('mousedown', itemObj);
    this.oldQty = 0;
    this.oldQty = _.cloneDeep(itemObj.item.quantity);

  }

  onclickSaveOldQtyVariant(itemObj, itemVariant) {
    console.log('mousedownvariant', itemVariant.quantity);
    this.oldQtyVariant = 0;
    this.oldQtyVariant = itemVariant.quantity;
  }
  checkMinimumMet(manufacturers) {
    return _.some(manufacturers, ['isMinimumMet', false]);
  }
  deleteItemFromCart(itemObj: any, existCartData) {
    this.visibleConfirmModel = true;
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this item?',
      header: 'Delete Cart Item',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: async () => {
        this.showLoader = true;
        await this.basketItemService.deleteBasketItemItem([itemObj.recordID]).then((res) => {
          this.basketItemService.setCartQtyUpdateEvent(res);
          if (res) {
            this.msgService.add({
              key: 'addItem',
              severity: 'info',
              summary: 'Item deleted',
              detail: `Item delete successfully.`
            });
          }
        });
        this.existCartData = _.remove(existCartData, (o: any) => o.recordID === itemObj.recordID);
        this.existCartData = await this.getCart(this.selectedCustomer);
        await this.getIsNewRetailer();
        this.setMfgMinimumMet(this.existCartData.manufacturer);
        this.updateExistCardEvent.emit({
          existCartData: this.existCartData,
          selectedCustomer: this.selectedCustomer, itemMfgIDs: this.itemMfgIDs
        });
        this.visibleConfirmModel = false;
        this.showLoader = false;
      },
      reject: () => {
        this.visibleConfirmModel = false;
      },
      key: this.cartBarPosition
    });
  }
  async updateItemFromCart(itemObj: any) {
    console.log(itemObj);
    this.showLoader = true;
    try {
      if (itemObj.itemDiscount) {
        if (itemObj.itemDiscount > 100) {
          itemObj.itemDiscount = 100;
        } else if (itemObj.itemDiscount < 0) {
          itemObj.itemDiscount = 0;
        }
      } else {
        itemObj.itemDiscount = 0;
      }
      if (itemObj.quantity && itemObj.quantity !== 0) {
        await this.basketItemService.saveItemToBasket(itemObj, this.selectedCustomer).then(async (res) => {
          this.basketItemService.setCartQtyUpdateEvent(res);
        });
      } else if (itemObj.quantity === 0) {
        await this.basketItemService.deleteBasketItemItem([itemObj.recordID]).then(async (res) => {
          this.basketItemService.setCartQtyUpdateEvent(res);
        });
      }
      this.cartData = await this.getCart(this.selectedCustomer);
      this.existCartData = this.cartData;
      await this.getIsNewRetailer();
      this.setMfgMinimumMet(this.existCartData.manufacturer);
      this.itemMfgIDs = this.getItemWithMfg(this.existCartData);
      this.updateExistCardEvent.emit({
        existCartData: this.existCartData,
        selectedCustomer: this.selectedCustomer, itemMfgIDs: this.itemMfgIDs
      });
      this.msgService.clear();
      this.msgService.add({
        key: 'addItem',
        severity: 'info',
        summary: 'Item Updated to Cart',
        detail: `${itemObj.item.itemNumber} was saved successfully.`
      });
      this.showLoader = false;
    } catch (e) {
      this.showLoader = false;
      this.msgService.add({
        key: 'addItem',
        severity: 'error',
        summary: 'Error',
        detail: `${e.message}`
      });
    }
  }
  async updatescsItemFromCart(itemObj: any, itemVariant: any) {
    const retailerID: string = this.selectedCustomer.recordID;
    const selectedShipTo: string = this.selectedCustomer.selectedShipTo.recordID;
    this.showLoader = true;
    itemObj.item.b2bPriceCode = itemObj.b2bPriceCode;

    if (itemVariant.quantity && itemVariant.quantity !== 0) {
      await this.bivService.saveItemVariantToBasket(retailerID, selectedShipTo, itemObj.item, itemVariant).then(async (res) => {
        this.basketItemService.setCartQtyUpdateEvent(res);
      });
    } else if (itemVariant.quantity === 0) {
      await this.bivService.deleteItemVariant([itemVariant.recordID]).then(async (res) => {
        this.basketItemService.setCartQtyUpdateEvent(res);
      });
    }
    this.cartData = await this.getCart(this.selectedCustomer);
    this.existCartData = this.cartData;
    await this.getIsNewRetailer();
    this.setMfgMinimumMet(this.existCartData.manufacturer);
    this.itemMfgIDs = this.getItemWithMfg(this.existCartData);
    this.updateExistCardEvent.emit({
      existCartData: this.existCartData,
      selectedCustomer: this.selectedCustomer, itemMfgIDs: this.itemMfgIDs
    });
    this.msgService.clear();
    this.msgService.add({
      key: 'addItem',
      severity: 'info',
      summary: 'Item Updated to Cart',
      detail: `${itemObj.item.itemNumber} was saved successfully.`
    });
    this.showLoader = false;
  }
  // B2B-102 (NL 24-FEB-2020) add shopCheckOut() function
  shopCheckOut() {
    this.confirmationService.confirm({
      message: 'Continue to Checkout?',
      header: 'Checkout Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: async () => {
        this.checkoutClickEvent.emit(true);
      },
      reject: () => {
      }
    });
  }

  // (RM 28-02-2020): Cart details open item view details
  viewItemDetail(item, index) {
    const itemobj = _.cloneDeep(item);
    this.itemMfgIDs = this.getItemWithMfg(this.existCartData);
    itemobj.item.manufacturerName = this.getMfgName(itemobj, this.existCartData.manufacturer);
    itemobj.item.manufacturerID = itemobj.manufacturerID;

    this.viewItemDetailEvent.emit({
      index, itemobj,
      existCartData: this.existCartData,
      itemMfgIDs: this.itemMfgIDs
    });
  }

  addTokens(url: string, params) {
    Object.keys(params).forEach(key => {
      url = url.replace('{' + key + '}', params[key]);
    });
    return url;
  }

  // B2B-191 (MG-23-MAR-2020) modified to show MFR list
  getLogoPath(recordID) {
    return this.addTokens(this.logoPath, { companyID: recordID });
  }

  commonQtyManageFunVariant(itemVariant, itemObj) {
    const filterExistCartItem: any = this.existCartData.items.find(o => o.recordID === itemObj.recordID);
    const nearsetItemObj = Math.round(itemVariant.quantity / itemObj.item.quantityIncrement) * itemObj.item.quantityIncrement;
    console.log('4633', itemVariant, itemObj, nearsetItemObj);

    if (itemVariant.quantity === 0) {
      itemVariant.quantity = 0;
      this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
    } else if (itemVariant.quantity > 0 && itemVariant.quantity < itemObj.item.minimumQuantity) {
      itemVariant.quantity = itemObj.item.minimumQuantity;
      this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
    } else if (itemVariant.quantity > 0 &&
      nearsetItemObj.toString() !== itemVariant.quantity.toString() &&
      itemVariant.quantity.toString() !== itemObj.item.minimumQuantity.toString()) {
      console.log('46888');
      setTimeout(() => {
        if (document.getElementById('YesButton')) {
          document.getElementById('YesButton').focus();
        }
      }, 0);
      // <br>Click 'Ignore' to set quantity as ${itemObj.quantity}.
      this.confirmationService.confirm({
        message: `This process will break the manufacturer set increment quantity for this item.
        <br><br>Click 'Yes' to accept quantity of ${nearsetItemObj}.
        <br>Click 'No' to go back.`,
        header: 'Add Cart Item',
        icon: 'pi pi-exclamation-triangle',
        key: this.confirmkey,
        accept: async () => {
          itemVariant.quantity = nearsetItemObj;
          this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
        },
        reject: (type) => {
          console.log('type', type);

          itemVariant.quantity = this.oldQtyVariant;
          this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
        },
      });
    } else {
      this.updatescsItemFromCart(filterExistCartItem, itemVariant).then(res => { });
    }
  }
}
