import { EventEmitter, Injectable } from '@angular/core';

import { Options } from '../../models/options';
import { OptionsProviderService } from '../options-provider/options-provider.service';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { RtLanguagePipe } from '../../../app/pipes/rt-language.pipe';
import * as _ from 'lodash';
// import { access } from 'fs';
export interface IRTSharedService {
    getProjectName(): string;

    getToken(): string;

    getUserID(): any;

    getRepGroupID(): string;

    getRetailerID(): string;

    getSalesPersonID(): string;

    getEntityName(): string;

    getEntityType(): any;

    getCompanyID(): any;

    getRepgroupOrderStatus(entityType: string): void;

    setToken(token: string): void;

    setUserID(userID: any): void;

    setRepGroupID(repGroupID: string): void;

    setRetailerID(retailerID: string): void;

    setSalesPersonID(salesPersonID: string): void;

    setEntityName(entityName: string): void;

    setEntityType(entityType: string): void;

    setRepgroupOrderStatus(entityType: string): void;
}

@Injectable()
export class RTSharedService implements IRTSharedService {

    private _token = null;
    private _userID = null; // 11
    private _repGroupID = null; // 'R2'
    private _salesPersonID = null;
    private _entityName = null;
    private _entityType = null;
    private _companyID = null;
    private _retailerID = null; // 11
    protected options: Options;
    public _selectedShipTo = null;
    public _repgroupOrderStatus = null;
    private selectedAddressEvent = new Subject<any>();
    isRemoveToken = new EventEmitter<any>();
    constructor(private _optionsProvider: OptionsProviderService,
        public _languagePipe: RtLanguagePipe) {
        this.options = this._optionsProvider.options;
        this._languagePipe = _languagePipe;

    }


    public getProjectName() {
        return this.options.projectName;
    }

    public getApiUrl() {
        return this.options.apiUrl;
    }

    public getImageUrl() {
        return this.options.imageUrl;
    }

    public getToken() {
        return this._token;
    }

    public setToken(token: string) {
        this._token = token;
    }

    public getUserID() {
        return this._userID;
    }

    public setUserID(userID: any) {
        this._userID = userID;
    }

    public getRepGroupID() {
        return this._repGroupID;
    }

    public setRepGroupID(repGroupID: string) {
        this._repGroupID = repGroupID;
    }

    public getRetailerID() {
        return this._retailerID;
    }

    public setRetailerID(retailerID: string) {
        this._retailerID = retailerID;
    }

    public getSalesPersonID() {
        return this._salesPersonID;
    }

    public getCompanyID() {
        return this._companyID;
    }

    public setSalesPersonID(salesPersonID: string) {
        this._salesPersonID = salesPersonID;
    }

    public getEntityType() {
        return this._entityType;
    }
    getEntityTypeStrfi() {
        switch (this.getEntityName()) {
            case 'Repgroup':
                return 'R';
            case 'Salesperson':
                return 'S';
            case 'Manufacturer':
                return 'M';
            case 'Retailer':
                return 'B';
            default:
                return '';
        }
    }
    public getEntityName() {
        return this._entityName;
    }

    public setEntityName(entityName: string) {
        this._entityName = entityName;
    }

    public setEntityType(entityType: string) {
        this._entityType = entityType;
    }
    // B2B-148 (MJ 12-03-2020 Added) for Ability to switch shipping location from dashboard
    public getSelectedShipTo() {
        return this._selectedShipTo;
    }

    public setSelectedShipTo(selectedShipTo: any) {
        this._selectedShipTo = selectedShipTo;
        console.log('_selectedShipTo', this._selectedShipTo);
        sessionStorage.setItem('retailerShipToLocationID', selectedShipTo ? JSON.stringify(selectedShipTo) : null);
        this.selectedAddressEvent.next(this._selectedShipTo);
    }
    public getSelectedShipToEvent(): Observable<any> {
        return this.selectedAddressEvent.asObservable();
    }
    // END
    public setRepgroupOrderStatus(entityName: any) {
        this._repgroupOrderStatus = entityName;
    }

    public getRepgroupOrderStatus() {
        return this._repgroupOrderStatus;
    }

    getBrowser() {
        let tem, M;
        const ua = navigator.userAgent;
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }
        return M[0];
    }

    getStringtToDate(value) {
        if (this.getBrowser().toLowerCase() === 'safari' || this.getBrowser().toLowerCase() === 'firefox') {
            value = value.replaceAll('-', '/');
            // console.log('value', value);
            if (moment(value).isValid()) {
                return new Date(value);
            } else {
                return null;
            }
        } else if (moment(value).isValid()) {
            return new Date(moment(value).format('MM-DD-YYYY'));
        } else {
            return null;
        }
    }

    getStringDateFormat(fromDateString) {
        return moment(fromDateString).format('YYYY-MM-DD');
    }
    checkmfglock(data) {
        if (data && !data.showLockedB2bItems && (data.b2bItemPricingPolicy === 'NeedBrandApproval' || data.b2bItemPricingPolicy === 'HideItemPrice')) {
            return true;
        }
        return false;
    }

    getPageLinks(limit?) {
        if (window.innerWidth <= 991) {
            return 2;
        } else {
            return limit ? limit : 5;
        }
    }

    getResponsiveWidth(width) {
        if (window.innerWidth <= 991) {
            return '90%';
        } else {
            return width;
        }
    }

    getLabelFromJson(key: any, type?: any, langJson?: any) {
        return this._languagePipe.transform(key, type, langJson);
    }

    //1 Paramter : Without Any Language(Original Name) 2 Parameter : Original Value (Without Json, with Laguage), 3 Parameter : Json Object Value
    getSeletedLanguage(manufacturerName, repGroupManufacturerName?: any, repGroupManufacturerName_json?: any) {
        let defaultLang;
        defaultLang = 'en-us';
        if (!_.isEmpty(repGroupManufacturerName_json)) {
            if (!_.isEmpty(repGroupManufacturerName_json[defaultLang])) {
                return repGroupManufacturerName_json[defaultLang];
            } else {
                if (_.isEmpty(repGroupManufacturerName)) {
                    return manufacturerName;
                } else {
                    return repGroupManufacturerName;
                }
            }
        } else {
            if (_.isEmpty(repGroupManufacturerName)) {
                return manufacturerName;
            } else {
                return repGroupManufacturerName;
            }
        }
    }
}
