import { NgModule, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'rtLanguage'
})
export class RtLanguagePipe implements PipeTransform {

    transform(value: any, type?: any, langJson?: any): any {
        const originalValue = value;
        value = _.toLower(value);
        const selectedLanguage = JSON.parse(localStorage.getItem('setLanguage') || '{}');
        if (type === 'value') {
            try {
                return langJson[`${selectedLanguage.languageCode}`] ? langJson[`${selectedLanguage.languageCode}`] : langJson[`en-us`] ? langJson[`en-us`] : originalValue;
            } catch (e) {
                return originalValue;
            }
        } else {
            try {
                const languageJson = JSON.parse(sessionStorage.getItem('languageJsonData') || '{}');
                const index = _.findIndex(languageJson, (o: any) => { return o.keyname === value; });
                const idx = _.findIndex(languageJson[index].languageLabelsResponse, (o: any) => { return o.languageCode === selectedLanguage.languageCode; });
                const engIdx = _.findIndex(languageJson[index].languageLabelsResponse, (o: any) => { return o.languageCode === 'en-us'; });
                return languageJson[index].languageLabelsResponse[idx].keyValue ? languageJson[index].languageLabelsResponse[idx].keyValue : languageJson[index].languageLabelsResponse[engIdx].keyValue;
            } catch (e) {
                try {
                    const languageJson = JSON.parse(sessionStorage.getItem('languageJsonData') || '{}');
                    const index = _.findIndex(languageJson, (o: any) => { return o.keyname === value; });
                    const engIdx = _.findIndex(languageJson[index].languageLabelsResponse, (o: any) => { return o.languageCode === 'en-us'; });
                    return languageJson[index].languageLabelsResponse[engIdx].keyValue;
                } catch (e) {
                    return originalValue;
                }
            }
        }
    }

}

@NgModule({
    declarations: [RtLanguagePipe],
    providers: [RtLanguagePipe],
    exports: [RtLanguagePipe],
})
export class RtLanguagePipeModule { }