import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Order} from '../../models/order';

@Component({
    selector: 'rs-order-status-dropdown',
    templateUrl: './order-status-dropdown.component.html',
    styleUrls: ['./order-status-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrderStatusDropdownComponent),
            multi: true
        }
    ]
})
export class OrderStatusDropdownComponent implements ControlValueAccessor, OnInit {

    @Input() orderStatuses = Order.MANUFACTURER_DROPDOWN_STATUSES;
    @Input() placeholder = 'Order Status';
    @Input() readonly = false;
    @Input() noBorder = true;
    @Output() showEvent = new EventEmitter<any>();
    @Output() hideEvent = new EventEmitter<any>();
    @Output() changeEvent = new EventEmitter<any>();
    @ViewChild('statusDropdown', {static: false}) statusDropdown;
    onChange;
    onTouched;
    currentStatus;

    constructor() {
    }

    ngOnInit() {
    }

    getStyleClass(status) {
        for (let i = 0; i < this.orderStatuses.length; i++) {
            const s = this.orderStatuses[i];
            if (s.value === status) {
                return s.styleClass;
            }
        }
        return 'open';
    }

    show(event) {
        this.showEvent.emit(event);
    }

    hide(event) {
        this.hideEvent.emit(event);
    }

    change(event) {
        this.changeEvent.emit(event);
        if (this.onChange !== undefined) {
            this.onChange(event.value);
        }
    }

    touched(event) {
        if (this.onTouched !== undefined) {
            this.onTouched(event.value);
        }
    }

    registerOnChange(fn: any): void {
        if (!this.readonly && this.statusDropdown) {
            this.statusDropdown.registerOnChange(fn);
        }

    }

    registerOnTouched(fn: any): void {
        if (!this.readonly && this.statusDropdown) {
            this.statusDropdown.registerOnTouched(fn);
        }
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        if (!this.readonly && this.statusDropdown) {
            this.statusDropdown.writeValue(obj);
        }
        this.currentStatus = obj;
    }

}
