import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';

import { AuthService } from '../../middleware/auth/auth.service';
import { RetailerService } from '../../middleware/retailer/retailer.service';
import { AuthGuardRouterSetupService } from '../auth-guard-router-setup/auth-guard-router-setup.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    public domainData: any = (<any>window).domainData;

    constructor(
        private _router: Router,
        private _auth: AuthService,
        private retailerService: RetailerService,
        private _AuthGuardRouterSetupService: AuthGuardRouterSetupService

    ) {
    }

    /**
     * check Activate.
     * @param {object} route
     * @param {object} state
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const url: string = state.url;
        // return this.fnCheckAuthenticate(url);
        // return this._AuthGuardRouterSetupService.fnCheckdomain(route, url).then((res) => {
        //     console.log('resres???', res);
        //     return res === true ? this.fnCheckAuthenticate(url, route) : false;
        // });
        if (this._auth.getProjectType() === 0) {
            return this._AuthGuardRouterSetupService.fnCheckdomain(route, url).then((res) => {
                return res === true ? this.fnCheckAuthenticateFailDomain(url) : false;
            });
        }
        return this.fnCheckAuthenticate(url, route);
    }

    /**
     * check Authenticate.
     * @param {string} url
     */
    private async fnCheckAuthenticate(url: any, route) {
        if (this._auth.getIDToken()) {
            const getAuthRetailer = this._auth.getAuthRetailer.value;
            if (getAuthRetailer && getAuthRetailer.loggedInRetailerID) {
                try {
                    const getRetailer: any = await this.retailerService
                        .getRetailerData(getAuthRetailer.loggedInRetailerID + '?companyID=' + getAuthRetailer.loggedInRetailerID);
                    const rg: any = this.domainData.repGroup;
                    console.log('getRetailer?????????', getRetailer);
                    this._auth.setRetailerData(getRetailer);
                    if (getRetailer && _.isArray(getRetailer.repGroupRetailers)) {
                        console.log('57777');

                        if (getRetailer.repGroupRetailers.length && getRetailer.shipToLocations.length > 0) {
                            console.log('60000', getRetailer);

                            console.log('getRepGroupRetailer', getRetailer.repGroupRetailers[0].repGroupID, rg.recordID);
                            const getRepGroupRetailer: any = _.find(getRetailer.repGroupRetailers, (o) =>
                                o.repGroupID === (rg && rg.recordID));

                            if (getRepGroupRetailer && getRepGroupRetailer.recordDeleted && !url.includes('rep-retailer-notfound')) {
                                console.log('10101010', url);
                                this._router.navigate(['/rep-retailer-notfound']);
                                return false;
                            } else if (getRepGroupRetailer && getRepGroupRetailer.approvedByRepGroup === 0 &&
                                !url.includes('rep-group-msg-pending')) {
                                console.log('6888888');
                                this._router.navigate(['/rep-group-msg-pending']);
                                return false;
                            } else if (getRepGroupRetailer && getRepGroupRetailer.approvedByRepGroup === 1
                                && url.includes('rep-group-msg')) {
                                console.log('74444444');
                                this._router.navigate(['/home']);
                                return false;
                            } else if (getRepGroupRetailer && getRepGroupRetailer.approvedByRepGroup === 2
                                && !url.includes('rep-group-access-denied')) {
                                console.log('7999999');
                                this._router.navigate(['/rep-group-access-denied']);
                                return false;
                            } else if (!getRepGroupRetailer && !url.includes('rep-group-msg')) {
                                console.log('833333', url);
                                this._router.navigate(['/rep-group-msg']);
                                return false;
                            }
                        } else if (!url.includes('rep-group-msg') && getRetailer.shipToLocations.length > 0) {
                            console.log('8888', url);
                            this._router.navigate(['/rep-group-msg']);
                            return false;
                        } else if (!url.includes('shipping-location-not-found') && getRetailer.shipToLocations.length === 0) {
                            console.log('9111', url);
                            this._router.navigate(['/shipping-location-not-found']);
                            return false;
                        }

                    }
                } catch (e) {
                    this._auth.removeAllToken();
                    this._router.navigate(['/login']);
                    return false;
                }
            }
            return true;
        } else {
            if (this._auth.getProjectType() === 2) {
                const urlPrefix = url.split("?")[0];
                if (urlPrefix === '/login' || urlPrefix === '/' || urlPrefix === '/home' || urlPrefix === '/magazine' || urlPrefix === '/vendors-list' || urlPrefix === '/vendor-details' || urlPrefix === '/store') {
                    console.log('call new')
                    return true;
                } else {
                    this._auth.removeAllToken();
                    let x = {path: route.routeConfig.path, queryParams: route.queryParams};
                    sessionStorage.setItem('connectionLostUrlData', JSON.stringify(x));
                    this._router.navigate(['/']);
                    return false;
                }
            }
            this._auth.removeAllToken();
            let x = {path: route.routeConfig.path, queryParams: route.queryParams};
            sessionStorage.setItem('connectionLostUrlData', JSON.stringify(x));
            this._router.navigate(['/login']);
            return false;
        }
    }
    private fnCheckAuthenticateFailDomain(url: string): boolean {
        const domainData = (window as any).domainData;

        if (!this._auth.getIDToken() || !domainData) {
            console.log('aaaaaaaaa');
            return true;
        } else {
            this._router.navigate(['/home']);
            return false;
        }
    }
}
