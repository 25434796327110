import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmationService, DynamicDialogConfig, DynamicDialogRef, MessageService, SelectItem} from 'primeng/api';
import * as objectPath from 'object-path';

import {RetailerBasic} from '../../../models/retailer-basic';
import {APIResponse} from '../../../models/api-response';
import {RetailerAPIService} from '../../../services/api/retailer-api/retailer-api.service';
import {RTSharedService} from '../../../services/rtshared/rtshared.service';

@Component({
    selector: 'rs-retailers-table',
    templateUrl: './retailers-table.component.html',
    styleUrls: ['./retailers-table.component.scss']
})
export class RetailersTableComponent implements OnInit {
    @Input() isCustomerSelect = false;
    @Input() customerType = 'billTo';
    @Input() salespersonID: string;
    @Input() manufacturerID: string;
    @Input() repGroupID: string;
    @Output() total ? = new EventEmitter<number>();
    @Input() isEditable: boolean;
    currentOffset: number;
    rows = 20;
    totalRecords: number;
    loading: boolean;
    isSubmitLoading: boolean;
    isResetLoading: boolean;
    retailers: RetailerBasic[];
    selectedRetailerArray: RetailerBasic[];
    cols: any[];
    searchForm;
    advanceOptions: any;
    createRetailer: any;
    getLazyLoadFunction: any;
    selectedRetailer: any;
    tableEvent: { rows: 20, first: 0 };
    modeOptions: SelectItem[];
    // modeOptions = [{mode: 'show'}, {mode: 'road'}];
    selectedMode = 'show';

    public entityType: string;

    constructor(private retailerAPIService: RetailerAPIService,
                private msgService: MessageService,
                private confirmationService: ConfirmationService,
                private rtSharedService: RTSharedService,
                public ref: DynamicDialogRef,
                public config: DynamicDialogConfig) {
        this.entityType = this.rtSharedService.getEntityType();
        this.modeOptions = [{value: 'road', label: 'Assigned'}, {value: 'show', label: 'All'}];
        if (this.entityType === 'Salesperson') {
            this.selectedMode = 'road';
        }
        this.advanceOptions = [
            {label: 'All', value: {searchByName: 'all', key: 'all'}},
            {label: 'Name', value: {searchByName: 'name', key: 'name'}},
            {label: 'Buyer ID', value: {searchByName: 'buyer id', key: 'recordID'}},
            {label: 'Email', value: {searchByName: 'email', key: 'email'}},
            {label: 'Zip', value: {searchByName: 'zip', key: 'zip'}},
            {label: 'Address', value: {searchByName: 'address', key: 'address'}},
            {label: 'City', value: {searchByName: 'city', key: 'city'}},
            {label: 'State', value: {searchByName: 'state', key: 'state'}},
            {label: 'Phone', value: {searchByName: 'phone', key: 'phone'}}
        ];
    }

    ngOnInit() {
        this.repGroupID = this.config.data.repGroupID;
        this.salespersonID = this.config.data.salespersonID;
        this.createRetailer = this.config.data.createRetailer;
        this.getLazyLoadFunction = this.config.data.getLazyLoadFunction;
        if (this.getLazyLoadFunction) {
            this.getLazyLoadFunction(this.callLazyLoad.bind(this));
        }
        this.cols = [
            {field: 'recordID', header: 'ID', width: 'auto', spinner: true, sortField: 'recordID'},
            {field: 'name', header: 'Customer Name', width: 'auto', spinner: true, sortField: 'retailer.name'},
            {field: 'email', header: 'Email', width: 'auto', spinner: true, sortField: 'retailer.email'},
            // {field: 'website', header: 'Website', width: 'auto', spinner:true},
            {field: 'address1', header: 'Address', width: 'auto', spinner: true, sortField: 'retailer.address1'},
            {field: 'city', header: 'City', width: 'auto', spinner: true, sortField: 'retailer.city'},
            {field: 'state', header: 'ST', width: 'auto', spinner: true, sortField: 'retailer.state'},
            {field: 'zip', header: 'Zip', width: 'auto', spinner: true, sortField: 'retailer.zip'},
            {field: 'phone', header: 'Phone', width: 'auto', spinner: true, sortField: 'retailer.phone'}
            // {field: 'active', header: 'Active', width: 'auto'},
        ];
        this.loading = true;
    }

    getSelectedRepGroup($event) {
        this.rtSharedService.setRepGroupID($event);
        if (!this.repGroupID) {
            this.repGroupID = this.rtSharedService.getRepGroupID();
        } else {
            this.repGroupID = this.rtSharedService.getRepGroupID();
            this.callLazyLoad();
        }
        const element: any = document.getElementsByClassName('ui-dialog-title')[0];
        element.innerText = `Select Customer for ${$event}`;
    }

    callLazyLoad() {
        this.loadCustomersLazy(this.tableEvent, this.searchForm);
    }

    loadCustomersLazy(e, searchParams) {
        this.tableEvent = e;
        let size = e.rows;
        let offset = e.first;
        let sort = e.sortField;
        let sortOrder = e.sortOrder;
        const searchType = searchParams.searchType;
        if (size === undefined) {
            size = 20;
        }
        if (offset === undefined) {
            offset = 0;
        }
        if (sort === undefined) {
            sort = 'retailer.name';
        }
        if (sortOrder === undefined) {
            sortOrder = 1;
        }
        this.loading = true;
        // TODO: add billto shipto in search params
        if (this.repGroupID) {
            searchParams.repGroupID = this.repGroupID;
        }
        // if (this.salespersonID) { // API error:500: message: "No property name found for type RepGroupSalespersonRetailer!"
        //     searchParams.salespersonID = this.salespersonID;
        // }
        if (this.manufacturerID) {
            searchParams.manufacturerID = this.manufacturerID;
        }
        this.retailerAPIService.getRetailerByRepGroup({
            offset,
            size,
            sort,
            sortOrder,
            searchType
        }, searchParams, this.selectedMode)
            .subscribe((response: APIResponse) => {
                if (response.success) {
                    this.retailers = response.data.retailers;
                    this.totalRecords = response.total;
                    if (this.total) {
                        this.total.emit(this.totalRecords);
                    }
                } else {
                    this.totalRecords = 0;
                    if (this.total) {
                        this.total.emit(0);
                    }
                    this.msgService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                    });
                }
                this.loading = false;
                this.isSubmitLoading = false;
                this.isResetLoading = false;
            }, (error) => {
                this.totalRecords = 0;
                if (this.total) {
                    this.total.emit(0);
                }
                this.loading = false;
                this.isSubmitLoading = false;
                this.isResetLoading = false;
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: objectPath.get(error, 'message', 'Error occurred during services request')
                });
            });
    }

    selectRetailer(retailer: any) {
        this.selectedRetailer = retailer;
    }

    goToBasket() {
        this.ref.close(this.selectedRetailer);
    }

    confirmDelete() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete ' +
                this.selectedRetailerArray.length + ' retailer' +
                (this.selectedRetailerArray.length === 1 ? '' : 's') + '?',
            header: 'Delete Selected',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: () => {
                this.deleteSelectedRetailer();
            },
            reject: () => {
            }
        });
    }

    onHeaderCheckboxToggle($event: any) {
        const isChecked = $event.checked;
        this.selectedRetailerArray = [];
        if (isChecked) {
            for (let i = 0; i < this.rows; i++) {
                this.selectedRetailerArray.push(this.retailers[i]);
            }
        }
    }

    reset(event) {
        this.isResetLoading = true;
        this.searchForm = event;
        const defaultEvent = {rows: 20, offset: 0};
        this.loadCustomersLazy(defaultEvent, this.searchForm);
    }

    initSearchObj(event) {
        this.searchForm = event;
    }

    search(event) {
        this.isSubmitLoading = true;
        this.searchForm = event;
        const searchType = event.searchType;
        const defaultEvent = {rows: this.rows, offset: this.currentOffset};
        this.loadCustomersLazy(defaultEvent, this.searchForm);
    }

    onModeChange() {
        const defaultEvent = {rows: 20, offset: 0};
        this.loadCustomersLazy(defaultEvent, this.searchForm);
    }

    updateOffset($event) {
        this.rows = $event.rows;
        this.currentOffset = $event.first;
    }

    onRetailerCreated($event) {
        this.retailers.push($event);
    }

    private deleteSelectedRetailer() {

    }

}
