<div class="header-topbar" *ngIf="topHeaderText">
    <!-- *ngIf="entityType !== 'login' && entityType !== 'shopping-cart' && entityType !== 'recover-password' && entityType !== 'buyer-signup' && entityType !== 'seller-signup' && entityType !== 'profile'"> -->
    <div class="layout">
        <div class="header-topbar-inner">
            <div class="welcome-note d-flex justify-content-center">
                <p>{{topHeaderText}}</p>
                <!-- <span class="mobile-hide">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                <p>Summer sale Discount 20% off <a href="javascript:;">Shop now</a></p> -->
            </div>
        </div>
    </div>
</div>
<header [ngClass]="(loggedInUser || isIframeWindow) && entityType != 'shop'?'shop-menu-auto':'shop-menu-left'">
    <div class="header-wpr" id="lightovation-header">
        <!-- [ngClass]="[(entityType === 'login' || entityType === 'recover-password' || entityType === 'buyer-signup' || entityType === 'seller-signup')?'none-sticky':'', (entityType === 'profile' || entityType === 'shopping-cart')?'ds-remove-pad':'']"> -->
        <div class="layout">
            <!-- *ngIf="entityType !== 'login' && entityType !== 'shopping-cart' && entityType !== 'recover-password' && entityType !== 'buyer-signup' && entityType !== 'seller-signup'&& entityType !== 'profile'"> -->
            <div class="header-inner {{(loggedInUser || isIframeWindow) ? 'logged-in-header':'logged-out-header'}}">
                <!-- <a routerLink="/" class="logo"><img src="/assets/images/lightovation/lightovation-logo.png" alt="MarketTime"></a> -->
                <!-- <img class="logo" [src]="logo" alt="{{domainData.domainName}}"
                    onError="this.src='https://via.placeholder.com/125x50?text=NO LOGO';"  (click)="allBtnClick($event,null,null, null)"/> -->
                <div class="logo">
                    <a href="javascript:void(0);" (click)="!isIframeWindow ? allBtnClick($event,null,null, null) : ''">
                        <img [src]="logo" alt="{{domainData.domainName}}"
                            onError="this.src='https://via.placeholder.com/420x110?text=NO LOGO';" />
                    </a>
                </div>

                <div class="cat-menu-sec" *ngIf="auth.checkthisEntityType()">
                    <div class="cat-menu-wpr">
                        <!-- With Login Shop By Category  Start-->
                        <a href="javascript:;" class="cat-menu-btn" *ngIf="loggedInUser && !notApprovedByRepGroup">
                            <img src="/assets/images/lightovation/cat-menu.svg" alt="Category Menu">
                            <span class="cat-menu-text">Shop by Category</span>
                        </a>
                        <!-- With Login Shop By Category End-->
                        <!-- WithOut Login Shop By Category Start-->
                        <a href="javascript:;" class="cat-menu-btn"
                            *ngIf="!loggedInUser && notApprovedByRepGroup && !isIframeWindow">
                            <img src="/assets/images/lightovation/cat-menu.svg" alt="Category Menu">
                            <span class="cat-menu-text">Shop by Category</span>
                        </a>
                        <a href="javascript:;" class="cat-menu-btn"
                            *ngIf="!loggedInUser && !notApprovedByRepGroup && !isIframeWindow">
                            <img src="/assets/images/lightovation/cat-menu.svg" alt="Category Menu">
                            <span class="cat-menu-text">Shop by Category</span>
                        </a>
                        <a href="javascript:;" class="cat-menu-btn" *ngIf="!loggedInUser && isIframeWindow">
                            <img src="/assets/images/lightovation/cat-menu.svg" alt="Category Menu">
                            <span class="cat-menu-text">Shop by Category</span>
                        </a>
                        <!-- WithOut Login Shop By Category End-->
                        <ul class="cat-menu" *ngIf="auth.RepGroupCategoryGroupsList.length">
                            <li class="has-dropdown"
                                *ngFor="let grplist of auth.RepGroupCategoryGroupsList; let i = index">
                                <a href="javascript:;"
                                    (click)="!isIframeWindow ? allBtnClick($event, grplist, 'group', null) : ''">{{grplist.groupName}}</a>
                                <!--Manufacturers -->
                                <ul class="cat-dropdown mfr-cat-down"
                                    *ngIf="auth.getCategoryGroupType() === 'vendors' && grplist?.repGroupCategoryManufacturers.length>1">
                                    <li *ngFor="let mfr of grplist?.repGroupCategoryManufacturers">
                                        <a href="javascript:;"
                                            (click)="!isIframeWindow ? allBtnClick($event, mfr, 'vendors', null) : ''">
                                            {{mfr?.repGroupManufacturerName}}</a>
                                    </li>
                                </ul>
                                <!--Manufacturers over -->

                                <!--Category -->
                                <ul class="cat-dropdown overflow-y-auto sec-inner-menu"
                                    *ngIf="auth.getCategoryGroupType() === 'category' && grplist?.repGroupCategoryResponse.length">

                                    <li class="has-dropdown" (mouseenter)="mouseEnterMainList($event)"
                                        *ngFor="let catlist of grplist?.repGroupCategoryResponse;">
                                        <a href="javascript:;"
                                            (click)="!isIframeWindow ? allBtnClick($event, catlist, 'category', null) : ''">{{catlist?.categoryName}}</a>
                                        <ul class="cat-dropdown sub-category-block overflow-y-auto sec-inner-menu"
                                            *ngIf="catlist?.repGroupSubCategories.length">
                                            <li *ngFor="let subcatlist of catlist?.repGroupSubCategories">
                                                <a href="javascript:;"
                                                    (click)="!isIframeWindow ?  allBtnClick($event, catlist, 'subcategory', subcatlist): ''">{{subcatlist?.defaultSubCategory?.name}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <!--Category over-->

                            </li>
                            <li class="has-dropdown">
                                <a href="javascript:;"
                                    (click)="!isIframeWindow ? allBtnClick($event, '', 'group', null) : ''">ALL
                                    CATEGORIES</a>
                            </li>
                            <!-- <li><a href="javascript:;">Interior Architectural</a></li>
                            <li><a href="javascript:;">Portable Lamps</a></li>
                            <li><a href="javascript:;">Exterior Lighting</a></li>
                            <li><a href="javascript:;">Light Sources/Bulbs</a></li>
                            <li><a href="javascript:;">Accessories/Parts</a></li>
                            <li><a href="javascript:;">Controls/Dimmers</a></li>
                            <li><a href="javascript:;">Mirrors/Home Decor</a></li> -->
                            <!-- <li class="view-all"><a href="javascript:;">View All Categories&nbsp;&nbsp;&nbsp;&nbsp;+</a>
                            </li> -->
                        </ul>
                    </div>

                    <div class="cat-menu-wpr">
                        <!-- With Login Shop By Brand Start-->
                        <a href="javascript:;" class="cat-menu-btn" [routerLink]="['/vendors']"
                            *ngIf="auth.getdefaultShopbyBrandsButtonFlag() && loggedInUser && !notApprovedByRepGroup">
                            <img src="/assets/images/lightovation/cat-menu.svg" alt="Category Menu">
                            <span class="cat-menu-text">Shop by Brand</span>
                        </a>
                        <a href="javascript:;" class="cat-menu-btn" [routerLink]="['/vendors']"
                            *ngIf="(!auth.getdefaultShopbyBrandsButtonFlag()) && loggedInUser && !notApprovedByRepGroup">
                            <!-- <img src="/assets/images/lightovation/cat-menu.svg" alt="Category Menu"> -->
                            <i class="{{auth.getCustomShopbyBrandsButtonData()['iconClass']}}"></i>
                            <span class="cat-menu-text"
                                [innerHtml]="auth.getCustomShopbyBrandsButtonData()['text']"></span>
                        </a>
                        <!-- With Login Shop By Brand End-->
                        <!-- WithOut Login Shop By Brand Start-->
                        <a href="javascript:;" class="cat-menu-btn" *ngIf="!loggedInUser && !isIframeWindow"
                            [routerLink]="['/vendors-list']">
                            <img *ngIf="auth.getdefaultShopbyBrandsButtonFlag()"
                                src="/assets/images/lightovation/cat-menu.svg" alt="Category Menu">
                            <i *ngIf="!auth.getdefaultShopbyBrandsButtonFlag() && auth.getCustomShopbyBrandsButtonData()['iconClass']"
                                class="{{auth.getCustomShopbyBrandsButtonData()['iconClass']}}"></i>
                            <span
                                *ngIf="!auth.getdefaultShopbyBrandsButtonFlag() && auth.getCustomShopbyBrandsButtonData()['text']"
                                class="cat-menu-text"
                                [innerHtml]="auth.getCustomShopbyBrandsButtonData()['text']"></span>
                            <span *ngIf="auth.getdefaultShopbyBrandsButtonFlag()" class="cat-menu-text">Shop by
                                Brand</span>
                        </a>
                        <a href="javascript:;" class="cat-menu-btn" *ngIf="!loggedInUser && isIframeWindow">
                            <img src="/assets/images/lightovation/cat-menu.svg" alt="Category Menu">
                            <span class="cat-menu-text">Shop by Brand</span>
                        </a>
                        <!-- WithOut Login Shop By Brand End-->
                        <!-- <ul class="cat-menu">
                            <li *ngFor="let brandList of auth.RepGroupBrandsList  | slice:0:10;">
                                <a href="javascript:;" [routerLink]="['/vendor']"
                                    [queryParams]="{mfg: brandList.recordID}">{{brandList.name}}</a>
                            </li>
                            <li class="view-all"><a href="javascript:;" [routerLink]="['/vendors']">View All
                                    Brands&nbsp;&nbsp;&nbsp;&nbsp;+</a>
                            </li>                           
                        </ul> -->
                    </div>
                </div>
                <div class="search-wpr" *ngIf="auth.checkthisEntityType() && getMenuShow()">
                    <span class="search-bar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.556" height="25.592"
                            viewBox="0 0 23.556 25.592">
                            <path id="search"
                                d="M22.962,23.318,17.4,17.759a10.714,10.714,0,1,1,1.5-1.143l5.38,5.373a.941.941,0,0,1,0,1.329.932.932,0,0,1-1.321,0ZM2.765,8.7A8.94,8.94,0,1,0,11.706-.241h0A8.941,8.941,0,0,0,2.765,8.7Z"
                                transform="translate(-1 2)" />
                        </svg>
                    </span>
                    <form id="lightovationSearchForm" class="search-form" [formGroup]="itemSearchForm"
                        (ngSubmit)="onSubmitSearch()">
                        <!-- <select name="" id="">
                            <option value="">All Categories</option>
                            <option value="">Option 1</option>
                            <option value="">Option 2</option>
                            <option value="">Option 3</option>
                            <option value="">Option 4</option>
                            <option value="">Option 5</option>
                        </select> -->
                        <input class="input-text usernam" type="text" pInputText
                            placeholder="Search for products or brands" formControlName="txtItemSearch"
                            [disabled]="isIframeWindow ? 'true' : 'false'" appCustomValidation
                            [allowStartWithSpace]="false" />
                        <button type="submit" class="search-btn">
                            <img src="/assets/images/lightovation/search-icon.svg" alt="Search">
                        </button>
                    </form>
                </div>
                <div class="user-info {{loggedInUser || isIframeWindow ? 'is-login':''}} ">
                    <!-- is-login class for user proile dropdown menu -->
                    <!-- not-login class for user proile dropdown menu -->
                    <div class="item-menu" *ngIf="loggedInUser || isIframeWindow">
                        <span class="item-menu-bar" (click)="userMenuClick();"
                            *ngIf="!notApprovedByRepGroup || isIframeWindow"></span>
                        <div class="menu-overlay" (click)="closeuserMenu();"></div>
                        <div class="item-menu-inner">
                            <a *ngIf="auth.getshowPromotionsButtonFlag() && auth.getdefaultPromotionButtonFlag() && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && auth.getdefaultPromotionButtonFlag())"
                                (click)="!isIframeWindow ? allBtnClick($event, null, null, null): ''">Promotions</a>
                            <a *ngIf="auth.getshowPromotionsButtonFlag() && (!auth.getdefaultPromotionButtonFlag()) && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && !auth.getdefaultPromotionButtonFlag())"
                                (click)="!isIframeWindow ? allBtnClick($event,auth.getCustomPromotionButtonData(), 'promotion', null) : ''"
                                [innerHtml]="auth.getCustomPromotionButtonData()['text']"></a>
                            <span
                                *ngIf="auth.getshowPromotionsButtonFlag() && (!notApprovedByRepGroup || isIframeWindow)">|</span>
                            <a *ngIf="auth.getdefaultCatalogButtonFlag() && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && auth.getdefaultCatalogButtonFlag())"
                                (click)="!isIframeWindow ? allBtnClick($event, null, null, null) : ''">Catalogs</a>
                            <a *ngIf="(!auth.getdefaultCatalogButtonFlag()) && loggedInUser && !notApprovedByRepGroup  || (isIframeWindow && !auth.getdefaultCatalogButtonFlag())"
                                [innerHtml]="auth.getCustomCatalogButtonData()['text']"
                                (click)="!isIframeWindow ? allBtnClick($event,auth.getCustomCatalogButtonData(), 'Catalog', null) : ''"></a>
                            <!-- <span>|</span> -->
                            <!-- <a *ngIf="auth.getdefaultShopbyItemButtonFlag() && loggedInUser && !notApprovedByRepGroup"
                                (click)="allBtnClick($event, null, null, null)">Shop by Items</a>
                            <a (click)="allBtnClick($event,auth.getCustomShopbyItemButtonData(), 'Shop by Items', null)"
                                [innerHtml]="auth.getCustomShopbyItemButtonData()['text']"
                                *ngIf="(!auth.getdefaultShopbyItemButtonFlag()) && loggedInUser && !notApprovedByRepGroup">Shop
                                by Items</a> -->
                        </div>
                    </div>
                    <!-- <div class="item-menu" *ngIf="!loggedInUser">
                        <span class="item-menu-bar"></span>
                        <div class="item-menu-inner">
                            <a href="javascript:;">View by Items</a>
                        </div>
                    </div> -->
                    <div class="user-menu-wpr" *ngIf="auth.checkthisEntityType()">
                        <div class="user-menu">
                            <span class="user-icon">
                                <!-- <img src="/assets/images/lightovation/user-icon.svg" alt="User"> -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.743" height="31.565"
                                    viewBox="0 0 27.743 31.565">
                                    <path id="user-icon"
                                        d="M13.872,0A8.323,8.323,0,1,0,22.2,8.323,8.322,8.322,0,0,0,13.872,0Zm0,14.8a6.473,6.473,0,1,1,6.476-6.476A6.474,6.474,0,0,1,13.872,14.8Zm2.578,3.757H11.292A13.37,13.37,0,0,0,0,24.75v6.815H27.743V24.75a13.37,13.37,0,0,0-11.292-6.193Zm9.443,11.16H1.85v-4.4a11.51,11.51,0,0,1,9.443-4.91h5.158a11.51,11.51,0,0,1,9.443,4.91Z" />
                                </svg>
                            </span>
                            <p class="user-text" *ngIf="loggedInUser || isIframeWindow">My <br>Account</p>
                            <div class="user-menu-text" *ngIf="!loggedInUser && !isIframeWindow">
                                <p class="dropdown-hide">Continue with us</p>
                                <p class="user-text" style="text-align: center;">
                                    <a *ngIf="auth.getdefaultLoginButtonFlag()"
                                        [ngClass]="{'disabled-action-control':isIframeWindow}"
                                        (click)="!isIframeWindow ? loginClick(): ''">Login </a>
                                    <span class="dropdown-hide"
                                        *ngIf="auth.getdefaultLoginButtonFlag() && (auth.getdefaultSignupButtonFlag() || auth.getCustomSignupButtonData().url)">or
                                    </span>
                                    <a target="_blank" *ngIf="!isIframeWindow && auth.getdefaultSignupButtonFlag()"
                                        [routerLink]="['/signup']">Signup</a>
                                    <a target="_blank"
                                        *ngIf="!isIframeWindow && !auth.getdefaultSignupButtonFlag() && auth.getCustomSignupButtonData().url"
                                        href="{{auth.getCustomSignupButtonData().url}}"> {{
                                        auth.getCustomSignupButtonData().text }} </a>
                                    <a target="_blank" class="disabled-action-control" *ngIf="isIframeWindow">Signup</a>
                                    <!-- <a routerLink="/" class="mobile-show">Become a Seller</a> -->
                                    <!-- <a routerLink="/buyer-signup" class="mobile-show">Signup as Buyer</a> -->
                                </p>
                            </div>
                        </div>
                        <div class="login-after-layer" (click)="closeLoginForm()"></div>
                        <div class="user-dropdown-menu1 user-login-form">
                            <h4 class="uset-title">Buyer Login</h4>
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="input-filed-wpr">
                                    <div class="input-filed">
                                        <img src="/assets/images/lightovation/input-user-icon.svg" alt="">
                                        <!-- <input type="text" id="username" name="username" class="input-text username"
                                            placeholder="Your Name"> -->
                                        <input class="input-text usernam" type="text" pInputText
                                            placeholder="Enter MarketTime Username" formControlName="username"
                                            appCustomValidation [allowStartWithSpace]="false"
                                            [ngClass]="{ 'ng-dirty ng-invalid': submitted && loginForm.get('username').errors?.required}" />
                                    </div>
                                    <div class="mt-2" *ngIf="submitted && loginForm.get('username').errors?.required">
                                        <p-message severity="error" text="Username is required" style="line-height: 1">
                                        </p-message>
                                    </div>
                                    <a class="forgot-filed" id="forgot-password-link"
                                        [routerLink]="['/forgot-username']">Forgot your username?</a>
                                </div>
                                <div class="input-filed-wpr">
                                    <div class="input-filed">
                                        <img src="/assets/images/lightovation/input-pswd-icon.svg" alt="">
                                        <!-- <input type="password" id="username" name="username" class="input-text password"
                                            placeholder="Enter Password"> -->
                                        <input class="w-100 input-text password" type="password" pInputText
                                            placeholder="Enter Password" autocomplete="new-password"
                                            formControlName="password"
                                            [ngClass]="{ 'ng-dirty ng-invalid': submitted && loginForm.get('password').errors?.required}" />
                                    </div>
                                    <div class="mt-2" *ngIf="submitted && loginForm.get('password').errors?.required">
                                        <p-message severity="error" text="Password is required" style="line-height: 1">
                                        </p-message>
                                    </div>
                                    <a class="forgot-filed" id="forgot-password-link"
                                        [routerLink]="['/forgot-password']">Forgot your password?</a>
                                </div>
                                <!-- <button type="submit" id="login-btn" class="custom-btn custom-login-btn">Login
                                    Now</button> -->
                                <p-button type="submit" [styleClass]="'ui-button-success theme-maroon-btn lt-login-btn'"
                                    label="Login
                                        Now" [disabled]="isLoading"
                                    [icon]="isLoading ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-fw fa-sign-in'">
                                </p-button>
                                <!-- <div class="new-user-sec">
                                    If you new user? <a [routerLink]="['/signup']">Register Now</a>
                                </div> -->
                            </form>
                        </div>
                        <ul class="user-dropdown-menu list-menu" *ngIf="loggedInUser && !notApprovedByRepGroup">
                            <li><a [routerLink]="['/profile']">Profile</a></li>
                            <li><a [routerLink]="['/my-orders']">View Orders</a></li>
                            <li><a [routerLink]="['/manage-company']">Manage Company</a></li>
                            <li><a *ngIf="auth.getEnableProfileEditing()" [routerLink]="['/change-password']">Change
                                    Password</a></li>
                            <li *ngIf="mtPayAvilableForRetailer"><a [routerLink]="['/manage-company']"
                                    (click)="openMtPayService()">mtServices</a></li>
                            <li><a (click)="logout()">Logout</a></li>
                        </ul>
                        <ul class="user-dropdown-menu list-menu" *ngIf="isIframeWindow">
                            <li><a href="javascript:;">Profile</a></li>
                            <li><a href="javascript:;">View Orders</a></li>
                            <li><a href="javascript:;">Manage Company</a></li>
                            <li><a href="javascript:;">Change Password</a></li>
                            <li><a href="javascript:;">Logout</a></li>
                        </ul>
                        <ul class="user-dropdown-menu list-menu" *ngIf="loggedInUser && notApprovedByRepGroup">
                            <li><a (click)="logout()">Logout</a></li>
                        </ul>
                    </div>
                    <!-- <a href="javascript:;" class="user-menu link-menu login">
                        <span class="user-icon">
                            <img src="/assets/images/lightovation/user-icon.svg" alt="User">
                        </span>
                        <div class="user-menu-text">My <br>Account</div>
                    </a> -->

                    <div class="currency-menu"
                        *ngIf="auth.getshowDiscountTickerButtonFlag() && loggedInUser && !notApprovedByRepGroup || (isIframeWindow)">
                        <span class="currency-icon">
                            <!-- <img src="/assets/images/lightovation/map-pin.png" style="width: 24px;"> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="35.4" height="35.4" viewBox="0 0 35.4 35.4">
                                <g id="dollar" transform="translate(0.25 0.25)">
                                    <path id="Path_2692" data-name="Path 2692"
                                        d="M206,143.785a4.871,4.871,0,0,0,4.866,4.866,2.919,2.919,0,1,1-2.919,2.919.973.973,0,0,0-1.946,0,4.874,4.874,0,0,0,3.893,4.768v2.044a.973.973,0,0,0,1.946,0v-2.044a4.866,4.866,0,0,0-.973-9.634,2.919,2.919,0,1,1,2.919-2.919.973.973,0,1,0,1.946,0,4.874,4.874,0,0,0-3.893-4.768v-2.044a.973.973,0,1,0-1.946,0v2.044A4.874,4.874,0,0,0,206,143.785Z"
                                        transform="translate(-193.416 -130.228)" />
                                    <path id="Path_2693" data-name="Path 2693"
                                        d="M17.45,0A17.766,17.766,0,0,0,0,17.45,17.766,17.766,0,0,0,17.45,34.9,17.766,17.766,0,0,0,34.9,17.45,17.766,17.766,0,0,0,17.45,0Zm0,33.537A16.508,16.508,0,0,1,1.363,17.45,16.508,16.508,0,0,1,17.45,1.363,16.508,16.508,0,0,1,33.537,17.45,16.508,16.508,0,0,1,17.45,33.537Z"
                                        stroke="#000" stroke-width="0.5" />
                                </g>
                            </svg>
                        </span>
                        <div class="currency-dropdown-menu">
                            <div class="saving-sec">
                                <div class="saving-info">
                                    <!-- <div class="saving-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25.075" viewBox="0 0 27 25.075">
                                            <g id="coins" transform="translate(0 -18.25)">
                                                <path id="Path_2694" data-name="Path 2694" d="M11.18,378.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,381.636,0,379.832V378.25Z" transform="translate(0 -341.016)" fill="#fea832"/>
                                                <path id="Path_2695" data-name="Path 2695" d="M111.59,378.25v1.582c0,1.8-2.426,3.164-5.59,3.164V378.25Z" transform="translate(-100.41 -341.016)" fill="#fe9923"/>
                                                <path id="Path_2696" data-name="Path 2696" d="M11.18,348.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,351.636,0,349.832V348.25Z" transform="translate(0 -312.598)" fill="#fea832"/>
                                                <path id="Path_2697" data-name="Path 2697" d="M111.59,348.25v1.582c0,1.8-2.426,3.164-5.59,3.164V348.25Z" transform="translate(-100.41 -312.598)" fill="#fe9923"/>
                                                <path id="Path_2698" data-name="Path 2698" d="M11.18,348.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,351.636,0,349.832V348.25Z" transform="translate(0 -312.598)" fill="#fedb41"/>
                                                <path id="Path_2699" data-name="Path 2699" d="M111.59,348.25v1.582c0,1.8-2.426,3.164-5.59,3.164V348.25Z" transform="translate(-100.41 -312.598)" fill="#fc3"/>
                                                <path id="Path_2700" data-name="Path 2700" d="M11.18,318.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,321.636,0,319.832V318.25Z" transform="translate(0 -284.18)" fill="#fea832"/>
                                                <path id="Path_2701" data-name="Path 2701" d="M111.59,318.25v1.582c0,1.8-2.426,3.164-5.59,3.164V318.25Z" transform="translate(-100.41 -284.18)" fill="#fe9923"/>
                                                <path id="Path_2702" data-name="Path 2702" d="M11.18,288.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,291.636,0,289.832V288.25Z" transform="translate(0 -255.762)" fill="#fedb41"/>
                                                <path id="Path_2703" data-name="Path 2703" d="M111.59,288.25v1.582c0,1.8-2.426,3.164-5.59,3.164V288.25Z" transform="translate(-100.41 -255.762)" fill="#fc3"/>
                                                <path id="Path_2704" data-name="Path 2704" d="M11.18,258.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,261.636,0,259.832V258.25Z" transform="translate(0 -227.344)" fill="#fea832"/>
                                                <path id="Path_2705" data-name="Path 2705" d="M111.59,258.25v1.582c0,1.8-2.426,3.164-5.59,3.164V258.25Z" transform="translate(-100.41 -227.344)" fill="#fe9923"/>
                                                <path id="Path_2706" data-name="Path 2706" d="M11.18,228.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,231.636,0,229.832V228.25Z" transform="translate(0 -198.926)" fill="#fedb41"/>
                                                <path id="Path_2707" data-name="Path 2707" d="M111.59,228.25v1.582c0,1.8-2.426,3.164-5.59,3.164V228.25Z" transform="translate(-100.41 -198.926)" fill="#fc3"/>
                                                <path id="Path_2708" data-name="Path 2708" d="M11.18,198.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,201.636,0,199.832V198.25Z" transform="translate(0 -170.508)" fill="#fea832"/>
                                                <path id="Path_2709" data-name="Path 2709" d="M111.59,198.25v1.582c0,1.8-2.426,3.164-5.59,3.164V198.25Z" transform="translate(-100.41 -170.508)" fill="#fe9923"/>
                                                <path id="Path_2710" data-name="Path 2710" d="M11.18,168.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,171.636,0,169.832V168.25Z" transform="translate(0 -142.09)" fill="#fedb41"/>
                                                <path id="Path_2711" data-name="Path 2711" d="M111.59,168.25v1.582c0,1.8-2.426,3.164-5.59,3.164V168.25Z" transform="translate(-100.41 -142.09)" fill="#fc3"/>
                                                <path id="Path_2712" data-name="Path 2712" d="M11.18,138.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,141.636,0,139.832V138.25Z" transform="translate(0 -113.672)" fill="#fea832"/>
                                                <path id="Path_2713" data-name="Path 2713" d="M111.59,138.25v1.582c0,1.8-2.426,3.164-5.59,3.164V138.25Z" transform="translate(-100.41 -113.672)" fill="#fe9923"/>
                                                <path id="Path_2714" data-name="Path 2714" d="M11.18,108.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,111.636,0,109.832V108.25Z" transform="translate(0 -85.254)" fill="#fedb41"/>
                                                <path id="Path_2715" data-name="Path 2715" d="M111.59,108.25v1.582c0,1.8-2.426,3.164-5.59,3.164V108.25Z" transform="translate(-100.41 -85.254)" fill="#fc3"/>
                                                <path id="Path_2716" data-name="Path 2716" d="M11.18,78.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,81.636,0,79.832V78.25Z" transform="translate(0 -56.836)" fill="#fea832"/>
                                                <path id="Path_2717" data-name="Path 2717" d="M111.59,78.25v1.582c0,1.8-2.426,3.164-5.59,3.164V78.25Z" transform="translate(-100.41 -56.836)" fill="#fe9923"/>
                                                <path id="Path_2718" data-name="Path 2718" d="M5.59,18.25C2.426,18.25,0,19.611,0,21.414s2.426,3.164,5.59,3.164,5.59-1.361,5.59-3.164S8.754,18.25,5.59,18.25Z" fill="#fedb41"/>
                                                <path id="Path_2719" data-name="Path 2719" d="M111.59,21.414c0,1.8-2.426,3.164-5.59,3.164V18.25C109.164,18.25,111.59,19.611,111.59,21.414Z" transform="translate(-100.41)" fill="#fc3"/>
                                                <path id="Path_2720" data-name="Path 2720" d="M223.074,378.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,381.636,212,379.832V378.25Z" transform="translate(-200.82 -341.016)" fill="#fea832"/>
                                                <path id="Path_2721" data-name="Path 2721" d="M322.537,378.25v1.582c0,1.8-2.373,3.164-5.537,3.164V378.25Z" transform="translate(-300.283 -341.016)" fill="#fe9923"/>
                                                <path id="Path_2722" data-name="Path 2722" d="M223.074,348.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,351.636,212,349.832V348.25Z" transform="translate(-200.82 -312.598)" fill="#fedb41"/>
                                                <path id="Path_2723" data-name="Path 2723" d="M322.537,348.25v1.582c0,1.8-2.373,3.164-5.537,3.164V348.25Z" transform="translate(-300.283 -312.598)" fill="#fc3"/>
                                                <path id="Path_2724" data-name="Path 2724" d="M223.074,318.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,321.636,212,319.832V318.25Z" transform="translate(-200.82 -284.18)" fill="#fea832"/>
                                                <path id="Path_2725" data-name="Path 2725" d="M322.537,318.25v1.582c0,1.8-2.373,3.164-5.537,3.164V318.25Z" transform="translate(-300.283 -284.18)" fill="#fe9923"/>
                                                <path id="Path_2726" data-name="Path 2726" d="M223.074,288.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,291.636,212,289.832V288.25Z" transform="translate(-200.82 -255.762)" fill="#fedb41"/>
                                                <path id="Path_2727" data-name="Path 2727" d="M322.537,288.25v1.582c0,1.8-2.373,3.164-5.537,3.164V288.25Z" transform="translate(-300.283 -255.762)" fill="#fc3"/>
                                                <path id="Path_2728" data-name="Path 2728" d="M223.074,258.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,261.636,212,259.832V258.25Z" transform="translate(-200.82 -227.344)" fill="#fea832"/>
                                                <path id="Path_2729" data-name="Path 2729" d="M322.537,258.25v1.582c0,1.8-2.373,3.164-5.537,3.164V258.25Z" transform="translate(-300.283 -227.344)" fill="#fe9923"/>
                                                <path id="Path_2730" data-name="Path 2730" d="M223.074,228.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,231.636,212,229.832V228.25Z" transform="translate(-200.82 -198.926)" fill="#fedb41"/>
                                                <path id="Path_2731" data-name="Path 2731" d="M322.537,228.25v1.582c0,1.8-2.373,3.164-5.537,3.164V228.25Z" transform="translate(-300.283 -198.926)" fill="#fc3"/>
                                                <path id="Path_2732" data-name="Path 2732" d="M223.074,198.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,201.636,212,199.832V198.25Z" transform="translate(-200.82 -170.508)" fill="#fea832"/>
                                                <path id="Path_2733" data-name="Path 2733" d="M322.537,198.25v1.582c0,1.8-2.373,3.164-5.537,3.164V198.25Z" transform="translate(-300.283 -170.508)" fill="#fe9923"/>
                                                <path id="Path_2734" data-name="Path 2734" d="M223.074,168.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,171.636,212,169.832V168.25Z" transform="translate(-200.82 -142.09)" fill="#fedb41"/>
                                                <path id="Path_2735" data-name="Path 2735" d="M322.537,168.25v1.582c0,1.8-2.373,3.164-5.537,3.164V168.25Z" transform="translate(-300.283 -142.09)" fill="#fc3"/>
                                                <path id="Path_2736" data-name="Path 2736" d="M223.074,138.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,141.636,212,139.832V138.25Z" transform="translate(-200.82 -113.672)" fill="#fea832"/>
                                                <path id="Path_2737" data-name="Path 2737" d="M322.537,138.25v1.582c0,1.8-2.373,3.164-5.537,3.164V138.25Z" transform="translate(-300.283 -113.672)" fill="#fe9923"/>
                                                <path id="Path_2738" data-name="Path 2738" d="M217.537,78.25c-3.164,0-5.537,1.361-5.537,3.164s2.373,3.164,5.537,3.164,5.537-1.361,5.537-3.164S220.7,78.25,217.537,78.25Z" transform="translate(-200.82 -56.836)" fill="#fedb41"/>
                                                <path id="Path_2739" data-name="Path 2739" d="M322.537,81.414c0,1.8-2.373,3.164-5.537,3.164V78.25C320.164,78.25,322.537,79.611,322.537,81.414Z" transform="translate(-300.283 -56.836)" fill="#fc3"/>
                                                <path id="Path_2740" data-name="Path 2740" d="M278.328,253.75a6.328,6.328,0,1,0,6.328,6.328A6.326,6.326,0,0,0,278.328,253.75Z" transform="translate(-257.656 -223.081)" fill="#fea832"/>
                                                <path id="Path_2741" data-name="Path 2741" d="M398.328,260.078A6.326,6.326,0,0,1,392,266.406V253.75A6.326,6.326,0,0,1,398.328,260.078Z" transform="translate(-371.328 -223.081)" fill="#fe9923"/>
                                                <path id="Path_2742" data-name="Path 2742" d="M306.746,283.75a4.746,4.746,0,1,0,4.746,4.746A4.76,4.76,0,0,0,306.746,283.75Z" transform="translate(-286.074 -251.499)" fill="#fedb41"/>
                                                <path id="Path_2743" data-name="Path 2743" d="M396.746,288.5A4.76,4.76,0,0,1,392,293.242V283.75A4.76,4.76,0,0,1,396.746,288.5Z" transform="translate(-371.328 -251.499)" fill="#fc3"/>
                                            </g>
                                        </svg>
                                    </div> -->
                                </div>
                                <div class="currency-value">You’ve saved {{auth.getTickerText()}} % shopping with
                                    {{domainData.repGroup.name}}</div>
                            </div>
                            <ul class="timeline-list" *ngIf="buyerQickReport">
                                <li>
                                    <span>Year to Date</span>
                                    <span class="currency-value">{{buyerQickReport?.orderSummary?.yearToDate |
                                        rtCurrency}} (Saved {{(yourYTDSaving ? yourYTDSaving : '0') |
                                        rtCurrency}})</span>
                                </li>
                                <li>
                                    <span>Last Year</span>
                                    <span class="currency-value">{{buyerQickReport?.orderSummary?.lastYearTotal |
                                        rtCurrency}} (Saved {{(yourLYSaving ? yourLYSaving : '0' ) |
                                        rtCurrency}})</span>
                                </li>
                                <li>
                                    <span>Lifetime</span>
                                    <span class="currency-value">{{buyerQickReport?.orderSummary?.lifetimeTotal |
                                        rtCurrency}} (Saved {{(yourLTTaving ? yourLTTaving : '0' ) |
                                        rtCurrency}})</span>
                                </li>
                            </ul>
                            <ul class="timeline-list" *ngIf="!buyerQickReport">
                                <li>
                                    <span>Year to Date</span>
                                    <span class="currency-value">-</span>
                                </li>
                                <li>
                                    <span>Last Year</span>
                                    <span class="currency-value">-</span>
                                </li>
                                <li>
                                    <span>Lifetime</span>
                                    <span class="currency-value">-</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <a class="whishlist-menu" *ngIf="loggedInUser && !notApprovedByRepGroup || isIframeWindow"
                        [pTooltip]="isIframeWindow ? 'Sample Buyer - 1234 Everywhere St. - Austin, TX 78705' : (retailerSelectedAddress && retailerSelectedAddress.name !== undefined) ?
                    retailerSelectedAddress.name + ' - ' + retailerSelectedAddress.address1 + ', ' +
                    retailerSelectedAddress.city + ', ' + retailerSelectedAddress.state + ', ' +
                    retailerSelectedAddress.country + ', ' + retailerSelectedAddress.zip : ''"
                        tooltipPosition="bottom">
                        <span class="whishlist-icon">
                            <!-- <img src="/assets/images/lightovation/map-pin.png" style="width: 24px;"> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.983" height="34.109"
                                viewBox="0 0 23.983 34.109">
                                <g id="Group_467" data-name="Group 467" transform="translate(-76)">
                                    <path id="Path_804" data-name="Path 804"
                                        d="M87.992,0a11.994,11.994,0,0,0-10.2,18.3l9.519,15.34a1,1,0,0,0,.849.472h.008a1,1,0,0,0,.849-.486l9.276-15.488A11.994,11.994,0,0,0,87.992,0Zm8.585,17.109L88.143,31.19,79.489,17.244a10,10,0,1,1,17.088-.135Z" />
                                    <path id="Path_805" data-name="Path 805"
                                        d="M172,90a6,6,0,1,0,6,6A6,6,0,0,0,172,90Zm0,10.006A4.011,4.011,0,1,1,176,96,4.014,4.014,0,0,1,172,100.006Z"
                                        transform="translate(-84.004 -84.004)" />
                                </g>
                            </svg>
                        </span>
                    </a>
                    <a class="whishlist-menu" *ngIf="loggedInUser && !notApprovedByRepGroup || isIframeWindow"
                        (click)="!isIframeWindow ? likeListDialog() : ''">
                        <span class="whishlist-icon">
                            <!-- <img src="/assets/images/lightovation/whishlist-icon.svg" alt=""> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="29.154" viewBox="0 0 33 29.154">
                                <g id="whishlist-icon" transform="translate(0 -29.836)">
                                    <path id="Path_806" data-name="Path 806"
                                        d="M30.592,32.7a8.312,8.312,0,0,0-13.271.955,12.653,12.653,0,0,0-.821,1.332,12.642,12.642,0,0,0-.821-1.332A8.312,8.312,0,0,0,2.408,32.7,10,10,0,0,0,0,39.344c0,2.746,1.072,5.3,3.374,8.037a75.11,75.11,0,0,0,8.444,7.889c1.277,1.088,2.6,2.214,4,3.444l.042.037a.967.967,0,0,0,1.274,0l.042-.037c1.406-1.23,2.726-2.355,4-3.444a75.1,75.1,0,0,0,8.443-7.889C31.928,44.644,33,42.09,33,39.344A10,10,0,0,0,30.592,32.7ZM19.928,53.8c-1.1.938-2.234,1.9-3.428,2.942-1.195-1.038-2.328-2-3.429-2.942C6.364,48.083,1.934,44.307,1.934,39.344a8.073,8.073,0,0,1,1.925-5.365A6.454,6.454,0,0,1,8.766,31.77a6.518,6.518,0,0,1,5.335,3,11.462,11.462,0,0,1,1.48,2.943.967.967,0,0,0,1.838,0,11.463,11.463,0,0,1,1.48-2.943,6.381,6.381,0,0,1,10.242-.794,8.073,8.073,0,0,1,1.925,5.365C31.066,44.307,26.636,48.083,19.928,53.8Z" />
                                </g>
                            </svg>
                        </span>
                        <span class="whishlist-count">{{wishListItemCount}}</span>
                    </a>
                    <a class="cart-menu" *ngIf="loggedInUser && !notApprovedByRepGroup || isIframeWindow"
                        (click)="!isIframeWindow ? ((auth.getCartStyle() === 3 || auth.getCartStyle() === 4)? router.navigate(['/cart']):openCartBar()) : ''">
                        <span class="cart-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="33" viewBox="0 0 26 35">
                                <g id="bag_1_" data-name="bag (1)" transform="translate(-55.85 0.25)">
                                    <g id="Group_9" data-name="Group 9" transform="translate(56.1)">
                                        <g id="Group_8" data-name="Group 8">
                                            <path id="Path_35" class="blue-bg" data-name="Path 35"
                                                d="M79.522,9.983H74.951V6.535a6.559,6.559,0,0,0-13.117,0V9.983H56.885a.771.771,0,0,0-.785.754V29.085A3.922,3.922,0,0,0,60.027,33H76.365a3.922,3.922,0,0,0,3.927-3.919V10.753A.771.771,0,0,0,79.522,9.983ZM63.4,6.535a4.988,4.988,0,0,1,9.975,0V9.983H63.4ZM78.721,29.077a2.352,2.352,0,0,1-2.356,2.348H60.027a2.35,2.35,0,0,1-2.356-2.341V11.554h4.163v4.563a.785.785,0,1,0,1.571,0V11.554H73.38v4.563a.785.785,0,1,0,1.571,0V11.554h3.77Z"
                                                transform="translate(-56.1)" stroke="#000" stroke-width="0.5" />
                                        </g>
                                    </g>
                                    <g id="Group_11" data-name="Group 11" transform="translate(60.97 20.351)">
                                        <g id="Group_10" data-name="Group 10">
                                            <path id="Path_36" class="orange-bg" data-name="Path 36"
                                                d="M124.7,259.1h-5.812a.785.785,0,1,0,0,1.571H124.7a.785.785,0,1,0,0-1.571Z"
                                                transform="translate(-118.1 -259.1)" stroke="#000" stroke-width="0.5" />
                                        </g>
                                    </g>
                                    <g id="Group_13" data-name="Group 13" transform="translate(60.97 24.278)">
                                        <g id="Group_12" data-name="Group 12">
                                            <path id="Path_37" class="orange-bg" data-name="Path 37"
                                                d="M131.767,309.1H118.885a.785.785,0,0,0,0,1.571h12.881a.785.785,0,0,0,0-1.571Z"
                                                transform="translate(-118.1 -309.1)" stroke="#000" stroke-width="0.5" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <span class="cart-count">{{cartItemCount}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>