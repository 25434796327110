<div class="btb-common">
    <div class="b2baccess">
        <div id="contact_form" class="contact-form-sec popup res-comp-form">
            <h4 class="popup-title">Let us know about you for better services</h4>
            <a class="close-popup"><img src="/assets/images/close.png" alt="" (click)="closeUserInfo()"></a>
            <form (ngSubmit)="onSubmit()" [formGroup]="companyInfoForm" class="contact-form">
                <div class="input-row">
                    <div class="input-group">
                        <label for="re-companyName">Company name</label>
                        <input id="re-companyName" type="text" class="control" placeholder="Ex. Company name" pInputText
                            formControlName="companyName">
                        <i *ngIf="validateChk.companyName.invalid && (validateChk.companyName.dirty || validateChk.companyName.touched)"
                            class="error-icon pi pi-info-circle"
                            [pTooltip]="getErrorMsg(validateChk.companyName,'Company Name')" tooltipPosition="left"
                            tooltipEvent="hover"></i>
                    </div>
                    <div class="input-group">
                        <label for="re-name">Your name</label>
                        <input id="re-name" type="text" class="control" placeholder="Ex. Mark Jecno" pInputText
                            formControlName="name">
                        <i *ngIf="validateChk.name.invalid && (validateChk.name.dirty || validateChk.name.touched)"
                            class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.name,'Name')"
                            tooltipPosition="left" tooltipEvent="hover"></i>
                    </div>
                </div>
                <div class="input-row">
                    <div class="input-group">
                        <label for="re-address">Address</label>
                        <input id="re-address" type="text" class="control" placeholder="Ex. Abc mall near school" pInputText
                            formControlName="address">
                        <i *ngIf="validateChk.address.invalid && (validateChk.address.dirty || validateChk.address.touched)"
                            class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.address,'Address')"
                            tooltipPosition="left" tooltipEvent="hover"></i>
                    </div>
                </div>
                <div class="input-row res-country-row">
                    <div class="input-group">
                        <label for="state">Country</label>
                        <p-dropdown id="re-country" class="control-dropdown" [options]="countries" [filter]="true"
                            [style]="{'width':'100%','height':'50px'}" appendTo="body" [formControl]="f['country']"
                            autocomplete="country-name" (onChange)="countrySpecific()"></p-dropdown>
                    </div>
                    <div class="input-group">
                        <!-- <div *ngIf="f['country'].value !== 'US';then content else other_content"></div> -->
                        <div *ngIf="countriesStateJSON && countriesStateJSON[f['country'].value] && countriesStateJSON[f['country'].value].length;then other_content else content"></div>
                        <ng-template #content>
                            <label for="re-state">State</label>
                            <input id="re-state" class="control" maxlength="50" type="text" name="state"
                                placeholder="Ex. State" pInputText formControlName="state" autocomplete="address-level1">
                            <i *ngIf="validateChk.state.invalid && (validateChk.state.dirty || validateChk.state.touched)"
                                class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.state,'State')"
                                tooltipPosition="left" tooltipEvent="hover"></i>
                        </ng-template>
                        <ng-template #other_content>
                            <label for="re-state1">State</label>
                            <p-dropdown id="re-state1" class="control-dropdown" [options]="countriesStateJSON[f['country'].value]" [filter]="true"
                                [style]="{'width':'100%','height':'50px'}" appendTo="body" [formControl]="f['state']"
                                autocomplete="address-level1"></p-dropdown>
                        </ng-template>
                    </div>
                    <div class="input-group">
                        <label for="re-city">City</label>
                        <input id="re-city" type="text" class="control" class="control" maxlength="50"
                            placeholder="Ex. Texas" pInputText formControlName="city">
                        <i *ngIf="validateChk.city.invalid && (validateChk.city.dirty || validateChk.city.touched)"
                            class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.city,'City')"
                            tooltipPosition="left" tooltipEvent="hover"></i>
                    </div>
                    <div class="input-group">
                        <label for="re-zip">Zip code</label>
                        <input id="re-zip" class="control" [ngClass]="f['country'].value !== 'US' ? 'optional' : ''"
                            [style]="{'width':'100%'}" maxlength="20" type="text" pInputText formControlName="zip"
                            autocomplete="postal-code" (blur)="updateChange()">
                        <i *ngIf="validateChk.zip.invalid && (validateChk.zip.dirty || validateChk.zip.touched)"
                            class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.zip,'Zip')"
                            tooltipPosition="left" tooltipEvent="hover"></i>
                    </div>
                </div>
                <div class="input-row">
                    <div class="input-group">
                        <label for="website">Website</label>
                        <input id="website" class="" type="text" formControlName="website"
                            placeholder="Ex. https://www.company.com">
                    </div>
                </div>
                <div class="input-row">
                    <div class="input-group">
                        <label for="re-email">Email</label>
                        <input id="re-email" class="control" maxlength="80" type="email" name="company-email" pInputText
                            formControlName="email" autocomplete="work email">
                        <i *ngIf="validateChk.email.invalid && (validateChk.email.dirty || validateChk.email.touched)"
                            class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.email,'Email')"
                            tooltipPosition="left" tooltipEvent="hover"></i>
                    </div>
                    <div class="input-group">
                        <label for="number">Phone number</label>
                        <div class="input-grp">
                            <p-inputMask  formControlName="phone" styleClass="control" minlength="10" maxlength="12"  placeholder="000-000-0000" id="re-phone" mask="999-999-9999?99" autocomplete="off" type="tel"></p-inputMask>
                            <i *ngIf="validateChk.phone.invalid && (validateChk.phone.dirty || validateChk.phone.touched)"
                                class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.phone,'Phone')"
                                tooltipPosition="left" tooltipEvent="hover"></i>
                        </div>
                    </div>
                </div>
                <div class="q-a-wpr">
                    <div class="q-a">
                        <div class="input-group">
                            <p class="dark-label">1. What are you?</p>
                            <div class="radio-group">
                                <div class="radio-wpr">
                                    <input type="radio"  formControlName="who" name="who" value="Brand" id="c-brand" [(ngModel)]="who" [checked]="true">
                                    <label for="c-brand">Brand</label>
                                </div>
                                <div class="radio-wpr">
                                    <input type="radio"  formControlName="who" name="who" value="Agency" id="c-agency">
                                    <label for="c-agency">Agency</label>
                                </div>
                            </div>
                        </div>
                        <div class="input-group">
                            <p class="dark-label">2. Are you currently using MarketTime?</p>
                            <div class="radio-group">
                                <div class="radio-wpr">
                                    <input type="radio"  formControlName="usewebsite" name="usewebsite" value="Yes" id="c-web" [(ngModel)]="usewebsite" [checked]="true">
                                    <label for="c-web">Yes</label>
                                </div>
                                <div class="radio-wpr">
                                    <input type="radio"  formControlName="usewebsite" name="usewebsite" value="No" id="c-webno">
                                    <label for="c-webno">No</label>
                                </div>
                            </div>
                        </div>
                        <div class="input-group">
                            <p class="dark-label">3. Are you a multiline company?</p>
                            <div class="radio-group">
                                <div class="radio-wpr">
                                    <input type="radio"   formControlName="multilineCompany" value="Yes" name="multilineCompany" id="m-yes" [(ngModel)]="multilineCompany" [checked]="true">
                                    <label for="m-yes">Yes</label>
                                </div>
                                <div class="radio-wpr">
                                    <input type="radio"  formControlName="multilineCompany" value="No" name="multilineCompany" id="m-no">
                                    <label for="m-no">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="form-errors"></div>
                    <button [disabled]="saveLoading"  class="custom-btn submit-btn">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>