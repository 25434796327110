import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from '../../middleware/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserpoolService {

    baseUrl = environment.apiUrl;
    signupUrl = this.baseUrl + 'reptime/b2baccess/userpool/signup';
    requestAccessURL = this.baseUrl + 'reptime/b2baccess/repgroup/retailer/{retailerID}/save';
    checkEmailUrl = this.baseUrl + 'reptime/userpool/checkIfEmailRegistered'; // RC-568 (MG 29-JAN-2020): changed method to post
    checkUsernameUrl = this.baseUrl + 'reptime/userpool/checkUsername/{username}';
    forgotPasswordUrl = this.baseUrl + 'reptime/user/{username}/forgotPassword';
    confirmPasswordUrl = this.baseUrl + 'reptime/user/confirmPassword';
    getUserInfoUrl = this.baseUrl + 'reptime/api/user/{username}';
    getUserBuyer1Url = this.baseUrl + 'reptime/api/user';
    changePasswordUrl = this.baseUrl + 'reptime/user/{username}/changePassword';
    forgotUsername = this.baseUrl + 'reptime/user/forgotUsername';
    retailerDmcUnityURL = this.baseUrl + 'reptime/retailer/DMC/unity';

    constructor(private httpClient: HttpClient, private authService: AuthService) {
    }

    addTokens(url: string, params) {
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }

    signup(attr: any) {
        return this.httpClient.post(this.signupUrl, attr, { responseType: 'text' as 'text' }).pipe(
            map(data => {
                return data;
            }));
    }

    requestAccess(retailerID: string) {
        return this.httpClient.get(this.addTokens(this.requestAccessURL, { retailerID }));
    }

    checkUsernameAvailable(username) {
        return this.httpClient.get(this.addTokens(this.checkUsernameUrl, { username }));
    }

    // RC-568 (MG 29-JAN-2020): changed method to post
    checkEmailAvailable(email) {
        // return this.httpClient.get(this.addTokens(this.checkEmailUrl, {email}));
        return this.httpClient.post(this.checkEmailUrl, { email });
    }

    // B2B-135 (RK 05-MARCH-2020): changed method to post
    forgotPassword(username: string) {
        const httpOptions = { observe: 'response' as 'response' };
        return this.httpClient.get(this.addTokens(this.forgotPasswordUrl, { username }));
        // return this.httpClient.get(this.urlTools.addTokens(this.forgotPasswordUrl, {username: username}), httpOptions).pipe(map(res => {
        //     return res.status;
        // }));
    }

    resetPassword(params: string) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            observe: 'response' as 'response'
        };
        return this.httpClient.post(this.confirmPasswordUrl, params, httpOptions).pipe(map(res => {
            return res.status;
        }));
    }

    // [B2B-262] (SG 18-JUN-2020) Get Userinfo & call change password api
    getUserInfo(username) {

        return this.httpClient.get(this.addTokens(this.getUserInfoUrl, { username: username }));
    }

    getUserBuyer1Info(getUsername, companyId) {
        let url = this.getUserBuyer1Url + '/' + getUsername;
        if (companyId !== null && companyId !== undefined) {
            const params: any = new URLSearchParams();
            params.set('companyID', companyId);
            url = url + '?' + params.toString();
        }
        return this.httpClient.get(this.addTokens(url, { companyID: companyId })).toPromise();

    }

    changePassword(oldPassword, newPassword) {
        const getUsername = sessionStorage.getItem('username');
        const accessToken = this.authService.getAccessToken();  // remove quotes
        const requestBody = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            accessToken: accessToken
        };
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            observe: 'response' as 'response'
        };
        const populatedUrl = this.addTokens(this.changePasswordUrl, { username: getUsername });
        return this.httpClient.post(populatedUrl, requestBody, httpOptions).pipe(map(res => {
            return res;
        }));
    }
    // End

     // RC-1241 - forgot Username 
     forgotUsernameURL(email) {
        return this.httpClient.post(this.forgotUsername, email);
    }

    retailerDmcUnity(retailer) {
        let params;
            params = new HttpParams()
                .append('retailerID', retailer);
        return this.httpClient.post(this.retailerDmcUnityURL, '', {params});
    }
}
