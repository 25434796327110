<p-table [value]="iscArr" [columns]="cols">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [style.width]="col.width">
                {{col.header}}
            </th>
            <th style="width: 120px;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
        <tr>
            <td *ngFor="let col of columns;">
                <div [ngSwitch]="col.field">
                    <div *ngSwitchCase="'quantity'">
                        <ng-container *ngIf="!itemObj.b2bItemPricingPolicyFlag">
                            <input pInputText  pKeyFilter="pint" type="number" onClick="this.select();" [(ngModel)]="rowData[col.field]"
                            (mousedown)="onclickSaveOldQty(rowData)"
                            (blur)="commonQtyManageFunVariant(rowData, i)" min="0"/>
                        </ng-container>
                    </div>
                    <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                </div>
            </td>
            <td>
                <ng-container *ngIf="!itemObj.b2bItemPricingPolicyFlag">
                    <button class="ui-button-info ui-button" icon="fa fa-plus" pButton title="Add to basket"
                            [disabled]="(addDisbaleObj | json) != '{}' && addDisbaleObj[rowData.recordID +''+ rowData.itemID]"
                            (click)="incrQtyVariantEvent(rowData, i)"></button>
                </ng-container>
                <button (click)="openHistoryItem(rowData, $event)" class="ui-button-info ui-button mx-5" icon="fa fa-history" pButton title="View History"></button>
            </td>
        </tr>
    </ng-template>
</p-table>
<!-- [tabindex]="i + 1"  -->