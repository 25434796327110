import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarApiService {
  private mfgEvent = new Subject<any>();
  private categoriesEvent = new Subject<any>();
  public mfgData = [];
  public categoriesData = [];
  constructor() { }

  setMfgEvent(data: any) {
    this.mfgEvent.next(data);
    this.mfgData = data;
  }

  getMfgEvent(): Observable<any> {
    return this.mfgEvent.asObservable();
  }

  setMenuEvent(data: any) {
    this.categoriesEvent.next(data);
    this.categoriesData = data;
  }

  getMenuEvent(): Observable<any> {
    return this.categoriesEvent.asObservable();
  }
}
