import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpResponse,
    HttpClient
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../middleware/auth/auth.service';
import { RTSharedService } from '../../rtshared/rtshared.service';
import * as _ from 'lodash';


@Injectable()
export class InterceptorService implements HttpInterceptor {
    index = 0;
    oldsessiondata = this.getsessionlog();

    baseUrl = environment.apiUrl;
    constructor(private router: Router,
        private _auth: AuthService,
        private http: HttpClient,
        private _rtSharedService: RTSharedService) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('intercept request >>', request);
        if (this._auth.getIDToken() && !request.headers.has('Authorization')) {
            if (request.url.startsWith(environment.apiUrl)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this._auth.getIDToken()}`
                    }
                });
            }
        }
        const restime = new Date().getTime();
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.apilogs(restime, request, event);
                }
            }, (error: any) => {
                this.apilogs(restime, request, error);
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 401:
                            console.error('InterceptorService > 401 =>', error.error);
                            this._auth.removeAllToken();
                            this._auth.moveToAfterLogin();
                            break;
                        case 403:
                            console.error('InterceptorService -> 403 =>', error.error);
                            this._auth.removeAllToken();
                            this._auth.moveToAfterLogin();
                            break;
                        case 500:
                            const errormsg = (<HttpErrorResponse>error).error;
                            if (errormsg.message) {
                                if (errormsg.message.includes("org.hibernate.exception.LockAcquisitionException")) {
                                    this.checkout500();
                                }
                            } else if (errormsg.error.message) {
                                if (errormsg.error.message.includes("org.hibernate.exception.LockAcquisitionException")) {
                                    this.checkout500();
                                }
                            }
                            break;
                        default:
                        // console.error('InterceptorService => ', error.status);
                    }
                }
            })
        );
    }

    apilogs(restime, header, response) {
        const token = sessionStorage.getItem('idToken');
        if (token != null) {
            let _apiRes = _.cloneDeep(response.body);
            if (_apiRes && _.isArray(_apiRes.data)) {
                _apiRes.dataLength = _apiRes.data.length;
                _apiRes.data = [];
            } else {
                _apiRes = null;
            }
            if (!_.includes(header.url, 'saveCallLogs')) {
                this.index++;
                const apirequest = {
                    url: header.url,
                    urlWithParams: header.urlWithParams,
                    method: header.method,
                    headerBody: header.body ? JSON.stringify(header.body) : null,
                    status: response.status,
                    responseSuccess: _apiRes ? JSON.stringify(_apiRes) : null,
                    responseFail: response.error ? JSON.stringify(response.error) : null,
                    startTimestamp: restime,
                    endTimestamp: new Date().getTime(),
                    index: this.index,
                    browser: this.getBrowser(),
                    browserURL: this.router.url,
                    origin: 'b2b'
                };
                try {
                    let session = JSON.parse(sessionStorage.getItem('logindetail'));
                    session = session ? session : null;
                    apirequest['userDetail'] = {
                        entityID: session && session.entityId ? session.entityId : '',
                        role: session && session.role ? session.role : '',
                        userID: session && session.userId ? session.userId : '',
                        entityType: session && session.entityType ? session.entityType : '',
                    };
                } catch (err) {
                }
                this.oldsessiondata = this.getsessionlog();
                this.oldsessiondata.push(apirequest);
                sessionStorage.setItem('log', JSON.stringify(this.oldsessiondata));
            }
        }
    }
    getsessionlog() {
        try {
            JSON.parse(sessionStorage.getItem('log'));
        } catch (e) {
            return [];
        }
        const d = JSON.parse(sessionStorage.getItem('log'));
        return d ? d : [];
    }
    getBrowser() {
        let tem, M;
        const ua = navigator.userAgent;
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }
        return M.join(' ');
    }
    checkout500() {
        this.http.get<any>('' + this.baseUrl + 'reptime/api/getTransactionProcess').subscribe(data => {
        });
    }
}
