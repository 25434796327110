<p-toast key="addItem" position="top-right"></p-toast>
<div class="ui-g-3 item-cart-list sticky" [ngStyle]="{'top': stickyTop?stickyTop.offsetHeight+'px' : headerHeight}">
    <p-card class="card">
        <p-header class="card-header d-block">
            <button class="icon-theme-color mr-5 theme-grayclr close_cartbar-btn"
                (click)="closeSidebar.emit(true)" icon="fa fa-fw fa-times" pButton
                style="background: transparent; border: none; font-size: 20px;"></button>
            <h2 class="m-0" *ngIf="!pageType">
                <i class="fa fa-shopping-basket "></i> Cart Details
                <i *ngIf="basketSyncLoader" class="fa fa-spinner fa-spin" style="float: right;"></i>
            </h2>
            <br />
            <div class="all-checkout d-flex align-items-center justify-content-between" *ngIf="!pageType">
                <div>Click "Checkout Now" to finalize your order(s)</div>
            </div>
            <h2 class="m-0" *ngIf="pageType">
                <i class="fa fa-user-plus"></i> Signup to place an order
            </h2>
        </p-header>
        <div class="cart-content-box" [ngClass]="pageType?'center-section':''">
            <div *ngIf="pageType" class="ml-15">
                <a pButton type="button" label="Sign Up or Login for Pricing" icon="fa fa-user-plus"
                    class="btn-theme-color disabled-action-control" *ngIf="isIframeWindow"></a>
                <!-- [RC-1965] login/signup button hide/show start -->
                <a pButton type="button" label="Sign Up or Login for Pricing" icon="fa fa-user-plus"
                    class="btn-theme-color" *ngIf="!isIframeWindow && auth.getdefaultSignupButtonFlag()" [routerLink]="['/signup']"></a>
                <a pButton type="button" label="Sign Up or Login for Pricing" icon="fa fa-user-plus" href="{{auth.getCustomSignupButtonData().url}}" target="_blank"
                    class="btn-theme-color" *ngIf="!isIframeWindow && !auth.getdefaultSignupButtonFlag() && auth.getCustomSignupButtonData().url"></a>
                <!-- [RC-1965] login/signup button hide/show end -->
            </div>
            <p-scrollPanel styleClass="{{basketIscStatus ? 'adjustScrollpanel' : 'defaultScrollpanel'}}">
                <!-- B2B-102 (NL 25-FEB-2020) add shimmer effect HTML    -->
                <!-- shimmer effect -->
                <div class="d-none">
                    <div class="cart-item-list-main">
                        <div class="cart-item-img">
                            <div class="shimmer-effect" style="width: 60px; height: 60px;"></div>
                        </div>
                        <div class="cart-item-detail">
                            <div class="shimmer-effect p-5 Wid70 mb-5"></div>
                            <div class="cart-item-num-block mb-10">
                                <div class="shimmer-effect p-5 Wid30"></div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid40"></div>
                            </div>
                            <div class="cart-item-num-block mb-5">
                                <div class="cart-item-qty-block">
                                    <div class="cart-item-inc-block">
                                        <div class="cart-item-inc-inner d-flex align-items-center">
                                            <div class="shimmer-effect p-6 mr-5"></div>
                                            <div class="shimmer-effect p-10 mr-5" style="width: 30px;"></div>
                                            <div class="shimmer-effect p-6"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid20"></div>
                            </div>
                            <div class="cart-item-delete p-5 shimmer-effect" style="position: absolute;"></div>
                        </div>
                    </div>
                    <div class="cart-item-list-main">
                        <div class="cart-item-img">
                            <div class="shimmer-effect" style="width: 60px; height: 60px;"></div>
                        </div>
                        <div class="cart-item-detail">
                            <div class="shimmer-effect p-5 Wid70 mb-5"></div>
                            <div class="cart-item-num-block mb-10">
                                <div class="shimmer-effect p-5 Wid30"></div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid40"></div>
                            </div>
                            <div class="cart-item-num-block mb-5">
                                <div class="cart-item-qty-block">
                                    <div class="cart-item-inc-block">
                                        <div class="cart-item-inc-inner d-flex align-items-center">
                                            <div class="shimmer-effect p-6 mr-5"></div>
                                            <div class="shimmer-effect p-10 mr-5" style="width: 30px;"></div>
                                            <div class="shimmer-effect p-6"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid20"></div>
                            </div>
                            <div class="cart-item-delete p-5 shimmer-effect" style="position: absolute;"></div>
                        </div>
                    </div>
                    <div class="cart-item-list-main">
                        <div class="cart-item-img">
                            <div class="shimmer-effect" style="width: 60px; height: 60px;"></div>
                        </div>
                        <div class="cart-item-detail">
                            <div class="shimmer-effect p-5 Wid70 mb-5"></div>
                            <div class="cart-item-num-block mb-10">
                                <div class="shimmer-effect p-5 Wid30"></div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid40"></div>
                            </div>
                            <div class="cart-item-num-block mb-5">
                                <div class="cart-item-qty-block">
                                    <div class="cart-item-inc-block">
                                        <div class="cart-item-inc-inner d-flex align-items-center">
                                            <div class="shimmer-effect p-6 mr-5"></div>
                                            <div class="shimmer-effect p-10 mr-5" style="width: 30px;"></div>
                                            <div class="shimmer-effect p-6"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid20"></div>
                            </div>
                            <div class="cart-item-delete p-5 shimmer-effect" style="position: absolute;"></div>
                        </div>
                    </div>
                    <div class="cart-item-list-main">
                        <div class="cart-item-img">
                            <div class="shimmer-effect" style="width: 60px; height: 60px;"></div>
                        </div>
                        <div class="cart-item-detail">
                            <div class="shimmer-effect p-5 Wid70 mb-5"></div>
                            <div class="cart-item-num-block mb-10">
                                <div class="shimmer-effect p-5 Wid30"></div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid40"></div>
                            </div>
                            <div class="cart-item-num-block mb-5">
                                <div class="cart-item-qty-block">
                                    <div class="cart-item-inc-block">
                                        <div class="cart-item-inc-inner d-flex align-items-center">
                                            <div class="shimmer-effect p-6 mr-5"></div>
                                            <div class="shimmer-effect p-10 mr-5" style="width: 30px;"></div>
                                            <div class="shimmer-effect p-6"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid20"></div>
                            </div>
                            <div class="cart-item-delete p-5 shimmer-effect" style="position: absolute;"></div>
                        </div>
                    </div>
                    <div class="cart-item-list-main">
                        <div class="cart-item-img">
                            <div class="shimmer-effect" style="width: 60px; height: 60px;"></div>
                        </div>
                        <div class="cart-item-detail">
                            <div class="shimmer-effect p-5 Wid70 mb-5"></div>
                            <div class="cart-item-num-block mb-10">
                                <div class="shimmer-effect p-5 Wid30"></div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid40"></div>
                            </div>
                            <div class="cart-item-num-block mb-5">
                                <div class="cart-item-qty-block">
                                    <div class="cart-item-inc-block">
                                        <div class="cart-item-inc-inner d-flex align-items-center">
                                            <div class="shimmer-effect p-6 mr-5"></div>
                                            <div class="shimmer-effect p-10 mr-5" style="width: 30px;"></div>
                                            <div class="shimmer-effect p-6"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-spacer"></div>
                                <div class="shimmer-effect p-5 Wid20"></div>
                            </div>
                            <div class="cart-item-delete p-5 shimmer-effect" style="position: absolute;"></div>
                        </div>
                    </div>
                </div>
                <!-- ENd -->

                <ng-container *ngIf="showLoader">
                    <div class="pt-10 center cart-loader">
                        <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
                    </div>
                </ng-container>
                <!-- cart item list -->
                <ng-container *ngFor="let item of existCartData?.items;let i = index">
                    <div class="cart-item-list-main">
                        <div class="cart-item-img" (click)="viewItemDetail(item, i)">
                            <div [ngClass]="{'show-loader':item.item.imageLoader}" class="image-loader">
                                <div class="loader-icon "></div>
                            </div>
                            <img *ngIf="item.item.imageError" src="{{item.item.profilePic}}"
                                class="object-fit-cover wid-100" alt="Cart Image"
                                onerror="this.src='assets/images/no-image-available.png'">
                            <div *ngIf="!item.item.imageError" class="cart-item-img">
                                <img *ngIf="!item.item.imageError" (load)="item.item.imageLoader=false;"
                                    (error)="item.item.imageLoader=false;item.item.fullPicturePath = null;item.item.imageError=true"
                                    src="{{item.item.fullPicturePath}}" alt="Cart Image">
                            </div>
                        </div>
                        <div class="cart-item-detail">
                            <div class="one-linedesc" tooltipPosition="top" tooltipZIndex="1100000"
                                pTooltip="{{item.item.name}}" (click)="viewItemDetail(item, i)">{{item.item.name}}</div>
                            <div class="cart-item-num-block">
                                <strong style="font-weight: normal;">#{{item?.item?.itemNumber}}</strong>
                                <div class="flex-spacer"></div>
                                <strong style="font-weight: normal;" class="cart-item-mfr">{{getMfgName(item,
                                    existCartData.manufacturer)}}</strong>
                            </div>
                            <ng-container *ngIf="!item?.basketItemVariants || item?.basketItemVariants.length === 0">
                                <div class="cart-item-num-block" style="margin-bottom: 0px;">
                                    <div class="cart-item-qty-block">
                                        <div class="cart-item-inc-block">
                                            <div class="cart-item-inc-inner">
                                                <button (mouseup)="decrQtyEvent(item)"
                                                    (mousedown)="onclickSaveOldQty(item)" pButton type="button"
                                                    icon="fas fa-minus" class="cart-minus cart-btn"></button>
                                                <div class="cart-item-inc-input">
                                                    <input type="number" pInputText pKeyFilter="pint"
                                                        style="width: 50px;" [ngModel]="item.item.quantity"
                                                        (ngModelChange)="item.item.quantity=$event"
                                                        step="item.item.quantityIncrement" min="0"
                                                        (blur)="updateQtyEvent(item)"
                                                        (click)="onclickSaveOldQty(item)" />
                                                </div>
                                                <button (mouseup)="incrQtyEvent(item)"
                                                    (mousedown)="onclickSaveOldQty(item)" pButton type="button"
                                                    icon="fas fa-plus" class="cart-add  cart-btn"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-spacer"></div>
                                    <span
                                        class="cart-unit-price">{{(item.discountedTotal ? item.discountedTotal : 0) | rtCurrency}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let itemVariant of item?.basketItemVariants;let j = index">
                                <div class="d-flex justify-content-end" *ngIf="j === 0">
                                    <span class="cart-unit-price">
                                        <i class="fa fa-exclamation text-danger" *ngIf="item?.basketIscStatus"></i>
                                        {{(item.discountedTotal ? item.discountedTotal : 0) | rtCurrency}}
                                    </span>
                                </div>
                                <div class="cart-item-num-block mb-5">
                                    <div class="cart-item-qty-block">
                                        <div class="cart-item-inc-block">
                                            <div class="cart-item-inc-inner">
                                                <button (click)="decrQtyEvent(item, itemVariant)" pButton type="button"
                                                    icon="fas fa-minus"
                                                    (mousedown)="onclickSaveOldQtyVariant(item, itemVariant)"
                                                    class="cart-minus cart-btn"></button>
                                                <div class="cart-item-inc-input">
                                                    <input type="number" pInputText pKeyFilter="pint"
                                                        style="width: 50px;" [ngModel]="itemVariant.quantity"
                                                        (ngModelChange)="itemVariant.quantity=$event"
                                                        step="item.item.quantityIncrement" min="0"
                                                        (blur)="updateQtyEvent(item, itemVariant)"
                                                        (mousedown)="onclickSaveOldQtyVariant(item, itemVariant)"
                                                        (keydown)="updateQtyEventDown(item, itemVariant)" />
                                                </div>
                                                <button (click)="incrQtyEvent(item, itemVariant)" pButton type="button"
                                                    icon="fas fa-plus"
                                                    (mousedown)="onclickSaveOldQtyVariant(item, itemVariant)"
                                                    class="cart-add  cart-btn"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-spacer"></div>
                                    <div class="mr-2 ml-2" style="font-size: 12px;"
                                        *ngIf="itemVariant.color || itemVariant.style || itemVariant.size">
                                        (<span *ngIf="itemVariant.color">{{itemVariant.color}}, </span>
                                        <span *ngIf="itemVariant.style">{{itemVariant.style}}, </span>
                                        <span *ngIf="itemVariant.size">{{itemVariant.size}}</span>)
                                    </div>
                                    <div class="flex-spacer"></div>
                                    <span class="cart-unit-price"
                                        style="color: #999; font-size: 12px;">{{((itemVariant.quantity * itemVariant.unitPrice) - ((itemVariant.quantity * itemVariant.unitPrice) * ((itemVariant.itemDiscount ? itemVariant.itemDiscount : 0) / 100))) | rtCurrency}}</span>
                                </div>
                            </ng-container>
                            <div class="cart-item-delete">
                                <div class="d-flex align-items-center">
                                    <div class="mr-5" (click)="deleteItemFromCart(item, existCartData.items)">
                                        <i class="fa fa-trash" pTooltip="Delete" tooltipPosition="left"
                                            tooltipZIndex="1100000"></i>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="cart-item-delete" (click)="deleteItemFromCart(item, existCartData.items)">
                                <i class="fa fa-trash" pTooltip="Delete" tooltipPosition="left" tooltipZIndex="1100000"></i>
                            </div> -->
                        </div>
                    </div>
                </ng-container>
                <!-- End -->
            </p-scrollPanel>
        </div>
        <p-footer class="card-footer" *ngIf="!pageType">

            <div class="wid-100 d-flex justify-content-between p-4" *ngIf="basketIscStatus">
                <span style="font-size: 12px;" class="text-danger">
                    <i class="fa fa-exclamation"></i>
                    Please add minimum qty for items
                </span>
            </div>
            <div class="wid-100 d-flex p-5" [ngClass]="auth.getshowPromotionsButtonFlag() == true ? 'justify-content-between' : 'justify-content-end'">
                <button *ngIf="auth.getshowPromotionsButtonFlag()" class="ui-button-info btn-theme-color btn-with-icon" pButton type="button" label="Promotions"
                    (click)="promotionsClickEvent.emit(true)">
                    <span class="btn-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32.812" viewBox="0 0 40 32.812">
                            <g id="Group_1" data-name="Group 1" transform="translate(-122 -177)">
                                <path class="fill-current" id="Path_1" data-name="Path 1" d="M440.765,226h-3.593a1.172,1.172,0,1,0,0,2.343h3.593a1.172,1.172,0,1,0,0-2.343Z" transform="translate(-279.936 -34.937)"/>
                                <path class="fill-current" id="Path_2" data-name="Path 2" d="M425.344,288.686,423,286.343A1.172,1.172,0,0,0,421.344,288l2.343,2.343a1.172,1.172,0,0,0,1.657-1.657Z" transform="translate(-266.108 -90.249)"/>
                                <path class="fill-current" id="Path_3" data-name="Path 3" d="M425.343,136.343a1.171,1.171,0,0,0-1.657,0l-2.343,2.343A1.172,1.172,0,0,0,423,140.343L425.343,138A1.171,1.171,0,0,0,425.343,136.343Z" transform="translate(-266.108 48.032)"/>
                                <path class="fill-current" id="Path_4" data-name="Path 4" d="M8.28,136a3.509,3.509,0,0,0-3.3,2.343H4.765a4.687,4.687,0,1,0,0,9.373h.216a3.509,3.509,0,0,0,3.3,2.343h5.858V136Z" transform="translate(122 48.032)"/>
                                <path class="fill-current" id="Path_5" data-name="Path 5" d="M333.343,46A2.344,2.344,0,0,0,331,48.344V71.782a2.343,2.343,0,1,0,4.687,0V48.344A2.344,2.344,0,0,0,333.343,46Z" transform="translate(-183.14 131)"/>
                                <path class="fill-current" id="Path_6" data-name="Path 6" d="M92.172,344.6A5.856,5.856,0,0,1,91,344.482v5.977a3.515,3.515,0,0,0,7.03,0V344.6Z" transform="translate(38.11 -144.161)"/>
                                <path class="fill-current" id="Path_7" data-name="Path 7" d="M217,85.819a10.032,10.032,0,0,1-6,2.856v14.289a10.031,10.031,0,0,1,6,2.856l1.03,1.03V84.789Z" transform="translate(-73.229 95.241)"/>
                            </g>
                        </svg>
                    </span>
                </button>
                <button class="ui-button-info btn-theme-color btn-with-icon"
                    [ngClass]="{'no-min-met': checkMinimumMet(existCartData?.manufacturer)}" pButton type="button"
                    label=" Minimum Met" (click)="metClickEvent.emit(true)">
                    <span class="btn-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="37.399" viewBox="0 0 40 37.399">
                            <g id="Products-2" transform="translate(0 -16.645)">
                                <path class="fill-current" id="Path_8" data-name="Path 8" d="M189.469,16.645v7.031h-2.344V16.645h-7.031V33.051H196.5V16.645Z" transform="translate(-166.024)"/>
                                <path class="fill-current" id="Path_9" data-name="Path 9" d="M39.171,240.624a16.051,16.051,0,0,0-1.735-1.005,2.343,2.343,0,0,0-2.694.445l-5.978,5.977a5.822,5.822,0,0,1-4.143,1.717H15.134v-2.344h10.3v-4.687H12.392a5.842,5.842,0,0,0-4.156,1.726L0,250.686l8.688,8.688,6.931-6.931h9.014a10.444,10.444,0,0,0,7.434-3.081c2.455-2.43,5.64-5.618,7.933-7.911Z" transform="translate(0 -205.33)"/>
                            </g>
                        </svg>
                    </span>
                </button>
            </div>
            <div class="d-flex justify-content-between align-items-end p-5">
                <h2 class="m-0 mr-10 align-self-center"><strong>{{existCartData?.totalItems}} Items/ {{(existCartData?.cartDiscountedTotal ?
                    existCartData?.cartDiscountedTotal : 0) | rtCurrency}}</strong></h2>
                <button pButton type="button" label="Checkout Now" class="ui-button-success theme-maroon-btn no-wrap-text-btn"
                    icon="fa fa-fw fa-shopping-basket"
                    [disabled]="basketIscStatus || basketSyncLoader || !existCartData?.cartTotal || !selectedCustomer || (selectedCustomer && !selectedCustomer.selectedShipTo)"
                    (click)="checkoutClickEvent.emit(true)"></button>
                <!-- (click)="shopCheckOut()" -->
            </div>
        </p-footer>
    </p-card>
</div>
<p-confirmDialog [(visible)]="visibleConfirmModel" [key]="cartBarPosition" [appendTo]="'body'" [baseZIndex]="10000">
</p-confirmDialog>
<RtCustom-confirmDialog key="{{confirmkey}}" [baseZIndex]="1000" [appendTo]="'body'" #breckincrementcart
    header="Drop database" acceptLabel="Yes" rejectLabel="No">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="Yes" id="YesButton"
            (click)="breckincrementcart.accept()"></button>
        <button type="button" class="ui-button-secondary" pButton icon="pi pi-times" label="No"
            (click)="breckincrementcart.close($event)"></button>
    </p-footer>
</RtCustom-confirmDialog>