<form (submit)="saveRetailerAdvancedInfo($event)" [formGroup]="editRetailerAdvancedForm" autocomplete="off">
    <div style="position: fixed; top:0;left:0;z-index: 5000" #multiDropdownContainer></div>
    <div class="p-grid" style="padding: 5px;">
        <div class="p-col-6">
            <label for="retailerToDivision">Assign Retailer To Division</label><br>
            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" id="retailerToDivision"
                        formControlName="repGroupDivision"
                        [appendTo]="multiDropdownContainer" [options]="repgroupDivisionList"
                        placeholder="Select Division"></p-dropdown>
        </div>
        <div class="p-col-6">
            <label for="companyCategory">Select Company Category</label>
            <div class="input-error-container">
                <p-dropdown autoWidth="false" [style]="{'width':'100%'}" id="companyCategory"
                            [appendTo]="multiDropdownContainer" [options]="categories"
                            formControlName="retailerCategory"
                            placeholder="Select Category"></p-dropdown>
            </div>
        </div>
        <rs-salesperson-assign-table [repGroupID]="repGroupID" [(salesPersonTable)]="salesPersonTable" *ngIf="!isSalesPerson()"></rs-salesperson-assign-table>
    </div>
    <ng-content></ng-content>
</form>
