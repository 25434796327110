import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyService } from '../../services/api/company.service';

@Component({
  selector: 'app-download-image-zip',
  templateUrl: './download-image-zip.component.html',
  styleUrls: ['./download-image-zip.component.scss']
})
export class DownloadImageZipComponent implements OnInit, OnDestroy {
  @Output() hide = new EventEmitter();
  @Input() listOfItems = [];
  visible = true;
  isLoading = false;
  sizeList = [
    {
      title: 'Thumbnails (64x64px)',
      value: '64x64'
    },
    {
      title: 'Wallet Size (256x256px)',
      value: '256x256'
    },
    {
      title: 'Photo Size (512x512px)',
      value: '512x512'
    },
    {
      title: 'Large Size (1024x1024px)',
      value: '1024x1024'
    },
    {
      title: 'Original Size (may take time to download)',
      value: 'originalsize'
    }
  ];
  selectedsize = this.sizeList[0].value;
  private readonly onDestroy = new Subject<void>();
  repGroupID = '';
  filename = '';

  public domainData: any = (<any>window).domainData;
  constructor(
    public companyService: CompanyService,
    private msgService: MessageService,
  ) { }

  ngOnInit() {
    let session = JSON.parse(sessionStorage.getItem('logindetail'));
    this.repGroupID = this.domainData && this.domainData.repGroup && this.domainData.repGroup.recordID ? this.domainData.repGroup.recordID : '';
    this.filename = this.repGroupID + '_' + session.entityId;
    console.log('-->', this.listOfItems)
  }
  ngOnDestroy() {
    this.onDestroy.next();
  }
  downloadFilezip(res: any, mimeType: string) {
    const url = window.URL.createObjectURL(new Blob([res.data], { type: mimeType }));
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
  }
  downloadfile() {
    const list = this.listOfItems.reduce((a, o) => (o.manufacturerID && a.push({ manufacturerID: o.manufacturerID, recordID: o.recordID }), a), []);
    console.log(this.selectedsize, list);
    this.isLoading = true;
    this.onDestroy.next();
    this.companyService.downloadImages(list, this.repGroupID, this.filename, this.selectedsize).
      pipe(takeUntil(this.onDestroy))
      .subscribe(async (response: any) => {
        this.isLoading = false;
        this.downloadFilezip(response, 'application/zip');
        this.visible = false;
        this.hide.emit(false)
      }, error => {
        this.isLoading = false;
        this.msgService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error occurred while download file'
        });
      });
    console.log(this.selectedsize);
  }

}
