import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../middleware/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardRouterSetupService implements CanActivate {
  constructor(private router: Router, private httpClient: HttpClient, private _auth: AuthService,) { }
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.fnCheckdomain(route, state.url).then(res => {
      return res;
    });
  }
  fnCheckdomain(route, url) {
    return new Promise((resolve, reject) => {
      const domainData = (window as any).domainData;
      console.log(route, domainData);

      if (domainData) {
        console.log('route.data.index ', route.data.index);
        if (route.data && route.data.index !== undefined && route.data.index === 0) {
          console.log('a');
          if (this._auth.getProjectType() === 2) {
            resolve(true);
          } else {
            this.router.navigate(['/login']);
            resolve(false);
          }
        } else {
          console.log('b');
          resolve(true);
        }
      } else {
        if (route.data && route.data.index !== undefined && route.data.index === 0) {
          console.log('c');
          resolve(true);
        } else {
          console.log('d');
          this.router.navigate(['/']);
          resolve(false);
        }
      }
    });
  }
}
