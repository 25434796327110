<div class="mt-10 mx-5 pt-5">
    <button type="button"
            *ngIf="activeIndex >= 1 && stepsArr.length > 1"
            [icon]="previousLoader ? 'pi pi-spin pi-spinner' : 'fa fa-chevron-left FsWhite'"
            (click)="previousEvent.emit()" class="ui-button-info ui-button ui-button-raised FLeft  mb-10" label="Previous"
            [disabled]="previousLoader"
            pButton></button>
    <button type="submit" *ngIf="stepsArr.length - 1 > activeIndex"
            class="ui-button-info ui-button ui-button-raised FRight mb-10"
            iconPos="right"
            [disabled]="nextLoader"
            [icon]="nextLoader ? 'pi pi-spin pi-spinner' : 'fa fa-chevron-right FsWhite'"
            label="Next"
            pButton></button>
    <button type="submit" *ngIf="stepsArr.length - 1 === activeIndex"
            class="ui-button-info ui-button ui-button-raised FRight mb-10"
            label="Finish"
            iconPos="right"
            [icon]="finishLoader ? 'pi pi-spin pi-spinner' : 'fa fa-chevron-right FsWhite'"
            [disabled]="finishLoader"
            pButton></button>
</div>
