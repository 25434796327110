<div class="vendor-item-main d-flex" *ngIf="notMfgIdFoundSidebar"
    [ngClass]="{'shopinfohun': (auth.getCartStyle() === 0 || auth.getCartStyle() === 3), 'shopstaticcart': auth.getCartStyle() === 2, 'shop-four-img': (auth.getCartStyle() === 1 || auth.getCartStyle() === 4)}">
    <p-toast position="top-right"></p-toast>
    <div class="page reptime-theme wid-catalogs-main resp-promo"
        [ngClass]="{'window-catalog-main': fromPage && fromPage === 'digital-catalog'}">

        <!-- File manager start -->
        <div class="filemanager-main" *ngIf="isShowFolder" style="position: relative;">
            <div class="breadcrumb">
                <i class="fas fa-arrow-left pointer backarrow" *ngIf="prevArray.length"
                    [ngClass]="{'disabled': backArrowDisable}" (click)="navigateOut()"></i>
                <!-- <span style="margin-left: 8px">{{currentPath}}</span> -->
                <div class="path-wrap">
                    <span class="active-path" *ngFor="let element of prevArray; let i = index; let last = last;">
                        <a href="javascript:;" *ngIf="!last"
                            (click)="goToSelectedFolder(element, i)">{{element.folderName}}</a>
                        <span href="javascript:;" *ngIf="last">{{element.folderName}}</span>
                    </span>
                </div>
                <div class="button-wrap" style="margin-left: auto;">
                    <p-button icon="fa fa-shopping-bag" label="Shop Items" (click)="goToShoppingPage()"
                        class="shoping-btn" [styleClass]="'ui-button-info btn-theme-color'">
                    </p-button>
                </div>
            </div>
            <div *ngIf="showFolderLoader && (!fileManagment || fileManagment.length === 0)">
                <div class="center main-loader">
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
                </div>
            </div>
            <div class="folder-manager" *ngIf="!showFolderLoader && fileManagment && fileManagment.length">
                <div *ngFor="let element of fileManagment; let i = index" class="file-or-folder">
                    <div class="folder-wrap" (click)="element.type === 'folder' ? navigateIn(element, i) : ''"
                        *ngIf="element.type === 'folder'">
                        <!-- <i color="primary" class="fas fa-folder fileicon" *ngIf="element.type === 'folder' && !element.thumbnailImage"></i> -->
                        <div class="thumbnailimage">
                            <!-- <div class="thumbnailimage-inner">
                                    <i color="primary" class="fas fa-folder fileicon"></i>
                                    <img src="{{docFolderImage}}/{{element.thumbnailImage}}" class="folder-img" />
                                </div> -->

                            <div class="folder">
                                <img src="assets/images/folder-img.png" class="folder-img"
                                    *ngIf="element.type === 'folder' && !element.thumbnailImage" />
                                <img src="{{docFolderImage}}/{{element.thumbnailImage}}" class="folder-img"
                                    *ngIf="element.type === 'folder' && element.thumbnailImage" />
                            </div>
                        </div>
                        <span class="folder-name">{{element.name}}</span>
                    </div>
                    <div class="file-grid" *ngIf="element.type === 'file'">
                        <div class="grid-item ui-g-12 ui-md-2" style="height: auto !important;">
                            <span class="cart-count vendor-cart-count"
                                *ngIf="element && element.documentType">{{element.documentType}}</span>
                            <div>
                                <div class="catalog-img-block">
                                    <img *ngIf="element.imageError" src="assets/images/no-image-available.png"
                                        alt="{{element.name}}">
                                    <img *ngIf="!element.imageError" src="{{element.fullPicturePath}}"
                                        alt="{{element.name}}" (load)="element.imageLoader=false;"
                                        (error)="element.imageLoader=false;element.fullPicturePath = null;element.imageError=true" />
                                </div>
                                <div class="catalogs-nametxt theme-grayclr"><b>{{element.name}}</b></div>
                            </div>
                            <!-- <div class="theme-grayclr" style="border-top:1px solid #DDD;padding:5px;font-size:11px">
                                    {{element.manufacturerName}}<br>{{element.documentType}}
                                </div> -->
                            <!-- B2B-110 (NL 03-Mar-2020) add class "pdf-option" and add download option -->
                            <div class="overlay mobile-show-icon">
                                <div class="overlay-links clearfix overlay-links-center" [ngClass]="getClass(element)">
                                    <a *ngIf="element.viewAllowed && (element['documentType'] != 'Digital Book')"
                                        (click)="openPdfDialog(element['fileHash'], element['fileNamePath'], element['companyID'])"
                                        pTooltip="View Document">
                                        <div>
                                            <i class="fa fa-fw fa-eye"></i>
                                        </div>
                                    </a>
                                    <a (click)="downloadDoc(element['fileHash'], element['fileNamePath'], element['companyID'])"
                                        pTooltip="Download">
                                        <div>
                                            <i class="fa fa-download"></i>
                                        </div>
                                    </a>
                                    <a
                                        (click)="copyDownloadUrl(element['fileHash'], element['recordID'], element['companyID'], 'grid')">
                                        <div>
                                            <i class="fa fa-fw fa-clone" pTooltip="Link copied to clipboard!"
                                                #copyLinksGrid [attr.id]="'copy-grid-link-' + element['recordID']"
                                                tooltipPosition="top" tooltipEvent="focus" [life]="1200"></i>
                                        </div>
                                    </a>
                                    <a *ngIf="element['documentType'] === 'Digital Book'"
                                        (click)="openDigitalDialog(element)" pTooltip="Shop Catalog">
                                        <div>
                                            <i class="fa fa-shopping-bag"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-file" *ngIf="!showFolderLoader && fileManagment.length === 0">
                <p>No file found</p>
            </div>
        </div>
        <!-- File manager over -->

        <p-card class="card" *ngIf="!isShowFolder">
            <p-header class="card-header search-tool-box has-other-options">

                <div class="ui-md-4 ui-sm-12 ui-xl-6" *ngIf="!fromPage || (fromPage && fromPage !== 'digital-catalog')">
                    <h1 class="theme-grayclr" style="margin-bottom: 0px;font-size: 20px;"> Catalogs </h1>
                </div>
                <!-- <div class="ui-selectbutton catalog-dropdown-block mr-10">
                            <p-dropdown [options]="catalogCallout" filter="true" class="callout-drlist" [disabled]="true">                
                                <ng-template let-car pTemplate="item">
                                    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">                                
                                        <div style="font-size:14px;margin-top:4px">{{car.label}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div> -->
                <!-- <div class="ui-selectbutton catalog-dropdown-block mr-10">
                            <p-dropdown [options]="catalogManufacture" [(ngModel)]="selectedMfgName"  (onChange)="search($event)" filter="true" class="callout-mfrlist">                
                                <ng-template let-car pTemplate="item">
                                    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">                                
                                        <div style="font-size:14px;margin-top:4px">{{car.label}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div> -->
                <div class="flex-1 search-box">
                    <rs-advanced-search [placeholderForSearchBar]="placeholderForSearchBar" class="brandinner-search"
                        [advanceOptions]="advanceOptions" [isSearchByActive]="false" [isStatusActive]="false"
                        [isAvailable]="false" [hideAdavanceBtn]="false" [isResetLoading]="isResetLoading"
                        [isSubmitLoading]="isSubmitLoading" [searchText]="searchText" (submitEvent)="search($event)"
                        (resetEvent)="reset($event)" [searchFormData]='searchFormData'>
                    </rs-advanced-search>
                </div>

                <div class="other-options">
                    <div class="ui-selectbutton">
                        <p-button icon="fa fa-shopping-bag" label="Shop Item" (click)="goToShoppingPage()"
                            class="shoping-btn" [styleClass]="'ui-button-info btn-theme-color'"
                            [ngClass]="{'display-none': fromPage && fromPage === 'digital-catalog'}">
                        </p-button>
                        <button (click)="displayTable()" label="" class="ui-button ui-button-icon-only ui-state-default"
                            [class.ui-state-active]="!showTable" pButton style="margin-right: 0px;"><i
                                class="fa fa-bars ui-button-icon-left small-hover-icon"></i></button>
                        <button (click)="displayGrid()" label="" class="ui-button ui-button-icon-only ui-state-default"
                            [class.ui-state-active]="showTable" pButton><i
                                class="fa fa-th-large ui-button-icon-left small-hover-icon"></i></button>
                    </div>
                </div>

            </p-header>

            <p-table (onLazyLoad)="updateTable($event)" #itemListingTable [lazy]="true" [columns]="documentsTableCols"
                [value]="finaldocumentsTableValue" [dataKey]="'name'" [responsive]="false" [autoLayout]="true"
                [loading]="loadingTableData" [scrollable]="true" [rows]="50" [totalRecords]="totalDocuments"
                [paginator]="true" [rowsPerPageOptions]="[50, 75, 100]" *ngIf="!showTable" sortField="name"
                class="head-fcol-break">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th class="icon-cell reptimesticky-possition reptimesticky-possition-left-0"
                            style="width: 45px;">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th *ngFor="let col of documentsTableCols" [pSortableColumn]="col.field"
                            [style.width]="col.width">
                            <div *ngIf="col.field === 'name'" class="clearfix">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </div>
                            <div *ngIf="col.field === 'description'" class="clearfix">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </div>
                            <div *ngIf="col.field !== 'name' && col.field !== 'description'" class="clearfix">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </div>
                        </th>
                        <th class="icon-cell reptimesticky-possition reptimesticky-possition-right-0"
                            [ngStyle]="{'width': (!fromPage || (fromPage && fromPage !== 'digital-catalog')) ? '160px' : '55px'}">
                        </th>
                        <!-- <th class="icon-cell" style="width: 45px;"></th>
                        <th class="icon-cell" style="width: 45px;"></th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorright">
                    <div class="per-page-text">Per page :</div>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    <div class="per-page-text pl-10">Total :</div>
                    {{ totalDocuments }}
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="documentsTableCols">
                    <tr [pSelectableRow]="rowData">
                        <td class="icon-cell reptimesticky-possition reptimesticky-possition-left-0">
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </td>
                        <td *ngFor="let col of documentsTableCols" [style.width]="col.width">
                            <div [ngSwitch]="col.field">

                                <div *ngSwitchCase="'manufacturerName'">
                                    {{ rowData[col.field] }}
                                    <!-- <span *ngIf="rowData['manufacturerID']" class="id-label">{{rowData['manufacturerID']}}</span>
                                    <span *ngIf="rowData.manufacturerManagedBy === rowData.manufacturerID" class="ml-5"
                                        title="Managed By {{rowData['manufacturerName']}} " pTooltip="Managed By {{rowData['manufacturerName']}} "
                                        tooltipPosition="top">
                                        <i class="fa fa-chain link grey-text"></i>
                                    </span> -->
                                </div>
                                <div *ngSwitchCase="'name'">
                                    {{ rowData[col.field] }}
                                    <br />
                                    <span style="word-break: break-word;color:#848484;font-size:12px;">
                                        {{rowData['fileNamePath']}} </span>
                                </div>
                                <div *ngSwitchDefault style="min-width: 100px">{{rowData[col.field]}}</div>
                            </div>
                        </td>
                        <td class="icon-cell reptimesticky-possition reptimesticky-possition-right-0"
                            [ngStyle]="{'width': (!fromPage || (fromPage && fromPage !== 'digital-catalog')) ? '160px' : '55px'}">
                            <div class="reptime-action-grid "
                                *ngIf="!fromPage || (fromPage && fromPage !== 'digital-catalog')">
                                <div class="reptime-action-col">
                                    <i style="font-size:22px;color:#333;" class="fa fa-eye" *ngIf="rowData.viewAllowed"
                                        (click)="openPdfDialog(rowData['fileHash'], rowData['fileNamePath'], rowData['companyID'])"
                                        pTooltip="View Document" tooltipPosition="top" tooltipEvent="focus"></i>
                                </div>
                                <div class="reptime-action-col">
                                    <i style="font-size:22px;color:#333;" class="fa fa-fw fa-clone link" #copyLinks
                                        [attr.id]="'copy-link-' + rowData['recordID']"
                                        (click)="copyDownloadUrl(rowData['fileHash'], rowData['recordID'], rowData['companyID'], 'table')"
                                        pTooltip="Link copied to clipboard!" tooltipPosition="top" tooltipEvent="focus"
                                        [life]="1200"></i>
                                </div>
                                <div class="reptime-action-col">
                                    <i style="font-size:22px;color:#333;" class="fa fa-download link"
                                        pTooltip="Download Document"
                                        (click)="downloadDoc(rowData['fileHash'], rowData['fileNamePath'], rowData['companyID'])"
                                        tooltipPosition="top" tooltipEvent="focus"></i>
                                </div>
                                <div class="reptime-action-col">
                                    <i style="font-size:22px;color:#333;" class="fa fa-shopping-bag link"
                                        *ngIf="rowData.documentType === 'Digital Book'"
                                        (click)="openDigitalDialog(rowData)" pTooltip="Shop Catalog"
                                        tooltipPosition="top" tooltipEvent="focus"></i>
                                </div>
                            </div>
                            <div class="reptime-action-grid" *ngIf="fromPage && fromPage === 'digital-catalog'">
                                <div class="reptime-action-col">
                                    <i style="font-size:22px;color:#333;" class="fa fa-shopping-bag link"
                                        *ngIf="rowData.documentType === 'Digital Book'"
                                        (click)="openDigitalDialog(rowData)" pTooltip="Shop Catalog"
                                        tooltipPosition="top" tooltipEvent="focus"></i>
                                </div>
                            </div>
                        </td>
                        <!-- <td class="icon-cell" style="width: 45px;">
                           <i style="font-size:22px;color:#333;" class="fa fa-fw fa-clone link" #copyLinks
                           [attr.id]="'copy-link-' + rowData['recordID']"
                           (click)="copyDownloadUrl(rowData['fileHash'], rowData['recordID'], rowData['companyID'], 'table')"
                           pTooltip="Link copied to clipboard!"
                           tooltipPosition="top" tooltipEvent="focus" [life]="1200"></i>
                        </td>
                        <td class="icon-cell" style="width: 45px;">
                            <i style="font-size:22px;color:#333;" class="fa fa-download link"
                            pTooltip="Download Document" (click)="downloadDoc(rowData['fileHash'], rowData['fileNamePath'], rowData['companyID'])"
                            tooltipPosition="top" tooltipEvent="focus" ></i>
                         </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr class="table-empty">
                        <td [attr.colspan]="documentsTableCols && documentsTableCols.length + 0" class="text-center">
                            {{loadingTableData ?
                            'Loading documents' :
                            'You have no catalogs list yet.' }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <p-dataView #itemListingGrid [value]="finaldocumentsTableValue" *ngIf="showTable" emptyMessage="{{loadingTableData ?
                            'Loading documents' :
                            'You have no catalogs list yet.'}}" [loading]="loadingTableData"
                (onPage)="updateTable($event);" [rows]="50" [totalRecords]="totalDocuments" [paginator]="true"
                [rowsPerPageOptions]="[50, 75, 100]" layout="grid" (onLazyLoad)="updateTable($event)" [lazy]="true"
                [totalRecords]="totalDocuments" class="promotion-datview">

                <ng-template pTemplate="paginatorright">
                    <div class="per-page-text">Per page :</div>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    <div class="per-page-text pl-10">Total:</div>
                    {{ totalDocuments }}
                </ng-template>

                <ng-template let-catalog pTemplate="gridItem">
                    <div class="grid-item ui-g-12 ui-md-2 document-wrap" style="height: auto !important;">
                        <span class="cart-count vendor-cart-count"
                            *ngIf="catalog && catalog.documentType === 'Digital Book'">Digital Book</span>
                        <div>
                            <div class="catalog-img-block">
                                <img *ngIf="catalog.imageError" src="assets/images/no-image-available.png"
                                    alt="{{catalog.name}}">
                                <img *ngIf="!catalog.imageError" src="{{catalog.fullPicturePath}}"
                                    alt="{{catalog.name}}" (load)="catalog.imageLoader=false;"
                                    (error)="catalog.imageLoader=false;catalog.fullPicturePath = null;catalog.imageError=true" />
                            </div>
                            <div class="catalogs-nametxt theme-grayclr"><b>{{catalog.name}}</b></div>
                        </div>
                        <div class="theme-grayclr" style="border-top:1px solid #DDD;padding:5px;font-size:11px">
                            {{catalog.manufacturerName}}<br>{{catalog.documentType}}
                        </div>
                        <!-- B2B-110 (NL 03-Mar-2020) add class "pdf-option" and add download option -->
                        <div class="overlay">
                            <div class="overlay-links overlay-links-center clearfix" [ngClass]="getClass(catalog)"
                                *ngIf="!fromPage || (fromPage && fromPage !== 'digital-catalog')">
                                <a *ngIf="catalog.viewAllowed"
                                    (click)="openPdfDialog(catalog['fileHash'], catalog['fileNamePath'], catalog['companyID'])"
                                    pTooltip="View Document">
                                    <div>
                                        <i class="fa fa-fw fa-eye"></i>
                                    </div>
                                </a>
                                <a (click)="downloadDoc(catalog['fileHash'], catalog['fileNamePath'], catalog['companyID'])"
                                    pTooltip="Download">
                                    <div>
                                        <i class="fa fa-download"></i>
                                    </div>
                                </a>
                                <a
                                    (click)="copyDownloadUrl(catalog['fileHash'], catalog['recordID'], catalog['companyID'], 'grid')">
                                    <div>
                                        <i class="fa fa-fw fa-clone" pTooltip="Link copied to clipboard!" #copyLinksGrid
                                            [attr.id]="'copy-grid-link-' + catalog['recordID']" tooltipPosition="top"
                                            tooltipEvent="focus" [life]="1200"></i>
                                    </div>
                                </a>
                                <a *ngIf="catalog && catalog.documentType === 'Digital Book'"
                                    (click)="openDigitalDialog(catalog)" pTooltip="Catalog">
                                    <div>
                                        <i class="fa fa-shopping-bag"></i>
                                    </div>
                                </a>
                            </div>
                            <div class="overlay-links overlay-links-center clearfix" [ngClass]="getClass(catalog)"
                                *ngIf="fromPage && fromPage === 'digital-catalog'">
                                <a *ngIf="catalog && catalog.documentType === 'Digital Book'"
                                    (click)="openDigitalDialog(catalog)" pTooltip="Catalog">
                                    <div>
                                        <i class="fa fa-shopping-bag"></i>
                                        <span class="shop-item">Shop</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr class="table-empty">
                        <td [attr.colspan]="documentsTableCols && documentsTableCols.length + 3" class="text-center">
                            {{loadingTableData ?
                            'Loading documents' :
                            'You have no catalogs list yet.' }}
                        </td>
                    </tr>
                </ng-template>
            </p-dataView>

            <!-- <p-dataView #itemListingGrid [value]="catalogManufacture" *ngIf="showTable" emptyMessage="{{loadingTableData ?
                'Loading documents' :
                'You have no catalogs list yet.'}}" [loading]="loadingTableData"
                (onPage)="getManufactureData($event);" [rows]="50" [totalRecords]="catalogManufacture.length" [paginator]="true"
                [rowsPerPageOptions]="[50, 75, 100]" layout="grid" (onLazyLoad)="updateTable($event)"
                [lazy]="true" [totalRecords]="catalogManufacture.length" class="promotion-datview">
    
                <ng-template pTemplate="paginatorright">
                    <div class="per-page-text">Per page :</div>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    <div class="per-page-text px-10">Total :</div>
                   {{catalogManufacture ? catalogManufacture.length-1 : 0}}
                </ng-template>
    
                <ng-template let-vendor pTemplate="gridItem">
                    <div class="grid-item ui-g-12 ui-md-2" (click)="viewcatalog(vendor)" *ngIf="vendor.value">
                        <div style="padding:10px;">                        
                            <div class="catalog-img-block">
                                <img alt="vendor logo" src="{{vendor['fullPicturePath']}}" onerror="this.onerror=null;this.src='assets/images/no-image-available.png';">
                            </div>
                            <div class="mb-5" style="color:#585958;">
                                <b class="catalogs-nametxt" pTooltip="Manufacturers" tooltipPosition="top">
                                    {{vendor['label']}}
                                </b>
                            </div>
                            <div class="text-center d-flex flex-column mb-2" style="font-size: 13px;color:#585958;">
                                <span class="mb-5">Minimum Order {{(vendor['minimumOrderAmount'] ? vendor['minimumOrderAmount'] : 0 ) | rtCurrency}} </span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr class="table-empty">
                    </tr>
                </ng-template>
            </p-dataView> -->
        </p-card>
    </div>
    <app-digital-catalog *ngIf="openDigitalCatalog" [catalogData]="catalogData" (closeDialog)="closeDialog($event)">
    </app-digital-catalog>
    <!-- RC-766 (MJ 13-APRIL-2020) Error message and View loading percentage added -->
    <p-dialog [(visible)]="showPdfDialog" [style]="{'height': dialogHeight, 'width': '70%'}" header="View Documents"
        modal="true" [maximizable]="true" [blockScroll]="true" [closeOnEscape]="true" [draggable]="false"
        class="pdf-view-dialog" [resizable]="false">
        <div *ngIf="showPdfDialogLoader && !errorMsg" class="text-center pt-10">
            Loading documents file {{pdfViewPercent/100 | percent}}...
        </div>
        <div *ngIf="errorMsg" class="text-center pt-10">
            <label>Failed to load PDF document.</label>
        </div>
        <pdf-viewer [src]="pdfSrc" [render-text]="false" (on-progress)="onProgressPdfViewer($event)"
            [original-size]="false" [zoom]="1.35" [zoom-scale]="'page-height'"
            (after-load-complete)="callBackFnPdfViewer($event)" (error)="onError($event)" style="display: block;"
            [ngStyle]="{'height': pdfHeight}"></pdf-viewer>
    </p-dialog>
</div>
<div *ngIf="!notMfgIdFoundSidebar">
    <div class="no-mfg-found">
        <img src="../../../../assets/images/no-data-for-mfg.svg">
        <p>No manufacturers associated with requested IDs</p>
        <button pButton type="button" class="btn-theme-color" label="Back To Homepage" (click)="gotoHome()"></button>
    </div>
</div>