import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ItemSearch } from '../../../../models/item-search';
import { environment } from '../../../../../environments/environment';
import { ItemsAPIService } from '../../../../services/api/items-api/items-api.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../../../services/middleware/order/order.service';
import { RTSharedService } from '../../../../services/rtshared/rtshared.service';
import { ConfirmationService, DialogService, MessageService } from 'primeng/api';
import { AuthService } from '../../../../services/middleware/auth/auth.service';
import { BasketItemService } from '../../../../services/middleware/basket-item/basket-item.service';
import { CompanyService } from '../../../../services/api/company.service';
import * as objectPath from 'object-path';
import $ from 'jquery';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { APIResponse } from '../../../../models/api-response';
import { Table } from 'primeng/table';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-search-item-table',
    templateUrl: './search-item-table.component.html',
    styleUrls: ['./search-item-table.component.scss']
})
export class SearchItemTableComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() manufacturerID: string;
    @Input() manufacturersArr: any;
    @Input() fromPage: string;
    @Input() selectedCustomer: any;
    @Input() repGroupID: string;
    @Input() existCartData: any;
    @Input() rows = 30;
    @Output() hidePopup?= new EventEmitter<any>();
    @ViewChild('itemListingTable', { static: false }) itemListingTable: Table;

    public cols: any[];
    public tableEvent: any = { rows: this.rows, first: 1 };
    public items: any[] = [];
    public showTable: boolean;
    public searchItem = new ItemSearch();
    public baseImageUrl: string;
    public searchForm: any;
    public loadingTableData = true;
    public isSubmitLoading: boolean;
    public isResetLoading: boolean;
    public advanceOptions = [];
    public dateRangeOptions = [];
    public totalItemCount = -1;
    public selectedView: string;
    public addDisbaleObj: any = {};
    addBtnDisableSub: Subscription;
    itemTableSubscribe: Subscription;
    heartStatusArray: boolean[] = [];
    public initSelectorItemTable: boolean;
    public displayItemViewDialog: boolean;
    public activeRouteQP: any;
    public activeViewItem: any;
    public activeViewIndex: any;
    public calloutValues = [];
    public shopItemShimmer = true;
    shimmercounts = ['1', '1', '1', '1', '1', '1', '1', '1'];
    public showDisplayViewOption = true;
    public isQuickOrder: any;
    public cartData: any;
    cartSidebar: any;
    win: any = (window);
    public domainData: any = this.win.domainData;
    header: any;
    headerHeight: any;
    public cartClickSubscription: Subscription;
    public cartQtyUpdateSubscription: Subscription;
    public selectedCartItemSubscription: Subscription;
    public itemSearchSubscription: Subscription;
    // placeholderForSearchBar = 'Search for products or brands';
    placeholderForSearchBar = 'Search for items';
    public subscriptionItemsData: Subscription;
    oldQty = 0;
    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    // RC-1243 (MK-2-OCT-2020) add exact search type
    typeList = [{ label: 'Start', value: 'startswith' }, { label: 'Contain', value: 'contains' }, { label: 'Exact Match', value: 'exactmatch' }];
    stickyTop;
    selecteditems = [];
    visibledownload = false;
    isShowDownload = false;
    @Input() pageType: any;
    public logoBucketUrl = environment.logoBucketUrl;
    public cartBarPosition = 'sliding';
    logoPath = this.logoBucketUrl + 'logo/{companyID}.png';
    searchFormData: any;
    isLogin = true;
    public colorOptions = this.auth.getColorSelectionSettings();
    selectedItemObj: any;
    constructor(
        private itemsAPIService: ItemsAPIService,
        private fb: FormBuilder,
        private activeRoute: ActivatedRoute,
        public router: Router,
        private orderService: OrderService,
        private rtSharedService: RTSharedService,
        public dialogService: DialogService,
        public auth: AuthService,
        private msgService: MessageService,
        private basketItemService: BasketItemService,
        public companyService: CompanyService,
        private confirmationService: ConfirmationService,
        private dom: DomSanitizer
    ) {
        this.advanceOptions = [
            { label: 'All', value: { searchByName: 'all', key: 'all' } },
            { label: 'Name', value: { searchByName: 'name', key: 'name' } },
            { label: 'Description ', value: { searchByName: 'description', key: 'description' } },
            { label: 'Item #', value: { searchByName: 'item number', key: 'itemNumber' } },
            { label: 'UPC1', value: { searchByName: 'UPC1', key: 'upc' } },
            { label: 'UPC2', value: { searchByName: 'UPC2', key: 'upc2' } },
            { label: 'UPC3', value: { searchByName: 'UPC3', key: 'upc3' } }
        ];
        this.dateRangeOptions = [
            { label: 'Order Date From', value: { key: 'dateModifiedFrom', type: 'startDate' } },
            { label: 'Order Date to', value: { key: 'dateModifiedTo', type: 'endDate' } }
        ];
    }
    ngOnInit() {
        this.isLogin = this.auth.getIDToken();
        this.isShowDownload = this.domainData && this.domainData.enableItemImageDownload;
        if (sessionStorage.getItem('ItemAdvanceSearch') && sessionStorage.getItem('ItemAdvanceSearch') !== 'undefined') {
            this.searchFormData = JSON.parse(sessionStorage.getItem('ItemAdvanceSearch'));
            sessionStorage.removeItem('ItemAdvanceSearch');
        }
        if (this.domainData && this.auth.getProjectType() === 2) {
            this.stickyTop = document.getElementById('lightovation-header');
            this.headerHeight = '80px';
        } else {
            this.stickyTop = document.getElementById('b2bheader');
            this.headerHeight = '123px';
        }

        const projectName = this.rtSharedService.getProjectName();
        this.isQuickOrder = this.activeRoute.snapshot.queryParams.quick;
        this.showTable = projectName !== 'B2B';
        this.selectedView = 'TableView';
        // if (this.showTable) {
        // } else {
        //     // this.rows = (this.auth.getCartStyle()) ? 48 : 15;
        //     this.rows = (this.auth.getCartStyle() === 0) ? 30 : 48;
        //     this.tableEvent = { rows: this.rows, first: 1 };
        //     this.selectedView = 'GridView';
        // }
        // }
        this.searchItem.isAvailable = true;
        if (!this.pageType) {
            this.cols = [
                { field: 'itemNumber', header: 'Item #', width: '120px', spinner: true, sortField: 'itemNumber' },
                { field: 'name', header: 'Name', width: '150px', spinner: true, sortField: 'name' },
                {
                    field: 'repGroupManufacturerName',
                    header: 'Manufacturer',
                    width: '160px',
                    spinner: false,
                    sortField: ''
                },
                { field: 'upc1', header: 'UPC1', width: '120px', spinner: true, sortField: 'upc1' },
                // { field: 'upc2', header: 'UPC2', width: '106px', spinner: true, sortField: 'upc2' },
                // { field: 'upc3', header: 'UPC3', width: '106px', spinner: true, sortField: 'upc3' },
                // { field: 'nextAvailableDate', header: 'Next Available', width: '145px', spinner: true, sortField: 'nextAvailableDate' },
                // { field: 'minimumQuantity', header: 'Min.', width: '84px', spinner: true, sortField: 'minimumQuantity' },
                {
                    field: 'unitPrice',
                    header: 'Unit Price',
                    width: '120px',
                    spinner: true,
                    sortField: 'unitPrice',
                    class: 'price'
                },
                { field: 'category', header: 'Category', width: '120px', spinner: true, sortField: 'category' },
                // { field: 'quantity', header: 'Qty.', width: '50px', spinner: false, sortField: '' },
                // { field: 'total', header: 'Ext. Total', width: '85px', spinner: false, sortField: '', class: 'price' }
            ];
        }

        this.baseImageUrl = '';
        this.initSelectorItemTable = true;
    }

    ngAfterViewInit() {
        this.itemTableSubscribe = this.activeRoute.queryParams.subscribe((qp) => {
            if (!this.initSelectorItemTable && (!qp.itemdetails)) {
                this.loadingTableData = true;
                this.isResetLoading = true;
                const defaultEvent = { rows: this.rows, first: 0 };
                const queryParam = this.activeRoute.snapshot.queryParams;
                this.isQuickOrder = queryParam.quick;

                if (this.isQuickOrder !== 'true' || (this.isQuickOrder === 'true' && _.size(queryParam) > 1)) {
                    if (!this.pageType) {
                        this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
                    } else {
                    }
                } else {
                    this.items = [];
                    this.totalItemCount = 0;
                    this.loadingTableData = false;
                    this.isSubmitLoading = false;
                    this.isResetLoading = false;
                }
            } else {
                const queryParam = this.activeRoute.snapshot.queryParams;
                this.isQuickOrder = queryParam.quick;
                if (this.isQuickOrder === 'true' && _.size(queryParam) > 1) {
                    const qpObject = Object.assign({});
                    qpObject.quick = true;
                    this.router.navigate([`/shop`], { queryParams: qpObject });
                }
            }
            this.initSelectorItemTable = false;
        });
    }
    ngOnChanges(changes) {
        this.activeRoute.queryParams.subscribe((qp) => {
            this.activeRouteQP = qp;
        });
    }
    initSearchObj($event) {
        this.searchForm = $event;
        const defaultEvent = { rows: this.rows, first: 0 };
        this.searchFormData = _.cloneDeep($event);
        // this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
    }

    search($event) {
        this.searchFormData = _.cloneDeep($event);
        if (!this.loadingTableData) {
            this.isSubmitLoading = true;
            this.searchForm = $event;
            const defaultEvent = { rows: this.rows, first: 0 };
            //   this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
            if (!this.pageType) {
                this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
            } else {
                // this.loadItemsLazyPublic(defaultEvent, this.searchForm, this.selectedView);
            }
        }
    }

    reset($event) {
        this.searchFormData = _.cloneDeep($event);
        this.isResetLoading = true;
        this.searchForm = $event;
        const defaultEvent = { rows: this.rows, first: 0 };
        if (this.isQuickOrder !== 'true') {
            // this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
            if (!this.pageType) {
                this.loadItemsLazy(defaultEvent, this.searchForm, this.selectedView);
            } else {
                // this.loadItemsLazyPublic(defaultEvent, this.searchForm, this.selectedView);
            }
        } else {
            this.items = [];
            this.totalItemCount = 0;
            this.loadingTableData = false;
            this.isSubmitLoading = false;
            this.isResetLoading = false;
        }
    }

    // [B2B-232] (MG-08-APR-2020) modified to do ui hotfix
    loadItemsLazy(e, searchParams, view) {
        if (this.subscriptionItemsData) {
            this.subscriptionItemsData.unsubscribe();
        }

        if (!this.initSelectorItemTable || this.isQuickOrder !== 'true') {
            if (e.first !== 1) {
                window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            }
            this.tableEvent = e;
            if (view !== this.selectedView) {
                setTimeout(() => {
                    this.loadingTableData = false;
                });
                this.selectedView = view;
                return;
            } else {
                this.selectedView = view;
            }
            let size = e.rows;
            let offset = e.first;
            let sort = e.sortField;
            let sortOrder = e.sortOrder;
            const searchType = searchParams.searchType;
            if (size === undefined) {
                size = 20;
            }
            if (offset === undefined) {
                offset = 0;
            }
            if (sort === undefined) {
                sort = 'sequenceTopItem';
            }
            if (sortOrder === undefined) {
                sortOrder = 1;
            }
            const spID = this.rtSharedService.getSalesPersonID();
            if (searchParams.manufacturerID) {
                delete searchParams.manufacturerID;
            }
            if (this.repGroupID) {
                searchParams.repGroupID = this.repGroupID;
            }
            if (spID) {
                searchParams.salespersonID = spID;
            }
            // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
            if (this.activeRouteQP.g) {
                searchParams.repGroupCategoryGroupID = Number(this.activeRouteQP.g);
            } else {
                delete searchParams.repGroupCategoryGroupID;
            }
            // B2B-391 (RK 24-MAY-2021) => B2B and Marketplace Add color filter
            searchParams.colorIDSet = [];
            if (this.activeRouteQP.color && this.colorOptions && this.colorOptions.colorsList.length) {
                const colorModel = this.activeRouteQP.color.split(',');
                Object.keys(colorModel).forEach((k: any) => {
                    const mapData = this.colorOptions.colorsList.find(o => (o.colorlabel === colorModel[k]));
                    if (mapData) {
                        searchParams.colorIDSet.push(colorModel[k]);
                    }
                });
            }
            // B2B-81 (RK 17-FEB-2020): Update new Item list API in shopping page and pass relevant payload data
            searchParams.manufacturerIDSet = this.activeRouteQP.mfg ? this.activeRouteQP.mfg.split(',') : [];
            searchParams.calloutIDSet = this.activeRouteQP.callout ? this.activeRouteQP.callout.split(',') : [];
            _.forEach(searchParams.calloutIDSet, (element, index) => {
                searchParams.calloutIDSet[index] = +searchParams.calloutIDSet[index];
            });
            this.activeRouteQP.fromprice ? searchParams.priceFrom = this.activeRouteQP.fromprice : delete searchParams.priceFrom;
            this.activeRouteQP.toprice ? searchParams.priceTo = this.activeRouteQP.toprice : delete searchParams.priceTo;
            // B2B-268 (MJ 23-Jun-2020) Groups category filter added
            if (this.activeRouteQP.c) {
                const categoryIDSet = this.activeRouteQP.c.split(',');
                searchParams.repGroupCategoryIDSet = _.map(categoryIDSet, (o) => Number(o));
                // this.activeRouteQP.c ? searchParams.repGroupCategoryID = Number(this.activeRouteQP.c) : delete searchParams.repGroupCategoryID;
            } else {
                delete searchParams.repGroupCategoryIDSet;
            }
            this.activeRouteQP.s ? searchParams.repGroupSubCategoryID =
                Number(this.activeRouteQP.s) : delete searchParams.repGroupSubCategoryID;
            // MM-138 (RK 07-APRIL-2021) => B2B and Marketplace Group Redirection
            this.activeRouteQP.g || this.activeRouteQP.c || this.activeRouteQP.s ? searchParams.includeCatItems = true : delete searchParams.includeCatItems;
            this.activeRouteQP.new ? searchParams.daysLastModified =
                this.auth.getDaysLastModified() : delete searchParams.daysLastModified;

            this.activeRouteQP.bestseller ? searchParams.isBestSeller =
                this.auth.getbestSallerData() : delete searchParams.isBestSeller;

            if (searchParams.calloutIDSet && searchParams.calloutIDSet.length) {
                searchParams.assigned = true;
            } else {
                delete searchParams.assigned;
            }
            this.loadingTableData = true;
            this.shopItemShimmer = true;
            this.subscriptionItemsData = this.itemsAPIService.getb2baccessItems(searchParams, { offset, size, sort, sortOrder, searchType })
                .subscribe(async (response: APIResponse) => {
                    this.shopItemShimmer = false;
                    if (response.success) {
                        _.forEach(response.data, (d: any, key) => {
                            d.imageError = false;
                            d.imageLoader = true;
                            d.fullPicturePath = this.itemsAPIService.getFullPicturePath(d.picturePath, d.manufacturerID);
                            d.fullPicturePath64px = this.itemsAPIService.getFullPicturePathWithPX(d.picturePath, d.manufacturerID, 64);
                            d.fullPicturePath256px = this.itemsAPIService.getFullPicturePathWithPX(d.picturePath, d.manufacturerID, 256);
                        });
                        if ((searchParams.all !== null && searchParams.all !== '') && this.isQuickOrder) {
                            this.items = response.data;
                            this.totalItemCount = response.total;
                        } else {
                            this.items = [];
                            this.totalItemCount = 0;
                        }
                        this.items = _.map(_.cloneDeep(response.data), (o) => {
                            if (!o.showLockedB2bItems && (o.b2bItemPricingPolicy === 'NeedBrandApproval' || o.b2bItemPricingPolicy === 'HideItemPrice')) {
                                o.b2bItemPricingPolicyFlag = true;
                            } else {
                                o.b2bItemPricingPolicyFlag = false;
                            }
                            return o;
                        });
                        this.totalItemCount = response.total;
                        // }

                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 150);
                        // await this.setItemQtyTable();
                        // B2B-167 (CB 16-MAR-2020): Add quick order functionality
                        if (this.items.length === 1 && this.isQuickOrder) {
                            if (this.items[0].isc.length > 0) {
                            } else if (this.items[0].isc.length === 0) {
                                this.msgService.clear();
                                this.msgService.add({
                                    key: 'addItem',
                                    severity: 'info',
                                    summary: 'Item Already Added in Cart',
                                    detail: `${this.items[0].itemNumber} was already in cart.`
                                });
                            }
                        }

                        // ONLY ONE ADD
                        if (this.items && this.items.length && this.items.length === 1) {
                            const addItem: any = this.items[0];
                            if (addItem.isc && addItem.isc.length) {
                                setTimeout(() => {
                                    const elem = document.getElementById(`iscButton${addItem.recordID}`);
                                    elem.click();
                                }, 0);
                            } else {
                            }
                        }
                    } else {
                        this.totalItemCount = 0;
                        this.msgService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                        });
                    }
                    this.loadingTableData = false;
                    this.isSubmitLoading = false;
                    this.isResetLoading = false;
                }, error => {

                    this.loadingTableData = false;
                    this.shopItemShimmer = false;
                    this.totalItemCount = 0;
                    this.msgService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error occurred while updating table'
                    });
                });
        } else {
            setTimeout((res) => {
                this.items = [];
                this.totalItemCount = 0;
                this.loadingTableData = false;
                this.isSubmitLoading = false;
                this.isResetLoading = false;
                this.shopItemShimmer = false;
            }, 0);
        }
    }

    ngOnDestroy() {
        this.itemTableSubscribe.unsubscribe();
        this.subscriptionItemsData.unsubscribe();
    }
    onHeaderCheckboxToggle($event: any) {
        console.log($event, this.selecteditems);
    }
    SelectItemsChange(item, $event) {
        item.isChecked = $event;
        if ($event) {
            this.selecteditems.push(item);
        } else {
            const index = this.selecteditems.findIndex(o => o.recordID === item.recordID);
            this.selecteditems.splice(index, 1);
        }
    }

    itemload($event, searchForm, viewType) {
        this.loadItemsLazy($event, searchForm, viewType);
    }

    addTokens(url: string, params) {
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }

    selectItemData(itemData) {
        if (itemData) {
            this.selectedItemObj = itemData;
        }
    }

    getData() {
        if (this.selectedItemObj && this.fromPage === 'digital-catalog') {
            this.hidePopup.emit({ 'selectionData': this.selectedItemObj });
        } else {
            this.selecteditems = [];
            // this.selectedItemsStore = [];
            this.hidePopup.emit({ 'selectionData': this.selecteditems });
        }
    }
}
