import { Subject, Subscription } from 'rxjs';
import {
    Component, EventEmitter, OnInit, Output, HostListener, OnDestroy, QueryList, ViewChildren,
    AfterViewInit, AfterViewChecked
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ConfirmationService, MessageService, DialogService } from 'primeng/api';
import {
    Router, ActivatedRoute, NavigationEnd, NavigationStart, GuardsCheckEnd, GuardsCheckStart,
    RouterPreloader, ActivationEnd, ResolveEnd, NavigationCancel, RoutesRecognized
} from '@angular/router';
import * as objectPath from 'object-path';
import * as _ from 'lodash';
import { AuthService } from './services/middleware/auth/auth.service';
import { CompanyService } from './services/api/company.service';
import { ReptimeEntity } from 'src/app/models/reptime-entity';
import { RetailerSelectedAddress } from 'src/app/models/retailer-selected-address';
import { RepgroupService } from './services/api/repgroup.service';
import { CategoryGroups, CategoryGroupsList } from 'src/app/models/repgroup-group';
import { Renderer2, ViewChild, ElementRef } from '@angular/core';
import { BasketItemService } from './services/middleware/basket-item/basket-item.service';
import { RTSharedService } from './services/rtshared/rtshared.service';
import { LikeListDialogComponent } from './modals/like-list-dialog/like-list-dialog.component';
import { SidebarApiService } from './services/api/sidebar-api/sidebar-api.service';
import $ from 'jquery';
import AOS from 'aos';
import { APIResponse } from './models/api-response';
import { pairwise, takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'ng-connection-service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { AuthAPIService } from './services/api/auth-api/auth-api.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    public items: MenuItem[];
    public loggedInUser: any;
    win: any = (window);
    public domainData: any = this.win.domainData;
    public logo: string;
    public showSidebarArray = ['/shop', '/store'];
    public hideShopButtonArray = ['/shop', '/finalize-order'];
    public hasSidebar = false;
    public hasShopPage = false;
    public hasShopButton = true;
    public navigatedURL: any;
    public reptimeEntity = ReptimeEntity;
    public retailerSelectedAddress: any = {};
    public activeRouteQP: any;
    public qpObject: any;
    public groups: CategoryGroupsList[];
    public groupsMaster: CategoryGroupsList[];
    public fullPageLoader = false;
    public isIframeWindow: any = (window as any).iframeData;
    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    @ViewChild('customStylesHash', { static: false }) customStylesHash: ElementRef;
    customStyleObj = '';
    // [B2B-115] (RM 06-03-2020): wish list variable
    public wishListItemCount = 0;
    public cartItemSubscription: Subscription;
    public wishListItemSubscription: Subscription;
    public retailerDataSubscription: Subscription;
    public wishListItem = [];
    public wishListItemAllow = true;
    public notApprovedByRepGroup = true;
    public logoClickURL: any = '/login';
    public saveForShowAddress: Subscription;
    hidemenutoggle = true;
    shiplocationNot = false;
    isInternetActive = true;
    // @ViewChildren('getmenu_width') getmenu_width: QueryList<any>;
    // @ViewChildren('menubutton') menubutton: QueryList<any>;

    public mobMenuList: MenuItem[];
    saveLocation: any[] = [];
    public FinalSelectLocation: any;
    public menuShimmerAction: boolean;
    public getFontText: any;
    public cartItemCount = 0;
    interval;
    header: any;
    entityType = 'auth';
    ipAddress: any;
    private readonly onDestroyLoginLogout = new Subject<void>();
    private readonly onDestroy1 = new Subject<void>();
    private readonly getAuthRetailer = new Subject<void>();
    private readonly onDestroyGetRetailerData = new Subject<void>();
    mtPayAvilableForRetailer: boolean;
    constructor(
        private router: Router,
        public rtSharedService: RTSharedService,
        private basketItemService: BasketItemService,
        private activeRoute: ActivatedRoute,
        public auth: AuthService,
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private repGroupService: RepgroupService,
        private msgService: MessageService,
        private renderer: Renderer2,
        public dialogService: DialogService,
        private sidebarApiService: SidebarApiService,
        private confirmationService: ConfirmationService,
        public connectionService: ConnectionService,
        public titleService: Title,
        private http: HttpClient,
        private authAPI: AuthAPIService,
    ) {
        this.connectionService.monitor().subscribe(isConnected => {
            console.log('ddd', isConnected);
            if (isConnected) {
                this.isInternetActive = true;
            } else {
                this.isInternetActive = false;
            }
        });

        this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                if (!events[0].urlAfterRedirects.includes('itemdetails')) {
                    sessionStorage.setItem('old-link', events[0].urlAfterRedirects);
                    sessionStorage.setItem('current-link', events[1].urlAfterRedirects);
                    sessionStorage.setItem('old-link-params', JSON.stringify(events[0].state['root'].queryParams));
                }
            });

        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.entityType = route.root.firstChild.snapshot.data.entityType;
        });
        this.header = this.auth.getProjectType()
        /*B2B-153 (RK 07-MARCH-2020): Bug : Rejected B2B user show all header option*/
        this.rtSharedService.isRemoveToken.subscribe((data: any = true) => {
            if (data) {
                this.hasSidebar = false;
                this.auth.removeAllToken();
                // this.router.navigate(['/login']);
                this.auth.moveToAfterLogin();
            }
        });
        const rg: any = this.domainData ? this.domainData.repGroup : '';
        if (rg) {
            if (document.querySelector('#appTitle').innerHTML.includes('Online')) {
                this.titleService.setTitle(rg.name + ' ' + document.querySelector('#appTitle').innerHTML.replace('Online', ''));
            }
        }

        let getRepGroupRetailer: any;
        this.onDestroyGetRetailerData.next();
        this.retailerDataSubscription = this.auth.getRetailerData.pipe(takeUntil(this.onDestroyGetRetailerData)).subscribe((getRetailerRes) => {
            console.log('94444', getRetailerRes, this.router.url);
            // if (this.router.url === '/' ) {
            //     this.notApprovedByRepGroup = true;
            //     this.setTopMenuUnApprovedUser();
            // }
            if (getRetailerRes && _.isArray(getRetailerRes.repGroupRetailers)) {
                console.log('96666666666');
                this.shiplocationNot = false;
                this.FinalSelectLocation = this.rtSharedService.getSelectedShipTo();

                if (getRetailerRes.shipToLocations.length > 0 && getRetailerRes.shipToLocations !== undefined) {
                    if (!this.FinalSelectLocation) {
                        let primaryList = getRetailerRes.shipToLocations.find(item => item.isPrimary === true);
                        primaryList = primaryList ? primaryList : getRetailerRes.shipToLocations[0];
                        this.FinalSelectLocation = primaryList;
                        const sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
                        const abendantCartData = JSON.parse(sessionStorage.getItem('abandoned_carts')).retailerShipToLocationID;
                        let filterShipFromAbendantData;
                        if (abendantCartData) {
                            filterShipFromAbendantData = _.filter(getRetailerRes.shipToLocations, (data, index) => {
                                return _.toInteger(data.recordID) === _.toInteger(abendantCartData);
                            })[0];
                        }
                        if (sessionLocationId === null) {
                            this.FinalSelectLocation = (filterShipFromAbendantData) ? filterShipFromAbendantData : this.FinalSelectLocation;
                            sessionStorage.setItem('retailerShipToLocationID', JSON.stringify(this.FinalSelectLocation));
                            this.rtSharedService.setSelectedShipTo(this.FinalSelectLocation);
                        } else {
                            const x = getRetailerRes.shipToLocations.find(o => +o.recordID === +sessionLocationId.recordID);
                            this.rtSharedService.setSelectedShipTo(x);
                        }
                    }
                } else {
                    console.log('shiplocationNot 113');

                    // if (this.router.url !== '/shipping-location-not-found' ) {
                    //     console.log('116 if');
                    //     this.shiplocationNot = true;
                    //     console.log('shipToLocations>>>>', this.router.url);
                    //     this.notApprovedByRepGroup = true;
                    //     this.setTopMenuUnApprovedUser();
                    // } else {
                    //     console.log('122 else');
                    //     this.notApprovedByRepGroup = false;
                    //     this.setTopMenuApprovedUser();
                    // }
                }

                if (getRetailerRes.shipToLocations.length === 0) {
                    this.logoClickURL = '/shipping-location-not-found';
                    this.notApprovedByRepGroup = true;
                    this.setTopMenuUnApprovedUser();
                } else if (getRetailerRes.repGroupRetailers.length) {
                    getRepGroupRetailer = _.find(getRetailerRes.repGroupRetailers, (o) =>
                        o.repGroupID === (rg && rg.recordID));
                    if (getRepGroupRetailer && (getRepGroupRetailer.approvedByRepGroup === 0 ||
                        getRepGroupRetailer.approvedByRepGroup === 2)) {
                        console.log('getRepGroupRetailer 2');
                        if (getRepGroupRetailer.approvedByRepGroup === 0) {
                            console.log('getRepGroupRetailer 0');
                            this.logoClickURL = '/rep-group-msg-pending';
                        } else if (getRepGroupRetailer.approvedByRepGroup === 2) {
                            console.log('getRepGroupRetailer 22');
                            this.logoClickURL = '/rep-group-access-denied';
                        } else {
                            console.log('getRepGroupRetailer ');
                            this.logoClickURL = '/login';
                        }
                        this.notApprovedByRepGroup = true;
                        this.setTopMenuUnApprovedUser();
                        // return false;
                    } else if (getRepGroupRetailer && getRepGroupRetailer.recordDeleted) {
                        console.log('rep-retailer-notfound app.com');
                        this.logoClickURL = '/rep-retailer-notfound';
                        this.notApprovedByRepGroup = true;
                        this.setTopMenuUnApprovedUser();
                    } else if (getRepGroupRetailer && getRepGroupRetailer.approvedByRepGroup === 1) {
                        console.log('getRepGroupRetailer 11');
                        this.logoClickURL = '/home';
                        // [B2B-212] (RM 30-03-2020): When login retailer that time call wishlist api
                        if (this.wishListItemAllow) {
                            // if ( this.router.url === '/home') {
                            this.updateWishListCount();
                            this.wishListItemAllow = false;
                            // }

                        } // END
                        this.notApprovedByRepGroup = false;
                        this.setTopMenuApprovedUser();
                        // return false;
                    } else {
                        console.log('getRepGroupRetailer nothing');

                        /*when registered buyer login from other repgrup domian*/
                        this.logoClickURL = '/rep-group-msg';

                        this.notApprovedByRepGroup = true;
                        this.setTopMenuUnApprovedUser();
                        // return false;
                    }
                }
                this.getRepGroupRetailers(getRetailerRes);
            }
        });
        this.cartItemSubscription = this.auth.getCartItemCountEvent().subscribe(res => {
            try {
                this.cartItemCount = res;
            } catch (e) {
            }
            if (getRepGroupRetailer && getRepGroupRetailer.approvedByRepGroup === 1) {
                this.setTopMenuApprovedUser();
            }
        });
        this.wishListItemSubscription = this.basketItemService.getWishListClickEvent().subscribe(data => {
            if (getRepGroupRetailer && getRepGroupRetailer.approvedByRepGroup === 1) {
                // this.updateWishListCount();
                this.getWishListCount(this.retailerSelectedAddress.recordID);
            }
        });
        this.logo = this.domainData ? this.companyService.getLogoPath() + '?' + new Date().getTime() : '';
        // B2B-74 (RK 13-FEB-2020): Sidebar show conditionally
        // B2B-102 (NL 26-FEB-2020): add fullPageLoader boolean varialble
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.fullPageLoader = true;
                this.logoClickURL = '/login';
                /*
                if (val.url.indexOf('/vendor') > -1 && val.url.indexOf('/vendors') !== 0) {
                    this.router.routeReuseStrategy.shouldReuseRoute = (res)=> {
                        return false;
                    }
                }*/
            }
            if (val instanceof NavigationCancel) {
                this.fullPageLoader = false;
            }
            if (val instanceof NavigationEnd) {
                $('.item-menu-bar, .menu-overlay').removeClass('active');
                $('.item-menu-inner').removeClass('menu-show');
                $('body').removeClass('action');
                this.fullPageLoader = false;
                this.hasSidebar = false;
                this.hasShopPage = false;
                this.showSidebarArray.forEach(k => {
                    if (val.url.indexOf(k) > -1) {
                        this.hasSidebar = true;
                        console.log('TEST', val.url);
                        this.navigatedURL = val.url;
                        return;
                    }
                });
                if (val.url.indexOf('/shop') > -1) {
                    this.hasShopPage = true;
                }
                /*if (this.router.routeReuseStrategy.shouldReuseRoute) {
                    delete this.router.routeReuseStrategy.shouldReuseRoute;
                }*/
                // B2B-75 (RK 14-FEB-2020): Show new order button conditionally
                this.hasShopButton = true;
                this.hideShopButtonArray.forEach(k => {
                    if ((val.url.indexOf(k) > -1)) {
                        this.hasShopButton = false;
                        console.log('TEST 1', val.url);
                        this.navigatedURL = val.url;
                        return;
                    }
                });
            }
        });
        this.activeRoute.queryParams.subscribe((qp) => {
            this.activeRouteQP = qp;
        });
        this.resetColor();
        if (JSON.parse(sessionStorage.getItem('retailerShipToLocationID'))) {
            this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        } else { }
        this.saveForShowAddress = this.rtSharedService.getSelectedShipToEvent().subscribe((e) => {
            this.retailerSelectedAddress = e;
            this.setTopMenuApprovedUser();
            this.notApprovedByRepGroup = false;
            if (this.router.url === '/home') {
                this.updateWishListCount();
                this.setTopMenuApprovedUser();
            }

        });
    }
    // getShipmentAddress() {
    // }
    ngOnDestroy() {
        this.onDestroy1.next();
        this.getAuthRetailer.next();
        this.onDestroyGetRetailerData.next();
        this.onDestroyLoginLogout.next();
        this.cartItemSubscription.unsubscribe();
        this.wishListItemSubscription.unsubscribe();
        this.retailerDataSubscription.unsubscribe();
        this.saveForShowAddress.unsubscribe();
        this.items = [];
        this.auth.onDestroy.next();
        clearInterval(this.interval);
    }
    ngAfterViewInit() {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.auth.callAPILogs();
        }, (+(30) * 1000));
    }
    resetColor() {
        this.customStyleObj = '';
        this.customStyleObj += '.dynamic-theme, .footer-dynamic-theme, .theme-maroon-btn ';
        this.customStyleObj += '{background-color: #PRIMARYCOLOR# !important; color: #TEXTCOLOR# !important;} ';
        this.customStyleObj += '.theme-maroon-btn {border: 1px solid #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.btn-theme-color {background-color: #SECONDARYCOLOR# !important; ';
        this.customStyleObj += 'border: 1px solid #SECONDARYCOLOR# !important; color: #TEXTCOLOR# !important;} ';
        this.customStyleObj += '.shopping-cart-top.ui-menuitem-link .ui-menuitem-icon {color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.main-category-block ul li:hover .m-category-list, .sub-category-block .m-subcategory-list:hover';
        // this.customStyleObj += '.main-category-block ul li:hover .m-category-list, .sub-category-block .m-subcategory-list:hover, .sub-sub-category-block .m-subsubcategory-list:hover';
        this.customStyleObj += ' {color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.main-group-clr, .main-group-clr:hover{color: #TEXTCOLOR# !important} ';
        this.customStyleObj += '.hr-category-menu a.main-group-clr:after{background: #TEXTCOLOR# !important} ';
        this.customStyleObj += 'body .ui-dialog .ui-dialog-titlebar{background-color: #PRIMARYCOLOR# !important;';
        this.customStyleObj += 'border: 1px solid #PRIMARYCOLOR# !important} ';
        this.customStyleObj += '.register-panel-bg .ui-panel-titlebar{background-color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.regi-title{color: #SECONDARYCOLOR# !important;} ';
        this.customStyleObj += 'body,body .ui-widget,textarea {font-family: #FONTFAMILY# !important;}';
        this.customStyleObj += '.p-cat-theme{color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.mob-bar-menu a{color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += 'body .ui-panel .ui-panel-titlebar{background-color: #PRIMARYCOLOR# !important;border: 1px solid  #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.theme-color{color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.manage-tab-clr .ui-tabview-nav .ui-state-default.ui-state-active{background-color: #PRIMARYCOLOR# !important;border:1px solid #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.manage-tab-clr .ui-tabview-nav .ui-state-default{background-color: #SECONDARYCOLOR# !important;border:1px solid #SECONDARYCOLOR# !important;} ';
        this.customStyleObj += '.manage-tab-clr .ui-tabview-nav .ui-state-default a,.whishlist-count,.cart-count{color: #TEXTCOLOR# !important} ';
        this.customStyleObj += '.header-topbar{background-color: #PRIMARYCOLOR# !important; color: #TEXTCOLOR# !important;} ';
        this.customStyleObj += '.main-banner-sec .left-text-sec:after{background-color: #SECONDARYCOLOR# !important;} ';
        this.customStyleObj += '.theme-maroon-btn.dynamic-hover:hover{border-color: #PRIMARYCOLOR# !important;color: #PRIMARYCOLOR# !important;background:transparent !important;} ';
        this.customStyleObj += '.btn-theme-color.dynamic-hover:hover{border-color: #SECONDARYCOLOR# !important;color: #SECONDARYCOLOR# !important;background:transparent !important;} ';
        this.customStyleObj += '.magzine-disp-layer:after,.show-upnext-right,.show-left-video-content{background-color: #SECONDARYCOLOR# !important;} ';
        this.customStyleObj += '.magzine-disp-right-inner h2,.magzine-disp-right-inner h3,.magzine-disp-right-inner p,.magzine-disp-right-inner a{color: #TEXTCOLOR# !important;} ';
        this.customStyleObj += '.whishlist-count,.cart-count,.title-highlights:after,.coming-up-slider-main h2:after,.get-social-title h2:after{background-color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.user-dropdown-menu.list-menu li a:hover{background-color: #SECONDARYCOLOR# !important;color: #TEXTCOLOR# !important} ';
        this.customStyleObj += '#close_sidebar, #close_suggested_items, #close_checkout{background-color: #SECONDARYCOLOR# !important;} ';
        this.customStyleObj += '.amt-wrap span {background-color: #PRIMARYCOLOR# !important; color: #TEXTCOLOR# !important;} ';
        this.customStyleObj += '.amtmeet-total:after {background-color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += '.amtmeet-total {color: #PRIMARYCOLOR# !important; border-color: #PRIMARYCOLOR# !important;} ';
        this.customStyleObj += 'p-button.shoping-btn .ui-button .ui-button-icon-left {color: #TEXTCOLOR# !important;} ';
        this.customStyleObj += '.currency-icon {color: #PRIMARYCOLOR# !important;} ';

        // this.customStyleObj += '.shopping-cart-top.heart-cnt .ui-menuitem-text {background-color: #PRIMARYCOLOR# !important;}';p-cat-theme 
    }

    // [RC-695] (RM 25-03-2020) Max group list display depends on device width
    // @HostListener('window:resize', ['$event'])
    // onResize(event) {
    //     this.getCategoryMenuData(event.target.innerWidth);
    // }

    @HostListener('window:scroll', []) // for window scroll events
    onScroll() {
        if (window.pageYOffset > 50) {
            for (var i = 0; i < document.getElementsByClassName('sticky-basket').length; i++) {
                const element: any = document.getElementsByClassName('sticky-basket')[i];
                if (element) {
                    element.classList.add('basket-sticky');
                }
            }
            // const element: any = document.getElementsByClassName('sticky-basket')[0];
            // if (element) {
            //     element.classList.add('basket-sticky');
            // }
        } else {
            for (var i = 0; i < document.getElementsByClassName('sticky-basket').length; i++) {
                const element: any = document.getElementsByClassName('sticky-basket')[i];
                if (element) {
                    element.classList.remove('basket-sticky');
                }
            }
            // const element = document.getElementsByClassName('sticky-basket')[0];
            // if (element) {
            //     element.classList.remove('basket-sticky');
            // }
        }
    }

    // [RC-695] (RM 25-03-2020) Max group list display depends on device width
    // getCategoryMenuData(mainWidth) {
    //     // [B2B-234] (MK 13-04-2020) group display in one line
    //     const interval = setInterval(() => {
    //         if (this.getmenu_width['_results'].length !== 0 && interval) {
    //             let totalcalculatedwidth = window.innerWidth > 1780 ? 1780 : window.innerWidth;
    //             totalcalculatedwidth = totalcalculatedwidth - (17 + 50);
    //             let currentwidth = $(this.menubutton['_results'][0].nativeElement).outerWidth(true);
    //             this.hidemenutoggle = true;
    //             this.groups.forEach((d, i) => {
    //                 currentwidth = currentwidth + $(this.getmenu_width['_results'][i].nativeElement).outerWidth(true);
    //                 d['isHide'] = totalcalculatedwidth >= currentwidth ? false : true;
    //                 this.hidemenutoggle = ((this.groups.length - 1) === i) &&
    //                     totalcalculatedwidth < currentwidth ? false : true;
    //             });
    //             clearInterval(interval);
    //         }
    //         this.fullPageLoader = false;
    //     }, 1000);
    // }

    ngOnInit() {
        if (this.auth.getProjectType() === 0 && window.location.hostname.toLocaleLowerCase().includes('markettime')) {
            window.location.replace(environment.marketTimeURL);
        }
        // let name: string = null;
        this.groups = [];
        this.groupsMaster = [];
        // [B2B-217] (RK 31-03-2020): Fix reflect groups lisiting issue;
        // this.repGroupService.currentData.subscribe(groups => this.groups= groups);
        this.route.paramMap.subscribe(params => {
        });
        this.getAuthRetailer.next();
        this.auth.getAuthRetailer.pipe(takeUntil(this.getAuthRetailer)).subscribe((userInfo: any) => {
            // [B2B-212] (RM 30-03-2020): When retailer logout that time variable reset
            if (userInfo === null) { this.wishListItemAllow = true; this.wishListItem = []; }
            this.loggedInUser = userInfo && userInfo.parent;
            if (this.loggedInUser && this.notApprovedByRepGroup) {
                // B2B-73 (RK 24-FEB-2020): Get group listing
            } else if (this.loggedInUser && !this.notApprovedByRepGroup) {
                this.notApprovedByRepGroup = true;
                this.setTopMenuUnApprovedUser();
            } else {
                if (this.isIframeWindow) {
                    this.setTopMenuApprovedUser();
                } else {
                    // this.items = [{
                    //     label: 'Welcome', icon: 'fa fa-fw fa-users', disabled: this.isIframeWindow ? true : false,
                    //     command: () => {
                    //         if (!this.isIframeWindow) {
                    //             this.router.navigate(['/login']);
                    //         }
                    //     }
                    // }];
                    this.items = [];
                }
            }
        });
        this.updateThemeColors();

        // mobile menu array
        this.mobMenuList = [{
            // label: `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`,
            // icon: 'fa fa-user',
            items: [
                {
                    // tslint:disable-next-line:max-line-length
                    label: (!this.auth.getdefaultShopbyBrandsButtonFlag() ? this.auth.getCustomShopbyBrandsButtonData().text : 'Shop by Brand'), disabled: this.isIframeWindow ? true : false,
                    // tslint:disable-next-line:max-line-length
                    icon: (!this.auth.getdefaultShopbyBrandsButtonFlag() ? 'fas ' + this.auth.getCustomShopbyBrandsButtonData().iconClass : 'fa fa-fw fa-shopping-basket'),
                    command: () => {
                        this.router.navigateByUrl('/vendors');
                    }
                },
                {
                    // tslint:disable-next-line:max-line-length
                    label: (!this.auth.getdefaultShopbyItemButtonFlag() ? this.auth.getCustomShopbyItemButtonData().text : 'Shop by Items'), disabled: this.isIframeWindow ? true : false,
                    // tslint:disable-next-line:max-line-length
                    icon: (!this.auth.getdefaultShopbyItemButtonFlag() ? 'fas ' + this.auth.getCustomShopbyItemButtonData().iconClass : 'fa fa-fw fa-tags'),
                    command: () => {
                        this.router.navigateByUrl('/shop');
                    }
                },
                // {
                //     label: 'Quick Order',
                //     icon: 'fa fa-fw fa-pencil-square-o',
                //     command: () => {
                //          this.router.navigateByUrl('/shop?quick=true');
                //     }
                // },
                {
                    label: 'Category', disabled: this.isIframeWindow ? true : false,
                    icon: 'fa fa-list',
                    command: () => {
                        this.router.navigateByUrl('/category');
                    }
                },
                {
                    label: (!this.auth.getdefaultPromotionButtonFlag() ? this.auth.getCustomPromotionButtonData().text : 'Promotions'),
                    disabled: this.isIframeWindow ? true : false,
                    visible: this.auth.getshowPromotionsButtonFlag(),
                    icon: (!this.auth.getdefaultPromotionButtonFlag() ? 'fas ' + this.auth.getCustomPromotionButtonData().iconClass : 'fas fa-bullhorn'),
                    command: () => {
                        if (this.auth.getdefaultPromotionButtonFlag()) {
                            this.router.navigateByUrl('/promotions');
                        } else {
                            window.open(this.auth.getCustomPromotionButtonData()['url'], '_blank');
                        }
                    }
                },
                {
                    label: (!this.auth.getdefaultCatalogButtonFlag() ? this.auth.getCustomCatalogButtonData().text : 'Catalogs'), disabled: this.isIframeWindow ? true : false,
                    icon: (!this.auth.getdefaultCatalogButtonFlag() ? 'fas ' + this.auth.getCustomCatalogButtonData().iconClass : 'fas fa-swatchbook'),
                    command: () => {
                        if (this.auth.getdefaultCatalogButtonFlag()) {
                            this.router.navigate([`/vendors`], { queryParams: { catalogs: true } });
                        } else {
                            window.open(this.auth.getCustomCatalogButtonData()['url'], '_blank');
                        }
                    }
                },
                {
                    label: 'Profile', disabled: this.isIframeWindow ? true : false,
                    icon: 'fa fa-info-circle',
                    command: () => {
                        this.router.navigateByUrl('/profile');
                    }
                },
                {
                    label: 'Change Password', disabled: this.isIframeWindow ? true : false,
                    icon: 'fas fa-user',
                    command: () => {
                        this.router.navigateByUrl('/change-password');
                    }
                },
                {
                    label: 'Manage Company', disabled: this.isIframeWindow ? true : false,
                    icon: 'fa fa-th-list',
                    command: () => {
                        this.router.navigateByUrl('/manage-company');
                    }
                },
                {
                    label: 'mtServices', disabled: this.isIframeWindow ? true : false,
                    icon: 'fas fa-concierge-bell',
                    command: () => {
                        sessionStorage.setItem('openMtPayFromDD', 'true');
                        this.router.navigateByUrl('/manage-company');
                    }
                },
                {
                    label: 'Help & Support', disabled: this.isIframeWindow ? true : false,
                    icon: 'fas fa-question-circle',
                    command: () => {
                        this.gotoHelpSupport();
                    }
                },

                {
                    label: 'Logout', disabled: this.isIframeWindow ? true : false,
                    icon: 'fa fa-fw fa-sign-out',
                    command: () => {
                        this.auth.callAPILogs(true);
                        let sessionLoginDetails = JSON.parse(sessionStorage.getItem('allLoginCompanyID'));
                        console.log('sessionLoginDetails 123', sessionLoginDetails);
                        if (sessionLoginDetails && sessionLoginDetails.length) {
                            let i = 0;
                            for (i; i < sessionLoginDetails.length; i++) {
                                this.saveUserLoginInfo(sessionLoginDetails[i], 'logout');
                            }
                        }
                        if (!this.rtSharedService.getRepgroupOrderStatus()) {
                            this.hasSidebar = false;
                            this.auth.removeAllToken();
                        }
                        this.router.navigate(['/login']);
                        // this.saveForShowAddress.unsubscribe();
                        // this.cartItemSubscription.unsubscribe();
                        // this.wishListItemSubscription.unsubscribe();
                        // this.retailerDataSubscription.unsubscribe();
                        // this.rtSharedService.setRepgroupOrderStatus(false);
                    }
                },
            ]
        }];

        // hide change password when showLogin is off/false PPP
        if (!this.auth.getEnableProfileEditing()) {
            console.log('debug_ edit here');
            _.remove(this.mobMenuList[0].items, (el) => {
                return el.label === 'Change Password';
            });
            console.log('debug_ value', _.cloneDeep(this.items));
        }

        this.getIPAddress();
        if (!this.mtPayAvilableForRetailer && this.mobMenuList.length) {
            _.remove(this.mobMenuList[0].items, (el) => {
                return el.label === 'mtServices';
            });
            return;
        }
        // B2B-251 (BM 12-JUN-2020) animation call
        // AOS.init({
        //     duration: 1200,
        // });
    }
    setTopMenuApprovedUser() {
        this.fullPageLoader = false;
        this.items = [{
            label: 'Dashboard', icon: 'fa fa-fw fa-home', disabled: this.isIframeWindow ? true : false,
            command: () => {
                this.router.navigateByUrl('/home');
            }
        },
        {
            label: `${this.isIframeWindow ? 'Jane Doe' : this.loggedInUser.firstName + this.loggedInUser.lastName}`,
            icon: 'fa fa-fw fa-user',
            items: [
                {
                    label: 'Profile', disabled: this.isIframeWindow ? true : false,
                    icon: 'fa fa-info-circle',
                    command: () => {
                        this.router.navigateByUrl('/profile');
                    }
                },
                {
                    label: 'Change Password', disabled: this.isIframeWindow ? true : false,
                    icon: 'fas fa-user',
                    command: () => {
                        this.router.navigateByUrl('/change-password');
                    }
                },
                {
                    label: 'Manage Company', disabled: this.isIframeWindow ? true : false,
                    icon: 'fa fa-th-list',
                    command: () => {
                        this.router.navigateByUrl('/manage-company');
                    }
                },
                {
                    label: 'mtServices', disabled: this.isIframeWindow ? true : false,
                    icon: 'fas fa-concierge-bell',
                    command: () => {
                        sessionStorage.setItem('openMtPayFromDD', 'true');
                        this.router.navigateByUrl('/manage-company');
                    }
                },
                {
                    label: 'Help & Support', disabled: this.isIframeWindow ? true : false,
                    icon: 'fas fa-question-circle',
                    command: () => {
                        this.gotoHelpSupport();
                    }
                },
                {
                    label: 'Logout', disabled: this.isIframeWindow ? true : false,
                    icon: 'fa fa-fw fa-sign-out',
                    command: () => {
                        this.auth.callAPILogs(true);
                        let sessionLoginDetails = JSON.parse(sessionStorage.getItem('allLoginCompanyID'));
                        console.log('sessionLoginDetails 123', sessionLoginDetails);
                        if (sessionLoginDetails && sessionLoginDetails.length) {
                            let i = 0;
                            for (i; i < sessionLoginDetails.length; i++) {
                                this.saveUserLoginInfo(sessionLoginDetails[i], 'logout');
                            }
                        }
                        if (!this.rtSharedService.getRepgroupOrderStatus()) {
                            this.hasSidebar = false;
                            this.auth.removeAllToken();
                        }
                        this.router.navigate(['/login']);
                        // this.saveForShowAddress.unsubscribe();
                        // this.cartItemSubscription.unsubscribe();
                        // this.wishListItemSubscription.unsubscribe();
                        // this.retailerDataSubscription.unsubscribe();
                        // this.rtSharedService.setRepgroupOrderStatus(false);
                        // this.router.navigate(['/login']);
                    }
                }
            ]
        },
        {
            label: ` `,
            icon: 'fas fa-map-marker-alt ', disabled: this.isIframeWindow ? true : false,
            styleClass: 'remove-dropdown-icon add-tooltip-text',
            items: [
                {
                    label: this.isIframeWindow ? 'Sample Buyer - 1234 Everywhere St. - Austin, TX 78705' :
                        (this.retailerSelectedAddress && this.retailerSelectedAddress.name !== undefined) ?
                            this.retailerSelectedAddress.name + ' - ' +
                            this.retailerSelectedAddress.address1 + ', ' +
                            this.retailerSelectedAddress.city + ', ' +
                            this.retailerSelectedAddress.state + ', ' +
                            this.retailerSelectedAddress.country + ', ' +
                            this.retailerSelectedAddress.zip : '',
                    icon: '',
                    styleClass: 'remove-dropdown-item',
                },
            ]
        },
        {
            label: `${this.isIframeWindow ? 0 : this.wishListItemCount}`, disabled: this.isIframeWindow ? true : false,
            icon: 'fa fa-heart', styleClass: 'shopping-cart-top heart-cnt',
            command: () => {
                this.likeListDialog();
            }
        },
        {
            label: `${this.isIframeWindow ? 0 : this.getCartItemCount()}`, disabled: this.isIframeWindow ? true : false,
            icon: 'fa fa-shopping-cart', styleClass: 'shopping-cart-top ml-7 item-cnt',
            command: () => {
                if (this.auth.getCartStyle() === 3 || this.auth.getCartStyle() === 4) {
                    this.router.navigate(['/cart'])
                } else if (!_.includes(this.router.url, '/shop')) {
                    this.router.navigateByUrl('/shop').then((res) => {
                        this.basketItemService.setCartClickEvent(true);
                    });
                } else {
                    this.basketItemService.setCartClickEvent(true);
                }
            }
        }];

        // hide change password when showLogin is off/false PPP
        if (!this.auth.getEnableProfileEditing()) {
            console.log('debug_ edit here');
            const x = _.find(this.items, (o) => {
                _.remove(o.items, (el) => {
                    return el.label === 'Change Password';
                });
            });
            console.log('debug_ value', _.cloneDeep(this.items));
        }

        if (!this.mtPayAvilableForRetailer) {
            const x = _.find(this.items, (o) => {
                if (o.icon === 'fa fa-fw fa-user' && o.items) {
                    _.remove(o.items, (el) => {
                        return el.label === 'mtServices';
                    });
                }
                return;
            });
        }
    }
    /*B2B-153 (RK 07-MARCH-2020): Bug : Rejected B2B user show all header option*/
    setTopMenuUnApprovedUser() {
        console.log('setTopMenuUnApprovedUser');

        this.items = [{
            label: `${this.isIframeWindow ? 'Jane Doe' : this.loggedInUser.firstName + this.loggedInUser.lastName}`,
            icon: 'fa fa-fw fa-user',
            items: [
                {
                    label: 'Logout',
                    icon: 'fa fa-fw fa-sign-out',
                    disabled: this.isIframeWindow ? true : false,
                    command: () => {
                        if (!this.isIframeWindow) {
                            this.auth.callAPILogs(true);
                            let sessionLoginDetails = JSON.parse(sessionStorage.getItem('allLoginCompanyID'));
                            console.log('sessionLoginDetails 123', sessionLoginDetails);
                            if (sessionLoginDetails && sessionLoginDetails.length) {
                                let i = 0;
                                for (i; i < sessionLoginDetails.length; i++) {
                                    this.saveUserLoginInfo(sessionLoginDetails[i], 'logout');
                                }
                            }
                            if (!this.rtSharedService.getRepgroupOrderStatus()) {
                                this.hasSidebar = false;
                                this.auth.removeAllToken();
                            }
                            this.router.navigate(['/login']);
                            // this.saveForShowAddress.unsubscribe();
                            // this.cartItemSubscription.unsubscribe();
                            // this.wishListItemSubscription.unsubscribe();
                            // this.retailerDataSubscription.unsubscribe();
                            // this.rtSharedService.setRepgroupOrderStatus(false);
                        }
                    }
                }
            ]
        }];
    }
    getQpObject(catlist, subcatlist?: any) {
        this.qpObject = Object.assign({}, this.activeRouteQP);
        // this.qpObject.group = 'true';
        if (catlist.recordID) {
            this.qpObject.c = catlist.recordID;
            delete this.qpObject.s;
        }
        if (subcatlist && subcatlist.recordID) {
            this.qpObject.c = catlist.recordID;
            this.qpObject.s = subcatlist.recordID;
        }
        if (this.qpObject && this.qpObject.itemdetails) {
            delete this.qpObject.itemdetails;
        }
        return this.qpObject;
    }
    // B2B-268 (MJ 23-Jun-2020) Groups category filter added
    getQpGroupObject(grouplist) {
        // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
        this.qpObject = Object.assign({}, this.activeRouteQP);
        if (grouplist && grouplist.groupID) {
            this.qpObject.g = grouplist.groupID;
        } else {
            delete this.qpObject.g;
        }
        return this.qpObject;
        /* if (this.auth.getCategoryGroupType() === 'vendors') {
            this.qpObject = Object.assign({}, this.activeRouteQP);
            if (grouplist.repGroupCategoryManufacturers && grouplist.repGroupCategoryManufacturers.length) {
                const groupCatStr = _.map(grouplist.repGroupCategoryManufacturers, (o) => {
                    return o.manufacturerID;
                }).join(',');
                this.qpObject.mfg = groupCatStr;
            } else {
                delete this.qpObject.mfg;
            }
            return this.qpObject;
        } else {
            this.qpObject = Object.assign({}, this.activeRouteQP);
            if (grouplist.repGroupCategoryResponse && grouplist.repGroupCategoryResponse.length) {
                const groupCatStr = _.map(grouplist.repGroupCategoryResponse, (o) => {
                    return o.recordID;
                }).join(',');
                this.qpObject.c = groupCatStr;
                delete this.qpObject.s;
            } else {
                delete this.qpObject.c;
                delete this.qpObject.s;
            }
            return this.qpObject;
        } */
    }

    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    updateThemeColors() {
        this.resetColor();
        const thisObj = this;
        setTimeout(() => {
            const themeObj = this.auth.getColorCodes();
            if (themeObj !== null) {
                this.getFontText = themeObj.siteFontFamily;
                this.fontLoader(themeObj.siteFontFamily);
                this.customStyleObj = this.customStyleObj.replace(/#PRIMARYCOLOR#/gi, themeObj.primaryColor);
                this.customStyleObj = this.customStyleObj.replace(/#SECONDARYCOLOR#/gi, themeObj.secondaryColor);
                this.customStyleObj = this.customStyleObj.replace(/#TEXTCOLOR#/gi, themeObj.textColor);
                this.customStyleObj = this.customStyleObj.replace(/#FONTFAMILY#/gi, themeObj.siteFontFamily);
                const style: HTMLStyleElement = thisObj.renderer.createElement('style');
                style.innerHTML = this.customStyleObj;
                thisObj.renderer.appendChild(thisObj.customStylesHash.nativeElement, style);
            }
        }, 0);
    }
    // [B2B-110] (RK 03-FEB-2020) Dynamic link style create
    fontLoader = (param) => {
        const headID = document.getElementsByTagName('head')[0];
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        headID.appendChild(link);
        link.href = 'https://fonts.googleapis.com/css?family=' + param + ':400,500,700&display=swap';
    }
    // [B2B-115] (RM 04-03-2020): Get item from wish list count
    async updateWishListCount() {
        this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        if (this.retailerSelectedAddress && this.retailerSelectedAddress.recordID) {
            this.fullPageLoader = true;
            this.getWishListCount(this.retailerSelectedAddress.recordID);
            const params = {
                retailerShipToLocationID: this.retailerSelectedAddress.recordID,
                flagBrandPricingPolicy: true,
                shipToLocationPricingPolicyID: this.retailerSelectedAddress.recordID
            };
            this.getCategoryGroupsByRepGroup(this.domainData.repGroup.recordID, params);
        }
    }

    async getWishListCount(recordID) {
        const payload = {
            retailerShipToLocationID: recordID
        };
        const wishListItem = await this.basketItemService.getWishListData(payload);
        if (wishListItem) {
            this.wishListItemCount = wishListItem.length;
            this.wishListItem = wishListItem;
            this.setTopMenuApprovedUser();
        }
    }
    // B2B-131 (NL 04-MAR-2020) add likeListDialog function
    async likeListDialog() {
        const ref = this.dialogService.open(LikeListDialogComponent, {
            header: 'Favorites',
            width: '700px',
            height: 'auto',
            styleClass: 'overflow-auto cut-dialog-height like-cut-dialog',
            closable: true,
            baseZIndex: 100000
        });
        ref.onClose.subscribe((dataObj: any) => {
        });
    }

    // [B2B-227] (MG 03-APR-2020) modified to make custom button dynamic
    gotoExternalLink($event, type) {
        if (type === 'catalog') {
            window.open(this.auth.getCustomCatalogButtonData().url, '_blank');
            // window.location.href = this.auth.getCustomCatalogButtonData()['url'];
        } else if (type === 'promotion') {
            window.open(this.auth.getCustomPromotionButtonData().url, '_blank');
            // window.location.href = this.auth.getCustomPromotionButtonData()['url'];
        } else {
            alert('Invalid Link');
        }
    }

    gotoHelpSupport() {
        window.open('https://help.markettime.com/', '_blank');
    }

    getCategoryGroupsByRepGroup(domainData, params) {
        this.onDestroy1.next();
        this.menuShimmerAction = true;
        this.repGroupService.getCategoryGroupsByRepGroup(domainData, params).pipe(takeUntil(this.onDestroy1))
            .subscribe((response: CategoryGroups) => {
                if (response.success) {
                    this.groupsMaster = response.data;
                    this.groups = this.groupsMaster.map(v => ({ ...v, isHide: true }));
                    // const mainWidth = $(window).width();
                    this.menuShimmerAction = false;
                    // this.getCategoryMenuData(mainWidth);
                } else {
                    this.groups = [];
                    this.groupsMaster = [];
                    this.menuShimmerAction = false;
                    this.msgService.add({
                        severity: 'error', summary: 'Error',
                        detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                    });
                }
                if (this.auth.getProjectType() === 2) {
                    this.auth.setRepGroupCategoryGroupsList(this.groupsMaster);
                }
                this.repGroupService.changeData(this.groupsMaster);
            }, (error) => {
                this.groups = [];
                this.groupsMaster = [];
                this.msgService.add({
                    severity: 'error', summary: 'Error',
                    detail: objectPath.get(error, 'message', 'Error occurred during services request')
                });
            });
        this.setTopMenuApprovedUser();

    }

    getCartItemCount() {
        try {
            const itemsCount = sessionStorage.getItem('cartItemCount');
            if (itemsCount) {
                return `${itemsCount}`;
            } else {
                return '0';
            }
        } catch (e) {
            return '0';
        }
    }

    saveUserLoginInfo(res, type) {
        console.log('session data', res);
        let gtRepgroupID = this.domainData ? this.domainData.repGroup.recordID : null;
        let obj: any;

        obj = {
            companyID: res ? res : null,
            repGroupID: gtRepgroupID ? gtRepgroupID : null,
            type: type,
            source: 'B2B',
            ip: this.ipAddress ? this.ipAddress : null
        }

        // this.onDestroyLoginLogout.next();
        this.authAPI.saveLoginInfo(obj).pipe(takeUntil(this.onDestroyLoginLogout)).subscribe((response: any) => {
            this.msgService.clear();
            if (response && response.success) {
                // this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Login Detail Save Successfully' }); 
            } else {
                // this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Login Detail Not Save' });
            }
        }, (error) => {
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
        });
    }

    getIPAddress() {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }
    getRepGroupRetailers(retailerData: any) {
        const rg: any = this.domainData.repGroup;
        const getRepGroupRetailer: any = _.find(retailerData.repGroupRetailers, (o) =>
            o.repGroupID === (rg && rg.recordID));
        if (getRepGroupRetailer) {
            if (getRepGroupRetailer && getRepGroupRetailer.retailerIntegrationServices && getRepGroupRetailer.retailerIntegrationServices.mtPay) {
                this.mtPayAvilableForRetailer = true;
            } else {
                this.mtPayAvilableForRetailer = false;
            }
            return getRepGroupRetailer;
        } else {
            return null;
        }
    }
}
