<p-dialog [(visible)]="federalTaxNumber" [style]="{'max-width': '450px','width': 'calc(100% - 20px)'}" (onHide)="cancel()" appendTo="body" [baseZIndex]="110000"
    [contentStyle]="{'max-height': '90vh', 'overflow': 'auto'}" header="Federal Tax ID Required"
    modal="true" [maximizable]="false" [blockScroll]="true" [closeOnEscape]="true" [resizable]="false">
    <div class="h-100 py-10">
        <p>Your federal tax ID number is not available for this account. We require all customers to have a valid tax ID to shop our brands. Please enter this information in the <b>Manage Company</b> section in your menu listing.</p>
    </div>
    <p-footer>
    <button pButton type="button" label="Go to Manage Company Settings" class="theme-maroon-btn edit-save-btn"
        (click)="clickOkToRedirctMange()"></button>
    </p-footer>
</p-dialog>
