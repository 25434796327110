import { Component, OnInit } from '@angular/core';
import { SelectItem, DynamicDialogConfig, MessageService, DynamicDialogRef } from 'primeng/api';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { RetailerService } from 'src/app/services/middleware/retailer/retailer.service';
import { BasketItemService } from 'src/app/services/middleware/basket-item/basket-item.service';
import { ItemsAPIService } from 'src/app/services/api/items-api/items-api.service';
import * as _ from 'lodash';
import { SalespersonService } from 'src/app/services/api/salesperson.service';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import * as objectPath from 'object-path';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-like-list-dialog',
  templateUrl: './like-list-dialog.component.html',
  styleUrls: ['./like-list-dialog.component.scss']
})
export class LikeListDialogComponent implements OnInit {
  emailListType: SelectItem[];
  likeListData = [];
  retailerEmail: any;
  public loggedInUser: any;
  public parentRetailerID: any;
  public retailerObj: any;
  newEmailShow = false;
  emailBody = '';
  errorMsg = false;
  searchParams: any;
  newEmail: any;
  wishListItemArray = [];
  isValidateEmail = false;
  showLoader = true;
  errormsgEmail = false;
  public disableBtn = false;
  public showDataLoader = false;
  wishListItemArrayRemove = [];
  public logoBucketUrl = environment.logoBucketUrl;
  logoPath = this.logoBucketUrl + 'logo/{companyID}.png';
  constructor(public config: DynamicDialogConfig,
              public ref: DynamicDialogRef,
              private auth: AuthService,
              private basketItemService: BasketItemService,
              private itemsAPIService: ItemsAPIService,
              private rtSharedService: RTSharedService,
              private msgService: MessageService,
              private salesPersonService: SalespersonService,
              private retailerService: RetailerService) {
    this.emailListType = [
      {label: 'Other', value: 'Other'},
    ];
    this.basketItemService.getWishListDataEvent().subscribe(res => {
      this.likeListData = res;
      this.getWishlistItems();
      this.showLoader = false;
    });
  }
// B2B-132 (MJ 05-03-2020 ) Added for Implement Wish List to salesperson email functionality
  async ngOnInit() {
    this.auth.getAuthRetailer.subscribe((userInfo: any) => {
      if (userInfo) {
          this.loggedInUser = userInfo.child ? userInfo.child : userInfo.parent;
          this.parentRetailerID = userInfo.parent && userInfo.parent.entityId;
      } else {
          this.loggedInUser = null;
      }
    });
    this.getRetailer();
    const payload: any = {};
    if (this.auth.getRetailerTerritoryFilterFlag() === true) {
      payload.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
    }
    this.basketItemService.getWishListData(payload);
    await this.getWishlistItems();
  }
  async getWishlistItems() {
    // RC-857 (MJ 02-Jun-2020) blank the toArray
    this.wishListItemArray = [];
    this.showDataLoader = true;
    this.likeListData = _.map(this.likeListData, (o: any) => {
      o.item.fullPicturePath = this.itemsAPIService.getFullPicturePath(o.item.picturePath, o.item.manufacturerID);
      o.item.imageError = false;
      const rand = Math.floor(Math.random() * 20) + 1;
      o.item.profilePic = this.getLogoPath(o.item.manufacturerID) + '?time=' + rand + 5;
      return o;
    });
    this.likeListData.forEach((d: any) => {
      this.wishListItemArray.push(d.item.recordID);
    });
    this.showDataLoader = false;
  }

  async getRetailer() {
    if (this.loggedInUser && this.loggedInUser.entityId) {
        this.retailerObj = await this.retailerService
            .getRetailerData(this.loggedInUser.entityId + '?companyID=' + this.loggedInUser.entityId);
        this.retailerEmail = [{label: this.retailerObj.email, value: this.retailerObj.email}];
        this.retailerEmail.push(this.emailListType[0]);
    }
  }
  selectEmail($event) {
    if ($event.value === this.retailerEmail[1].value) {
      this.newEmailShow = true;
    } else {
      this.newEmailShow = false;
    }
  }

  async heartClickEvent(activeItemObj, heartStatus, index?) {
    this.showDataLoader = true;
    await this.basketItemService.saveItemToWishList(activeItemObj, heartStatus);
    this.msgService.add({
      key: 'addItem',
      severity: 'info',
      summary: 'Item removed from wish list',
      detail: `${activeItemObj.itemNumber} was saved successfully.`
    });
    // this.likeListData.splice(index, 1);
    // this.wishListItemArray.splice(index, 1);
    this.showDataLoader = false;
    // await this.getWishlistItems();
  }

  // [B2B-271] (SG 24-06-2020): Remove all items from wish list
  async removeAllList() {
    this.showDataLoader = true;
    this.likeListData.forEach((d: any) => {
      this.wishListItemArrayRemove.push(d.item);
    });
    await this.basketItemService.saveItemToAllWishList(this.wishListItemArrayRemove,  true);
    this.msgService.add({
      key: 'addItem',
      severity: 'info',
      summary: 'Item removed from wish list',
      detail: 'Remove all items successfully.'
    });
    // this.showDataLoader = false;
  }

  async reachOut() {
    if (this.emailBody.length > 0 && (this.isValidateEmail || !this.newEmailShow)) {
        this.disableBtn = true;
        if (this.newEmailShow) {
          this.searchParams = {
            email: this.newEmail,
            message: this.emailBody,
            items: this.wishListItemArray
          };
        } else {
            this.searchParams = {
              email: this.retailerEmail[0].value,
              message: this.emailBody,
              items: this.wishListItemArray
            };
        }
        const repGroupID = this.rtSharedService.getRepGroupID();
        const retailerID = this.rtSharedService.getRetailerID();
        await this.salesPersonService.mailWishListToSalesperson(repGroupID, retailerID, this.searchParams).subscribe(
            data => {
                this.msgService.add({
                    severity: 'info',
                    summary: 'Email sent successfully',
                });
                this.disableBtn = false;
                this.ref.close();
            },
            error => {
                this.msgService.add({
                    severity: 'error', summary: 'Error',
                    detail: objectPath.get(error, 'message', 'Error occurred during services request')
                });
            }
        );
      } else {
        if (this.emailBody.length <= 0) {
          this.errorMsg = true;
        }
        if (!(this.isValidateEmail || !this.newEmailShow)) {
          this.errormsgEmail = true;
        }
      }
  }

  checkMessage(emailBody) {
      if (emailBody.length > 0) {
          this.errorMsg = false;
      }
  }

  validateEmail() {
    if (/^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/.test(this.newEmail)) {
      this.isValidateEmail = true;
      this.errormsgEmail = false;
    } else {
      this.isValidateEmail = false;
      this.errormsgEmail = true;
    }
  }
// END

addTokens(url: string, params) {
  Object.keys(params).forEach(key => {
      url = url.replace('{' + key + '}', params[key]);
  });
  return url;
}

getLogoPath(recordID) {
  return this.addTokens(this.logoPath, { companyID: recordID });
}

}
