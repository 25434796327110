import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RTSharedService } from '../../rtshared/rtshared.service';

@Injectable({
  providedIn: 'root'
})
export class CalloutApiService {
  baseUrl: string;
  constructor(private http: HttpClient, private rtSharedService: RTSharedService) {
    this.baseUrl = this.rtSharedService.getApiUrl();
  }
  async getCalloutsData(Payload): Promise<any> {
    Payload['isB2bOnly'] = 1;
    const url = `${this.baseUrl}reptime/api/callouts/all`;
    return await this.http.post<any>(url, Payload).toPromise();
  }
}
