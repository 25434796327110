import { isPlatformBrowser } from '@angular/common';
import {
  Component, OnInit, Input, QueryList, ViewChildren, HostListener, OnChanges, SimpleChanges, Output, PLATFORM_ID, Inject,
  EventEmitter,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import * as objectPath from 'object-path';
import * as _ from 'lodash';
import { AuthService } from '../../services/middleware/auth/auth.service';
import { CompanyService } from '../../services/api/company.service';
import { ReptimeEntity } from 'src/app/models/reptime-entity';
import * as $ from 'jquery';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { APIResponse } from '../../models/api-response';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { RTSharedService } from '../../services/rtshared/rtshared.service';
import { ConfirmationService, DialogService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { Login } from "../../models/login";
import { Constant } from "../../constant";

import { BasketItemService } from '../../services/middleware/basket-item/basket-item.service';
import { SalespersonService } from '../../services/api/salesperson.service';
import { LikeListDialogComponent } from '../../modals/like-list-dialog/like-list-dialog.component';
import { AuthAPIService } from '../../services/api/auth-api/auth-api.service';
import { HttpClient  } from '@angular/common/http';
import { RetailerService } from '../../services/middleware/retailer/retailer.service';
@Component({
  selector: 'app-lightovation-header',
  templateUrl: './lightovation-header.component.html',
  styleUrls: ['./lightovation-header.component.scss']
})
export class LightovationHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public isIframeWindow: any = (window as any).iframeData;
  private readonly onDestroy = new Subject<void>();
  private readonly onDestroy1 = new Subject<void>();
  private readonly onDestroy2 = new Subject<void>();
  private readonly onDestroyRetailer = new Subject<void>();
  public hasSidebar = false;
  @Input() loggedInUser: any;
  win: any = (window);
  public domainData: any = this.win.domainData;
  @Input() mobMenuList: any;
  @Input() items: any;
  @Input() menuShimmerAction: any;
  @Input() notApprovedByRepGroup: any;
  @Input() groups: any;
  @Input() logo: any;
  @Input() logoClickURL: any;
  @Input() hidemenutoggle: any;
  public qpObject: any;
  public activeRouteQP: any;
  @ViewChildren('getmenu_width') getmenuwidth: QueryList<any>;
  @ViewChildren('menubutton') menubutton: QueryList<any>;
  @Output() fullPageLoader = new EventEmitter<any>();

  public loginForm: FormGroup;
  public submitted = false;
  public isLoading = false;

  public cartItemCount = '0';
  public wishListItemCount = 0;
  public wishListItem = [];
  public wishListItemAllow = true;
  public FinalSelectLocation: any;
  public retailerSelectedAddress: any = {};
  public cartItemSubscription: Subscription;
  public wishListItemSubscription: Subscription;
  public saveForShowAddress: Subscription;
  public getRetailerDataSubscription: Subscription;
  public subscriptionRetailerDetails: Subscription;
  // Email
  public showEmailDialogs: boolean;
  public salespersonEmail: any;
  public emailBody = '';
  public retailerID: any;
  public salesRepID: any;
  public errorMsg = false;
  public disableBtn = false;
  public itemSearchForm: FormGroup;
  entityType: any;
  public marketTimeEventsSettings = null;
  public topHeaderText = '';
  ipAddress: any;
  storeCompanyID: any[] = [];
  buyerQickReport: any;
  yourYTDSaving: any;
  yourLYSaving: any;
  yourLTTaving: any;
  mtPayAvilableForRetailer: boolean;
  constructor(
    public auth: AuthService,
    private companyService: CompanyService,
    public rtSharedService: RTSharedService,
    private formBuilder: FormBuilder,
    public router: Router,
    private basketItemService: BasketItemService,
    private msgService: MessageService,
    private salesPersonService: SalespersonService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private authAPI: AuthAPIService,
    private http:HttpClient,
    private retailerService: RetailerService,
    private route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId?: Object) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.entityType = route.root.firstChild.snapshot.data.entityType;
    });

    this.subscriptionRetailerDetails = this.retailerService.getRetailerDetailsEvent().subscribe(async (flag) => {
      console.log("flag",flag)
      if(this.auth.getshowDiscountTickerButtonFlag() && flag){
        this.getRetailerQickReportAll();
      } 
    })

  }

  ngOnInit() {
    this.getIPAddress();
    this.marketTimeEventsSettings = this.auth.getMarketTimeEventsSettings();
    if (this.marketTimeEventsSettings) {
      this.topHeaderText = this.marketTimeEventsSettings.topHeaderText;

    }

    let getRepGroupRetailer: any;
    this.getRetailerDataSubscription = this.auth.getRetailerData.subscribe((getRetailerRes) => {
      console.log('94444', getRetailerRes, this.router.url);
      // if (this.router.url === '/' ) {
      //     this.notApprovedByRepGroup = true;
      //     this.setTopMenuUnApprovedUser();
      // }
      if (getRetailerRes && _.isArray(getRetailerRes.repGroupRetailers)) {
        console.log('96666666666');
        this.FinalSelectLocation = this.rtSharedService.getSelectedShipTo();

        if (getRetailerRes.shipToLocations.length > 0 && getRetailerRes.shipToLocations !== undefined) {
          if (!this.FinalSelectLocation) {
            let primaryList = getRetailerRes.shipToLocations.find(item => item.isPrimary === true);
            primaryList = primaryList ? primaryList : getRetailerRes.shipToLocations[0];
            this.FinalSelectLocation = primaryList;
            const sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
            if (sessionLocationId === null) {
              sessionStorage.setItem('retailerShipToLocationID', JSON.stringify(this.FinalSelectLocation));
              this.rtSharedService.setSelectedShipTo(this.FinalSelectLocation);
            } else {
              const x = getRetailerRes.shipToLocations.find(o => +o.recordID === +sessionLocationId.recordID);
              this.rtSharedService.setSelectedShipTo(x);
            }
          }
        } else {
          console.log('shiplocationNot 113');

          // if (this.router.url !== '/shipping-location-not-found' ) {
          //     console.log('116 if');
          //     this.shiplocationNot = true;
          //     console.log('shipToLocations>>>>', this.router.url);
          //     this.notApprovedByRepGroup = true;
          //     this.setTopMenuUnApprovedUser();
          // } else {
          //     console.log('122 else');
          //     this.notApprovedByRepGroup = false;
          //     this.setTopMenuApprovedUser();
          // }
        }
        const rg: any = this.domainData ? this.domainData.repGroup : '';
        if (getRetailerRes.shipToLocations.length === 0) {
          this.logoClickURL = '/shipping-location-not-found';
          this.notApprovedByRepGroup = true;
          // this.setTopMenuUnApprovedUser();
        } else if (getRetailerRes.repGroupRetailers.length) {
          getRepGroupRetailer = _.find(getRetailerRes.repGroupRetailers, (o) =>
            o.repGroupID === (rg && rg.recordID));
          if (getRepGroupRetailer && (getRepGroupRetailer.approvedByRepGroup === 0 ||
            getRepGroupRetailer.approvedByRepGroup === 2)) {
            console.log('getRepGroupRetailer 2');
            if (getRepGroupRetailer.approvedByRepGroup === 0) {
              console.log('getRepGroupRetailer 0');
              this.logoClickURL = '/rep-group-msg-pending';
            } else if (getRepGroupRetailer.approvedByRepGroup === 2) {
              console.log('getRepGroupRetailer 22');
              this.logoClickURL = '/rep-group-access-denied';
            } else {
              console.log('getRepGroupRetailer ');
              this.logoClickURL = '/login';
            }
            this.notApprovedByRepGroup = true;
            // this.setTopMenuUnApprovedUser();
            // return false;
          } else if (getRepGroupRetailer && getRepGroupRetailer.recordDeleted) {
            console.log('rep-retailer-notfound ligh.header');
            this.logoClickURL = '/rep-retailer-notfound';
            this.notApprovedByRepGroup = true;
          } else if (getRepGroupRetailer && getRepGroupRetailer.approvedByRepGroup === 1) {
            console.log('getRepGroupRetailer 11');
            this.logoClickURL = '/home';
            // [B2B-212] (RM 30-03-2020): When login retailer that time call wishlist api
            if (this.wishListItemAllow) {
              // if ( this.router.url === '/home') {
              // this.updateWishListCount();
              this.wishListItemAllow = false;
              // }

            } // END
            this.notApprovedByRepGroup = false;
            // this.setTopMenuApprovedUser();
            // return false;
          } else {
            console.log('getRepGroupRetailer nothing');

            /*when registered buyer login from other repgrup domian*/
            this.logoClickURL = '/rep-group-msg';

            this.notApprovedByRepGroup = true;
            // this.setTopMenuUnApprovedUser();
            // return false;
          }
        }
        this.getRepGroupRetailers(getRetailerRes);
      }
    });
    this.cartItemSubscription = this.auth.getCartItemCountEvent().subscribe(res => {
      try {
        this.cartItemCount = res;
      } catch (e) { }
    });
    this.wishListItemSubscription = this.basketItemService.getWishListClickEvent().subscribe(data => {
      if (getRepGroupRetailer && getRepGroupRetailer.approvedByRepGroup === 1) {
        this.getWishListCount(this.retailerSelectedAddress.recordID);
      }
    });
    // NOTE: RM (26-02-2021) => this api(Wish List) call 2 time on page load, so now code comment.
    if (JSON.parse(sessionStorage.getItem('retailerShipToLocationID'))) {
      this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    }
    this.saveForShowAddress = this.rtSharedService.getSelectedShipToEvent().subscribe((e) => {
      this.retailerSelectedAddress = e;
      if (this.retailerSelectedAddress) {
        this.getWishListCount(this.retailerSelectedAddress.recordID);
      }
      this.notApprovedByRepGroup = false;
    });
    this.cartItemCount = this.getCartItemCount();
    this.createLoginForm();
    this.getRepGroupBrandsList();
    this.getRepGroupCategoryGroupsList();
    if(this.auth.getshowDiscountTickerButtonFlag() && this.loggedInUser){
      this.getRetailerQickReportAll();
    }
  }
  ngAfterViewInit() {
    this.isIframeWindow = this.win.iframeData;
  }
  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.itemSearchForm = this.formBuilder.group({
      txtItemSearch: ['', Validators.required]
    });
  }
  mouseEnterMainList(e) {
    const getBoundingClientRect = $(e.target)[0].getBoundingClientRect();
    const subCategoryBlockWidth = $(e.target).find('.sub-category-block').outerWidth();
    const subCategoryBlockHeight = $(e.target).find('.sub-category-block').outerHeight();
    const targetOuterWidth = $(e.target).outerWidth();
    const targetOuterHeight = $(e.target).outerHeight();
    const windowInnerWidth = window.innerWidth;
    const windowInnerHeight = window.innerHeight;
    if (subCategoryBlockWidth) {
      if (windowInnerWidth <= +(targetOuterWidth + getBoundingClientRect.x + subCategoryBlockWidth).toFixed(2)) {
        $(e.target).find('.sub-category-block').css({
          'top': getBoundingClientRect.y,
          'left': getBoundingClientRect.x - subCategoryBlockWidth,
          'bottom': 'auto'
        });
      } else {
        $(e.target).find('.sub-category-block').css({
          'top': getBoundingClientRect.y,
          'left': targetOuterWidth + getBoundingClientRect.x,
          'bottom': 'auto'
        });
      }

      if (windowInnerHeight <= +(targetOuterHeight + getBoundingClientRect.y + subCategoryBlockHeight).toFixed(2)) {
        $(e.target).find('.sub-category-block').css({
          'top': 'auto',
          'bottom': windowInnerHeight - getBoundingClientRect.y - targetOuterHeight
        });
      }
    }
  }
  async onSubmit() {
    this.submitted = true;
    // check form validation
    if (this.loginForm.valid) {
      const formData: Login = this.loginForm.getRawValue();
      this.isLoading = true;
      const res = await this.auth.localLogin(formData);
      this.isLoading = false;
      console.log('login Successs', res);
      if (res.error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Login failed',
          detail: Constant.LOGIN_ERROR
        });
      } else {
        sessionStorage.setItem('username', this.loginForm.value.username);
        sessionStorage.setItem('logindetail', JSON.stringify(res.body.userInfos[0]));
        sessionStorage.setItem('idToken', JSON.stringify(res.body.idToken));
        this.storeCompanyID = [];
        _.forEach(res.body.userInfos, (o) =>  {
          this.storeCompanyID.push(o.entityId);
         });
         
         if(this.storeCompanyID && this.storeCompanyID.length){
           sessionStorage.setItem('allLoginCompanyID', JSON.stringify(this.storeCompanyID));
           let i = 0;
           for(i; i < this.storeCompanyID.length; i++) {
             this.saveUserLoginInfo(this.storeCompanyID[i], 'login');
           }
         }
        
        this.messageService.add({
          severity: 'success',
          summary: 'Login success',
          detail: Constant.LOGIN_SUCCESS
        });
        if(this.auth.getshowDiscountTickerButtonFlag()){
          this.getRetailerQickReportAll();
        }
        // RC-2222 (RK 13-OCT-2020): mtPay payment decline time retailer login step [start]
        const hittedUrl = JSON.parse(sessionStorage.getItem('connectionLostUrlData'));
        if (hittedUrl && _.includes(this.storeCompanyID, hittedUrl.queryParams.buyerID)) {
                this.router.navigate([hittedUrl.path], { queryParams: hittedUrl.queryParams}).then(res => {
                    sessionStorage.removeItem('connectionLostUrlData');
                });
        } else {
            this.router.navigate(['/home']);
        }
        // RC-2222 (RK 13-OCT-2020): mtPay payment decline time retailer login step [end]
        // window.location.href='/home';
        // this.appComponent.updateWishListCount();
      }
    }
  }

  saveUserLoginInfo(res, type) {
    console.log('session data', res);
    let gtRepgroupID = this.domainData ? this.domainData.repGroup.recordID : null;
    let obj: any;

    obj = {
      companyID : res ? res : null,
      repGroupID : gtRepgroupID ? gtRepgroupID : null,
      type: type,
      source : 'B2B',
      ip: this.ipAddress ? this.ipAddress : null
    }

    // this.onDestroy.next();
    this.authAPI.saveLoginInfo(obj).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      this.msgService.clear();
     if (response && response.success) { 
      // this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Login Detail Save Successfully' }); 
     } else {
      // this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Login Detail Not Save' });
     } 
    }, (error) => { 
     this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') }); }   );
  }

  getIPAddress(){
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent(event) {
    const header = $('header');
    const scrollPosition = header.outerHeight() + header.offset().top;
    if (isPlatformBrowser(this.platformId)) {
      if ($(window).scrollTop() >= scrollPosition) {
        $('header .header-wpr').not('.none-sticky').addClass('fixed');
        $('.defaultScrollpanel').addClass('header-sticky');
      } else {
        $('header .header-wpr').removeClass('fixed');
        $('.defaultScrollpanel').removeClass('header-sticky');
      }
    }
  }
  ngOnDestroy() {
    this.cartItemSubscription.unsubscribe();
    this.wishListItemSubscription.unsubscribe();
    this.saveForShowAddress.unsubscribe();
    this.getRetailerDataSubscription.unsubscribe();
    this.subscriptionRetailerDetails.unsubscribe();
    this.onDestroy.next();
    this.onDestroy1.next();
    this.onDestroy2.next();
    this.onDestroyRetailer.next();
  }
  gotoHelpSupport() {
    window.open('https://reptime.freshdesk.com/support/solutions/11000004198', '_blank');
  }

  goToVendors() {
    this.router.navigate([`/vendors`], { queryParams: { catalogs: true } });
  }
  logout() {
    this.auth.callAPILogs(true);
    let sessionLoginDetails = JSON.parse(sessionStorage.getItem('allLoginCompanyID'));
    console.log('sessionLoginDetails', sessionLoginDetails);
    if(sessionLoginDetails && sessionLoginDetails.length){
      let i = 0;
      for(i; i < sessionLoginDetails.length; i++) {
        this.saveUserLoginInfo(sessionLoginDetails[i], 'logout');
      }
    }
    
    if (!this.rtSharedService.getRepgroupOrderStatus()) {
      this.hasSidebar = false;
      this.auth.removeAllToken();
    }
    this.getRepGroupCategoryGroupsList();
    this.router.navigate(['/']);
  }
  getRepGroupBrandsList() {
    this.onDestroy1.next();
    this.auth.RepGroupshimmer[0] = true;
    this.companyService.getRepGroupBrandsList(true).pipe(takeUntil(this.onDestroy1)).subscribe((res: APIResponse) => {
      if (res.data) {
        this.auth.setRepGroupBrandsList(res.data);
        // console.log("RepGroupBrands" , this.auth.getRepGroupBrandsList())
      }
      this.auth.RepGroupshimmer[0] = false;
    }, (error) => {
      this.auth.RepGroupshimmer[0] = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    });
  }
  getRepGroupCategoryGroupsList() {
    this.onDestroy2.next();
    this.auth.RepGroupshimmer[1] = true;
    this.companyService.getRepGroupCategoryGroupsList().pipe(takeUntil(this.onDestroy2)).subscribe((res: APIResponse) => {
      if (res.data) {
        this.auth.setRepGroupCategoryGroupsList(res.data);
        // console.log("RepGroupCategoryGroupsList" , this.auth.getRepGroupCategoryGroupsList())
      }
      this.auth.RepGroupshimmer[1] = false;
    }, (error) => {
      this.auth.RepGroupshimmer[1] = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    });
  }

  allBtnClick($event, group, optionalparams, subcategory) {
    this.routeAllBtn($event, group, optionalparams, subcategory);
  }
  routeAllBtn($event, group, optionalparams, subcategory) {
    if (optionalparams === 'categorylist') {
      this.router.navigateByUrl('/category');
    } else if ($event.target.innerText === 'Shop by Brand' || (group && $event.target.innerText === group.text && optionalparams === 'Shop by Brand')) {
      this.router.navigateByUrl('/vendors');
    } else if ($event.target.innerText === 'Shop by Items' || (group && $event.target.innerText === group.text && optionalparams === 'Shop by Items')) {
      this.router.navigateByUrl('/shop');
    } else if ($event.target.innerText === 'Quick Order') {
      this.router.navigate([`/shop`], { queryParams: { quick: true } });
    } else if ($event.target.innerText === 'Promotions') {
      this.router.navigateByUrl('/promotions');
    } else if ($event.target.innerText === 'Catalogs') {
      this.router.navigate([`/vendors`], { queryParams: { catalogs: true } });
      // this.router.navigateByUrl('/documentlist');
    } else if ($event.target.innerText === '') {
      if (this.auth.getProjectType() === 2) {
        this.router.navigate(['/']);
      } else {
        this.router.navigateByUrl('/' + this.logoClickURL);
      }
    } else if (optionalparams === 'group') {
      if (this.loggedInUser) {
        this.router.navigate([`/shop`], { queryParams: this.getQpGroupObject(group) });
      } else {
        this.router.navigate([`/store`], { queryParams: this.getQpGroupObject(group) });
      }
    } else if (optionalparams === 'category') {
      //  this.router.navigate([`/shop`], { queryParams: this.getQpObject(group) });
      if (this.loggedInUser) {
        this.router.navigate([`/shop`], { queryParams: this.getQpObject(group) });
      } else {
        this.router.navigate([`/store`], { queryParams: this.getQpObject(group) });
      }
    } else if (optionalparams === 'subcategory') {
      // this.router.navigate([`/shop`], { queryParams: this.getQpObject(group, subcategory) });
      if (this.loggedInUser) {
        this.router.navigate([`/shop`], { queryParams: this.getQpObject(group, subcategory) });
      } else {
        this.router.navigate([`/store`], { queryParams: this.getQpObject(group, subcategory) });
      }
    } else if (optionalparams === 'vendors') {
      if (this.loggedInUser) {
        this.router.navigate([`/vendor`], { queryParams: { mfg: group.manufacturerID } });
      } else {
        this.router.navigate([`/vendor-details`], { queryParams: { mfg: group.manufacturerID } });
      }

    } else if (optionalparams === 'Catalog') {
      window.open(this.auth.getCustomCatalogButtonData().url, '_blank');
    } else if (optionalparams === 'promotion') {
      window.open(this.auth.getCustomPromotionButtonData().url, '_blank');
    }
  }

  getQpObject(catlist, subcatlist?: any) {
    this.qpObject = Object.assign({}, this.activeRouteQP);
    // this.qpObject.group = 'true';
    if (catlist.recordID) {
      this.qpObject.c = catlist.recordID;
      delete this.qpObject.s;
    }
    if (subcatlist && subcatlist.recordID) {
      this.qpObject.c = catlist.recordID;
      this.qpObject.s = subcatlist.recordID;
    }
    if (this.qpObject && this.qpObject.itemdetails) {
      delete this.qpObject.itemdetails;
    }
    return this.qpObject;
  }
  getQpGroupObject(grouplist) {
    // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
    this.qpObject = Object.assign({}, this.activeRouteQP);
    if (grouplist && grouplist.groupID) {
      this.qpObject.g = grouplist.groupID;
    } else {
      delete this.qpObject.g;
    }
    return this.qpObject;
    /* if (this.auth.getCategoryGroupType() === 'vendors') {
      this.qpObject = Object.assign({}, this.activeRouteQP);
      if (grouplist.repGroupCategoryManufacturers && grouplist.repGroupCategoryManufacturers.length) {
        const groupCatStr = grouplist.repGroupCategoryManufacturers.reduce((a, o) =>
          ((o.showLockedB2bItems || o.b2bItemPricingPolicy !== 'NeedBrandApproval') && a.push(o.manufacturerID), a), []).join(',');
        this.qpObject.mfg = groupCatStr;
      } else {
        delete this.qpObject.mfg;
      }
      return this.qpObject;
    } else {
      this.qpObject = Object.assign({}, this.activeRouteQP);
      if (grouplist.repGroupCategoryResponse && grouplist.repGroupCategoryResponse.length) {
        const groupCatStr = _.map(grouplist.repGroupCategoryResponse, (o) => {
          return o.recordID;
        }).join(',');
        this.qpObject.c = groupCatStr;
        delete this.qpObject.s;
      } else {
        delete this.qpObject.c;
        delete this.qpObject.s;
      }
      return this.qpObject;
    } */
  }
  loginClick() {
    $('.user-info').addClass('not-login')
  }
  closeLoginForm() {
    $('.user-info').removeClass('not-login')
  }
  userMenuClick() {
    if ($('.item-menu-bar').hasClass('active')) {
      $('.item-menu-bar, .menu-overlay').removeClass('active');
      $('.item-menu-inner').removeClass('menu-show');
      $('body').removeClass('action');
    } else {
      $('.item-menu-bar, .menu-overlay').addClass('active');
      $('.item-menu-inner').addClass('menu-show');
      $('body').addClass('action');
    }
  }
  closeuserMenu() {
    $('.item-menu-bar, .menu-overlay').removeClass('active');
    $('.item-menu-inner').removeClass('menu-show');
    $('body').removeClass('action');
  }

  async getWishListCount(recordID) {
    const payload = {
      retailerShipToLocationID: recordID
    };
    console.log('1 ===> lightovation-header.component.ts');
    const wishListItem = await this.basketItemService.getWishListData(payload);
    if (wishListItem) {
      this.wishListItemCount = wishListItem.length;
      this.wishListItem = wishListItem;
    }
  }

  getCartItemCount() {
    try {
      const itemsCount = sessionStorage.getItem('cartItemCount');
      if (itemsCount) {
        return itemsCount;
      } else {
        return '0';
      }
    } catch (e) {
      return '0';
    }
  }

  async likeListDialog() {
    const ref = this.dialogService.open(LikeListDialogComponent, {
      header: 'Favorites',
      width: '700px',
      height: 'auto',
      styleClass: 'overflow-auto cut-dialog-height like-cut-dialog',
      closable: true,
      baseZIndex: 100000
    });
    ref.onClose.subscribe((dataObj: any) => {
    });
  }

  onSubmitSearch() {
    if (this.itemSearchForm.valid && this.itemSearchForm.value.txtItemSearch && !this.isIframeWindow) {
      sessionStorage.setItem('ItemSearch', this.itemSearchForm.value.txtItemSearch);
      this.router.navigateByUrl(this.loggedInUser ? '/shop' : 'store').then((res) => {
        this.basketItemService.setItemSearchEvent(this.itemSearchForm.value.txtItemSearch);
      });
    }
  }

  openCartBar() {
    if (!_.includes(this.router.url, '/shop')) {
      this.router.navigateByUrl('/shop').then((res) => {
        this.basketItemService.setCartClickEvent(true);
      });
    } else {
      this.basketItemService.setCartClickEvent(true);
    }
  }

  getMenuShow() {
    if (this.loggedInUser && this.notApprovedByRepGroup) {
        return false;
    } else if (!this.loggedInUser) {
        return true;
    } else {
        return true;
    }
}
  getRetailerQickReportAll() {
    this.onDestroyRetailer.next();
    const repGroupID = this.rtSharedService.getRepGroupID();
    const retailerID = this.rtSharedService.getRetailerID();
    this.retailerService.getRetailerQickReport([retailerID], repGroupID).pipe(takeUntil(this.onDestroyRetailer)).subscribe((response: any) => {
    if (response && response.success) {
      this.buyerQickReport = response.data[0];
      if(this.buyerQickReport && this.auth.getTickerText()){
        this.buyerQickReport.orderSummary.yearToDate
        this.yourYTDSaving = (this.buyerQickReport.orderSummary.yearToDate * this.auth.getTickerText())/100;
        this.yourLYSaving = (this.buyerQickReport.orderSummary.lastYearTotal * this.auth.getTickerText())/100;
        this.yourLTTaving = (this.buyerQickReport.orderSummary.lifetimeTotal * this.auth.getTickerText())/100;
      }
    }
    }, (error) => { 
    //  this.msgService.clear();
    //  this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
  }   );
  }

  openMtPayService(){
    sessionStorage.setItem('openMtPayFromDD', 'true');
  }
  getRepGroupRetailers(retailerData: any) {
    const rg: any = this.domainData.repGroup;
    const getRepGroupRetailer: any = _.find(retailerData.repGroupRetailers, (o) =>
    o.repGroupID === (rg && rg.recordID));
    if (getRepGroupRetailer) {
        if (getRepGroupRetailer && getRepGroupRetailer.retailerIntegrationServices && getRepGroupRetailer.retailerIntegrationServices.mtPay) {
            this.mtPayAvilableForRetailer = true;
        } else {
            this.mtPayAvilableForRetailer = false;
        }
        return getRepGroupRetailer;
    } else {
        return null;
    }
  }
}
