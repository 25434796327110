import {Injectable} from '@angular/core';
import {RTSharedService} from '../rtshared/rtshared.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as objectPath from 'object-path';
import {UrlToolsService} from '../url-tools.service';

@Injectable()
export class SalespersonService {
    baseUrl: string;

    constructor(private http: HttpClient,
                private rtSharedService: RTSharedService,
                private urlTools: UrlToolsService) {
        this.baseUrl = this.rtSharedService.getApiUrl();
    }

    findSalespersonByName(namePart: string) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const url = `${this.baseUrl}reptime/api/${repGroupID}/salespeople/findSalespersonByName`;
        return this.http.post<any>(url, namePart);
    }

    getSalespeopleByRepGroup(repGroupID: string, offset?: any, size?: any, sort?: any, sortOrder?: number, searchParams?: any, showAll?: boolean) {
        const searchTypeParam = objectPath.get(searchParams, 'searchType', 'startswith');
        size = !size ? 25 : size;
        showAll = showAll ? showAll : false;
        offset = !offset ? 0 : offset;
        sort = !sort ? 'name' : sort;
        sortOrder = !sortOrder ? 1 : sortOrder;
        let params;
        if (!showAll) {
            params = new HttpParams()
                .append('offset', offset)
                .append('size', size)
                .append('sort', sort)
                .append('searchType', searchTypeParam)
                .append('sortOrder', String(sortOrder));
        } else {
            params = new HttpParams().append('showAll', 'true');
            searchParams.active = true;
            searchParams.approved = true;
        }
        // reptime/api/salespeople/{repGroupID}
        return this.http.post(this.urlTools.addTokens(this.baseUrl + 'reptime/api/salespeople/{repGroupID}', {repGroupID}), searchParams, {params});
        // return this.http.post(this.urlTools.addTokens(this.getSalespeopleByRepGroupURL, {repGroupID: repGroupID}), searchParams, {params: params});
    }

    getRepGroupsBySalespersonID(salespersonID: string) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const url = `${this.baseUrl}reptime/api/salespersonrepgroups/${salespersonID}`;
        return this.http.get(url);
    }
    // B2B-119 (MJ 03-03-2020 ) Added for Implement buyer to salesperson email functionality
    mailToSalesperson(retailerID, salesrepID, searchParams?: any) {
        const url = `${this.baseUrl}reptime/api/${retailerID}/${salesrepID}/send`;
        return this.http.post<any>(url, searchParams);
    }
    // END

    // B2B-132 (MJ 05-03-2020 ) Added for Implement Wish List to salesperson email functionality
    mailWishListToSalesperson(repGroupID, retailerID, searchParams?: any) {
        const retailerShipToLocationArr = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        let retailerShipToLocationID = '';
        if(retailerShipToLocationArr !== undefined && retailerShipToLocationArr !== null) {
            retailerShipToLocationID = retailerShipToLocationArr.recordID;
        }
        const url = `${this.baseUrl}reptime/api/${repGroupID}/${retailerID}/sendWishList?shipToLocationPricingPolicyID=${retailerShipToLocationID}`;
        return this.http.post<any>(url, searchParams);
    }
    // END


}
