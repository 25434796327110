<p-toast position="top-right" class="reachout-toast"></p-toast>
<!-- B2B-57 (MG 30-JAN-2020): added loader -->
<!-- h-100 d-flex align-items-center -->
<div *ngIf="containerRetailer" class="home-page reptime-theme w-100">
    <div style="padding: 10px; vertical-align: middle; margin: 0 auto; text-align: center;">
        <h1></h1>
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>
<!-- B2B-71 (NL 11-FEB-2020): added accordion inside information block and Past Order History Table -->
<div *ngIf="!containerRetailer" class="home-page reptime-theme w-100 resp-home res-home-myord">
    <div class="p-grid mt-0">
        <app-my-orders></app-my-orders>
        <!-- callout design -->
        <div class="callout-main w-100" *ngIf="calloutData?.length">
            <div class="callout-main-inner p-grid m-0 w-100 justify-content-center">
                <ng-container *ngFor="let item of calloutData; index as i">
                    <div class="p-col-3" *ngIf="item?.showOnWebsite"
                        [class.callout-hover-off]="!item?.description?.trim()">
                        <p-card styleClass="ui-card-shadow">
                            <a [routerLink]="['/shop']" [queryParams]="{callout: item?.recordID}">
                                <p-header class="callout-img">
                                    <img src="{{bucketUrl}}{{item?.picturePath}}"
                                        onerror="this.onerror=null;this.src='assets/images/no-image-available.png';"
                                        alt="callout-img">
                                    <div class="callout-layer"></div>
                                </p-header>
                                <div class="callout-content-block">
                                    <h2 class="dash-bigger theme-grayclr" style="font-weight: normal; font-size: 19px;"
                                        *ngIf="item?.title">{{item?.title}}</h2>
                                    <span class="callout-sub-title"
                                        style="font-weight: normal; font-size: 14px; color:#585958"
                                        *ngIf="item?.subTitle">{{item?.subTitle}}</span>
                                    <div class="callout-desc" *ngIf="item?.description">
                                        <div>{{item?.description}}</div>
                                    </div>
                                </div>
                            </a>
                        </p-card>
                    </div>
                </ng-container>
                <!-- <div class="p-col-3">
                    <p-card styleClass="ui-card-shadow">
                        <p-header class="callout-img">
                            <img src="assets/images/b2b-comingsoon-bg.jpg" alt="">
                            <div class="callout-layer"></div>
                            <p-button [styleClass]="'ui-button-warning'" class="callout-shop-btn" label="Shop"></p-button>
                        </p-header>
                        <div class="callout-content-block">
                            <h2 class="callout-title">Advanced Card</h2>
                            <span  class="callout-sub-title">Subheader</span>
                            <div  class="callout-desc">
                                <div class="">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores!
                                </div>
                            </div>
                        </div>
                                               
                    </p-card>
                </div>
                <div class="p-col-3">
                    <p-card styleClass="ui-card-shadow">
                        <p-header class="callout-img">
                            <img src="assets/images/b2b-comingsoon-bg.jpg" alt="">
                            <div class="callout-layer"></div>
                            <p-button [styleClass]="'ui-button-warning'" class="callout-shop-btn" label="Shop"></p-button>
                        </p-header>
                        <div class="callout-content-block">
                            <h2 class="callout-title">Advanced Card</h2>
                            <span  class="callout-sub-title">Subheader</span>
                            <div  class="callout-desc">
                                <div class="">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores!
                                </div>
                            </div>
                        </div>
                                               
                    </p-card>
                </div>
                <div class="p-col-3">
                    <p-card styleClass="ui-card-shadow">
                        <p-header class="callout-img">
                            <img src="assets/images/b2b-comingsoon-bg.jpg" alt="">
                            <div class="callout-layer"></div>
                            <p-button [styleClass]="'ui-button-warning'" class="callout-shop-btn" label="Shop"></p-button>
                        </p-header>
                        <div class="callout-content-block">
                            <h2 class="callout-title">Advanced Card</h2>
                            <span  class="callout-sub-title">Subheader</span>
                            <div  class="callout-desc">
                                <div class="">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores!
                                </div>
                            </div>
                        </div>
                                               
                    </p-card>
                </div>
                <div class="p-col-3">
                    <p-card styleClass="ui-card-shadow">
                        <p-header class="callout-img">
                            <img src="assets/images/b2b-comingsoon-bg.jpg" alt="">
                            <div class="callout-layer"></div>
                            <p-button [styleClass]="'ui-button-warning'" class="callout-shop-btn" label="Shop"></p-button>
                        </p-header>
                        <div class="callout-content-block">
                            <h2 class="callout-title">Advanced Card</h2>
                            <span  class="callout-sub-title">Subheader</span>
                            <div  class="callout-desc">
                                <div class="">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores!
                                </div>
                            </div>
                        </div>
                                               
                    </p-card>
                </div>
                <div class="p-col-3">
                    <p-card styleClass="ui-card-shadow">
                        <p-header class="callout-img">
                            <img src="assets/images/b2b-comingsoon-bg.jpg" alt="">
                            <div class="callout-layer"></div>
                            <p-button [styleClass]="'ui-button-warning'" class="callout-shop-btn" label="Shop"></p-button>
                        </p-header>
                        <div class="callout-content-block">
                            <h2 class="callout-title">Advanced Card</h2>
                            <span  class="callout-sub-title">Subheader</span>
                            <div  class="callout-desc">
                                <div class="">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores!
                                </div>
                            </div>
                        </div>
                                               
                    </p-card>
                </div> -->
            </div>
        </div>
        <!-- calout end -->
    </div>
</div>
<!-- B2B-71 (NL 12-FEB-2020): added dialog inside information block -->
<!-- Start Dialog -->
<p-dialog [(visible)]="showBillLocationDialog"
    [contentStyle]="{'width': '450px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}"
    header="Bill To Location" modal="true" [maximizable]="false" [closeOnEscape]="true" [draggable]="false"
    styleClass="resp-bill-info overflow-auto cut-dialog-height" [blockScroll]="true"  [resizable]="false">
    <div class="h-100 py-10" *ngIf="retailerObj">
        <p class="mb-5 word-break-all"><strong>Name:</strong>&nbsp;<span
                *ngIf="retailerObj.name">{{retailerObj.name}}</span></p>
        <p class="mb-5 word-break-all"><strong>Address:</strong>&nbsp;
            <span *ngIf="retailerObj.address1">{{retailerObj.address1}}, </span>
            <span *ngIf="retailerObj.city">{{retailerObj.city}}, </span>
            <span *ngIf="retailerObj.state">{{retailerObj.state}}, </span>
            <span *ngIf="retailerObj.country">{{retailerObj.country}}, </span>
            <span *ngIf="retailerObj.zip">{{retailerObj.zip}}.</span>
        </p>
        <p class="mb-5"><strong>Phone:</strong>&nbsp;<span
                *ngIf="retailerObj.phone">{{retailerObj.phone | phone}}</span></p>
        <p class="mb-5"><strong>Email:</strong>&nbsp;<span *ngIf="retailerObj.email">{{retailerObj.email}}</span></p>
    </div>
    <ul class="list-group" *ngIf="!retailerObj">
        <li class="p-0 pb-15">
            <h3 class="my-0">No Bill To</h3>
        </li>
    </ul>
</p-dialog>
<p-dialog [(visible)]="showShipLocationDialog"
    [contentStyle]="{'width': '450px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}"
    header="Ship To Locations" modal="true" [maximizable]="false" [closeOnEscape]="true" [draggable]="false"
    styleClass="resp-ship-info overflow-auto cut-dialog-height"  [resizable]="false">
    <div class="h-100 py-10" *ngIf="rShipToObj">
        <p class="mb-5 word-break-all"><strong>Name:</strong>&nbsp;<span
                *ngIf="rShipToObj.name">{{rShipToObj.name}}</span></p>
        <p class="mb-5 word-break-all"><strong>Address:</strong>&nbsp;
            <span *ngIf="rShipToObj.address1">{{rShipToObj.address1}}, </span>
            <span *ngIf="rShipToObj.city">{{rShipToObj.city}}, </span>
            <span *ngIf="rShipToObj.state">{{rShipToObj.state}}, </span>
            <span *ngIf="rShipToObj.country">{{rShipToObj.country}}, </span>
            <span *ngIf="rShipToObj.zip">{{rShipToObj.zip}}.</span>
        </p>
        <p class="mb-5"><strong>Phone:</strong>&nbsp;<span *ngIf="rShipToObj.phone">{{rShipToObj.phone | phone}}</span>
        </p>
        <p class="mb-5"><strong>Email:</strong>&nbsp;<span *ngIf="rShipToObj.email">{{rShipToObj.email}}</span></p>
    </div>
    <ul class="list-group" *ngIf="!rShipToObj">
        <li class="p-0 pb-15">
            <h3 class="my-0">No Ship To</h3>
        </li>
    </ul>
</p-dialog>
<p-dialog [(visible)]="showsalepersonDialog"
    [contentStyle]="{'width': '500px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}"
    header="Your Salesperson" modal="true" [maximizable]="false" [closeOnEscape]="true" [draggable]="false"
    styleClass="resp-salesperson-info overflow-auto cut-dialog-height" [blockScroll]="true"  [resizable]="false">
    <div>
        <ul class="list-group" *ngIf="salesperson.length" style="padding: 0px;">
            <li *ngFor="let sp of salesperson" class="p-0 pb-15">
                <h3 class="m-0 mb-5 d-flex justify-content-between">
                    <span class="d-sales-personname">{{sp.salesperson.name}}</span>
                    <span class="" style="position: relative;">
                        <a href="javascript:;" class="chip bg-warning mr-5" (click)="showEmailDialog(sp)"
                            style="color: #58595b"><i class="far fa-envelope" style="color: #58595b"></i> Contact</a>
                        <div class="sales-typetext">{{sp.salespersonType}}</div>
                    </span>
                </h3>
                <p class="mb-5"><strong>Phone:</strong> {{sp.salesperson.phone | phone}}</p>
                <p class="mb-5"><strong>Email:</strong> {{sp.salesperson.email}}</p>
            </li>
        </ul>
        <ul class="list-group" *ngIf="!salesperson.length">
            <li class="p-0 pb-15">
                <h3 class="my-0">No Salesperson</h3>
            </li>
        </ul>
    </div>
</p-dialog>

<p-dialog [(visible)]="showEmailDialogs"
    [contentStyle]="{'width': '450px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}"
    [blockScroll]="true" header="Contact Salesperson" modal="true" [maximizable]="false" [closeOnEscape]="true"
    [draggable]="false" class="emaildialog" styleClass="emaildialog overflow-auto cut-dialog-height"  [resizable]="false">
    <div>
        <div class="">
            <div class="p-grid p-5">
                <div class="p-col-12">
                    <label for="name">Salesperson Email*</label>
                    <div class="input-error-container">
                        <input [(ngModel)]="salespersonEmail" [required]="true" [disabled]="true" autocomplete="off"
                            class="control" pInputText type="text" />

                    </div>
                </div>
                <div class="p-col-12">
                    <label for="name">Your Message*</label>
                    <div class="input-error-container">
                        <textarea pInputTextarea class="control Wid100 email-msg" [required]="true"
                            [(ngModel)]="emailBody" (blur)="checkMessage(emailBody)"></textarea>
                    </div>
                    <label for="name" *ngIf="errorMsg" class="errorMsg">Please enter a message</label>
                </div>
                <div class="p-col-12 d-flex justify-content-end">
                    <p-button (click)="reachOut()" type="button" [styleClass]="'btn-theme-color mr-5'" label="Reach Out"
                        [disabled]="disableBtn"
                        [icon]="disableBtn ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-paper-plane'"></p-button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- End Dialog -->

<!-- // [B2B-127] (MG 15-APR-2020) modified to duplicate order -->
<p-dialog header="Duplicate Order" [(visible)]="displayOrderDuplicate" [closable]="true" [draggable]="false"
    [blockScroll]="true" [closeOnEscape]="true" [showHeader]="true" [style]="{'width': '650px'}" [positionTop]="60"
    [maximizable]="false" [modal]="true" [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'" class="responsive-ship-loc cut-dialog-height add-cat-dialog dup-order-dialog"   [resizable]="false">
    <form (ngSubmit)="saveDuplicateOrderCheckPO()" [formGroup]="orderDuplicateForm">
        <!-- <div class="p-col-12"> -->

        <div class="p-col-4" style="float: left;">
            <label for="">Contact: <br><b>{{selectedOrderToDuplicate?.retailerContact?.firstName}}
                    {{selectedOrderToDuplicate?.retailerContact?.middleName}}
                    {{selectedOrderToDuplicate?.retailerContact?.lastName}}</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">BillTo: <br><b>{{ selectedOrderToDuplicate?.billToName }}</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">ShipTo: <br><b>{{ selectedOrderToDuplicate?.shipToName }}</b></label>
        </div>
        <div style="clear: both;"></div>
        <div class="p-col-4" style="float: left;">
            <label for="">Salesperson:
                <br><b>{{selectedOrderToDuplicate?.salesperson1 ? selectedOrderToDuplicate?.salesperson1?.name : 'N/A'}}</b></label>
        </div>


        <!-- {{selectedOrderToDuplicate?.}} -->

        <div class="p-col-4" style="float: left;">
            <label for="">PO Number: <br>
                <input id="accountNoDeactiveDup" type="text" (input)="updateChangesMade()"
                    formControlName="selectedOrderToDuplicatePoNumber" pInputText class="wid-100 h-30">
            </label>
            <!-- <label for="">PO Number: <br><b>{{selectedOrderToDuplicate?.poNumber}}-D</b></label> -->
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Order Total:
                <br><b>{{ (selectedOrderToDuplicate?.orderGrossTotal - (selectedOrderToDuplicate?.orderDiscount / 100 * selectedOrderToDuplicate?.orderGrossTotal)) | rtCurrency }}</b></label>
        </div>
        <div style="clear: both;"></div>
        <div class="p-col-4" style="float: left;">
            <label for="">Discount:
                <br><b>{{(selectedOrderToDuplicate?.orderDiscount) ? selectedOrderToDuplicate?.orderDiscount : '0'}}%</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for=""># of Items: <br><b>{{selectedOrderToDuplicate?.orderDetails?.length}}</b></label>
        </div>



        <div class="p-col-4" style="float: left;">
            <label for="">Order Code:
                <br><b>{{(selectedOrderToDuplicate?.orderCode) ? selectedOrderToDuplicate?.orderCode : 'N/A'}}</b></label>
        </div>
        <!-- <div class="p-col-4" style="float: left;" >
                <label for="">Ship Method: <br><b>{{(selectedOrderToDuplicate?.shippingMethod) ? selectedOrderToDuplicate?.shippingMethod : 'N/A'}}</b></label>
            </div>
            <div class="p-col-4" style="float: left;" >
                <label for="">Payment Method: <br><b>{{(selectedOrderToDuplicate?.paymentTerm) ? selectedOrderToDuplicate?.paymentTerm : 'N/A'}}</b></label>
            </div> -->

        <div style="clear: both;"></div>

        <div class="p-col-4" style="float: left;">
            <label for="">Order Date*</label>
            <rs-calendar (dateChange)="updateChangesMade($event, 'orderDateD')" formControlName="orderDateD"
                id="orderDateD" [required]="true"></rs-calendar>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Request Ship Date*</label>
            <rs-calendar (dateChange)="updateChangesMade($event, 'shipDateD')" id="shipDateD"
                formControlName="shipDateD" [required]="true" [minDate]="orderDuplicateForm.value.orderDateD">
            </rs-calendar>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Cancel Date</label>
            <rs-calendar (dateChange)="updateChangesMade($event, 'cancelDateD')" formControlName="cancelDateD"
                id="cancelDateD" [minDate]="orderDuplicateForm.value.shipDateD"></rs-calendar>
        </div>
        <!-- <div class="p-col-6" style="float: left;">
                <label for="">Order Code*</label> <br />
                <p-dropdown required="true" placeholder="Select a Code"></p-dropdown>
            </div> -->
        <!-- </div> -->
        <!-- (dateChange)="datesUpdate(order, 'ORDER_DATE')" [disabled]="!editable" [maxDate]="dateConvert(re.limitedOrderDate)" id="orderDate{{i}}" [(date)]="order.orderDate" -->
        <div style="clear: both"></div>
        <br /><br />
        <div class="" style="text-align: right;">

            <p-button type="submit" [styleClass]="'ui-button-success theme-maroon-btn'" label="Duplicate Order"
                [disabled]="!isValidateDuplicate" [icon]="isSavingData ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-save'">
            </p-button>
            <!-- <button pButton icon="fa fa-save" class="theme-maroon-btn" label="Duplicate Order" [disabled]="!isValidateDuplicate"  type="submit" ></button> -->
            <!-- <app-async-button [icon]="'fas fa-save'" [disabled]="!isValidateDuplicate" [loading]="isSavingData"
            type="submit" class="ui-button ui-button-success" [label]="'Duplicate Order'"></app-async-button> -->
        </div>
    </form>
</p-dialog>
<p-confirmDialog key="duplicate" [appendTo]="'body'" [baseZIndex]="10000"></p-confirmDialog>

<!-- <p-dialog [(visible)]="federalTaxNumber" (onHide)="cancel()"
    [contentStyle]="{'width': '450px', 'max-width': '90vw', 'max-height': '90vh', 'overflow': 'auto'}" header="Warning"
    modal="true" [maximizable]="false" [blockScroll]="true" [closeOnEscape]="true" [resizable]="false">
    <div class="h-100 py-10">
        <p>Your federal tax ID number is missing. Please enter this information in the <b>Manage Company</b>.</p>
    </div>
    <p-footer>
        <button pButton type="button" label="OK" class="theme-maroon-btn edit-save-btn"
            (click)="clickOkToRedirctMange()"></button>
    </p-footer>
</p-dialog> -->
<app-ein-number-dialog [federalTaxNumber]="federalTaxNumber" (closeFederalTaxDialog)="closeFederalTaxDialog($event)"></app-ein-number-dialog>