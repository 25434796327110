export enum ReptimeEntityType {
    INVALID_USER = -1,
    STAFF = 0,
    MANUFACTURER = 1,
    REPGROUP = 2,
    SALESPERSON = 3,
    RETAILER = 4,
    EXISTING_USER = 5,
    ACCOUNTING = 6,
    SYSTEM = 1000
}
export class ReptimeEntity {
    static readonly SALESPERSON_TYPE_PRIMARY = 'Primary';
    static readonly SALESPERSON_TYPE_SECONDARY = 'Secondary';
    static readonly SALESPERSON_TYPE_KEY = 'Key';
    // static readonly limitedOrderDate = '12/31/2099'; /*MM/DD//YYYY*/
    static readonly limitedOrderDate = new Date(new Date().getFullYear() + 100, new Date().getMonth(), new Date().getDate());
    // static readonly SHOPTILEVIEW = 'OFF'; // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic

// Easy access enum handles
    static readonly INVALID_USER = ReptimeEntityType.INVALID_USER;
    static readonly STAFF = ReptimeEntityType.STAFF;
    static readonly MANUFACTURER = ReptimeEntityType.MANUFACTURER;
    static readonly REPGROUP = ReptimeEntityType.REPGROUP;
    static readonly SALESPERSON = ReptimeEntityType.SALESPERSON;
    static readonly RETAILER = ReptimeEntityType.RETAILER;
    static readonly EXISTING_USER = ReptimeEntityType.EXISTING_USER;
    static readonly ACCOUNTING = ReptimeEntityType.ACCOUNTING;
    static readonly SYSTEM = ReptimeEntityType.SYSTEM;

    name: string;
    address1: string;
    address2: string;
    zip: string;
    city: string;
    state: string;
    country: string;
    email: string;
    fax: string;
    phone: string;
    notes: string;
    federalTaxNumber: string;
    salesTaxNumber: string;
    stateTaxNumber: string;
    companyCategory: string;
}
