import { Injectable } from '@angular/core';
import { RTSharedService } from '../rtshared/rtshared.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
// import * as _momentTzone from 'moment-timezone';
// const momentTzone = _momentTzone;
import * as objectPath from 'object-path';
import { UrlToolsService } from '../url-tools.service';
import { environment } from 'src/environments/environment';
import { RetailerService } from '../middleware/retailer/retailer.service';
import { AuthService } from '../../services/middleware/auth/auth.service';

@Injectable()
export class ManufacturerService {
    baseUrl: string;
    selectedCustomer: any;
    constructor(private http: HttpClient,
        private urlTools: UrlToolsService,
        private retailerService: RetailerService,
        private auth: AuthService,
        private rtSharedService: RTSharedService) {
        this.baseUrl = this.rtSharedService.getApiUrl();
    }

    getManufacturerCarrierTypes(manufacturerID: string) {
        const url = `${this.baseUrl}reptime/api/manufacturers/${manufacturerID}/manufacturercarriertypes`;
        return this.http.get(url);
    }

    public getManufacturerPaymentTerms(manufacturerID: string) {
        const url = `${this.baseUrl}reptime/api/manufacturers/${manufacturerID}/manufacturerpaymentterms`;
        return this.http.get(url);
    }

    getActiveManufacturerPromotions(manufacturerID: string) {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // RC-765 (RK-10-APRIL-2020) Fixed: Some browser getting timeZone undefined
        // if (!timeZone) {
        //     timeZone = momentTzone.tz.guess(true); // If set to true, the cache will be ignored and overwritten with the new value.
        // }
        const url = `${this.baseUrl}reptime/api/manufacturers/${manufacturerID}/manufacturerpromotions/active`;
        return this.http.post(url, timeZone);
    }
    // B2B-106 (MJ 27-02-2019 Added) For dynamic promotions page
    getManufacturerPromotions(offset?: any, size?: any, sort?: any, sortOrder?: number, searchParams?: any, viewGrid?: any) {
        const searchTypeParam = objectPath.get(searchParams, 'searchType', 'startswith');
        size = size === undefined ? 25 : size;
        offset = offset === undefined ? 0 : offset;
        sort = sort === undefined ? 'name' : sort;
        sortOrder = sortOrder === undefined ? 1 : sortOrder;
        const params = new HttpParams()
            .append('offset', offset)
            .append('size', size)
            .append('sort', sort)
            .append('searchType', searchTypeParam)
            .append('sortOrder', String(sortOrder));
        let url = '';
        if (viewGrid === false) {
            url = `${environment.apiUrl}reptime/api/manufacturerpromotions/search`;
        } else {
            url = `${environment.apiUrl}reptime/api/manufacturerpromotions/gridView/search`;
        }
        console.log('fetch', JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID);
        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
            searchParams.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        }
        searchParams.requestFromB2B = true;
        if (searchParams === undefined) {
            return this.http.post(url, {}, { params });
        } else {
            return this.http.post(url, searchParams, { params });
        }
    }
    // END

    getManufacturersByRepGroup(retailerData?: any, filterByCatalog?: any, needMfgStats?: any) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const salespersonID = this.rtSharedService.getSalesPersonID();
        const retailerID = this.rtSharedService.getRetailerID();
        const retailerObj = this.rtSharedService.getSelectedShipTo();
        const themesetting = this.auth.getSiteTheme();
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/manufacturer/${repGroupID}/search`;
        // B2B-260 (MJ 06-July-2020) condition for manufacturer catalog added
        let queryStringParam: any;
        if (filterByCatalog) {
            queryStringParam = { showAll: 'true', filterByCatalog: 'true' };
        } else {
            queryStringParam = { showAll: 'true' };
        }
        queryStringParam.findCustomerOrderHistory = true;
        if (retailerObj && retailerObj.retailerID) {
            queryStringParam.customerToFindOrderHistory = retailerObj.retailerID;
        }
        if (retailerObj && retailerObj.recordID) {
            queryStringParam.retailerShipTo = retailerObj.recordID;
        }

        if (needMfgStats !== undefined && needMfgStats !== null) {
            queryStringParam.needMfgStats = needMfgStats;
        }
        queryStringParam.filterByTerritory = this.auth.getRetailerTerritoryFilterFlag();
        queryStringParam.flagBrandPricingPolicy = true;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        const payload: any = { active: true, approved: true };
        // const payload: any = { active: true, approved: true, retailerID: retailerID }; // B2B HOTFIX only retailer MFR showing issue
        // if (retailerID) {
        //     payload.retailerID = retailerID;
        // }
        if (salespersonID) {
            payload.salespersonID = salespersonID;
        }
        const sessionRetailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        let retailerShipToLocationID = sessionRetailerShipToLocationID.recordID;
        if (!sessionRetailerShipToLocationID) {
            sessionStorage.setItem('retailerShipToLocationID', JSON.stringify(retailerData.selectedShipTo));
            retailerShipToLocationID = retailerData.selectedShipTo.recordID;
        }
        if (this.auth.getRetailerTerritoryFilterFlag() === true || (themesetting && themesetting.filterByDivision)) {
            payload.retailerShipToLocationID = retailerShipToLocationID;
        }
        payload.shipToLocationPricingPolicyID = retailerShipToLocationID;

        return this.http.post<any>(url, payload);
    }

    getBrandsByRepGroup(queryStringParam, payload) {
        const repGroupID = this.rtSharedService.getRepGroupID();

        let url = `${this.rtSharedService.getApiUrl()}reptime/api/manufacturer/${repGroupID}/search`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }

        return this.http.post<any>(url, payload);
    }
    sendMfrRequest(data) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const retailerID = this.rtSharedService.getRetailerID();
        const sessionRetailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        const retailerShipToLocationID = sessionRetailerShipToLocationID.recordID ? sessionRetailerShipToLocationID.recordID : null;
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/${repGroupID}/retailermanufacturer/request/send?sendNotificationToManufacturer=true`;
        const payload: any = {
            manufacturerID: data.recordID ? data.recordID : null,
            retailerID: retailerID,
            retailerShipToID: retailerShipToLocationID,
            recordID: ''
        };
        return this.http.post<any>(url, payload);

    }
    // B2B-174 (MJ 26-MARCH-2020) Added for get Manufacturer data
    getSelectedManufacturer(manufacturerID) {
        const sessionRetailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        const retailerShipToLocationID = sessionRetailerShipToLocationID.recordID ? sessionRetailerShipToLocationID.recordID : null;
        const payload = {
            repGroupID: this.rtSharedService.getRepGroupID(),
            flagBrandPricingPolicy: true,
        };
        if (retailerShipToLocationID) {
            payload['shipToLocationPricingPolicyID'] = retailerShipToLocationID
        }

        let manufacturerUrl = `${this.baseUrl}reptime/api/manufacturer/${manufacturerID}`;

        if (payload && !_.isEmpty(payload)) { // if data available or not
            const params: any = new URLSearchParams();
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) { // return true Or fasle
                    payload[key] = encodeURIComponent(payload[key]); // get value of key
                    params.set(key, payload[key]); // set params in key and value
                }
            }
            manufacturerUrl = manufacturerUrl + '?' + params.toString(); // join url with params
        }

        return this.http.get(manufacturerUrl);
    }
    // B2B-174 (MJ 27-MARCH-2020) Added for get Cover Image 
    getCoverImgPath(coverImgPath) {
        let coverImgUrl = `${environment.coverImgBucketUrl}coverImage/${coverImgPath}`;
        return coverImgUrl;
    }
    // B2B-174 (MJ 27-MARCH-2020) Added for get Logo
    getLogoPath(logoPath) {
        let coverImgUrl = `${environment.logoBucketUrl}logo/${logoPath}.png`;
        return coverImgUrl;
    }
    // END
    CheckValidpPoNumber(mfgId: string, ponumber: any, orderid?) {
        let url = `${this.baseUrl}reptime/api/${mfgId}/checkPONumber/${ponumber}`;
        if (orderid) {
            url += '?orderID=' + orderid;
        }
        return this.http.get(url);
    }

    public checkReviewOrRateAvailabilityMFG(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/rate/review/check`;
        return this.http.post<any>(url, payload);
    }
    public getReview(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/manufacturer/review`;
        return this.http.post<any>(url, payload);
    }

    public getAllReviews(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/manufacturer/review/all`;
        return this.http.post<any>(url, payload);
    }

    public saveReview(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/manufacturer/review/save`;
        return this.http.post<any>(url, payload);
    }

    public getAvgItemsRating(payload: any): any {
        // const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/manufacturer/rate/avg`;
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/manufacturer/rate/review/count`;
        return this.http.post<any>(url, payload);
    }

    public getItemsRating(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/manufacturer/rate`;
        return this.http.post<any>(url, payload);
    }

    public saveItemsRating(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/manufacturer/rate/save`;
        return this.http.post<any>(url, payload);
    }

    getDocumentByFolder(mfgID: any, repGroupID: any) {
        let url = `${this.baseUrl}reptime/api/manufacturers/${mfgID}/documents`;
        if (repGroupID) {
            url += '?repGroupID=' + repGroupID;
        }
        return this.http.get(url);
    }
    
    getDocURL(params) {
        let url = this.baseUrl + 'reptime/manufacturer/{companyID}/docs/getFile/{fileHash}';
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }
    public getOrderSummary(manufacturerID: any,repGroupID: any,retailerID: any) {
        const url = `${this.baseUrl}reptime/api/orderSummary/${manufacturerID}`;
        return this.http.post(url,{repGroupID,retailerID});
    }

}
