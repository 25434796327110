<form (submit)="saveRetailerInfo()" [formGroup]="editRetailerForm" autocomplete="off">
    <div style="position: fixed; top:0;left:0;z-index: 5000" #multiDropdownContainer></div>
    <div class="p-grid" style="padding: 5px;">
        <div class="p-col-6">
            <label for="name">Name*</label>
            <div class="input-error-container">
                <input class="control" formControlName="name" id="name" maxlength="50" pInputText
                    autocomplete="new-password" type="text" />
                <i *ngIf="validateChk.name.invalid && (validateChk.name.dirty || validateChk.name.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.name,'Name')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6"><label for="email">Email*</label>
            <div class="input-error-container">
                <input class="control" formControlName="email" id="email" maxlength="100" pInputText
                    autocomplete="new-password" type="text" />
                <i *ngIf="validateChk.email.invalid && (validateChk.email.dirty || validateChk.email.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.email,'Email')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6">
            <label for="address1">Address 1*</label>
            <div class="input-error-container">
                <input class="control" formControlName="address1" maxlength="100" id="address1" pInputText
                    autocomplete="new-password" type="text" />
                <i *ngIf="validateChk.address1.invalid && (validateChk.address1.dirty || validateChk.address1.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.address1,'Address1')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6">
            <label for="address2">Address 2</label>
            <div class="input-error-container">
                <input class="control" formControlName="address2" maxlength="50" id="address2" pInputText
                    autocomplete="new-password" type="text" />
                <i *ngIf="validateChk.address2.invalid && (validateChk.address2.dirty || validateChk.address2.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.address2,'Address2')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6">
            <label for="zip">Zip
                Code{{validateChk && validateChk.country && validateChk.country.value === 'US' ? '*' : ''}}</label>
            <div class="input-error-container">
                <input class="control" formControlName="zip" id="zip" maxlength="20" pInputText
                    autocomplete="new-password" type="text">
                <i *ngIf="validateChk.zip.invalid && (validateChk.zip.dirty || validateChk.zip.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.zip,'Zip')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6">
            <label for="country">Country*</label>
            <div class="input-error-container">
                <p-dropdown (onChange)="countrySpecific()" [filter]="true" [options]="countries"
                    [style]="{'width':'100%'}" [appendTo]="multiDropdownContainer" class="control-dropdown"
                    formControlName="country" id="country"></p-dropdown>
                <i *ngIf="validateChk.country.invalid && (validateChk.country.dirty || validateChk.country.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.country,'Country')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6">
            <label for="city">City*</label>
            <div class="input-error-container">
                <input class="control" formControlName="city" id="city" maxlength="50" pInputText
                    autocomplete="new-password" type="text">
                <i *ngIf="validateChk.city.invalid && (validateChk.city.dirty || validateChk.city.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.city,'City')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6">
            <!-- <div *ngIf="validateChk['country'].value !== 'US';then content else other_content"></div> -->
            <div *ngIf="countriesStateJSON && countriesStateJSON[validateChk['country'].value] && countriesStateJSON[validateChk['country'].value].length;then other_content else content"></div>
            <ng-template #content>
                <label for="non-us-state">State*</label>
                <div class="input-error-container">
                    <input class="control" formControlName="state"
                        autocomplete="new-password" id="non-us-state" maxlength="50" name="state" pInputText
                        type="text">
                    <i *ngIf="validateChk.state.invalid && (validateChk.state.dirty || validateChk.state.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.state,'State')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </ng-template>
            <ng-template #other_content>
                <label for="us-state">State*</label>
                <div class="input-error-container">
                    <p-dropdown [filter]="true" [options]="countriesStateJSON[validateChk['country'].value]"
                        [style]="{'width':'100%'}" class="control-dropdown" formControlName="state" id="us-state">
                    </p-dropdown>
                    <i *ngIf="validateChk.state.invalid && (validateChk.state.dirty || validateChk.state.touched)"
                        class="multiSelect-error  error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.state,'State')" tooltipPosition="left"
                        tooltipEvent="hover"></i>
                </div>
            </ng-template>
        </div>
        <div class="p-col-3"><label for="phone">Phone*</label>
            <div class="input-error-container">
                <!-- <input class="control" formControlName="phone" id="phone" maxlength="12" minlength="10" pInputText pKeyFilter="pnum"
                    autocomplete="off" type="text"> -->
                <p-inputMask [unmask]="true" formControlName="phone" styleClass="control" minlength="10" maxlength="12" id="phone" mask="999-999-9999?99" autocomplete="off" type="text"></p-inputMask>
            </div>
        </div>
        <div class="p-col-3">
            <label for="phoneExtension">Phone Ext #</label>
            <div class="input-error-container">
                <input [maxlength]="10" autocomplete="off" class="control" formControlName="phoneExtension"
                    id="phoneExtension" pInputText size="3" autocomplete="new-password" type="text" />
                <i *ngIf="validateChk.phoneExtension.invalid && (validateChk.phoneExtension.dirty || validateChk.phoneExtension.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.phoneExtension,'Ext #')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-3"><label for="fax">Fax </label>
            <div class="input-error-container">
                <p-inputMask [unmask]="true" formControlName="fax"
                    [style]="{'width':'100%','border':'1px solid #628292'}" id="fax" mask="999-999-9999"
                    autocomplete="off"></p-inputMask>
                <i *ngIf="validateChk.fax.invalid && (validateChk.fax.dirty || validateChk.fax.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.fax,'Fax')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-3"><label for="faxExtension">Fax Ext # </label>
            <div class="input-error-container">
                <input [maxlength]="10" class=" control" formControlName="faxExtension" id="faxExtension" pInputText
                    size="3" autocomplete="new-password" type="text" />
                <i *ngIf="validateChk.faxExtension.invalid && (validateChk.faxExtension.dirty || validateChk.faxExtension.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.faxExtension,'Ext #')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-4">
            <label for="federalTaxNumber">Resale ID/Federal Tax Number (EIN)</label>
            <div class="input-error-container">
                <p-inputMask [unmask]="true" formControlName="federalTaxNumber" styleClass="control"
                    id="federalTaxNumber" [style]="{'width':'100%','border':'1px solid #628292'}" mask="99-9999999999"
                    autocomplete="new-password"></p-inputMask>
                <i *ngIf="validateChk.federalTaxNumber.invalid && (validateChk.federalTaxNumber.dirty || validateChk.federalTaxNumber.touched)"
                    class="error-icon pi pi-info-circle"
                    [pTooltip]="getErrorMsg(validateChk.federalTaxNumber,'Federal Tax Number')" tooltipPosition="left"
                    tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-4">
            <label for="salesTaxNumber">Sales Tax Number</label>
            <div class="input-error-container">
                <input class="control" formControlName="salesTaxNumber" autocomplete="new-password" id="salesTaxNumber"
                    pInputText type="text" maxlength="20" />
                <i *ngIf="validateChk.salesTaxNumber.invalid && (validateChk.salesTaxNumber.dirty || validateChk.salesTaxNumber.touched)"
                    class="error-icon pi pi-info-circle"
                    [pTooltip]="getErrorMsg(validateChk.salesTaxNumber,'Sales Tax Number')" tooltipPosition="left"
                    tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-4">
            <label for="stateTaxNumber">State Tax Number</label>
            <div class="input-error-container">
                <input class="control" formControlName="stateTaxNumber" autocomplete="new-password" id="stateTaxNumber"
                    pInputText type="text" maxlength="20" />
                <i *ngIf="validateChk.stateTaxNumber.invalid && (validateChk.stateTaxNumber.dirty || validateChk.stateTaxNumber.touched)"
                    class="error-icon pi pi-info-circle"
                    [pTooltip]="getErrorMsg(validateChk.stateTaxNumber,'State Tax Number')" tooltipPosition="left"
                    tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6"><label for="website">Website</label>
            <div class="input-error-container">
                <input class=" control" formControlName="website" id="website" maxlength="150" pInputText
                    autocomplete="new-password" type="text" />
                <i *ngIf="validateChk.website.invalid && (validateChk.website.dirty || validateChk.website.touched)"
                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.website,'Website')"
                    tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6" *ngIf="fromPage==='editRetailer' && !isRetailer">
            <label for="repGroupDivision">Assign Retailer To Division</label><br>
            <p-dropdown autoWidth="false" [style]="{'width':'100%'}" id="repGroupDivision"
                formControlName="repGroupDivision" [appendTo]="multiDropdownContainer" [options]="repgroupDivisionList"
                placeholder="Select Division"></p-dropdown>
        </div>
        <div class="p-col-6" *ngIf="!isRetailer"><label for="status">Status*</label> <br />
            <p-selectButton [options]="statusList" formControlName="retailerStatus" id="status"></p-selectButton>
        </div>
    </div>
    <ng-content></ng-content>
</form>