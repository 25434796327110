import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OrderPaymentToken } from '../../../models/order-payment-token';
import { MessageService, SelectItem } from 'primeng/api';
import { OrderService } from '../../../services/middleware/order/order.service';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { RetailerAPIService } from '../../../services/api/retailer-api/retailer-api.service';
import { RetailerPaymentInfo } from '../../../models/retailer-payment-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HtmlToolsService } from '../../../services/html-tools/html-tools.service';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { ReptimeEntity } from '../../../models/reptime-entity';
// [RC-626] (MG 14-APR-2020) modified to make update vault URL
// [RC-830] (MG 24-APR-2020) modified to make update vault URL V3
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';

@Component({
    selector: 'app-add-order-setting-payment-info-dialog',
    templateUrl: './add-order-setting-payment-info-dialog.component.html',
    styleUrls: ['./add-order-setting-payment-info-dialog.component.css']
})
export class AddOrderSettingPaymentInfoDialogComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isOpen: boolean;
    @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saveEvent?: EventEmitter<any> = new EventEmitter<any>();
    orderPaymentInfo = new OrderPaymentToken();
    paymentInfo = new RetailerPaymentInfo();
    saveCardToDB = true;
    paymentTypes: SelectItem[] = [];
    paymentInfoForm: FormGroup;

    @Input() isOrderPayment: boolean;
    @Input() orderID;
    @Input() repGroupID;
    @Input() salespersonID;
    @Input() currentRetailer;
    retailerID: string;
    private loadingRetailerTerms = false;
    private cardCVVSaved = false;
    copyBillToAddress = false;
    loading: boolean = false;

    @Input() set fRetailerID(value: string) {
        this.retailerID = value;
        this.loadRetailerTerms();
    }

    get fRetailerID(): string {
        return this.retailerID;
    }

    monthOptions = [];
    yearOptions = [];
    @ViewChild('cciframe', { static: false }) iframe: ElementRef;


    sessionID = '';
    timestamp = '';
    retailerPaymentTerms: SelectItem[] = [];
    selectedRetailerTerm: RetailerPaymentInfo;

    cardInfoSaved = false;
    cardNumberSaved = false;
    isDisabledForm = false;
    @Input() selectedPaymentInfo: any;

    // [RC-626] (MG 14-APR-2020) modified to make update vault URL
    // [RC-830] (MG 24-APR-2020) modified to make update vault URL V3
    vaultURL = '';
    vaultURLSafe: SafeResourceUrl;
    cvvIframe = false;
    private eventHandler;

    constructor(
        private orderService: OrderService,
        private authService: AuthService,
        private httpClient: HttpClient,
        private retailerService: RetailerAPIService,
        private msgService: MessageService,
        private fb: FormBuilder,
        private htmlTools: HtmlToolsService,
        public sanitizer: DomSanitizer,
        private rtSharedService: RTSharedService,
    ) {
        this.vaultURL = environment.vaultURL;
        this.vaultURLSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.vaultURL);
        // if (window.addEventListener) {
        //     window.addEventListener('message', ($event) => {
        //         console.log('Message Raised');
        //         this.receiveMessage($event);
        //     }, false);
        // }
        this.eventHandler = (event) => {
            // RC-1129 (MJ 11-Aug-2020) creditcard number and cvv validation add
            console.log(event.data, event.data.message);
            if (event.data.message) {
                this.cvvIframe = true;
                if (event.data.message === true) {
                    this.onFormSubmit(event);
                }
                this.onMessageReceived(event);
            } else {
                this.cvvIframe = false;
                this.loading = false;
                this.msgService.clear();
                if (event.data.type === 'both' && !event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfoSettingDialog', summary: 'Error', severity: 'error', detail: 'Please insert Credit Card number and CVV' });
                } else if (event.data.type === 'both' && event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfoSettingDialog', summary: 'Error', severity: 'error', detail: 'Please insert valid Credit Card number and CVV' });
                } else if (event.data.type === 'cc' && !event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfoSettingDialog', summary: 'Error', severity: 'error', detail: 'Please insert Credit Card number' });
                } else if (event.data.type === 'cc' && event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfoSettingDialog', summary: 'Error', severity: 'error', detail: 'Please insert valid Credit Card number' });
                } else if (event.data.type === 'cvv' && !event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfoSettingDialog', summary: 'Error', severity: 'error', detail: 'Please insert CVV' });
                } else if (event.data.type === 'cvv' && event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfoSettingDialog', summary: 'Error', severity: 'error', detail: 'Please insert valid CVV' });
                }
            }
        };
        window.addEventListener('message', this.eventHandler, false);
    }

    private onMessageReceived(event) {
        const data = event.data.message as string;
        if (data && data.toString().startsWith('ORDER TOKEN SAVED')) {
            if (data.toString().endsWith('cc')) {
                this.cardNumberSaved = true;
            } else if (data.toString().endsWith('cvv')) {
                this.cardCVVSaved = true;
            }
            if (event.data.runningCount === event.data.totalCount) {
                if (this.isOrderPayment && data.toString().endsWith('cc')) {
                    this.msgService.clear();
                    this.msgService.add({ detail: 'Card Info Saved to Order', severity: 'success' });
                } else {
                    const repGroupID = this.repGroupID;
                    console.log('Saving Retailer Payment Info....');
                    this.saveRetailerPaymentInfo(repGroupID, this.paymentInfoForm.value);
                    // setTimeout(() => {
                    this.loading = false;
                    this.isOpenChange.emit(false);
                    // }, 3000);
                }
            }
            this.saveEvent.emit();
        } else if (data && data.toString().startsWith('RETAILER TOKEN SAVED')) {
            this.cardNumberSaved = true;
            this.msgService.clear();
            this.msgService.add({ detail: 'Card Info Saved to Retailer', severity: 'success' });
            this.saveEvent.emit();
            if (event.data.runningCount === event.data.totalCount) {
                // setTimeout(() => {
                this.loading = false;
                this.isOpenChange.emit(false);
                // }, 3000);
            }
        }
    }

    ngOnChanges(changes: any) {
        if (changes && changes.selectedPaymentInfo && !_.isEmpty(changes.selectedPaymentInfo.currentValue)) {
            this.selectedPaymentInfo = changes.selectedPaymentInfo.currentValue;
        }
    }

    ngOnInit() {
        this.paymentInfoForm = this.fb.group({
            paymentType: ['', Validators.required],
            cardHolderName: ['', Validators.required],
            billAddress: ['', Validators.required],
            billCity: ['', Validators.required],
            billState: ['', Validators.required],
            billZip: ['', Validators.required],
            expirationMonth: ['', Validators.required],
            expirationYear: ['', Validators.required],
            paymentToken: [''],
            cardNumber: [''],

        });
        // TODO: Fetch valid card payment types from Manufacturer Payment Types
        this.paymentTypes = [
            { label: 'Select Payment Type', value: null },
            { label: 'American Express', value: 'AMEX' },
            { label: 'Discover', value: 'DISCOVER' },
            { label: 'MasterCard', value: 'MASTERCARD' },
            { label: 'Visa', value: 'VISA' }
            // { label: 'Other', value: 'OTHER' }
        ];
        this.loadDateFields();
        if (!this.isOrderPayment) {
            this.setFormFromObject();
        }
        console.log('repgroupId', this.rtSharedService.getRepGroupID());

    }

    setFormFromObject() {
        this.paymentInfoForm.reset();
        this.paymentInfoForm.patchValue(this.selectedPaymentInfo);
    }

    /* RC-424 (RK 17JAN2020) Add copyAddress method */
    copyAddress() {
        const patchObj: any = {
            billAddress: '',
            billCity: '',
            billState: '',
            billZip: ''
        };
        if (this.copyBillToAddress) {
            patchObj.billAddress = [this.currentRetailer.address1, this.currentRetailer.address2].join(', ');
            patchObj.billCity = this.currentRetailer.city;
            patchObj.billState = this.currentRetailer.state;
            patchObj.billZip = this.currentRetailer.zip;
        }
        this.paymentInfoForm.patchValue(patchObj);
    }

    private loadDateFields() {
        this.monthOptions = [
            { label: 'Month', value: null },
            { label: '01', value: '01' },
            { label: '02', value: '02' },
            { label: '03', value: '03' },
            { label: '04', value: '04' },
            { label: '05', value: '05' },
            { label: '06', value: '06' },
            { label: '07', value: '07' },
            { label: '08', value: '08' },
            { label: '09', value: '09' },
            { label: '10', value: '10' },
            { label: '11', value: '11' },
            { label: '12', value: '12' },
        ];

        this.yearOptions = [{ label: 'Year', value: null }];
        const fullYear = new Date().getFullYear();
        for (let i = 0; i < 10; i++) {
            // consolelog('Year = ' + fullYear + ' - ' + (fullYear + i));
            this.yearOptions.push({
                label: ('' + (fullYear + i)).substr(2, 2), value: ('' + (fullYear + i)).substr(2, 2)
            });
        }
    }

    get validateChk() {
        // Convenience getter for easy access to form fields
        return this.paymentInfoForm.controls;
    }


    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.pattern && field.errors.pattern.requiredPattern === '^[0-9]*$') {
            return 'Enter numeric value';
        } else if (field.errors.pattern && field.errors.pattern.requiredPattern
            === '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$') {
            return 'Enter website url e.g. http://www.eg.com or https://www.eg.com';
        } else if (field.errors.pattern) {
            return 'Enter valid value';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
        if (field.errors.maxlength) {
            return 'Maximum length exceed for ' + name;
        }
    }

    onFormSubmit($event) {
        if (!this.iframe) {
            this.cvvIframe = true;
        }
        const isAlphaNumeric = this.checkAlphaNumeric(this.paymentInfoForm.value.cardHolderName);
        this.cardNumberSaved = false;
        this.cardInfoSaved = false;
        if (this.paymentInfoForm.valid || this.isDisabledForm) {
            if (isAlphaNumeric) {
                this.loading = true;
                if (this.cvvIframe) {
                    if (this.isOrderPayment) {
                        this.setOrderInfoFromRetailerInfo(this.paymentInfoForm.value);
                        this.saveOrderPaymentInfo(this.repGroupID, this.orderID, this.orderPaymentInfo);
                    } else {
                        this.saveRetailerPaymentInfo(this.repGroupID, this.paymentInfoForm.value);
                    }
                    this.cvvIframe = false;
                } else {
                    if (this.isOrderPayment) {
                        const data = {
                            type: 'cvvCheck',
                            numberType: this.paymentInfoForm.getRawValue().cardNumber ? 'cvv' : 'ccCvvCheck'
                        };
                        this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(data), this.vaultURL);
                    } else {
                        const data = {
                            type: 'ccCheck'
                        };
                        this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(data), this.vaultURL);
                    }
                }
            } else {
                this.msgService.clear();
                this.msgService.add({ key: 'addPaymentInfoSettingDialog', severity: 'warn', summary: 'Invalid card holder name', detail: 'Card holder name can not be only numeric' });
            }
        } else {
            this.loading = false;
            this.htmlTools.validateAllFields(this.paymentInfoForm);
        }

    }

    // [RC-1350] (CB 09-NOV-2020) Check validation for card holder name is alphanumeric or not
    checkAlphaNumeric(cardHolderName) {
        const letters = /[a-zA-Z]/g;
        if (cardHolderName) {
            if (cardHolderName.match(letters)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    // [RC-626] (MG 14-APR-2020) modified to make update vault URL
    // [RC-830] (MG 24-APR-2020) modified to make update vault URL V3
    private saveIFrameData(repGroupID, id, type) {
        console.log('2. saveIFrameData');
        let bearerToken = sessionStorage.getItem('idToken');
        if (bearerToken != null) {
            bearerToken = bearerToken.substring(1, bearerToken.length - 1);
        }
        if (this.iframe) {
            try {
                console.log('Enter To Iframe');
                // const domains = ['https://vault02-sb.auricsystems.com/corp/reptime/v1/auricenc.html'];
                const domains = [this.vaultURL];
                // const domains = ['https://reptimedev.com/auricenc.html'];
                let data = {};
                console.log('Loading iframe');
                domains.forEach(url => {
                    const baseURL = environment.apiUrl;
                    if (type === 'order') {
                        console.log('IF Order');
                        data = {
                            type: 'order',
                            orderID: id,
                            repGroupID: repGroupID,
                            saveCardToDB: this.saveCardToDB,
                            bearer: bearerToken,
                            isDisabledForm: this.isDisabledForm,
                            baseUrl: baseURL
                        };
                    } else if (type === 'retailer') {
                        console.log('IF retailer');
                        data = {
                            type: 'retailer',
                            retailerID: id,
                            repGroupID: repGroupID,
                            bearer: bearerToken,
                            paymentInfoForm: JSON.stringify(this.paymentInfoForm.value),
                            baseUrl: baseURL
                        };
                    }
                    this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(data), url);
                });

            } catch (e) {
                console.log('Error posting message', e);
            }
        }
    }

    private loadRetailerTerms() {
        console.log('loadRetailerTerms');
        const repGroupID = this.rtSharedService.getRepGroupID() ? this.rtSharedService.getRepGroupID() : undefined;
        if (!this.loadingRetailerTerms) {
            console.log('loadRetailerTerms if');

            this.loadingRetailerTerms = true;
            this.retailerPaymentTerms = [];
            this.retailerPaymentTerms.push({ value: null, label: '--Select Card--' });
            this.retailerService.getPaymentTermsForRetailer(this.retailerID)
                .subscribe((paymentInfoList: RetailerPaymentInfo[]) => {
                    paymentInfoList.forEach(info => {
                        this.retailerPaymentTerms.push({ value: info, label: this.getTermLabel(info) });
                    });
                    this.retailerPaymentTerms.push({ value: new RetailerPaymentInfo(), label: '--Add Card--' });
                    this.loadingRetailerTerms = false;
                });
        }
    }

    private getTermLabel(info) {
        return info.paymentType + ' ' + info.expirationMonth + '/' + info.expirationYear + ' *' + (info.cardNumber ? info.cardNumber : '');
    }

    // [RC-626] (MG 14-APR-2020) modified to make update vault URL
    // [RC-830] (MG 24-APR-2020) modified to make update vault URL V3
    onRetailerPaymentTermChange($event) {
        console.log('onRetailerPaymentTermChange');

        this.selectedRetailerTerm = $event.value;
        this.orderPaymentInfo = new OrderPaymentToken();
        if (this.selectedRetailerTerm && this.selectedRetailerTerm.recordID) {
            console.log('onRetailerPaymentTermChange if');
            this.paymentInfo = this.selectedRetailerTerm;
            this.paymentInfoForm.controls.paymentType.patchValue(this.paymentInfo.paymentType);
            this.paymentInfoForm.controls.cardHolderName.patchValue(this.paymentInfo.cardHolderName);
            this.paymentInfoForm.controls.billAddress.patchValue(this.paymentInfo.billAddress);
            this.paymentInfoForm.controls.billCity.patchValue(this.paymentInfo.billCity);
            this.paymentInfoForm.controls.billState.patchValue(this.paymentInfo.billState);
            this.paymentInfoForm.controls.billZip.patchValue(this.paymentInfo.billZip);
            this.paymentInfoForm.controls.expirationMonth.patchValue(this.paymentInfo.expirationMonth);
            this.paymentInfoForm.controls.expirationYear.patchValue(this.paymentInfo.expirationYear);
            this.paymentInfoForm.controls.paymentToken.patchValue(this.paymentInfo.paymentToken);
            this.paymentInfoForm.controls.cardNumber.patchValue(this.paymentInfo.cardNumber);
            this.isDisabledForm = true;
            // }
            // if (this.selectedRetailerTerm && !this.selectedRetailerTerm.recordID) {
            //     this.isDisabledForm = false;
            //     this.paymentInfo = new RetailerPaymentInfo();
            //     this.orderPaymentInfo = new OrderPaymentToken();
        } else {
            console.log('onRetailerPaymentTermChange else');
            this.isDisabledForm = false;
            this.orderPaymentInfo = new OrderPaymentToken();
            this.paymentInfoForm.reset();
        }
        // const domains = ['https://reptimedev.com/auricenc.html'];
        // const domains = ['https://vault02-sb.auricsystems.com/corp/reptime/v1/auricenc.html'];
        const domains = [this.vaultURL];
        domains.forEach(url => {
            const obj = { hideCC: this.isDisabledForm, baseUrl: environment.apiUrl };
            this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(obj), url);
        });
        this.setFormDisabled();

    }

    private setOrderInfoFromRetailerInfo(pmtInfo: RetailerPaymentInfo) {
        console.log('setOrderInfoFromRetailerInfo');

        this.orderPaymentInfo = new OrderPaymentToken();
        this.orderPaymentInfo.cardHolderName = pmtInfo.cardHolderName;
        this.orderPaymentInfo.billAddress = pmtInfo.billAddress;
        this.orderPaymentInfo.billCity = pmtInfo.billCity;
        this.orderPaymentInfo.billState = pmtInfo.billState;
        this.orderPaymentInfo.billZip = pmtInfo.billZip;
        this.orderPaymentInfo.paymentType = pmtInfo.paymentType;
        this.orderPaymentInfo.expirationMonth = pmtInfo.expirationMonth;
        this.orderPaymentInfo.expirationYear = pmtInfo.expirationYear;
        this.orderPaymentInfo.cardNumber = pmtInfo.cardNumber;
        this.orderPaymentInfo.paymentToken = pmtInfo.paymentToken;    // may want to move this ackend service pmtInfofrom retailer term
    }

    private saveOrderPaymentInfo(repGroupID: string, orderID: number, orderPaymentInfo: OrderPaymentToken) {
        console.log('saveOrderPaymentInfo');
        // saveCardToRetailer param should always be false here, we only want to trigger the save from the iframe when card number exists
        // console.log('saveOrderPaymentInfo', 'repGroupID: ' + repGroupID, 'orderID: ' + orderID, 'orderPaymentInfo: ' + orderPaymentInfo  );
        this.orderService.saveOrderPaymentToken(repGroupID, orderID, orderPaymentInfo, false, this.selectedRetailerTerm).then(res => {
            this.saveIFrameData(repGroupID, orderID, 'order');
            this.msgService.clear();
            // this.msgService.add({summary: 'Card Info Saved to Order', severity: 'success'});
            this.cardInfoSaved = true;
        }, error => {
            console.log(error);
            this.msgService.clear();
            this.msgService.add({ summary: 'Error saving Card Info to Order', severity: 'error' });
            this.cardInfoSaved = false;
        });
    }

    private saveRetailerPaymentInfo(repGroupID: string, retailerPaymentInfo: RetailerPaymentInfo) {
        // console.log('saveRetailerPaymentInfo', 'repGroupID: ' + repGroupID, 'retailerID :' + this.retailerID, 'retailerPaymentInfo :' + this.selectedPaymentInfo );
        console.log('saveRetailerPaymentInfo');

        if (this.selectedPaymentInfo && this.selectedPaymentInfo.recordID) {
            console.log('saveRetailerPaymentInfo if');

            this.setObjectFromForm();
            this.savePaymentInfo();
        } else {
            this.setOrderInfoFromRetailerInfo(this.paymentInfoForm.value);
            console.log('saveRetailerPaymentInfo else');
            this.saveIFrameData(repGroupID, this.retailerID, 'retailer');
        }
    }


    private setFormDisabled() {
        console.log('setFormDisabled');
        if (!this.isDisabledForm) {
            console.log('setFormDisabled if');
            console.log('Enabling Payment Form', this.isDisabledForm);
            this.paymentInfoForm.controls.paymentType.enable();
            this.paymentInfoForm.controls.cardHolderName.enable();
            this.paymentInfoForm.controls.billAddress.enable();
            this.paymentInfoForm.controls.billCity.enable();
            this.paymentInfoForm.controls.billState.enable();
            this.paymentInfoForm.controls.billZip.enable();
            this.paymentInfoForm.controls.expirationMonth.enable();
            this.paymentInfoForm.controls.expirationYear.enable();
            this.paymentInfoForm.controls.paymentToken.enable();
            this.paymentInfoForm.controls.cardNumber.enable();

        } else {
            console.log('setFormDisabled else');
            console.log('Disabling Payment Form');

            this.paymentInfoForm.controls.paymentType.disable();
            this.paymentInfoForm.controls.cardHolderName.disable();
            this.paymentInfoForm.controls.billAddress.disable();
            this.paymentInfoForm.controls.billCity.disable();
            this.paymentInfoForm.controls.billState.disable();
            this.paymentInfoForm.controls.billZip.disable();
            this.paymentInfoForm.controls.expirationMonth.disable();
            this.paymentInfoForm.controls.expirationYear.disable();
            this.paymentInfoForm.controls.paymentToken.disable();
            this.paymentInfoForm.controls.cardNumber.disable();
        }
    }

    // [RC-626] (MG 14-APR-2020) modified to make update vault URL
    // [RC-830] (MG 24-APR-2020) modified to make update vault URL V3
    onIframeReady() {
        console.log('onIframeReady');

        if (!this.isOrderPayment) {
            // const domains = ['https://reptimedev.com/auricenc.html'];
            // const domains = ['https://vault02-sb.auricsystems.com/corp/reptime/v1/auricenc.html'];
            const domains = [this.vaultURL];
            const obj = { hideCVV: true, baseUrl: environment.apiUrl };
            domains.forEach(url => {
                console.log('calll Iframe', url, this.iframe);
                this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(obj), url);
            });
        }
    }

    setObjectFromForm() {
        console.log('setObjectFromForm');

        _.forEach(this.paymentInfoForm.controls, (key, value) => {
            this.selectedPaymentInfo[value] = this.paymentInfoForm.controls[value].value;
        });
    }

    savePaymentInfo() {
        console.log('savePaymentInfo');

        const repGroupID = this.rtSharedService.getRepGroupID() ? this.rtSharedService.getRepGroupID() : undefined;
        this.retailerService.saveRetailerPaymentInfoOrderSetting(repGroupID, this.retailerID, this.selectedPaymentInfo)
            .subscribe((response: RetailerPaymentInfo) => {
                if (response) {
                    this.saveEvent.emit(response);
                    setTimeout(() => {
                        this.loading = false;
                        this.isOpenChange.emit(false);
                    }, 3000);
                    this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Payment Info Saved' });
                } else {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error saving payment info' });
                }
            });
    }

    ngOnDestroy() {
        window.removeEventListener('message', this.eventHandler, false);
    }

}