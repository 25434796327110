<div>
    <h2 style="margin:0">Password requirements:</h2>
    <ul style="list-style-type:none;">
        <li><i [ngClass]="!password.hasError('minlength') && !password.hasError('required') ? 'fa fa-check green' : 'fa fa-times red'"></i>Minimum 8 characters
        </li>
        <li><i [ngClass]="!password.hasError('lowercase') && !password.hasError('required') ? 'fa fa-check green' : 'fa fa-times red'"></i>At least one lower case letter
        </li>
        <li><i [ngClass]="!password.hasError('uppercase') && !password.hasError('required') ? 'fa fa-check green' : 'fa fa-times red'"></i>At least one upper case letter
        </li>
        <li><i [ngClass]="!password.hasError('number') && !password.hasError('required') ? 'fa fa-check green' : 'fa fa-times red'"></i>At least one number
        </li>
        <li><i [ngClass]="!password.hasError('special') && !password.hasError('required') ? 'fa fa-check green' : 'fa fa-times red'"></i>At least one special character (e.g. $, %, #)
        </li>
    </ul>
</div>
