<p-toast key="warnItem" position="top-right"></p-toast>
<div class="reptime-theme">
    <ng-container *ngIf="showLoader">
        <div class="pt-10 center cart-loader main-loader">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
        </div>
    </ng-container>
    <p-card class="card">
        <p-table [columns]="cols" [value]="manufacturers" [scrollable]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 38px;" *ngIf="config.data.warningText !== ''">
                        <div class="itemchkout">
                            <p-checkbox [disabled]="isLoading" name="groupname" value="allSelect"
                                [(ngModel)]="allSelected" (onChange)="selectAll($event)"></p-checkbox>
                        </div>
                    </th>
                    <th *ngFor="let col of columns" [width]="col.width">
                        <div [ngSwitch]="col.class">
                            <div *ngSwitchCase="'price'" [class]="col.class">
                                {{col.header}}
                            </div>
                            <div *ngSwitchDefault>{{col.header}}</div>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData" [ngClass]="{'disabled-row': !rowData['discountedTotal']}">
                    <td style="width: 38px;" *ngIf="config.data.warningText !== ''">
                        <div class="itemchkout" *ngIf="rowData['discountedTotal']">
                            <p-checkbox [disabled]="isLoading" name="groupname" [value]="rowData.recordID"
                                [(ngModel)]="selectedValues" (onChange)="signleSelectChange()"></p-checkbox>
                        </div>
                    </td>
                    <td *ngFor="let col of columns" [style.background]="!rowData.isMinimumMet ? '#ffd6dd': ''"
                        [width]="col.width">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchCase="'minimumOrderAmount'" [class]="col.class">
                                {{(!rowData.isNewRetailer ? (rowData['minimumReorderAmount'] ?
                                rowData['minimumReorderAmount'] : 0) : (rowData[col.field] ? rowData[col.field] : 0)) |
                                rtCurrency}}
                            </div>
                            <div *ngSwitchCase="'name'" style="min-width: 110px">
                                {{rtSharedService.getSeletedLanguage(rowData['name'], 
                                rowData['repGroupManufacturerName'],
                                rowData['repGroupManufacturerName_json'] )}}
                            </div>
                            <div *ngSwitchCase="'discountedTotal'" [class]="col.class">
                                {{(rowData[col.field] ? rowData[col.field] : 0) | rtCurrency}}
                            </div>
                            <div *ngSwitchDefault>
                                {{rowData[col.field]}}
                                <i class="fas fa-exclamation-circle text-danger" tooltipPosition="right" tooltipZIndex="1100000"
                                    pTooltip="Minimum order amount not met for this manufacturer"
                                    *ngIf="col.header === 'Name' &&  !rowData.isMinimumMet"></i>
                            </div>
                        </div>
                        <!-- <div [ngSwitch]="col.class">
                            <div *ngSwitchCase="'price'" [class]="col.class">
                                {{(rowData[col.field] ? rowData[col.field] : 0) | rtCurrency:'USD'}}
                            </div>
                            <div *ngSwitchDefault>
                                {{rowData[col.field]}}
                                <i class="fa fa-exclamation-circle text-danger"
                                    *ngIf="col.header === 'Name' &&  !rowData.isMinimumMet"></i>
                            </div>
                        </div> -->
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
    <!-- // [B2B-92] (MG 26-FEB-2020) modified to warn on min order -->
    <div>
        <div *ngIf="config.data.warningText !== ''" class="mt-15">
            <span> {{config.data.warningText}} </span>
        </div>
        <div class="btn-wpr checkout-dailog-btm">
            <p class="select-text" *ngIf="config.data.warningText !== ''"><span><i aria-hidden="true"
                        class="ui-button-icon-left ui-clickable fa fa-fw fa-check-square-o"
                        style="color: red;"></i></span> Select the orders you would like to checkout today, the
                unselected orders<br> will remain in the basket for future.</p>
            <div *ngIf="config.data.warningText !== ''"
                class="d-flex justify-content-end mt-10 ml-auto checkout-dailog-act">
                <button (click)="close('shop')"
                    *ngIf="config.data.actionType === 'both' || config.data.actionType === 'shop'" type="button"
                    class="ui-button ui-button-success btn-theme-color mr-10" pButton label="Continue Shopping"
                    icon="fas fa-shopping-basket"></button>
                <button (click)="verifyCheckout('checkout')" [disabled]="isLoading"
                    [icon]="isLoading? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-fw fa-arrow-right'"
                    *ngIf="config.data.actionType === 'both' || config.data.actionType === 'checkout'" type="button"
                    class="ui-button ui-button-success theme-maroon-btn" pButton label="Checkout"></button>
            </div>
        </div>
    </div>
</div>
<app-ein-number-dialog [federalTaxNumber]="showEinAlert" (closeFederalTaxDialog)="closeFederalTaxDialog($event)"></app-ein-number-dialog>