import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import { environment } from '../../environments/environment';
@Pipe({
    name: 'rtDate'
})
export class RtDatePipe implements PipeTransform {

    constructor(private dp: DatePipe) {
    }

    transform(value: any, format = 'MM-dd-yyyy', timezone?: string, locale?: string) {
        format = environment.rtDateFormat;
        try {
            if (value && (String(value)).indexOf('-') > -1) {
                return this.dp.transform(value.split('-').join('/'), format, timezone, locale);
            } else {
                return this.dp.transform(value, format, timezone, locale);
            }
        } catch (E) {
            if (!value) {
                return;
            }
            return this.dp.transform(value, format, timezone, locale);
        }
    }

}
