<div class="reptime-theme pb-10">
    <ng-container *ngIf="showLoader">
        <div class="pt-10 center cart-loader main-loader">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
        </div>
    </ng-container>
    <div *ngIf="likeListData.length !== 0">
        <div class="" >
            <div class="p-grid p-5">
                <div class="p-col-12">
                    <label for="name">Email to:*</label>
                    <div class="input-error-container">
                        <p-dropdown (onChange)="selectEmail($event)" [options]="retailerEmail" [showClear]="true" class="like-emaillist"></p-dropdown>                            
                    </div>
                </div>
                <div class="p-col-12" *ngIf="newEmailShow">
                    <label for="name">New Email*</label>
                    <div class="input-error-container">
                        <input  autocomplete="off" [required]="true" (keyup)="validateEmail()" [class.fill-error]='!isValidateEmail' [(ngModel)]="newEmail" class="control" pInputText type="email" />                            
                    </div>
                    <label for="name" *ngIf="errormsgEmail" class="errorMsg">Please enter valid Email</label>
                </div>
                <div class="p-col-12">
                    <label for="name">Body Message*</label>
                    <div class="input-error-container">
                        <textarea pInputTextarea class="control Wid100 email-msg" [required]="true" [(ngModel)]="emailBody" (blur)="checkMessage(emailBody)"></textarea>
                    </div>
                    <label for="name" *ngIf="errorMsg" class="errorMsg">Please enter a message</label>
                </div>
                <div class="p-col-12 d-flex justify-content-end">
                    <p-button (click)="reachOut()" type="button" [styleClass]="'btn-theme-color mr-5'" label="Send List" [disabled]="disableBtn" [icon]="disableBtn ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-paper-plane'" ></p-button>    
                    <button [label]="'Clear all'" (click)="removeAllList()"
                    class="ui-btn  ui-button-primary ui-button theme-line-btnprnt border-all"
                    *ngIf="likeListData.length != 0 && !showLoader" [icon]="'fa fa-close'"
                    pButton type="button"></button>
                </div>
            </div>
        </div>
        <div class='fav-item-list'>
            <ng-container *ngIf="showDataLoader">
                <div class="pt-10 center cart-loader">
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
                </div>
            </ng-container>
            <div class="like-list-main" *ngFor="let data of likeListData; index as i">
                <div class="like-list-inner">
                    <p-card class="like-main-block">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="like-detail-block Wid80 d-flex align-items-center">
                                <div class="like-img-block mr-15">
                                    <img *ngIf="data.item.imageError" src="{{data.item.profilePic}}" onerror="this.onerror=null;this.src='assets/images/no-image-available.png';" />
                                    <img *ngIf="!data.item.imageError" src="{{data.item.fullPicturePath}}" (error)="data.item.fullPicturePath = null;data.item.imageError=true" />
                                </div>
                                <div class="like-img-detail">
                                    <div class="like-item-name">
                                        <b class="like-item-id">{{data.item.itemNumber}}</b> - {{data.item.name}}
                                    </div>
                                    <div class="like-mfr-name">{{data.item.manufacturerName}}</div>
                                </div>
                            </div>
                            <!-- B2B-167 (NL 18-MAR-2020) add close icon and remove-->
                            <div class="like-icon d-flex align-items-center">
                                <a href="javascript:;" class="single-like mr-15">
                                    <i class="fa fa-heart"></i>
                                </a>
                                <a href="javascript:;" (click)='heartClickEvent(data.item, true, i)' class="single-like remove-like mr-5">
                                    <i class="fa fa-close"></i>
                                </a>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </div>
    <b class="d-flex justify-content-center pt-26" *ngIf="likeListData.length === 0 && !showLoader">No items selected in this favorite list</b>
</div>
<p-toast position="top-right"></p-toast>