import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {RTSharedService} from '../../rtshared/rtshared.service';

@Injectable()
export class DataAPIService {

    private baseUrl;

    constructor(private http: HttpClient,
                private rtSharedService: RTSharedService) {
        this.baseUrl = this.rtSharedService.getApiUrl();
    }

    getDefaultCarrierTypes() {
        return this.http.get(`${this.baseUrl}reptime/api/repgroup/carriertypes`);
    }

    getDefaultOrderCodes() {
        return this.http.get(`${this.baseUrl}reptime/api/repgroup/ordercodes`);
    }

    getDefaultPaymentTerms() {
        return this.http.get(`${this.baseUrl}reptime/api/repgroup/paymentterms`);
    }
}
