import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Retailer} from '../../../models/retailer';
import {FormBuilder, FormGroup} from '@angular/forms';

import {RepgroupDivision} from '../../../models/repgroup-division';
import {Subject, zip} from 'rxjs';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import {APIResponse} from '../../../models/api-response';
import {ReptimeEntity} from '../../../models/reptime-entity';
import {CompanyAPIService} from '../../../services/api/company-api/company-api.service';
import {RepgroupService} from '../../../services/api/repgroup.service';
import {SalespersonService} from '../../../services/api/salesperson.service';
import {RTSharedService} from '../../../services/rtshared/rtshared.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'rs-retailer-advanced',
    templateUrl: 'retailer-advanced.component.html',
    styleUrls: ['retailer-advanced.component.scss']
})
export class RetailerAdvancedComponent implements OnInit, OnChanges, OnDestroy {

    @Input() isNewCustomerWizard = false;
    @Output() previousStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() selectedRetailer: Retailer;
    @Input() repGroupID: string;
    @Input() selectedRepGroupRetailer: any;
    @Input() selectedRepGroupSalespersonRetailer: any;
    editRetailerAdvancedForm: FormGroup;
    tableError: boolean;
    isLoading: boolean;
    repgroupDivisionList: RepgroupDivision[];
    categories: any[];
    cols: any[];
    salesPersonTable: any[] = [];
    private readonly onDestroyGetDivisionsByCompany = new Subject<void>();
    constructor(private fb: FormBuilder,
                private companyService: CompanyAPIService,
                private repgroupService: RepgroupService,
                private rtSharedService: RTSharedService,
                private salespersonService: SalespersonService,
                private msgService: MessageService) {
        this.initForm();
    }

    get validateChk() {
        return this.editRetailerAdvancedForm && this.editRetailerAdvancedForm.controls;
    }

    ngOnDestroy() {
        this.onDestroyGetDivisionsByCompany.next();
    }

    ngOnChanges(changes: any) {
        if (changes && (changes.selectedRepGroupRetailer && !_.isEmpty(changes.selectedRepGroupRetailer.currentValue)) &&
            (changes.selectedRepGroupSalespersonRetailer && !_.isEmpty(changes.selectedRepGroupSalespersonRetailer.currentValue))) {
            this.selectedRepGroupRetailer = changes.selectedRepGroupRetailer.currentValue;
            this.selectedRepGroupSalespersonRetailer = changes.selectedRepGroupSalespersonRetailer.currentValue;
        }
        if (changes && changes.repGroupID && changes.repGroupID.currentValue) {
            this.repGroupID = changes.repGroupID.currentValue;
        }
    }

    initForm() {
        this.isLoading = true;
        this.editRetailerAdvancedForm = this.fb.group({
            retailerCategory: [null],
            repGroupDivision: ['']
        });
    }

    ngOnInit() {
        this.onDestroyGetDivisionsByCompany.next();
        zip(this.companyService.getCompanyCategories(),
            this.repgroupService.getDivisionsByCompany(this.repGroupID))
            .pipe(takeUntil(this.onDestroyGetDivisionsByCompany)).subscribe((response: any) => {
                this.categories = response[0] ? response[0].map((d) => {
                    const c: any = {};
                    c.label = d.name;
                    c.value = d;
                    return c;
                }) : [];
                this.repgroupDivisionList = response[1].success ? response[1].data.map((d) => {
                    const c: any = {};
                    c.label = d.name;
                    c.value = d;
                    return c;
                }) : [];
                this.isLoading = false;
            });
    }

    saveRetailerAdvancedInfo($event) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        if (this.editRetailerAdvancedForm.valid) {
            this.setRetailerAdvancedFromForm();
            this.nextStepEvent.next({
                selectedRepGroupRetailer: this.selectedRepGroupRetailer,
                selectedRepGroupSalespersonRetailer: this.selectedRepGroupSalespersonRetailer
            });
        } else {
            this.tableError = true;
        }
    }

    isSalesPerson() {
        return this.rtSharedService.getEntityType() === 'Salesperson';
    }

    private setRetailerAdvancedFromForm() {
        this.selectedRepGroupRetailer.retailerCategory = objectPath.
        get(this.editRetailerAdvancedForm, 'controls.retailerCategory.value.name', '');
        this.selectedRepGroupRetailer.repGroupDivision = objectPath.
        get(this.editRetailerAdvancedForm, 'controls.repGroupDivision.value', '');
        this.selectedRepGroupSalespersonRetailer = this.salesPersonTable;
    }

}
