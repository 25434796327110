import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'rs-wizard-buttons',
    templateUrl: './wizard-buttons.component.html',
    styleUrls: ['./wizard-buttons.component.scss']
})
export class WizardButtonsComponent implements OnInit {
    @Input() stepsArr = [];
    @Input() activeIndex = 0;
    @Input() finishLoader = false;
    @Input() previousLoader = false;
    @Input() nextLoader = false;
    @Output() previousEvent?: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

}

