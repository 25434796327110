import {Options} from '../../models/options';

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}

export function mergeOptions(oldOptions: Options, newOptions?: Options): Options {
    if (!newOptions) {
        return oldOptions;
    }
    const apiUrl: string = isPresent(newOptions.apiUrl) ? newOptions.apiUrl : oldOptions.apiUrl;
    const imageUrl: string = isPresent(newOptions.imageUrl) ? newOptions.imageUrl : oldOptions.imageUrl;
    const projectName: string = isPresent(newOptions.projectName) ? newOptions.projectName : oldOptions.projectName;
    return {imageUrl, apiUrl, projectName};
}
