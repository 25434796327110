import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-resized-image',
    templateUrl: './resized-image.component.html',
    styleUrls: ['./resized-image.component.scss']
})
export class ResizedImageComponent implements OnInit {

    public resizedImageError = false;
    public ogImageError = false;
    // public imageLoading = true;
    @Input() imgTagClass = '';
    @Input() ogPicturePath: string;
    @Input() resizedPicturePath: any;
    @Output() imageLoadChange = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
        if (this.resizedPicturePath === undefined || this.resizedPicturePath === null) {
            this.resizedImageError = true;
        } else {
            if (this.resizedPicturePath.hasOwnProperty('changingThisBreaksApplicationSecurity')) {
                this.resizedPicturePath = this.resizedPicturePath.changingThisBreaksApplicationSecurity;
            }
        }
        // console.log('resizedPicturePath', this.resizedPicturePath);
    }

    loaded(status) {
        // console.log('loaded', which);
        // this.imageLoading = status;
        this.imageLoadChange.emit(status);
    }
}
