import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationService, DialogService, MessageService } from 'primeng/api';
import { RetailerAPIService } from '../../services/api/retailer-api/retailer-api.service';
import * as objectPath from 'object-path';
import * as _ from 'lodash';
import { EditShipToDialogComponent } from '../edit-ship-to-dialog/edit-ship-to-dialog.component';
import { RetailerShipToLocation } from '../../models/retailer-ship-to-location';
import { RTSharedService } from '../../services/rtshared/rtshared.service';
import { AuthService } from '../../services/middleware/auth/auth.service';
@Component({
  selector: 'app-retailer-ship-to-location-table',
  templateUrl: './retailer-ship-to-location-table.component.html',
  styleUrls: ['./retailer-ship-to-location-table.component.scss']
})
export class RetailerShipToLocationListTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() retailerData: any;
  @Input() retailerID: string;
  advanceOptions = [];
  isResetLoading: boolean;
  isSubmitLoading: boolean;
  searchForm: any;
  loading = true;
  shipToCols: any[];
  shipToLocations: Array<RetailerShipToLocation> = [];
  selectedShippingArray: RetailerShipToLocation[];
  selectedShipToLocation: RetailerShipToLocation;
  displayShipToLocationsDialog = false;
  placeholderForSearchBar = 'Search';

  private readonly onDestroy = new Subject<void>();
  sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
  checkBoxPrimaryTrueOrFalse = false;
  constructor(
    private confirmationService: ConfirmationService,
    private msgService: MessageService,
    private retailerAPIService: RetailerAPIService,
    public dialogService: DialogService,
    public rtSharedService: RTSharedService,
    public auth: AuthService,
  ) {
    this.advanceOptions = [
      { label: 'All', value: { searchByName: 'all', key: 'all' } }
    ];
  }

  ngOnInit() {
    this.shipToCols = [
      { field: 'name', header: 'Name', width: '150px' , spinner: true, sortField: 'name'},
      { field: 'address1', header: 'Address', width: '200px', spinner: true,  sortField: 'address1' },
      { field: 'city', header: 'City', width: '150px' , spinner: true,  sortField: 'city'},
      { field: 'state', header: 'State', width: '150px' , spinner: true,  sortField: 'state'},
      { field: 'zip', header: 'Zip', width: '150px' , spinner: true,  sortField: 'zip'},
      { field: 'phone', header: 'Phone', width: '150px', spinner: true,  sortField: 'phone' }
    ];
    const defaultSearch = {
      all: null,
      searchType: 'contains'
    };
    this.loadShipToLocations(defaultSearch);
  }
  ngOnChanges(changes: SimpleChanges) {
  }
  addShipToLocation() {
    this.selectedShipToLocation = new RetailerShipToLocation();
    const mydata: any = {
      retailerID: this.retailerID,
    };
    const header = 'Add Ship To Location';

    const ref = this.dialogService.open(EditShipToDialogComponent, {
      header,
      width: '50%',
      height: 'auto',
      styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
      closable: true,
      data: mydata
    });

    ref.onClose.subscribe((updateData: any) => {
      if (updateData && !updateData.isOrderOnly) {

        const defaultSearch = {
          all: null,
          searchType: 'contains'
        };
        this.loadShipToLocations(defaultSearch);
      }
    });
  }

  confirmDelete() {
    // this.confirmationService.confirm({
    //   message: 'Are you sure that you want to delete ' +
    //     this.selectedShippingArray.length + ' shipping location' +
    //     (this.selectedShippingArray.length === 1 ? '' : 's') + '?',
    //   header: 'Delete Selected',
    //   icon: 'pi pi-exclamation-triangle',
    //   acceptLabel: 'Yes',
    //   rejectLabel: 'No',
    //   accept: () => {
    //     this.deleteSelectedShipToList();
    //   },
    //   reject: () => {
    //   },
    //   key: 'mfrDialog'
    // });
    this.confirmationService.confirm({
      message: `Do you want to transfer all orders to the primary shipping location?
      <br><br>Click 'Yes' to delete the current ship-to location and move the associated orders to the primary shipping location.
      <br>Click 'No' to delete the current ship-to location and all associated orders.
      <br><b>This process cannot be undone</b>`,
      header: 'Delete confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: 'shipToDeleteDialog',
      accept: async () => {
        this.deleteSelectedShipToList(true);
      },
      reject:  (type) => {
        if (type === 1) {
          this.deleteSelectedShipToList(false);
        }
      }
    });
  }

  deleteSelectedShipToList(moveDataToPrimary) {
    this.selectedShippingArray.forEach(s => {
      s.recordDeleted = true;
      s['moveDataToPrimary'] = moveDataToPrimary;
      this.selectedShipToLocation = s;
      this.deleteSelectedShipTo();
    });
  }

  reset($event) {
    this.searchForm = $event;
    this.isResetLoading = true;
    this.loadShipToLocations(this.searchForm);
  }

  loadShipToLocations(searchParams) {
    this.loading = true;
    this.onDestroy.next();
    this.retailerAPIService.getRetailerShipToByRetailerID(this.retailerID, searchParams).subscribe((response: any) => {
      if (response.success) {
        this.shipToLocations = response.data;
        this.selectedShippingArray = [];
        // RK (12-MAY-2021) => Fix deleted shiptolocation id passing issue in placing order time.
        if (sessionStorage.getItem('retailerShipToLocationID')) {
          const avilableShipToLocations = [];
          _.forEach(this.retailerData.shipToLocations, (res, index) => {
            if (_.find(this.shipToLocations, (o) => o.recordID === (res.recordID))) {
              avilableShipToLocations.push(res);
            }
          });
          this.retailerData.shipToLocations = avilableShipToLocations;
          const sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
          if (!_.find(this.shipToLocations, (o) => o.recordID === (sessionLocationId.recordID))) {
            this.rtSharedService.setSelectedShipTo(this.retailerData.shipToLocations[0]);
            sessionStorage.setItem('retailerShipToLocationID', JSON.stringify(this.retailerData.shipToLocations[0]));
          } else {
            const x = _.filter(this.retailerData.shipToLocations, (c: any) => {
              return c.recordID !== sessionLocationId.recordID;
            });
          }
        }
      } else {
        this.msgService.add({
          severity: 'error', summary: 'Error', detail: objectPath.get(response,
            'error.message', 'Error occurred during services request')
        });
      }
      this.loading = false;
      this.isSubmitLoading = false;
      this.isResetLoading = false;
    }, (error) => {
      this.loading = false;
      this.isSubmitLoading = false;
      this.isResetLoading = false;
      this.msgService.add({
        severity: 'error', summary: 'Error', detail: objectPath.get(error,
          'message', 'Error occurred during services request')
      });
    });
  }

  search($event) {
    this.searchForm = $event;
    this.isSubmitLoading = true;
    const searchType = $event.searchType;
    this.loadShipToLocations(this.searchForm);
  }

  initSearchObj($event) {
    this.searchForm = $event;
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  onHeaderCheckboxToggle($event: any, rows: number) {
    const isChecked = $event.checked;
    this.selectedShippingArray = [];
    if (isChecked) {
      this.selectedShippingArray = this.shipToLocations;
    }
  }

  editShipToLocation($event: MouseEvent, rowData, type?, index?) {

    const mydata: any = {
      retailerID: this.retailerID,
    };
    const header = 'Edit Ship To Location';
    if (type.toLowerCase() === 'edit') {
      mydata.selectedShipToLocation = rowData;
    }
    const ref = this.dialogService.open(EditShipToDialogComponent, {
      header,
      width: '50%',
      height: 'auto',
      styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
      closable: true,
      data: mydata
    });

    ref.onClose.subscribe((updateData: any) => {
      if (updateData && !updateData.isOrderOnly) {
        this.msgService.clear();
        this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Ship To Location Edited' });
        this.shipToLocations.splice(this.shipToLocations.indexOf(this.selectedShipToLocation), 1);
        this.loadShipToLocations($event);
      }
    });
  }


  removeShipToLocation($event, rowData) {
    // this.confirmationService.confirm({
    //   message: `Do you want to delete this record?.
    //   <br><br>Click 'Yes' to delete current ship to location and move associated order to primary shipping location
    //   <br>Click 'No' to delete current ship to location and associated order`,
    //   header: 'Delete confirmation',
    //   icon: 'pi pi-exclamation-triangle',
    //   acceptLabel: 'Yes',
    //   rejectLabel: 'No',
    //   accept: async () => {
    //     this.selectedShipToLocation = rowData;
    //     this.selectedShipToLocation.recordDeleted = true;
    //     this.deleteSelectedShipTo();
    //   },
    //   reject: () => {
    //   },
    //   key: 'mfrDialog'
    // });
    const hasPrimaryLocation = _.find(this.shipToLocations, (o) => o.isPrimary === true);
    if (hasPrimaryLocation) {
      this.confirmationService.confirm({
        message: `Do you want to transfer all orders to the primary shipping location?
        <br><br>Click 'Yes' to delete the current ship-to location and move the associated orders to the primary shipping location.
        <br>Click 'No' to delete the current ship-to location and all associated orders.
        <br><b>This process cannot be undone</b>`,
        header: 'Delete confirmation',
        icon: 'pi pi-exclamation-triangle',
        key: 'shipToDeleteDialog',
        accept: async () => {
          this.selectedShipToLocation = rowData;
          this.selectedShipToLocation.recordDeleted = true;
          this.selectedShipToLocation['moveDataToPrimary'] = true;
          this.deleteSelectedShipTo();
        },
        reject:  (type) => {
          if (type === 1) {
            this.selectedShipToLocation = rowData;
            this.selectedShipToLocation['moveDataToPrimary'] = false;
            this.selectedShipToLocation.recordDeleted = true;
            this.deleteSelectedShipTo();
          }
        }
      });
    } else {
      this.msgService.add({ severity: 'error', summary: 'Error',
       detail: 'You must have at least 1 primary shipping location to remove the current shipping location and to transfer orders.' });
    }
  }

  deleteSelectedShipTo() {
    this.retailerAPIService.saveRetailerShipTo(this.retailerID, this.selectedShipToLocation)
      .subscribe((data: RetailerShipToLocation) => {
        if (data) {
          this.msgService.clear();
          if (this.selectedShipToLocation.recordDeleted === true) {
            this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Ship To Location Deleted' });
            this.shipToLocations.splice(this.shipToLocations.indexOf(this.selectedShipToLocation), 1);
            this.selectedShippingArray.splice(this.selectedShippingArray.indexOf(this.selectedShipToLocation), 1);
          }
          this.onShipToClose();
        } else {
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error saving ship to location' });
        }
      });
  }

  onShipToClose() {
    this.displayShipToLocationsDialog = false;
    const defaultSearch = {
      all: null,
      searchType: 'contains'
    };
    this.loadShipToLocations(defaultSearch);
  }

  changeTableCheckbox(e) {
    // checkBoxPrimaryTrueOrFalse
    // this.selectedShippingArray = [];
    const allcheck = [];
    this.selectedShippingArray.forEach((o) => {
      allcheck.push( o['isPrimary']);
    });

    if (allcheck.some((item) => item === true)) {
      this.checkBoxPrimaryTrueOrFalse =  false;
    } else {
      this.checkBoxPrimaryTrueOrFalse = true;
    }
  }
}
