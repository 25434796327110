<p-toast key="addItem" position="top-right" [baseZIndex]="110000"></p-toast>
<p-card class="card" [ngClass]="isLogin ? 'login-card-header': 'logout-card-header'">
    <div [ngClass]="{'sticky-header-basket': fromPage !== 'ORDER_PAGE'}"
        [ngStyle]="{'top': stickyTop?stickyTop.offsetHeight+'px' : headerHeight}">
        <p-header class="card-header search-tool-box">
            <!-- B2B-102 (NL 25-FEB-2020) add shimmer effect HTML    -->
            <!-- shimmer effect -->
            <div class="Wid100 d-flex align-items-center" style="display: none !important;">
                <div class="flex-1 search-box p-col-6 p-0">
                    <div class="shimmer-effect p-20 Wid100"></div>
                </div>
                <div
                    class=" p-col-6 flex-1 search-actions mfg-dropdown rt-brows-center d-flex align-items-center justify-content-between p-0">
                    <div class="callout-dropdown-part p-col-4">
                        <div class="shimmer-effect p-20 Wid100"></div>
                    </div>
                    <div class="">
                        <span class="shimmer-effect p-15 mr-2"></span>
                        <span class="shimmer-effect p-15"></span>
                    </div>
                </div>
            </div>
            <!-- End -->
            <div class="flex-1 search-box"
                [ngStyle]="pageType && !isIframeWindow?{'display': 'block'} : {'display': 'block'}" [ngClass]="whichPage === 'vendor' && auth.getshowManufacturerItemCategories() ? 'with-mfr-btn': 'without-mfr-btn'">
                <a href="javascript:;" id="show_sidebar" (click)="show_sidebar();"><i class="fa fa-bars"
                        aria-hidden="true"></i> Filter</a>
                <rs-advanced-search [placeholderForSearchBar]="placeholderForSearchBar"
                    [advanceOptions]="advanceOptions" [dateRangeOptions]="dateRangeOptions"
                    [dateRangeLabel]="'Item Modified Date Range'" [isStatusActive]="false" [isAvailable]="true"
                    [isResetLoading]="isResetLoading" [typeList]="typeList" [isSubmitLoading]="isSubmitLoading"
                    [searchText]="searchText" [defaultSearchBy]='isQuickOrder? "" : ""' (submitEvent)="search($event)"
                    (resetEvent)="reset($event)" [searchFormData]='searchFormData' (ready)="initSearchObj($event)">
                </rs-advanced-search>
            </div>

            <div class="search-actions mfg-dropdown rt-brows-center" *ngIf="isLogin">
                <!-- B2B-73 (RK 14-FEB-2020): Mfg listing part remove from here and move on sidebar.component.ts-->
                <!-- <p-dropdown [options]="manufacturersArr" *ngIf="fromPage !== 'ORDER_PAGE'"
                            [styleClass]="'m-5'"
                            [(ngModel)]="selectedManufacturer"
                            (onChange)="search(searchForm)"
                            filter="true"
                            [appendTo]="'body'"
                            placeholder="Select Manufacturer"
                            optionLabel="name"
                            [disabled]="loadingTableData"
                            [showClear]="true">
                </p-dropdown>
                <div class="callout-dropdown-part p-col-4" *ngIf="fromPage !== 'ORDER_PAGE'">
                    <div style="position: fixed; top:0;left:0;z-index: 5000" #multiDropdownContainer></div>
                    <p-multiSelect  [(ngModel)]="calloutValues"
                        class="control optional d-inline-block p-ui-multiselect" 
                        *ngIf="fromPage !== 'ORDER_PAGE'" 
                        [options]="calloutData"
                        optionLabel="title" 
                        (onChange)="searchCallout($event)"  
                        [appendTo]="multiDropdownContainer" 
                        [styleClass]="'m-5'" 
                        defaultLabel="Select Callouts" 
                        [disabled]="loadingTableData" 
                        [filter]="true" maxSelectedLabels="3"
                        displaySelectedLabel="false"
                        selectedItemsLabel="{0} selected Items">
                    </p-multiSelect>
                </div> -->
                <label *ngIf="fromPage === 'ORDER_PAGE'" class="p-5">
                    <b>
                     <!-- {{(selectedManufacturer.refrenceName)?selectedManufacturer.refrenceName:selectedManufacturer.name
                       }} -->
                       {{rtSharedService.getSeletedLanguage(selectedManufacturer.refrenceName, 
                        selectedManufacturer.repGroupManufacturerName,
                        selectedManufacturer.repGroupManufacturerName_json )}}
                    </b>
                </label>
                <!-- B2B-151 (NL 09-MAR-2020) comment toggle button-->
                <div class="btn-group float-right" *ngIf="showDisplayViewOption && isLogin">
                    <div class="ui-selectbutton" *ngIf="fromPage === 'ORDER_PAGE'">
                        <button (click)="displayTable()" label="" class="ui-button ui-button-icon-only ui-state-default"
                            [class.ui-state-active]="showTable" icon="fa fa-bars ui-button-icon-left" pButton></button>
                        <button (click)="displayGrid()" label="" class="ui-button ui-button-icon-only ui-state-default"
                            [class.ui-state-active]="!showTable" icon="fa fa-th-large ui-button-icon-left"
                            pButton></button>
                    </div>
                    <!-- <button (click)="itemListingTable.exportCSV()" icon="fa fa-download" label="Export to .CSV"
                            pButton
                            class="ui-button-success ui-button"></button> -->
                </div>
                <div class="btn-group" *ngIf="fromPage === 'ORDER_PAGE' && !pageType && isShowDownload">
                    <button class="icon-theme-color mx-5 ui-button-secondary" pTooltip="Download Images"
                        (click)="downloadimageszip()" tooltipPosition="bottom" [disabled]="!selecteditems.length"
                        pButton label="Images" [icon]="'pi pi-download'"></button>
                </div>
                <div class="view-option d-flex justify-content-between align-items-center"
                    *ngIf="fromPage !== 'ORDER_PAGE'">
                    <div class="d-flex align-items-center topbar-option">
                        <div class="category-filter position-relative" *ngIf="whichPage === 'vendor' && auth.getshowManufacturerItemCategories()" #attachHere>
                            <button class="btn-theme-color ui-button-info" label="MFR Collections"
                                icon="fas fa-filter" (click)="category_content.toggle($event)" pButton
                                style="position: relative;"></button>
                            <p-overlayPanel #category_content [styleClass]="'category-info-sec'" [appendTo]="attachHere"
                                [dismissable]="false" showCloseIcon="true">
                                <div class="category-info-inner">
                                    <p-multiSelect [options]="mfgCategory1" [appendTo]="'body'"
                                        [style]="{'width':'240px','height':'40px'}" [styleClass]="'p-5'"
                                        [(ngModel)]="manufacturerCategory1" id="manufacturerCategory1"
                                        filterPlaceHolder="Search Collections 1" defaultLabel="Manufacturer Collections 1"
                                        (onChange)="getMFGCategory2($event);blankValue(1);"></p-multiSelect>
            
                                    <p-multiSelect [options]="mfgCategory2" [appendTo]="'body'"
                                        [style]="{'width':'240px','height':'40px'}" [styleClass]="'p-5'"
                                        [(ngModel)]="manufacturerCategory2" id="manufacturerCategory2"
                                        filterPlaceHolder="Search Collections 2" defaultLabel="Manufacturer Collections 2"
                                        (onChange)="getMFGCategory3($event);blankValue(2);"></p-multiSelect>
            
                                    <p-multiSelect [options]="mfgCategory3" [appendTo]="'body'"
                                        [style]="{'width':'240px','height':'40px'}" [styleClass]="'p-5'"
                                        [(ngModel)]="manufacturerCategory3" id="manufacturerCategory3"
                                        filterPlaceHolder="Search Collections 3" defaultLabel="Manufacturer Collections 3"
                                        (onChange)="getMFGGroup();"></p-multiSelect>
                                </div>
                            </p-overlayPanel>
                        </div>

                        <div class="btn-group view-btns" *ngIf="showDisplayViewOption && isLogin">
                            <div class="ui-selectbutton">
                                <button (click)="displayTable()" label=""
                                    class="ui-button ui-button-icon-only ui-state-default"
                                    [class.ui-state-active]="showTable" icon="fa fa-bars ui-button-icon-left"
                                    pButton></button>
                                <button (click)="displayGrid()" label=""
                                    class="ui-button ui-button-icon-only ui-state-default"
                                    [class.ui-state-active]="!showTable" icon="fa fa-th-large ui-button-icon-left"
                                    pButton></button>
                            </div>
                        </div>
                        
                        <!-- *ngIf="auth.getCartStyle() !== 2 -->
                        <div class="cart-option-menu" *ngIf="isLogin">
                            <button class="icon-theme-color mr-5 ui-button-secondary dwnld-image-btn" *ngIf="!pageType && isShowDownload"
                                pTooltip="Download Images" (click)="downloadimageszip()" tooltipPosition="bottom"
                                [disabled]="!selecteditems.length" pButton label="Images"
                                [icon]="'pi pi-download'"></button>
                            <button class="icon-theme-color mr-5 grand-btn theme-grayclr btn-with-icon icon-only-btn" pTooltip="Promotions"
                                (click)="promotionsClickCartEvent($event)" tooltipPosition="bottom"
                                pButton
                                style="background: transparent; border: none; font-size: 20px;"
                                *ngIf="auth.getshowPromotionsButtonFlag() && !pageType">
                                <span class="btn-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32.812" viewBox="0 0 40 32.812">
                                        <g id="Group_1" data-name="Group 1" transform="translate(-122 -177)">
                                            <path class="fill-current" id="Path_1" data-name="Path 1" d="M440.765,226h-3.593a1.172,1.172,0,1,0,0,2.343h3.593a1.172,1.172,0,1,0,0-2.343Z" transform="translate(-279.936 -34.937)"/>
                                            <path class="fill-current" id="Path_2" data-name="Path 2" d="M425.344,288.686,423,286.343A1.172,1.172,0,0,0,421.344,288l2.343,2.343a1.172,1.172,0,0,0,1.657-1.657Z" transform="translate(-266.108 -90.249)"/>
                                            <path class="fill-current" id="Path_3" data-name="Path 3" d="M425.343,136.343a1.171,1.171,0,0,0-1.657,0l-2.343,2.343A1.172,1.172,0,0,0,423,140.343L425.343,138A1.171,1.171,0,0,0,425.343,136.343Z" transform="translate(-266.108 48.032)"/>
                                            <path class="fill-current" id="Path_4" data-name="Path 4" d="M8.28,136a3.509,3.509,0,0,0-3.3,2.343H4.765a4.687,4.687,0,1,0,0,9.373h.216a3.509,3.509,0,0,0,3.3,2.343h5.858V136Z" transform="translate(122 48.032)"/>
                                            <path class="fill-current" id="Path_5" data-name="Path 5" d="M333.343,46A2.344,2.344,0,0,0,331,48.344V71.782a2.343,2.343,0,1,0,4.687,0V48.344A2.344,2.344,0,0,0,333.343,46Z" transform="translate(-183.14 131)"/>
                                            <path class="fill-current" id="Path_6" data-name="Path 6" d="M92.172,344.6A5.856,5.856,0,0,1,91,344.482v5.977a3.515,3.515,0,0,0,7.03,0V344.6Z" transform="translate(38.11 -144.161)"/>
                                            <path class="fill-current" id="Path_7" data-name="Path 7" d="M217,85.819a10.032,10.032,0,0,1-6,2.856v14.289a10.031,10.031,0,0,1,6,2.856l1.03,1.03V84.789Z" transform="translate(-73.229 95.241)"/>
                                        </g>
                                    </svg>
                                </span>
                            </button>

                            <button class="icon-theme-color mr-5 grand-btn theme-grayclr btn-with-icon icon-only-btn" pTooltip="Minimum Met"
                                (click)="metClickCartEvent($event)" tooltipPosition="bottom" pButton
                                style="background: transparent; border: none; font-size: 20px;"
                                *ngIf="!pageType">
                                <span class="btn-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="37.399" viewBox="0 0 40 37.399">
                                        <g id="Products-2" transform="translate(0 -16.645)">
                                            <path class="fill-current" id="Path_8" data-name="Path 8" d="M189.469,16.645v7.031h-2.344V16.645h-7.031V33.051H196.5V16.645Z" transform="translate(-166.024)"/>
                                            <path class="fill-current" id="Path_9" data-name="Path 9" d="M39.171,240.624a16.051,16.051,0,0,0-1.735-1.005,2.343,2.343,0,0,0-2.694.445l-5.978,5.977a5.822,5.822,0,0,1-4.143,1.717H15.134v-2.344h10.3v-4.687H12.392a5.842,5.842,0,0,0-4.156,1.726L0,250.686l8.688,8.688,6.931-6.931h9.014a10.444,10.444,0,0,0,7.434-3.081c2.455-2.43,5.64-5.618,7.933-7.911Z" transform="translate(0 -205.33)"/>
                                        </g>
                                    </svg> 
                                </span>
                            </button>

                            <button class="icon-theme-color mr-10 grand-btn theme-grayclr" pTooltip="Checkout Now"
                                tooltipPosition="bottom" icon="fa fa-fw fa-shopping-basket"
                                [disabled]="!(existCartData?.totalItems && +existCartData?.totalItems > 0)"
                                (click)="checkoutClickCartEvent($event)" pButton
                                style="background: transparent; border: none; font-size: 20px;"
                                *ngIf="!pageType"></button>
                            <button class="btn-theme-color ui-button-info" pTooltip="Cart" label="Cart"
                                tooltipPosition="bottom" icon="fa fa-shopping-cart" (click)="(auth.getCartStyle() === 3 || auth.getCartStyle() === 4)? router.navigate(['/cart']):cartSidebar = true" pButton
                                style="position: relative;"><span class="cart-btn-count"
                                    *ngIf="existCartData?.totalItems">{{existCartData?.totalItems}}</span></button>
                        </div>
                    </div>
                    <!-- *ngIf="auth.getCartStyle() !== 2 && !pageType" -->
                    <div class="total-sec" *ngIf="isLogin">
                        <h3 class="my-0 d-flex flex-column align-items-center">
                            <span style="font-size: 19px; color:#535353; font-weight: normal;"> Total
                                {{(existCartData?.cartDiscountedTotal ? existCartData?.cartDiscountedTotal : 0) |
                                rtCurrency }}</span>
                        </h3>
                    </div>
                </div>
            </div>
        </p-header>
    </div>

    <p-table id="items-table" *ngIf="showTable" [columns]="cols" [value]="items" dataKey="recordID"
        [loading]="loadingTableData" (onLazyLoad)="itemload($event, searchForm, 'TableView')" #itemListingTable
        [lazy]="true" [autoLayout]="true" [totalRecords]="totalItemCount" [exportFilename]="getExportFilename()"
        [paginator]="true" sortField="itemNumber" [responsive]="false" [rowsPerPageOptions]="[rows, rows*2, rows*3]"
        [(first)]="tableEvent.first" [rows]="tableEvent.rows" [(selection)]="selecteditems"
        (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" [pageLinks]="rtSharedService.getPageLinks(5)">
        <ng-template pTemplate="paginatorright">
            <div class="per-page-text">Per page :</div>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
            <div class="per-page-text px-10">Total :</div>
            {{totalItemCount}}
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="icon-cell center reptimesticky-possition reptimesticky-possition-left-0" style="width:45px">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" [style.min-width]="col.width">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                </th>
                <th class="reptimesticky-possition reptimesticky-possition-right-0" *ngIf="!pageType"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td class="icon-cell reptimesticky-possition reptimesticky-possition-left-0" style="width:45px">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of columns">
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'itemnumber'">
                            <a style="color:#333;"> {{rowData[col.field]}}</a>
                        </div>
                        <div *ngSwitchCase="'name'" class="Bold">
                            <a style="color:#333;"> {{rowData[col.field]}} </a>
                        </div>
                        <div *ngSwitchCase="'repGroupManufacturerName'" class="Bold">
                            <a style="color:#333;">
                                {{rtSharedService.getSeletedLanguage(rowData['repGroupManufacturerName'], 
                                rowData['repGroupManufacturerName'],
                                rowData['repGroupManufacturerName_json'] )}}
                            </a>
                        </div>
                        <div *ngSwitchCase="'nextAvailableDate'">
                            {{rowData[col.field] | rtDate : 'UTC'}}
                        </div>
                        <div *ngSwitchCase="'minimumquantity'">{{(rowData[col.field] ? rowData[col.field] :
                            null) | number}}
                        </div>
                        <div *ngSwitchCase="'unitPrice'" [class]="col.class">
                            <ng-container
                                *ngIf="!(auth.getParentItemShoppingFlag() === false && rowData.isParentItem === true) && !rowData.b2bItemPricingPolicyFlag">
                                {{(rowData[col.field] ? rowData[col.field] : 0) | rtCurrency}}
                            </ng-container>
                        </div>
                        <div *ngSwitchCase="'upc1'">
                            <a style="color:#333;">{{rowData[col.field]}}</a>
                        </div>
                        <div *ngSwitchCase="'upc2'">
                            <a style="color:#333;">{{rowData[col.field]}}</a>
                        </div>
                        <div *ngSwitchCase="'upc3'">
                            <a style="color:#333;">{{rowData[col.field]}}</a>
                        </div>
                        <div *ngSwitchCase="'isavailable'" style="text-align:center;">
                            <i *ngIf="rowData[col.field] === 1" class="fa fa-check green"></i>
                            <div *ngIf="rowData[col.field] === 0 && rowData['nextavailabledate'] !== null">
                                {{rowData['nextavailabledate'] | rtDate : 'UTC'}}
                            </div>
                            <i *ngIf="rowData[col.field] === 0 && rowData['nextavailabledate'] === null"
                                class="fa fa-times red"></i>
                        </div>
                        <div *ngSwitchCase="'qtyavailable'">{{(rowData[col.field] ? rowData[col.field] : null) |
                            number}}
                        </div>
                        <div *ngSwitchCase="'datemodified'">{{rowData[col.field] | rtDate: 'UTC'}}
                        </div>
                        <div *ngSwitchCase="'itemDiscount'">
                            <input type="number" pInputText [(ngModel)]="rowData[col.field]" min="0"
                                (ngModelChange)="applyDiscount(rowData)" onClick="this.select();"
                                [disabled]="checkIsPriceCodeAndDiscountApplied(rowData)" style="width: 75px;"
                                *ngIf="rowData.isc && !rowData.isc.length" />
                        </div>
                        <div *ngSwitchCase="'quantity'">
                            <ng-container
                                *ngIf="!(auth.getParentItemShoppingFlag() === false && rowData.isParentItem === true) && !rowData.b2bItemPricingPolicyFlag">
                                <div
                                    *ngIf="!rowData.discontinued || (rowData.discontinued === true && rowData.allowDiscontinuedItemsShopping === true)">
                                    <input type="text" pInputText [(ngModel)]="rowData[col.field]" pKeyFilter="pint"
                                        (ngModelChange)="applyVolumePrice(rowData)" (blur)="setQuantity(rowData)"
                                        (click)="onclickSaveOldQty(rowData)" onClick="this.select();"
                                        style="width: 75px;" step="rowData.quantityIncrement"
                                        [min]="rowData.minimumQuantity" *ngIf="rowData.isc && !rowData.isc.length" />
                                </div>
                                <div *ngIf="rowData.discontinued && (rowData.allowDiscontinuedItemsShopping === null || rowData.allowDiscontinuedItemsShopping === false)"
                                    pTooltip="Discounted item are restricted for order.">
                                    <button pButton type="button" [disabled]="true"
                                        class="ui-button-secondary ui-button" label="Discontinued"></button>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngSwitchCase="'total'" [class]="col.class">
                            {{((rowData['quantity'] * rowData['unitPrice']) -
                            ((rowData['quantity'] * rowData['unitPrice']) *
                            (rowData['itemDiscount'] / 100))) | rtCurrency}}
                        </div>
                        <div *ngSwitchCase="'itemPriceCodes'">
                            <div *ngIf="rowData.isc && !rowData.isc.length">
                                <select [(ngModel)]="rowData.selectedPriceCode" class="price-code-select"
                                    (change)="setPriceCode(rowData)"
                                    *ngIf="rowData[col.field] && rowData[col.field].length" style="width: 85px;">
                                    <option [value]="''">Select</option>
                                    <option *ngFor="let obj of rowData[col.field]" [value]="obj.priceCode">
                                        {{obj.manufacturerPriceCode ? obj.manufacturerPriceCode + ' (' + obj.priceCode +
                                        ')' : obj.priceCode}}
                                        <!-- {{obj.priceCode}} -->
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                    </div>
                </td>
                <td class="reptimesticky-possition reptimesticky-possition-right-0 item-action-btn" *ngIf="!pageType">
                    <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
                    <ng-container
                        *ngIf="!(auth.getParentItemShoppingFlag() === false && rowData.isParentItem === true) && (!rowData.discontinued || (rowData.discontinued === true && rowData.allowDiscontinuedItemsShopping === true))">
                        <button *ngIf="rowData.isc && !rowData.isc.length && !rowData.b2bItemPricingPolicyFlag"
                            (click)="incrQtyEvent(rowData)" class="ui-button-info ui-button mx-5"
                            [class.ui-state-disabled]='rowData.isLoading'
                            [icon]="rowData.isLoading? 'pi pi-spin pi-spinner' : 'fa fa-plus'" pButton
                            [title]="fromPage === 'ORDER_PAGE' ? 'Add to order' : 'Add to basket'"
                            [disabled]="(rowData.isLoading) || (!selectedCustomer.selectedShipTo && fromPage !== 'ORDER_PAGE') || ((addDisbaleObj | json) != '{}' && addDisbaleObj[rowData.recordID])"></button>
                        <button id="iscButton{{rowData.recordID}}" *ngIf="rowData.isc && rowData.isc.length"
                            (click)="viewISC.next(rowData)" class="ui-button-info ui-button mx-5" icon="fa fa-eye"
                            pButton title="View item style colors"></button>
                    </ng-container>
                    <!-- RC-343 (RK 23JAN2020) Add View History button-->
                    <button (click)="viewHistory.next(rowData)" *ngIf="!rowData.isc || !rowData.isc.length"
                        class="ui-button-info ui-button mx-5" icon="fa fa-history" pButton
                        title="View History"></button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr class="table-empty" *ngIf="!loadingTableData">
                <td class="text-center" [attr.colspan]="cols.length + 3">No items found. Please check back later.</td>
            </tr>
        </ng-template>
    </p-table>

    <!-- B2B-102 (NL 26-FEB-2020) implement shimmer effect   -->
    <!-- shimmer effect -->
    <div class="or-item-dataview res-shimmer-item" *ngIf="shopItemShimmer && selectedView === 'GridView'">
        <div class="d-flex flex-wrap">
            <ng-container *ngFor="let shimmercount of shimmercounts">
                <div class="responsive-grid-item grid-item-style">
                    <div class="position-relative h-282-px item-main-block" style="height: 282px;">
                        <div class="item-inner-block">
                            <div class="item-detail-block">
                                <div class="p-5 d-flex justify-content-center position-relative">
                                    <div class="shimmer-effect Wid100" style="height: 166px;"></div>
                                </div>
                                <div class="shimmer-effect p-5 Wid50 d-block" style="margin: 5px auto 12px;"></div>
                                <div class="shimmer-effect p-15 Wid80 d-block" style="margin: 0px auto 12px;"></div>
                                <div class="shimmer-effect p-5 Wid30 d-block" style="margin: 0px auto 0px;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <!-- End -->

    <!-- // [B2B-232] (MG-08-APR-2020) modified to do ui hotfix -->
    <!-- [B2B-366] (NL-01-MAR-2021) remove [loading]="loadingTableData" -->
    <p-dataView #itemListingGrid *ngIf="!showTable" [value]="items" [paginator]="!showTable && !shopItemShimmer"
        [rowsPerPageOptions]="[rows, rows*2, rows*3]" [first]="tableEvent.first" [rows]="tableEvent.rows"
        styleClass="custom-data-view" (onLazyLoad)="itemload($event, searchForm, 'GridView')" [lazy]="true"
        [totalRecords]="totalItemCount"
        emptyMessage="{{loadingTableData? '' : 'No items found. Please check back later.'}}" layout="grid"
        class="or-item-dataview" [pageLinks]="rtSharedService.getPageLinks(5)">
        <ng-template pTemplate="paginatorright">
            <div class="per-page-text">Per page :</div>
        </ng-template>
        <ng-template pTemplate="caption">

        </ng-template>
        <ng-template let-item let-i='rowIndex' pTemplate="gridItem">
            <div class="responsive-grid-item grid-item-style" *ngIf="shopItemShimmer === false">
                <p-panel [showHeader]="false">
                    <div class="position-relative h-282-px item-main-block">
                        <div class="item-inner-block">
                            <div class="item-detail-block">
                                <div class="d-flex justify-content-center position-relative"
                                    [ngClass]="(item.imageLoader)?'p-0':'p-5'">
                                    <div [ngClass]="{'show-loader':item.imageLoader}" class="image-loader">
                                        <div class="loader-icon "></div>
                                    </div>
                                    <!-- <div class="list-items-image" *ngIf="item.imageError">
                                        <img *ngIf="item.imageError" src="{{item.profilePic}}"
                                        class="object-fit-cover wid-100" style="height:166px;max-width:100%;"
                                        alt="{{item.itemNumber}}" onerror="this.src='assets/images/no-image-available.png'">
                                    </div> -->
                                    <!-- *ngIf="!item.imageError" (load)="item.imageLoader=false;" -->
                                    <div class="list-items-image" *ngIf="!item.imageError">
                                        <!-- <img *ngIf="!item.imageError" (load)="item.imageLoader=false;"
                                            (error)="item.imageLoader=false;item.fullPicturePath = null;item.imageError=true"
                                            src="{{item.fullPicturePath}}" alt="{{item.itemNumber}}"> -->
                                        <!-- <object [data]="item.fullPicturePath256PX" *ngIf="!item.imageError">
                                            <img *ngIf="!item.imageError" (load)="item.imageLoader=false;" [src]="item.picturePath ? item.fullPicturePath : item.profilePic " class="img-cover" onerror="this.src='assets/images/no-image-available.png'" alt="{{item.itemNumber}}">
                                        </object> -->
                                        <app-resized-image [resizedPicturePath]="item.fullPicturePath256PX"
                                            [ogPicturePath]="item.picturePath ? item.fullPicturePath : item.profilePic"
                                            [imgTagClass]="'img-cover'" (imageLoadChange)="item.imageLoader=!$event">
                                        </app-resized-image>
                                    </div>
                                </div>
                                <div class="text-center pt-5 px-5 item-vendor-name">
                                    {{rtSharedService.getSeletedLanguage(item.manufacturerName, 
                                        item.repGroupManufacturerName,
                                        item.repGroupManufacturerName_json )}}
                                </div>
                                <div class="text-center pt-5 px-5 list-itemdesc" style="font-size: 16px;color: #333333;"
                                    [pTooltip]="item.name">
                                    {{item.name && item.name.length >= 40 ? item.name.slice(0, 40) + '...'
                                    : item.name}}
                                </div>
                                <div class="text-center pt-5 px-5 item-next-date">
                                    <div *ngIf="item.nextAvailableDate">
                                        <b>Next Available : </b>{{item.nextAvailableDate | rtDate: 'UTC'}}
                                    </div>
                                </div>
                                <div class="item-layer" [ngClass]="{'overlayIndex':item.imageLoader}"
                                    (click)="viewItemDetail(item, i)">
                                    <a href="javascript:;"><i class="fa fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="p-8">
                                <div class="item-detail-info">
                                    <div class="item-detail-info-number item-min-label-val p-5"
                                        style="font-size: 14px; color: #585958;">
                                        #{{item.itemNumber}}
                                    </div>
                                    <div class="item-detail-info-price item-min-label-val p-5"
                                        style="font-size: 14px; color: #585958;">
                                        <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
                                        <ng-container
                                            *ngIf="!(auth.getParentItemShoppingFlag() === false && item.isParentItem === true) && !item.b2bItemPricingPolicyFlag; else noPurchase">
                                            {{item.unitPrice | rtCurrency}}
                                        </ng-container>
                                        <ng-template #noPurchase>&nbsp;</ng-template>
                                    </div>
                                    <div class="item-min-label-val dwnld-image-checkbox p-5" *ngIf="!pageType && isShowDownload">
                                        <p-checkbox [binary]="true" [(ngModel)]="item.isChecked"
                                            (onChange)="SelectItemsChange(item,$event)"></p-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-panel>
            </div>
        </ng-template>

    </p-dataView>
</p-card>
<app-item-view-model [activeViewIndex]="activeViewIndex" [activeViewItem]="this.activeViewItem"
    [itemMfgIDs]="itemMfgIDs" [manufacturersArr]="manufacturersArr" [selectedCustomer]="this.selectedCustomer"
    [existCartData]="existCartData" [existItemsData]="existItemsData" [fromPage]="fromPage"
    (addToCartEvent)="addToCartEvent($event)" [(isOpen)]="displayItemViewDialog" *ngIf="displayItemViewDialog">
</app-item-view-model>

<!-- *ngIf="auth.getCartStyle() !== 2" -->
<p-sidebar [(visible)]="cartSidebar" position="right" [baseZIndex]="10000" [dismissible]="true" [showCloseIcon]="false"
    class="cart-prime-sidebar shop-cart-static">
    <div class="cart-right-sidebar">
        <app-cartbar [selectedCustomer]="customer" [confirmkey]="'item-table-confirmkey'"
            [existCartData]="existCartData" (promotionsClickEvent)="promotionsClickCartEvent($event)"
            (metClickEvent)="metClickCartEvent($event)" (closeSidebar)="cartSidebar=!$event" (checkoutClickEvent)="checkoutClickCartEvent($event)"
            (updateExistCardEvent)="updateExistCardCartEvent($event)"
            (viewItemDetailEvent)="viewItemDetailTileData($event)" [pageType]="pageType"
            [cartBarPosition]="cartBarPosition"></app-cartbar>
    </div>
</p-sidebar>
<app-download-image-zip *ngIf="visibledownload" [listOfItems]="selecteditems" (hide)="visibledownload=$event">
</app-download-image-zip>
<RtCustom-confirmDialog key="breckincrement2" [baseZIndex]="1000" [appendTo]="'body'" #breckincrement2
    header="Drop database" acceptLabel="Yes" rejectLabel="No">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" id="YesButton" label="Yes"
            (click)="breckincrement2.accept()"></button>
        <button type="button" class="ui-button-secondary" pButton icon="pi pi-times" label="No"
            (click)="breckincrement2.close($event)"></button>
    </p-footer>
</RtCustom-confirmDialog>