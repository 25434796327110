<p-card class="card">
    <p-table #dt [columns]="cols" [value]="promotions"
             dataKey="recordID" sortField="name" [rows]="25" #promotionsListingTable
             [autoLayout]="true" [responsive]="false"
             [totalRecords]="totalRecords"
             [paginator]="true" [rowsPerPageOptions]="[25, 50, 75]" [scrollable]="true">

        <ng-template pTemplate="paginatorright">
            <div class="per-page-text">Per page :</div>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
            <div class="per-page-text px-10">Total :</div>
            {{totalRecords}}
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [pSortableColumn]="col.field"
                    [width]="col.width">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns">
                    <td *ngIf="getActive(rowData)" [width]="col.width">
                        <div [ngSwitch]="col.field">
                            <strong>
                                <div *ngSwitchCase="'name'">
                                    <a style="color:#333;">
                                        {{rowData[col.field]}}
                                    </a>
                                </div>
                            </strong>
                            <div *ngSwitchCase="'description'">
                                <a style="color:#333;">
                                    {{rowData[col.field]}}
                                </a>
                            </div>
                            <div *ngSwitchCase="'amountToMeet'" class="center">
                                {{rowData['amountToMeet'] | rtCurrency}}
                            </div>
                            <div *ngSwitchCase="'startDate'" class="center">
                                {{rowData['startDate'] | rtDate}}
                            </div>
                            <div *ngSwitchCase="'endDate'" class="center">
                                {{rowData['endDate'] | rtDate}}
                            </div>
                            <div *ngSwitchCase="'discount'" class="center">
                                {{rowData['discountPercent'] == null ?
                                (rowData['discountAmount'] | rtCurrency) :
                                (rowData['discountPercent'] / 100 | percent: locale)}}
                            </div>
                            <div *ngSwitchCase="'active'" class="center">
                                <div *ngIf="getActive(rowData)">
                                    <i class="fa fa-calendar-check-o" style="font-size:18px;" title="active"></i>
                                </div>
                                <!--<div *ngIf="!getActive(rowData)">-->
                                <!--<i class="fa fa-calendar-times-o FsRed" style="font-size:18px;"-->
                                <!--title="inactive"></i>-->
                                <!--</div>-->
                            </div>
                            <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                        </div>
                    </td>
                </ng-container>
            </tr>
        </ng-template>
        <!--<ng-template pTemplate="emptymessage">-->
            <!--<tr class="table-empty">-->
                <!--<td [attr.colspan]="cols.length + 3" class="text-center">You have no promotions yet. Use the "+ New"-->
                    <!--button to add-->
                    <!--new.-->
                <!--</td>-->
            <!--</tr>-->
        <!--</ng-template>-->
    </p-table>
</p-card>
