import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { HtmlToolsService } from '../../services/html-tools/html-tools.service';
import { OrderPaymentToken } from '../../models/order-payment-token';
import { RetailerPaymentInfo } from '../../models/retailer-payment-info';
import { RTSharedService } from '../../services/rtshared/rtshared.service';
import { OrderService } from '../../services/middleware/order/order.service';
import { RetailerAPIService } from '../../services/api/retailer-api/retailer-api.service';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeUrl, SafeScript, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'rs-add-payment-info-dialog',
    templateUrl: './add-payment-info-dialog.component.html',
    styleUrls: ['./add-payment-info-dialog.component.css']
})
export class AddPaymentInfoDialogComponent implements OnInit, OnChanges, OnDestroy {
    @Input() selectedPaymentTerm: string;
    @Input() isOpen: boolean;
    @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() OnCloseDialog: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveEvent?: EventEmitter<any> = new EventEmitter<any>();
    @Output() savePTEvent?: EventEmitter<any> = new EventEmitter<any>();
    orderPaymentInfo = new OrderPaymentToken();
    paymentInfo = new RetailerPaymentInfo();
    saveCardToDB = true;
    copyBillToAddress = false;
    paymentTypes: SelectItem[] = [];
    paymentInfoForm: FormGroup;
    domainurl: any;
    @Input() isOrderPayment: boolean;
    @Input() orderID;
    @Input() orderObj;
    @Input() repGroupID;
    retailerID: string;
    private loadingRetailerTerms = false;
    private cardCVVSaved = false;

    @Input() set fRetailerID(value: string) {
        this.retailerID = value;
        this.loadRetailerTerms();
    }

    get fRetailerID(): string {
        return this.retailerID;
    }

    monthOptions = [];
    yearOptions = [];
    @ViewChild('cciframe', { static: false }) iframe: ElementRef;


    sessionID = '';
    timestamp = '';
    retailerPaymentTerms: SelectItem[] = [];
    selectedRetailerTerm: RetailerPaymentInfo;
    selectedPaymentType = null;

    cardInfoSaved = false;
    cardNumberSaved = false;
    isDisabledForm = false;
    @Input() selectedPaymentInfo: any;
    loading: boolean = false; // RC-500 (MG 16 JAN 2020)

    valutURL = '';
    valutURLSafe: SafeResourceUrl;
    copyBillToAddressToShip: false;
    @Input() currentRetailer;
    cvvIframe = false;
    private eventHandler;
    private readonly destroyRetailerTerms = new Subject<void>();

    constructor(
        private orderService: OrderService,
        private rtSharedService: RTSharedService,
        private httpClient: HttpClient,
        private auth: AuthService,
        private router: Router,
        private retailerService: RetailerAPIService,
        private msgService: MessageService,
        private fb: FormBuilder,
        private sanitizer: DomSanitizer,
        private htmlTools: HtmlToolsService
    ) {
        this.valutURL = environment.vaultURL;
        this.valutURLSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.valutURL);
        // if (window.addEventListener) {
        //     window.addEventListener('message', ($event) => {
        //         this.receiveMessage($event);
        //     }, false);
        // }
        this.eventHandler = (event) => {
            // RC-1129 (MJ 11-Aug-2020) creditcard number and cvv validation add
            console.log(event.data.message);
            if (event.data.message) {
                this.cvvIframe = true;
                if (event.data.message === true) {
                    this.onFormSubmit(event);
                }
                this.onMessageReceived(event);
            } else {
                this.cvvIframe = false;
                this.loading = false;
                this.msgService.clear();
                if (event.data.type === 'both' && !event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfo', summary: 'Error', severity: 'error', detail: 'Please insert Credit Card number and CVV' });
                } else if (event.data.type === 'both' && event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfo', summary: 'Error', severity: 'error', detail: 'Please insert valid Credit Card number and CVV' });
                } else if (event.data.type === 'cc' && !event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfo', summary: 'Error', severity: 'error', detail: 'Please insert Credit Card number' });
                } else if (event.data.type === 'cc' && event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfo', summary: 'Error', severity: 'error', detail: 'Please insert valid Credit Card number' });
                } else if (event.data.type === 'cvv' && !event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfo', summary: 'Error', severity: 'error', detail: 'Please insert CVV' });
                } else if (event.data.type === 'cvv' && event.data.validType) {
                    this.msgService.add({ key: 'addPaymentInfo', summary: 'Error', severity: 'error', detail: 'Please insert valid CVV' });
                }
            }
        };
        window.addEventListener('message', this.eventHandler, false);

    }

    copyAddress() {
        console.log('currentRetailer0ooo', this.currentRetailer, this.orderObj);
        const patchObj: any = {
            billAddress: '',
            billCity: '',
            billState: '',
            billZip: ''
        };


        this.copyBillToAddressToShip = false;

        if ((this.currentRetailer !== undefined || this.currentRetailer !== null || this.currentRetailer !== '') && this.orderObj === undefined) {
            patchObj.billAddress = (this.currentRetailer.address2) ?
                [this.currentRetailer.address1, this.currentRetailer.address2].join(', ') : this.currentRetailer.address1;
            patchObj.billCity = this.currentRetailer.city;
            patchObj.billState = this.currentRetailer.state;
            patchObj.billZip = this.currentRetailer.zip;
        } else if (this.orderObj) {
            patchObj.billAddress = (this.orderObj.billToAddress2) ?
                [this.orderObj.billToAddress1, this.orderObj.billToAddress2].join(', ') : this.orderObj.billToAddress1;
            patchObj.billCity = this.orderObj.billToCity;
            patchObj.billState = this.orderObj.billToState;
            patchObj.billZip = this.orderObj.billToZip;
        }

        if(!this.copyBillToAddress) {
            patchObj.billAddress =  '';
            patchObj.billCity =  '';
            patchObj.billState =  '';
            patchObj.billZip =  '';
        }
        this.paymentInfoForm.patchValue(patchObj);
    }

    copyAddressShipTo() {
        const patchObj: any = {
            billAddress: '',
            billCity: '',
            billState: '',
            billZip: ''
        };
        this.copyBillToAddress = false;
        console.log('sessionLocationId', this.copyBillToAddressToShip, this.orderObj);

        if (this.copyBillToAddressToShip && this.orderObj) {
            patchObj.billAddress = (this.orderObj.shipToAddress2) ? [this.orderObj.shipToAddress1, this.orderObj.shipToAddress2].join(', ') : this.orderObj.shipToAddress1;
            patchObj.billCity = this.orderObj.shipToCity;
            patchObj.billState = this.orderObj.shipToState;
            patchObj.billZip = this.orderObj.shipToZip;
        } else {
            patchObj.billAddress =  '';
            patchObj.billCity =  '';
            patchObj.billState =  '';
            patchObj.billZip =  '';
        }
        // if (this.copyBillToAddressToShip && this.orderObj) {
        //     patchObj.billAddress = (this.orderObj.shipToAddress2) ?
        //         [this.orderObj.shipToAddress1, this.orderObj.shipToAddress2].join(', ') : this.orderObj.shipToAddress1;
        //     patchObj.billCity = this.orderObj.shipToCity;
        //     patchObj.billState = this.orderObj.shipToState;
        //     patchObj.billZip = this.orderObj.shipToZip;
        // } else {
        //     const sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        //     console.log('sessionLocationId', sessionLocationId);
        //     patchObj.billAddress = (sessionLocationId.address2) ?
        //         [sessionLocationId.address1, sessionLocationId.address2].join(', ') : sessionLocationId.address1;
        //     patchObj.billCity = sessionLocationId.city;
        //     patchObj.billState = sessionLocationId.state;
        //     patchObj.billZip = sessionLocationId.zip;

        // }
        this.paymentInfoForm.patchValue(patchObj);
    }

    // RC-500 (MG 16 JAN 2020): modified to close popup
    private onMessageReceived(event) {
        const data = event.data.message as string;
        if (data && data.toString().startsWith('ORDER TOKEN SAVED')) {
            if (data.toString().endsWith('cc')) {
                this.cardNumberSaved = true;
            } else if (data.toString().endsWith('cvv')) {
                this.cardCVVSaved = true;
            }
            if (event.data.runningCount === event.data.totalCount) {
                if (this.isOrderPayment && (this.cardNumberSaved || this.cardCVVSaved)) {
                    this.msgService.clear();
                    this.msgService.add({ detail: 'Card Info Saved to Order', severity: 'success' });
                    console.log('closing popup 110');
                    this.loading = false;
                    this.isOpenChange.emit(false);
                } else {
                    const repGroupID = this.repGroupID;
                    // this.paymentInfoForm.patchValue({'paymentToken': data.split(':')[1]});
                    // this.paymentInfoForm.patchValue(
                    //     {
                    //         'cardNumber': this.paymentInfoForm.get('paymentToken')
                    // .value.substr(this.paymentInfoForm.get('paymentToken').value.length - 4, 4)
                    //     });
                    console.log('Saving Retailer Payment Info....');
                    this.saveRetailerPaymentInfo(repGroupID, this.paymentInfoForm.value);
                    console.log('closing popup 122');
                    this.loading = false;
                    this.isOpenChange.emit(false);
                }
            }
            // this.saveEvent.emit();
            // RC-1153(RM 10-DEC-2020 Added) selected PaymentTerm option from Add payment info dialog display in finalize order screen
            const selectedpaymetTerm = _.find(this.paymentTypes, o => o.value === this.paymentInfoForm.value.paymentType);
            this.saveEvent.emit(selectedpaymetTerm.label);
            // END
        } else if (data && data.toString().startsWith('RETAILER TOKEN SAVED')) {
            this.cardNumberSaved = true;
            this.msgService.clear();
            this.msgService.add({ detail: 'Card Info Saved to Retailer', severity: 'success' });
            this.saveEvent.emit();
            console.log('closing popup 132');
            if (event.data.runningCount === event.data.totalCount) {
                this.loading = false;
                this.isOpenChange.emit(false);
            }
        }
    }

    ngOnChanges(changes: any) {
        if (changes && changes.selectedPaymentInfo && !_.isEmpty(changes.selectedPaymentInfo.currentValue)) {
            this.selectedPaymentInfo = changes.selectedPaymentInfo.currentValue;
        }
    }

    ngOnInit() {
        this.domainurl = window.location.protocol ? `${window.location.protocol}//${window.location.hostname}` :
            `${window.location.hostname}`;
        this.paymentInfoForm = this.fb.group({
            paymentType: ['', Validators.required],
            cardHolderName: ['', Validators.required],
            billAddress: ['', Validators.required],
            billCity: ['', Validators.required],
            billState: ['', Validators.required],
            billZip: ['', Validators.required],
            expirationMonth: ['', Validators.required],
            expirationYear: ['', Validators.required],
            paymentToken: [''],
            cardNumber: [''],
        });
        // TODO: Fetch valid card payment types from Manufacturer Payment Types
        this.paymentTypes = [
            { label: 'Select Payment Type', value: null },
            { label: 'American Express', value: 'AMEX' },
            { label: 'Discover', value: 'DISCOVER' },
            { label: 'Mastercard', value: 'MASTERCARD' },
            { label: 'Visa', value: 'VISA' }
            // { label: 'Other', value: 'OTHER' }
        ];
        this.loadDateFields();
        if (!this.isOrderPayment || this.selectedPaymentInfo) {
            this.setFormFromObject();
        }

        console.log('selectedPaymentInfo>>>>>', this.selectedPaymentInfo, this.repGroupID);
    }

    setFormFromObject() {
        this.paymentInfoForm.reset();
        this.paymentInfoForm.patchValue(this.selectedPaymentInfo);
    }


    private loadDateFields() {
        this.monthOptions = [
            { label: 'Month', value: null },
            { label: '01', value: '01' },
            { label: '02', value: '02' },
            { label: '03', value: '03' },
            { label: '04', value: '04' },
            { label: '05', value: '05' },
            { label: '06', value: '06' },
            { label: '07', value: '07' },
            { label: '08', value: '08' },
            { label: '09', value: '09' },
            { label: '10', value: '10' },
            { label: '11', value: '11' },
            { label: '12', value: '12' },
        ];

        this.yearOptions = [{ label: 'Year', value: null }];
        const fullYear = new Date().getFullYear();
        for (let i = 0; i < 10; i++) {
            // console.log('Year = ' + fullYear + ' - ' + (fullYear + i));
            this.yearOptions.push({
                label: ('' + (fullYear + i)).substr(2, 2), value: ('' + (fullYear + i)).substr(2, 2)
            });
        }
    }

    get validateChk() {
        // Convenience getter for easy access to form fields
        return this.paymentInfoForm.controls;
    }


    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.pattern && field.errors.pattern.requiredPattern === '^[0-9]*$') {
            return 'Enter numeric value';
        } else if (field.errors.pattern && field.errors.pattern.requiredPattern
            === '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$') {
            return 'Enter website url e.g. http://www.eg.com or https://www.eg.com';
        } else if (field.errors.pattern) {
            return 'Enter valid value';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
        if (field.errors.maxlength) {
            return 'Maximum length exceed for ' + name;
        }
    }

    // RC-500 (MG 16 JAN 2020): modified to close popup
    onFormSubmit($event) {
        const isAlphaNumeric = this.checkAlphaNumeric(this.paymentInfoForm.value.cardHolderName);
        this.cardNumberSaved = false;
        this.cardInfoSaved = false;
        console.log('onFormSubmit >>', this.repGroupID, this.orderID, this.paymentInfoForm, this.orderPaymentInfo);
        // this.saveIFrameData(repGroupID);
        if (this.paymentInfoForm.valid || this.isDisabledForm) {
            const selectedTermObj = _.find(this.paymentTypes, o => o.value && o.value.toLowerCase() === this.selectedPaymentType.toLowerCase());
            if (selectedTermObj && selectedTermObj.label) {
                const checkAvailableTermObj = _.find(this.orderObj.paymentTermOptions, o => o.label && o.label.toLowerCase() === selectedTermObj.label.toLowerCase());
                if (!checkAvailableTermObj) {
                    this.msgService.clear();
                    this.msgService.add({ key: 'addPaymentInfo', severity: 'error', summary: 'Invalid Payment Type', 
                        detail: this.orderObj.repGroupManufacturerName + ' not supported cart ' + selectedTermObj.label });
                    return false;
                }
            }
            if (isAlphaNumeric) {
                this.loading = true;
                if (this.cvvIframe) {
                    if (this.isOrderPayment) {
                        this.setOrderInfoFromRetailerInfo(this.paymentInfoForm.value);
                        this.saveOrderPaymentInfo(this.repGroupID, this.orderID, this.orderPaymentInfo);
                    } else {
                        this.saveRetailerPaymentInfo(this.repGroupID, this.paymentInfoForm.value);
                    }
                } else {
                    const data = {
                        type: 'cvvCheck',
                        numberType: this.paymentInfoForm.getRawValue().cardNumber ? 'cvv' : 'ccCvvCheck'
                    };
                    this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(data), this.valutURL);
                }
            } else {
                this.msgService.clear();
                this.msgService.add({ key: 'addPaymentInfo', severity: 'warn', summary: 'Invalid card holder name', detail: 'Card holder name can not be only numeric' });
            }
        } else {
            console.log('Payment form invalid', this.paymentInfoForm);
            this.loading = false;
            this.htmlTools.validateAllFields(this.paymentInfoForm);
        }
    }

    // [RC-1350] (CB 09-NOV-2020) Check validation for card holder name is alphanumeric or not
    checkAlphaNumeric(cardHolderName) {
        const letters = /[a-zA-Z]/g;
        if (cardHolderName) {
            if (cardHolderName.match(letters)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    // [RC-626] (MG 14-APR-2020) modified to make update vault URL
    private saveIFrameData(repGroupID, id, type) {
        let bearerToken = sessionStorage.getItem('idToken');
        if (bearerToken != null) {
            bearerToken = bearerToken.substring(1, bearerToken.length - 1);
        }
        if (bearerToken === null) {
            bearerToken = this.auth.getIDToken();
        }
        if (this.iframe) {
            try {
                // const domains = [`${this.domainurl}/assets/static/auricenc.html`];
                // const domains = ['https://vault02-sb.auricsystems.com/corp/reptime/v1/auricenc.html'];
                const domains = [this.valutURL];
                // const domains = ['https://reptimedev.com/auricenc.html'];
                let data = {};
                domains.forEach(url => {
                    // this.iframe.nativeElement.contentWindow.postMessage
                    // ('Send CC:' + this.orderID + ':' + repGroupID + ':' + bearerToken, url);
                    const baseURL = this.rtSharedService.getApiUrl();
                    if (type === 'order') {
                        data = {
                            type: 'order',
                            orderID: id,
                            repGroupID: repGroupID,
                            saveCardToDB: this.saveCardToDB,
                            bearer: bearerToken,
                            isDisabledForm: this.isDisabledForm,
                            baseUrl: baseURL
                        };
                    } else if (type === 'retailer') {
                        data = {
                            type: 'retailer',
                            retailerID: id,
                            repGroupID: repGroupID,
                            bearer: bearerToken,
                            paymentInfoForm: JSON.stringify(this.paymentInfoForm.value),
                            baseUrl: baseURL
                        };
                    }
                    /*this.iframe.nativeElement.contentWindow.postMessage(
                        'OrderCC:::' + id + ':::' + repGroupID + ':::' + this.saveCardToDB + ':::' + bearerToken,
                        url);
                    this.iframe.nativeElement.contentWindow.postMessage(
                        'RetailerCC:::' + id + ':::' + repGroupID + ':::' +
                        JSON.stringify(this.paymentInfoForm.value) + ':::' + bearerToken,
                        url);

                     */
                    console.log('Posting Message with data', JSON.stringify(data), url);
                    this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(data), url);
                });

            } catch (e) {
                console.log('Error posting message', e);
            }
        }
    }

    // RC-500 (MG 16 JAN 2020): modified to auto-select "Add Card"
    private loadRetailerTerms() {
        // const repGroupID = this.authService.getCompanyID() ? this.authService.getCompanyID() :
        // 'R2';    // TODO: for testing only, use only auth service for production
        if (!this.loadingRetailerTerms) {
            this.loadingRetailerTerms = true;
            this.retailerPaymentTerms = [];
            this.retailerPaymentTerms.push({ value: null, label: '--Select Card--' });
            this.destroyRetailerTerms.next();

            this.retailerService.getPaymentTermsForRetailer(this.retailerID)
            .pipe(takeUntil(this.destroyRetailerTerms)).subscribe((paymentInfoList: RetailerPaymentInfo[]) => {
                    paymentInfoList.forEach(info => {
                        this.retailerPaymentTerms.push({ value: info, label: this.getTermLabel(info) });
                    });
                    this.retailerPaymentTerms.push({ value: new RetailerPaymentInfo(), label: 'Add Card' });
                    this.loadingRetailerTerms = false;

                    // RC-500 (MG 16 JAN 2020)
                    if (paymentInfoList.length === 0) {
                        console.log('setting Add Card');
                        this.selectedRetailerTerm = new RetailerPaymentInfo();
                    }
                    // RC-444 (RK 16 JAN 2020) Modified Payment Type selection option
                    if (this.paymentTypes.length && this.selectedPaymentTerm) {
                        const selectedTermObj = _.find(this.paymentTypes, o => o.label.toLowerCase()
                            === this.selectedPaymentTerm.toLowerCase());
                        if (selectedTermObj && selectedTermObj.value) {
                            this.selectedPaymentType = selectedTermObj.value;
                        }
                    }
                });
        }
    }

    private getTermLabel(info) {
        return info.paymentType + ' ' + info.expirationMonth + '/' + info.expirationYear + ' *' + (info.cardNumber ? info.cardNumber : '');
    }

    // [RC-626] (MG 14-APR-2020) modified to make update vault URL
    onRetailerPaymentTermChange($event) {
        this.selectedRetailerTerm = $event.value;
        this.orderPaymentInfo = new OrderPaymentToken();
        if (this.selectedRetailerTerm && this.selectedRetailerTerm.recordID) {
            this.paymentInfo = this.selectedRetailerTerm;
            this.paymentInfoForm.controls.paymentType.patchValue(this.paymentInfo.paymentType);
            this.paymentInfoForm.controls.cardHolderName.patchValue(this.paymentInfo.cardHolderName);
            this.paymentInfoForm.controls.billAddress.patchValue(this.paymentInfo.billAddress);
            this.paymentInfoForm.controls.billCity.patchValue(this.paymentInfo.billCity);
            this.paymentInfoForm.controls.billState.patchValue(this.paymentInfo.billState);
            this.paymentInfoForm.controls.billZip.patchValue(this.paymentInfo.billZip);
            this.paymentInfoForm.controls.expirationMonth.patchValue(this.paymentInfo.expirationMonth);
            this.paymentInfoForm.controls.expirationYear.patchValue(this.paymentInfo.expirationYear);
            this.paymentInfoForm.controls.paymentToken.patchValue(this.paymentInfo.paymentToken);
            this.paymentInfoForm.controls.cardNumber.patchValue(this.paymentInfo.cardNumber);
            this.isDisabledForm = true;
            // }
            // if (this.selectedRetailerTerm && !this.selectedRetailerTerm.recordID) {
            //     this.isDisabledForm = false;
            //     this.paymentInfo = new RetailerPaymentInfo();
            //     this.orderPaymentInfo = new OrderPaymentToken();
        } else {
            this.isDisabledForm = false;
            this.orderPaymentInfo = new OrderPaymentToken();
            this.paymentInfoForm.reset();
        }
        // const domains = [`${this.domainurl}/assets/static/auricenc.html`];
        // const domains = ['https://vault02-sb.auricsystems.com/corp/reptime/v1/auricenc.html'];
        const domains = [this.valutURL];
        // const domains = ['https://reptimedev.com/auricenc.html'];
        domains.forEach(url => {
            const obj = { hideCC: this.isDisabledForm, baseUrl: this.rtSharedService.getApiUrl() };
            this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(obj), url);
        });
        this.setFormDisabled();

    }
    // RC-444 (RK 16 JAN 2020)
    onPaymentTypeChange($event) {
        this.savePTEvent.emit($event.selectedOption.label);
    }
    private setOrderInfoFromRetailerInfo(pmtInfo: RetailerPaymentInfo) {
        this.orderPaymentInfo = new OrderPaymentToken();
        this.orderPaymentInfo.cardHolderName = pmtInfo.cardHolderName;
        this.orderPaymentInfo.billAddress = pmtInfo.billAddress;
        this.orderPaymentInfo.billCity = pmtInfo.billCity;
        this.orderPaymentInfo.billState = pmtInfo.billState;
        this.orderPaymentInfo.billZip = pmtInfo.billZip;
        this.orderPaymentInfo.paymentType = pmtInfo.paymentType;
        this.orderPaymentInfo.expirationMonth = pmtInfo.expirationMonth;
        this.orderPaymentInfo.expirationYear = pmtInfo.expirationYear;
        this.orderPaymentInfo.cardNumber = pmtInfo.cardNumber;
        this.orderPaymentInfo.paymentToken = pmtInfo.paymentToken;    // may want to move this ackend service pmtInfofrom retailer term
    }

    private saveOrderPaymentInfo(repGroupID: string, orderID: number, orderPaymentInfo: OrderPaymentToken) {
        // saveCardToRetailer param should always be false here, we only want to trigger the save from the iframe when card number exists
        console.log('Saving data');
        this.orderService.saveOrderPaymentToken(repGroupID, orderID, orderPaymentInfo, false, this.selectedRetailerTerm)
            .then(res => {
                this.saveIFrameData(repGroupID, orderID, 'order');
                this.msgService.clear();
                // this.msgService.add({summary: 'Card Info Saved to Order', severity: 'success'});
                this.cardInfoSaved = true;
            }, error => {
                console.log(error);
                this.msgService.clear();
                this.msgService.add({ summary: 'Error saving Card Info to Order', severity: 'error' });
                this.cardInfoSaved = false;
            });
    }

    private saveRetailerPaymentInfo(repGroupID: string, retailerPaymentInfo: RetailerPaymentInfo) {
        if (this.selectedPaymentInfo && this.selectedPaymentInfo.recordID) {
            this.setObjectFromForm();
            this.savePaymentInfo();
        } else {
            this.saveIFrameData(repGroupID, this.retailerID, 'retailer');
        }
        /*
                this.retailerService.saveRetailerPaymentInfo(repGroupID, this.retailerID,
                    retailerPaymentInfo).subscribe((info: RetailerPaymentInfo) => {
                    let infoExists = false;
                    this.retailerPaymentTerms.forEach(r => {
                        if (r.value && r.value.recordID === info.recordID) {
                            infoExists = true;
                        }
                    });
                    if (!infoExists) {
                        this.retailerPaymentTerms.push({value: info, label: this.getTermLabel(info)});
                    }
                    this.saveIFrameData(repGroupID, this.retailerID, 'retailer');
                });
         */
    }


    private setFormDisabled() {
        if (!this.isDisabledForm) {
            console.log('Enabling Payment Form', this.isDisabledForm);
            this.paymentInfoForm.controls.paymentType.enable();
            this.paymentInfoForm.controls.cardHolderName.enable();
            this.paymentInfoForm.controls.billAddress.enable();
            this.paymentInfoForm.controls.billCity.enable();
            this.paymentInfoForm.controls.billState.enable();
            this.paymentInfoForm.controls.billZip.enable();
            this.paymentInfoForm.controls.expirationMonth.enable();
            this.paymentInfoForm.controls.expirationYear.enable();
            this.paymentInfoForm.controls.paymentToken.enable();
            this.paymentInfoForm.controls.cardNumber.enable();

        } else {
            console.log('Disabling Payment Form');

            this.paymentInfoForm.controls.paymentType.disable();
            this.paymentInfoForm.controls.cardHolderName.disable();
            this.paymentInfoForm.controls.billAddress.disable();
            this.paymentInfoForm.controls.billCity.disable();
            this.paymentInfoForm.controls.billState.disable();
            this.paymentInfoForm.controls.billZip.disable();
            this.paymentInfoForm.controls.expirationMonth.disable();
            this.paymentInfoForm.controls.expirationYear.disable();
            this.paymentInfoForm.controls.paymentToken.disable();
            this.paymentInfoForm.controls.cardNumber.disable();
        }
    }

    // [RC-626] (MG 14-APR-2020) modified to make update vault URL
    onIframeReady() {
        if (!this.isOrderPayment) {
            // const domains = [`${this.domainurl}/assets/static/auricenc.html`];
            // const domains = ['https://vault02-sb.auricsystems.com/corp/reptime/v1/auricenc.html'];
            const domains = [this.valutURL];
            // const domains = ['https://reptimedev.com/auricenc.html'];
            const obj = { hideCVV: true, baseUrl: this.rtSharedService.getApiUrl() };
            domains.forEach(url => {
                this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(obj), url);
            });
        }
    }

    setObjectFromForm() {
        _.forEach(this.paymentInfoForm.controls, (key, value) => {
            this.selectedPaymentInfo[value] = this.paymentInfoForm.controls[value].value;
        });
    }

    // RC-500 (MG 16 JAN 2020): modified to close popup
    savePaymentInfo() {
        this.retailerService.saveRetailerPaymentInfo(this.repGroupID, this.retailerID, this.selectedPaymentInfo)
            .subscribe((response: RetailerPaymentInfo) => {
                if (response) {
                    this.saveEvent.emit(response);
                    console.log('closing popup 469');
                    setTimeout(() => {
                        this.loading = false;
                        this.isOpenChange.emit(false);
                    }, 3000);
                    this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Payment Info Saved' });
                } else {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error saving payment info' });
                }
            });
    }

    ngOnDestroy() {
        this.destroyRetailerTerms.next();
        window.removeEventListener('message', this.eventHandler, false);
    }

}

