import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../services/middleware/auth/auth.service';
import { ManufacturerService } from '../../../services/api/manufacturer.service';
import { ItemsAPIService } from '../../../services/api/items-api/items-api.service';
import { DialogService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SidebarApiService } from '../../../services/api/sidebar-api/sidebar-api.service';
import { BasketItemService } from '../../../services/middleware/basket-item/basket-item.service';
import * as objectPath from 'object-path';
import * as _ from 'lodash';
import { MinimumMetDialogComponent } from '../../../modals/minimum-met-dialog/minimum-met-dialog.component';
import { Router } from '@angular/router';
import { PromotionsDialogComponent } from '../../../modals/promotions-dialog/promotions-dialog.component';
import { RTSharedService } from '../../../services/rtshared/rtshared.service';
import { BrowseItemsDialogComponent } from '../../../modals/browse-items-dialog/browse-items-dialog.component';

declare function openDigitalCatelogDialog(url);
declare function closeDigitalCatelogDialog();
declare function getAnnotationsFlipbook(url, searchText);

@Component({
  selector: 'app-digital-catalog',
  templateUrl: './digital-catalog.component.html',
  styleUrls: ['./digital-catalog.component.scss']
})
export class DigitalCatalogComponent implements OnInit, OnDestroy, OnChanges {
  @Input() catalogData: any;
  @Output() closeDialog = new EventEmitter<any>();
  @Output() cartDataEvent?= new EventEmitter<any>();
  errorMsg = false;
  pdfViewPercent: any;
  public pdfSrc = null;
  catalogShopItemLoading = false;
  showLoader: boolean;
  catalogShopItem: any;
  showCatalogShopDialog = false;
  selectedCustomer: any;
  public getRetailerDataSubscription: Subscription;
  @Output() promotionsClickEvent = new EventEmitter<any>();
  @Output() metClickEvent = new EventEmitter<any>();
  @Output() checkoutClickEvent = new EventEmitter<any>();
  @Output() updateExistCardEvent = new EventEmitter<any>();
  public manufacturersArr: any = [];
  public cartData: any;
  public itemMfgIDs: any = [];
  existCartData: any;
  existItemsData: any;
  fromPage = 'digital-catalog';
  activeViewItem: any;
  activeViewIndex: any;
  cartSidebar = false;
  customer: any;
  retailerID: string;
  public subscription: Subscription;
  public subscriptionWishListData: Subscription;
  public cartBarPosition = 'sliding';
  projectName: string;
  showSearchDialog = false;
  showSearchLoader = false;

  constructor(public manufacturerService: ManufacturerService, private eRef: ElementRef,
    private authService: AuthService, private itemsAPIService: ItemsAPIService, public msgService: MessageService, public router: Router,
    private sidebarApiService: SidebarApiService, private basketItemService: BasketItemService, public dialogService: DialogService,
    private rtSharedService: RTSharedService) {
    this.projectName = this.rtSharedService.getProjectName();
  }

  async ngOnInit() {
  }

  async ngOnChanges() {
    this.retailerID = this.rtSharedService.getRetailerID();
    this.getRetailerDataSubscription = await this.authService.getRetailerData.subscribe(async (getRetailerRes) => {
      this.selectedCustomer = getRetailerRes;
      this.getRetailer();
    });
    this.sidebarApiService.getMfgEvent().subscribe((data) => {
      this.manufacturersArr = data;
    });
    this.cartData = await this.getCart(this.selectedCustomer);
    this.existCartData = this.cartData;
    this.openDigitalDialog(this.catalogData.fileHash, this.catalogData.companyID);
  }

  openDigitalDialog(fileHash, companyID) {
    this.errorMsg = false;
    this.pdfViewPercent = 0;
    const rand = Math.floor(Math.random() * 20) + 1;
    this.pdfSrc = this.manufacturerService.getDocURL({ fileHash, companyID }) + '?time=' + rand + 5;
    openDigitalCatelogDialog(this.pdfSrc);
  }

  async closePdfDialog() {
    closeDigitalCatelogDialog();
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.remove('overflow-hidden');
    }
  }

  async getCart(customer) {
    // this.basketSyncLoader = true;
    const cartData1 = await this.basketItemService.getBasketItemData(customer);
    this.itemMfgIDs = await this.getItemWithMfg(cartData1);
    // this.basketSyncLoader = false;
    if (cartData1) {
      this.authService.setCartItemCount(cartData1.totalItems);
    }
    return cartData1;
  }

  async getRetailer() {
    if (this.selectedCustomer) {
      if (this.selectedCustomer.shipToLocations && this.selectedCustomer.shipToLocations.length) {
        this.selectedCustomer.shipToLocations = this.selectedCustomer.shipToLocations.map(o => {
          o.retailerID = this.retailerID;
          return o;
        });
        const sessionRetailerShipToLocationID = sessionStorage.getItem('retailerShipToLocationID');
        this.selectedCustomer.selectedShipTo = sessionRetailerShipToLocationID ? JSON.parse(sessionRetailerShipToLocationID) :
          this.selectedCustomer.shipToLocations[0];
      } else if (!this.selectedCustomer.shipToLocations) {
        console.log('no foundShip');
      }
    }
  }

  getItemWithMfg(cartData) {
    const itemIDs: any = [];
    if (objectPath.get(cartData, 'manufacturer', null)) {
      _.forEach(cartData.manufacturer, (o) => {
        _.forEach(o.items, (itemObj: any) => {
          if (itemObj.basketItemVariants && itemObj.basketItemVariants.length) {
            _.forEach(itemObj.basketItemVariants, (biv: any) => {
              itemIDs.push({
                manufacturerID: itemObj.manufacturerID,
                itemNumber: itemObj.itemNumber ? itemObj.itemNumber : itemObj.item.itemNumber,
                itemColorStyleID: biv.itemColorStyleID
              });
            });
          } else {
            itemIDs.push({
              manufacturerID: itemObj.manufacturerID,
              itemNumber: itemObj.item.itemNumber
            });
          }

        });
      });
    }
    return itemIDs;
  }

  promotionsClickCartEvent($event) {
    this.openSpecialModal();
  }

  metClickCartEvent($event) {
    const headerText = 'Manufacturer(s) Minimum';
    this.openMimMetModal(headerText);
  }

  checkoutClickCartEvent($event) {
    let stop = 'no';
    this.cartData.manufacturer.forEach(o => {
      if (o.isMinimumMet === false) {
        stop = 'yes';
        return;
      }
    });

    const isMinOrderRequired = this.authService.getIsMinOrderRequired();
    let headerText = 'Checkout';
    let warningText = 'Continue to Checkout?';
    let actionType = 'checkout';
    if (stop === 'yes') {
      if (isMinOrderRequired === true) {
        headerText = 'Manufacturer Minimum(s) Not Met';
        warningText = 'You have not met the manufacturer minimum(s). '
          + 'Please continue shopping to meet manufacturer requirements.';
        actionType = 'shop';
      } else {
        headerText = 'Manufacturer Minimum(s) Not Met';
        warningText = 'You have not met the manufacturer minimums. Continue to checkout?';
        actionType = 'both';
      }
    }
    this.openMimMetModal(headerText, warningText, actionType);
  }
  updateExistCardCartEvent(dataEvent) {
    this.cartData = dataEvent.existCartData;
    this.existCartData = dataEvent.existCartData;
    this.selectedCustomer = dataEvent.selectedCustomer;
    this.updateExistCardEvent.emit({
      existCartData: dataEvent.existCartData,
      selectedCustomer: dataEvent.selectedCustomer, itemMfgIDs: dataEvent.itemMfgIDs
    });

  }

  updateqty(d) {
    const selectdata = this.itemMfgIDs.filter(x => x.itemRecordID === d.recordID);
    let totalqty = 0;
    d.ismultiple = false;
    if (selectdata.length && !d.isc.length) {
      totalqty = selectdata.map((qt) => qt.quantity ? qt.quantity : 0)
        .reduce((sum, current) => sum + current);
      d.ismultiple = selectdata.length >= 1 ? true : false;
      d.saverecordID = selectdata[0].saverecordID;
    }
    d.isc.forEach((c: any) => {
      const iscselectdata = selectdata.filter(x => x.itemRecordID === c.itemID && x.iscItemRecordID === c.recordID);
      c.ismultiple = false;
      if (iscselectdata.length) {
        c.quantity = iscselectdata.map((qt) => qt.quantity ? qt.quantity : 0)
          .reduce((sum, current) => sum + current);
        totalqty = totalqty + c.quantity;
        c.ismultiple = iscselectdata.length >= 1 ? true : false;
      } else {
        c.quantity = 0;
      }
    });
    d.quantity = totalqty;
  }

  addToCartEvent(dataEvent, activeViewItem) {
    if (_.cloneDeep(this.itemMfgIDs) && _.cloneDeep(this.itemMfgIDs).length) {
      const existFind = this.itemMfgIDs.find(o => (activeViewItem.itemNumber === o.itemNumber &&
        activeViewItem.manufacturerID === o.manufacturerID && !o.recordDeleted));
      if (existFind) {
        this.msgService.clear();
        this.msgService.add({
          key: 'searchItemTable',
          severity: 'info',
          summary: 'Item Updated',
          detail: `${activeViewItem.itemNumber} was updated successfully.`
        });
      } else {
        this.msgService.clear();
        this.msgService.add({
          key: 'searchItemTable',
          severity: 'info',
          summary: 'Item Added',
          detail: `${activeViewItem.itemNumber} was saved successfully.`
        });
      }
    } else {
      this.msgService.clear();
      this.msgService.add({
        key: 'searchItemTable',
        severity: 'info',
        summary: 'Item Added',
        detail: `${activeViewItem.itemNumber} was saved successfully.`
      });
    }
    this.itemMfgIDs = dataEvent.itemMfgIDs;
    this.cartData = dataEvent.cartData;
    this.existCartData = this.cartData;
    this.cartDataEvent.next(dataEvent);
    if (dataEvent.linkedItemsData && dataEvent.linkedItemsData.length > 0) {
    } else {
      this.showCatalogShopDialog = false;
    }
  }

  openSpecialModal() {
    this.manufacturersArr.forEach(element => {
      const mfrIndex = this.cartData.manufacturer.findIndex(p => p.recordID === element.recordID);
      element.promotions = (mfrIndex >= 0) ? this.cartData.manufacturer[mfrIndex].promotions : [];
    });

    const ref = this.dialogService.open(PromotionsDialogComponent, {
      header: 'Promotions',
      width: '70%',
      height: 'auto',
      styleClass: 'overflow-auto cut-dialog-height responsive-ship-loc res-dialog-promo',
      closable: true,
      baseZIndex: 100000, // RM (29-02-2020): change - comment code, because cart sidebar keep open
      data: {
        manufacturers: this.cartData.manufacturer
      }
    });
  }

  openMimMetModal(headerText, warningText = '', actionType = '') {
    const ref = this.dialogService.open(MinimumMetDialogComponent, {
      header: headerText,
      width: '50%',
      height: 'auto',
      styleClass: 'overflow-auto cut-dialog-height responsive-ship-loc',
      closable: true,
      baseZIndex: 100000, // RM (29-02-2020): change - comment code, because cart sidebar keep open
      data: {
        manufacturers: this.cartData.manufacturer,
        warningText,
        actionType,
        retailer: this.selectedCustomer
      },

    });

    ref.onClose.subscribe((checkoutData) => {
      if (checkoutData) {
        const ordersIDs = checkoutData.map((o) => o.recordID);
        this.router.navigate([`/finalize-order`], { queryParams: { orderIDs: ordersIDs.join() } });
      }

    });
  }

  openItemSearchModal() {
    this.showSearchDialog = true;
  }

  async getAllInfo(event) {
    this.showSearchDialog = false;
    if (event && event.selectionData && event.selectionData.recordID) {
      const itemObj = event.selectionData;
      const url = this.pdfSrc;
      const searchText = itemObj.itemNumber;
      this.showSearchLoader = true;
      const feedBack = await getAnnotationsFlipbook(url, searchText);
      this.showSearchLoader = false;
      if (feedBack) {
        this.msgService.clear();
        this.msgService.add({ severity: 'info', summary: 'Item search', detail: `${searchText}: Item Found` });
      } else {
        this.msgService.clear();
        this.msgService.add({ severity: 'error', summary: 'Error', detail: `${searchText}: Item Not Found` });
      }
    }
  }

  openCart() {
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.add('overflow-hidden');
    }
    this.cartSidebar = true;
  }

  ngOnDestroy() {
    this.closeDialog.emit(false);
    this.closePdfDialog();
    this.getRetailerDataSubscription.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target['tagName'] === 'A') {
      if (this.eRef.nativeElement.contains(event.target)) {
        if (event.target['href'] !== undefined && event.target['href'] !== null && event.target['href'] !== '') {
          if (event.target['href'].startsWith('https://catalogshop.reptime.com')) {
            event.preventDefault();
            const href = new URL(event.target['href']);
            const securityParam = '?companyID=' + this.authService.getCompanyID();
            const params = new URLSearchParams(href.search);
            this.catalogShopItemLoading = true;
            this.showLoader = true;
            this.itemsAPIService.getItemDetails(params.get('IID') + securityParam).subscribe(async (itemResponse: any) => {
              if (itemResponse && itemResponse.recordDeleted) { 
                this.msgService.clear();
                this.msgService.add({ severity: 'warn', summary: 'Warning', detail: 'This item has been removed contact your sales representative for more information.' });
                this.catalogShopItemLoading = false;
                this.showLoader = false;
              } else {
                this.catalogShopItem = itemResponse;
                if (this.catalogShopItem) {
                  this.cartData = await this.getCart(this.selectedCustomer);
                  this.existCartData = this.cartData;
                  this.activeViewItem = this.catalogShopItem;
                  // tslint:disable-next-line: max-line-length
                  this.activeViewIndex = this.cartData && this.cartData.length && this.cartData.items ? _.findIndex(this.cartData.items, (o) => o.recordID === this.activeViewItem.recordID) : '';
                  this.showCatalogShopDialog = true;
                  const selectdata = this.itemMfgIDs.find(x => x.itemRecordID === this.catalogShopItem['recordID']);
                  this.catalogShopItem['basicUnitPrice'] = _.cloneDeep(this.catalogShopItem['unitPrice']);
                  this.catalogShopItem['itemDiscount'] = 0;
                  this.catalogShopItem['quantity'] = selectdata && selectdata.quantity ? selectdata.quantity : this.catalogShopItem['quantity'] ? this.catalogShopItem['quantity'] : 0;
                  this.catalogShopItem['selectedPriceCode'] = '';
                  this.catalogShopItem['imageError'] = false;
                  this.catalogShopItem['imageLoader'] = true;
                  this.catalogShopItem['fullPicturePath'] = await this.itemsAPIService.getFullPicturePath(this.catalogShopItem['picturePath'], this.catalogShopItem['manufacturerID']);
                  this.catalogShopItemLoading = false;
                  this.updateqty(itemResponse);
                  this.showLoader = false;
                  // this.rescale();
                } else {
                  this.msgService.clear();
                  this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Item Not Found' });
                  this.catalogShopItemLoading = false;
                  this.showLoader = false;
                }
              }
            }, () => {
              this.catalogShopItemLoading = false;
            });
            return false;
          }
        }
      }
    }
  }

}
