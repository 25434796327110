import {Injectable} from '@angular/core';

// import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class UrlToolsService {

    constructor() {
    }

    addTokens(url: string, params) {
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        // url = url.replace('{companyId}', this.authService.getCompanyID());
        // url = url.replace('{companyID}', this.authService.getCompanyID());
        return url;
    }

}
