<div class="reptime-theme custom-header finalize-browse-item">
    <!-- <p-toast key="addItem" position="top-right"></p-toast> -->
    <rs-items-table *ngIf="retailer"
                    [repGroupID]="repGroupID"
                    [searchText]="searchText"
                    [fromPage]="fromPage"
                    [(onlyOneAdd)]="onlyOneAdd"
                    [manufacturersArr]="manufacturersArr"
                    [selectedManufacturer]="selectedManufacturer"
                    [selectedCustomer]="retailer"
                    [existItemsData]="existItemsData"
                    [rows]="20"
                    (addToCart)="addToCart($event)"
                    (viewISC)="openItemStyleColorModal($event)"
                    (viewHistory)="openHistoryItem($event)"
                    (cartDataEvent)="OrderDataEvent($event)"
    ></rs-items-table>
</div>
