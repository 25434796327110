<p-dialog [(visible)]="isOpen"
          [closable]="true" [draggable]="false" [blockScroll]="true"
          [closeOnEscape]="true" [showHeader]="false"
          [style]="{'width': '800px'}" [positionTop]="60"
          [maximizable]="false" [modal]="true"
          [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'"  [resizable]="false" styleClass="cut-dialog-height add-cat-dialog responsive-ship-loc order-payment-dialog">
    <div class="d-flex mt-5 mb-10">
        <h3 class="my-0 flex-grow-1 title-text">Payment Info
            <span *ngIf="selectedPaymentInfo && selectedPaymentInfo.recordID">- {{selectedPaymentInfo.paymentType}} card ending in {{selectedPaymentInfo.cardNumber}}</span>
        </h3>
        <!-- RC-500 MG 16JAN2020 modified button -->
        <a class="text-black" *ngIf="!loading" (click)="isOpenChange.emit(false); OnCloseDialog.emit(paymentInfoForm.valid);"><i class="pi pi-times fs-22"></i></a>
    </div>
    <form (submit)="onFormSubmit($event)" [formGroup]="paymentInfoForm" style="width: 100%" autocomplete="off">
        <div class="p-grid p-5">
            <div class="p-col-12" *ngIf="isOrderPayment && retailerPaymentTerms.length > 1">
                <p-dropdown name="retailerPaymentTermDropdown" [options]="retailerPaymentTerms" id="paymentTerms"
                            class="control-dropdown" [style]="{'width':'100%'}"
                            (onChange)="onRetailerPaymentTermChange($event)" [(ngModel)]="selectedRetailerTerm"
                            [ngModelOptions]="{standalone:true}">
                </p-dropdown>
            </div>
            <!-- <label for="paymentTerms">Select card number</label>
             <div class="input-error-container">
                 <p-dropdown name="retailerPaymentTermDropdown" [options]="retailerPaymentTerms" id="paymentTerms"
                             class="control-dropdown" [style]="{'width':'100%'}"
                             (onChange)="onRetailerPaymentTermChange($event)" [(ngModel)]="selectedRetailerTerm">
                 </p-dropdown>
             </div>-->
            <div class="p-col-6">
                <label for="paymentType">Payment Type</label>
                <div class="input-error-container">
                    <!-- RC-444 (RK 16 JAN 2020) Add onPaymentTypeChange onChange event -->
                    <p-dropdown #dropDown formControlName="paymentType" name="paymentType"
                                [disabled]="selectedPaymentInfo && selectedPaymentInfo.recordID"
                                (onChange)="onPaymentTypeChange(dropDown)" [(ngModel)]="selectedPaymentType"
                                [options]="paymentTypes" id="paymentType" [style]="{'width':'100%'}"></p-dropdown>
                    <i class="error-icon pi pi-info-circle multiSelect-error"
                       *ngIf="validateChk.paymentType.invalid && (validateChk.paymentType.dirty || validateChk.paymentType.touched)"
                       [pTooltip]="getErrorMsg(validateChk.paymentType,'Payment type')"
                       tooltipPosition="left"
                       tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="cardHolderName">Card Holder Name</label>
                <div class="input-error-container">
                    <input pInputText class="control" formControlName="cardHolderName" autocomplete="off"
                        appCustomValidation [allowStartWithSpace]="false"
                        name="cardHolderName" type="text" id="cardHolderName"/>
                    <i class="error-icon pi pi-info-circle"
                       *ngIf="validateChk.cardHolderName.invalid && (validateChk.cardHolderName.dirty || validateChk.cardHolderName.touched)"
                       [pTooltip]="getErrorMsg(validateChk.cardHolderName,'Card Holder Name')"
                       tooltipPosition="left"
                       tooltipEvent="hover"></i>

                </div>
            </div>
            <div class="p-col-6" *ngIf="isDisabledForm">
                <label for="cardNumber">Card Number</label>
                <div class="input-error-container">
                    <input type="text" pInputText name="ccNumberShort" formControlName="cardNumber"
                           autocomplete="new-password"
                           appCustomValidation [allowStartWithSpace]="false"
                           class="control" id="cardNumber">
                </div>
            </div>
            <!-- // [RC-626] (MG 14-APR-2020) modified to make update vault URL -->
            <div class="p-col-6 p-0"
                 *ngIf="orderID || (retailerID && selectedPaymentInfo && !selectedPaymentInfo.recordID)">
                <!-- <iframe #cciframe src="https://vault02-sb.auricsystems.com/corp/reptime/v1/auricenc.html" -->
                <iframe #cciframe [src]="valutURLSafe"
                        [frameBorder]="0"
                        (load)="onIframeReady()"
                        style="width: 100%;"
                        [ngStyle]="{'height' : isOrderPayment && !isDisabledForm ? '132px' :'66px' }"></iframe>
            </div>
            <div class="p-col-6">
                <label>Expiration Date</label>
                <div class="d-flex">
                    <div class="input-error-container d-inline-block mr-5" id="expirationMonth" style="width:135px">
                        <p-dropdown formControlName="expirationMonth" title="Expiration Month"
                                    name="expirationMonth" class="control-dropdown" appendTo="body"
                                    [options]="monthOptions" [style]="{'width':'100%'}"></p-dropdown>
                        <i class="error-icon pi pi-info-circle multiSelect-error"
                           *ngIf="validateChk.expirationMonth.invalid && (validateChk.expirationMonth.dirty || validateChk.expirationMonth.touched)"
                           [pTooltip]="getErrorMsg(validateChk.expirationMonth,'Expiration Month')"
                           tooltipPosition="left"
                           tooltipEvent="hover"></i>
                    </div>
                    <div class="input-error-container d-inline-block" id="expirationYear" style="width:135px">
                        <p-dropdown formControlName="expirationYear" title="Expiration Year"
                                    class="control-dropdown" autocomplete="off" appendTo="body"
                                    name="expirationYear"
                                    [options]="yearOptions" [style]="{'width':'100%'}"></p-dropdown>
                        <i class="error-icon pi pi-info-circle multiSelect-error"
                           *ngIf="validateChk.expirationYear.invalid && (validateChk.expirationYear.dirty || validateChk.expirationYear.touched)"
                           [pTooltip]="getErrorMsg(validateChk.expirationYear,'Expiration Year')"
                           tooltipPosition="left"
                           tooltipEvent="hover"></i>
                    </div>
                </div>
            </div>
            <div class="p-col-6">
                <label for="billAddress">Billing Address</label>
                <div class="input-error-container">
                    <input pInputText class="control" formControlName="billAddress" autocomplete="new-password"
                           appCustomValidation [allowStartWithSpace]="false"
                           name="billAddress" maxlength="100"
                           id="billAddress"/>
                    <i class="error-icon pi pi-info-circle"
                       *ngIf="validateChk.billAddress.invalid && (validateChk.billAddress.dirty || validateChk.billAddress.touched)"
                       [pTooltip]="getErrorMsg(validateChk.billAddress,'Billing Address')"
                       tooltipPosition="left"
                       tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="billCity">Billing City</label>
                <div class="input-error-container">
                    <input pInputText class="control" formControlName="billCity" autocomplete="new-password"
                           name="billCity"  maxlength="50"
                           appCustomValidation [allowStartWithSpace]="false"
                           id="billCity"/>
                    <i class="error-icon pi pi-info-circle"
                       *ngIf="validateChk.billCity.invalid && (validateChk.billCity.dirty || validateChk.billCity.touched)"
                       [pTooltip]="getErrorMsg(validateChk.billCity,'Billing City')"
                       tooltipPosition="left"
                       tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="billState">Billing State</label>
                <div class="input-error-container">
                    <input pInputText class="control" formControlName="billState" autocomplete="new-password"
                           name="billState"  maxlength="50"
                           appCustomValidation [allowStartWithSpace]="false"
                           id="billState"/>
                    <i class="error-icon pi pi-info-circle"
                       *ngIf="validateChk.billState.invalid && (validateChk.billState.dirty || validateChk.billState.touched)"
                       [pTooltip]="getErrorMsg(validateChk.billState,'Billing State')"
                       tooltipPosition="left"
                       tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="billZip">Billing Zip</label>
                <div class="input-error-container">
                    <input pInputText class="control" formControlName="billZip" autocomplete="new-password"
                           name="billZip"  maxlength="20"
                           appCustomValidation [allowStartWithSpace]="false"
                           id="billZip"/>
                    <i class="error-icon pi pi-info-circle"
                       *ngIf="validateChk.billZip.invalid && (validateChk.billZip.dirty || validateChk.billZip.touched)"
                       [pTooltip]="getErrorMsg(validateChk.billZip,'Billing Zip')"
                       tooltipPosition="left"
                       tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-12">
                <div class="d-flex align-items-center justify-content-between payment-chk-box">
                    <div class="flex-grow-1 btm-chk">
                        <form class="d-inline-block">
                        <p-checkbox name="copyBillToAddress" binary="true" *ngIf="!this.isDisabledForm"
                                    title="Save Card to Retailer"
                                    (onChange)="copyAddress()"
                                    [(ngModel)]="copyBillToAddress" label="Copy Address From Bill To">
                        </p-checkbox>
                        </form>
                    </div>
                    <div class="flex-grow-1 btm-chk">
                        <form class="d-inline-block">
                        <p-checkbox name="copyBillToAddressToShip" binary="true" *ngIf="!this.isDisabledForm"
                                    title="Save Card to Retailer"
                                    (onChange)="copyAddressShipTo()"
                                    [(ngModel)]="copyBillToAddressToShip" label="Copy Address From Ship To">
                        </p-checkbox>
                        </form>
                    </div>
                    <div class="btn-checkbox">
                        <form class="d-inline-block">
                            <p-checkbox name="saveCardInfo" binary="true"
                                        title="Save Card to Retailer" [disabled]="isDisabledForm"
                                        [(ngModel)]="saveCardToDB" label="Save Card To Retailer">
                            </p-checkbox>
                        </form>
                        <!-- RC-500 MG 16JAN2020 modified button -->
                        <!-- <button type="submit" class="ui-button ui-button-info ml-10" pButton title="Save Card Info"
                                label="Save Card Info"></button> -->
                        <button class="ui-button ui-button-info btn-payment-submit ml-10 btn-theme-color" pButton [disabled]="loading" [ngStyle]="{'margin-left': '10px' }" [icon]="loading ? 'pi pi-spin pi-spinner' : 'fa ' + ''" [label]="'Save Card Info'"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</p-dialog>
<p-toast key="addPaymentInfo" position="top-center" [baseZIndex]="110000"></p-toast>
