import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {InputTextModule, MessageModule} from 'primeng/primeng';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';

import {ReactiveFormsModule} from '@angular/forms';

import {LoginComponent} from './login.component';
import {ToastModule} from 'primeng/toast';
import { CustomValidationDirectiveModule } from 'src/app/directives/custom-validation.directive';

const routes = [{path: '', component: LoginComponent}];

@NgModule({
    declarations: [LoginComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        CardModule,
        InputTextModule,
        ButtonModule,
        MessageModule,
        ToastModule,
        CustomValidationDirectiveModule
    ],
    exports: [LoginComponent]
})
export class LoginModule {
}
