import {Injectable} from '@angular/core';
import {CompanyAPIService} from '../../api/company-api/company-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    docUrl = `${environment.apiUrl}reptime/manufacturer/{companyID}/docs/getFile/{fileHash}`;
    constructor(private companyAPIService: CompanyAPIService) {
    }

    async getCompanyCategoriesData() {
        return this.companyAPIService.getCompanyCategories();
    }

    // B2B-53 (MG 04-FEB-2020): modified to save filtered documents
    getMFRDocumentsData(payload: any, queryStringParam: any) {
        return this.companyAPIService.getMFRDocuments(payload, queryStringParam);
    }

    // B2B-53 (MG 04-FEB-2020): modified to save filtered documents
    getDocURL(params) {
        let url = this.docUrl;
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }

    // B2B-53 (MG 04-FEB-2020): modified to save filtered documents
    downloadDoc(fileHash, companyID) {
        return this.companyAPIService.downloadDoc(this.getDocURL({fileHash, companyID}));
    }
}
