<div class="container-fluid p-0" [ngClass]="(header === 2)?'lightovation-fluid':'traditional-fluid'">
    <div #customStylesHash></div>
    <!-- [B2B-274] (mk 26-JUN-2020) Dynamic header -->
    <app-header *ngIf="(entityType != 'auth') && domainData && header===1 || (isIframeWindow && header===1)"
        [loggedInUser]="loggedInUser" [mobMenuList]="mobMenuList" [items]="items"
        [menuShimmerAction]="menuShimmerAction" [groups]="groups" [notApprovedByRepGroup]="notApprovedByRepGroup"
        [logo]="logo" [logoClickURL]="logoClickURL" [hidemenutoggle]="hidemenutoggle"
        (fullPageLoader)="fullPageLoader=$event">
    </app-header>

    <app-lightovation-header
        *ngIf="domainData && header===2 && entityType != 'landing-page' && entityType != 'auth' || (isIframeWindow && header===2 && entityType != 'landing-page' && entityType != 'auth')"
        [loggedInUser]="loggedInUser" [mobMenuList]="mobMenuList" [items]="items"
        [menuShimmerAction]="menuShimmerAction" [groups]="groups" [notApprovedByRepGroup]="notApprovedByRepGroup"
        [logo]="logo" [logoClickURL]="logoClickURL" [hidemenutoggle]="hidemenutoggle"
        (fullPageLoader)="fullPageLoader=$event"></app-lightovation-header>
    <!-- // [B2B-108] (MG 26-FEB-2020) modified to make cart style dynamic -->
    <!-- "shop-four-img" add class for 4 image view -->
    <!-- [B2B-274] (mk 26-JUN-2020) Dynamic header -->
    <div class="container-fluid p-0 h-100"
        [ngClass]="{'pt-0':domainData, 'Rale-font-height': getFontText === 'Raleway', 'rok-font-height': getFontText === 'Rokkitt', 'arc-font-height': getFontText === 'Archivo' }">
        <div class="b2b-main-container {{(header === 2 || entityType === 'my-orders')?'main-wrapper-lightovation':'main-wrapper'}} {{(header === 2 && entityType === 'lightovation-home' || entityType === 'magazine' || entityType === 'landing-page') ?'container-lightovation':'container'}}"
            [ngClass]="{'p-0 full-width':!domainData ,'shopinfohun': (auth.getCartStyle() === 0 || auth.getCartStyle() === 3) && hasSidebar, 'shopstaticcart': auth.getCartStyle() === 2 && hasSidebar, 'shop-four-img': (auth.getCartStyle() === 1 || auth.getCartStyle() === 4) && hasSidebar, 'no-group-approve':  !groups?.length && !notApprovedByRepGroup}">
            <div class="b2b-sidebar" *ngIf="hasSidebar && domainData">
                <app-sidebar [navigatedURL]="navigatedURL" [whichPage]="'shop'" [pageType]="false" [pageAllType]="true"
                    *ngIf="loggedInUser"></app-sidebar>
                <app-sidebar [navigatedURL]="navigatedURL" [whichPage]="'store'" [pageType]="true" [pageAllType]="true"
                    *ngIf="!loggedInUser"></app-sidebar>
            </div>
            <!-- [class.showSidebar]="hasSidebar" -->
            <div [ngClass]="hasSidebar?'showSidebar' : 'wid-without-sidebar'">
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- [B2B-274] (mk 26-JUN-2020) Dynamic header -->
        <app-footer *ngIf="(entityType != 'auth') && domainData && header===1 || (isIframeWindow && header===1)">
        </app-footer>
        <app-lightovation-footer
            *ngIf="domainData && header===2 && entityType != 'landing-page' && entityType != 'auth' || (isIframeWindow && header===2  && entityType != 'landing-page' && entityType != 'auth')"
            [domainData]="domainData" [logo]="logo"></app-lightovation-footer>

    </div>

    <!-- Start full page loader -->
    <div class="full-page-loader" *ngIf="fullPageLoader">
        <div class="full-page-loadinner">
            <p-progressSpinner [style]="{width: '150px', height: '150px'}" strokeWidth="2"></p-progressSpinner>
        </div>
    </div>
    <!-- End -->

</div>
<p-toast position="top-right" [baseZIndex]="11001"></p-toast>
<p-confirmDialog key="unfinishedOrder" [appendTo]="'body'" [baseZIndex]="10000"></p-confirmDialog>
<RtCustom-confirmDialog key="breckincrementcart" [baseZIndex]="1000" [appendTo]="'body'" #breckincrementcart
    header="Drop database" acceptLabel="Yes" rejectLabel="No">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="Yes" id="YesButton"
            (click)="breckincrementcart.accept()"></button>
        <button type="button" class="ui-button-secondary" pButton icon="pi pi-times" label="No"
            (click)="breckincrementcart.close($event)"></button>
    </p-footer>
</RtCustom-confirmDialog>

<!-- no internet -->
<link rel="preload" href="/assets/images/no-wifi.svg" as="image">
<div class="no-internet" *ngIf="!isInternetActive"> <img src="/assets/images/no-wifi.svg" alt=""> There is no internet
    connection</div>
<!-- End -->