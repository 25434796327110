import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
    name: 'rtCurrency'
})
export class RtCurrencyPipe implements PipeTransform {

    constructor() {
    }

    transform(value: any) {
        let isNagative = false;
        if (value < 0) {
            isNagative = true;
            value = Number(value.toString().replace('-', ''));
        }
        const symbol = environment.currencySymbol ? environment.currencySymbol : '$';
        const toFixValue = environment.currencyDecimalPoint ? environment.currencyDecimalPoint : 2;
        let final;
        if (value !== null && value !== undefined && value !== '') {
            if (environment.currencyFormat === 'X,XXX,XXX.XX') {
                final = this.getFormat(value, toFixValue, 3, ',', '.');
            } else if (environment.currencyFormat === 'X XXX XXX,XX') {
                final = this.getFormat(value, toFixValue, 3, ' ', ',');
            } else if (environment.currencyFormat === 'X.XXX.XXX,XX') {
                final = this.getFormat(value, toFixValue, 3, '.', ',');
            } else {
                final = this.getFormat(value, toFixValue, 3, ',', '.');
            }
            if (isNagative) {
                return `-${symbol}${final}`;
            } else {
                return `${symbol}${final}`;
            }
        } else {
            return '';
        }
    }

    /**
     *
     * @param amt is amount
     * @param n decimalPoint value
     * @param x seprate a amount in its value EX: amount=123456 | x value is 2 | result = 12 34 56
     * @param s sepretor for seprated value from x
     * @param c place the value of c before decimal value
     * @returns finalAmount
     */
    getFormat(amt, n, x, s, c) {
        const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
            num = Number(amt).toFixed(n);

        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    }
}
