<div class="center main-loader" *ngIf="promotionsLoader">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
</div>

<div class="promotion-list-main" *ngIf="!promotionsLoader && promotionsGridDialog && promotionsGridDialog.data">
    <div class="promotion-list-block" *ngFor="let promotions of promotionsGridDialog.data">
        <div class="left-sidebar">
            <div class="amt-date-wrap">
                <div class="amttomeet-date">
                    <div class="amt-wrap">
                        <span>Amount to meet</span>
                        <div class="amtmeet-total">{{promotions.amountToMeet | rtCurrency}}</div>
                    </div>
                    <div class="date-wrap">
                        {{promotions.startDate | rtDate}}
                        <span> to </span>
                        {{promotions.endDate | rtDate}}
                    </div>
                </div>
                <div class="dis-amt-wrap">
                    <span class="dis-amt-heading">{{promotions.discountPercent == null ? 'Discount Amount' : 'Discount Percent'}}</span>
                    {{promotions.discountPercent == null ?
                        (promotions.discountAmount | rtCurrency) :
                        (promotions.discountPercent + '%')}}
                </div>
            </div>
            <div class="content-wrap">
                <div class="promo-name"><strong>{{promotions.name}}</strong></div>
                <div class="promo-des">
                    {{promotions.description}}
                </div>
            </div>
        </div>
        
    </div>
    <!-- <div class="pt-10 pb-10 promotion-list-block" *ngFor="let promotions of promotionsGridDialog.data">
        <div class="promo-name mb-5"><strong>{{promotions.name}}</strong></div>
        <div class="promo-date mb-5 d-flex justify-content-between">
            <div class="">
                <strong>Date: </strong>
            </div>
            <div class="">
                <strong>Amount: </strong>
                {{promotions.amountToMeet | rtCurrency}}
            </div>
            <div class="">
                <strong>Discount: </strong>
                {{promotions.discountPercent == null ?
                    (promotions.discountAmount | rtCurrency) :
                    (promotions.discountPercent + '%')}}
            </div>
        </div>
        <div class="promo-date">
            <div class="">
                <strong>Description: </strong>
                {{promotions.description}}
            </div>
        </div>
    </div> -->
</div>

<div class="promotion-list-main" *ngIf="!promotionsLoader && promotionsGridDialog && promotionsGridDialog.length === 0">
    <p class="empty-promotion">There are no current promotions at this time. Pleae check back later.</p>
</div>  