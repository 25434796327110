 <!-- <p-button type="button" (onClick)="manageompany()"
                              [styleClass]="'ui-button-success'" icon="fa fa-th-list"
                              label="Manage Company"
                              ></p-button> -->

<div class="page-not-found-page h-100 py-30 w-75 ship-not-found" style="margin: 0 auto">
    <div class="p-grid">
        <div class="ui-g p-col-12 p-justify-center">
            <p-card >
                <div class="text-center">
                    <h1 class="m-0 mt-5 oops">Shipping Location Needed</h1>
                    <h2 class="mt-5" style="font-weight: normal;">You have not added a shipping location</h2>
                    <h4>
                        <!-- Please add a shipping location. -->
                    </h4>
                </div>
                <div class="d-flex justify-content-center">

                              <div class="reptime-theme">
                                <form [formGroup]="shipToLocationForm" (ngSubmit)="saveSelectedShipTo()">
                                    <div id="form-errors"></div>
                                    <div class="p-grid p-5">
                                        <div class="p-col-12"><label for="name">Location Name*</label>
                                            <div class="input-error-container">
                                                <input [maxlength]="50" [required]="true" autocomplete="off" class="control" formControlName="name"
                                                    id="name" pInputText type="text" />
                                                <i *ngIf="validateChk.name.invalid && (validateChk.name.dirty || validateChk.name.touched)"
                                                    class="error-icon pi pi-info-circle" pTooltip="Enter your username" tooltipPosition="left"
                                                    tooltipEvent="hover"></i>
                                            </div>
                                        </div>
                                        <div class="p-col-6"><label for="address1">Address 1*</label>
                                            <div class="input-error-container">
                                                <input [maxlength]="100" autocomplete="off" class="control" formControlName="address1" id="address1"
                                                    pInputText type="text" />
                                                <i *ngIf="validateChk.address1.invalid && (validateChk.address1.dirty || validateChk.address1.touched)"
                                                    class="error-icon pi pi-info-circle" pTooltip="getErrorMsg(validateChk.address1,'Address')"
                                                    tooltipPosition="left" tooltipEvent="hover"></i>
                                            </div>
                                        </div>
                                        <div class="p-col-6"><label for="address2">Address 2</label><br />
                                            <input [maxlength]="50" autocomplete="off" class="control" formControlName="address2" id="address2"
                                                pInputText type="text" />
                                        </div>
                                        <div class="p-col-6">
                                            <label for="country">Country*</label> <br />
                                            <div class="input-error-container">
                                                <p-dropdown (onChange)="countrySpecific()" [filter]="true" [options]="countries"
                                                    [style]="{'width':'100%'}" class="control-dropdown" formControlName="country" id="country">
                                                </p-dropdown>
                                                <i *ngIf="validateChk.country.invalid && (validateChk.country.dirty || validateChk.country.touched)"
                                                    class="error-icon pi pi-info-circle"
                                                    [pTooltip]="getErrorMsg(validateChk.country,'country Name')" tooltipPosition="left"
                                                    tooltipEvent="hover"></i>
                                            </div>
                                        </div>
                                        <div class="p-col-3 zip-wid">
                                            <label for="zip">{{validateChk['country'].value === 'US' ? 'Zip Code*' : 'Zip Code'}}</label>
                                            <div class="input-error-container">
                                                <input [required]="validateChk['country'].value === 'US' ? true : false" class="control" formControlName="zip" id="zip" maxlength="20" pInputText
                                                    type="text" (blur)="updateChange()">
                                                <i *ngIf="validateChk.zip.invalid && (validateChk.zip.dirty || validateChk.zip.touched)"
                                                    class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.zip,'Zip')"
                                                    tooltipPosition="left" tooltipEvent="hover"></i>
                                            </div>
                                        </div>
                                        <div class="p-col-3 ship-opt">
                                            <label for="primary">Primary</label> <br />
                                            <p-selectButton class="primaryes" [options]="primaryOptions" id="primary" formControlName="isPrimary"></p-selectButton>
                                        </div>
                                        <div class="p-col-6">
                                            <!-- <div *ngIf="validateChk['country'].value !== 'US';then content else other_content"></div> -->
                                            <div *ngIf="countriesStateJSON && countriesStateJSON[validateChk['country'].value] && countriesStateJSON[validateChk['country'].value].length;then other_content else content"></div>
                                            <ng-template #content>
                                                <label for="non-us-state">State*</label>
                                                <div class="input-error-container">
                                                    <input class="control" formControlName="state"
                                                        id="non-us-state" maxlength="50" name="state" pInputText type="text">
                                                    <i *ngIf="validateChk.state.invalid && (validateChk.state.dirty || validateChk.state.touched)"
                                                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.state,'State')"
                                                        tooltipPosition="left" tooltipEvent="hover"></i>
                                                </div>
                                            </ng-template>
                                            <ng-template #other_content>
                                                <label for="us-state">State*</label>
                                                <div class="input-error-container">
                                                    <p-dropdown [filter]="true" [options]="countriesStateJSON[validateChk['country'].value]"
                                                        [style]="{'width':'100%'}" class="control-dropdown" formControlName="state" id="us-state">
                                                    </p-dropdown>
                                                    <i *ngIf="validateChk.state.invalid && (validateChk.state.dirty || validateChk.state.touched)"
                                                        class="multiSelect-error error-icon pi pi-info-circle"
                                                        [pTooltip]="getErrorMsg(validateChk.state,'State')" tooltipPosition="left"
                                                        tooltipEvent="hover"></i>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="p-col-6">
                                            <label for="city">City*</label>
                                            <div class="input-error-container">
                                                <input class="control" formControlName="city" id="city" maxlength="50" pInputText type="text">
                                                <i *ngIf="validateChk.city.invalid && (validateChk.city.dirty || validateChk.city.touched)"
                                                    class="error-icon pi pi-info-circle" pTooltip="getErrorMsg(validateChk.city,'City')"
                                                    tooltipPosition="left" tooltipEvent="hover"></i>
                                            </div>
                                        </div>

                                        <div class="p-col-3 resp-e50"><label for="phone">Phone</label> <br />
                                            <!-- <p-inputMask class="inputmask-control" [unmask]="true" formControlName="phone" id="phone"
                                                [style]="{'border':'1px solid #628292','width':'100%'}" mask="(999) 999-9999"></p-inputMask> -->
                                            <!-- <input class="control" formControlName="phone" id="phone" maxlength="12" minlength="10" pInputText pKeyFilter="pnum" autocomplete="off" type="text"> -->
                                            <p-inputMask [unmask]="true" formControlName="phone" styleClass="control" minlength="10" maxlength="12" id="phone" mask="999-999-9999?99" autocomplete="off" type="text"></p-inputMask>
                                        </div>
                                        <div class="p-col-3 resp-e50"><label for="phoneExtension">Ext #</label><br />
                                            <input [maxlength]="10" autocomplete="off" class="control" formControlName="phoneExtension"
                                                id="phoneExtension" pInputText type="text" />
                                        </div>
                                        <div class="p-col-3 resp-e50"><label for="fax">Fax</label> <br />
                                            <p-inputMask class="inputmask-control" [unmask]="true" formControlName="fax"
                                                [style]="{'border':'1px solid #628292','width':'100%'}" id="fax" mask="999-999-9999">
                                            </p-inputMask>
                                        </div>
                                        <div class="p-col-3 resp-e50"><label for="faxExtension">Ext #</label> <br />
                                            <input [maxlength]="10" autocomplete="off" class="control" formControlName="faxExtension"
                                                id="faxExtension" pInputText type="text" />
                                        </div>
                                        <div class="p-col-12"><label for="email">Email</label> <br />
                                            <input [maxlength]="80" autocomplete="off" class="control" formControlName="email" id="email" pInputText
                                                type="text" />
                                        </div>
                                        <!-- <div class="p-col-12"><label for="notes">Notes</label><br/>
                                            <textarea id="notes" maxlength="250" class="control"
                                                      formControlName="notes" pInputTextarea rows="3" style="width: 100%;"
                                                      type="text"></textarea>
                                        </div> -->
                                    </div>
                                    <form class="d-inline-block">
                                        <div class="p-col-12">
                                            <p-checkbox name="copyBillToAddress" binary="true"
                                                        title="Save Card to Retailer"
                                                        (onChange)="copyAddress()" [(ngModel)]="copyBillToAddress" label="Copy Address From Bill To">
                                            </p-checkbox>
                                        </div>
                                    </form>
                                    <div class="p-col-12 d-flex justify-content-end" >
                                        <button type="submit" [disabled]=saveLoading class="ui-button ui-button-info btn-theme-color" icon="{{!saveLoading?'fa fa-save':'fa fa-spinner'}}" pButton
                                            label="Save"></button>
                                    </div>


                                </form>
                            </div>

                </div>
            </p-card>


        </div>
    </div>
</div>
