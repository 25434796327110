<form class="p-grid" style="padding: 5px;"
      (submit)="addRow($event);" [formGroup]="salespersonDetail"
      #salepersonFormEle>
    <div style="position: fixed; top:0;left:0;z-index: 5000" #multiDropdownContainer></div>
    <div class="p-col-12">
        <p-table [columns]="cols" [value]="salesPersonTable" [rows]="3" [loading]="isLoading"
                 [ngClass]="'custom-table'"
                 #salespersonTableEle>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [ngClass]="{'action-column':col.field === 'action'}">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr *ngIf="!rowData.recordDeleted">
                    <td *ngFor="let col of columns">
                        <ng-container *ngIf="col.field === 'action'">
                            <button type="button" pButton icon="pi pi-trash" class="ui-button-danger"
                                    (click)="deleteRow(rowData)"></button>
                        </ng-container>
                        <ng-container *ngIf="col.field !== 'action'&&col.field !== 'salesPersonName'">
                            {{rowData[col.field]}}
                        </ng-container>
                        <ng-container *ngIf="col.field === 'salesPersonName'">
                            {{rowData[col.field]}}&nbsp;[{{rowData['salesPersonID']}}]
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" class="custom-footer" let-rowData>
                <tr *ngIf="getTotalLength === 0" class="table-empty" style=" height: 130px;">
                    <td [attr.colspan]="cols && cols.length"
                        class="text-center fs-15">{{isLoading ? 'Loading Salespeople' : 'You have no salesperson yet. Click + to add one.'}}
                    </td>
                </tr>
                <tr *ngIf="filteredSalesPersonTypeList.length !== 0">
                    <td>
                        <p-dropdown autoWidth="false" [style]="{'width':'100%'}" id="salesPerson"
                                    formControlName="salesperson" [filter]="true" (onFocus)="setFilteredSalesPersonList()"
                                    [appendTo]="multiDropdownContainer" [options]="filteredSalesPersonList"
                                    placeholder="Select Salesperson"></p-dropdown>
                    </td>
                    <td>
                        <p-dropdown autoWidth="false" [style]="{'width':'100%'}" id="salesPersonType"
                                    formControlName="salespersonType" (onFocus)="setFilteredTypeList()"
                                    [appendTo]="multiDropdownContainer" [options]="filteredSalesPersonTypeList"
                                    placeholder="Select Type"></p-dropdown>
                    </td>
                    <td>
                        <button type="submit" [style]="{'width':'100%'}" pButton icon="fa fa-plus"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <small class="text-danger" *ngIf="tableError">Minimum 1 salesperson required
        </small>
        <small class="text-danger" *ngIf="secondaryError">Can't delete 'Primary' salesperson if 'Secondary' salesperson
            is present.
        </small>
        <small class="text-danger" *ngIf="salespersonError">Please make sure you select a salesperson and its type
        </small>
    </div>
</form>
