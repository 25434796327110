<div class=" reptime-theme">
<p-card class="card">
    <p-header class="card-header search-tool-box has-other-options">
        <div class="flex-1 search-box">
            <rs-advanced-search [advanceOptions]="advanceOptions"
                                [placeholderForSearchBar]="placeholderForSearchBar"
                                 [isResetLoading]="isResetLoading"
                                 [isSubmitLoading]="isSubmitLoading"
                                 [isStatusActive]="false"
                                 (submitEvent)="search($event)"
                                 (resetEvent)="reset($event)" (ready)="initSearchObj($event)"></rs-advanced-search>
        </div>
        <div class="other-options">
            <div class="btn-group" > <!-- *ngIf="auth.getEnableProfileEditing()" -->
                <button class="ui-button ui-button-info" (click)="addContact()" pButton
                        icon="fas fa-plus" label="New"></button>
                <button class="ui-button-danger ui-button" (click)="confirmDelete()" pButton
                        [disabled]=" checkBoxPrimaryTrueOrFalse === false ||  (!selectedContactArray || selectedContactArray.length==0 ||
                        selectedContactArray.length === contacts.length || selectedContactArray[0].isPrimary === true)"
                        label="Delete{{(selectedContactArray  && selectedContactArray.length !== 0) && selectedContactArray.length ? ' (' + selectedContactArray.length + ')' : ''}}"
                        icon="fas fa-minus"></button>
                <button class="ui-button-success ui-button retailer-acc-success" (click)="contactTable.exportCSV({selectionOnly:true})" type="button"
                        pButton
                        icon="fas fa-download" iconPos="left"
                        label="Export{{selectedContactArray && selectedContactArray.length ?
                   ' (' + selectedContactArray.length + ')' : ' All'}}"
                        [disabled]="!selectedContactArray || selectedContactArray.length==0"></button>
            </div>
        </div>
    </p-header>
    <p-table [autoLayout]="true" [columns]="contactCols" [(selection)]="selectedContactArray" [loading]="loading"
             #contactTable exportFilename="Contacts" [scrollable]="true" scrollHeight="300px" (selectionChange)="changeTableCheckbox($event)"
             [responsive]="false" (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event, contactTable.rows)"
             [value]="contacts" dataKey="recordID"
             id="contacts-table" sortField="name" [styleClass]="'header-grey'">

        <ng-template let-columns pTemplate="header">
            <tr>
                <th style="width:45px;min-width: 45px;" class="reptimesticky-possition reptimesticky-possition-left-0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" [style.width]="col.width">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                </th>
                <th style="width:90px;" class="reptimesticky-possition reptimesticky-possition-right-0" ></th> <!-- *ngIf="auth.getEnableProfileEditing()" -->
            </tr>
        </ng-template>

        <ng-template let-columns="columns" let-rowData pTemplate="body">
            <tr [pSelectableRow]="rowData">
                <td class="center reptimesticky-possition reptimesticky-possition-left-0" style="width:45px;min-width: 45px;">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of columns" [style.width]="col.width">
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'firstName'">{{rowData[col.field]}}
                            <span style="color: darkorange;" *ngIf="rowData['isPrimary'] === true">
                                <i pTooltip="This is primary contact" tooltipPosition="right" tooltipEvent="hover"
                                   class="fas fa-star"></i></span>
                        </div>
                        <div *ngSwitchCase="'phone'">{{rowData[col.field] | phone}}</div>
                        <div *ngSwitchCase="'cell'">{{rowData[col.field] | phone}}</div>
                        <div *ngSwitchCase="'email'">
                            <a href="mailto:{{rowData[col.field]}}" class="word-break-all res-word-break">{{rowData[col.field]}}</a>
                        </div>
                        <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                    </div>
                </td>
                <td class="icon-cell reptimesticky-possition reptimesticky-possition-right-0" style="width:90px;" > <!-- *ngIf="auth.getEnableProfileEditing()" -->
                    <div class="d-flex action-btns" >
                        <a (click)="editContact($event,rowData, 'NEW');" pTooltip="Edit" tooltipPosition="top" title="Edit">
                            <i class="fas fa-pencil-alt"></i>
                        </a>
                        <!--  -->
                        <a (click)="removeContact($event,rowData);" class="ml-10 button-danger"
                        *ngIf="rowData['isPrimary'] === false" >
                            <i class="fas fa-trash-alt"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr class="table-empty">
                <td [attr.colspan]="contactCols && contactCols.length + 3" class="text-center">
                    {{loading ? 'Loading contacts' : 'You have no contacts yet. Use the "New" button to add one.'  }}
                    <!-- 'You have no contacts yet.' + (isEditable ? 'Use the "New" button to add one.' : '') -->
               
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="displayContactsDialog">
        <!-- <app-edit-contact-dialog [(isOpen)]="displayContactsDialog"
                                 [retailerID]="retailerID"
                                 [repGroupID]="repGroupID"
                                 (saveEvent)="saveSelectedContact()"
                                 [selectedContact]="selectedContact">
        </app-edit-contact-dialog> -->
    </div>
</p-card>
<p-confirmDialog key="ContactDialog" [appendTo]="'body'" [baseZIndex]="10000"></p-confirmDialog>

</div>