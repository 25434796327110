import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  public retailerSelectedAddress: any = {};

  baseUrl = environment.apiUrl;
  getIntegrationServicesURL = this.baseUrl + 'reptime/api/getIntegrationServices';
  mtPayBuyerSaveURL = this.baseUrl + 'reptime/api/mtPay/{companyID}/buyers/save';
  getBuyerDetailsURL = this.baseUrl + 'reptime/api/mtPay/buyers/{buyeyID}/getBuyerDetails';
  updateBuyerDetailsURL = this.baseUrl + 'reptime/api/mtPay/buyers/{buyeyID}/updateBuyerDetails';
  getCompanyIntegrationURL = this.baseUrl + 'reptime/api/IntegrationServices/{companyID}/getCompanyIntegration';
  getQualificationInvitationURL = this.baseUrl + 'reptime/api/mtPay/buyers/{buyeyID}/qualification';
  qualificationApprovalURL = this.baseUrl + 'reptime/api/mtPay/buyers/{buyeyID}/qualificationApproval';
  transactionsURL = this.baseUrl + 'reptime/api/mtPay/{orderID}/transactions';
  transactionsUpdateURL = this.baseUrl + 'reptime/api/mtPay/{transactionID}/transactionsUpdate';
  transactionStatusUpdateURL = this.baseUrl + 'reptime/api/mtPay/{transactionID}/transactionStatus/update';
  getPaymentDetailsURL = this.baseUrl + 'reptime/api/mtPay/buyer/{companyID}/paymentDetails';
  savePaymentCardURL = this.baseUrl + 'reptime/api/mtPay/buyer/{companyID}/savePaymentCard';
  deletePaymentCardURL = this.baseUrl + 'reptime/api/mtPay/buyer/{buyerID}/{paymentID}/deletePaymentCard';
  getBankConnectionWindowURL = this.baseUrl + 'reptime/api/mtPay/buyer/{buyerID}/saveBank';
  transactionFinanceURL = this.baseUrl + 'reptime/api/mtPay/{transactionID}/transactionFinance';
  getShipmentDetailsURL = this.baseUrl + 'reptime/api/orderpayments/{repGroupID}/{orderID}/invoiceShipmentDetails';
  captureTransactionURL = this.baseUrl + 'reptime/api/mtPay/{transactionID}/captureTransaction';
  buyerCreditLimitURL = this.baseUrl + 'reptime/api/mtPay/{buyeyID}/buyerCreditLimit';
  checkIfEmailRegisteredURL = this.baseUrl + 'reptime/api/mtPay/checkIfEmailRegistered';
  deleteBuyerBank = this.baseUrl + 'reptime/api/mtPay/buyer/{buyerID}/{bankPaymentMethodId}/deleteBuyerBank';


  constructor(private httpClient: HttpClient) { }

  addTokens(url: string, params) {
    Object.keys(params).forEach(key => {
      url = url.replace('{' + key + '}', params[key]);
    });
    return url;
  }
  getIntegrationServices() {
    return this.httpClient.get(this.getIntegrationServicesURL);
  }

  getBuyerDetails(companyID: any) {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.get(this.addTokens(this.getBuyerDetailsURL, { buyeyID: this.retailerSelectedAddress.retailerID }));
  }
  removeBuyerBank(bankPaymentMethodId: any) {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.get(this.addTokens(this.deleteBuyerBank, { buyerID: this.retailerSelectedAddress.retailerID, bankPaymentMethodId: bankPaymentMethodId }));
  }

  getPaymentDetails() {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.get(this.addTokens(this.getPaymentDetailsURL, { companyID: this.retailerSelectedAddress.retailerID }));
  }

  transactionStatusUpdate(transactionID: any) {
    return this.httpClient.post<any>(this.addTokens(this.transactionStatusUpdateURL, { transactionID: transactionID }), '');
  }

  getCompanyIntegration(data, companyID: any) {
    const params = new HttpParams()
      .append('intergationService', data);

    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.get(this.addTokens(this.getCompanyIntegrationURL, { companyID: this.retailerSelectedAddress.retailerID }), { params });
  }

  mtPayBuyerSave(companyID: any, payload: any) {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.post<any>(this.addTokens(this.mtPayBuyerSaveURL, { companyID: this.retailerSelectedAddress.retailerID }), payload);
  }

  savePaymentCard(payload: any, saveBuyer) {


    const params = new HttpParams()
      .append('saveBuyer', (saveBuyer) ? saveBuyer : false);

    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.post<any>(this.addTokens(this.savePaymentCardURL, { companyID: this.retailerSelectedAddress.retailerID }), payload, { params });
  }
  deletePaymentCard(buyerID: any, paymentID: any) {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.get(this.addTokens(this.deletePaymentCardURL, { buyerID: this.retailerSelectedAddress.retailerID, paymentID: paymentID }));
  }
  updateBuyerDetails(buyeyID: any, payload: any) {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.put<any>(this.addTokens(this.updateBuyerDetailsURL, { buyeyID: this.retailerSelectedAddress.retailerID }), payload);
  }
  getQualificationInvitation(buyeyID: any) {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.post<any>(this.addTokens(this.getQualificationInvitationURL, { buyeyID: this.retailerSelectedAddress.retailerID }), null);
  }
  getBankConnectionWindow(buyeyID: any) {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.get(this.addTokens(this.getBankConnectionWindowURL, { buyerID: this.retailerSelectedAddress.retailerID }));
  }
  qualificationApproval(buyeyID: any, payload: any) {
    this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
    return this.httpClient.post<any>(this.addTokens(this.qualificationApprovalURL, { buyeyID: this.retailerSelectedAddress.retailerID }), payload);
  }

  async transactions(orderID: any, payload: any) {
    console.log('DSP844441', payload);
    return this.httpClient.post<any>(this.addTokens(this.transactionsURL, { orderID: orderID }), payload).toPromise();
  }

  async transactionsUpdate(transactionID: any, payload: any) {
    console.log('900000', transactionID, payload);
    return this.httpClient.post<any>(this.addTokens(this.transactionsUpdateURL, { transactionID: transactionID }), payload).toPromise();
  }

  transactionFinance(transactionID: any) {
    return this.httpClient.get(this.addTokens(this.transactionFinanceURL, { transactionID: transactionID }));
  }
  getShipmentDetail(repGroupID: any, orderID: any) {
    return this.httpClient.get(this.addTokens(this.getShipmentDetailsURL, { repGroupID: repGroupID, orderID: orderID }));
  }

  captureTransaction(transactionID: any) {
    return this.httpClient.get(this.addTokens(this.captureTransactionURL, { transactionID: transactionID }));
  }
  getBuyerCreditLimit(buyeyID: any) {
    return this.httpClient.get(this.addTokens(this.buyerCreditLimitURL, { buyeyID: buyeyID }));
  }
  checkmtpayemailreg(payload: any) {
    return this.httpClient.post<any>(this.addTokens(this.checkIfEmailRegisteredURL, {}), payload);
  }
}
