import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as objectPath from 'object-path';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root'
})
export class DataService {
    baseUrl = environment.apiUrl;

  
    private getDefaultZipCodesURL = this.baseUrl + 'reptime/defaultzipcodes/search';
  
    constructor(private http: HttpClient) {
    }

    public async setCountriesState() {
        const countries = ['US'];
        const countriesJSON = {};
        if (!_.includes(countries, environment.countryAbrv.toUpperCase())) {
            countries.push(environment.countryAbrv.toUpperCase());
        }
        const x = await Promise.all(countries.map(async (res: any) => {
            countriesJSON[res] = await this.getCountryStateList(res.toLowerCase()).then((res2) => {
                // console.log('??????????????????success', res2);
                return res2;
            }).catch((err) => {
                // console.log('??????????????????err', err);
                return [];
            });
            return await countriesJSON;
        }));
        // console.log('??????????????????Final', countriesJSON);
        return await Promise.resolve(countriesJSON);
    }

    async getCountryStateList(country: any): Promise<any> {
        return await this.http.get('assets/data/' + country + '-states.json').toPromise();
    }

    getCountryList() {
        return this.http.get('assets/data/countries.json').pipe(map(res => res));
    }

    getStateList() {
        return this.http.get('assets/data/us-states.json').pipe(map(res => res));
    }
    // RC-588 (SG 25 MAR 2020): add service for defaultzipcode 
    getZipCodeList(offset?: any, size?: any, sort?: any, sortOrder?: any, searchParams?: any, showAll?: any) {
        const searchTypeParam = objectPath.get(searchParams, 'searchType', 'contains');
        size = !size ? 25 : size;
        offset = !offset ? 0 : offset;
        sort = !sort ? 'retailer.name' : sort;
        sortOrder = !sortOrder ? 1 : sortOrder;
        let params;
        if (!showAll) {
            params = new HttpParams()
                .append('offset', offset)
                .append('size', size)
                .append('sort', sort)
                .append('searchType', searchTypeParam)
                .append('sortOrder', String(sortOrder));
        } else {
            params = new HttpParams().append('showAll', 'true');
        }
        return this.http.post(this.getDefaultZipCodesURL, searchParams, {params: params});
    }
}
