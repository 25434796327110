import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { RTSharedService } from '../../rtshared/rtshared.service';
// [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase
import { AuthService } from '../../middleware/auth/auth.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { UrlToolsService } from '../../url-tools.service';
@Injectable()
export class ItemsAPIService {

    // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase
    constructor(
        private http: HttpClient,
        public auth: AuthService,
        public urlTools: UrlToolsService,
        private rtSharedService: RTSharedService,
        private dom: DomSanitizer) {
    }

    getItems(payload: any, queryStringParam: any) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/items/all`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.post<any>(url, payload);
    }
    getItemDetails(itemsID: any, queryStringParam?: any) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/items/${itemsID}`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.get<any>(url);
    }

    // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase
    getb2baccessItems(payload: any, queryStringParam: any) {
        payload.retailerID = this.rtSharedService.getRetailerID();
        payload.allowParentItemShopping = this.auth.getParentItemShoppingFlag();
        payload.filterByTerritory = this.auth.getRetailerTerritoryFilterFlag();
        if (JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID) {
            payload.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
            payload.shipToLocationPricingPolicyID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        } else {
            payload.retailerShipToLocationID = null;
        }
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/items/b2baccess/all`;
        const params: any = new URLSearchParams();
        params.set('filterByTerritory', this.auth.getRetailerTerritoryFilterFlag());
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
        }
        url = url + '?' + params.toString();
        return this.http.post<any>(url, payload);
    }
    getItemsHistory(queryStringParam?: any, retailerID?: any, repGroupID?: any, mfgID?: any, itemNumber?: any, companyID?: any, ISCPayload?: any) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/orders/${mfgID}/${itemNumber}/${repGroupID}`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.post<any>(url, ISCPayload);
    }

    public getFullPicturePath(picturePath: any, mfgId?: any, px?) {
        // [B2B-159] (RM 11-MARCH-2020): Condition added restrict null value.
        if (picturePath) {
            if (px) {
                return this.dom.bypassSecurityTrustResourceUrl(this.getFullPicturePathWithPX(picturePath, px, mfgId) + '?' + new Date().getTime());
            } else {
                // return this.itemService.getFullPicturePath(imagePath, mfgId) + '?' + new Date().getTime();
                return `${this.rtSharedService.getImageUrl()}${mfgId}/${picturePath}` + '?' + new Date().getTime();
            }
        } else {
            return '';
        }
    }

    public getFullPicturePathWithPX(picturePath, px, mfgId?) {
        return this.rtSharedService.getImageUrl() + mfgId + '/' + px + 'x' + px + '-' + picturePath;
    }

    public getFullPictureLandingPathWithPX(picturePath, px, repGroupID?) {
        return environment.marketTimeLandingBucketUrl + repGroupID + '/markethighlights/' + px + 'x' + px + '-' + picturePath;
    }

    public getFullBackgroundImageWithPX(url, picturePath, px?) {

        if (px) {
            return this.dom.bypassSecurityTrustResourceUrl(url + px + 'x' + px + '-' + picturePath + '?' + new Date().getTime());
        } else {
            return url + picturePath + '?' + new Date().getTime();
        }
    }

    public getFullPictureLandingPath(picturePath: any, repGroupID?: any, px?) {
        if (picturePath) {
            if (px) {
                return this.dom.bypassSecurityTrustResourceUrl(this.getFullPictureLandingPathWithPX(picturePath, px, repGroupID) + '?' + new Date().getTime());
            } else {
                return `${environment.marketTimeLandingBucketUrl}${repGroupID}/markethighlights/${picturePath}` + '?' + new Date().getTime();
            }

            // return`${environment.marketTimeLandingBucketUrl}${repGroupID}/markethighlights/${picturePath}`;
        } else {
            return '';
        }
    }

    public getFullLandingSliderPath(picturePath: any, repGroupID?: any) {
        if (picturePath) {
            return `${environment.magazineBucket}${repGroupID}/landingpage-images/${picturePath}`;
        } else {
            return '';
        }
    }

    // [B2B-219] (RM 02-04-2020): Get itemlist api calling.
    public getLinkedItems(payload: any) {
        payload.shipToLocationPricingPolicyID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/items/b2baccess/linkedItems?flagBrandPricingPolicy=true`;
        return this.http.post<any>(url, payload);
    }

    // getShopOtherItems(payload: any, queryStringParam: any) {
    //     let url = `${this.rtSharedService.getApiUrl()}reptime/api/items/b2baccess`;
    //     if (queryStringParam && !_.isEmpty(queryStringParam)) {
    //         const params: any = new URLSearchParams();
    //         for (const key in queryStringParam) {
    //             if (queryStringParam.hasOwnProperty(key)) {
    //                 queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
    //                 params.set(key, queryStringParam[key]);
    //             }
    //         }
    //         url = url + '?' + params.toString();
    //     }
    //     return this.http.post<any>(url, payload);
    // }

    getShopOtherItems(repGroupID: any, itemID: any, mfgID: any, queryStringParam: any) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/items/b2baccess/${repGroupID}/${itemID}/${mfgID}/relatedItems`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.get<any>(url);
    }

    public checkReviewOrRateAvailability(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/rate/review/check`;
        return this.http.post<any>(url, payload);
    }

    public getAvgItemsRating(payload: any): any {
        // const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/item/rate/avg`;
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/item/rate/review/count`;
        return this.http.post<any>(url, payload);
    }

    public getItemsRating(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/item/rate`;
        return this.http.post<any>(url, payload);
    }

    public saveItemsRating(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/item/rate/save`;
        return this.http.post<any>(url, payload);
    }

    public getReview(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/item/review`;
        return this.http.post<any>(url, payload);
    }

    public getAllReviews(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/item/review/all`;
        return this.http.post<any>(url, payload);
    }

    public saveReview(payload: any): any {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/b2b/marketplace/item/review/save`;
        return this.http.post<any>(url, payload);
    }

    get360Images(itemID: any) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/item/${itemID}/item360/getdata`;
        return this.http.get(url);
    }

    getMFGCategory1List(manufacturerID:any) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/${manufacturerID}/manufacturerCategory1List`;
        return this.http.get(url);
    }
    getMFGCategory2List(manufacturerID:any,payload: any) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/${manufacturerID}/manufacturerCategory2List`;
        return this.http.post<any>(url, payload);
    }
    getMFGCategory3List(manufacturerID:any,payload: any) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/${manufacturerID}/manufacturerCategory3List`;
        return this.http.post<any>(url, payload);
    }
}
