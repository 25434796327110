import { Inject, Input, LOCALE_ID } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APIResponse } from '../../models/api-response';
import { ManufacturerService } from '../../services/api/manufacturer.service';
import * as objectPath from 'object-path';
import * as _ from 'lodash';

@Component({
  selector: 'app-promotion-detail-dialog',
  templateUrl: './promotion-detail-dialog.component.html',
  styleUrls: ['./promotion-detail-dialog.component.scss']
})
export class PromotionDetailDialogComponent implements OnInit {

  @Input() promotionsGridDialog: any;
  @Input() manufacturerID: any;
  @Input() repGroupID: any;
  promotionsGrid : any;
  promotionsLoader = false;
  private readonly destroyManufacturerPromotions = new Subject<void>();
  @Inject(LOCALE_ID) public locale: string;
  constructor(
    public config: DynamicDialogConfig,
    private manufacturerService: ManufacturerService,
    private router: Router,
    private msgService: MessageService,
  ) { }

  ngOnInit() {
    console.log('this.promotionsGridDialog', this.promotionsGridDialog, this.manufacturerID, this.repGroupID);
    if(!this.promotionsGridDialog) {
      this.promotionsGridDialog = [];
      console.log('loadPromotionsLazy');
      this.loadPromotionsLazy();
    }
  }

  loadPromotionsLazy() {
    this.promotionsLoader = true;
    let size;
    let offset;
    let sort;
    let sortOrder;
    let searchParams = {};
    if (size === undefined) {
      size = 50;
    }
    if (offset === undefined) {
      offset = 0;
    }
    if (sort === undefined) {
      sort = 'name';
    }
    if (sortOrder === undefined) {
      sortOrder = 1;
    }
    if (this.manufacturerID) {
      searchParams['manufacturerIDSet'] = [this.manufacturerID];
    }
    if (this.repGroupID) {
      searchParams['repGroupID'] = this.repGroupID;
    }

    if (this.manufacturerID !== undefined) {
      this.destroyManufacturerPromotions.next();
      this.manufacturerService.getManufacturerPromotions(offset, size, sort, sortOrder, searchParams)
      .pipe(takeUntil(this.destroyManufacturerPromotions)).subscribe((response: APIResponse) => {
          if (response.success) {
              const promotionGrid = _.chain(response.data).map((v, i) => {
                console.log('vvvvvvvvvvvv', v);

                return {
                  manufacturerName: v[0] && v[0].repGroupManufacturerName ? v[0].repGroupManufacturerName : '',
                  manufacturerID: v[0] && v[0].manufacturerID ? v[0].manufacturerID : '',
                  data: v
                };
              }).value();
              this.promotionsGridDialog = promotionGrid && promotionGrid.length ? promotionGrid[0] : [];
              this.promotionsLoader = false;
              console.log('123', this.promotionsGridDialog);
          } else {
            // tslint:disable-next-line: max-line-length
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response, 'error.message', 'Error occurred during services request') });
            this.promotionsLoader = false;
          }
        }, (error) => {
          // tslint:disable-next-line: max-line-length
          this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
          this.promotionsLoader = false;
        });
    }
  }

}
