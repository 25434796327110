import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef, MessageService} from 'primeng/api';
import {OrderAPIService} from '../../services/api/order-api/order-api.service';
import {DatePipe} from '@angular/common';
import * as objectPath from 'object-path';
import { RTSharedService } from '../../services/rtshared/rtshared.service';

@Component({
    selector: 'rs-order-success-dialog',
    templateUrl: './order-success-dialog.component.html',
    styleUrls: ['./order-success-dialog.component.scss']
})
export class OrderSuccessDialogComponent implements OnInit {
    orderList: any;
    orderArr: any;
    cols: any[];
    // B2B-110 (NL 29-FEB-2020) get repgroup name
    win: any = (window);
    public domainData: any = this.win.domainData;
    thanksMessage: any;

    constructor(public config: DynamicDialogConfig,
                public ref: DynamicDialogRef,
                public dp: DatePipe,
                private msgService: MessageService,
                public rtSharedService: RTSharedService,
                private orderApi: OrderAPIService) {
    }

    ngOnInit() {
        if(this.domainData && this.domainData.repGroupThemeSettings && this.domainData.repGroupThemeSettings.lastPhraseThanksMessage) {
            this.thanksMessage = this.domainData.repGroupThemeSettings.lastPhraseThanksMessage;
        } else {
            this.thanksMessage = 'your orders will be sent to your assigned salesperson';
        }

        this.orderArr = this.config.data;
        this.orderList = this.orderArr.map((obj: any) => {
            console.log('objobj', obj);
            return {
                manufacturer: obj.repGroupManufacturerName,
                poNumber: obj.poNumber,
                lineItmes:  obj.orderDetails.filter((e) => e.recordDeleted === 0).length,
                total: obj.orderTotal,
                recordID: obj.recordID
            };
        });
        console.log('this.orderArr', this.orderList);
        this.cols = [
            {field: 'manufacturer', header: 'Manufacturer'},
            {field: 'poNumber', header: 'PO'},
            {field: 'lineItmes', header: '# of Items'},
            {field: 'total', header: 'Order Total '}
        ];

        this.orderApi.b2bOrderSendEmail(this.orderArr.map(o => o.recordID)).then((orderObj) => {
            }, (error) => {
                console.log(error);
                // (RK 08-OCT-2020): Make an error handling when getting the error from  save order API
                this.msgService.clear();
                this.msgService.add({ severity: 'error', summary: 'Error',
                    detail: objectPath.get(error, 'error.message', 'Error occurred during email services request') });
                // resolve(false);
            });
    }

    removeInvalidChars(str) {
        // Remove characters that are invalid for file names
        return str.replace(/([^a-zA-Z0-9\-_]+)/gi, '');
    }

    downloadFile(data, order, mimeType, ext) {
        const repGroupName = order['repGroup'] && order['repGroup']['name'] ? order['repGroup']['name'] : 'NoRepGroupName';
        const poNumber = order['poNumber'] ? order['poNumber'] : 'NoPoNumber';
        const orderDate = order['orderDate'] ? this.dp.transform(order['orderDate'], 'MM-dd-yyyy') : 'NoOrderDate';
        const filename = repGroupName + '_' + poNumber + '_' + orderDate;
        console.log('filename parts', repGroupName, poNumber, orderDate);
        const url = window.URL.createObjectURL(new Blob([data], {type: mimeType}));
        console.log('Download URL:', url);

        // Directly download the file by adding a temporary link with blob file data
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.removeInvalidChars(filename) + ext;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        console.log('Should be downloading now.');
    }

    previewOrder(orderObj) {
        const order = this.orderArr.find(o => o.recordID === orderObj.recordID);
        console.groupCollapsed('Downloading PDF :', order);
        this.orderApi.downloadOrder(order.recordID, 'pdf')
            .then(data => {
                console.groupEnd();
                this.downloadFile(data, order, 'application/pdf', '.pdf');
                // Update order status(es) in batch group if Manufacturer opens order
            }, () => {
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'An error occurred downloading order ' + order.recordID
                });
            });
    }

    close() {
        this.ref.close();
    }

}
