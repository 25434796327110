import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RTSharedService } from '../../rtshared/rtshared.service';
import { Order } from '../../../models/order';
import { AuthService } from '../../middleware/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class OrderAPIService {
    baseUrl: string;

    constructor(
        private http: HttpClient,
        public auth: AuthService,
        private rtSharedService: RTSharedService
    ) {
        this.baseUrl = this.rtSharedService.getApiUrl();
    }

    async getRetailerOrders(queryStringParam: any, payload: any): Promise<any> {
        let url = `${environment.apiUrl}reptime/api/orders/all`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return await this.http.post<any>(url, payload).toPromise();
    }

    saveOrder(selectedOrder: Order, mtPayPaymentDeclined?: any) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        let url = `${this.baseUrl}reptime/api/orders/${repGroupID}/order/save`;
        if (mtPayPaymentDeclined) {
            const params: any = new URLSearchParams();
            params.set('sendMfgNotification', true);
            url = url + '?' + params.toString();
        }
        return this.http.post<any>(url, selectedOrder);
    }

    getOrders(payload: any) {
        payload.repGroupID = this.rtSharedService.getRepGroupID();
        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
            payload.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        }
        const url = `${this.baseUrl}reptime/api/orders`;
        return new Promise((resolve, reject) => {
            this.http.post<any>(url, payload)
                .subscribe((res) => {
                    resolve(res);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    updateRepGroupOrderStatus(order: Order) {
        const url = `${this.baseUrl}reptime/api/repgroup/order/status`;
        return this.http.post<any>(url, order).toPromise();
    }

    getTerritoryStatus(order: Order, companyID: string) {
        const url = `${this.baseUrl}reptime/api/salesperson/order/territorystatus/${companyID}`;
        return this.http.post<any>(url, order).toPromise();
    }

    sendEmail(orderID) {
        const url = `${this.baseUrl}reptime/orders/${orderID}/email`;
        return this.http.get<any>(url).toPromise();
    }

    saveOrderDetail(orderID: number, detailObj: any, manufacturerID: string) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const url = `${this.baseUrl}reptime/api/orders/${repGroupID}/${orderID}/detail/save?manufacturerID=${manufacturerID}`;
        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
            detailObj.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        }
        return this.http.post<any>(url, detailObj).toPromise();
    }

    savePaymentToken(params, token) {
        let url = `${this.baseUrl}reptime/orders/${params.repGroupID}/${params.orderID}/savePaymentInfo?saveCard=${params.saveRetailerCard}`;
        if (params.retailerPaymentID) {
            url = `${url}&paymentID=${params.retailerPaymentID}`;
        }
        return this.http.post<any>(url, token, { observe: 'response' }).toPromise();
    }

    emailToMFG(orderID) {
        const url = `${this.baseUrl}reptime/api/orders/${orderID}/retransmit/email`;
        return this.http.get<any>(url).toPromise();
    }

    downloadOrder(orderId, fileType) {
        const url = `${environment.apiUrl}reptime/api/manufacturer/order/${fileType}/${orderId}?companyType=${this.rtSharedService.getEntityTypeStrfi()}`; // pdf, edi
        return this.http.get(url, { responseType: 'blob' as 'blob' }).toPromise();
    }

    downloadPrintOrder(orderId, fileType, sortOrderOption?, withimage?) {
        console.log('withimage', withimage);
        const sortOption = sortOrderOption && sortOrderOption.sort ? sortOrderOption.sort : 'sequenceID';
        const sortOrder = sortOrderOption && sortOrderOption.order ? sortOrderOption.order : 'DESC';
        const url = `${this.baseUrl}reptime/api/manufacturer/order/${fileType}/${orderId}?sortOption=${sortOption}&sortOrder=${sortOrder}&itemImagesOnOrderCopy=${withimage}&companyType=${this.rtSharedService.getEntityTypeStrfi()}`; // pdf, edi
        return this.http.get(url, { responseType: 'blob' as 'blob' }).toPromise();
    }

    emailOrder(orderID: number, fileType: string, email: string) {
        const httpOptions: any = {
            responseType: 'arraybuffer'
        };
        const repGroupID = this.rtSharedService.getRepGroupID();
        const url = `${this.baseUrl}reptime/api/orders/${repGroupID}/${orderID}/send`;
        return this.http.post<any>(url, { fileType, email }, httpOptions);
    }

    b2bOrderSendEmail(orderIds) {
        console.log('b2bOrderSendEmail', orderIds);
        const url = `${this.baseUrl}reptime/b2baccess/orders/email`;
        return this.http.post<any>(url, orderIds).toPromise();
    }

    transmitOrder(orderID) {
        const payload = {
            orderIDs: [orderID],
            type: 'manufacturer',
            event: 'transmit'
        };
        const url = `${this.baseUrl}reptime/api/order/transmit`;
        return this.http.post<any>(url, payload).toPromise();
    }

    // [B2B-83] [B2B-105] (RM 25-FEB-2020): get suggested product list
    getRetailerSuggestions(payload: any) {
        let retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        payload.shipToLocationPricingPolicyID = retailerShipToLocationID.recordID;
        const url = `${this.baseUrl}reptime/api/items/b2baccess/suggestions?flagBrandPricingPolicy=true`;
        return this.http.post<any>(url, payload).toPromise();
    }

    public setOrderChangesHistoryObj(order, orderChangesHistory, page, isFromOpenLink = false) {
        console.log('setOrderChangesHistoryObj', order);
        return {
            userEntityID: (isFromOpenLink) ? null : this.auth.getCompanyID(),
            userEntityType: (isFromOpenLink) ? null : this.auth.getEntityTypeStr(),
            userID: (isFromOpenLink) ? null : this.auth.getUserID(),
            userRole: (isFromOpenLink) ? null : this.auth.getUserRoleType(),
            recordID: (isFromOpenLink) ? null : order.recordID,
            publicOrderID: (isFromOpenLink) ? null : order.publicOrderID,
            origin: 'B2B',
            page,
            updatedDateTimeUTC: new Date(),
            updatedOrderFields: JSON.stringify(orderChangesHistory)
        };
    }
}
