import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntegrationService } from '../../services/api/integration.service';
import { AuthService } from '../../services/middleware/auth/auth.service';
import * as objectPath from 'object-path';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-retailer-onboarding',
  templateUrl: './retailer-onboarding.component.html',
  styleUrls: ['./retailer-onboarding.component.scss']
})
export class RetailerOnboardingComponent implements OnInit, OnChanges, OnDestroy {
  stepItems = [];
  @Input() activeIndex = 0;
  @Input() fromPage;
  dndCheckbox = false;
  registerFormDisable = false;
  @Input() getIntegrationData: any;
  @Input() getUserIntegrationData: any;
  @Input() selectedRetailer: any;
  @Input() getBuyerDetailsData: any;

  savedGetIntegrationData: any;
  savedGetUserIntegrationData: any;
  savedgetBuyerDetailsData: any;
  @Output() skipDialog = new EventEmitter<any>();
  @Output() loadBackgroundServices = new EventEmitter<any>();
  status = '';
  einNumber: any;
  businessName: any;
  applyForNetTermsLoader = false;
  showQualificationBankDialog = false;
  private readonly onDestroyQualificationApproval = new Subject<void>();
  private readonly onDestroyGetQualificationLink = new Subject<void>();
  iframeSrc: any;
  private readonly onDestroygetBuyerDetails = new Subject<void>();
  constructor(public msgService: MessageService, private rtSharedService: RTSharedService, private integrationService: IntegrationService, public authService: AuthService, private router: Router, private sanitizer: DomSanitizer) { }


  ngOnInit() {
    this.stepItems = [
      { label: 'Information' },
      { label: 'Registration' },
      { label: 'Apply for Net Terms' },
      { label: 'Success' }
    ];
  }
  ngOnDestroy() {
    this.onDestroyQualificationApproval.next();
    this.onDestroyGetQualificationLink.next();
    this.onDestroygetBuyerDetails.next();
  }

  ngOnChanges(changes: any) {
    // this.savedGetIntegrationData = this.getIntegrationData;
    // this.savedGetUserIntegrationData = this.getUserIntegrationData;
    // this.savedgetBuyerDetailsData = this.getBuyerDetailsData;
    if (changes && changes.getIntegrationData && changes.getIntegrationData.currentValue && changes.getIntegrationData.currentValue) {
      console.log('Rahul....123', changes);
      this.savedGetIntegrationData = this.getmtPayIntegration(changes.getIntegrationData.currentValue);
    }
    this.einNumber = (this.getBuyerDetailsData && this.getBuyerDetailsData.ein) ? this.getBuyerDetailsData.ein : '';
    this.businessName = (this.getBuyerDetailsData && this.getBuyerDetailsData.businessName) ? this.getBuyerDetailsData.businessName : this.businessName;
    this.status = (this.getBuyerDetailsData && this.getBuyerDetailsData.qualificationStatus) ? this.getBuyerDetailsData.qualificationStatus : '';
    console.log('Rahul....1', changes);
    console.log('Rahul....2', this.getIntegrationData);
    console.log('Rahul....3', this.getUserIntegrationData);
    console.log('Rahul....4', this.getBuyerDetailsData);
    console.log('Rahul....5', this.status, this.activeIndex);
    if (this.fromPage !== 'finalize-order') {
      if (_.isEmpty(this.getUserIntegrationData)) {
        this.activeIndex = 0;
      } else if ((this.getUserIntegrationData && !this.getUserIntegrationData.isApproved) || this.status !== 'completed') {
        this.activeIndex = 2;
      } else if (this.getUserIntegrationData && this.getUserIntegrationData.isApproved && this.status === 'completed') {
        this.msgService.clear();
        this.msgService.add({ severity: 'success', summary: 'Success', detail: 'You are already registered with mtPay Service.' });
        this.skipDialog.emit(true);
      }
    }
  }
  retailerOnBoardingOnChange($event) {
  }
  skipAction($event) {
    if ($event === 0) { // Skip dialog
    } else if ($event === 1) {// Close dialog
    }
    this.skipDialog.emit(true);
  }
  registerAction() {
    this.activeIndex = 1;
  }
  skipToStep($event) {
    if ($event === 1) {
      this.activeIndex = 0;
    }
    if ($event === 2) {
      this.activeIndex = 2;
    }
  }
  saveRetailerData($event) {
    console.log('DSP72222', $event );
    this.einNumber = ($event && $event.ein) ? $event.ein  :   this.getBuyerDetailsData.ein;
    this.status = ($event && $event.qualificationStatus) ? $event.qualificationStatus : '';
    this.businessName = ($event && $event.businessName) ? $event.businessName : this.getBuyerDetailsData.businessName;
    this.activeIndex = 2;
  }
  cancelRequest() {
    this.skipDialog.emit(true);
  }

  sendRequest(isEINWithBankQualification?: any) {
    console.log('DSP82222', this.einNumber);
    if (!this.einNumber) {
      this.msgService.clear();
      this.msgService.add({ severity: 'error', summary: 'Need more information',
      detail: 'Your federal tax ID number is not available for this account. We require all customers to have a valid tax ID to shop our brands. Please enter this information in the Manage Company section in your menu listing.' });
      return;
    }
    if (!isEINWithBankQualification) {
      const requestPayload = {
        ein: (this.getBuyerDetailsData && this.getBuyerDetailsData.ein) ? this.getBuyerDetailsData.ein : this.einNumber,
        businessName: (this.getBuyerDetailsData && this.getBuyerDetailsData.businessName) ? this.getBuyerDetailsData.businessName : this.businessName
      };
      console.log('DSP866', requestPayload);
      this.applyForNetTermsLoader = true;
      this.onDestroyQualificationApproval.next();
      this.integrationService.qualificationApproval(this.authService.getCompanyID(),
      requestPayload).pipe(takeUntil(this.onDestroyQualificationApproval)).subscribe((response: any) => {
        // this.applyForNetTermsLoader = false;
        if (response && response.success) {
          this.status = JSON.parse(response.data).status;
          this.msgService.clear();
          this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Qualification Approval Send Successfully' });
          // this.activeIndex = 3;
          this.getBuyerDetails();
          this.loadBackgroundServices.emit(true);
        } else {
            this.applyForNetTermsLoader = false;
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response, 'error.message', 'Error occurred during services request') });
        }
      }, (error) => {
        this.applyForNetTermsLoader = false;
        this.msgService.clear();
        this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
      });
    } else {
      this.applyForNetTermsLoader = true;
      this.onDestroyGetQualificationLink.next();
      this.integrationService.getQualificationInvitation(this.rtSharedService.getRetailerID()).pipe(takeUntil(this.onDestroyGetQualificationLink)).subscribe((response: any) => {
          console.log('????', response);
          this.applyForNetTermsLoader = false;
          if (response && response.success) {
            this.showQualificationBankDialog = true;
            this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response.data);
          } else {
            this.iframeSrc = null;
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response, 'error.message', 'Error occurred during services request') });
          }
        }, (error) => {
          this.iframeSrc = null;
          this.applyForNetTermsLoader = false;
          this.msgService.clear();
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });
    }
  }

  backBtn() {
    this.activeIndex = this.activeIndex - 1;
    this.registerFormDisable = true;
  }
  onHideQualificationBankDialog($event) {
    // this.activeIndex = 3;
    this.loadBackgroundServices.emit(true);
    this.getBuyerDetails();
  }
  getBuyerDetails() {
    this.onDestroygetBuyerDetails.next();
    this.applyForNetTermsLoader = true;
    this.integrationService.getBuyerDetails(this.authService.getCompanyID()).pipe(takeUntil(this.onDestroygetBuyerDetails)).subscribe((response: any) => {
        this.applyForNetTermsLoader = false;
        if (response && response.success) {
            this.getBuyerDetailsData = response.data;
            this.status = (this.getBuyerDetailsData && this.getBuyerDetailsData.qualificationStatus) ? this.getBuyerDetailsData.qualificationStatus : '';
            if (this.status !== '' && this.status !== 'pending') {
              this.activeIndex = 3;
            }
        } else {
          this.msgService.clear();
          this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response, 'error.message', 'Error occurred during services request') });
      }
    }, (error) => {
      this.applyForNetTermsLoader = false;
      this.msgService.clear();
      this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    });
}
  goToManageCompany() {
    sessionStorage.setItem('openMtPayFromDD', 'true');
    this.router.navigateByUrl('/manage-company');
  }
  getmtPayIntegration(obj: any) {
    if (obj) {
        const findmtPayIntegrtaion = _.find(obj, o => (o && o.serviceName.toLowerCase() === 'mtpay'));
        return (findmtPayIntegrtaion) ? findmtPayIntegrtaion : null;
    } else {
        return null;
    }
  }
}
