<!-- // B2B-53 (MG 04-FEB-2020): modified to save filtered documents -->
<p-toast position="top-right"></p-toast>
<div class="page reptime-theme wid-catalogs-main resp-promo py-20">
    <p-card class="card repgroup-main-tbl-block">
        <p-header class="card-header search-tool-box ui-g">

            <div class="ui-md-6 ui-sm-12 ui-xl-6">
                <h1 class="theme-grayclr" style="margin-bottom: 0px;font-size: 20px;"> Catalogs</h1>
            </div>
            <div class="ui-md-6 ui-sm-12 ui-xl-6 resp-pro-search">                
                <div class="btn-group float-right">
                    <!-- <div class="ui-selectbutton catalog-dropdown-block mr-10" *ngIf="catalogManufacture && catalogManufacture.length">
                        <p-dropdown [options]="catalogManufacture" (onChange)="search($event)" filter="true" class="callout-mfrlist">                
                            <ng-template let-car pTemplate="item">
                                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">                                
                                    <div style="font-size:14px;margin-top:4px">{{car.label}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div> -->
                </div>
            </div>

        </p-header>

        <p-dataView [value]="catalogManufacture" [loading]="loadingTableData" emptyMessage="{{loadingTableData ?
            'Loading documents' :
            'You have no catalogs list yet.'}}"
            layout="grid" class="promotion-datview">

            <ng-template pTemplate="paginatorright">
                <div class="per-page-text">Per page :</div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <div class="per-page-text px-10">Total :</div>
               {{catalogManufacture ? catalogManufacture.length-1 : 0}}
            </ng-template>

            <ng-template let-vendor pTemplate="gridItem">
                <div class="grid-item ui-g-12 ui-md-2" (click)="viewcatalog(vendor)" *ngIf="vendor.value">
                    <div style="padding:10px;">                        
                        <div class="catalog-img-block">
                            <!-- RC-698 (MJ 28-MARCH-2020) onerror added for default image -->
                            <img alt="vendor logo" src="{{vendor['fullPicturePath']}}" onerror="this.onerror=null;this.src='assets/images/no-image-available.png';">
                        </div>
                        <div class="mb-5" style="color:#585958;">
                            <b class="catalogs-nametxt" pTooltip="Manufacturers" tooltipPosition="top">
                                {{vendor['label']}}
                            </b>
                        </div>
                        <div class="text-center d-flex flex-column mb-2" style="font-size: 13px;color:#585958;">
                            <!-- RC-698 (MJ 28-MARCH-2020) if no minimum order amount then display 0 -->
                            <span class="mb-5">Minimum Order {{(vendor['minimumOrderAmount'] ? vendor['minimumOrderAmount'] : 0 ) | rtCurrency}} </span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="table-empty">
                </tr>
            </ng-template>
        </p-dataView>
    </p-card>
</div>