import {Injectable} from '@angular/core';
import {RTSharedService} from '../../rtshared/rtshared.service';

import {OrderAPIService} from '../../api/order-api/order-api.service';
import * as _ from 'lodash';
import {OrderPaymentToken} from '../../../models/order-payment-token';
import {RetailerPaymentInfo} from '../../../models/retailer-payment-info';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { UrlToolsService } from '../../url-tools.service';
import { environment } from '../../../../environments/environment';
import { MessageService } from 'primeng/api';
import * as objectPath from 'object-path';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    private addOrderItemSub = new Subject<any>();
    private addBtnDisableSub = new Subject<any>();
    private addBtnDisableArr: any = {};
    win: any = (window);
    public domainData: any = this.win.domainData;
    baseUrl = environment.apiUrl;
    orderDuplicateURL = this.baseUrl + 'reptime/api/orders/duplicate/{companyID}/{orderID}';

    constructor(
        private orderAPIService: OrderAPIService,
        private rtSharedService: RTSharedService,
        private httpClient: HttpClient,
        private urlTools: UrlToolsService,
        private msgService: MessageService
    ) { }

    setAddOrderItemSub(item: any) {
        this.addOrderItemSub.next(item);
    }

    getAddOrderItemSub(): Observable<any> {
        return this.addOrderItemSub.asObservable();
    }

    setAddBtnDisable(id) {
        this.addBtnDisableArr[id] = true;
        this.addBtnDisableSub.next(this.addBtnDisableArr);
    }

    removeAddBtnDisable(id) {
        delete this.addBtnDisableArr[id];
        this.addBtnDisableSub.next(this.addBtnDisableArr);
    }

    getAddBtnDisableSub(): Observable<any> {
        return this.addBtnDisableSub.asObservable();
    }

    async saveOrderData(selectedOrder: any, mtPayPaymentDeclined?: any) {
        if (selectedOrder.retailer && selectedOrder.retailer.recordID) {
            selectedOrder.retailer = {
                recordID: selectedOrder.retailer.recordID
            };
        }
        if (selectedOrder.retailerShipTo && selectedOrder.retailerShipTo.recordID) {
            selectedOrder.retailerShipTo = {
                recordID: selectedOrder.retailerShipTo.recordID
            };
        }
        if (selectedOrder.manufacturer && selectedOrder.manufacturer.recordID) {
            selectedOrder.manufacturer = {
                recordID: selectedOrder.manufacturer.recordID
            };
        }
        if (selectedOrder.orderWriter && selectedOrder.orderWriter.recordID) {
            selectedOrder.orderWriter = {
                recordID: selectedOrder.orderWriter.recordID
            };
        }
        if (selectedOrder.retailerContact && selectedOrder.retailerContact.recordID) {
            selectedOrder.retailerContact = {
                recordID: selectedOrder.retailerContact.recordID
            };
        }
        selectedOrder.repGroup = {
            recordID: selectedOrder.repGroup.recordID
        };
        return new Promise(async (resolve, reject) => {
            this.orderAPIService.saveOrder(selectedOrder, mtPayPaymentDeclined).subscribe((orderObj) => {
                resolve(orderObj);
                }, (error) => {
                    console.log(error);
                    // (RK 08-OCT-2020): Make an error handling when getting the error from  save order API
                    // this.msgService.clear();
                    this.msgService.add({ severity: 'error', summary: 'Error',
                    detail: objectPath.get(error, 'error.message', 'Error occurred during services request') });
                    resolve({dataErrorStatus: true});
                });
        });
    }

    async getOrdersData(payload: any) {
        return await this.orderAPIService.getOrders(payload)
            .then((res: any) => {
                return Promise.resolve(res.data);
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    async changeRepGroupOrderStatus(payload: any) {
        try {
            await this.orderAPIService.updateRepGroupOrderStatus(payload);
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async getTerritoryStatus(payload: any, companyID: string) {
        return await this.orderAPIService.getTerritoryStatus(payload, companyID)
        .then((res: any) => {
            return Promise.resolve(res);
        })
        .catch((e) => {
            return Promise.reject(e);
        });
    }

    async sendEmailToManufacturer(orderID: string) {
        try {
            const res = await this.orderAPIService.sendEmail(orderID);
            return Promise.resolve(res.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async saveOrderPaymentToken(repGroupID: string, orderID: number, token: OrderPaymentToken,
         saveCardToRetailer: boolean, retailerPaymentInfo?: RetailerPaymentInfo) {
        try {
            const params = {
                repGroupID,
                orderID,
                saveRetailerCard: saveCardToRetailer,
                retailerPaymentID: ''
            };
            if (retailerPaymentInfo && retailerPaymentInfo.recordID) {
                params.retailerPaymentID = '' + retailerPaymentInfo.recordID;
            }
            console.log('params', params);
            const res = await this.orderAPIService.savePaymentToken(params, token);
            console.log('res', res);
            return Promise.resolve(res.status);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async updateOrderDetail(orderID: number, detailObj: any, manufacturerID: string) {
        try {
            const res: any = await this.orderAPIService.saveOrderDetail(orderID, detailObj, manufacturerID);
            return Promise.resolve(res);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async downloadOrder(id, fileType) {
        try {
            const res: any = await this.orderAPIService.downloadOrder(id, fileType);
            console.log('res', res);
            return Promise.resolve(res);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    // downloadOrder(orderId, fileType) {
    //     return this.orderAPIService.downloadOrder(orderId, fileType);
    // }

    async orderReTransmit(orderID) {
        console.log('orderID', orderID);
        try {
            const res: any = await this.orderAPIService.emailToMFG(orderID);
            return Promise.resolve(res);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async getRetailerOrdersData(queryStringParam: any, payload: any) {
        return this.orderAPIService.getRetailerOrders(queryStringParam, payload);
    }

    // [B2B-83] [B2B-105] (RM 25-FEB-2020): get suggested product list
    async getRetailerSuggestionsData(payload: any) {
        return this.orderAPIService.getRetailerSuggestions(payload);
    }

    saveOrderDuplicate(orderID: number, searchParams) {
        return this.httpClient.post(this.urlTools.addTokens(this.orderDuplicateURL, {companyID: this.domainData.repGroup.recordID ,orderID: orderID}), searchParams);
    }

    public setOrderChangesHistoryObj(order, orderChangesHistory, page) {
        return this.orderAPIService.setOrderChangesHistoryObj(order, orderChangesHistory, page);
    }

    orderTrackingAPI (repGroupID, orderID) {
        const url = `${environment.apiUrl}reptime/api/orderpayments/${repGroupID}/${orderID}/invoiceShipmentDetails`;
        return this.httpClient.get(url);
    }
}
