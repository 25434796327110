import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../../services/other/data/data.service';
import {HtmlToolsService} from '../../../services/html-tools/html-tools.service';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
@Component({
    selector: 'rs-retailer-shipping',
    templateUrl: './retailer-shipping.component.html',
    styleUrls: ['./retailer-shipping.component.scss']
})

export class RetailerShippingComponent implements OnInit, OnChanges, OnDestroy {

    @Input() isNewCustomerWizard = false;
    @Output() previousStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() selectedRetailer: any;
    @Input() retailerShipTo: any;
    @Input() isRetailerCreated: boolean;
    editRetailerShippingForm: FormGroup;
    countries: any[];
    states: any[];
    copyFromBillTo: boolean;
    countriesStateJSON: any;
    private readonly getCountryList = new Subject<void>();
    private readonly getStateList = new Subject<void>();
    constructor(private fb: FormBuilder, private dataService: DataService, private htmlTools: HtmlToolsService) {
        this.initForm();
        this.copyFromBillTo = false;
    }

    get validateChk() {
        // Convenience getter for easy access to form fields
        return this.editRetailerShippingForm.controls;
    }

    ngOnChanges(changes: any) {
        if (changes && changes.retailerShipTo && !_.isEmpty(changes.retailerShipTo.currentValue)) {
            this.retailerShipTo = changes.retailerShipTo.currentValue;
            this.setFormFromRetailerShipping();
        }
        if (changes && changes.isRetailerCreated && changes.isRetailerCreated.currentValue) {
            this.isRetailerCreated = changes.isRetailerCreated.currentValue;
            if (this.isRetailerCreated) {
                this.editRetailerShippingForm.disable();
            }
        }
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.getCountryList.next();
        this.getStateList.next();
    }
    initForm() {
        this.editRetailerShippingForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
            address1: ['', Validators.required],
            address2: [''],
            zip: ['', [Validators.required, Validators.maxLength(20)]],
            country: [environment.countryAbrv, Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            phone: ['', Validators.pattern('^[0-9]*$')],
            phoneExtension: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
            fax: ['', Validators.pattern('^[0-9]*$')],
            faxExtension: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
            website: ['', [Validators.pattern('^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$')]]

        });
        this.getCountryList.next();
        this.getStateList.next();
        this.dataService.getCountryList().pipe(takeUntil(this.getCountryList)).subscribe((data: any[]) => {
            this.countries = data;
        });
        // this.dataService.getStateList().pipe(takeUntil(this.getStateList)).subscribe((data: any[]) => {
        //     this.states = data;
        // });
        this.dataService.setCountriesState().then(res => {
            this.countriesStateJSON = res;
        });
    }

    setFormFromRetailerShipping() {
        this.editRetailerShippingForm.reset();
        this.editRetailerShippingForm.patchValue(this.retailerShipTo);
        if (!this.editRetailerShippingForm.get('country').value) {
            this.editRetailerShippingForm.patchValue({country: environment.countryAbrv});
        }
        this.countrySpecific();
    }

    countrySpecific() {
        if (this.validateChk.country.value === 'US') {
            this.editRetailerShippingForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
            this.editRetailerShippingForm.get('zip').updateValueAndValidity();
        } else {
            this.editRetailerShippingForm.get('state').setValue(objectPath.get(this.editRetailerShippingForm.get('state').value, ''));
            this.editRetailerShippingForm.get('zip').clearValidators();
            this.editRetailerShippingForm.get('zip').updateValueAndValidity();
        }
    }

    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.pattern && field.errors.pattern.requiredPattern === '^[0-9]*$') {
            return 'Enter numeric value';
        } else if (field.errors.pattern && field.errors.pattern.requiredPattern
            === '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$') {
            return 'Enter website url e.g. http://www.eg.com or https://www.eg.com';
        } else if (field.errors.pattern) {
            return 'Enter valid value';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
        if (field.errors.maxlength) {
            return 'Maximum length exceed for ' + name;
        }
    }

    toggleCopyFromBillTo($event) {
        this.copyFromBillTo = <boolean>$event;
        if (this.copyFromBillTo) {
            this.editRetailerShippingForm.patchValue(this.selectedRetailer);
            if (!this.selectedRetailer.country) {
                this.editRetailerShippingForm.patchValue({country: environment.countryAbrv});
            }
        } else {
            this.setFormFromRetailerShipping();
        }

    }

    saveRetailerShippingInfo() {
        if (this.editRetailerShippingForm.valid || this.isRetailerCreated) {
            this.setRetailerShippingFromForm();
            this.nextStepEvent.next({retailerShipTo: this.editRetailerShippingForm.value});
        } else {
            this.htmlTools.validateAllFields(this.editRetailerShippingForm);
        }
    }

    private setRetailerShippingFromForm() {
        _.forEach(this.editRetailerShippingForm.controls, (key, value) => {
            this.retailerShipTo[value] = this.editRetailerShippingForm.controls[value].value;
        });
    }

}
