import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../../middleware/auth/auth.service';
import { AuthGuardRouterSetupService } from '../auth-guard-router-setup/auth-guard-router-setup.service';

@Injectable({
    providedIn: 'root'
})
export class NotAuthGuardService implements CanActivate {

    constructor(private _router: Router, private _auth: AuthService,
        private _AuthGuardRouterSetupService: AuthGuardRouterSetupService

    ) {
    }

    /**
     * check Activate.
     * @param {object} route
     * @param {object} state
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url: string = state.url;
        // return this.fnCheckAuthenticate(url);
        return this._AuthGuardRouterSetupService.fnCheckdomain(route, url).then((res) => {
            return res === true ? this.fnCheckAuthenticate(url) : false;
        });
    }

    /**
     * check Authenticate.
     * @param {string} url
     */
    private fnCheckAuthenticate(url: string): boolean {
        const domainData = (window as any).domainData;

        if (!this._auth.getIDToken() || !domainData) {
            console.log('aaaaaaaaa');
            return true;
        } else {
            this._router.navigate(['/home']);
            return false;
        }
    }
}
