import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';

import { RetailerAPIService } from '../../../services/api/retailer-api/retailer-api.service';
import { RetailerShipToLocation } from '../../../models/retailer-ship-to-location';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'rs-retailer-ship-to-location-table',
    templateUrl: './retailer-ship-to-location-table.component.html',
    styleUrls: ['./retailer-ship-to-location-table.component.scss']
})
export class RetailerShipToLocationTableComponent implements OnInit, OnDestroy {

    @Input() retailerID: string;
    @Input() isEditable: boolean;
    @Input() selectedShipTo: any;
    shipToCols: any[];
    shipToLocations: Array<RetailerShipToLocation> = [];
    loading: boolean;
    private readonly onDestroy1 = new Subject<void>();
    constructor(private confirmationService: ConfirmationService,
        private msgService: MessageService,
        private retailerAPIService: RetailerAPIService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public rtSharedService: RTSharedService,
    ) {
    }

    ngOnDestroy() {
        this.onDestroy1.next();
    }
    ngOnInit() {
        this.retailerID = this.config.data.retailerID;
        // this.selectedShipTo = this.config.data.selectedShipTo;
        this.shipToCols = [
            { field: 'name', header: 'Name', width: '150px', spinner: true },
            { field: 'address1', header: 'Address', width: '200px', spinner: true },
            { field: 'city', header: 'City', width: '150px', spinner: true },
            { field: 'state', header: 'State', width: '150px', spinner: true },
            { field: 'zip', header: 'Zip', width: '150px', spinner: true },
            { field: 'phone', header: 'Phone', width: '150px', spinner: true }
        ];
        // B2B-307 (MK 07-08-2020) Improvement: changes list for finalize screen
        if (this.config.data.selectedShipTo) {
            this.selectedShipTo = this.config.data.selectedShipTo;
        }
        this.loadShipToLocations(this.config.data.selectedShipTo);
    }

    loadShipToLocations(ShipTo) {
        this.loading = true;
        this.onDestroy1.next();
        this.retailerAPIService.getRetailerShipToByRetailerID(this.retailerID)
        .pipe(takeUntil(this.onDestroy1)).subscribe((data: any) => {
                console.log('533333', data.data);
                this.shipToLocations = data.data;
                const sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
                const x = this.shipToLocations.find(o => +o.recordID === +sessionLocationId.recordID);
                // B2B-307 (MK 07-08-2020) Improvement: changes list for finalize screen
                if (!ShipTo) {
                    this.selectedShipTo = x;
                }
                this.loading = false;
            });
    }

    selectShipTo(shipTo: any) {
        console.log('select', shipTo);
        this.ref.close(shipTo);
    }
}
