import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';

@Injectable()
export class HtmlToolsService {

    constructor() {
    }

    validateAllFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFields(control);
            } else if (control instanceof FormArray) {
                control.controls.forEach((frmGrp: FormGroup) => {
                    this.validateAllFields(frmGrp);
                });
            }
        });
    }
}
