import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';

@Component({
    selector: 'rs-promotions-table',
    templateUrl: './promotions-table.component.html',
    styleUrls: ['./promotions-table.component.scss']
})
export class PromotionsTableComponent implements OnInit {


    @Input() promotions: any[];
    cols: any[];
    rows: 25;
    totalRecords: number;
    @Inject(LOCALE_ID) public locale: string;

    constructor() {
    }

    // (MG-06-MAR-2020) modified to show valid promotion total
    ngOnInit() {
        this.cols = [
            { field: 'name', header: 'Name', width: '150px', spinner: true },
            { field: 'description', header: 'Description', width: '150px', spinner: true },
            { field: 'startDate', header: 'Start Date', width: '120px', spinner: true },
            { field: 'endDate', header: 'End Date', width: '120px', spinner: true },
            { field: 'amountToMeet', header: 'Amt To Meet', width: '130px', spinner: true },
            { field: 'discount', header: 'Discount', width: '110px'},
            { field: 'active', header: 'Active', width: '90px' }
        ];
        // this.totalRecords = this.promotions.length;
        this.totalRecords = 0;
        this.promotions.forEach(element => {
            const status = this.getActive(element) ? this.totalRecords++ : '';    
        });
    }

    // B2B-52 (MG 31-JAN-2020): modified to filter MFR's promotions
    getActive(rowData) {
        const time = new Date().getTime();
        return  new Date(rowData.startDate).getTime() <= time &&
            new Date(rowData.endDate).getTime() >= time && rowData.showOnWebsite === true;
    }
}

