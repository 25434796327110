<form autocomplete="off" (submit)="saveRetailerContactInfo()" [formGroup]="editRetailerContactForm" autocomplete="off">
    <div id="form-errors"></div>
    <div class="p-grid p-5">
        <div class="p-col-4"><label for="firstName">First Name*</label>
            <div class="input-error-container">
                <input class="control" formControlName="firstName"
                       id="firstName" autocomplete="new-password"
                       pInputText size="3" maxlength="50"
                       type="text"/>
                <i *ngIf="validateChk.firstName.invalid && (validateChk.firstName.dirty || validateChk.firstName.touched)"
                   class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.firstName,'First Name')"
                   tooltipPosition="left"
                   tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-4"><label for="middleName">Middle Name</label> <br/>
            <div class="input-error-container">
                <input class="control" formControlName="middleName"
                       id="middleName" autocomplete="off" maxlength="50"
                       pInputText size="3"
                       type="text"/>
                <i *ngIf="validateChk.middleName.invalid && (validateChk.middleName.dirty || validateChk.middleName.touched)"
                   class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.middleName,'Middle Name')"
                   tooltipPosition="left"
                   tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-4"><label for="lastName">Last Name</label> <br/>
            <input class="control" formControlName="lastName" autocomplete="off"
                   id="lastName" maxlength="50"
                   pInputText size="3"
                   type="text"/>
        </div>
        <div class="p-col-6"><label for="cell">Phone</label>
            <div class="input-error-container">
                <!-- <p-inputMask [unmask]="true" formControlName="cell" styleClass="control"
                             id="cell" [style]="{'width':'100%','border':'1px solid #628292'}"
                             mask="(999) 999-9999" autocomplete="new-password"
                ></p-inputMask> -->
                <p-inputMask [unmask]="true" formControlName="cell" styleClass="control" minlength="10" maxlength="12" id="cell" mask="999-999-9999?99" autocomplete="off"></p-inputMask>
                <i *ngIf="validateChk.cell.invalid && (validateChk.cell.dirty || validateChk.cell.touched)"
                   class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.cell,'Phone')"
                   tooltipPosition="left"
                   tooltipEvent="hover"></i>
            </div>
        </div>
        <div class="p-col-6"><label for="email">Email*</label>
            <div class="input-error-container">
                <input class="control" formControlName="email" maxlength="100" autocomplete="new-password"
                       id="email" pInputText type="text"/>
                <i *ngIf="validateChk.email.invalid && (validateChk.email.dirty || validateChk.email.touched)"
                   class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.email,'Email')"
                   tooltipPosition="left" tooltipEvent="hover"></i>
            </div>
        </div>
    </div>
    <ng-content></ng-content>
</form>
