import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import * as jwt_decode from 'jwt-decode';
import * as _ from 'lodash';

import { AuthAPIService } from '../../api/auth-api/auth-api.service';
import { Login } from '../../../models/login';
import { RTSharedService } from '../../rtshared/rtshared.service';
import { Constant } from '../../../constant';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { APIResponse } from 'src/app/models/api-response';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private static readonly COOKIE_REFRESH_TOKEN = 'refreshToken';
    private static readonly COOKIE_AUTH_TOKEN = 'AUTH_TOKEN';
    private static readonly COOKIE_ACCESS_TOKEN = 'accessToken';
    private cartItemCountEvent = new Subject<any>();
    public getAuthRetailer: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public getRetailerData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public RepGroupBrandsList: any = [];
    public RepGroupCategoryGroupsList: any = [];
    public RepGroupshimmer = [false, false];
    public readonly onDestroy = new Subject<void>();
    entityType: any;

    constructor(
        private cookieService: CookieService,
        private router: Router,
        private rtSharedService: RTSharedService,
        private route: ActivatedRoute,
        private authAPI: AuthAPIService
    ) {
        this.setRTSharedService();
        this.setAuthRetailer();
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe(() => {
            this.entityType = route.root.firstChild.snapshot.data.entityType;
          });
    }

    public getProjectType() {
        // 0 for domain no valid , 1 for old b2baccess view , 2 new b2b access theme
        const dm = (<any>window).domainData;
        return dm ? (dm.themeStyle ? (dm.themeStyle === 'old' ? 1 : 2) : 1) : 0;
    }

    public moveToAfterLogin() {
        if (this.getProjectType() === 2) {
            this.router.navigate(['/']);
        } else {
            this.router.navigate(['/login']);
        }
    }

    public setRepGroupBrandsList(data) {
        this.RepGroupBrandsList = data;
    }

    getRepGroupBrandsList() {
        return this.RepGroupBrandsList;
    }

    public setRepGroupCategoryGroupsList(data) {
        this.RepGroupCategoryGroupsList = data;
    }

    getRepGroupCategoryGroupsList() {
        return this.RepGroupCategoryGroupsList;
    }

    public checkthisEntityType(){
        if(this.entityType === 'signup' || this.entityType === 'login' || this.entityType === 'forgot-username' || this.entityType === 'forgot-password'){
            return false;
        } else {
            return true;
        }
    }

    setRTSharedService() {
        let userInfo: any = this.getRetailer(Constant.RETAILER_PARENT);
        if (this.getRetailer(Constant.RETAILER_CHILD)) {
            userInfo = this.getRetailer(Constant.RETAILER_CHILD);
        }
        const domainData: any = (window as any).domainData;
        this.route.queryParams.subscribe(params => {
            sessionStorage.setItem('abandoned_carts', JSON.stringify(params));
        });
        if (userInfo) {
            if (userInfo.entityType) {
                this.rtSharedService.setEntityName(userInfo.entityType);
            }
            if (userInfo.userId) {
                this.rtSharedService.setUserID(userInfo.userId);
            }
            if (domainData && domainData.repGroup) {
                switch (userInfo.entityType) {
                    case 'Retailer':
                        if (userInfo.entityId) {
                            this.rtSharedService.setRetailerID(userInfo.entityId);
                        }
                        this.rtSharedService.setRepGroupID(domainData.repGroup.recordID);
                        break;
                }
            }
        }
    }

    async localLogin(loginData: Login) {
        try {
            const base64AuthCode = btoa(unescape(encodeURIComponent(loginData.username + ':' + loginData.password)));
            const res = await this.authAPI.localSignIn(base64AuthCode);
            this.setToken(res.body);
            return res;
        } catch (err) {
            console.error(`AuthService > localSignIn : ${err.message}`);
            return err;
        }
    }

    setDataToLocal(res, username) {
        sessionStorage.setItem('username', username);
        sessionStorage.setItem('logindetail', JSON.stringify(res.body.userInfos[0]));
        sessionStorage.setItem('idToken', JSON.stringify(res.body.idToken));
        const storeCompanyID = [];
        _.forEach(res.body.userInfos, (o) => {
            storeCompanyID.push(o.entityId);
        });
        return storeCompanyID;
    }

    setToken(body: any) {
        if (body) {
            this.setRefreshToken(body.refreshToken);
            this.setAccessToken(body.accessToken);
            this.setAuthToken(body);
            this.setAuthRetailer();
            this.setRTSharedService();
        }
    }

    getRefreshToken() {
        return this.cookieService.get(AuthService.COOKIE_REFRESH_TOKEN);
    }

    setRefreshToken(token: any) {
        if (token) {
            this.cookieService.put(AuthService.COOKIE_REFRESH_TOKEN, token);
        }
    }

    // [B2B-262] (SG 18-JUN-2020) Set & Get access token into cookies
    setAccessToken(token: any) {
        if (token) {
            this.cookieService.put(AuthService.COOKIE_ACCESS_TOKEN, token);
        }
    }

    getAccessToken() {
        return this.cookieService.get(AuthService.COOKIE_ACCESS_TOKEN);
    }
    // End

    getIDToken() {
        return this.getLoginInfo().idToken;
    }

    getLoginInfo(): any {
        let body: any = this.getAuthToken();
        if (body) {
            body = JSON.parse(atob(body));
        } else {
            body = { idToken: null, userInfos: [] };
        }
        return body;
    }

    getAuthToken() {
        return this.cookieService.get(AuthService.COOKIE_AUTH_TOKEN);
    }

    setAuthToken(body: any) {
        this.cookieService.put(AuthService.COOKIE_AUTH_TOKEN, btoa(unescape(encodeURIComponent(JSON.stringify(this.getFilteredBodyData(body))))));
    }

    getFilteredBodyData(body: any) {
        if (body && _.isObject(body) && !_.isEmpty(body)) {
            const tokenInfo: any = jwt_decode(body.idToken);
            delete body.accessToken;
            delete body.refreshToken;
            if (body.userInfos && body.userInfos.length) {
                body.userInfos = _.filter(body.userInfos, (o) => {
                    o.retailerType = o.userId == tokenInfo.userid ? Constant.RETAILER_PARENT : Constant.RETAILER_CHILD;
                    return o.entityType === Constant.RETAILER;
                });
            }
        }
        return body;
    }

    getRetailer(retailerType: any) {
        if (this.getLoginInfo().userInfos && this.getLoginInfo().userInfos.length) {
            const getRetailerArr = _.filter(this.getLoginInfo().userInfos, (o) => o.retailerType === retailerType);
            if (getRetailerArr && getRetailerArr.length) {
                return getRetailerArr[0];
            }
        }
        return null;
    }

    setAuthRetailer() {
        const parent: any = this.getRetailer(Constant.RETAILER_PARENT);
        const child: any = this.getRetailer(Constant.RETAILER_CHILD);
        this.getAuthRetailer.next({
            parent,
            child,
            loggedInRetailerID: child ? (child && child.entityId) : (parent && parent.entityId)
        });
    }

    removeAllToken() {
        this.rtSharedService._selectedShipTo = null;
        // this.rtSharedService.setSelectedShipTo(undefined);
        this.cookieService.remove(AuthService.COOKIE_REFRESH_TOKEN);
        this.cookieService.remove(AuthService.COOKIE_AUTH_TOKEN);
        this.cookieService.remove(AuthService.COOKIE_ACCESS_TOKEN);
        sessionStorage.removeItem('retailerShipToLocationID');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('idToken');
        sessionStorage.removeItem('logindetail');
        sessionStorage.removeItem('federalTaxNumbersession');
        sessionStorage.removeItem('allLoginCompanyID');
        this.getAuthRetailer.next(null);
    }

    getLoginInfoLocal() {
        return sessionStorage.getItem('logindetail');
    }

    public getCompanyID(): string {
        const primaryCompany = this.getLoginInfoLocal();
        return (primaryCompany !== null && primaryCompany !== undefined) ? JSON.parse(primaryCompany).entityId : null;
    }

    getEntityTypeStr(): string {
        const primaryCompany = this.getLoginInfoLocal();
        return (primaryCompany !== null && primaryCompany !== undefined) ? JSON.parse(primaryCompany).entityType : null;
    }

    getUserID(): number {
        const primaryCompany = this.getLoginInfoLocal();
        return (primaryCompany !== null && primaryCompany !== undefined) ? JSON.parse(primaryCompany).userId : null;
    }

    getUserRoleType() {
        const primaryCompany = this.getLoginInfoLocal();
        return (primaryCompany !== null && primaryCompany !== undefined) ? JSON.parse(primaryCompany).role : null;
    }

    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    getSiteTheme() {
        const theme = sessionStorage.getItem('B2Btheme');
        if (theme !== null && theme !== undefined) {
            const themeObj = JSON.parse(theme);
            return (themeObj !== undefined) ? themeObj : null;
        } else {
            return null;
        }
    }

    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    getColorCodes() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.primaryColor && themeObj.secondaryColor && themeObj.textColor) ? themeObj : null : null;
    }

    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    getCartStyle() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.fixedCart) ? themeObj.fixedCart : 0 : 0;
    }

    // [B2B-92] (MG 26-FEB-2020) modified to warn on min order
    getIsMinOrderRequired() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.isMinOrderRequired !== undefined) ? themeObj.isMinOrderRequired : true : true;
    }

    // [B2B-225] (RK 03-APRIL-2020) get daysLastModified
    getDaysLastModified() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.daysLastModified) ? themeObj.daysLastModified : 15 : 15;
    }

    getbestSallerData() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.isBestSeller) ? themeObj.isBestSeller : 1 : 0;
    }

    // [Urgent Basis] (RK 04-APRIL-2020) get showNewArrivals data
    getShowNewArrivals() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.showNewArrivals !== undefined) ? themeObj.showNewArrivals : false : false;
    }

    getShowBestSeller() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.enableBestSellersInB2b !== undefined) ? themeObj.enableBestSellersInB2b : false : false;
    }

    // [B2B-73] (RK 28-FEB-2020) modified to make cartItemCount
    setCartItemCount(count) {
        sessionStorage.setItem('cartItemCount', count);
        this.cartItemCountEvent.next(count);
    }

    // [B2B-73] (RK 28-FEB-2020) modified to make cartItemCount
    getCartItemCount() {
        const count = sessionStorage.getItem('cartItemCount');
        return count !== null && count !== undefined ? count : null;
    }

    getCartItemCountEvent(): Observable<any> {
        return this.cartItemCountEvent.asObservable();
    }

    // [B2B-652] (MG 03-MAR-2020) modified to make style dynamic
    getFontStyle() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.siteFontFamily) ? themeObj.siteFontFamily : 'Roboto' : 'Roboto';
    }

    // [B2B-153] (RK 07-MAR-2020) SET subscribe in this API data `${environment.apiUrl}reptime/api/retailer/${retailerID}`;
    setRetailerData(data) {
        this.getRetailerData.next(data);
    }

    // [B2B-227] (MG 03-APR-2020) modified to make custom button dynamic
    getdefaultPromotionButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.useDefaultPromotions !== undefined) ? themeObj.useDefaultPromotions : true : true;
    }

    // [B2B-227] (MG 03-APR-2020) modified to make custom button dynamic
    getCustomPromotionButtonData() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.customPromotions !== undefined) ? themeObj.customPromotions : {} : {};
    }

    // [B2B-227] (MG 03-APR-2020) modified to make custom button dynamic
    getdefaultCatalogButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.useDefaultCatalogs !== undefined) ? themeObj.useDefaultCatalogs : true : true;
    }

    // [B2B-227] (MG 03-APR-2020) modified to make custom button dynamic
    getCustomCatalogButtonData() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.customCatalogs !== undefined) ? themeObj.customCatalogs : {} : {};
    }

    // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase
    getParentItemShoppingFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.allowParentItemShopping !== undefined) ? themeObj.allowParentItemShopping : true : true;
    }

    // [B2B-198] (MG-13-APR-2020): modified to save default order code
    getDefaultOrderCode() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.defaultOrderCode !== undefined) ? themeObj.defaultOrderCode : 'W' : 'W';
    }
    // [RC-1965] login/signup button hide/show start
    getdefaultLoginButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.showLogin !== undefined) ? themeObj.showLogin : true : true;
    }

    getEnableProfileEditing() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.enableProfileEditing  !== undefined) ? themeObj.enableProfileEditing : true : true;
    }

    getdefaultSignupButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.showSignup !== undefined) ? themeObj.showSignup : true : true;
    }

    getCustomSignupButtonData() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.b2bSignupUrl !== undefined) ? themeObj.b2bSignupUrl : {} : {};
    }
    // [RC-1965] login/signup button hide/show end
    // [RC-2347] Login Dashboard dynamic widget data [start]
    getLoginDashboardWidgetSwap() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.loginWidgetSwap !== undefined) ? themeObj.loginWidgetSwap : false : false
    }
    getLoginDashboardCustomSignupWidget() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.signUpWidget !== undefined) ? themeObj.signUpWidget : {} : {};
    }
    // [RC-2347] Login Dashboard dynamic widget data [end]
    // [B2B-241] (MG 14-MAY-2020) modified to make custom shop url
    getShopURL() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.shopNow !== undefined) ? themeObj.shopNow : 'shop' : 'shop';
    }

    getCategoryGroupType() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.categoryGroupType !== undefined) ? themeObj.categoryGroupType : 'category' : 'category';
    }

    getRetailerTerritoryFilterFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.filterByRetailerTerritory !== undefined) ? themeObj.filterByRetailerTerritory : false : false;
    }

    // [B2B-270] [SG 30-06-2020] modified to make custom button dynamic
    getdefaultShopbyBrandsButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.useDefaultBrand !== undefined || themeObj.useDefaultBrand !== null) ? themeObj.useDefaultBrand : true : true;
    }

    // [B2B-270] [SG 30-06-2020] modified to make custom button dynamic
    getCustomShopbyBrandsButtonData() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.customShopByBrand !== undefined) ? themeObj.customShopByBrand : {} : {};
    }

    // [B2B-270] [SG 30-06-2020] modified to make custom button dynamic
    getdefaultShopbyItemButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.useDefaultItem !== undefined || themeObj.customShopByItem !== null) ? themeObj.useDefaultItem : true : true;
    }

    // [B2B-270] [SG 30-06-2020] modified to make custom button dynamic
    getCustomShopbyItemButtonData() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.customShopByItem !== undefined) ? themeObj.customShopByItem : {} : {};
    }

    getMagazineSlider() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.magazine !== undefined && themeObj.magazine !== null && themeObj.magazine.hasOwnProperty('magazineSlider')) ?
            themeObj.magazine.magazineSlider : null : null;
    }

    getMagazineImageOne() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.magazine !== undefined && themeObj.magazine !== null && themeObj.magazine.hasOwnProperty('magazineImageSectioneOne')) ?
            themeObj.magazine.magazineImageSectioneOne : null : null;
    }

    getMagazineImageTwo() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.magazine !== undefined && themeObj.magazine !== null && themeObj.magazine.hasOwnProperty('magazineImageSectionTwo')) ?
            themeObj.magazine.magazineImageSectionTwo : null : null;
    }

    getMarketTimeEventsSettings() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.marketTimeEventsSettings && themeObj.marketTimeEventsSettings !== undefined) ? themeObj.marketTimeEventsSettings : null : null;
    }

    getComingSoonLandingSettings() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.comingSoonLandingPage && themeObj.comingSoonLandingPage !== undefined) ? themeObj.comingSoonLandingPage : null : null;
    }

    getTrendingDataSettings() {
        const themeMarketTimeObj = this.getMarketTimeEventsSettings();
        return (themeMarketTimeObj !== null) ? (themeMarketTimeObj.whatsTrending && themeMarketTimeObj.whatsTrending !== undefined) ? themeMarketTimeObj.whatsTrending : null : null;
    }

    getHighlightDataSettings() {
        const themeMarketTimeObj = this.getMarketTimeEventsSettings();
        return (themeMarketTimeObj !== null) ? (themeMarketTimeObj.marketHighlights && themeMarketTimeObj.marketHighlights !== undefined) ? themeMarketTimeObj.marketHighlights : null : null;
    }

    getRepGroupPublicAccessTokenSettings() {
        const themeMarketTimeObj = this.getMarketTimeEventsSettings();
        return (themeMarketTimeObj !== null) ? (themeMarketTimeObj.repGroupPublicAccessToken && themeMarketTimeObj.repGroupPublicAccessToken !== undefined) ? themeMarketTimeObj.repGroupPublicAccessToken : null : null;

    }

    getmarketTimeEvents() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.headerSlider !== undefined) ? themeObj.headerSlider : null : null;
    }

    getMagazineComingUp() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.magazine !== undefined && themeObj.magazine !== null && themeObj.magazine.hasOwnProperty('comingUpImages')) ?
            themeObj.magazine.comingUpImages : null : null;
    }

    getSocialPosts() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.magazine !== undefined && themeObj.magazine !== null && themeObj.magazine.hasOwnProperty('socialPost')) ?
            themeObj.magazine.socialPost : null : null;
    }

    getMagazineImages() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.magazine !== undefined && themeObj.magazine !== null && themeObj.magazine.hasOwnProperty('magazineImages')) ?
            themeObj.magazine.magazineImages : null : null;
    }

    getTrendingNowText() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.magazine.trendingNowText !== undefined) ? themeObj.magazine.trendingNowText : null : null;
    }

    getNeedProofOfBusinessDocs() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.needProofOfBusinessDocs !== undefined) ? themeObj.needProofOfBusinessDocs : false : false;
    }

    getCategoryViewAllRedirectionLink() {
        const themeObj = this.getSiteTheme();
        const link = (themeObj !== null) ? (themeObj.categoryViewAllRedirectionLink !== undefined) ? themeObj.categoryViewAllRedirectionLink : 'allCategories' : 'allCategories';
        return (link === 'allCategories') ? '/category' : '/shop';
    }

    getSignupFormHeaderText() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.signupFormHeaderText !== undefined) ? themeObj.signupFormHeaderText : '' : '';
    }

    callAPILogs(isFromLogout = false) {
        const token = sessionStorage.getItem('idToken');
        if (token != null) {
            let _log = JSON.parse(sessionStorage.getItem('log')) ? JSON.parse(sessionStorage.getItem('log')) : [];
            if (_log && _log.length) {
                this.onDestroy.next();
                this.authAPI.setCallLogs(_log).pipe(takeUntil(this.onDestroy)).subscribe((res: APIResponse) => {
                    if (isFromLogout) {
                        // sessionStorage.clear();
                        this.removeAllToken();
                    }
                    const _apiResponse = _.cloneDeep(res.data);
                    _log = JSON.parse(sessionStorage.getItem('log')) ? JSON.parse(sessionStorage.getItem('log')) : [];
                    _.forEach(_apiResponse, (o) => {
                        const getIndexFromStorage = _.findIndex(_log, { index: o.index });
                        if (getIndexFromStorage !== -1) {
                            _log.splice(getIndexFromStorage, 1);
                            sessionStorage.setItem('log', JSON.stringify(_log));
                        }
                    });

                }, (error) => {
                    // this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
                });
            }
        }
    }

    getItemDetailPage() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.showItemDetailPage !== undefined) ? themeObj.showItemDetailPage : false : false;
    }

    getOtherManufacturerItems() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.otherManufacturerItems !== undefined) ? themeObj.otherManufacturerItems : false : false;
    }

    getColorSelectionSettings() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.colorSelectionSettings && themeObj.colorSelectionSettings !== undefined) ? themeObj.colorSelectionSettings : null : null;
    }

    getrepgroupData(): any {
        const repgroup = sessionStorage.getItem('repgroupObj');
        if (repgroup !== null && repgroup !== undefined) {
            const repgroupObj = JSON.parse(repgroup);
            return (repgroupObj !== undefined) ? repgroupObj : null;
        } else {
            return null;
        }
    }

    getRepGroupPublicAccessToken(): any {
        const repgroupMarketTimeObj = this.getrepgroupData();
        // tslint:disable-next-line:max-line-length
        return (repgroupMarketTimeObj !== null) ? (repgroupMarketTimeObj.publicAccessToken && repgroupMarketTimeObj.publicAccessToken !== undefined) ? repgroupMarketTimeObj.publicAccessToken : null : null;
    }

    getEINNumber() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.einNumberRequired !== undefined) ? themeObj.einNumberRequired : true : true;
    }
    getEINPlace() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.einPlace !== undefined) ? themeObj.einPlace : null : null;
    }
    getshowPromotionsButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.showPromotions !== undefined) ? themeObj.showPromotions : true : true;
    }

    getshowDiscountTickerButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.showDiscountTicker !== undefined) ? themeObj.showDiscountTicker : true : true;
    }

    getBrowseAllButtonFlag() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.hideBrowseAllButton !== undefined) ? themeObj.hideBrowseAllButton : false : false;
    }

    getShowWidgetsFlag() {
        const themeObj = this.getSiteTheme();
        const showWidgets = (themeObj !== null) ? (themeObj.extraWidget !== undefined) ? themeObj.extraWidget : 'showSalesperson' : 'showSalesperson';
        return (showWidgets === 'showOrderDetails') ? true : false;
    }

    getTickerText() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.tickerText !== undefined) ? themeObj.tickerText : null : null;
    }

    getshowManufacturerItemCategories() {
        const themeObj = this.getSiteTheme();
        return (themeObj !== null) ? (themeObj.showManufacturerItemCategories !== undefined) ? themeObj.showManufacturerItemCategories : false : false;
    }

    checkUndefinedNullEmpty(value) {
        if (value !== undefined && value !== null && value !== '') {
            return true;
        } else {
            return false;
        }
    }

    checkUndefinedNull(value) {
        if (value !== undefined && value !== null) {
            return true;
        } else {
            return false;
        }
    }
}
