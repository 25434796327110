<div class="reptime-theme">
    <p-card class="card">
        <p-header class="card-header search-tool-box">
            <div class="flex-grow-1 search-box">
                <rs-advanced-search [advanceOptions]="advanceOptions"
                                    [isResetLoading]="isResetLoading"
                                    [isSubmitLoading]="isSubmitLoading"
                                    [isStatusActive]="false"
                                    (submitEvent)="search($event)"
                                    (resetEvent)="reset($event)" (ready)="initSearchObj($event)"></rs-advanced-search>
            </div>
            <div class="flex-1 search-actions">
                <div class="btn-group float-right">
                    <button class="ui-button-info m-5" *ngIf="createRetailer"
                            icon="fa fa-plus" iconPos="left"
                            label="New Customer"
                            title="New Customer"
                            (click)="createRetailer(true)"
                            pButton type="button"></button>
                    <rs-salesperson-repgroup-dropdown
                        *ngIf="entityType === 'Salesperson'"
                        [salespersonID]="salespersonID"
                        (repGroupSelect)="getSelectedRepGroup($event)">
                    </rs-salesperson-repgroup-dropdown>
                </div>
            </div>
        </p-header>
        <p-table #dt (onLazyLoad)="loadCustomersLazy($event, searchForm)" (onPage)="updateOffset($event);"
                 [autoLayout]="true" [columns]="cols"
                 [lazy]="true" [loading]="loading" [responsive]="true"
                 (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" [totalRecords]="totalRecords"
                 [value]="retailers" [(selection)]="selectedRetailerArray"
                 dataKey="recordID" exportFilename="customers" sortField="retailer.name" [paginator]="true"
                 [rowsPerPageOptions]="[20, 40, 60]" [rows]="rows" *ngIf="repGroupID">

            <ng-template pTemplate="paginatorright">
                <div class="per-page-text">Per page:</div>
            </ng-template>
            <ng-template let-columns pTemplate="header">
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" [width]="col.width">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                    </th>
                    <!--<th style="width:35px;">Action</th>-->
                </tr>
            </ng-template>
            <ng-template let-columns="columns" let-rowData pTemplate="body">
                <tr [pSelectableRow]="rowData" (click)="selectRetailer(rowData)"
                    [style.background]="selectedRetailer && (selectedRetailer.recordID === rowData.recordID) ? '#fad961': ''">
                    <td *ngFor="let col of columns">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchCase="'email'"><a
                                href="mailto:{{rowData[col.field]}}">{{rowData[col.field]}}</a>
                            </div>
                            <div *ngSwitchCase="'recordID'">{{rowData[col.field]}}</div>
                            <div *ngSwitchCase="'active'">
                                <input checked="{{rowData[col.field]===1?'checked':''}}" disabled="disabled"
                                       type="checkbox">
                            </div>
                            <div *ngSwitchCase="'approved'">
                                <input checked="{{rowData[col.field]===1?'checked':''}}" disabled="disabled"
                                       type="checkbox">
                            </div>
                            <div *ngSwitchCase="'phone'">{{rowData[col.field] | phone}}</div>
                            <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                        </div>
                    </td>
                    <!--<td class="icon-cell">-->
                    <!--<div class="d-flex action-btns">-->
                    <!--<a class="mx-5" [routerLink]="['/repgroups/customer/view/', rowData.recordID]"-->
                    <!--title="View" pTooltip="View" tooltipPosition="top">-->
                    <!--<i class="fa fa-eye"></i>-->
                    <!--</a>-->
                    <!--<a class="mx-5" [routerLink]="['/repgroups/customer/edit/', rowData.recordID]" title="Edit"-->
                    <!--pTooltip="Edit" tooltipPosition="top">-->
                    <!--<i class="fa fa-pencil"></i>-->
                    <!--</a>-->
                    <!--</div>-->
                    <!--</td>-->
                </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <div class="per-page-text px-10">Total :</div>
                {{totalRecords}}
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="!loading" class="table-empty">
                    <td [attr.colspan]="cols.length + 3" class="text-center">You have no customers yet. Click 'New' to
                        add one.
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-20 d-flex justify-content-between">
            <div>
                <p-selectButton *ngIf="entityType === 'Salesperson'"
                                (onChange)="onModeChange()"
                                [options]="modeOptions"
                                [(ngModel)]="selectedMode"></p-selectButton>
            </div>
            <div class="float-right">
                <button class="ui-button-success mx-5" *ngIf="createRetailer"
                        icon="fa fa-plus" iconPos="left"
                        label="Summary Order"
                        title="Summary Order"
                        [disabled]="true"
                        (click)="goToBasket()"
                        pButton type="button"></button>
                <button class="ui-button-success" *ngIf="createRetailer"
                        icon="fa fa-plus" iconPos="left"
                        label="Line Item Order"
                        title="Line Item Order"
                        [disabled]="!selectedRetailer"
                        (click)="goToBasket()"
                        pButton type="button"></button>
            </div>
        </div>

    </p-card>
</div>
