import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { OrderService } from '../../../services/middleware/order/order.service';

@Component({
  selector: 'app-order-tracking-table',
  templateUrl: './order-tracking-table.component.html',
  styleUrls: ['./order-tracking-table.component.scss']
})
export class OrderTrackingTableComponent implements OnInit {

  @Input() orderTrackingData : any;
  @Input() orderTrackingDialog : any;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  totalTrackingOrder = 0;
  public orderTrackingLoading: any = true;
  orderTrackingCols: any[] = [];
  orderTrackingDataa = [];
  constructor(
    private orderService: OrderService,
    private msgService: MessageService,
  ) {
    this.orderTrackingCols = [
      { field: 'invoiceDate', header: 'Invoice Date', sortField: 'invoiceDate', spinner: true },
      { field: 'invoiceNumber', header: 'Invoice Number', sortField: 'invoiceNumber', spinner: true },
      { field: 'trackinglink', header: 'Tracking Link', sortField: 'trackinglink', spinner: true },
      { field: 'shippingMethodForTracking', header: 'Shipping Method', sortField: 'shippingMethodForTracking', spinner: true },
      { field: 'sentTrackingNumber', header: 'Tracking Number', sortField: 'sentTrackingNumber', spinner: true },
      { field: 'estimatedShippingCost', header: 'Estimated shipping cost', sortField: 'estimatedShippingCost', spinner: true },
    ]
   }

  ngOnInit(): void {

    this.totalTrackingOrder = 0;
        const repGroupID = this.orderTrackingData.repGroup.recordID;
        const orderID = this.orderTrackingData.recordID
        this.orderService.orderTrackingAPI(repGroupID, orderID).subscribe((response: any)  => {
            if (response.success) {
                this.orderTrackingDataa = response.data;
                this.totalTrackingOrder = response.data.length;
            } else {
                this.orderTrackingDataa = [];
            }
            this.orderTrackingLoading = false;
            window.setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
          }, 0);
        }, error => {
          this.orderTrackingLoading = false;
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });

  }

  onHide() {
    this.orderTrackingDialog = false;
    this.isOpenChange.emit(false);

  }

}
