import {Injectable} from '@angular/core';
import {RTSharedService} from '../rtshared/rtshared.service';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { AuthService } from '../../services/middleware/auth/auth.service';

@Injectable()
export class RepgroupService {
    baseUrl: string;
    private dataSource = new BehaviorSubject([]);
    currentData = this.dataSource.asObservable();

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private rtSharedService: RTSharedService
    ) {
        this.baseUrl = this.rtSharedService.getApiUrl();
    }

    getRepGroupOrderCodes() {
        const payload: any = {};
        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
            payload.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        }
        const repGroupID = this.rtSharedService.getRepGroupID();
        let url = `${this.baseUrl}reptime/api/repgroup/${repGroupID}/repgroupordercodes`;

        if (payload && !_.isEmpty(payload)) { // if data available or not
            const params: any = new URLSearchParams();
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) { // return true Or fasle
                    payload[key] = encodeURIComponent(payload[key]); // get value of key
                    params.set(key, payload[key]); // set params in key and value
                }
            }
            url = url + '?' + params.toString(); // join url with params
        }

        return this.http.get(url);
    }

    public getDivisionsByCompany(repGroupID: any) {
        const url = `${this.baseUrl}reptime/api/${repGroupID}/divisions`;
        return this.http.get(url);
    }

    getCategoryGroupsByRepGroup(repGroupID: any, queryStringParam?: any) {
        let url = `${this.baseUrl}reptime/api/${repGroupID}/repgroups/groups`;

        if (queryStringParam && !_.isEmpty(queryStringParam)) { // if data available or not
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) { // return true Or fasle
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]); // get value of key
                    params.set(key, queryStringParam[key]); // set params in key and value
                }
            }
            url = url + '?' + params.toString(); // join url with params
        }
        return this.http.get(url); // get data
    }

    getRepgroupCategories(repGroupID: any, manufacturerID?: any) {
        let url = `${this.baseUrl}reptime/api/${repGroupID}/categories`;
        const params: any = new URLSearchParams();
        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
            params.set('retailerShipToLocationID', JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID);
        }
        if (manufacturerID) {
            params.set('manufacturerID', manufacturerID);
        }
        url = url + (params ? '?' + params.toString() : '' );
        return this.http.get(url);
        // return await this.httpClient.get(this.urlTools.addTokens(this.getRepgroupCategoriesURL, {repGroupID: repGroupID})).toPromise();
    }

    changeData(data: any) {
        this.dataSource.next(data);
    }

}
