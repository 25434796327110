import { Injectable } from '@angular/core';

import { BasketItemVariantAPIService } from '../../api/basket-item-variant-api/basket-item-variant-api.service';

@Injectable({
    providedIn: 'root'
})
export class BasketItemVariantService {

    constructor(private bivAPIService: BasketItemVariantAPIService) {
    }

    async saveItemVariantToBasket(retailerID: string, retailerShipToLocationID: string, item: any, itemVariant: any) {
        const newBasketItemVariant: any = {};
        if (itemVariant.basketItemID) {
            newBasketItemVariant.recordID = itemVariant.recordID;
            newBasketItemVariant.itemColorStyleID = itemVariant.itemColorStyleID;
        } else {
            newBasketItemVariant.itemColorStyleID = itemVariant.recordID;
            const datediscontinue = [new Date(item.discountStartDate).getTime(), new Date(item.discountEndDate).getTime()];
            const date = new Date().getTime();
            if (datediscontinue[0] <= date && datediscontinue[1] >= date &&
                (itemVariant.itemDiscount === undefined || itemVariant.itemDiscount === null || itemVariant.itemDiscount === 0)) {
                itemVariant.itemDiscount = item.discountPercent ? item.discountPercent : itemVariant.itemDiscount;
            }
        }
        newBasketItemVariant.b2bPriceCode = item.b2bPriceCode ? item.b2bPriceCode : null;
        newBasketItemVariant.size = itemVariant.size;
        newBasketItemVariant.style = itemVariant.style;
        newBasketItemVariant.color = itemVariant.color;
        newBasketItemVariant.quantity = itemVariant.quantity ? itemVariant.quantity : (item.minimumQuantity ? item.minimumQuantity : 1);
        newBasketItemVariant.itemID = item.recordID;
        newBasketItemVariant.manufacturerID = item.manufacturerID;

        // MG:01-SEP-2021
        // if SCS has price then overwrite it
        newBasketItemVariant.unitPrice = item.unitPrice;
        if (itemVariant.price !== undefined && itemVariant.price !== null) {
            newBasketItemVariant.unitPrice = itemVariant.price;
        }

        newBasketItemVariant.itemDiscount = itemVariant.itemDiscount ? itemVariant.itemDiscount : 0;

        try {
            await this.bivAPIService.saveItemVariantToBasket({ retailerID, retailerShipToLocationID }, newBasketItemVariant);
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async deleteItemVariant(IDs: any[]) {
        try {
            await this.bivAPIService.deleteItemVariant(IDs);
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }
}
