import { Component, OnInit, OnChanges, AfterViewInit, OnDestroy, Input, HostListener } from '@angular/core';
import { ConfirmationService, DialogService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, NavigationStart } from '@angular/router';
import * as _ from 'lodash';
import * as objectPath from 'object-path';

import { ItemsListModalComponent } from '../../../modals/items-list-modal/items-list-modal.component';
import { RetailersTableComponent } from '../../..//components/common/retailers-table/retailers-table.component';
import { OrderService } from '../../../services/middleware/order/order.service';
import { RTSharedService } from '../../../services/rtshared/rtshared.service';
import { BasketItemService } from '../../../services/middleware/basket-item/basket-item.service';
import { SelectContactDialogComponent } from '../../../modals/select-contact-dialog/select-contact-dialog.component';
import { PromotionsDialogComponent } from '../../../modals/promotions-dialog/promotions-dialog.component';
import { RetailerService } from '../../../services/middleware/retailer/retailer.service';
import { MinimumMetDialogComponent } from '../../../modals/minimum-met-dialog/minimum-met-dialog.component';
import { ManufacturerService } from '../../../services/api/manufacturer.service';
import { ISCDialogComponent } from '../../../modals/isc-dialog/isc-dialog.component';
import { Subscription } from 'rxjs';
import { CalloutApiService } from 'src/app/services/api/callout-api/callout-api.service';
import { RepgroupCalloutList } from '../../../models/repgroup-callouts-list';
import { SidebarApiService } from '../../../services/api/sidebar-api/sidebar-api.service';
// [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
import { AuthService } from '../../../services/middleware/auth/auth.service';
import { ItemsAPIService } from '../../../services/api/items-api/items-api.service';
import { ReptimeEntity } from '../../../models/reptime-entity';
@Component({
    selector: 'rs-new-order',
    templateUrl: './new-order.component.html',
    styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() whichPage: any;
    public isIframeWindow: any = (window as any).iframeData;
    public repGroupID: string;
    public retailerID: string;
    public selectedCustomer: any;
    public cartData: any;
    public promotionsData: any;
    public manufacturersArr: any = [];
    public calloutArr: any = [];
    public itemMfgIDs: any = [];
    public subscription: Subscription;
    public subscriptionWishListData: Subscription;
    public isItemAlreadyAvailable: boolean;
    // public isNewRetailerWizard: boolean;
    public lazyLoadFunction: any;
    public retailer: any;
    public fromPage: any;
    public orderID: any;
    public manufacturerID: any;
    public sequenceID: any;
    public onlyOneAdd = false;
    public calloutData: any;
    public calloutList = [];
    public chipsData = [];
    containerOrderLoader = true; // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    basketSyncLoader = true; // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    chipsValues = [];
    public activeRouteQP: any;
    public reptimeEntity = ReptimeEntity;
    public sidebarMenuList: any;
    public cartClickSubscription: Subscription;
    public getRetailerDataSubscription: Subscription;
    public openSidebarStatus: any;

    // (RM 28-02-2020): Cart details open item view details
    public activeViewItem: any;
    public activeViewIndex: any;
    public displayItemViewDialog: boolean;
    public selectedManufacturer: any;
    public existCartData: any;
    public isQuickOrder: any;
    public innerWidth: any;
    public cartBarPosition = 'fixed';
    @Input() pageType: any;
    public colorOptions = this.auth.getColorSelectionSettings();
    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    constructor(
        public router: Router,
        private itemsAPIService: ItemsAPIService,
        private basketItemService: BasketItemService,
        private orderService: OrderService,
        private rtSharedService: RTSharedService,
        private retailerService: RetailerService,
        private manufacturerService: ManufacturerService,
        private sidebarApiService: SidebarApiService,
        private confirmationService: ConfirmationService,
        private calloutApiService: CalloutApiService,
        public dialogService: DialogService,
        private activeRoute: ActivatedRoute,
        public auth: AuthService,
        private msgService: MessageService) {
        this.repGroupID = this.rtSharedService.getRepGroupID();
        this.cartClickSubscription = this.basketItemService.getCartClickEvent().subscribe(async (flag) => {
            this.openSidebarStatus = flag ? true : false;
        });
        /*RC-573 (RK 2020-JAN-31) Add subscribe method for after ISC added show updated bucket Orders list */
        this.subscription = this.basketItemService.getCartEvent().subscribe(async (retailer) => {
            if (retailer) {
                this.cartData = this.setOldCartData(await this.getCart(retailer));

                this.setMfgMinimumMet(this.cartData.manufacturer);
            }
        });
        this.subscriptionWishListData = this.basketItemService.getWishListDataEvent().subscribe(async (res) => {
        });
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }
    // B2B-51 (MG 28-JAN-2020): modified to filter MFR
    // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.activeRoute.queryParams.subscribe((qp) => {

            this.isQuickOrder = this.activeRoute.snapshot.queryParams.quick;
            this.activeRouteQP = qp;
            let removedObj = _.omit(this.activeRouteQP, ['quick']);
            if (this.whichPage === 'vendor' || this.whichPage === 'vendor-details') { // TODO: In vendor page deduct mfg from url
                removedObj = _.omit(removedObj, ['mfg']);
            }
            if (!_.isEmpty(removedObj)) {
                this.addClearAllChip();
            } else {
                this.chipsValues = [];
            }
            this.addPriceChipsFilter();
            this.addCalloutChipsFilter();
            this.addColorChips();
        });
        /*B2B-75 (RK 2020-FEB-17) get MFG list when sidebar load*/
        this.sidebarApiService.getMfgEvent().subscribe((data) => {
            this.manufacturersArr = data;
            if (this.whichPage !== 'vendor' || this.whichPage !== 'vendor-details') { // TODO: In vendor page not need addVendorChipsFilter
                this.addNewArrivalChips(); // TODO: Fix issue new arrival page redirection not working
                this.addGroupChips(); // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
                this.addVendorChipsFilter();
                this.addNewBestSellerChips();
            }
        });
        /*B2B-75 (RK 2020-FEB-25) get Menu list when sidebar load*/
        this.sidebarApiService.getMenuEvent().subscribe((data) => {
            this.sidebarMenuList = data;
            this.addCategoriesFilter();
        });
        // this.openSelectRetailerModal();
        this.containerOrderLoader = true;
        this.retailerID = this.rtSharedService.getRetailerID();
        // B2B-286 (MK 04-07-2020 )subscribe data
        this.getRetailerDataSubscription = this.auth.getRetailerData.subscribe(async (getRetailerRes) => {
            this.selectedCustomer = getRetailerRes;
            console.log('Rahul....this.selectedCustomer ', this.selectedCustomer);
            this.getRetailer();
        });
        // const extraParam = (this.rtSharedService.getProjectName() === 'B2B') ? '?companyID=' + this.retailerID : '';
        // this.getRetailer(this.retailerID + extraParam);

    }
    ngAfterViewInit() {
        this.isIframeWindow = (window as any).iframeData;
    }
    ngOnDestroy() {
        this.cartClickSubscription.unsubscribe();
        this.getRetailerDataSubscription.unsubscribe();
        this.subscriptionWishListData.unsubscribe();
    }
    ngOnChanges() {

    }
    addCalloutChipsFilter() {
        this.chipsValues = this.chipsValues.filter((obj) => {
            return obj.type !== 'callout';
        });
        if (this.activeRouteQP.callout && this.calloutData && this.calloutData.length) {
            const calloutUrlData = this.activeRouteQP.callout.split(',');

            Object.keys(calloutUrlData).forEach((k: any) => {
                const mapCalloutUrlData = this.calloutData.find(o => (o.recordID.toString() === calloutUrlData[k]));
                if (mapCalloutUrlData) {
                    this.chipsValues.push({
                        name: mapCalloutUrlData.title,
                        value: mapCalloutUrlData.recordID,
                        type: 'callout'
                    });
                }
            });
        }
    }
    addPriceChipsFilter() {
        this.chipsValues = this.chipsValues.filter((obj) => {
            return obj.type !== 'fromprice' && obj.type !== 'toprice';
        });
        if (this.activeRouteQP.fromprice && this.activeRouteQP.fromprice.length) {
            const fromPriceUrlData = this.activeRouteQP.fromprice;
            if (fromPriceUrlData) {
                this.chipsValues.push({
                    name: `From Price: ${fromPriceUrlData}`,
                    value: fromPriceUrlData,
                    type: 'fromprice'
                });
            }
        }
        if (this.activeRouteQP.toprice && this.activeRouteQP.toprice.length) {
            const toPriceUrlData = this.activeRouteQP.toprice;
            if (toPriceUrlData) {
                this.chipsValues.push({
                    name: `To Price: ${toPriceUrlData}`,
                    value: toPriceUrlData,
                    type: 'toprice'
                });
            }
        }
    }

    addNewArrivalChips() {
        this.chipsValues = this.chipsValues.filter((obj) => {
            return obj.type !== 'newarrival';
        });
        if (this.activeRouteQP.new && this.activeRouteQP.new.length) {
            const newArrivalData = this.activeRouteQP.new;
            if (newArrivalData) {
                this.chipsValues.push({
                    name: `New Arrival`,
                    value: 90,
                    type: 'newarrival'
                });
            }
        }
    }
    addGroupChips() {
        this.chipsValues = this.chipsValues.filter((obj) => {
            return obj.type !== 'group';
        });
        if (this.activeRouteQP.g && this.auth.RepGroupCategoryGroupsList && this.auth.RepGroupCategoryGroupsList.length) {
            const getCurrentGroupData = this.auth.RepGroupCategoryGroupsList.find(o => o.groupID.toString() === this.activeRouteQP.g);
            if (getCurrentGroupData) {
                this.chipsValues.push({
                    name: `${getCurrentGroupData.groupName}`,
                    value: this.activeRouteQP.g,
                    type: 'group'
                });
            }
        }
    }
    addNewBestSellerChips() {
        this.chipsValues = this.chipsValues.filter((obj) => {
            return obj.type !== 'newbestseller';
        });
        // RC-1653 (RK 03-MARCH-2020 ) Remove Show Best Seller MFG wise
        // if (this.activeRouteQP.bestseller && this.activeRouteQP.bestseller.length) {
        //     const newBestSeller = this.activeRouteQP.bestseller;
        //     if (newBestSeller) {
        //         this.chipsValues.push({
        //             name: `Show Best Seller`,
        //             value: null,
        //             type: 'newbestseller'
        //         });
        //     }
        // }
    }
    addColorChips() {
        this.chipsValues = this.chipsValues.filter((obj) => {
            return obj.type !== 'color';
        });
        if (this.activeRouteQP.color && this.colorOptions && this.colorOptions.colorsList.length) {
            const colorModel = this.activeRouteQP.color.split(',');
            Object.keys(colorModel).forEach((k: any) => {
                const mapData = this.colorOptions.colorsList.find(o => (o.colorlabel === colorModel[k]));
                if (mapData) {
                    this.chipsValues.push({
                        name: mapData.colorlabel,
                        value: mapData.colorlabel,
                        type: 'color'
                    });
                }
            });
        }
        console.log('Rahul....chipsValues', this.chipsValues);
    }

    addClearAllChip() {
        const mapMFGData = this.chipsValues.find(o => (o.type === 'clearAll'));
        if (mapMFGData === undefined) {
            this.chipsValues.push({
                name: `Clear All`,
                value: null,
                type: 'clearAll'
            });
        }
    }

    addVendorChipsFilter() {
        this.chipsValues = this.chipsValues.filter((obj) => {
            return obj.type !== 'vendor';
        });
        if (this.activeRouteQP.mfg && this.manufacturersArr && this.manufacturersArr.length) {
            const mfgUrlData = this.activeRouteQP.mfg.split(',');
            Object.keys(mfgUrlData).forEach((k: any) => {
                const mapMFGData = this.manufacturersArr.find(o => (o.recordID === mfgUrlData[k]));
                if (mapMFGData) {
                    this.chipsValues.push({
                        name: mapMFGData.repGroupManufacturers[0].name,
                        value: mapMFGData.recordID,
                        type: 'vendor'
                    });
                }
            });
        }
        console.log('TESTing >>>', this.activeRouteQP);
        if (this.auth.getCategoryGroupType() === 'vendors') {
            if (this.chipsValues.length === 1 && this.chipsValues[0].type === 'clearAll') {
                this.chipsValues = [];
                //  this.router.navigate(['/shop']);
                if (this.auth.getIDToken() && this.activeRouteQP && !this.activeRouteQP.itemdetails) {
                    this.router.navigate(['/shop']);
                }
            }
        }
    }
    addCategoriesFilter() {
        this.chipsValues = this.chipsValues.filter((obj) => {
            return obj.type !== 'category' && obj.type !== 'subcategory' && obj.type !== 'group';
        });
        // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection [start]
        if (this.activeRouteQP.g && this.auth.RepGroupCategoryGroupsList && this.auth.RepGroupCategoryGroupsList.length) {
            const getCurrentGroupData = this.auth.RepGroupCategoryGroupsList.find(o => o.groupID.toString() === this.activeRouteQP.g);
            if (getCurrentGroupData) {
                this.chipsValues.push({
                    name: `${getCurrentGroupData.groupName}`,
                    value: this.activeRouteQP.g,
                    type: 'group'
                });
            }
        }
        // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection [end]
        if (this.activeRouteQP.c && this.activeRouteQP.c.length && this.sidebarMenuList.length) {
            const categoryIDSet = this.activeRouteQP.c.split(',');
            categoryIDSet.forEach(element => {
                const categoryData = element;
                const getCurrentCategoryData = this.sidebarMenuList.find(o => o.recordID.toString() === categoryData);
                if (categoryData) {
                    this.chipsValues.push({
                        name: `${getCurrentCategoryData.categoryName}`,
                        value: categoryData,
                        type: 'category'
                    });
                }
            });
        }
        // const categoryData = this.activeRouteQP.c;
        //     const getCurrentCategoryData = this.sidebarMenuList.find(o => o.recordID.toString() === categoryData);

        //     if (categoryData) {
        //         this.chipsValues.push({
        //             name: `${getCurrentCategoryData.categoryName}`,
        //             value: categoryData,
        //             type: 'category'
        //         });
        //     }
        // }
        if (this.activeRouteQP.s && this.activeRouteQP.s.length && this.sidebarMenuList.length) {
            const categoryData = this.activeRouteQP.c;
            const subcategoryData = this.activeRouteQP.s;
            const getCurrentCategoryData = this.sidebarMenuList.find(o => o.recordID.toString() === categoryData);
            const getCurrentSubCategoryData = getCurrentCategoryData.repGroupSubCategories
                .find(o => o.recordID.toString() === subcategoryData);

            if (subcategoryData) {
                this.chipsValues.push({
                    name: `${getCurrentSubCategoryData.defaultSubCategory.name}`,
                    value: subcategoryData,
                    type: 'subcategory'
                });
            }
        }

        // if (this.activeRouteQP.ss && this.activeRouteQP.ss.length && this.sidebarMenuList.length) {
        //     const categoryData = this.activeRouteQP.c;
        //     const subcategoryData = this.activeRouteQP.s;
        //     const subsubcategoryData = this.activeRouteQP.ss;
        //     const getCurrentCategoryData = this.sidebarMenuList.find((o: any) => o.recordID.toString() === categoryData);
        //     const getCurrentSubCategoryData = getCurrentCategoryData.repGroupSubCategories
        //         .find((o: any) => o.recordID.toString() === subcategoryData);
        //     const getCurrentSubSubCategoryData = getCurrentSubCategoryData.defaultSubCategory.defaultSubSubCategory
        //         .find((o: any) => o.recordID.toString() === subsubcategoryData);
        //     if (subcategoryData) {
        //         this.chipsValues.push({
        //             name: `${getCurrentSubSubCategoryData.name}`,
        //             value: subsubcategoryData,
        //             type: 'subsubcategory'
        //         });
        //     }
        // }
    }
    removeFilter($event) {
        const qpObject = Object.assign({}, this.activeRouteQP);
        let chkData = [];
        switch ($event.value.type) {
            case 'vendor':
                chkData = qpObject.mfg.split(',');
                const index: number = chkData.indexOf($event.value.value);
                if (index !== -1) {
                    chkData.splice(index, 1);
                }
                if (chkData && chkData.length) {
                    qpObject.mfg = chkData.toString();
                } else if (this.whichPage !== 'vendor') { // TODO: In vendor page don't remove MFG id from url
                    delete qpObject.mfg;
                }
                // B2B-268 (MJ 25-Jun-2020) changed for vendor filter
                if (this.chipsValues.length === 1 && this.chipsValues[0].type === 'clearAll') {
                    delete qpObject.mfg;
                }
                break;
            case 'color':
                chkData = qpObject.color.split(',');
                const index2: any = chkData.indexOf($event.value.value.toString());
                if (index2 !== -1) {
                    chkData.splice(index2, 1);
                }
                if (chkData && chkData.length) {
                    qpObject.color = chkData.toString();
                } else {
                    delete qpObject.color;
                }
                break;
            case 'vendor-details':
                chkData = qpObject.mfg.split(',');
                const index6: number = chkData.indexOf($event.value.value);
                if (index6 !== -1) {
                    chkData.splice(index6, 1);
                }
                if (chkData && chkData.length) {
                    qpObject.mfg = chkData.toString();
                } else if (this.whichPage !== 'vendor-details') { // TODO: In vendor page don't remove MFG id from url
                    delete qpObject.mfg;
                }
                // B2B-268 (MJ 25-Jun-2020) changed for vendor filter
                if (this.chipsValues.length === 1 && this.chipsValues[0].type === 'clearAll') {
                    //   delete qpObject.mfg;
                }
                break;
            case 'callout':
                chkData = qpObject.callout.split(',');
                const index1: number = chkData.indexOf($event.value.value.toString());
                if (index1 !== -1) {
                    chkData.splice(index1, 1);
                }
                if (chkData && chkData.length) {
                    qpObject.callout = chkData.toString();
                } else {
                    delete qpObject.callout;
                }
                break;
            case 'fromprice':
                delete qpObject.fromprice;
                break;
            case 'toprice':
                delete qpObject.toprice;
                break;
            case 'group':
                delete qpObject.g;
                break;
            case 'category':
                // B2B-268 (MJ 23-Jun-2020) Groups category filter added
                const groupCategory = qpObject.c.split(',');
                let ids = '';
                groupCategory.forEach((element, i) => {
                    if ($event.value.value === element) {
                        groupCategory.splice(i, 1);
                    }
                });
                groupCategory.forEach((element, i) => {
                    if (i + 1 === groupCategory.length) {
                        ids += `${element}`;
                    } else {
                        ids += `${element},`;
                    }
                });
                if (ids === '') {
                    delete qpObject.c;
                } else {
                    qpObject.c = ids;
                }
                delete qpObject.s;
                // delete qpObject.ss;
                break;
            case 'subcategory':
                delete qpObject.s;
                // delete qpObject.ss;
                break;
            // case 'subsubcategory':
            //     delete qpObject.ss;
            //     break;
            case 'clearAll':
                delete qpObject.g;
                delete qpObject.c;
                delete qpObject.s;
                // delete qpObject.ss;
                delete qpObject.toprice;
                delete qpObject.fromprice;
                delete qpObject.new;
                delete qpObject.bestseller;
                delete qpObject.callout;
                delete qpObject.color;
                if (this.whichPage !== 'vendor' && this.whichPage !== 'vendor-details') { // TODO: In vendor page don't remove MFG id from url
                    delete qpObject.mfg;
                }
                break;
            case 'newarrival':
                delete qpObject.new;
                break;
            case 'newbestseller':
                delete qpObject.bestseller;
        }
        if (!this.pageType) {
            this.router.navigate([this.whichPage === 'vendor' ? '/vendor' : '/shop'], { queryParams: qpObject });
        } else {
            console.log(qpObject)
            if (this.whichPage) {
                if (this.whichPage === 'vendor-details') {
                    this.router.navigate(['/vendor-details'], { queryParams: qpObject });
                }
                if (this.whichPage === 'store') {
                    this.router.navigate(['/store'], { queryParams: qpObject });
                }
            }
        }
    }
    // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    async getRetailer() {
        // B2B-286 (MK 04-07-2020 )remove api call
        console.log('this.selectedCustomer>>', this.selectedCustomer);
        if (this.selectedCustomer) {
            if (this.selectedCustomer.shipToLocations && this.selectedCustomer.shipToLocations.length) {
                this.selectedCustomer.shipToLocations = this.selectedCustomer.shipToLocations.map(o => {
                    o.retailerID = this.retailerID;
                    return o;
                });
                const sessionRetailerShipToLocationID = sessionStorage.getItem('retailerShipToLocationID');
                this.selectedCustomer.selectedShipTo = sessionRetailerShipToLocationID ? JSON.parse(sessionRetailerShipToLocationID) :
                    this.selectedCustomer.shipToLocations[0];
                // const sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
                // if (sessionLocationId === null) {
                //     sessionStorage.setItem('retailerShipToLocationID', JSON.stringify(this.selectedCustomer.selectedShipTo));
                //     this.rtSharedService.setSelectedShipTo(this.selectedCustomer.selectedShipTo);
                // } else {
                //     const x = this.selectedCustomer.shipToLocations.find(o => +o.recordID === +sessionLocationId.recordID);
                //     this.rtSharedService.setSelectedShipTo(x);
                // }
            } else if (!this.selectedCustomer.shipToLocations) {
                console.log('no foundShip');
            }
            if (this.selectedCustomer.contacts && this.selectedCustomer.contacts.length) {
                this.selectedCustomer.contacts = this.selectedCustomer.contacts.map(o => {
                    o.retailerID = this.retailerID;
                    return o;
                });

                // this.selectedCustomer.buyer = this.selectedCustomer.contacts[0];
                this.selectedCustomer.buyer = this.selectedCustomer.contacts.find(o => o.isPrimary === true);
            }
            this.retailer = this.selectedCustomer;
            if (this.auth.getIDToken()) {
                this.cartData = await this.getCart(this.selectedCustomer);


                if (objectPath.get(this.cartData, 'manufacturer', null)) {
                    this.setMfgMinimumMet(this.cartData.manufacturer);
                }
            }
        }
        this.retailerService.setRetailerDataEvent(this.selectedCustomer);

        /* B2B-73 (RK 14-FEB-2020): Comment getManufacturersByRepGroup method and called getManufacturersPromotions method directly
         beacuse of mfg list part move on sidebar.component.ts so no longer needed here*/
        if (this.whichPage !== 'vendor' && !this.pageType) {
            this.getCalloutData();
        }
        if (!this.pageType) {
            this.getManufacturersPromotions();
            console.log('TESTING >>>>>1');
        }
        this.containerOrderLoader = false;
    }

    setOldCartData(cartData: any) {
        if (cartData && cartData.manufacturer && cartData.manufacturer.length) {
            cartData.manufacturer = _.map(cartData.manufacturer, (m: any) => {
                if (m.items && m.items.length) {
                    m.items = _.map(m.items, (i: any) => {
                        if (this.cartData && this.cartData.manufacturer && this.cartData.manufacturer.length) {
                            _.forEach(this.cartData.manufacturer, (om) => {
                                if (om.items && om.items.length) {
                                    _.forEach(om.items, (oi) => {
                                        if (oi.recordID === i.recordID && oi.isTreeActive) {
                                            i.isTreeActive = oi.isTreeActive;
                                        }
                                    });
                                }
                            });
                        }
                        return i;
                    });
                }
                return m;
            });
        }

        return cartData;
    }

    // B2B-51 (MG 28-JAN-2020): modified to filter MFR
    // B2B-52 (MG 31-JAN-2020): modified to filter MFR's promotions
    getManufacturersByRepGroup() {
        this.manufacturerService.getManufacturersByRepGroup().subscribe(async (res) => {
            this.getManufacturersPromotions();
            console.log('TESTING >>>>>2');
            if (this.rtSharedService.getProjectName() === 'B2B') {
                this.manufacturersArr = res.data.filter(o => {
                    if (o.repGroupManufacturers && o.repGroupManufacturers.length) {
                        if (o.repGroupManufacturers[0].showOnWebsite === true) {
                            return { recordID: o.recordID, name: o.name };
                        }
                    }
                });
            } else {
                this.manufacturersArr = res.data.map(o => {
                    return { recordID: o.recordID, name: o.name };
                });
            }
        });
    }
    getCalloutData() {
        // B2B-75 (RK 15-FEB-2020): Get callout data
        const payload: any = {
            repGroupID: this.rtSharedService.getRepGroupID(),
        };
        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
            payload.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        }
        this.calloutApiService.getCalloutsData(payload).then((res: RepgroupCalloutList) => {
            if (res.success) {
                this.calloutData = res.data;
                Object.keys(this.calloutData).forEach(k => {
                    this.calloutList.push({ label: this.calloutData[k].title, value: this.calloutData[k].recordID });
                });
                this.addCalloutChipsFilter();
            } else {


                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: objectPath.get(res, 'error.message', 'Error occurred during services request')
                });
            }
        }, (err) => {
            this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });
    }
    // B2B-52 (MG 31-JAN-2020): modified to filter MFR's promotions
    async getManufacturersPromotions() {
        const activePromotions = await this.retailerService.getManufacturersPromotions(this.repGroupID, this.retailerID);
        this.manufacturersArr.forEach(element => {
            if (activePromotions.data) { // check data exist or not
                element.promotions = activePromotions.data.filter(o => {
                    if (o.manufacturerID === element.recordID) {
                        return o;
                    }
                });
            }
        });
    }

    async getSelectedCustomer(customer: any) {
        this.selectedCustomer = customer;
        this.cartData = await this.getCart(this.selectedCustomer);

        if (objectPath.get(this.cartData, 'manufacturer', null)) {
            this.setMfgMinimumMet(this.cartData.manufacturer);
        }
    }

    async addToCart(item: any) {
        item.isLoading = true;
        // const existFind = this.itemMfgIDs.find(o => (item.itemNumber === o.itemNumber
        //     && item.manufacturerID === o.manufacturerID && !o.recordDeleted));
        // if (existFind) {
        //     this.isItemAlreadyAvailable = true;
        //     item.isLoading = false;
        // } else {
        try {
            this.cartData = await this.getCart(this.selectedCustomer);
            const existItemFind = this.cartData.items.find(o => (item.itemNumber === o.item.itemNumber
                && item.manufacturerID === o.item.manufacturerID));
            if (item.quantity || existItemFind) {
                const msgServiceData = {
                    severity: 'info',
                    summary: 'Item Added to Cart',
                    detail: `${item.itemNumber} was saved successfully.`
                };
                if (existItemFind) {
                    existItemFind.quantity = item.quantity ? item.quantity : item.minimumQuantity;
                    msgServiceData.summary = 'Item Updated to Cart';
                    await this.basketItemService.saveItemToBasket(existItemFind, this.selectedCustomer);
                } else {
                    if ((item.quantity > 0 && item.quantity < item.minimumQuantity) || item.quantity === null) {
                        item.quantity = item.minimumQuantity;
                    }
                    await this.basketItemService.saveItemToBasket(item, this.selectedCustomer);
                }
                this.cartData = await this.getCart(this.selectedCustomer);
                this.msgService.clear();
                this.msgService.add(msgServiceData);
                if (objectPath.get(this.cartData, 'manufacturer', null)) {
                    this.setMfgMinimumMet(this.cartData.manufacturer);
                }
            }
            item.isLoading = false;
        } catch (e) {
            item.isLoading = false;
            this.msgService.add({
                severity: 'error',
                summary: 'Error',
                detail: `${e.message}`
            });
        }
        // }
        // focus and select advance search input field
        const textFiled = document.getElementById('adv-search-input');
        if (textFiled) {
            textFiled.focus();
        }
    }
    cartDataEvent(dataEvent: any) {
        this.cartData = dataEvent.cartData;
        this.itemMfgIDs = dataEvent.itemMfgIDs;
        this.displayItemViewDialog = false;
        if (objectPath.get(this.cartData, 'manufacturer', null)) {
            this.setMfgMinimumMet(this.cartData.manufacturer);
        }
    }
    async customerChange(customer: any) {
        this.selectedCustomer = customer;
        // If cart items available then assign to new customer.
        if (this.cartData.totalMgf) {
            const itemsIDs = [];
            _.forEach(this.cartData.manufacturer, (m) => {
                _.forEach(m.items, (i) => {
                    itemsIDs.push(i.recordID);
                });
            });
            await this.basketItemService.updateMultipleBasketItem(this.selectedCustomer, itemsIDs);
        }
        /* B2B-73 (RK 14-FEB-2020): Comment getManufacturersByRepGroup method and called getManufacturersPromotions method directly
         beacuse of mfg list part move on sidebar.component.ts so no longer needed here*/
        // this.getManufacturersByRepGroup();
        this.getManufacturersPromotions();
        await this.getSelectedCustomer(customer);
    }

    getPromotions(manufacturer) {

    }

    getItemWithMfg(cartData) {
        const itemIDs: any = [];
        if (objectPath.get(cartData, 'manufacturer', null)) {
            _.forEach(cartData.manufacturer, (o) => {
                _.forEach(o.items, (itemObj: any) => {
                    if (itemObj.basketItemVariants && itemObj.basketItemVariants.length) {
                        _.forEach(itemObj.basketItemVariants, (biv: any) => {
                            itemIDs.push({
                                manufacturerID: itemObj.manufacturerID,
                                itemNumber: itemObj.itemNumber ? itemObj.itemNumber : itemObj.item.itemNumber,
                                itemColorStyleID: biv.itemColorStyleID
                            });
                        });
                    } else {
                        itemIDs.push({
                            manufacturerID: itemObj.manufacturerID,
                            itemNumber: itemObj.item.itemNumber
                        });
                    }

                });
            });
        }
        return itemIDs;
    }

    // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    async getCart(customer) {

        this.basketSyncLoader = true;
        const cartData = await this.basketItemService.getBasketItemData(customer);
        //
        // cartData.items = _.map(cartData.items, (o: any) => {
        //     const chkIndex = _.findIndex(wishListItem, (oo) => {
        //         return oo.item.recordID === o.item.recordID;
        //     });
        //
        //     if (chkIndex >= 0) {
        //         o.item.isWishedItem = true;
        //     } else {
        //         o.item.isWishedItem = false;
        //     }
        //     o.item.isWishedItem = false;
        //     return o;
        // });
        //
        // const cartData = await this.basketItemService.getBasketItemData(customer);
        this.itemMfgIDs = this.getItemWithMfg(cartData);
        this.basketSyncLoader = false;
        this.auth.setCartItemCount(cartData.totalItems);
        return cartData;
    }

    // B2B-41 (MG 05-FEB-2020): modified to show order items summary
    openItemsModal(mfgName, items, total) {
        const ref = this.dialogService.open(ItemsListModalComponent, {
            header: `${mfgName}'s Items`,
            width: '97%',
            height: 'auto',
            styleClass: 'overflow-auto cut-dialog-height',
            data: { items, total, retailer: this.selectedCustomer }
        });
        ref.onClose.subscribe(async () => {
            this.cartData = await this.getCart(this.selectedCustomer);

            this.setMfgMinimumMet(this.cartData.manufacturer);
        });
    }
    // [RC-971] (MK 25-SEPT-2020) error handling
    async checkout(selectedItems) {
        await this.basketItemService.checkoutBasketItem(this.selectedCustomer, selectedItems).then((value) => {
            this.goToFinalizePage(value);
        }).catch((err) => {
            this.msgService.clear();
            if (err.status === 500) {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: err.error.error.message });
            } else {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
            }
        });
        // const checkoutData = await this.basketItemService.checkoutBasketItem(this.selectedCustomer, selectedItems).catch((err) => {
        //     this.msgService.clear();
        //     this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        // });
        // this.goToFinalizePage(checkoutData);
    }

    goToFinalizePage(checkoutData) {
        const ordersIDs = checkoutData.map((o) => o.recordID);
        this.router.navigate([`/finalize-order`], { queryParams: { orderIDs: ordersIDs.join() } });
    }
    promotionsClickEvent($event) {
        this.openSpecialModal();
    }

    // [B2B-92] (MG 26-FEB-2020) modified to warn on min order
    metClickEvent($event) {
        const headerText = 'Manufacturer(s) Minimum';
        this.openMimMetModal(headerText);
    }
    updatedCartDataEvent(dataEvent) {
        console.log('updatedCartDataEvent >>', dataEvent);
        this.cartData = dataEvent.existCartData;
        this.selectedCustomer = dataEvent.selectedCustomer;
        this.itemMfgIDs = dataEvent.itemMfgIDs;
    }

    // [B2B-108] (MG 26-FEB-2020) modified to make style dynamic
    // [B2B-92] (MG 26-FEB-2020) modified to warn on min order
    checkoutClickEvent($event) {
        let stop = 'no';
        this.cartData.manufacturer.forEach(o => {
            if (o.isMinimumMet === false) {
                stop = 'yes';
                return;
            }
        });

        const isMinOrderRequired = this.auth.getIsMinOrderRequired();
        console.log('isMinOrderRequired', isMinOrderRequired);
        let headerText = 'Checkout';
        let warningText = 'Continue to Checkout?';
        let actionType = 'checkout';
        // B2B-170 (NL 17-MAR-2020) change warningText message
        if (stop === 'yes') {
            if (isMinOrderRequired === true) {
                headerText = 'Manufacturer Minimum(s) Not Met';
                warningText = 'You have not met the manufacturer minimum(s). '
                    + 'Please continue shopping to meet manufacturer requirements.';
                // warningText = 'You have not met the manufacturer minimums. '
                // + 'Please continue shopping to add more items to your cart to meet the manufacturer requirements.';
                actionType = 'shop';
            } else {
                headerText = 'Manufacturer Minimum(s) Not Met';
                warningText = 'You have not met the manufacturer minimums. Continue to checkout?';
                actionType = 'both';
            }
        }
        this.openMimMetModal(headerText, warningText, actionType);
    }
    // B2B-52 (MG 31-JAN-2020): modified to filter MFR's promotions
    openSpecialModal() {
        this.manufacturersArr.forEach(element => {
            const mfrIndex = this.cartData.manufacturer.findIndex(p => p.recordID === element.recordID);
            element.promotions = (mfrIndex >= 0) ? this.cartData.manufacturer[mfrIndex].promotions : [];
        });

        const ref = this.dialogService.open(PromotionsDialogComponent, {
            header: 'Promotions',
            width: '70%',
            height: 'auto',
            styleClass: 'overflow-auto cut-dialog-height responsive-ship-loc res-dialog-promo',
            closable: true,
            baseZIndex: 100000, // RM (29-02-2020): change - comment code, because cart sidebar keep open
            data: {
                manufacturers: this.cartData.manufacturer
            }
        });

        /*ref.onClose.subscribe((contact: any) => {
            console.log('contact', contact);
            if (contact) {
                order.retailerContact = contact;
                this.saveOrder(order, true);
            }
        });*/
    }

    openMimMetModal(headerText, warningText = '', actionType = '') {
        // let headerText = 'Manufacturer(s) Minimum';
        // if (warningText === '') {
        //     headerText = 'Manufacturer(s) Minimum';
        // }
        const ref = this.dialogService.open(MinimumMetDialogComponent, {
            header: headerText,
            width: '50%',
            height: 'auto',
            styleClass: 'overflow-auto cut-dialog-height responsive-ship-loc',
            closable: true,
            baseZIndex: 100000, // RM (29-02-2020): change - comment code, because cart sidebar keep open
            data: {
                manufacturers: this.cartData.manufacturer,
                warningText,
                actionType,
                retailer: this.selectedCustomer
            },

        });

        ref.onClose.subscribe((checkoutData) => {
            if (checkoutData) {
                this.retailerService.setRetailerDetailsEvent(true);
                const ordersIDs = checkoutData.map((o) => o.recordID);
                this.router.navigate([`/finalize-order`], { queryParams: { orderIDs: ordersIDs.join() } });
            }

        });
    }

    setMfgMinimumMet(manufacturers) {
        return manufacturers.map((mfg: any) => {
            mfg.isMinimumMet = (mfg.discountedTotal >= mfg.minimumOrderAmount);
            mfg.totalQty = this.getMfgTotalQty(mfg.items);
            return mfg;
        });
    }

    getMfgTotalQty(mfgItems) {
        return _.sumBy(mfgItems, 'quantity');
    }

    checkMinimumMet(manufacturers) {
        return _.some(manufacturers, ['isMinimumMet', false]);
    }

    onRetailerCreated($event) {
        console.log('created : ', $event.value);
    }
    /*RC-573 (RK 2020-JAN-31) Show ISC dialogue*/
    async openItemStyleColorModal(itemObj: any) {
        console.log('openItemStyleColorModal:', itemObj, this.retailer, this.orderID, this.itemMfgIDs);
        this.cartData = await this.getCart(this.selectedCustomer);
        const ref: any = this.dialogService.open(ISCDialogComponent, {
            header: 'Item Size, Color And Style',
            width: '100%',
            height: 'auto',
            closable: true,
            style: { 'max-width': '960px' },
            styleClass: 'overflow-auto',
            data: {
                isc: itemObj.isc,
                item: itemObj,
                retailer: this.retailer,
                orderID: itemObj.recordID,
                manufacturerID: itemObj.manufacturer,
                itemMfgIDs: this.itemMfgIDs,
                cartData: this.cartData
            }
        });
    }
    /*RC-343 (RK 2020-JAN-30) Show Item History Popup without ISC in B2B*/
    openHistoryItem(itemObj: any) {
        const queryStringParam = { retailerID: this.rtSharedService.getRetailerID() };
        console.log('openHistoryItem:', itemObj);
        const ref = this.dialogService.open(ItemsListModalComponent, {
            header: `${itemObj.manufacturerID}'s Items History`,
            width: '100%',
            height: 'auto',
            style: { 'max-width': '1050px' },
            styleClass: 'overflow-auto cut-dialog-height',
            data: {
                selectedItem: itemObj, retailer: this.retailer.buyer, itemsHistory: true,
                entityId: this.rtSharedService.getRetailerID(), stringParam: queryStringParam
            }
        });
    }

    // (RM 28-02-2020): Cart details open item view details
    openItemDetails($event) {
        console.log('new order >> openItemDetails:', $event);
        this.activeViewItem = $event.itemobj.item;
        this.activeViewIndex = $event.index;
        this.displayItemViewDialog = true;
        this.existCartData = $event.existCartData;
        // this.fromPage = 'ORDER_PAGE';
    }
}
