import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { BasketItemService } from '../../../../src/app/services/middleware/basket-item/basket-item.service';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { SidebarApiService } from '../../services/api/sidebar-api/sidebar-api.service';
import { ManufacturerService } from '../../services/api/manufacturer.service';
import { RTSharedService } from '../../services/rtshared/rtshared.service';
@Component({
    selector: 'rs-minimum-met-dialog',
    templateUrl: './minimum-met-dialog.component.html',
    styleUrls: ['./minimum-met-dialog.component.scss']
})
export class MinimumMetDialogComponent implements OnInit {

    public manufacturers: any;
    public selectedCustomer: any;
    public cols: any[];
    public selectedValues: any;
    public allSelected: any;
    public isLoading = false;
    public wholeMfrArray: any;
    showLoader: any;
    showEinAlert = false;
    constructor(
        public config: DynamicDialogConfig,
        private basketItemService: BasketItemService,
        private sidebarApiService: SidebarApiService, 
        private manufacturerService: ManufacturerService,
        public rtSharedService: RTSharedService,
        private msgService: MessageService,
        public ref: DynamicDialogRef,
        private auth: AuthService) {
    }

    async ngOnInit() {
        this.showLoader = true;
        this.manufacturers = this.config.data.manufacturers;
        this.wholeMfrArray = this.sidebarApiService.mfgData;
        if (this.wholeMfrArray && !this.wholeMfrArray.length) {
            this.wholeMfrArray = await this.getManufacturersByRepGroup();
            this.sidebarApiService.mfgData = this.wholeMfrArray;
        }
        this.selectedCustomer = this.config.data.retailer;
        _.forEach(this.wholeMfrArray, (o) => {
            _.forEach(this.manufacturers, (m) => {
                if (o.recordID === m.recordID) {
                    m.minimumOrderAmount = o.minimumOrderAmount;
                    m.minimumReorderAmount = o.minimumReorderAmount;
                    m.isNewRetailer = o.isNewRetailer;
                    if (!m.isNewRetailer) {
                        m.isMinimumMet = (m.discountedTotal >= m.minimumReorderAmount);
                    }
                }
            })
        });
        this.showLoader = false;
        this.cols = [
            { field: 'name', header: 'Name', width: '150px',spinner: true },
            { field: 'quantity', header: 'Line item', width: '120px', spinner: true },
            { field: 'totalQty', header: 'Qty. Of items', width: '120px', spinner: true },
            { field: 'minimumOrderAmount', header: 'Min. Amt.($)', width: '120px', spinner: true, class: 'price' },
            { field: 'discountedTotal', header: 'Total($)', width: '150px', spinner: true, class: 'price' }
        ];
        this.selectAll(true);
    }
    verifyCheckout(type) {
        if (this.selectedCustomer !== undefined &&
            (this.selectedCustomer.federalTaxNumber === null || this.selectedCustomer.federalTaxNumber === undefined || this.selectedCustomer.federalTaxNumber === '')) {
                if (this.auth.getEINNumber() && this.auth.getEINPlace() && this.auth.getEINPlace() === 'checkout') {
                    this.showEinAlert = true;
                } else {
                    this.close(type);
                }
        } else {
            this.close(type);
        }
    }
    // [B2B-92] (MG 26-FEB-2020) modified to warn on min order
    close(type) {
        const selectedItems = [];
        _.forEach(this.manufacturers, (o) => {
            const index = _.findIndex(this.selectedValues, (e) => e === o.recordID);
            if (index !== -1) {
                _.forEach(o.items, (p) => {
                    selectedItems.push(p.recordID);
                });
            }
        });
        if (selectedItems.length === 0) {
            this.msgService.clear();
            this.msgService.add({
                key: 'warnItem',
                severity: 'error',
                summary: 'Alert',
                detail: `Please select at least one manufacturer to checkout`
            });
            this.isLoading = false;
        } else {
            if (type !== undefined) {
                if (type === 'shop') {
                    this.ref.close(false);
                } else if (type === 'checkout') {
                    this.checkout(selectedItems);
                }
            }
        }
    }
    closeFederalTaxDialog($event) {
        if ($event) {
            this.ref.close(false);
        } else {
            this.showEinAlert = false;
            this.close('checkout');
        }
    }
    // [RC-971] (MK 25-SEPT-2020) error handling
    async checkout(selectedItems) {
        this.isLoading = true;
        await this.basketItemService.checkoutBasketItem(this.selectedCustomer, selectedItems).then((value) => {
            this.getCartData(this.selectedCustomer);
            this.goToFinalizePage(value);
        }).catch((err) => {
            this.msgService.clear();
            if (err.status === 500) {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: err.error.error.message });
            } else {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
            }
            this.isLoading = false;
            this.ref.close(false);
        });
        // const checkoutData = await this.basketItemService.checkoutBasketItem(this.selectedCustomer, selectedItems).catch((err) => {
        //     this.msgService.clear();
        //     this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        //     this.isLoading = false;
        //     this.ref.close(false);
        // });
        // this.goToFinalizePage(checkoutData);
    }

    goToFinalizePage(checkoutData) {
        this.isLoading = false;
        this.ref.close(checkoutData);
    }

    // B2B-322 (CB 14-09-2020): Call on single manunfacturer selection change
    signleSelectChange() {
        if (this.selectedValues.length === this.manufacturers.length) {
            this.allSelected = ['allSelect'];
        } else {
            this.allSelected = [];
        }
    }

    // B2B-322 (CB 14-09-2020): Call when press select all checkbox
    selectAll(event) {
        this.selectedValues = [];
        if (event === true) {
            this.allSelected = ['allSelect'];
            _.forEach(this.manufacturers, (o) => {
                if (o.discountedTotal) {
                    this.selectedValues.push(o.recordID);
                }
            });
        }

    }

    async getCartData(customer) {
        const cartData = await this.basketItemService.getBasketItemData(customer);
        this.auth.setCartItemCount(cartData.totalItems);
    }

    async getManufacturersByRepGroup() {
        return new Promise((resolve, reject) => {
            this.manufacturerService.getManufacturersByRepGroup().subscribe((res) => {
                if (this.rtSharedService.getProjectName() === 'B2B') {
                    this.wholeMfrArray = res.data.filter(o => {
                        if (o.repGroupManufacturers && o.repGroupManufacturers.length) {
                            if (o.repGroupManufacturers[0].showOnWebsite === true) {
                                return o;
                            }
                        }
                    });
                    resolve(this.wholeMfrArray);
                } else {
                    this.wholeMfrArray = res.data;
                    resolve(res.data);
                }
            });
        });
    }
}
