import { Component, HostBinding, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { ConfirmationService, DialogService, MessageService, SelectItem, MenuItem, DynamicDialogConfig } from 'primeng/api';

import * as _ from 'lodash';
import * as objectPath from 'object-path';
import * as _moment from 'moment';
import $ from 'jquery';

const moment = _moment;

import { OrderService } from '../../../services/middleware/order/order.service';
import { Order } from '../../../models/order';
import { TerritoryStatusPayload } from '../../../models/territory-status-payload';
import { OrderDetail } from '../../../models/order-detail';
import { DataAPIService } from '../../../services/api/data-api/data-api.service';
import { RepgroupService } from '../../../services/api/repgroup.service';
import { RepGroupOrderCode } from '../../../models/repgroup-order-code';
import { ManufacturerService } from '../../../services/api/manufacturer.service';
import { ManufacturerCarrierType } from '../../../models/manufacturer-carrier-type';
import { ManufacturerPaymentTerm } from '../../../models/manufacturer-payment-term';
import { CarrierType } from '../../../models/carrier-type';
import { PaymentTerm } from '../../../models/payment-term';
import { OrderCode } from '../../../models/order-code';
import { BasketItemService } from '../../../services/middleware/basket-item/basket-item.service';
import { EditShipToDialogComponent } from '../../../modals/edit-ship-to-dialog/edit-ship-to-dialog.component';
import { EditBillToDialogComponent } from '../../../modals/edit-bill-to-dialog/edit-bill-to-dialog.component';
import { EditContactDialogComponent } from '../../../modals/edit-contact-dialog/edit-contact-dialog.component';
import { SelectContactDialogComponent } from '../../../modals/select-contact-dialog/select-contact-dialog.component';
import { ManufacturerPromotion } from '../../../models/manufacturer-promotion';
import { ItemsListModalComponent } from '../../../modals/items-list-modal/items-list-modal.component';
import { ISCDialogComponent } from '../../../modals/isc-dialog/isc-dialog.component';
import { Salesperson } from '../../../models/salesperson';
import { OrderAPIService } from '../../../services/api/order-api/order-api.service';
import { RTSharedService } from '../../../services/rtshared/rtshared.service';
import { OrderPromotion } from '../../../models/order-promotion';
import { OrderSuccessDialogComponent } from '../../../modals/order-success-dialog/order-success-dialog.component';
import { ReptimeEntity } from '../../../models/reptime-entity';
import { from, Subject, Subscription } from 'rxjs';
import { ConditionalExpr } from '@angular/compiler';
// import {Constant} from '../../../constant';
import { ItemsAPIService } from '../../../services/api/items-api/items-api.service';
import { ok } from 'assert';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';
import { CarrierAccount } from '../../../models/carrier-account';
import { RetailerContact } from 'src/app/models/retailerContact';
import { APIResponse } from '../../../models/api-response';
import { RetailerShipToLocationTableComponent } from 'src/app/components/common/retailer-ship-to-location-table/retailer-ship-to-location-table.component';
import { async } from '@angular/core/testing';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RtDatePipe } from '../../../pipes/rt-date.pipe';
import { IntegrationService } from '../../../services/api/integration.service';
import { HtmlToolsService } from '../../../services/other/html-tools/html-tools.service';
import { luhnCheck } from 'src/app/directives/verify-card-number.directive';
import { DomSanitizer } from '@angular/platform-browser';
import { Dropdown } from 'primeng/primeng';
import { RetailerPaymentInfo } from '../../../models/retailer-payment-info';
import { RetailerAPIService } from '../../../services/api/retailer-api/retailer-api.service';
import { OverlayPanel } from 'primeng/primeng';
// declare function launchAR();
@Component({
    selector: 'rs-finalize-order',
    templateUrl: './finalize-order.component.html',
    styleUrls: ['./finalize-order.component.scss']
})

export class FinalizeOrderComponent implements OnInit, OnDestroy {
    win: any = (window);
    public domainData: any = this.win.domainData;
    selectedCarrierAccount = new CarrierAccount();
    orderTypeOptions = Order.ORDER_TYPE_OPTIONS;
    // orderCodeErrorMsg = Constant.NO_REP_GROUP_ORDER_CODES;
    // paymentTermErrorMsg = Constant.NO_MANUFACTURER_PAYMENT_TERMS;
    // shipViaErrorMsg = Constant.NO_MANUFACTURER_CARRIER_TYPES;
    readonly re = ReptimeEntity;
    orderCodeOptions: SelectItem[];
    shippingMethodOptions: SelectItem[];
    @Inject(LOCALE_ID) public locale: string;
    public orderArr: any = [];
    public orderArrForHistory: any = [];
    activeIndex = 0;
    displayPaymentInfoDialog = false;
    displayCarrierAccountDialog = false;
    selectedID: string;
    projectName: string;
    repGroupID: string;
    salespersonID: string;
    isNoMfgEmail: boolean;
    headerHeight: any;
    stickyTop;

    /* TODO: remove */
    changesMade = 0;
    promotionCols: any[];
    displayDiscountPercentDialog = false;
    displaySpecialsAvailableDialog: boolean;
    // displayError: boolean;
    // displayOrderCodeError: boolean;
    // displayPaymentTermError: boolean;
    // displayShipViaError: boolean;
    availablePromotions: ManufacturerPromotion[];
    salespersonToEmail: Salesperson = new Salesperson();
    displayEmailDialog = false;
    urlPrefix = '';
    promotionsOnOrder: OrderPromotion[] = [];
    emptyPromotionMsg = 'No promotion found.'; // RC-459 (MG 16 JAN 2020): added for promotion row click & row loader
    promotionLoader: boolean[];
    promotionCurrentLoaderIndex = -1;
    territoryStatusData;
    public territoryStatusPayloadObj: TerritoryStatusPayload;
    public hasOOTCustomer: boolean;
    items: MenuItem[];
    basketSyncLoader = false;
    suggestItemList: SelectItem[];
    suggestedItemsArr: any[] = [];
    suggestedItemsMasterArr: any[] = [];
    suggestedItemsCount = 0;
    editable = true;
    shipObjMatch: any;
    params: any;
    isMinOrderRequired: any;
    // B2B-110 (NL 29-FEB-2020) add shimmer effect variable and array.
    checkoutListShimmer = true;
    suggestListShimmer = true;
    checkoutShimmerCnt = ['1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1'];
    suggestShimmerCnt = ['1', '1', '1', '1', '1', '1', '1', '1'];

    // [B2B-95] item view model pass variable
    public activeViewItem: any;
    public activeViewIndex: any;
    public displayItemViewDialog: boolean;
    public itemMfgIDs: any;
    public manufacturersArr: any;
    public fromPage = 'FINALIZE_PAGE';
    public selectedManufacturer: any;
    public selectedCustomer: any;
    public existCartData: any;
    isDisabledTableRow = false;
    isEditableNotSave = false;
    oldPaymentTerm = '';
    oldShippingMethod = '';

    displayOrderDuplicate = false;
    selectedOrderToDuplicate: any;
    selectedOrderToDuplicatePoNumber = '';
    saveLocationId: any;
    public salesPersonLoading = false;

    orderDuplicateForm: FormGroup;
    newOrderDate = new Date();
    newShipDate = new Date();
    newCancelDate = new Date();
    isValidateDuplicate = false;
    isSavingData = false;
    qpdata;
    loadingSave = false;

    private readonly destroyPromotiondata = new Subject<void>();

    public logoBucketUrl = environment.logoBucketUrl;
    logoPath = this.logoBucketUrl + 'logo/{companyID}.png';
    orderChangesHistory = [];
    orderCan = 'delete'; // delete || cancel
    visibleprintpreview = false;
    itemwithimage = false;
    pdfSrc: any;
    sortorder = { sort: 'sequenceID', order: 'DESC' };
    validationArrayForCC = [];

    mtPayMFRServiceEnableOrNot = false;
    mtPayRetailerServiceEnableOrNot = false;
    mtPayEditableItemsDetails = true;

    mtPayAvilableForRetailer = false;
    
    newMTPayRetailerQualifyStatus = '';
    newMTPaygetBuyerRegisterData: any = {};
    newMTPayPaymentTermDDModel: any;
    newMTPayPaymentTermDropDownArray = [];
    newMTPayBuyerRegisterApprovedOrNot = false;
    newMTPayPaymentMethods = [];
    newMTPayPaymentMethodsPopup = false;
    newMTPaySelectedPaymentMethods: any;
    newMTPayCreditCardDetails = [];
    newMTPayCreditCardDetailsSpinner = false;
    newMTPayBankDetails = [];
    newMTPaygetUserIntegrationData: any;
    newMTPaySavedTransactionArray = [];
    showGetBalancePopup = false;
    newMTPayretailercompanyIntegrations: any;
    newMTPayretaileravilablecl = 0;
    newMTPayretailerStepFrom = 0;
    currencySymbol = environment.currencySymbol;

    public fbCreditCard: FormGroup;

    monthOptions = [];
    yearOptions = [];
    creditCardApplyloading = false;
    bankDetailApplyloading = false;
    seletedCreditCard: any = {};
    selectedBankDetailModel: any;
    appliedBankDetail: any;
    applyOrSaveBtnLabel = 'Save & Apply';
    finalSelectedCard: any = {};
    clickOnAddNewCard = false;

    // saveTxnIdAfterSelectCard  = '';
    // loadCallTransactionFinance = false;
    loadCallTransactionAPI = false;
    private readonly onDestroyCompanyIntegration = new Subject<void>();
    private readonly onDestroygetBuyerDetails = new Subject<void>();
    private readonly onDestroyQualificationApproval = new Subject<void>();
    private readonly onDestroyTransaction = new Subject<void>();
    private readonly onDestroyTransactionUpdate = new Subject<void>();
    private readonly onDestroySavePaymentDetails = new Subject<void>();
    private readonly onDestroyGetPaymentDetails = new Subject<void>();
    private readonly onDestroyTransactionFinance = new Subject<void>();
    private readonly onDestroygetBuyerCreditLimit = new Subject<void>();
    private readonly onDestroygetBuyerBank = new Subject<void>();
    mtpayBuyeremailcCheckLoading: boolean;
    private readonly onDestroyIntegrationService = new Subject<void>();
    mtpaygetIntegrationData: any;
    mtpaylinkBankIframeSrc: any;
    mtpayShowBankConnectionLoading: boolean;
    mtpayShowBankDataFetching: boolean;
    mtpayShowBankConnectionDialog: boolean;
    retailerPaymentTerms: SelectItem[] = [];
    selectedRetailerTerm: RetailerPaymentInfo;
    selectedPaymentType = null;
    private readonly destroyRetailerTerms = new Subject<void>();
    cardPresent = true;
    cardInfoView = false;
    mtPayLoading = false;
    errorMessageService: any = {};
    public getRetailerDataSubscription: Subscription;
    public retailerObj: any;
    getApprovedForNetTermsButton = false;
    enabledmtPayService: boolean;
    mtPayPaymentDeclined: any;
    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        public config: DynamicDialogConfig,
        private basketItemService: BasketItemService,
        public auth: AuthService,
        private fb: FormBuilder,
        private location: Location,
        public dp: DatePipe,
        public router: Router,
        public dialogService: DialogService,
        private confirmationService: ConfirmationService,
        private dataAPIService: DataAPIService,
        private repgroupService: RepgroupService,
        private manufacturerService: ManufacturerService,
        public msgService: MessageService,
        private orderApi: OrderAPIService,
        public orderService: OrderService,
        private itemsAPIService: ItemsAPIService,
        public rtSharedService: RTSharedService,
        private integrationService: IntegrationService,
        private htmlTools: HtmlToolsService,
        private retailerService: RetailerAPIService,
    ) {
        this.salespersonID = this.rtSharedService.getSalesPersonID();

        this.suggestItemList = [
            { label: 'All Suggest Item', value: 'All Suggest Item' },
            { label: 'Suggest Item1', value: 'Suggest Item' },
            { label: 'Suggest Item2', value: 'Suggest Item' },
            { label: 'Suggest Item3', value: 'Suggest Item' },
            { label: 'Suggest Item4', value: 'Suggest Item' },
            { label: 'Suggest Item5', value: 'Suggest Item' }
        ];
    }

    @HostBinding('class.finalize-order')

    /* START:  tab event functions */
    // B2B-151 (NL 09-MAR-2020) add "suggestListShimmer" variaable in  openNext() and openPrev()
    openNext(isManualIndex = false, manualIndex = 0) {
        if (isManualIndex === true) {
            this.activeIndex = manualIndex;
        } else {
            this.activeIndex = (this.activeIndex === this.orderArr.length) ? 0 : this.activeIndex + 1;
        }
        this.onChangeOrderData();
        this.suggestListShimmer = true;
    }

    openPrev() {
        this.activeIndex = (this.activeIndex === 0) ? this.orderArr.length : this.activeIndex - 1;
        this.onChangeOrderData();
        this.suggestListShimmer = true;
    }

    onTabChange(e, i) {
        this.activeIndex = i;
        this.onChangeOrderData();
        this.suggestListShimmer = true;
    }

    // B2B-96 (MJ 25-FEB-2020): View Button in Orders
    // [B2B-95] (RM 27-FEB-2020):
    onChangeOrderData() {
        // [B2B-278] (RK 29-JUN-2020): Retailer can edit only INCOMPLETE order
        console.log('onChangeOrderData>');
        // RC-2101 (RK-21-SEP-2021) mtPay [start]
        this.initmtPay();
        this.enabledmtPayService = this.getmtPayMFRRetailerEnabledSetting(this.orderArr[this.activeIndex]);
        // this.checkMTPayMFRServiceEnableOrNotFn();
        // if (this.mtPayMFRServiceEnableOrNot && this.enabledmtPayService) {
        //     this.checkMTPayRetailerServiceEnableOrNotFn();
        //     this.setPaymentTermDDValue();
        //     this.initmtPayAutoFillData();
        // }
        // RC-2101 (RK-21-SEP-2021) mtPay [end]
        const repGroupOrderStatus = this.orderArr[this.activeIndex].repGroupOrderStatus;
        const manufacturerOrderStatus = this.orderArr[this.activeIndex].manufacturerOrderStatus;
        if ((repGroupOrderStatus === 'INCOMPLETE' || repGroupOrderStatus === 'OPEN' || repGroupOrderStatus === 'QUOTE') && manufacturerOrderStatus === 'NOT TRANSMITTED') {
            this.editable = true;
            this.orderCan = (repGroupOrderStatus === 'INCOMPLETE') ? 'delete' : 'cancel';
        } else {
            this.editable = false;
        }
        // RC-2101 (RK-19-SEP-2021) mtPay Editable Items Details Condition [start]
        /*const mtPayInitiated = this.ismtPayInitiated(this.orderArr[this.activeIndex]);
        console.log('onChangeOrderData>>>', mtPayInitiated, this.orderArr[this.activeIndex]);
        this.mtPayEditableItemsDetails = (mtPayInitiated) ? false : true;*/
        // RC-2101 (RK-19-SEP-2021) mtPay Editable Items Details Condition [end]
        this.rtSharedService.setRepgroupOrderStatus(repGroupOrderStatus === 'INCOMPLETE' ? true : false);
        // const repgroupStatus = Order.REPGROUP_DROPDOWN_STATUSES.findIndex(o => o.label === this.orderArr[this.activeIndex].repGroupOrderStatus);
        // console.log('repgroupStatus', repgroupStatus);
        // if (repgroupStatus <= 5) {
        //     this.editable = true;
        // } else {
        //     this.editable = false;
        // }
        // [B2B-95] (RM 25-FEB-2020): Call function for get suggested items
        this.getRetailerSuggestionsData('onChangeOrderData');
    }

    salespersonSelectedEvent(salesperson) {
        this.orderArr[this.activeIndex].salesperson1 = salesperson;
        this.saveOrder(this.orderArr[this.activeIndex], true);
        // [B2B-95] (RM 25-FEB-2020): Call function for get suggested items
        this.getRetailerSuggestionsData('salespersonSelectedEvent');
    }
    /* END:  tab event functions */

    removeInvalidChars(str) {
        // Remove characters that are invalid for file names
        return str.replace(/([^a-zA-Z0-9\-_]+)/gi, '');
    }

    // RC-538 (MG 22-JAN-2020): modified to fix discount issue
    async ngOnInit() {
        this.getRetailerDataSubscription = this.auth.getRetailerData.subscribe(async (getRetailerRes) => {
            this.retailerObj = getRetailerRes;
            // this.getRepGroupRetailers();
        });
        this.isMinOrderRequired = this.auth.getIsMinOrderRequired();
        this.projectName = this.rtSharedService.getProjectName();

        this.route.queryParams.subscribe(async (qp) => {
            try {
                let orderArr: any;
                this.qpdata = _.cloneDeep(qp);
                // this.territoryStatusPayloadObj: TerritoryStatusPayload;
                // if user salesperson then need to set repGroupID
                if (qp.repGroupID && this.rtSharedService.getEntityType() === 'Salesperson') {
                    this.rtSharedService.setRepGroupID(qp.repGroupID);
                    this.repGroupID = this.rtSharedService.getRepGroupID();
                    this.urlPrefix = this.projectName === 'B2B' ? '' : 'salesperson/';
                    this.territoryStatusPayloadObj = {
                        salespersonID: this.salespersonID,
                        repGroupID: qp.repGroupID,
                        zip: ''
                    };
                } else {
                    this.urlPrefix = this.projectName === 'B2B' ? '' : 'repgroups/';
                    this.territoryStatusPayloadObj = {
                        salespersonID: '',
                        repGroupID: this.rtSharedService.getRepGroupID(),
                        zip: ''
                    };
                }
                if (qp.orderIDs) {
                    const payloadObj = { orderIDs: qp.orderIDs.split(',') };
                    orderArr = await this.orderService.getOrdersData(payloadObj);
                    // update url without deleted record id if browser url with deleted record
                    orderArr = orderArr.filter(o => !o.recordDeleted);
                    orderArr = orderArr.filter(o => o.repGroupOrderStatus !== 'CANCELLED');
                    const ordersIDs = orderArr.map(o => o.recordID);
                    if (qp.repGroupID && this.rtSharedService.getEntityType() === 'Salesperson') {
                        this.location.replaceState(`${this.urlPrefix}finalize-order?orderIDs=${ordersIDs.join()}&repGroupID=${qp.repGroupID}`);
                    } else {
                        this.location.replaceState(`${this.urlPrefix}finalize-order?orderIDs=${ordersIDs.join()}`);
                    }
                } else if (qp.orderDate && qp.retailerShipToLocationID) {
                    const payloadObj = {
                        orderDate: qp.orderDate,
                        retailerShipToLocationID: qp.retailerShipToLocationID
                    };
                    orderArr = await this.orderService.getOrdersData(payloadObj);
                }


                if (orderArr && orderArr.length) {
                    await this.getOrderCode();
                    this.getmtPayIntegrationServices();
                    this.orderArr = await this.setOrderDetailColumns(_.cloneDeep(orderArr));
                    this.orderArr.forEach(element => {
                        this.validationArrayForCC.push({
                            isValidSpecialInstruction: true
                        });
                    });
                    if (this.orderArr.length <= this.activeIndex) {
                        this.activeIndex = 0;
                    }
                    if (this.getOrderSalespeople().length && this.rtSharedService.getEntityType() !== 'Salesperson') {
                        this.territoryStatusPayloadObj.salespersonID =
                            this.getOrderSalespeople()[this.getOrderSalespeople().length - 1].value.recordID;
                    }

                    // tslint:disable-next-line: prefer-for-of
                    for (let orderLoopIndex = 0; orderLoopIndex < this.orderArr.length; orderLoopIndex++) {
                        this.orderArr[orderLoopIndex].orderDiscount = this.orderArr[orderLoopIndex]
                            .orderDiscount ? this.orderArr[orderLoopIndex].orderDiscount : 0;
                        this.orderArr[orderLoopIndex].orderDetails.forEach(orderInner => {
                            orderInner.total = orderInner.unitPrice * orderInner.quantity * (1 - orderInner.itemDiscount / 100);
                        });
                        const x = this.countOrderTotalFromItems(this.orderArr[orderLoopIndex].orderDetails);
                        this.orderArr[orderLoopIndex].orderGrossTotal = x;
                        const orderItems = this.orderArr[orderLoopIndex].orderDetails.filter(o => !o.recordDeleted);
                        if (orderItems && orderItems.length) {
                            this.orderArr[orderLoopIndex].countOrderItems = orderItems.length;
                        } else {
                            this.orderArr[orderLoopIndex].countOrderItems = 0;
                        }
                    }
                    if (qp.selectedID) {
                        this.selectedID = qp.selectedID;
                        setTimeout(() => {
                            const temp = { index: _.cloneDeep(this.orderArr.findIndex(o => o.recordID === parseInt(this.selectedID, 10))) };
                            this.onTabChange(temp, temp.index > 0 ? temp.index : 0);
                        }, 0);
                    }
                    // [B2B-200] (RK 27-MARCH-2020) Init Level call method onChange OrderData()
                    this.onChangeOrderData();
                    if (this.projectName !== 'B2B') {
                        // RC-1346 (RK 06-Nov-2020): Remove OOT message
                        // this.getTerritoryStatusData();
                    }

                    // [B2B-159] (RM 11-MARCH-2020) Added: GET SUGGESTED ITEM LIST AND STORE MASTER ARRAY
                    const payloadObj: any = {
                        repGroupID: this.rtSharedService.getRepGroupID(),
                        retailerID: this.rtSharedService.getRetailerID(),
                    };
                    if (this.auth.getRetailerTerritoryFilterFlag() === true) {
                        payloadObj.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
                    }
                    await this.orderService.getRetailerSuggestionsData(payloadObj)
                        .then(res => {
                            this.suggestListShimmer = false;
                            if (res.success && res.data && res.data.length) {
                                this.suggestedItemsMasterArr = res.data;
                                this.suggestedItemsMasterArr = _.map(_.cloneDeep(res.data), (o: any) => {
                                    o.item.retailerRequestStatus = o.retailerRequestStatus ? o.retailerRequestStatus : 0;
                                    if (!o.showLockedB2bItems && (o.b2bItemPricingPolicy === 'NeedBrandApproval' || o.b2bItemPricingPolicy === 'HideItemPrice')) {
                                        o.item.b2bItemPricingPolicyFlag = true;
                                    } else {
                                        o.item.b2bItemPricingPolicyFlag = false;
                                    }
                                    return o;
                                });
                            } else {
                                this.suggestedItemsMasterArr = [];
                            }
                        }, () => {
                            this.msgService.add({
                                severity: 'error',
                                summary: 'Error',
                                detail: 'An error occurred suggested items ' + this.orderArr[this.activeIndex].recordID
                            });
                            this.basketSyncLoader = false;
                        });
                    console.log(' >>> Suggested Master Array >>>> ', this.suggestedItemsMasterArr);
                    // END

                    // [B2B-95] (RM 25-FEB-2020): Call function for get suggested items
                    this.getRetailerSuggestionsData('ngOnInit');

                    // checkout list boolean
                    this.checkoutListShimmer = false;
                    this.initmtPay();
                } else {
                    this.navigateTo();
                }
                const setRepgroupOrderStatus = this.orderArr[this.activeIndex].repGroupOrderStatus;
                if (setRepgroupOrderStatus === 'INCOMPLETE') {
                    const params = true;
                    this.rtSharedService.setRepgroupOrderStatus(params);
                }

                console.log('REPLACE');
                this.orderArrForHistory = _.cloneDeep(this.orderArr);
                this.orderChangesHistory = [];
            } catch (e) {
                console.log(e);
                if (e && e.error && e.error.message) {
                    if (e.error.message.includes('No order found')) {
                        this.msgService.add({ severity: 'info', summary: 'Info', detail: e.error.message });
                    } else {
                        this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Internal Server Error' });
                    }
                } else {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Internal Server Error' });
                }
                setTimeout(() => {
                    this.navigateTo();
                }, 1000);
            }

            this.loadRetailerTerms();
        });

        this.promotionCols = [
            { field: 'name', header: 'Name', width: '200px', spinner: true },
            { field: 'description', header: 'Description', width: '300px', spinner: true },
            { field: 'startDate', header: 'Start Date', width: '130px', spinner: true },
            { field: 'endDate', header: 'End Date', width: '130px', spinner: true },
            { field: 'amountToMeet', header: 'Amt To Meet', width: '200px', spinner: true },
            { field: 'discount', header: 'Discount', width: '150px' }
        ];

        this.items = [
            {
                label: 'Manufacturer One',
                icon: ''
            },
            {
                label: 'Manufacturer Two',
                icon: ''
            },
            {
                label: 'Manufacturer Three',
                icon: ''
            },
        ];
        this.createDuplicateOrderForm();

        if (this.domainData && this.auth.getProjectType() === 2) {
            this.stickyTop = document.getElementById('lightovation-header');
            this.headerHeight = '80px';
        } else {
            this.stickyTop = document.getElementById('b2bheader');
            this.headerHeight = '123px';
        }
    }

    // RC-481 (RK 06-FEB-2020): Set up getTerritoryStatus payload data
    async getTerritoryStatusData() {
        /* RC-481 (RK 2020-FEB-05) Get retailer data*/
        const retailerShipToData = objectPath.get(this.orderArr, '0.shipToZip');
        this.territoryStatusPayloadObj.zip = retailerShipToData;

        let getentityId = null;
        switch (this.rtSharedService.getEntityType()) {
            case 'Repgroup':
                getentityId = this.rtSharedService.getRepGroupID();
                break;
            case 'Salesperson':
                getentityId = this.rtSharedService.getSalesPersonID();
                break;
            default:
                /*This subject for B2B*/
                getentityId = this.rtSharedService.getRetailerID();
        }
        console.log('territoryStatusPayloadObj:', this.territoryStatusPayloadObj);
        this.territoryStatusData = this.orderService.getTerritoryStatus(this.territoryStatusPayloadObj, getentityId);
        this.territoryStatusData.then((res) => {
            this.hasOOTCustomer = !res.data;
        });
        /* RC-481 (RK 2020-FEB-05) Get retailer data*/
    }

    // RC-538 (MG 22-JAN-2020): added to fix discount issue
    countOrderTotalFromItems(orderDetails) {
        return _.sumBy(orderDetails, (o: any) => {
            return (!o.recordDeleted) ? o.total : 0;
        });
    }

    getOrderRepGroupRetailerManufacturers(order) {
        return _.find(order.retailer.repGroupRetailerManufacturers, (rgrm: any) => {
            return (rgrm.repGroupID === order.repGroup.recordID &&
                rgrm.retailerID === order.retailer.recordID &&
                rgrm.manufacturerID === order.manufacturer.recordID &&
                rgrm.shipToLocationID === order.retailerShipTo.recordID && !rgrm.recordDeleted);
        });
    }

    onAcActive(order, type, index) {
        order[type] = true;
        setTimeout(() => {
            document.getElementById(type + index).focus();
        }, 0);
    }

    getInitOrderCode(orderCode, orderCodeOptions) {
        let code = orderCode;
        const findCode = orderCodeOptions.find(o => o.label === orderCode);
        if (findCode) {
            code = findCode.value;
        }
        return code;
    }

    // [B2B-198] (MG-13-APR-2020): modified to save default order code
    private async setOrderDetailColumns(orderArr) {
        const order = await Promise.all(orderArr.map(async (o: any) => {
            const rgrm = this.getOrderRepGroupRetailerManufacturers(o);
            o.orderDate = o.orderDate ? this.rtSharedService.getStringtToDate(o.orderDate) : '';
            o.cancelDate = o.cancelDate ? this.rtSharedService.getStringtToDate(o.cancelDate) : '';
            o.requestDate = o.requestDate ? this.rtSharedService.getStringtToDate(o.requestDate) : '';
            // o.orderCode = o.orderCode ? this.getInitOrderCode(o.orderCode, this.orderCodeOptions) : this.projectName === 'B2B' ? 'W' : '';
            o.orderCode = o.orderCode ? this.getInitOrderCode(o.orderCode, this.orderCodeOptions) : this.auth.getDefaultOrderCode();
            o.accountNoDeactive = false;
            o.shipAccountNoDeactive = false;
            o.repGroupRetailerManufacturers = rgrm ? rgrm : {};
            o.orderCodeOptions = this.orderCodeOptions;
            o.shippingMethodOptions = await this.getShippingMethod(o);
            o.paymentTermOptions = await this.getPaymentTerms(o);
            o.retailerContact = await this.setOrderContact(o);
            o.mtPayOrderPaymentDetailsJson = _.cloneDeep(this.getmtPayPaymentDetailsStringTerms(o));
            // default
            o.paymentTerm = o.paymentTerm ? o.paymentTerm : (rgrm && rgrm.defaultPaymentTerm) ? rgrm.defaultPaymentTerm : '';
            o.shippingMethod = o.shippingMethod ? o.shippingMethod : (rgrm && rgrm.defaultCarrierType) ? rgrm.defaultCarrierType : '';
            await this.loadOrderDetailInfo(o);
            // o.editLineItemForm = this.createOrderForm(o);
            return o;
        }));
        return Promise.resolve(order);
    }

    private async loadOrderDetailInfo(orderObj: any) {
        orderObj.lineItemCount = 0;
        orderObj.itemQuantityTotal = 0;
        orderObj.orderDetails.forEach((d: OrderDetail) => {
            orderObj.lineItemCount++;
            orderObj.itemQuantityTotal = orderObj.itemQuantityTotal + d.quantity;
        });
        return orderObj;
    }

    private setOrderContact(orderObj: any) {
        let retailerContact = orderObj.retailerContact;
        if (!orderObj.retailerContact) {
            retailerContact = _.find(orderObj.retailer.contacts, o => o.isPrimary);
        }
        return retailerContact;
    }

    async getCart(customer) {
        const cartData = await this.basketItemService.getBasketItemData(customer);
        this.auth.setCartItemCount(cartData.totalItems);
        return cartData;
    }

    getInvoicedAmount() {
        return 0;
    }

    getRepGroupOrderStatuses() {
        const order = this.orderArr[this.activeIndex];
        if (!this.orderFormValidation(order)) {
            order.repGroupOrderStatus = 'INCOMPLETE';
            return Order.REPGROUP_ORDER_DROPDOWN_STATUSES.filter(o => o.label === 'INCOMPLETE');
        } else {
            if (order.repGroupOrderStatus !== 'TRANSMITTED') {
                if (order.repGroupOrderStatus === 'QUOTE') {
                    order.repGroupOrderStatus = 'QUOTE';
                } else {
                    order.repGroupOrderStatus = 'OPEN';
                }

            }
            return Order.REPGROUP_ORDER_DROPDOWN_STATUSES.filter(o => o.label !== 'INCOMPLETE');
        }

    }

    getItemNotes(rowData) {
        return 'Notes: ' + (rowData.itemNotes ? rowData.itemNotes : 'None');
    }

    datesUpdate(order, type) {
        setTimeout(() => {
            switch (type) {
                case 'ORDER_DATE':
                    if (order.orderDate > order.requestDate) {
                        order.requestDate = order.orderDate;
                    }
                    if (order.cancelDate && order.requestDate > order.cancelDate) {
                        order.cancelDate = order.requestDate;
                    }
                    if (!this.checkIfValueChanged(order, 'Order Date', 'orderDate')) {
                        console.log('No changes detected.');
                        return false;
                    }
                    break;
                case 'REQUEST_SHIP_DATE':
                    if (order.cancelDate && order.requestDate > order.cancelDate) {
                        order.cancelDate = order.requestDate;
                    }
                    if (!this.checkIfValueChanged(order, 'Ship Date', 'requestDate')) {
                        console.log('No changes detected.');
                        return false;
                    }
                    break;
            }
            this.updateChangesMade(undefined, type);
        });
    }

    dateConvert(d) {
        return new Date(d);
    }

    // RC-1233 (MK 28-SEPT-2020): check po number
    checkponumber(order, event) {
        console.log('order', order);
        if (order.poNumber === null || order.poNumber.length === 0) {
            order.poNumber = order.oldpoNumber;
            return false;
        }

        if (!this.checkIfValueChanged(order, 'PO#', 'poNumber')) {
            console.log('No changes detected.');
            return false;
        }

        this.manufacturerService.CheckValidpPoNumber(order.manufacturer.recordID, order.poNumber, order.recordID).subscribe((res) => {
            if (res === true) {
                this.confirmationService.confirm({
                    message: 'PO number already exists.<br>Are you sure you want to save this PO number?',
                    header: 'Duplicate Order',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Yes',
                    rejectLabel: 'No',
                    accept: async () => {
                        this.updateChangesMade(event, 'PONumber');
                    },
                    reject: () => {

                    }
                });
            } else {
                this.updateChangesMade(event, 'PONumber');
            }
            console.log(res);
        }, error => {
            this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Failed To Check PO Number' });
        });
    }

    checkIfValueChanged(order, updateField, updateKey, objPath?) {
        let changesDetected = true;
        const pos = this.orderArrForHistory.findIndex(r => r.recordID === order.recordID);
        if (pos > -1) {
            console.log('checkIfValueChanged old', this.orderArrForHistory[pos][updateKey], typeof this.orderArrForHistory[pos][updateKey]);
            console.log('checkIfValueChanged new', order[updateKey], typeof order[updateKey]);

            let oldValue = (objPath) ? _.get(this.orderArrForHistory[pos], objPath) : this.orderArrForHistory[pos][updateKey];
            let newValue = (objPath) ? _.get(order, objPath) : order[updateKey];
            oldValue = (oldValue !== null && oldValue !== undefined && typeof oldValue !== 'number' && typeof oldValue !== 'string' &&
                typeof oldValue !== 'boolean') ? _moment(oldValue).format('YYYY-MM-DD') : oldValue;
            newValue = (newValue !== null && newValue !== undefined && typeof newValue !== 'number' && typeof newValue !== 'string' &&
                typeof newValue !== 'boolean') ? _moment(newValue).format('YYYY-MM-DD') : newValue;
            console.log('---', oldValue, newValue);

            if (oldValue !== newValue) {
                const changes = {
                    fieldName: updateField,
                    oldValue: (oldValue === undefined || oldValue === null) ? '' : oldValue,
                    newValue: (newValue === undefined || newValue === null) ? '' : newValue,
                };
                console.log('changes', changes);
                this.orderChangesHistory.push(changes);
                changesDetected = true;
                console.log('orderChangesHistory', this.orderChangesHistory);
            } else {
                changesDetected = false;
            }
        }
        console.log('changesDetected', changesDetected);
        return changesDetected;
    }

    // RC-455 (MG 20-JAN-2020): modified to auto-select & save order-code & ship-method
    // RC-455 (MG 21-JAN-2020): modified to toast applied on all
    // RC-1179 (MK 08 AUG 2020): single time api call
    updateChangesMade($event?, type?) {
        const promisArr = [];
        this.changesMade++;
        // RC-690 (SG 20-MAR-2020): add notes and special instruction condition
        if (type === 'defaultEnable') {
            this.isEditableNotSave = true;
        }

        setTimeout(async () => {

            if (type === 'ORDER_CODE') {
                if (!this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Order Code', 'orderCode')) {
                    console.log('No changes detected.');
                    return false;
                }
            } else if (type === 'acceptBackOrder') {
                if (!this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Accept Back Orders', 'acceptBackOrder')) {
                    console.log('No changes detected.');
                    return false;
                }
            } else if (type === 'attachDocuments') {
                if (!this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Attach Credit App', 'attachDocuments')) {
                    console.log('No changes detected.');
                    return false;
                }
            } else if (type === 'defaultEnable') {
                if ((!this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Internal Notes', 'notes')) &&
                    (!this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Special Instructions', 'specialInstructions'))) {
                    console.log('No changes detected.');
                    return false;
                }
            }

            if (this.orderArr[this.activeIndex].dateAdded) {
                this.orderArr[this.activeIndex]['dateAddedString'] = this.rtSharedService.getStringDateFormat(this.orderArr[this.activeIndex].dateAdded);
            }
            if (this.orderArr[this.activeIndex].dateModified) {
                this.orderArr[this.activeIndex]['dateModifiedString'] = this.rtSharedService.getStringDateFormat(this.orderArr[this.activeIndex].dateModified);
            }

            await this.saveOrder(this.orderArr[this.activeIndex], true);
            if (type === 'ORDER_DATE' && this.qpdata.orderDate !== undefined) {
                const ids = this.orderArr.map(o => o.recordID).toString();
                location.replace(this.router.createUrlTree(['/finalize-order'], { queryParams: { orderIDs: ids } }).toString());
                return false;
            }
            this.orderArr.forEach(o => {
                if (type === 'ORDER_CODE') {
                    if (!o.orderCode) {
                        o.orderCode = this.orderArr[this.activeIndex].orderCode;
                        // this.saveOrder(o, true);
                        promisArr.push(o);
                    }
                } else if (type === 'SHIP_METHOD') {
                    if (!o.shippingMethod) {
                        // filter & check if option selected by MFR is available for other also, if so save
                        const filteredAvailableOptions = o.shippingMethodOptions
                            .filter(f => f.value.shippingMethod === this.orderArr[this.activeIndex].shippingMethod);
                        if (filteredAvailableOptions.length > 0) {
                            o.shippingMethod = this.orderArr[this.activeIndex].shippingMethod;
                            // this.saveOrder(o, true);
                            promisArr.push(o);
                        }
                    }
                }
            });

            console.log('REPLACE');
            this.orderArrForHistory = _.cloneDeep(this.orderArr);
            this.orderChangesHistory = [];

            if (promisArr.length > 0) {
                this.updateStatusPromise(promisArr);
            }
        });
    }

    // RC-455 (MG 21-JAN-2020): added to toast applied on all
    async updateStatusPromise(promisArr) {
        for (const iterator of promisArr) {
            const orderObj = this.beforeSavePreparation(_.clone(iterator));
            const saveObj: any = await this.orderService.saveOrderData(orderObj, this.mtPayPaymentDeclined);
            console.log('end', saveObj);
        }
        console.log('complete end now toast');
        setTimeout(() => {
            this.msgService.add({
                severity: 'success',
                summary: 'Order Saved',
                detail: 'Selected options applied to each order.'
            });
        }, 500);
    }

    updateAccountNumber(type) {
        const order: any = this.orderArr[this.activeIndex];
        if (type === 'BILL_TO') {
            if (order.preAccountNumber !== order.repGroupRetailerManufacturers.accountNumber) {
                this.updateChangesMade();
            }
            const element: any = document.getElementById('accountNumber' + this.activeIndex);
            if (order.repGroupRetailerManufacturers.accountNumber && order.repGroupRetailerManufacturers.accountNumber.trim() && element) {
                element.classList.remove('ng-invalid');
            }
        } else {
            if (order.preShipToAccountNumber !== order.repGroupRetailerManufacturers.shipToAccountNumber) {
                this.updateChangesMade();
            }
            const element: any = document.getElementById('shipToAccountNumber' + this.activeIndex);
            if (order.repGroupRetailerManufacturers.accountNumber && order.repGroupRetailerManufacturers.accountNumber.trim() && element) {
                element.classList.remove('ng-invalid');
            }
        }
    }

    private async getShippingMethod(orderObj) {
        return new Promise(async (resolve, reject) => {
            if (orderObj.manufacturer) {
                this.manufacturerService.getManufacturerCarrierTypes(orderObj.manufacturer.recordID)
                    .subscribe(async (data: ManufacturerCarrierType[]) => {
                        const shippingMethodOptions = [];
                        if (data && data.length > 0) {
                            data.forEach(ct => {
                                shippingMethodOptions.push({ label: ct.shippingMethod, value: ct });
                            });
                            resolve(shippingMethodOptions);
                        } else {
                            // const shippingMethodOptions = await this.loadDefaultCarrierTypes();
                            // this.displayShipViaError = true;
                            resolve(shippingMethodOptions);
                        }
                    });
            } else {
                // const shippingMethodOptions = await this.loadDefaultCarrierTypes();
                const shippingMethodOptions = [];
                resolve(shippingMethodOptions);
            }
        });
    }

    private async getPaymentTerms(orderObj) {
        return new Promise(async (resolve, reject) => {
            const paymentTermOptions = [];
            if (orderObj.manufacturer) {
                this.manufacturerService.getManufacturerPaymentTerms(orderObj.manufacturer.recordID)
                    .subscribe(async (data: ManufacturerPaymentTerm[]) => {
                        if (data && data.length > 0) {
                            // const paymentTermOptions = [];
                            const ismtPayManufacturer = this.getmtPayVendorId(orderObj);
                            data.forEach(pt => {
                                if (pt && pt['paymentTerm'] && pt['paymentTerm'].toString() === 'mtPay' && ismtPayManufacturer) {
                                    pt['disabled'] = true;
                                    paymentTermOptions.push({ label: pt.paymentTerm, value: pt });
                                } else {
                                    paymentTermOptions.push({ label: pt.paymentTerm, value: pt });
                                }
                            });
                            resolve(paymentTermOptions);
                        } else {
                            // const paymentTermOptions = await this.loadDefaultTerms();
                            // this.displayPaymentTermError = true;
                            resolve(paymentTermOptions);
                        }
                    });
            } else {
                // const paymentTermOptions = await this.loadDefaultTerms();
                resolve(paymentTermOptions);
            }
        });
    }

    private async getOrderCode() {
        return new Promise<void>((resolve, reject) => {
            this.repgroupService.getRepGroupOrderCodes()
                .subscribe(async (data: RepGroupOrderCode[]) => {
                    this.orderCodeOptions = [];
                    if (data && data.length > 0) {
                        data.forEach((oc: any) => {
                            this.orderCodeOptions.push({ label: oc.name, value: oc.abbreviation });
                        });
                        resolve();
                    } else {
                        // await this.loadDefaultOrderCodes();
                        // this.displayOrderCodeError = true;
                        resolve();
                    }
                });
        });
    }

    private async loadDefaultOrderCodes() {
        return new Promise<void>((resolve, reject) => {
            this.dataAPIService.getDefaultOrderCodes()
                .subscribe((data: OrderCode[]) => {
                    if (data) {
                        this.orderCodeOptions = [];
                        data.forEach(oc => {
                            this.orderCodeOptions.push({ label: oc.name, value: oc.abbreviation });
                        });
                        resolve();
                    }
                });
        });
    }

    private async loadDefaultCarrierTypes() {
        return new Promise((resolve, reject) => {
            this.dataAPIService.getDefaultCarrierTypes()
                .subscribe((data: CarrierType[]) => {
                    if (data) {
                        const shippingMethodOptions = [];
                        data.forEach(ct => {
                            shippingMethodOptions.push({ label: ct.shippingMethod, value: ct });
                        });
                        resolve(shippingMethodOptions);
                    }
                });
        });
    }

    private async loadDefaultTerms() {
        return new Promise((resolve, reject) => {
            this.dataAPIService.getDefaultPaymentTerms()
                .subscribe((data: PaymentTerm[]) => {
                    if (data) {
                        const paymentTermOptions = [];
                        data.forEach(pt => {
                            paymentTermOptions.push({ label: pt.name, value: pt });
                        });
                        resolve(paymentTermOptions);
                    }
                });
        });
    }

    orderFormValidation(orderObj, checkValidation?: boolean) {
        const selectedmanufacturers = orderObj.manufacturer;
        const requiredFields = ['orderDate', 'requestDate', 'shippingMethod', 'retailerContact'];
        if (this.projectName !== 'B2B') {
            requiredFields.push('orderCode');
        }
        const showAccNumberAlert = this.getShowAccNumberAlertValue(selectedmanufacturers, this.rtSharedService.getRepGroupID());
        console.log('orderFormValidation: >>>', orderObj.newAccountToManufacturer, showAccNumberAlert);
        if (selectedmanufacturers.requireCustomerNumber && !orderObj.newAccountToManufacturer && showAccNumberAlert) {
            requiredFields.push('accountNumber');
        }
        if (selectedmanufacturers.requireShipNumber && !orderObj.newAccountToManufacturer && showAccNumberAlert) {
            requiredFields.push('shipToAccountNumber');
        }
        if (this.mtPayMFRServiceEnableOrNot && this.enabledmtPayService && (!this.orderArr[this.activeIndex].paymentTerm || this.orderArr[this.activeIndex].paymentTerm === 'mtPay')) {
            requiredFields.push('paymentMethod');
            if (!this.getApprovedForNetTermsButton) {
                requiredFields.push('paymentTerms');
            }
        }
        if ((!this.mtPayMFRServiceEnableOrNot || !this.enabledmtPayService) || (this.orderArr[this.activeIndex].paymentTerm && this.orderArr[this.activeIndex].paymentTerm !== 'mtPay')) {
            requiredFields.push('paymentTerm');
        }
        const temp = requiredFields.map(o => {
            const element = document.getElementById(o + this.activeIndex);
            let checkData = (o === 'accountNumber' || o === 'shipToAccountNumber') ? (orderObj.repGroupRetailerManufacturers[o] ?
                orderObj.repGroupRetailerManufacturers[o].trim() : orderObj.repGroupRetailerManufacturers[o]) : orderObj[o];
            if (o === 'paymentMethod' || o === 'paymentTerms') {
                let getmtPayOPDetails = this.getmtPayPaymentDetails(this.orderArr[this.activeIndex]);
                checkData = getmtPayOPDetails ? getmtPayOPDetails[o] : null;
                if (o === 'paymentTerms' && getmtPayOPDetails !== null && _.isNumber(getmtPayOPDetails[o])) {
                    checkData = getmtPayOPDetails[o].toString();
                }
            }
            if (!checkData) {
                // console.log('!checkData', checkData, orderObj, element);
                // if (!orderObj[o]) {
                if (checkValidation) {
                    element.classList.add('ng-invalid');
                    element.classList.add('ng-dirty');
                    element.classList.add('ng-touched');
                }
                return false;
            } else {
                if (checkValidation) {
                    element.classList.remove('ng-invalid');
                }
                return true;
            }
        });
        return !temp.includes(false);
    }

    async transmitOrder(orderObj: any, noNext?: boolean, checkValidation?: boolean, isFinish?: boolean) {
        const emailListStr = orderObj.manufacturer.emailForOrders ? orderObj.manufacturer.emailForOrders : orderObj.manufacturer.email;
        if (!emailListStr) {
            this.isNoMfgEmail = true;
        } else {
            let message = 'Are you sure you want to transmit this order?';
            if (!orderObj.salesperson1) {
                message = '<b>This order has no Salesperson Assigned</b>,<br>' + message;
            }
            if (orderObj.repGroupOrderStatus === 'QUOTE') {
                message = 'This Order status is "QUOTE". <br>' + message;
            }
            message = message + '<br/> <br/>Order email sent to following manufacturers: <br/>';
            message = message + `<b>${emailListStr.replace(/,/g, '<br>')}</b>`;

            this.confirmationService.confirm({
                message,
                header: !orderObj.salesperson1 ? 'Transmit Order: There is no Salesperson' : 'Transmit Order',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: async () => {
                    const orderData = _.cloneDeep(orderObj);
                    if (orderObj.repGroupOrderStatus === 'TRANSMITTED') {
                        await this.saveOrder(orderData, noNext, checkValidation, isFinish);
                        await this.orderService.orderReTransmit(orderData.recordID);
                    } else {
                        orderData.repGroupOrderStatus = 'TRANSMITTED';
                        orderData.manufacturerOrderStatus = 'OPEN';
                        const saveObj = await this.saveOrder(orderData, noNext, checkValidation, isFinish);
                        if (saveObj) {
                            await this.orderApi.transmitOrder(orderData.recordID);
                        }
                    }
                },
                reject: () => {
                }
            });
        }
    }

    // RC-455 (MG 21-JAN-2020): added to toast applied on all
    beforeSavePreparation(orderData: any, noNext?: boolean, checkValidation?: boolean, isFinish?: boolean) {
        console.log('b4 beforeSavePreparation', orderData);
        this.oldPaymentTerm = this.orderArr[this.activeIndex].paymentTerm;
        this.oldShippingMethod = this.orderArr[this.activeIndex].shippingMethod;
        const omitFields = [
            'itemTable',
            'orderCodeOptions',
            'shippingMethodOptions',
            'paymentTermOptions',
            'accountNoDeactive',
            'shipAccountNoDeactive',

        ];
        orderData = _.omit(_.cloneDeep(orderData), omitFields);
        orderData.itemID = null;
        orderData.listPrice = null;
        orderData.orderType = 'Line Item';
        orderData.orderDate = orderData.orderDate ? moment(orderData.orderDate).format('MM-DD-YYYY') : '';
        orderData.requestDate = orderData.requestDate ? moment(orderData.requestDate).format('MM-DD-YYYY') : '';
        orderData.cancelDate = orderData.cancelDate ? moment(orderData.cancelDate).format('MM-DD-YYYY') : '';
        orderData.orderDetails = orderData.orderDetails.filter(o => !o.recordDeleted);
        console.log('b4 return', orderData);
        return orderData;
    }

    // RC-459 (MG 16 JAN 2020): added for promotion row click & row loader
    // RC-455 (MG 21-JAN-2020): modified to toast applied on all
    // B2B-94 (NL 17-FEB-2020): Add confirmation dialog
    // RC-653 (MG 04-MAR-2020): modified to fix updation of order status
    async saveOrder(orderObj: any, noNext?: boolean, checkValidation?: boolean, isFinish?: boolean) {
        // console.log('Confirmation', noNext, checkValidation, isFinish);
        // if (isFinish) {
        //     this.confirmationService.confirm({
        // tslint:disable-next-line:max-line-length
        //         message: 'Are you sure you are ready to finalize? Once you select "Yes" your orders will be sent to your assigned salesperson.',
        //         header: 'Confirmation',
        //         icon: 'pi pi-exclamation-triangle',
        //         accept: async () => {
        //         },
        //         reject: () => {
        //         }
        //     });
        // }

        // RC-690 (SG 20-MAR-2020): add second condition for notes and special instruction
        if (orderObj.retailerCarrierAccount && JSON.stringify(orderObj.retailerCarrierAccount) !== '{}') {
            orderObj.retailerCarrierAccountID = _.cloneDeep(orderObj.retailerCarrierAccount.recordID);
            orderObj.retailerCarrierAccount = null;
        }
        console.log('Rahul....editable', this.editable, this.isEditableNotSave);
        if (this.editable || this.isEditableNotSave || this.mtPayPaymentDeclined) {
            let orderData = _.clone(orderObj);
            if (!this.orderFormValidation(orderData, checkValidation)) {
                if (checkValidation) {
                    console.log('Alert....saveOrder', orderData);
                    const selectedmanufacturers = orderData.manufacturer;
                    const showAccNumberAlert = this.getShowAccNumberAlertValue(selectedmanufacturers, this.rtSharedService.getRepGroupID());
                    console.log('saveOrder: >>>', orderObj.newAccountToManufacturer, showAccNumberAlert);
                    if (((selectedmanufacturers.requireShipNumber && !orderData.repGroupRetailerManufacturers.shipToAccountNumber) ||
                        (selectedmanufacturers.requireCustomerNumber && !orderData.repGroupRetailerManufacturers.accountNumber))
                        && !orderObj.newAccountToManufacturer && showAccNumberAlert) {
                        await this.showAccNumberAlertDialog();
                    }
                    this.msgService.add({
                        severity: 'error',
                        summary: 'Required fields',
                        detail: 'Please fill all required fields'
                    });
                    orderData.repGroupOrderStatus = this.orderArr[this.activeIndex].repGroupOrderStatus;
                    orderData.menufaturerOrderStatus = this.orderArr[this.activeIndex].manufacturerOrderStatus;
                    return;
                }
            } else {
                // const mtPayTxnId = this.getmtPayOpenTxnId(this.orderArr[this.activeIndex]);
                const mtPayInitiated = this.ismtPayInitiated(this.orderArr[this.activeIndex]);
                console.log('Rahul....121212', this.mtPayMFRServiceEnableOrNot, mtPayInitiated);
                if (this.getApprovedForNetTermsButton) {
                    this.msgService.add({ severity: 'error', summary: 'Payment Terms Required', detail: 'Get Approved for Net Terms' });
                    return;
                }
                if (this.mtPayMFRServiceEnableOrNot && this.enabledmtPayService) {
                    // const x = await this.getmtPayBuyerCreditLimit();
                    const orderTotal = this.orderArr[this.activeIndex].orderTotal;
                    const mtPayOrderPaymentDetailsString =  this.getmtPayPaymentDetails(this.orderArr[this.activeIndex]);
                    console.log('final >>', this.newMTPayretaileravilablecl, orderTotal, mtPayOrderPaymentDetailsString['isFinanced']);
                    if (this.newMTPayretaileravilablecl !== null && this.newMTPayretaileravilablecl !== undefined && (this.newMTPayretaileravilablecl <= orderTotal)
                        && (mtPayOrderPaymentDetailsString && mtPayOrderPaymentDetailsString['isFinanced'])) {
                            this.msgService.clear();
                            this.msgService.add({ severity: 'error', summary: 'Order total exceeded', detail: 'Order total exceeded your credit limit.' });
                            return;
                    } else {
                        const x = await this.mtPayConfirmationOrder(orderData);
                        if (!x) {
                            return;
                        }
                        console.log('XXXXXXXX', x);
                        orderData = x;
                        this.orderArr[this.activeIndex] = x;
                    }
                } else if (orderData.repGroupOrderStatus === 'INCOMPLETE') {
                    orderData.repGroupOrderStatus = 'OPEN';
                }
                /*} else if (this.mtPayMFRServiceEnableOrNot && this.enabledmtPayService && mtPayInitiated && orderData.repGroupOrderStatus === 'INCOMPLETE') {
                    orderData.repGroupOrderStatus = 'OPEN';
                } else if ((!this.mtPayMFRServiceEnableOrNot || !this.enabledmtPayService || (this.orderArr[this.activeIndex].paymentTerm &&
                     this.orderArr[this.activeIndex].paymentTerm !== 'mtPay')) && orderData.repGroupOrderStatus === 'INCOMPLETE') {
                    orderData.repGroupOrderStatus = 'OPEN';
                }*/

            }
            this.checkCCNumberInString(orderData.specialInstructions);
            if (!this.validationArrayForCC[this.activeIndex].isValidSpecialInstruction) {
                return;
            }
            // RC-455 (MG 21-JAN-2020): moved code to function to reuse
            orderData = this.beforeSavePreparation(orderData, noNext, checkValidation, isFinish);
            const currentIndex = this.promotionCurrentLoaderIndex;
            if (isFinish) {
                this.loadingSave = true;
                this.editable = false;
            }
            // RC-2101 (RK-19-SEP-2021) mtPay Editable Items Details Condition [start]
            /*const mtPayInitiated = this.ismtPayInitiated(this.orderArr[this.activeIndex]);
            this.mtPayEditableItemsDetails = (mtPayInitiated) ? false : true;*/
            // RC-2101 (RK-19-SEP-2021) mtPay Editable Items Details Condition [start]
            if (this.orderChangesHistory.length > 0) {
                orderData.orderChangesHistory = [this.orderService.setOrderChangesHistoryObj(orderData, this.orderChangesHistory, 'order-details')];
            }

            const saveObj: any = await this.orderService.saveOrderData(orderData, this.mtPayPaymentDeclined);
            this.orderCan = (saveObj.repGroupOrderStatus === 'INCOMPLETE') ? 'delete' : 'cancel';
            if (saveObj && saveObj.dataErrorStatus) {
                this.loadingSave = false;
                this.editable = true;
                return false;
            }
            if (currentIndex >= 0) {
                this.promotionLoader[currentIndex] = false;
            }

            let successMessage = 'Your order was saved successfully';
            if (saveObj && (saveObj.recordDeleted || saveObj.repGroupOrderStatus === 'CANCELLED')) {
                successMessage = 'Your order was deleted successfully';
            }
            this.rtSharedService.setRepgroupOrderStatus(saveObj.repGroupOrderStatus === 'INCOMPLETE' && !saveObj.recordDeleted ? true : false);
            if (saveObj) {
                setTimeout(() => {
                    if (this.orderArr[this.activeIndex]) {
                        this.orderArr[this.activeIndex].dateAdded = saveObj.dateAdded;
                        this.orderArr[this.activeIndex].dateModified = saveObj.dateModified;
                        this.orderArr[this.activeIndex].modifiedBy = saveObj.modifiedBy;
                        this.orderArr[this.activeIndex].repGroupOrderStatus = saveObj.repGroupOrderStatus;
                        this.orderArr[this.activeIndex].orderTotal = saveObj.orderTotal;
                        this.orderArr[this.activeIndex].retailerCarrierAccount = saveObj.retailerCarrierAccount;
                        this.orderArr[this.activeIndex].orderPaymentTokens = saveObj.orderPaymentTokens;
                        this.newMTPayretaileravilablecl = parseInt(this.getmtPayAvailableCreditLimit(saveObj), 10) / 100;
                    }

                    console.log('REPLACE', saveObj);
                    this.orderArrForHistory = _.cloneDeep(this.orderArr);
                    this.orderChangesHistory = [];

                    this.msgService.clear();
                    this.msgService.add({
                        severity: 'info',
                        summary: (saveObj.recordDeleted || saveObj.repGroupOrderStatus === 'CANCELLED') ? 'Order Deleted' : 'Order Saved',
                        detail: successMessage
                    });
                    if (!noNext) {
                        this.openNext();
                    } else {
                        if (isFinish) {
                            if (this.projectName === 'B2B') {
                                this.openOrderSuccessDialog();
                            } else {
                                this.navigateTo();
                            }
                        }
                    }
                });
                // if (saveObj.repGroupOrderStatus === 'INCOMPLETE') {
                //     const params = true;
                //     this.rtSharedService.setRepgroupOrderStatus(params);
                // }
                this.loadingSave = false;
                return saveObj;
            } else {
                this.msgService.clear();
                this.msgService.add({
                    severity: 'error',
                    summary: 'Order Not Saved',
                    detail: 'Your order could not be saved at this time'
                });
                this.loadingSave = false;
                return;
            }
        }
    }
    mtPayConfirmationOrder(orderData) {
        return new Promise((resolve, reject) => {
            console.log('Rahul....mtPayConfirmationOrder', !this.mtPayPaymentDeclined, !this.isEditableNotSave, orderData);
            if (orderData.repGroupOrderStatus === 'INCOMPLETE') {
                orderData.repGroupOrderStatus = 'OPEN';
            }
            let sendedData = '';
            const mtPayOrderPaymentDetails = {
                paymentMethod: this.newMTPaySelectedPaymentMethods && this.newMTPaySelectedPaymentMethods.type,
                paymentTerms: this.newMTPayPaymentTermDDModel && this.newMTPayPaymentTermDDModel.day,
                isFinanced: (this.newMTPayPaymentTermDDModel && (this.newMTPayPaymentTermDDModel.day === 30
                    || this.newMTPayPaymentTermDDModel.day === 60 || this.newMTPayPaymentTermDDModel.day === 90)) ? true  : false,
                paymentMethodID: this.appliedBankDetail ? this.appliedBankDetail.id : (this.finalSelectedCard ? this.finalSelectedCard.paymentID : null)
                // paymentMethodID: this.finalSelectedCard ? this.finalSelectedCard.paymentID : null
            };
            sendedData = JSON.stringify(mtPayOrderPaymentDetails);
            orderData.mtPayOrderPaymentDetailsString = sendedData;
            orderData.paymentTerm = 'mtPay';
            // this.newMTPayretaileravilablecl = (this.newMTPayretaileravilablecl - orderData.orderTotal);
            resolve(orderData);
            /*this.confirmationService.confirm({
                message: 'Are you sure you are ready for the transaction? Once you select "Yes" you can not edit item this order details, and if you choose to "Pay 30, 60, 90 terms" then the order amount will be blocked from your limit.',
                header: 'Confirmation Transaction',
                acceptLabel: 'Yes, I agree',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                key: 'warn-transaction',
                accept: async () => {
                    orderData.repGroupOrderStatus = 'OPEN';
                    let sendedData = '';
                    const mtPayOrderPaymentDetails = {
                        paymentMethod: this.newMTPaySelectedPaymentMethods && this.newMTPaySelectedPaymentMethods.type,
                        paymentTerms: this.newMTPayPaymentTermDDModel && this.newMTPayPaymentTermDDModel.day,
                        isFinanced: (this.newMTPayPaymentTermDDModel && (this.newMTPayPaymentTermDDModel.day === 30
                            || this.newMTPayPaymentTermDDModel.day === 60 || this.newMTPayPaymentTermDDModel.day === 90)) ? true  : false,
                        paymentMethodID: this.finalSelectedCard ? this.finalSelectedCard.paymentID : null
                    };
                    sendedData = JSON.stringify(mtPayOrderPaymentDetails);
                    orderData.mtPayOrderPaymentDetailsString = sendedData;
                    orderData.paymentTerm = 'mtPay';
                    this.newMTPayretaileravilablecl = (this.newMTPayretaileravilablecl - orderData.orderTotal);
                    resolve(orderData);
                },
                reject: () => {
                    resolve(false);
                }
            });*/
        });
    }
    // B2B-49 (MG-03-MAR-2020) modified to confirm when complete finalize order
    // B2B-211 (MG-28-MAR-2020) modified to prevent user to complete order directly from last order tab
    confirmAndFinish(orderObj: any, noNext?: boolean, checkValidation?: boolean, isFinish?: boolean) {
        console.log('Confirmation', noNext, checkValidation, isFinish);
        if (isFinish) {

            // do not check this when only single
            if (this.orderArr.length > 1) {
                for (let order_index = 0; order_index < this.orderArr.length; order_index++) {
                    const element = this.orderArr[order_index];
                    if (element.repGroupOrderStatus === 'INCOMPLETE') {
                        if ((order_index + 1) !== this.orderArr.length) { // do not show this msg on last order
                            console.log('Load order-index', order_index);
                            this.openNext(true, order_index);
                            this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Please complete order details for #' + element.poNumber });
                            return;
                        }
                    }
                }
            }

            console.log('ahead now');
            this.confirmationService.confirm({
                message: 'Are you sure you are ready to finalize? Once you ' +
                    'select "Yes" your orders will be sent to your assigned salesperson.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: async () => {
                    this.saveOrder(orderObj, noNext, checkValidation, isFinish);
                },
                reject: () => {
                }
            });
        }
    }

    confirmAndFinishBypass(orderObj: any, noNext?: boolean, checkValidation?: boolean, isFinish?: boolean) {
        this.router.navigate([`/home`]);
    }

    openOrderSuccessDialog() {
        const ref = this.dialogService.open(OrderSuccessDialogComponent, {
            header: 'Order(s) Placed Successfully!',
            width: '50%',
            closeOnEscape: false,
            dismissableMask: false,
            closable: false,
            styleClass: 'overflow-auto cut-dialog-height order-thankyou-dialog',
            data: this.orderArr
        });

        ref.onClose.subscribe(() => {
            this.navigateTo();
        });
    }

    openEditRetailerShipToLocationModal(order, type) {
        const shipToObj: any = _.cloneDeep(order.retailerShipTo);
        shipToObj.address1 = order.shipToAddress1;
        shipToObj.address2 = order.shipToAddress2;
        shipToObj.city = order.shipToCity;
        shipToObj.country = order.shipToCountry;
        shipToObj.name = order.shipToName;
        shipToObj.state = order.shipToState;
        shipToObj.zip = order.shipToZip;
        shipToObj.phone = order.shipToPhone;
        shipToObj.phoneExtension = order.shipToPhoneExtension;
        shipToObj.fax = order.shipToFax;
        shipToObj.faxExtension = order.shipToFaxExtension;
        shipToObj.email = order.shipToEmail;
        shipToObj.notes = order.shipToNotes;

        let data: any = {
            retailerID: order.retailer.recordID,
            // fromPage: 'ORDER_PAGE'
        };
        let header = 'Add New Ship To Location';
        if (type === 'EDIT') {
            data = {
                selectedShipToLocation: shipToObj,
                fromPage: 'ORDER_PAGE',
            };
            header = 'Edit Ship To Location';
        }
        const ref = this.dialogService.open(EditShipToDialogComponent, {
            header,
            width: '50%',
            height: 'auto',
            closable: true,
            styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
            data
        });

        ref.onClose.subscribe(async (updateData: any) => {
            if (updateData && !updateData.isOrderOnly) {
                order.retailerShipTo = updateData.data;
            }
            if (updateData && updateData.data) {
                order.shipToAddress1 = updateData.data.address1;
                order.shipToAddress2 = updateData.data.address2;
                order.shipToCity = updateData.data.city;
                order.shipToCountry = updateData.data.country;
                order.shipToName = updateData.data.name;
                order.shipToState = updateData.data.state;
                order.shipToZip = updateData.data.zip;
                order.shipToFax = updateData.data.fax;
                order.shipToFaxExtension = updateData.data.faxExtension;
                order.shipToPhone = updateData.data.phone;
                order.shipToPhoneExtension = updateData.data.phoneExtension;
                order.shipToEmail = updateData.data.email;
                // order.shipToNotes = updateData.data.notes;
                const orderObj: any = await this.saveOrder(order, true);
                if (type !== 'EDIT') {
                    this.updatequeryperam(orderObj);
                }
                if (this.projectName !== 'B2B') {
                    // RC-1346 (RK 06-Nov-2020): Remove OOT message
                    // this.getTerritoryStatusData();
                }
            }
        });
    }

    // B2B-307 (MK 07-08-2020) Improvement: changes list for finalize screen
    openChangeShipToModal(order) {
        console.log(order);
        // return false;
        const ref = this.dialogService.open(RetailerShipToLocationTableComponent, {
            header: 'Change Ship To Location',
            width: '97%',
            height: 'auto',
            style: { 'max-width': '985px' },
            styleClass: 'overflow-auto cut-dialog-height',
            data: {
                retailerID: order.retailer.recordID,
                selectedShipTo: order.retailerShipTo
            }
        });
        ref.onClose.subscribe(async (shipTo: any) => {
            if (shipTo) {
                order.retailerShipTo = shipTo;
                order.shipToAddress1 = shipTo.address1;
                order.shipToAddress2 = shipTo.address2;
                order.shipToCity = shipTo.city;
                order.shipToCountry = shipTo.country;
                order.shipToName = shipTo.name;
                order.shipToState = shipTo.state;
                order.shipToZip = shipTo.zip;
                order.shipToPhone = shipTo.phone;
                order.shipToPhoneExtension = shipTo.phoneExtension;
                order.shipToFax = shipTo.fax;
                order.shipToFaxExtension = shipTo.faxExtension;
                order.shipToEmail = shipTo.email;
                order.shipToNotes = shipTo.notes;
                const orderObj: any = await this.saveOrder(order, true);
                this.updatequeryperam(orderObj);
                // this.getTerritoryStatusData();
            }
        });
    }

    openEditRetailerBillToLocationModal(order) {
        const billToObj: any = _.cloneDeep(order.retailer);
        billToObj.address1 = order.billToAddress1;
        billToObj.address2 = order.billToAddress2;
        billToObj.city = order.billToCity;
        billToObj.country = order.billToCountry;
        billToObj.name = order.billToName;
        billToObj.state = order.billToState;
        billToObj.zip = order.billToZip;
        billToObj.phone = order.billToPhone;
        billToObj.email = order.billToEmail;
        billToObj.fax = order.billToFax;
        billToObj.faxExtension = order.billToFaxExtension;
        billToObj.phoneExtension = order.billToPhoneExtension;
        billToObj.federalTaxNumber = order.billToFederalTaxNumber;
        billToObj.salesTaxNumber = order.billToSalesTaxNumber;
        billToObj.stateTaxNumber = order.billToStateTaxNumber;

        const ref = this.dialogService.open(EditBillToDialogComponent, {
            header: 'Edit Bill To Location',
            width: '50%',
            height: 'auto',
            closable: true,
            styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
            data: {
                selectedRetailer: billToObj,
                fromPage: 'ORDER_PAGE',
            }
        });

        ref.onClose.subscribe((updateData: any) => {
            if (updateData && !updateData.isOrderOnly) {
                order.retailerbillTo = updateData.data;
            }
            if (updateData) {
                order.billToAddress1 = updateData.data.address1;
                order.billToAddress2 = updateData.data.address2;
                order.billToCity = updateData.data.city;
                order.billToCountry = updateData.data.country;
                order.billToName = updateData.data.name;
                order.billToState = updateData.data.state;
                order.billToZip = updateData.data.zip;
                order.billToPhone = updateData.data.phone;
                order.billToEmail = updateData.data.email;
                order.billToPhoneExtension = updateData.data.phoneExtension;
                order.billToFax = updateData.data.fax;
                order.billToFaxExtension = updateData.data.faxExtension;
                order.billToFederalTaxNumber = updateData.data.federalTaxNumber;
                order.billToSalesTaxNumber = updateData.data.salesTaxNumber;
                order.billToStateTaxNumber = updateData.data.stateTaxNumber;
                this.saveOrder(order, true);
            }
        });
    }

    openEditRetailerContactModal(order, type?) {
        // B2B-301 (MK 18 JUL 2020): BUG: Retailer can’t edit/add Buyer to order in Finalize Screen in B2B
        const data: any = {
            selectedContact: type === 'NEW' ? new RetailerContact() : order.retailerContact,
        };
        if (type === 'NEW') {
            data.retailerID = order.retailer.recordID;

        }
        const ref = this.dialogService.open(EditContactDialogComponent, {
            header: type === 'NEW' ? 'Add New Contact' : 'Edit Contact',
            width: '50%',
            height: 'auto',
            closable: true,
            styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
            data
        });

        ref.onClose.subscribe((contact: any) => {
            if (contact) {
                order.retailerContact = contact;
                this.saveOrder(order, true);
            }
        });
    }

    ChangeOrderContactModal(order) {
        const ref = this.dialogService.open(SelectContactDialogComponent, {
            header: 'Change Contact',
            width: '97%',
            height: 'auto',
            style: { 'max-width': '985px' },
            styleClass: 'overflow-auto cut-dialog-height',
            closable: true,
            data: {
                selectedContact: order.retailerContact,
                retailerID: order.retailer.recordID
            }
        });

        ref.onClose.subscribe((contact: any) => {
            if (contact) {
                order.retailerContact = contact;
                const element = document.getElementById('retailerContact' + this.activeIndex);
                element.classList.remove('ng-invalid');
                this.saveOrder(order, true);
            }
        });
    }

    // updatequeryperam() {
    //     this.router.navigate([], {
    //         queryParams: this.qpdata,
    //         relativeTo: this.route,
    //         queryParamsHandling: 'merge'
    //     });
    // }

    updatequeryperam(data) {
        let update = false;
        if (this.qpdata.retailerShipToLocationID !== undefined && this.qpdata.retailerShipToLocationID !== data.retailerShipTo.recordID) {
            this.qpdata.retailerShipToLocationID = data.retailerShipTo.recordID;
            // this.qpdata.selectedID = data.recordID;
            update = true;
        }
        console.log(this.qpdata, update);
        if (this.qpdata.selectedID !== undefined && this.qpdata.selectedID !== data.recordID && update) {
            this.qpdata.selectedID = data.recordID;
            update = true;
        }
        if (update) {
            location.replace(this.router.createUrlTree(['finalize-order'], { queryParams: this.qpdata }).toString());
        }
    }

    async updateRepgroupStatus(event) {
        const order = this.orderArr[this.activeIndex];
        order.repGroupOrderStatus = event.value;
        this.saveOrder(order, true);
    }

    updatePaymentTerms(selectedTerm, paymentTerms) {
        setTimeout(async () => {
            const selectedTermObj = _.find(paymentTerms, o => o.label === selectedTerm);
            if (selectedTermObj && selectedTermObj.value.collectPaymentInformation) {
                this.displayPaymentInfoDialog = true; // open payment modal
            } else {
                if (!this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Payment Terms', 'paymentTerm')) {
                    console.log('No changes detected.');
                    return false;
                }
                this.updateChangesMade(selectedTerm);
            }
        });
    }

    updateShipMethod(selectedShip, shippingMethodOptions) {
        setTimeout(async () => {
            /*RC-401 (RK 08-APRIL-2020) show Carrier Account popup [start]*/
            this.shipObjMatch = _.find(shippingMethodOptions, o => o.label === selectedShip);
            if (this.shipObjMatch && this.shipObjMatch.value.collectAccountInformation) {
                this.displayCarrierAccountDialog = true; // open payment modal
                if (this.orderArr[this.activeIndex].retailerCarrierAccount && JSON.stringify(this.orderArr[this.activeIndex].retailerCarrierAccount) !== '{}') {
                    this.orderArr[this.activeIndex].retailerCarrierAccountID = _.cloneDeep(this.orderArr[this.activeIndex].retailerCarrierAccount.recordID);
                    this.orderArr[this.activeIndex].retailerCarrierAccount = null;
                }
            } else {
                if (!this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Ship Method', 'shippingMethod')) {
                    console.log('No changes detected.');
                    return false;
                }
                this.orderArr[this.activeIndex].retailerCarrierAccount = null;
                this.orderArr[this.activeIndex].retailerCarrierAccountID = null;
                this.updateChangesMade(selectedShip, 'SHIP_METHOD');
            }
        });
    }

    onCarrierAccountsDialogClose() {
        this.displayCarrierAccountDialog = false;
    }

    onCarrierAccountsCreated($event) {
        // RC-401 (RK 08 APRIL 2020): Add for After saving carrier popup details call order API -->
        if ($event && $event.data && this.orderArr[this.activeIndex]) {
            this.orderArr[this.activeIndex].shippingMethod = $event.data.shippingMethod;
            this.orderArr[this.activeIndex].retailerCarrierAccountID = $event.data.recordID;
            this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Ship Method', 'shippingMethod');
            this.updateChangesMade($event.data.shippingMethod, 'SHIP_METHOD');
        }
        this.onCarrierAccountsDialogClose();
    }

    onSaveCreditCardInfo(emitData) {
        this.orderArr[this.activeIndex].paymentTerm = emitData;
        this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Payment Terms', 'paymentTerm');
        this.saveOrder(this.orderArr[this.activeIndex], true);
    }

    // RC-444 (RK 16 JAN 2020) Add onPaymentType emit event
    onPaymentType(emitData, orderJSONData) {
        const selectedTermObj = _.find(orderJSONData.paymentTermOptions, o => o.label.toLowerCase() === emitData.toLowerCase());
        if (selectedTermObj && selectedTermObj.value.paymentTerm) {
            orderJSONData.paymentTerm = selectedTermObj.value.paymentTerm;
        }
        /*else {
            orderJSONData.paymentTerm = null;
        }*/
    }

    showSpecialsAvailableDialog() {
        this.loadPromotions();
        this.displaySpecialsAvailableDialog = true;
    }

    onDiscountDialogShow() {
        this.displayDiscountPercentDialog = true;
    }

    downloadFile(data, order, mimeType, ext) {
        const repGroupName = order['repGroup'] && order['repGroup']['name'] ? order['repGroup']['name'] : 'NoRepGroupName';
        const poNumber = order['poNumber'] ? order['poNumber'] : 'NoPoNumber';
        const orderDate = order['orderDate'] ? this.dp.transform(order['orderDate'], 'MM-dd-yyyy') : 'NoOrderDate';
        const filename = repGroupName + '_' + poNumber + '_' + orderDate;
        console.log('filename parts', repGroupName, poNumber, orderDate);
        const url = window.URL.createObjectURL(new Blob([data], { type: mimeType }));
        console.log('Download URL:', url);

        // Directly download the file by adding a temporary link with blob file data
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.removeInvalidChars(filename) + ext;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        console.log('Should be downloading now.');
    }

    previewOrder() {
        this.visibleprintpreview = true
        this.itemwithimage = false;
        this.pdfSrc = this.orderArr[this.activeIndex];

        // console.groupCollapsed('Downloading PDF :', this.orderArr[this.activeIndex]);
        // this.orderApi.downloadOrder(this.orderArr[this.activeIndex].recordID, 'pdf')
        //     .then(data => {
        //         console.groupEnd();
        //         this.downloadFile(data, this.orderArr[this.activeIndex], 'application/pdf', '.pdf');
        //         // Update order status(es) in batch group if Manufacturer opens order
        //     }, () => {
        //         this.msgService.add({
        //             severity: 'error',
        //             summary: 'Error',
        //             detail: 'An error occurred downloading order ' + this.orderArr[this.activeIndex].recordID
        //         });
        //     });
    }

    showEmailDialog() {
        this.salespersonToEmail = this.orderArr[this.activeIndex].salesperson1;
        this.displayEmailDialog = true;
    }

    ngOnDestroy() {
        this.destroyPromotiondata.next();
        this.onDestroyCompanyIntegration.next();
        this.onDestroygetBuyerDetails.next();
        this.onDestroyQualificationApproval.next();
        this.onDestroyTransaction.next();
        this.onDestroyTransactionUpdate.next();
        this.onDestroySavePaymentDetails.next();
        this.onDestroyGetPaymentDetails.next();
        this.onDestroyTransactionFinance.next();
        this.onDestroygetBuyerCreditLimit.next();
        this.onDestroygetBuyerBank.next();
        this.destroyRetailerTerms.next();
    }
    // RC-459 (MG 16 JAN 2020): added for promotion row click & row loader
    private loadPromotions() {
        this.availablePromotions = [];
        this.promotionLoader = [];
        this.emptyPromotionMsg = 'Loading promotions...';
        if (this.orderArr[this.activeIndex].manufacturer) {
            this.destroyPromotiondata.next();
            this.manufacturerService.getActiveManufacturerPromotions(this.orderArr[this.activeIndex].manufacturer.recordID)
                .pipe(takeUntil(this.destroyPromotiondata)).subscribe((data: ManufacturerPromotion[]) => {
                    this.availablePromotions = data.filter((d) => d.showOnWebsite);
                    this.promotionLoader = data.map(x => false);
                    if (this.availablePromotions.length === 0) {
                        this.emptyPromotionMsg = 'No promotion found.';
                    }
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    }, 0);

                });
        }
    }

    hideDialog(displayBool: boolean) {
        displayBool = false;
    }

    hideDiscountDialog() {
        this.displayDiscountPercentDialog = false;
    }

    sendOrder(fileType: string, email: string) {
        this.orderApi.emailOrder(this.orderArr[this.activeIndex].recordID, fileType, email)
            .subscribe((data: any) => {
                this.msgService.add({
                    severity: 'info',
                    summary: 'Email sent successfully',
                    detail: `Order ${fileType} successfully sent to ${email}`
                });
            });

    }

    getOrderSalespeople() {
        const orderSalespeople: SelectItem[] = [];
        const spMap = new Map<string, boolean>();
        if (this.orderArr[this.activeIndex].salesperson1) {
            spMap.set(this.orderArr[this.activeIndex].salesperson1.recordID, true);
            orderSalespeople.push({
                label: this.orderArr[this.activeIndex].salesperson1.name,
                value: this.orderArr[this.activeIndex].salesperson1
            });
        }
        if (this.orderArr[this.activeIndex].salesperson2 && !spMap.has(this.orderArr[this.activeIndex].salesperson2.recordID)) {
            spMap.set(this.orderArr[this.activeIndex].salesperson2.recordID, true);
            orderSalespeople.push({
                label: this.orderArr[this.activeIndex].salesperson2.name,
                value: this.orderArr[this.activeIndex].salesperson2
            });
        }
        if (this.orderArr[this.activeIndex].salesperson3 && !spMap.has(this.orderArr[this.activeIndex].salesperson3.recordID)) {
            spMap.set(this.orderArr[this.activeIndex].salesperson3.recordID, true);
            orderSalespeople.push({
                label: this.orderArr[this.activeIndex].salesperson3.name,
                value: this.orderArr[this.activeIndex].salesperson3
            });
        }
        if (this.orderArr[this.activeIndex].salesperson4 && !spMap.has(this.orderArr[this.activeIndex].salesperson4.recordID)) {
            spMap.set(this.orderArr[this.activeIndex].salesperson4.recordID, true);
            orderSalespeople.push({
                label: this.orderArr[this.activeIndex].salesperson4.name,
                value: this.orderArr[this.activeIndex].salesperson4
            });
        }
        if (this.orderArr[this.activeIndex].salesperson5 && !spMap.has(this.orderArr[this.activeIndex].salesperson5.recordID)) {
            spMap.set(this.orderArr[this.activeIndex].salesperson5.recordID, true);
            orderSalespeople.push({
                label: this.orderArr[this.activeIndex].salesperson5.name,
                value: this.orderArr[this.activeIndex].salesperson5
            });
        }
        return orderSalespeople;
    }

    onDiscountPercentSave(ev) {
        this.orderArr[this.activeIndex].orderDiscount = ev;
        this.saveOrder(this.orderArr[this.activeIndex], true);
        this.onDiscountDialogClose();
    }

    onDiscountDialogClose() {
        this.displayDiscountPercentDialog = false;
    }

    async deleteOrder() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to cancel and remove this order?',
            header: 'Cancel Order',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            accept: async () => {
                this.orderArr[this.activeIndex].sendNotification = true;

                if (this.orderCan === 'delete') {
                    this.orderArr[this.activeIndex].notes = 'BUYER DELETED ORDER';
                    this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Buyer Delete Notes', 'notes');
                    this.orderArr[this.activeIndex].recordDeleted = true;
                    this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Order Delete', 'recordDeleted');
                } else {
                    this.orderArr[this.activeIndex].notes = 'BUYER CANCELLED ORDER';
                    this.checkIfValueChanged(this.orderArr[this.activeIndex], 'Buyer Cancel Notes', 'notes');
                    this.orderArr[this.activeIndex].repGroupOrderStatus = 'CANCELLED';
                    this.checkIfValueChanged(this.orderArr[this.activeIndex], 'RepGroup Order Status(Buyer Cancelled)', 'repGroupOrderStatus');
                }

                const orderObj: any = await this.saveOrder(this.orderArr[this.activeIndex], true);
                console.log('orderObjorderObjorderObjorderObjorderObjorderObjorderObj', orderObj);
                if (orderObj.recordID) {
                    this.orderArr = this.orderArr.filter(o => !o.recordDeleted);
                    this.orderArr = this.orderArr.filter(o => o.repGroupOrderStatus !== 'CANCELLED');

                    if (this.orderArr.length) {
                        const ordersIDs = this.orderArr.map(o => o.recordID);
                        if (this.repGroupID && this.rtSharedService.getEntityType() === 'Salesperson') {
                            this.location.replaceState(`${this.urlPrefix}finalize-order?orderIDs=${ordersIDs.join()}&repGroupID=${this.repGroupID}`);
                        } else {
                            this.location.replaceState(`${this.urlPrefix}finalize-order?orderIDs=${ordersIDs.join()}`);
                            console.log('>>>>>>>>>>', this.orderArr.length, this.activeIndex);

                            if (this.orderArr.length === this.activeIndex) {
                                this.activeIndex = this.activeIndex - 1;
                            }
                        }
                        this.rtSharedService.setRepgroupOrderStatus(this.orderArr[this.activeIndex].repGroupOrderStatus === 'INCOMPLETE' ? true : false);
                        // RC-2101 (RK-21-SEP-2021) mtPay [start]
                        this.initmtPay();
                        /*this.checkMTPayMFRServiceEnableOrNotFn();
                        const mtPayInitiated = this.ismtPayInitiated(this.orderArr[this.activeIndex]);
                        this.mtPayEditableItemsDetails = (mtPayInitiated) ? false : true;*/
                        // RC-2101 (RK-21-SEP-2021) mtPay [end]
                    } else {
                        this.rtSharedService.setRepgroupOrderStatus(false);
                        this.navigateTo();
                    }

                    console.log('REPLACE');
                    this.orderArrForHistory = _.cloneDeep(this.orderArr);
                    this.orderChangesHistory = [];
                }
            },
            reject: () => {
            }
        });
    }

    isOnOrder(rowData: ManufacturerPromotion) {
        if (this.orderArr[this.activeIndex].specialInstructions) {
            return this.orderArr[this.activeIndex].specialInstructions.includes(rowData.name + ': ' + rowData.description);
        } else {
            return false;
        }
    }

    isOrderAmountMeet(rowData, msg = false) {
        if (!msg) {
            if (this.orderArr[this.activeIndex].orderTotal) {
                return this.orderArr[this.activeIndex].orderTotal >= rowData.amountToMeet;
            } else {
                return false;
            }
        } else {
            return 'You are just $' + (+rowData.amountToMeet - +this.orderArr[this.activeIndex].orderTotal) + ' away to avail this promotion!';
        }
    }

    // RC-459 (MG 16 JAN 2020): added for promotion row click & row loader
    async addPromotionToOrder(rowData: ManufacturerPromotion, rowIndex) {
        if (!this.isOrderAmountMeet(rowData)) {
            this.msgService.add({ severity: 'warn', summary: 'Minimum Met', detail: 'Please add more items to avail this promotion.' });
            return;
        }
        this.isDisabledTableRow = true;
        const appendValue = rowData.name + ': ' + rowData.description;
        let spi = this.orderArr[this.activeIndex].specialInstructions;
        if (spi) {
            spi += `\n${appendValue}`;
        } else {
            spi = appendValue;
        }
        this.orderArr[this.activeIndex].specialInstructions = spi;
        this.promotionLoader[rowIndex] = true;
        this.promotionCurrentLoaderIndex = rowIndex;
        await this.saveOrder(this.orderArr[this.activeIndex], true);
        this.isDisabledTableRow = false;
    }

    // RC-459 (MG 16 JAN 2020): added for promotion row click & row loader
    async removePromotionFromOrder(rowData: ManufacturerPromotion, rowIndex) {
        this.isDisabledTableRow = true;
        const appendValue = rowData.name + ': ' + rowData.description;
        const spi = this.orderArr[this.activeIndex].specialInstructions;
        this.orderArr[this.activeIndex].specialInstructions = spi.replace(appendValue, '').trim();
        this.promotionLoader[rowIndex] = true;
        this.promotionCurrentLoaderIndex = rowIndex;
        await this.saveOrder(this.orderArr[this.activeIndex], true);
        this.isDisabledTableRow = false;
    }

    navigateTo() {
        if (this.rtSharedService.getProjectName() === 'B2B') {
            this.router.navigate([`/home`]);
        } else {
            this.router.navigate([`${this.urlPrefix}/orders`]);
        }
    }

    // RC-538 (MG 22-JAN-2020): modified to fix discount issue
    async getOrderTotal(orderEventData) {
        const orderDetail = orderEventData.items;
        const orderItems = orderDetail.filter(o => !o.recordDeleted);
        if (orderItems && orderItems.length) {
            this.orderArr[this.activeIndex].orderGrossTotal = this.countOrderTotalFromItems(orderDetail);
            this.orderArr[this.activeIndex].orderDetails = orderItems;
            this.orderArr[this.activeIndex].countOrderItems = orderItems.length;
            this.orderArr[this.activeIndex].orderTotal = _.sumBy(orderDetail, (o: any) => {
                if (!o.recordDeleted) {
                    return o.total;
                } else {
                    return 0;
                }
            });
        } else {
            this.orderArr[this.activeIndex].orderGrossTotal = 0;
            this.orderArr[this.activeIndex].countOrderItems = 0;
            this.orderArr[this.activeIndex].recordDeleted = true;
            await this.saveOrder(this.orderArr[this.activeIndex], true);
            this.navigateTo();
        }
        // [B2B-95] (RM 25-FEB-2020): Call function for get suggested items
        // [B2B-151] (RM 11-MARCH-2020): When item remove that time refresh suggested data
        if (orderEventData.isDelete) {
            await this.getRetailerSuggestionsData('getOrderTotal');
        }
    }
    async getItemsUpdatedObj(itemEventData) {
        if (itemEventData && itemEventData.itemsUpdatedObj) {
            this.newMTPayretaileravilablecl = parseInt(itemEventData.itemsUpdatedObj['availableCreditLimit'], 10) / 100;
        }
    }
    // [B2B-95] (RM 25-FEB-2020): Get Suggested Items
    // [B2B-159] (RM 11-MARCH-2020): Unwanted API calling problem reduce.
    async getRetailerSuggestionsData(funName) {
        console.log(' >>> function call from >>> ', funName);
        this.suggestedItemsArr = [];
        this.suggestedItemsCount = 0;
        this.basketSyncLoader = true;
        this.suggestListShimmer = true;
        const selectedOrder = this.orderArr[this.activeIndex];
        const itemMfgIDs: any = await this.getItemWithMfg(selectedOrder);
        // console.log(itemMfgIDs);
        this.suggestListShimmer = false;
        if (this.suggestedItemsMasterArr && this.suggestedItemsMasterArr.length) {
            const suggestedList = this.suggestedItemsMasterArr;
            this.suggestedItemsArr = _.filter(suggestedList, (o: any) => {
                const existFind = itemMfgIDs.find(d => (
                    o.item.itemNumber === d.itemNumber
                    && o.item.manufacturerID === d.manufacturerID
                    && !d.recordDeleted));
                o.item.isDisabled = false;
                if (o.item.manufacturerID === selectedOrder.manufacturer.recordID && !existFind) {
                    o.item.fullPicturePath = this.itemsAPIService.getFullPicturePath(o.item.picturePath, o.item.manufacturerID);
                    o.item.fullPicturePath64PX = this.itemsAPIService.getFullPicturePath(o.item.picturePath, o.item.manufacturerID, 64);
                    const rand = Math.floor(Math.random() * 20) + 1;
                    o.item.profilePic = this.getLogoPath(o.item.manufacturerID) + '?time=' + rand + 5;
                    o.item.imageError = false;
                    // console.log('o.profilePic>>>>>>xx', o.profilePic);
                    return o;
                }
            });
            this.suggestedItemsCount = this.suggestedItemsArr.length;
            this.basketSyncLoader = false;
        } else {
            this.suggestedItemsArr = [];
            this.suggestedItemsCount = 0;
            this.basketSyncLoader = false;
        }
    }

    // [B2B-95] (RM 27-FEB-2020):
    async getItemWithMfg(selectedOrder) {
        console.log('getItemWithMfg');
        console.log(selectedOrder);
        const itemIDs: any = [];
        _.forEach(selectedOrder.orderDetails, (itemObj: any) => {
            itemIDs.push({
                manufacturerID: selectedOrder.manufacturer.recordID,
                itemNumber: itemObj.itemNumber,
                recordDeleted: itemObj.recordDeleted,
                size: itemObj.size,
                color: itemObj.color,
                style: itemObj.style,
                recordID: itemObj.recordID,
                quantity: itemObj.quantity,
                sequenceID: itemObj.sequenceID,
                itemID: itemObj.itemID,
                itemColorStyleID: itemObj.itemSCSID
            });
        });
        return itemIDs;
    }

    // [B2B-95] (RM 27-FEB-2020):
    async addToOrderCart(suggestedItems) {
        suggestedItems.isDisabled = true;
        this.basketSyncLoader = true;
        if (this.editable) {
            const selectedOrder = this.orderArr[this.activeIndex];
            const itemMfgIDs: any = await this.getItemWithMfg(selectedOrder);
            const existFind = itemMfgIDs.find(o => (suggestedItems.itemNumber === o.itemNumber &&
                suggestedItems.manufacturerID === o.manufacturerID && !o.recordDeleted));
            if (existFind) {
                this.confirmationService.confirm({
                    message: 'Selected item already on order.',
                    header: 'Item Already Available',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Ok',
                    rejectLabel: 'Cancel'
                    // accept: async () => {
                    // },
                    // reject: () => {
                    // }
                });
                suggestedItems.isDisabled = false;
            } else {
                if (suggestedItems.isc && suggestedItems.isc.length) {
                    this.viewItemDetails(suggestedItems);
                    suggestedItems.isDisabled = false;
                } else {
                    let sequenceID = 0;
                    if (selectedOrder.orderDetails && selectedOrder.orderDetails.length) {
                        sequenceID = selectedOrder.orderDetails[selectedOrder.orderDetails.length - 1].sequenceID;
                    }
                    if (this.validateDateBetweenTwoDates(suggestedItems)) {
                        suggestedItems.itemDiscount = suggestedItems.discountPercent;
                    }
                    const detailObj = {
                        b2bPriceCode: suggestedItems.b2bPriceCode ? suggestedItems.b2bPriceCode : null,
                        color: null,
                        commissionPercent: null,
                        description: suggestedItems.description,
                        inventoryAtMoment: null,
                        invoiceQuantity: null,
                        invoiceUnitPrice: null,
                        itemDiscount: suggestedItems.itemDiscount ? suggestedItems.itemDiscount : 0,
                        itemNumber: suggestedItems.itemNumber,
                        itemStatus: null,
                        listPrice: suggestedItems.retailPrice,
                        name: suggestedItems.name,
                        notes: null,
                        orderInvoiceID: null,
                        QAComments: null,
                        QATestReport: null,
                        quantity: suggestedItems.quantity ? suggestedItems.quantity : suggestedItems.minimumQuantity,
                        retailerItemNumber: null,
                        size: null,
                        style: null,
                        unitPrice: suggestedItems.unitPrice,
                        unitQty: suggestedItems.unitQty,
                        priceCode: null,
                        sequenceID,
                        itemID: suggestedItems.recordID,
                        itemSCSID: null,
                    };
                    const datediscontinue = [new Date(suggestedItems.discountStartDate).getTime(), new Date(suggestedItems.discountEndDate).getTime()];
                    const date = new Date().getTime();
                    if (datediscontinue[0] <= date && datediscontinue[1] >= date
                        && (detailObj.itemDiscount === undefined || detailObj.itemDiscount === null || detailObj.itemDiscount === 0)) {
                        detailObj.itemDiscount = suggestedItems.discountPercent ? suggestedItems.discountPercent : detailObj.itemDiscount;
                    }
                    const orderID = selectedOrder.recordID;
                    const manufacturerID = selectedOrder.manufacturer.recordID;
                    const updateDetailObj: any = _.cloneDeep(detailObj);
                    const updateDetail: any = await this.orderService.updateOrderDetail(orderID, updateDetailObj, manufacturerID);
                    updateDetail.picturePath = suggestedItems.picturePath;
                    suggestedItems.isDisabled = false;
                    if (updateDetail && updateDetail.recordID) {
                        const eventObj = {
                            itemObj: updateDetail,
                            orderID
                        };
                        await this.orderService.setAddOrderItemSub(eventObj);
                    }
                    await this.getRetailerSuggestionsData('addToOrderCart');
                }
            }
        }
        this.basketSyncLoader = false;
    }

    // [B2B-95] (RM 27-FEB-2020):
    viewItemDetails(suggestedItems) {
        if (this.editable) {
            console.log('open item browser dialog');
            this.displayItemViewDialog = true;
            this.activeViewIndex = this.activeIndex;
            this.activeViewItem = suggestedItems;
        }
    }

    // [B2B-95] (RM 27-FEB-2020):
    async addToCartEvent(dataEvent) {
        this.displayItemViewDialog = false;
        const suggestedItems = dataEvent.activeViewItem;
        if (suggestedItems.isc && suggestedItems.isc.length) {
            const index = 0;
            this.addToCartEventVariant(suggestedItems, index);
        } else {
            this.addToOrderCart(suggestedItems);
        }
    }

    // [B2B-95] (RM 27-FEB-2020):
    async addToCartEventVariant(suggestedItems, i) {
        if (suggestedItems.isc.length > i) {
            const index = i;
            if (suggestedItems.isc[i].quantity) {
                const selectedOrder = this.orderArr[this.activeIndex];
                let sequenceID = 0;
                if (selectedOrder.orderDetails && selectedOrder.orderDetails.length) {
                    sequenceID = selectedOrder.orderDetails[selectedOrder.orderDetails.length - 1].sequenceID;
                }
                const detailObj = {
                    b2bPriceCode: suggestedItems.b2bPriceCode ? suggestedItems.b2bPriceCode : null,
                    color: suggestedItems.isc[i].color ? suggestedItems.isc[i].color : null,
                    commissionPercent: null,
                    description: suggestedItems.description,
                    inventoryAtMoment: null,
                    invoiceQuantity: null,
                    invoiceUnitPrice: null,
                    itemDiscount: suggestedItems.itemDiscount ? suggestedItems.itemDiscount : 0,
                    itemNumber: suggestedItems.itemNumber,
                    itemStatus: null,
                    listPrice: suggestedItems.retailPrice,
                    name: suggestedItems.name,
                    notes: null,
                    orderInvoiceID: null,
                    QAComments: null,
                    QATestReport: null,
                    quantity: suggestedItems.isc[i].quantity ? suggestedItems.isc[i].quantity : suggestedItems.minimumQuantity,
                    retailerItemNumber: null,
                    size: suggestedItems.isc[i].size ? suggestedItems.isc[i].size : null,
                    style: suggestedItems.isc[i].style ? suggestedItems.isc[i].style : null,
                    unitPrice: suggestedItems.unitPrice,
                    unitQty: suggestedItems.unitQty,
                    priceCode: null,
                    sequenceID,
                };
                const datediscontinue = [new Date(suggestedItems.discountStartDate).getTime(), new Date(suggestedItems.discountEndDate).getTime()];
                const date = new Date().getTime();
                if (datediscontinue[0] <= date && datediscontinue[1] >= date
                    && (detailObj.itemDiscount === undefined || detailObj.itemDiscount === null || detailObj.itemDiscount === 0)) {
                    detailObj.itemDiscount = suggestedItems.discountPercent ? suggestedItems.discountPercent : detailObj.itemDiscount;
                }

                const orderID = selectedOrder.recordID;
                const manufacturerID = selectedOrder.manufacturer.recordID;
                const updateDetailObj: any = _.cloneDeep(detailObj);
                const updateDetail: any = await this.orderService.updateOrderDetail(orderID, updateDetailObj, manufacturerID);
                updateDetail.picturePath = suggestedItems.picturePath;
                if (updateDetail && updateDetail.recordID) {
                    const eventObj = {
                        itemObj: updateDetail,
                        orderID
                    };
                    await this.orderService.setAddOrderItemSub(eventObj);
                }
                await this.getRetailerSuggestionsData('addToCartEventVariant');
            }
            this.addToCartEventVariant(suggestedItems, index + 1);
        }
    }

    closeDialogPaymentInfo(isFormValid) {
        if (!isFormValid) {
            this.orderArr[this.activeIndex].paymentTerm = this.oldPaymentTerm;
        }
    }

    closeDialogShippingMethod(isFormValid) {
        if (!isFormValid) {
            this.orderArr[this.activeIndex].shippingMethod = this.oldShippingMethod;
        }
    }

    createDuplicateOrderForm() {
        // Create company-info-form form using FormBuilder
        this.orderDuplicateForm = this.fb.group({
            orderDateD: ['', [Validators.required]],
            shipDateD: ['', [Validators.required]],
            cancelDateD: ['', []],
            selectedOrderToDuplicatePoNumber: ['', [Validators.required]]
        });
    }

    initiateOrderDuplicate() {
        console.log(this.orderArr[this.activeIndex].orderDate);
        console.log(new Date());
        this.selectedOrderToDuplicate = _.cloneDeep(this.orderArr[this.activeIndex]);
        console.log('initiateOrderDuplicate', this.selectedOrderToDuplicate);
        this.selectedOrderToDuplicate['orderDetails'].forEach(orderInner => {
            orderInner.total = orderInner.unitPrice * orderInner.quantity * (1 - orderInner.itemDiscount / 100);
        });
        this.selectedOrderToDuplicate['orderGrossTotal'] = this.countOrderTotalFromItems(this.selectedOrderToDuplicate['orderDetails']);
        this.selectedOrderToDuplicate.poNumber = this.selectedOrderToDuplicate.poNumber + '-D';
        this.selectedOrderToDuplicate.accountNoDeactive = false;
        this.selectedOrderToDuplicatePoNumber = this.selectedOrderToDuplicate.poNumber;
        this.selectedOrderToDuplicate.shipToID = this.selectedOrderToDuplicate.retailerShipTo.recordID;

        this.displayOrderDuplicate = true;
        // this.resetForm();
        // tslint:disable-next-line: max-line-length
        // this.orderDuplicateForm.patchValue({ orderDateD: this.selectedOrderToDuplicate.orderDate ? this.selectedOrderToDuplicate.orderDate : new Date() });
        this.orderDuplicateForm.patchValue({ orderDateD: new Date() });
        // tslint:disable-next-line: max-line-length
        // this.orderDuplicateForm.patchValue({ shipDateD: this.selectedOrderToDuplicate.requestDate ? this.selectedOrderToDuplicate.requestDate : new Date() });
        this.orderDuplicateForm.patchValue({ shipDateD: new Date() });
        this.orderDuplicateForm.patchValue({ cancelDateD: this.selectedOrderToDuplicate.cancelDate ? this.selectedOrderToDuplicate.cancelDate : '' });
        this.orderDuplicateForm.patchValue({ selectedOrderToDuplicatePoNumber: this.selectedOrderToDuplicatePoNumber });
        this.isValidateDulicate();
    }

    saveDuplicateOrder() {
        this.isSavingData = true;
        const orderID = this.selectedOrderToDuplicate['recordID'];
        const requestPayload = {
            orderDate: this.formatDate(this.orderDuplicateForm.value.orderDateD),
            shipDate: this.formatDate(this.orderDuplicateForm.value.shipDateD),
            cancelDate: this.formatDate(this.orderDuplicateForm.value.cancelDateD),
            retailerID: this.rtSharedService.getRetailerID(),
            retailerShipToID: this.selectedOrderToDuplicate.shipToID,
            poNumber: this.orderDuplicateForm.value.selectedOrderToDuplicatePoNumber
        };
        this.orderService.saveOrderDuplicate(orderID, requestPayload).subscribe(async (response: APIResponse) => {
            if (await response.success) {
                this.isSavingData = false;
                this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Order has been Duplicate Successfully.', life: 5000 });
                this.router.navigate(['/finalize-order'], { queryParams: this.createEditQueryParams(response.data) });
                this.resetForm();
                this.displayOrderDuplicate = false;
            } else {
                this.isSavingData = false;
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
            }
        }, error => {
            this.isSavingData = false;
            if (error.error.error.status === 400) {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
            } else {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
            }
        });
    }

    updateDuplicateChangesMade($event?: any, fieldName?: string) {
        if (fieldName === 'orderDateD') {
            if ($event > this.orderDuplicateForm.value.shipDateD) {
                this.orderDuplicateForm.patchValue({ orderDateD: $event, shipDateD: $event });
            } else {
                this.orderDuplicateForm.patchValue({ orderDateD: $event });
            }
            if (this.orderDuplicateForm.value.cancelDateD && this.orderDuplicateForm.value.shipDateD > this.orderDuplicateForm.value.cancelDateD) {
                this.orderDuplicateForm.patchValue({ shipDateD: this.orderDuplicateForm.value.shipDateD, cancelDateD: this.orderDuplicateForm.value.shipDateD });
            }
        } else if (fieldName === 'shipDateD') {
            if (this.orderDuplicateForm.value.cancelDateD && $event > this.orderDuplicateForm.value.cancelDateD) {
                this.orderDuplicateForm.patchValue({ shipDateD: $event, cancelDateD: $event });
            } else {
                this.orderDuplicateForm.patchValue({ shipDateD: $event });
            }
        } else if (fieldName === 'cancelDateD') {
            this.orderDuplicateForm.patchValue({ cancelDateD: $event });
        }
        this.isValidateDulicate();
    }

    private formatDate(dateValue) {
        if (dateValue !== '' && dateValue !== null) {
            return moment(dateValue).format('MM-DD-YYYY');
        }
        return '';
    }

    private resetForm() {
        this.orderDuplicateForm.reset();
        this.orderDuplicateForm.patchValue({ orderDateD: '' });
        this.orderDuplicateForm.patchValue({ shipDateD: '' });
        this.orderDuplicateForm.patchValue({ cancelDateD: '' });
    }

    createEditQueryParams(order) {
        return {
            orderDate: order.orderDate && order.orderDate.split('T')[0],
            retailerShipToLocationID: order.retailerShipTo.recordID,
            selectedID: order.recordID,
        };
    }

    isValidateDulicate() {
        if (this.orderDuplicateForm.status === 'VALID') {
            this.isValidateDuplicate = true;
        } else {
            this.isValidateDuplicate = false;
        }
    }

    getPaymentToken(index, selectedTerm, paymentTerms) {
        const selectedTermObj = _.find(paymentTerms, o => o.label === selectedTerm);
        if (selectedTermObj && selectedTermObj.value.collectPaymentInformation) {
            try {
                if (this.orderArr[index].orderPaymentTokens[0].cardNumber) {
                    // tslint:disable-next-line:max-line-length
                    return `Applied X${this.orderArr[index].orderPaymentTokens[0].cardNumber} Exp:${this.orderArr[index].orderPaymentTokens[0].expirationMonth}/${this.orderArr[index].orderPaymentTokens[0].expirationYear}`;
                } else {
                    return '';
                }
            } catch (e) {
                return '';
            }
        } else {
            return '';
        }
    }

    addTokens(url: string, params) {
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }

    // B2B-191 (MG-23-MAR-2020) modified to show MFR list
    getLogoPath(recordID) {
        return this.addTokens(this.logoPath, { companyID: recordID });
    }

    validateDateBetweenTwoDates(data) {
        const from = new Date(new RtDatePipe(this.dp).transform(data.discountStartDateString));
        const to = new Date(new RtDatePipe(this.dp).transform(data.discountEndDateString));
        const check = new Date(new RtDatePipe(this.dp).transform(moment(new Date()).format('MM-DD-YYYY')));
        return check <= to && check >= from;
    }

    show_suggested_items(): any {
        $('.suggested-items-block').addClass('show_suggested-items');
        $('body,html').addClass('overflow-hidden');
    }

    close_suggested_items(): any {
        $('.suggested-items-block').removeClass('show_suggested-items');
        $('body,html').removeClass('overflow-hidden');
    }

    show_checkout(): any {
        $('.checkout-block').addClass('show_checkout');
        $('body,html').addClass('overflow-hidden');
    }

    close_checkout(): any {
        $('.checkout-block').removeClass('show_checkout');
        $('body,html').removeClass('overflow-hidden');
    }

    async showAccNumberAlertDialog() {
        return new Promise(async (resolve, reject) => {
            this.confirmationService.confirm({
                header: 'Manufacturer Requires More Information',
                message: 'REQUIREMENT: This manufacturer requires you to have a MFG Cust# & Shipping Ext# for ALL orders sent. <br><br> You may need to contact the Manufacturer to obtain these number.',
                key: 'warn-mfr-details',
                acceptLabel: 'OK',
                rejectVisible: false,
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }
    getShowAccNumberAlertValue(selectedmfr: any, repGroupID: string) {
        const findShowAccNumberAlert = _.find(selectedmfr.repGroupManufacturers, o => (o.repGroupID === repGroupID));
        return objectPath.get(findShowAccNumberAlert, 'showAccNumberAlert', null);
    }

    checkCCNumberInString(value) {
        if (value) {
            let matches;
            matches = value.split(/\s+/);
            if (matches) {
                for (const o of matches) {
                    // tslint:disable-next-line:max-line-length
                    const ccRegex = new RegExp('^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$');
                    const str = o.split('-').join('');
                    const isMatch = ccRegex.test(str);
                    if (isMatch) {
                        this.validationArrayForCC[this.activeIndex].isValidSpecialInstruction = false;
                        break;
                    } else {
                        this.validationArrayForCC[this.activeIndex].isValidSpecialInstruction = true;
                    }
                }
            }
        }
    }
    // getCompanyIntegration(data) {
    //     this.onDestroyCompanyIntegration.next();
    //     this.getBuyerDetails();
    //     this.integrationService.getCompanyIntegration(data, this.auth.getCompanyID()).pipe(takeUntil(this.onDestroyCompanyIntegration)).subscribe((response: any) => {


    //         if (response && response.success) {
    //             this.newMTPaygetUserIntegrationData = _.find(response.data, o => o.companyID === this.auth.getCompanyID());
    //             console.log('newMTPaygetUserIntegrationData >>', this.newMTPaygetUserIntegrationData);
    //             if (this.newMTPaygetUserIntegrationData && this.newMTPaygetUserIntegrationData.isApproved) {
    //                 this.newMTPayBuyerRegisterApprovedOrNot = true;
    //             } else {
    //                 this.newMTPayBuyerRegisterApprovedOrNot = false;
    //             }
    //         }
    //         this.setPaymentTermDDValue();            
    //     }, (error) => {
    //     });
    // }

    async getBuyerDetails() {
        return new Promise((resolve, reject) => {
            this.onDestroygetBuyerDetails.next();
            this.errorMessageService['mtPayBuyerDetails'] = null;
            this.integrationService.getBuyerDetails(this.rtSharedService.getRetailerID()).pipe(takeUntil(this.onDestroygetBuyerDetails)).subscribe((response: any) => {
                if (response && response.success) {
                    this.newMTPaygetBuyerRegisterData = response.data;
                    console.log('Rahul....123456789', this.newMTPaygetBuyerRegisterData);
                    this.newMTPayRetailerQualifyStatus = (this.newMTPaygetBuyerRegisterData && this.newMTPaygetBuyerRegisterData.qualificationStatus) ? this.newMTPaygetBuyerRegisterData.qualificationStatus : '';
                }
                resolve(this.newMTPaygetBuyerRegisterData);
            }, (error) => {
                console.log("err>>>>>", error);
                this.errorMessageService['mtPayBuyerDetails'] = error.message;
                this.msgService.clear();
                this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during getting mtPay buyer details') });
                this.newMTPaygetBuyerRegisterData = null;
                resolve(false);
            });
        });
    }

    // async makeArrayForTransaction(paymentTerm = false) {

    //     const orderLineItemArray = [];
    //     _.forEach(this.orderArr[this.activeIndex].orderDetails, (value, key) => {
    //         orderLineItemArray.push({ price: value.unitPrice, quantity: value.quantity, title: value.itemNumber });
    //     });

    //     let a = {
    //         buyer: {
    //             id: this.newMTPaygetBuyerRegisterData.id,
    //             email: this.newMTPaygetBuyerRegisterData.email,
    //         },
    //         plan: {
    //             planType: 'installments',
    //             installments: 1
    //         },
    //         allowedPaymentMethods: [
    //             'invoice',
    //             'bank',
    //             'check',
    //             'creditCard',
    //             'payWithTerms'
    //         ],
    //         lines: [
    //             {
    //                 tax: 0,
    //                 shippingPrice: 0,
    //                 discount: 0,
    //                 vendorId: this.getmtPayVendorId(this.orderArr[this.activeIndex]),
    //                 lineItems: orderLineItemArray
    //             }
    //         ],
    //         autoPayouts: false,
    //         currency: 'USD',
    //         externalReferenceId: this.orderArr[this.activeIndex].recordID,
    //         // financingConfig: paymentTerm ? (this.newMTPayPaymentTermDDModel.day && this.newMTPayPaymentTermDDModel.day > 0) ?  this.newMTPayPaymentTermDDModel.day  : null : null,
    //         financingConfig: (paymentTerm ) ? {financingNetDays : this.newMTPayPaymentTermDDModel.day} : null,
    //     };
    //     this.newMTPaySavedTransactionArray[this.activeIndex] = a;
    //     console.log('>>>>>>>>>>>>>>>01', this.newMTPaySavedTransactionArray[this.activeIndex], paymentTerm);
    //     const mtPayTxnId = this.getmtPayOpenTxnId(this.orderArr[this.activeIndex]);
    //     if (mtPayTxnId) {
    //         this.newMTPaySavedTransactionArray[this.activeIndex]['authAmount'] = this.orderArr[this.activeIndex].orderTotal;
    //         console.log('>>>>>>>>>>>>>>>12', this.newMTPaySavedTransactionArray[this.activeIndex]);
    //         await this.transactionsUpdateAPI(mtPayTxnId).then(async (res) => {
    //             console.log('>>>>>>>>>>>>>>>1212', res);
    //             if (res.success && res.data) {
    //                 this.afterTxnUpdateOrder(paymentTerm);
    //             } else {
    //                 this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(res, 'error.message', 'Error occurred during add transaction service request')});
    //             }
    //         }).catch(response => {
    //             this.msgService.clear();
    //             this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response, 'error.message', 'Error occurred during update transaction service request')}); 
    //         }); 
    //         ;
    //     } else {
    //         console.log('>>>>>>>>>>>>>>>34', this.orderArr[this.activeIndex].recordID, this.newMTPaySavedTransactionArray[this.activeIndex]);
    //         this.loadCallTransactionAPI = true;
    //         await this.callTransactionAPI().then(async (res) => {
    //             console.log('>>>>>>>>>>>>>>>3434', res);
    //             this.loadCallTransactionAPI = false;
    //             if (res.success && res.data) {
    //                 const mtPayTxnDetails = this.orderArr[this.activeIndex].mtPayTransactionDetails;
    //                 if (mtPayTxnDetails) {
    //                     mtPayTxnDetails.push(res.data);
    //                 }
    //                 this.afterTxnUpdateOrder(paymentTerm);
    //             } else {
    //                 this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(res, 'error.message', 'Error occurred during add transaction service request')}); 
    //             }
    //         }).catch(response => {
    //             this.loadCallTransactionAPI = false;
    //             this.msgService.clear();
    //             this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response, 'error.message', 'Error occurred during add transaction service request')}); 
    //         });
    //     }
    // }
    /*async mtPayOrderTransaction(mtPayTxnId?: any) {
        const orderLineItemArray = [];
        _.forEach(this.orderArr[this.activeIndex].orderDetails, (value, key) => {
            orderLineItemArray.push({ price: value.unitPrice, quantity: value.quantity, title: value.itemNumber });
        });
        let a = {
            buyer: {
                id: this.newMTPaygetBuyerRegisterData.id,
                email: this.newMTPaygetBuyerRegisterData.email,
            },
            plan: {
                planType: 'installments',
                installments: 1
            },
            allowedPaymentMethods: [
                'invoice',
                'bank',
                'check',
                'creditCard',
                'payWithTerms'
            ],
            lines: [
                {
                    tax: 0,
                    shippingPrice: 0,
                    discount: 0,
                    vendorId: this.getmtPayVendorId(this.orderArr[this.activeIndex]),
                    lineItems: orderLineItemArray
                }
            ],
            autoPayouts: false,
            currency: 'USD',
            externalReferenceId: this.orderArr[this.activeIndex].recordID,
            financingConfig: (this.newMTPaySelectedPaymentMethods.type === 'creditLimit' && this.newMTPayPaymentTermDDModel) ? { financingNetDays: this.newMTPayPaymentTermDDModel.day } : null,
            isFinanced: this.newMTPaySelectedPaymentMethods.type === 'creditLimit' && (this.newMTPayPaymentTermDDModel && (this.newMTPayPaymentTermDDModel.day === 30 || this.newMTPayPaymentTermDDModel.day === 60 || this.newMTPayPaymentTermDDModel.day === 90)) ? true : false
        };
        this.newMTPaySavedTransactionArray[this.activeIndex] = a;
        this.loadCallTransactionAPI = true;
        if (mtPayTxnId) {
            this.newMTPaySavedTransactionArray[this.activeIndex]['authAmount'] = this.orderArr[this.activeIndex].orderTotal;
            await this.transactionsUpdateAPI(mtPayTxnId).then(async (res) => {
                this.loadCallTransactionAPI = false;
                if (res.success && res.data) {
                    this.mtPayUpdateOrder(true);
                } else {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(res, 'error.message', 'Error occurred during add transaction service request') });
                }
            }).catch(response => {
                this.loadCallTransactionAPI = false;
                this.msgService.clear();
                this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response, 'error.message', 'Error occurred during update transaction service request') });
            });
            ;
        } else {
            await this.callTransactionAPI().then(async (res) => {
                this.loadCallTransactionAPI = false;
                if (res.success && res.data) {
                    const mtPayTxnDetails = this.orderArr[this.activeIndex].mtPayTransactionDetails;
                    if (mtPayTxnDetails) {
                        mtPayTxnDetails.push(res.data);
                    }
                    this.mtPayUpdateOrder(true);
                    if (this.newMTPaySavedTransactionArray[this.activeIndex]['isFinanced']) {
                        this.callTransactionFinance();
                    }
                } else {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(res, 'error.message', 'Error occurred during add transaction service request') });
                }
            }).catch(response => {
                this.loadCallTransactionAPI = false;
                this.msgService.clear();
                this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response, 'error.message', 'Error occurred during add transaction service request') });
            });
        }
    }*/
    /*async transactionsUpdateAPI(txnId: any) {
        try {
            const res = await this.integrationService.transactionsUpdate(txnId, this.newMTPaySavedTransactionArray[this.activeIndex]);
            return Promise.resolve(res);
        } catch (e) {
            return Promise.reject(e);
        }
    }*/

    /*async callTransactionAPI() {
        try {
            const res = await this.integrationService.transactions(this.orderArr[this.activeIndex].recordID, this.newMTPaySavedTransactionArray[this.activeIndex]);
            return Promise.resolve(res);
        } catch (e) {
            return Promise.reject(e);
        }
    }*/

    // New Payment Term Method Start

    setPaymentTermDDValue() {
        console.log('Rahul....setPaymentTermDDValue', this.newMTPaygetBuyerRegisterData, this.newMTPayBuyerRegisterApprovedOrNot,
         this.newMTPayRetailerQualifyStatus, this.orderArr[this.activeIndex]);
        this.newMTPayPaymentTermDropDownArray = [];
        if (_.isEmpty(this.newMTPaygetBuyerRegisterData)) {
            this.newMTPayPaymentTermDropDownArray.push(
                { label: 'Get Approved for Net Terms', value: { day: -1, percentage: 0, retailerStatus: 'notRegistered' } }
            );
            this.newMTPayretailerStepFrom = 0;
        // } else if (this.newMTPayRetailerQualifyStatus !== 'completed') {
        } else if (!this.newMTPayBuyerRegisterApprovedOrNot || this.newMTPayRetailerQualifyStatus !== 'completed') {
            this.newMTPayPaymentTermDropDownArray.push(
                { label: 'Get Approved for Net Terms', value: { day: -1, percentage: 0, retailerStatus: 'notApproved' } }
            );
            this.newMTPayretailerStepFrom = 2;
        } else {
            const orderTotal = this.orderArr[this.activeIndex].orderTotal;
            this.newMTPayPaymentTermDropDownArray = [
                { label: 'Pay Upon Shipment', value: { day: 0, percentage: 0, retailerStatus: 'approved' } },
                // { label: 'Pay 30 Days After Shipment', value: { day: 30, percentage: 2, retailerStatus: 'approved' }},
                // { label: 'Pay 60 Days After Shipment', value: { day: 60, percentage: 3.5, retailerStatus: 'approved' }},
                // { label: 'Pay 90 Days After Shipment', value: { day: 90, percentage: 4.9, retailerStatus: 'approved' }}
            ];
            _.forEach(this.getmtPayVendorAllowPaymentDays(this.orderArr[this.activeIndex]), (res) => {
                this.newMTPayPaymentTermDropDownArray.push({label: `Pay ${res} Days After Shipment`, value: { day: Number(res), retailerStatus: 'approved' },
                 disabled: this.newMTPayretaileravilablecl <= orderTotal});
            });
            console.log('resresresres', this.newMTPayPaymentTermDropDownArray);
        }

        this.newMTPayPaymentMethods = [
            { label: 'Pay With Bank Transfer', value: { value: 1, icon: '/assets/mtpay/bank.svg', type: 'bank' } },
            { label: 'Pay With Credit Card', value: { value: 2, icon: '/assets/mtpay/creditCard.svg', type: 'creditCard' } },
            // { label: 'Pay With Credit Limit', value: { value: 3, icon: '/assets/mtpay/creditLimit.svg', type: 'creditLimit' },
            //  disabled: this.newMTPayretaileravilablecl <= orderTotal }
        ];
        this.newMTPayBankDetails = [];
        if (this.newMTPaygetBuyerRegisterData && this.newMTPaygetBuyerRegisterData.paymentData && this.newMTPaygetBuyerRegisterData.paymentData.banks) {
            _.forEach(this.newMTPaygetBuyerRegisterData.paymentData.banks, (o) => {
                this.newMTPayBankDetails.push({ label: `${o.institutionName} (${o.accountName})`, value: o });
            });
        }
        this.newMTPayBankDetails.push({ label: 'Add New Bank Details', value: '' });

    }
    loadPaymentDetails() {
        /*this.newMTPayCreditCardDetailsSpinner = true;
        this.onDestroyGetPaymentDetails.next();
        this.integrationService.getPaymentDetails().pipe(takeUntil(this.onDestroyGetPaymentDetails)).subscribe((response: any) => {
            this.newMTPayCreditCardDetailsSpinner = false;
            if (response && response.success) {
                this.newMTPayCreditCardDetails = response.data;
                this.newMTPayCreditCardDetails = _.map(this.newMTPayCreditCardDetails, (o) => {
                    const a: any = {};
                    a.label = o.last4;
                    a.value = o;
                    return a;
                });
                console.log('DSP : --- ', this.newMTPayCreditCardDetails);
            } else {
                console.log('DSP : --- ', response);
            }
            this.newMTPayCreditCardDetails.push({ label: 'Add New Card', value: '' });
            this.initmtPayAutoFillData();
        }, (error) => {
            this.newMTPayCreditCardDetailsSpinner = false;
        });*/
        console.log('Rahul....loadPaymentDetails');
        if (this.newMTPaygetBuyerRegisterData && this.newMTPaygetBuyerRegisterData.paymentData && this.newMTPaygetBuyerRegisterData.paymentData.creditCards) {
            this.newMTPayCreditCardDetails = this.newMTPaygetBuyerRegisterData.paymentData.creditCards;
            this.newMTPayCreditCardDetails = _.map(this.newMTPayCreditCardDetails, (o) => {
                o['paymentID']  = _.cloneDeep(o['id']);
                const a: any = {};
                a.label = o.last4;
                a.value = o;
                return a;
            });
        }
        this.newMTPayCreditCardDetails.push({ label: 'Add New Card', value: '' });
        this.initmtPayAutoFillData();
    }

    initmtPayAutoFillData() {
        // if (this.newMTPayCreditCardDetails && this.newMTPayCreditCardDetails.length && !this.newMTPayCreditCardDetails[0].value) {
        //     this.seletedCreditCard = this.newMTPayCreditCardDetails[0].value;
        // }
        const mtPayPaymentDetails = _.cloneDeep(this.getmtPayPaymentDetails(this.orderArr[this.activeIndex]));
        const findPaymentMethod = _.find(this.newMTPayPaymentMethods, o => (o.value && o.value.type === (mtPayPaymentDetails && mtPayPaymentDetails['paymentMethod'])));
        // if (!this.mtPayPaymentDeclined) {
        this.newMTPaySelectedPaymentMethods = findPaymentMethod ? findPaymentMethod.value : findPaymentMethod;
        // } else {
        //     this.newMTPaySelectedPaymentMethods = null;
        // }

        const findPaymentTerm = _.find(this.newMTPayPaymentTermDropDownArray, o => (o.value && o.value.day) === (mtPayPaymentDetails && mtPayPaymentDetails['paymentTerms']));
        this.newMTPayPaymentTermDDModel = findPaymentTerm ? findPaymentTerm.value : findPaymentTerm;
        this.finalSelectedCard = {};
        this.selectedBankDetailModel = null;
        this.appliedBankDetail = null;
        console.log('????????', this.newMTPayPaymentTermDropDownArray, findPaymentTerm, this.newMTPayPaymentTermDDModel, mtPayPaymentDetails, this.newMTPaygetBuyerRegisterData);
        if (mtPayPaymentDetails) {
            if (mtPayPaymentDetails['paymentMethod'] === 'creditCard') {
                _.forEach(this.newMTPayCreditCardDetails, (value, key) => {
                    console.log('>>>>newMTPayCreditCardDetails', value);
                    if (mtPayPaymentDetails['paymentMethodID'] && mtPayPaymentDetails['paymentMethodID'] === value.value.paymentID) {
                        this.finalSelectedCard = value.value;
                        console.log('>>>>>>>>value1', this.newMTPayCreditCardDetails, this.newMTPayPaymentMethods, value);
                    }
                });
            }
            if (mtPayPaymentDetails['paymentMethod'] === 'bank' && this.newMTPaygetBuyerRegisterData && this.newMTPaygetBuyerRegisterData.paymentData && this.newMTPaygetBuyerRegisterData.paymentData.banks) {
                _.forEach(this.newMTPaygetBuyerRegisterData.paymentData.banks, (value, key) => {
                    if (mtPayPaymentDetails['paymentMethodID'] && mtPayPaymentDetails['paymentMethodID'] === value.id) {
                        this.selectedBankDetailModel = value;
                        this.appliedBankDetail = value;
                        console.log('>>>>>>>>value2', this.newMTPaygetBuyerRegisterData, this.appliedBankDetail);
                    }
                });
            }
        }
        console.log('Rahul....initmtPayAutoFillData', this.mtPayPaymentDeclined, this.seletedCreditCard, this.newMTPayCreditCardDetails);
    }

    mtPaySelectBankDetail($event) {
        console.log('Rahul....123', $event);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
        if (!$event.value) {
            this.mtpayShowBankConnectionLoading = true;
            this.onDestroygetBuyerBank.next();
            this.integrationService.getBankConnectionWindow(this.rtSharedService.getRetailerID()).pipe(takeUntil(this.onDestroygetBuyerBank)).subscribe((response: any) => {
                this.mtpayShowBankConnectionLoading = false;
                if (response && response.success) {
                    this.mtpayShowBankConnectionDialog = true;
                    this.mtpaylinkBankIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response.data['validationLink']);
                } else {
                    this.mtpaylinkBankIframeSrc = null;
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response.error, 'message', 'Error occurred during services request') });
                }
            }, (error) => {
                this.mtpayShowBankConnectionLoading = false;
                this.mtpaylinkBankIframeSrc = null;
                this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
            });
        }
    }
    // callTransactionFinance() {
    //     this.loadCallTransactionFinance = true;
    //     this.onDestroyTransactionFinance.next();
    //     const mtPayTxnId = this.getmtPayOpenTxnId(this.orderArr[this.activeIndex]);
    //     if (mtPayTxnId) {
    //         this.integrationService.transactionFinance(mtPayTxnId).pipe(takeUntil(this.onDestroyTransactionFinance)).subscribe((response: any) => {
    //             if (response && response.success) {
    //                 const saveTransactionUpdate = JSON.parse(response.data);
    //                 console.log('DSP2729', saveTransactionUpdate);
    //                 this.loadCallTransactionFinance = false;
    //                 this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Your qualification status: ' + saveTransactionUpdate.status });
    //             } else {
    //                 this.loadCallTransactionFinance = false;
    //                 this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response.error, 'message', 'Error occurred during services request') });
    //             }
    //         }, (error) => {
    //             this.loadCallTransactionFinance = false;
    //             this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    //         });
    //     }
    // }

    newMTPayPaymentTermDropDownOnChange($event) {
        this.showGetBalancePopup = false;
        console.log('Rahul....1234', this.newMTPayPaymentTermDDModel);
        if (this.newMTPayPaymentTermDDModel.day === -1) {
            this.showGetBalancePopup = true;
            if (this.newMTPayPaymentTermDDModel && this.newMTPayPaymentTermDDModel.retailerStatus === 'notRegistered') {
                this.newMTPayretailerStepFrom = 0;
            } else if (this.newMTPayPaymentTermDDModel && this.newMTPayPaymentTermDDModel.retailerStatus === 'notApproved') {
                this.newMTPayretailerStepFrom = 2;
            }
            setTimeout((res) => {
                this.newMTPayPaymentTermDDModel = null;
            }, 0);
        } else {
            if (!this.newMTPaySelectedPaymentMethods) {
                setTimeout((res) => {
                    this.newMTPayPaymentTermDDModel = null;
                }, 0);
                this.msgService.add({ severity: 'warn', summary: 'Payment Method', detail: 'Please select payment method.' });
                return false;
            } else {
                this.mtPayUpdateOrder(false);
            }
        }
    }
    getApprovedForNetTermsClick() {
        this.showGetBalancePopup = true;
        setTimeout((res) => {
            this.newMTPayPaymentTermDDModel = null;
        }, 0);
    }

    newMTPayselectPaymentMethod($event: any) {
        console.log('newMTPayselectPaymentMethod >>', $event);
        this.newMTPayPaymentMethodsPopup = false;
        this.showGetBalancePopup = false;
        if ($event.value.value > 0) {
            this.newMTPaySelectedPaymentMethods = $event.value;
            if ($event.value.value === 1) {
                if (_.isEmpty(this.newMTPaygetBuyerRegisterData)) {
                    console.log('>>>>>>>>>>>>>', this.newMTPaygetBuyerRegisterData);
                    this.newMTPayretailerStepFrom = 0;
                    this.showGetBalancePopup = true;
                } else {
                    this.newMTPayPaymentMethodsPopup = true;
                    // this.appliedBankDetail = null;
                }
            } else if ($event.value.value === 2) {
                if (_.isEmpty(this.newMTPaygetBuyerRegisterData)) {
                    this.newMTPayretailerStepFrom = 0;
                    this.showGetBalancePopup = true;
                } else {
                    this.clickOnCreditCardMethod();
                    this.newMTPayPaymentMethodsPopup = true;
                    console.log('Rahul....123456', this.seletedCreditCard);
                }
            } else if ($event.value.value === 3) {
                // const mtPayPaymentDetails = this.getmtPayPaymentDetails(this.orderArr[this.activeIndex]);
                // this.newMTPayretailerStepFrom = 0;
                // if (_.isEmpty(this.newMTPaygetBuyerRegisterData)) {
                //     this.showGetBalancePopup = true;
                // } else if (!this.newMTPayBuyerRegisterApprovedOrNot || this.newMTPayRetailerQualifyStatus !== 'completed') {
                //     this.showGetBalancePopup = true;
                //     this.newMTPayretailerStepFrom = 2;
                // } else {
                //     this.finalSelectedCard = {};
                //     this.mtPayUpdateOrder(false);
                // }
            }
        } else {
            this.closePaymentMethodPopup();
        }
    }

    newMTPayclickPaymentMethod(optionData: any) {
        // if (this.mtPayPaymentDeclined) {
            console.log('Rahul....newMTPayclickPaymentMethod', optionData, this.newMTPaySelectedPaymentMethods);
            this.newMTPayselectPaymentMethod({value: optionData.value});
        // }
    }

    clickOnCreditCardMethod() {
        this.monthOptions = [{ label: 'Month', value: null }];

        for (let index = 1; index <= 12; index++) {
            this.monthOptions.push({ label: `${index <= 9 ? '0' : ''}${index}`, value: `${index <= 9 ? '0' : ''}${index}` });
        }
        this.yearOptions = [{ label: 'Year', value: null }];
        const fullYear = new Date().getFullYear();
        for (let i = 0; i < 20; i++) {
            this.yearOptions.push({
                label: ('' + (fullYear + i)), value: ('' + (fullYear + i))
            });
        }

        if (_.isEmpty(this.newMTPaygetBuyerRegisterData)) {
            this.applyOrSaveBtnLabel = this.mtPayPaymentDeclined ? 'Save & Update' : 'Save & Apply';
            this.fbCreditCard = this.fb.group({
                email: ['', [Validators.required, Validators.email]],
                cardNumber: ['', [Validators.required, Validators.minLength(12), luhnCheck()]],
                cardCVC: ['', [Validators.required]],
                cardMonth: ['', [Validators.required]],
                cardYear: ['', [Validators.required]],
            });
            setTimeout(() => {
                const getretailer = this.orderArr[this.activeIndex].retailer;
                this.fbCreditCard.get('email').patchValue(getretailer.email);
                this.onFocusOutEvent();
            });
        } else {
            this.applyOrSaveBtnLabel = this.mtPayPaymentDeclined ? 'Save & Update' : 'Save & Apply';
            this.fbCreditCard = this.fb.group({
                cardNumber: ['', [Validators.required, Validators.minLength(12), luhnCheck()]],
                cardCVC: ['', [Validators.required]],
                cardMonth: ['', [Validators.required]],
                cardYear: ['', [Validators.required]],
            });
        }
        console.log('DSP2774', this.fbCreditCard);
    }
    openPaymentMethodPopup() {
        console.log('openPaymentMethodPopup >>>>>>', this.newMTPaySelectedPaymentMethods);
        if (this.newMTPaySelectedPaymentMethods && this.newMTPaySelectedPaymentMethods['value'] === 2) {
            this.clickOnAddNewCard = true;
            const x = this.getmtPayPaymentDetails(this.orderArr[this.activeIndex]);
            let matchMTPayCCObj;
            if (x) {
                matchMTPayCCObj = _.find(_.cloneDeep(this.newMTPayCreditCardDetails), o => o.value && o.value.paymentID === x['paymentMethodID']);
                this.clickOnAddNewCard = false;
                this.applyOrSaveBtnLabel = this.mtPayPaymentDeclined ? 'Update' : 'Apply';
                this.seletedCreditCard = matchMTPayCCObj ? matchMTPayCCObj.value : {};
                this.mtPaySelectCreditCardDD({ value: matchMTPayCCObj ? matchMTPayCCObj.value : null });
            } else {
                this.seletedCreditCard = {};
                this.mtPaySelectCreditCardDD({ value: '' });
            }
        } else if (this.newMTPaySelectedPaymentMethods && this.newMTPaySelectedPaymentMethods['value'] === 3) {
            this.clickOnAddNewCard = true;
        }
    }
    mtPaySelectCreditCardDD($event) {
        console.log('DSP02779', $event, this.seletedCreditCard);

        if (_.isEmpty(this.seletedCreditCard)) {
            this.applyOrSaveBtnLabel = this.mtPayPaymentDeclined ? 'Save & Update' : 'Save & Apply';
            this.clickOnAddNewCard = true;
        }

        if ($event.value && this.seletedCreditCard && !_.isEmpty(this.seletedCreditCard)) {
            this.applyOrSaveBtnLabel = this.mtPayPaymentDeclined ? 'Update' : 'Apply';
            this.clickOnAddNewCard = false;

            this.fbCreditCard.controls.cardNumber.patchValue(this.seletedCreditCard.last4);
            this.fbCreditCard.controls.cardCVC.patchValue(this.seletedCreditCard.cvc);
            this.fbCreditCard.controls.cardMonth.patchValue(
                (Number(this.seletedCreditCard.expiredMonth) <= 9) ? _.toString(0 + this.seletedCreditCard.expiredMonth) : _.toString(this.seletedCreditCard.expiredMonth));
            this.fbCreditCard.controls.cardYear.patchValue(_.toString(this.seletedCreditCard.expiredYear));

            this.fbCreditCard.get('cardNumber').disable();
            this.fbCreditCard.get('cardCVC').disable();
            this.fbCreditCard.get('cardMonth').disable();
            this.fbCreditCard.get('cardYear').disable();

        } else {
            this.fbCreditCard.reset();
            this.fbCreditCard.get('cardNumber').enable();
            this.fbCreditCard.get('cardCVC').enable();
            this.fbCreditCard.get('cardMonth').enable();
            this.fbCreditCard.get('cardYear').enable();
        }
    }

    // onCreditCardFormSubmit($event) {

    //     const newMTBuyerRegDataEmptyFlag = _.isEmpty(this.newMTPaygetBuyerRegisterData);
    //     const selectedCCEmptyFlag = _.isEmpty(this.seletedCreditCard);

    //     console.log('fbCreditCard >>', !this.fbCreditCard.valid, this.fbCreditCard);
    //     if (!this.fbCreditCard.valid && this.fbCreditCard.status !== "DISABLED") {
    //         this.htmlTools.validateAllFields(this.fbCreditCard);
    //     } else {
    //         this.confirmationService.confirm({
    //             message: 'Are you sure you want to pay with this credit card? because once you confirm you can not update CC and items details for this order.',
    //             header: 'Confirm payment',
    //             icon: 'pi pi-exclamation-triangle',
    //             acceptLabel: 'Yes',
    //             rejectLabel: 'No',
    //             accept: async () => {
    //                 if (!newMTBuyerRegDataEmptyFlag && !selectedCCEmptyFlag) {
    //                     // For existing CC selection and avilable buyer reg
    //                     this.finalSelectedCard = this.seletedCreditCard;
    //                     this.newMTPayPaymentMethodsPopup = false;
    //                     this.makeArrayForTransaction(false);
    //                     return;
    //                 } else if (!newMTBuyerRegDataEmptyFlag && selectedCCEmptyFlag && this.clickOnAddNewCard) {
    //                     // For new CC selection and avilable buyer reg
    //                     const requestPayload: any = {
    //                         number: this.fbCreditCard.value.cardNumber,
    //                         expirationMonth: this.fbCreditCard.value.cardMonth,
    //                         expirationYear: this.fbCreditCard.value.cardYear,
    //                         cvc: this.fbCreditCard.value.cardCVC
    //                     };
    //                     console.log('Rahul....2', requestPayload, this.seletedCreditCard);
    //                     this.creditCardApplyloading = true;
    //                     this.onDestroySavePaymentDetails.next();
    //                     this.integrationService.savePaymentCard(requestPayload, false).pipe(takeUntil(this.onDestroySavePaymentDetails)).subscribe((response: any) => {
    //                         this.creditCardApplyloading = false;
    //                         if (response && response.success) {
    //                             this.newMTPayCreditCardDetails = _.filter(this.newMTPayCreditCardDetails, (data, index) => {
    //                                 if (data.value) {
    //                                     return data;
    //                                 }});
    //                                 this.seletedCreditCard = response.data;
    //                                 this.newMTPayCreditCardDetails.push({ label: response.data.last4, value: response.data});
    //                                 this.newMTPayCreditCardDetails.push({ label: 'Add New Card', value: '' });
    //                                 // this.fbCreditCard.reset();
    //                                 this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Card added successfully' });
    //                                 this.finalSelectedCard = this.seletedCreditCard;
    //                                 this.makeArrayForTransaction(false);
    //                                 this.newMTPayPaymentMethodsPopup = false;
    //                             } else {
    //                                 this.creditCardApplyloading = false;
    //                                 this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response.error.message, 'message', 'Error occurred during services request') });
    //                             }
    //                         }, (error) => {
    //                             this.creditCardApplyloading = false;
    //                             this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    //                         });
    //                     return;
    //                 } else if (newMTBuyerRegDataEmptyFlag && selectedCCEmptyFlag) {
    //                     // For new CC selection with new buyer reg
    //                     const requestPayload: any = {
    //                         number: this.fbCreditCard.value.cardNumber,
    //                         expirationMonth: this.fbCreditCard.value.cardMonth,
    //                         expirationYear: this.fbCreditCard.value.cardYear,
    //                         cvc: this.fbCreditCard.value.cardCVC,
    //                         email: this.fbCreditCard.value.email
    //                     };
    //                     console.log('Rahul....3', requestPayload);
    //                     this.creditCardApplyloading = true;
    //                     this.onDestroySavePaymentDetails.next();
    //                     this.integrationService.savePaymentCard(requestPayload, true).pipe(takeUntil(this.onDestroySavePaymentDetails)).subscribe((response: any) => {
    //                         if (response && response.success) {
    //                             this.newMTPayCreditCardDetails = _.filter(this.newMTPayCreditCardDetails, (data, index) => {
    //                                 if (data.value) {
    //                                     return data;
    //                                 }
    //                             });
    //                             this.seletedCreditCard = response.data;
    //                             this.newMTPayCreditCardDetails.push({ label: response.data.last4, value: response.data });
    //                             this.newMTPayCreditCardDetails.push({ label: 'Add New Card', value: '' });
    //                             // this.fbCreditCard.reset();
    //                             this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Card added successfully' });
    //                             this.finalSelectedCard = this.seletedCreditCard;
    //                             this.getBuyerDetails().then(res => {
    //                                 this.creditCardApplyloading = false;
    //                                 this.makeArrayForTransaction(false);
    //                                 this.newMTPayPaymentMethodsPopup = false;
    //                             });

    //                         } else {
    //                             this.creditCardApplyloading = false;
    //                             this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response.error.message, 'message', 'Error occurred during services request') });
    //                         }
    //                     }, (error) => {
    //                         this.creditCardApplyloading = false;
    //                         this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    //                     });
    //                     return;
    //                 }
    //                 return;
    //             },
    //             reject: () => {

    //             }
    //         });
    //     }
    // }
    onPaymentMethodChangesConfirmation(paymentmethod: any) {
        if (this.mtPayPaymentDeclined) {
            this.confirmationService.confirm({
                message: 'Please confirm you would like to update your payment method. Your new payment method will be transmitted to the vendor so they can fulfill your order',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: async () => {
                    if (paymentmethod === 'cc') {
                        this.applymtPayBankSelection();
                    } else if (paymentmethod === 'bank') {
                        this.onCreditCardFormSubmitNew();
                    }
                },
                reject: () => {
                }
            });
        } else {
            if (paymentmethod === 'cc') {
                this.applymtPayBankSelection();
            } else if (paymentmethod === 'bank') {
                this.onCreditCardFormSubmitNew();
            }
        }
    }
    onCreditCardFormSubmitNew() {

        const newMTBuyerRegDataEmptyFlag = _.isEmpty(this.newMTPaygetBuyerRegisterData);
        const selectedCCEmptyFlag = _.isEmpty(this.seletedCreditCard);

        console.log('fbCreditCard >>', !this.fbCreditCard.valid, this.fbCreditCard);
        if (!this.fbCreditCard.valid && this.fbCreditCard.status !== "DISABLED") {
            this.htmlTools.validateAllFields(this.fbCreditCard);
        } else {
            if (!newMTBuyerRegDataEmptyFlag && !selectedCCEmptyFlag) {
                // For existing CC selection and avilable buyer reg
                this.finalSelectedCard = this.seletedCreditCard;
                this.appliedBankDetail = null;
                this.mtPayUpdateOrder(false);
                this.newMTPayPaymentMethodsPopup = false;
                return;
            } else if (!newMTBuyerRegDataEmptyFlag && selectedCCEmptyFlag && this.clickOnAddNewCard) {
                // For new CC selection and avilable buyer reg
                const requestPayload: any = {
                    number: this.fbCreditCard.value.cardNumber,
                    expirationMonth: this.fbCreditCard.value.cardMonth,
                    expirationYear: this.fbCreditCard.value.cardYear,
                    cvc: this.fbCreditCard.value.cardCVC
                };
                console.log('Rahul....2', requestPayload, this.seletedCreditCard);
                this.creditCardApplyloading = true;
                this.onDestroySavePaymentDetails.next();
                this.integrationService.savePaymentCard(requestPayload, false).pipe(takeUntil(this.onDestroySavePaymentDetails)).subscribe((response: any) => {
                    this.creditCardApplyloading = false;
                    if (response && response.success) {
                        this.newMTPayCreditCardDetails = _.filter(this.newMTPayCreditCardDetails, (data, index) => {
                            if (data.value) { return data; }
                        });
                        // response.data['expiredMonth']  = _.cloneDeep(response.data['exp_month']);
                        // response.data['expiredYear']  = _.cloneDeep(response.data['exp_year']);
                        this.seletedCreditCard = response.data;
                        this.newMTPayCreditCardDetails.push({ label: response.data.last4, value: response.data });
                        this.newMTPayCreditCardDetails.push({ label: 'Add New Card', value: '' });
                        // this.fbCreditCard.reset();
                        this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Card added successfully' });
                        this.finalSelectedCard = this.seletedCreditCard;
                        this.appliedBankDetail = null;
                        this.mtPayUpdateOrder(false);
                        this.newMTPayPaymentMethodsPopup = false;
                    } else {
                        this.creditCardApplyloading = false;
                        this.newMTPayPaymentMethodsPopup = false;
                        this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response.error, 'message', 'Error occurred during services request') });
                    }
                }, (error) => {
                    this.creditCardApplyloading = false;
                    this.newMTPayPaymentMethodsPopup = false;
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
                });
                return;
            } else if (newMTBuyerRegDataEmptyFlag && selectedCCEmptyFlag) {
                // For new CC selection with new buyer reg
                const requestPayload: any = {
                    number: this.fbCreditCard.value.cardNumber,
                    expirationMonth: this.fbCreditCard.value.cardMonth,
                    expirationYear: this.fbCreditCard.value.cardYear,
                    cvc: this.fbCreditCard.value.cardCVC,
                    email: this.fbCreditCard.value.email
                };
                console.log('Rahul....3', requestPayload);
                this.creditCardApplyloading = true;
                this.onDestroySavePaymentDetails.next();
                this.integrationService.savePaymentCard(requestPayload, true).pipe(takeUntil(this.onDestroySavePaymentDetails)).subscribe((response: any) => {
                    if (response && response.success) {
                        this.newMTPayCreditCardDetails = _.filter(this.newMTPayCreditCardDetails, (data, index) => {
                            if (data.value) {
                                return data;
                            }
                        });
                        // response.data['expiredMonth']  = _.cloneDeep(response.data['exp_month']);
                        // response.data['expiredYear']  = _.cloneDeep(response.data['exp_year']);
                        this.seletedCreditCard = response.data;
                        this.newMTPayCreditCardDetails.push({ label: response.data.last4, value: response.data });
                        this.newMTPayCreditCardDetails.push({ label: 'Add New Card', value: '' });
                        // this.fbCreditCard.reset();
                        this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Card added successfully' });
                        this.finalSelectedCard = this.seletedCreditCard;
                        this.appliedBankDetail = null;
                        this.getBuyerDetails().then(res => {
                            this.creditCardApplyloading = false;
                            if (res) {
                                this.mtPayUpdateOrder(false);
                                this.newMTPayPaymentMethodsPopup = false;
                            }
                        });

                    } else {
                        this.creditCardApplyloading = false;
                        this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response.error, 'message', 'Error occurred during services request') });
                    }
                }, (error) => {
                    this.creditCardApplyloading = false;
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
                });
                return;
            }
        }
    }
    applymtPayBankSelection() {
        this.finalSelectedCard = {};
        this.appliedBankDetail = _.cloneDeep(this.selectedBankDetailModel);
        this.mtPayUpdateOrder(false);
        this.newMTPayPaymentMethodsPopup = false;
    }
    get validateChk() {
        return this.fbCreditCard.controls;
    }
    closePaymentMethodPopup() {
        this.newMTPayPaymentMethodsPopup = false;
        console.log('>>>>>test', this.seletedCreditCard, this.finalSelectedCard, this.orderArr[this.activeIndex]);
        // if (!this.finalSelectedCard || JSON.stringify(this.finalSelectedCard) === '{}') {
        //     this.newMTPaySelectedPaymentMethods = null;
        // }
        this.initmtPayAutoFillData();
    }

    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.pattern && field.errors.pattern.requiredPattern === '^[0-9]*$') {
            return 'Enter numeric value';
        } else if (field.errors.pattern && field.errors.pattern.requiredPattern
            === '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$') {
            return 'Enter website url e.g. http://www.eg.com or https://www.eg.com';
        } else if (field.errors.pattern) {
            return 'Enter valid value';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
        if (field.errors.maxlength) {
            return 'Maximum length exceed for ' + name;
        }
        if (field.errors.minlength && field.errors.minlength.actualLength) {
            return `${name} must be minimum of ${field.errors.minlength.requiredLength} length`;
        }
        if (field.errors.ccValidation) {
            return 'Enter Valid Credit Card Number';
        }
        if (field.errors.emailIsTaken) {
            return 'The email is already registered';
        }
    }

    applyForContinueBtn() {
        this.mtPayUpdateOrder(false);
    }
    // afterTxnUpdateOrder(paymentTerm = false) {
    //     let sendedData = '';
    //     const mtPayOrderPaymentDetails = {
    //         paymentMethod: 'creditCard',
    //         paymentTerms: this.newMTPayPaymentTermDDModel.day,
    //         paymentMethodID: this.finalSelectedCard.paymentID
    //     }
    //     console.log('sendedData >>', mtPayOrderPaymentDetails, paymentTerm);
    //     sendedData = JSON.stringify(mtPayOrderPaymentDetails);
    //     this.orderArr[this.activeIndex].mtPayOrderPaymentDetailsString = sendedData;
    //     if (paymentTerm) {
    //         this.orderArr[this.activeIndex].paymentTerm = 'mtPay';
    //     }
    //     this.updateChangesMade();
    // }
    async mtPayUpdateOrder(isDoneTxn = false) {
        console.log('Rahul....', this.newMTPayPaymentTermDDModel && this.newMTPayPaymentTermDDModel.day, this.finalSelectedCard,  isDoneTxn, this.appliedBankDetail);
        let sendedData = '';
        const mtPayOrderPaymentDetails = {
            paymentMethod: this.newMTPaySelectedPaymentMethods && this.newMTPaySelectedPaymentMethods.type,
            paymentTerms: this.newMTPayPaymentTermDDModel && this.newMTPayPaymentTermDDModel.day,
            isFinanced: (this.newMTPayPaymentTermDDModel && (this.newMTPayPaymentTermDDModel.day === 30 || this.newMTPayPaymentTermDDModel.day === 60
                || this.newMTPayPaymentTermDDModel.day === 90)) ? true : false,
            paymentMethodID: this.appliedBankDetail ? this.appliedBankDetail.id : (this.finalSelectedCard ? this.finalSelectedCard.paymentID : null)
        };
        console.log('Rahul....456', mtPayOrderPaymentDetails);
        sendedData = JSON.stringify(mtPayOrderPaymentDetails);
        this.orderArr[this.activeIndex].mtPayOrderPaymentDetailsString = sendedData;
        this.updateChangesMade();
    }
    getmtPayOpenTxnId(order: any) {
        if (order && order.mtPayTransactionDetails && order.mtPayTransactionDetails.length) {
            const findTxnDetails = _.find(order.mtPayTransactionDetails, o => o.status === 'open');
            return (findTxnDetails && findTxnDetails.id) ? findTxnDetails.id : null;
        } else {
            return null;
        }
    }
    ismtPayInitiated(order: any) {
        if (order && order.paymentTerm && order.paymentTerm.toLowerCase() === 'mtpay') {
            return true;
        } else {
            return false;
        }
    }
    getmtPayVendorId(order: any) {
        if (order && order.manufacturer && order.manufacturer.companyIntegrations && order.manufacturer.companyIntegrations.length) {
            const findVendorId = _.find(order.manufacturer.companyIntegrations, o => (o.intergationService.toLowerCase() === 'mtpay'));
            return (findVendorId && findVendorId.integrationID) ? findVendorId.integrationID : null;
        } else {
            return null;
        }
    }
    getmtPayVendorAllowPaymentDays(order: any) {
        if (order && order.manufacturer && order.manufacturer.companyIntegrations && order.manufacturer.companyIntegrations.length) {
            const intergationServiceObj = _.find(order.manufacturer.companyIntegrations, o => (o.intergationService.toLowerCase() === 'mtpay'));
            return (intergationServiceObj && intergationServiceObj.allowPaymentDays) ? intergationServiceObj.allowPaymentDays : null;
        } else {
            return null;
        }
    }
    getmtPayBuyerId(order: any) {
        if (order && order.retailer && order.retailer.companyIntegrations && order.retailer.companyIntegrations.length) {
            const findBuyerId = _.find(order.retailer.companyIntegrations, o => (o.intergationService.toLowerCase() === 'mtpay'));
            return (findBuyerId && findBuyerId.integrationID) ? findBuyerId.integrationID : null;
        } else {
            return null;
        }
    }
    getmtPayAvailableCreditLimit(order: any) {
        if (order && order.retailer && order.retailer.companyIntegrations && order.retailer.companyIntegrations.length) {
            const findBuyerId = _.find(order.retailer.companyIntegrations, o => (o.intergationService.toLowerCase() === 'mtpay'));
            return (findBuyerId && findBuyerId.availableCreditLimit) ? findBuyerId.availableCreditLimit : null;
        } else {
            return null;
        }
    }
    getmtPayPaymentDetails(order: any) {
        if (order && order.mtPayTransactionDetails) {
            this.mtPayPaymentDeclined = _.find(order.mtPayTransactionDetails, o => o.retailerStatus && o.retailerStatus === 'PaymentDeclined' && o.status === 'open');
        }
        if (order && order.mtPayOrderPaymentDetailsString) {
            return JSON.parse(order.mtPayOrderPaymentDetailsString);
        } else {
            return null;
        }
    }
    getmtPayPaymentDetailsStringTerms(order: any) {
        if (order && order.mtPayOrderPaymentDetailsString) {
            const x = JSON.parse(order.mtPayOrderPaymentDetailsString);
            if (x  && _.isNumber(x['paymentTerms'])) {
                x['paymentTerms'] = x['paymentTerms'].toString();
                return x;
            } else {
                return x;
            }
        } else {
            return null;
        }
    }
    async initmtPay(mtPayRegFormPopup?: any) {
        // RC-2101 (RK-21-SEP-2021) mtPay [start]
        this.checkMTPayMFRServiceEnableOrNotFn();
        this.enabledmtPayService = this.getmtPayMFRRetailerEnabledSetting(this.orderArr[this.activeIndex]);
        if (this.mtPayMFRServiceEnableOrNot && this.enabledmtPayService && (!this.orderArr[this.activeIndex].paymentTerm || this.orderArr[this.activeIndex].paymentTerm === 'mtPay')) {
            this.checkMTPayRetailerServiceEnableOrNotFn(true);
            this.mtPayLoading = true;
            this.getBuyerDetails().then(res => {
                this.mtPayLoading = false;
                if (res) {
                    this.setPaymentTermDDValue();
                    if (!mtPayRegFormPopup) {
                        this.loadPaymentDetails();
                    }
                    // this.checkMTPayRetailerServiceEnableOrNotFn(true);
                    console.log('Rahul....1515', res, _.isEmpty(this.newMTPaygetBuyerRegisterData),
                    !this.newMTPayBuyerRegisterApprovedOrNot, this.newMTPayRetailerQualifyStatus !== 'completed');
                    if (_.isEmpty(this.newMTPaygetBuyerRegisterData)) {
                        this.getApprovedForNetTermsButton = true;
                        this.newMTPayretailerStepFrom = 0;
                    } else if (!this.newMTPayBuyerRegisterApprovedOrNot || this.newMTPayRetailerQualifyStatus !== 'completed') {
                        this.getApprovedForNetTermsButton = true;
                        this.newMTPayretailerStepFrom = 2;
                    } else {
                        this.getApprovedForNetTermsButton = false;
                    }
                } else {
                    this.newMTPayPaymentTermDDModel = null;
                    this.newMTPaySelectedPaymentMethods = null;
                }
            });
        }
        // RC-2101 (RK-21-SEP-2021) mtPay [end]
        console.log('initmtPay >>', this.mtPayMFRServiceEnableOrNot);
    }
    checkMTPayMFRServiceEnableOrNotFn() {
        this.mtPayMFRServiceEnableOrNot = false;
        if (this.getmtPayVendorId(this.orderArr[this.activeIndex])) {
            this.mtPayMFRServiceEnableOrNot = true;
        }
    }
    async checkMTPayRetailerServiceEnableOrNotFn(loadmtPayCL?: any) {
        this.mtPayRetailerServiceEnableOrNot = false;
        this.newMTPayBuyerRegisterApprovedOrNot = false;
        if (this.getmtPayBuyerId(this.orderArr[this.activeIndex])) {
            const retailerCompanyObj = _.find(this.orderArr[this.activeIndex].retailer.companyIntegrations,
                 o => (o.intergationService.toLowerCase() === 'mtpay'));
            this.newMTPayretailercompanyIntegrations = retailerCompanyObj;
            if (retailerCompanyObj) {
                this.mtPayRetailerServiceEnableOrNot = true;
            }
            if (retailerCompanyObj && retailerCompanyObj.isApproved) {
                this.newMTPayBuyerRegisterApprovedOrNot = true;
                if (loadmtPayCL) {
                    const limit = await this.getmtPayBuyerCreditLimit();
                    this.setPaymentTermDDValue();
                }
            }
        }
    }
    onFocusOutEvent() {
        const getemail = this.fbCreditCard.get('email');
        if (getemail.invalid) {
            return;
        }
        this.mtpayBuyeremailcCheckLoading = true;
        this.integrationService.checkmtpayemailreg(getemail.value).subscribe((r: any) => {
            this.mtpayBuyeremailcCheckLoading = false;
            if (r && (r.status !== 200 || r.message !== 'The email is available')) {
                getemail.setErrors({ 'emailIsTaken': true });
            } else {
                getemail.setErrors(null);
            }
        }, err => {
            this.mtpayBuyeremailcCheckLoading = false;
            getemail.setErrors(null);
        });
    }
    eventSkipDialog($event) {
        console.log('Rahul....eventSkipDialog', $event);
        this.showGetBalancePopup = false;
    }
    loadBackgroundServicesOnSuccess($event) {
        console.log('Rahul....loadBackgroundServicesOnSuccess', $event);
    }
    hideRegistrationgPopup() {
        this.newMTPayPaymentTermDDModel = null;
        this.newMTPaySelectedPaymentMethods = null;
        if (this.mtPayMFRServiceEnableOrNot && this.enabledmtPayService && (!this.orderArr[this.activeIndex].paymentTerm || this.orderArr[this.activeIndex].paymentTerm === 'mtPay')) {
            this.mtPayLoading = true;
            this.getBuyerDetails().then((res: any) => {
                this.mtPayLoading = false;
                if (!_.isEmpty(res) && res) {
                    this.orderArr[this.activeIndex].retailer.companyIntegrations = [];
                    this.orderArr[this.activeIndex].retailer.companyIntegrations.push({
                        availableCreditLimit: res,
                        companyID: this.rtSharedService.getRetailerID(),
                        creditLimit: res.creditLimit,
                        email: res.id,
                        integrationID: res.id,
                        intergationService: "mtPay",
                        isApproved: true,
                    });
                    setTimeout(() => {
                        this.checkMTPayRetailerServiceEnableOrNotFn(true);
                        this.initmtPayAutoFillData();
                        if (_.isEmpty(this.newMTPaygetBuyerRegisterData)) {
                            this.getApprovedForNetTermsButton = true;
                            this.newMTPayretailerStepFrom = 0;
                        } else if (!this.newMTPayBuyerRegisterApprovedOrNot || this.newMTPayRetailerQualifyStatus !== 'completed') {
                            this.getApprovedForNetTermsButton = true;
                            this.newMTPayretailerStepFrom = 2;
                        } else {
                            this.getApprovedForNetTermsButton = false;
                        }
                    }, 0);
                }
                console.log('hideRegistrationgPopup >>>>', res, _.isEmpty(res));
            });
        }
    }
    onHideBankConnectionDialog($event) {
        console.log('Rahul....onHideBankConnectionDialog', $event);
        this.selectedBankDetailModel = null;
        this.appliedBankDetail = null;
        this.mtpayShowBankConnectionLoading = true;
        this.mtpayShowBankDataFetching = true;
        this.getBuyerDetails().then(res => {
            this.mtpayShowBankConnectionLoading = false;
            this.mtpayShowBankDataFetching = false;
            if (!_.isEmpty(res) && res) {
                this.newMTPayBankDetails = [];
                if (this.newMTPaygetBuyerRegisterData && this.newMTPaygetBuyerRegisterData.paymentData && this.newMTPaygetBuyerRegisterData.paymentData.banks) {
                    _.forEach(this.newMTPaygetBuyerRegisterData.paymentData.banks, (o) => {
                        this.newMTPayBankDetails.push({ label: `${o.institutionName} (${o.accountName})`, value: o });
                    });
                }
                this.newMTPayBankDetails.push({ label: 'Add New Bank Details', value: '' });
            }
        });
    }
    getmtPayIntegrationServices() {
        this.onDestroyIntegrationService.next();
        this.integrationService.getIntegrationServices().pipe(takeUntil(this.onDestroyIntegrationService)).subscribe((response: any) => {
            if (response && response.success) {
                this.mtpaygetIntegrationData = _.filter(response.data, (data, index) => {
                    return data.showInRetailer;
                });
            }
        }, (error) => {
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
        });
    }
    private async getmtPayBuyerCreditLimit() {
        this.onDestroygetBuyerCreditLimit.next()
        return new Promise(async (resolve, reject) => {
            this.errorMessageService['mtPayBuyerCreditLimit'] = null;
            this.integrationService.getBuyerCreditLimit(this.rtSharedService.getRetailerID())
                .subscribe(async (res: any) => {
                    this.newMTPayretaileravilablecl = 0;
                    console.log('>>>>getmtPayBuyerCreditLimit', res);
                    if (res && res.success) {
                        this.newMTPayretaileravilablecl = parseInt(res.data['availableCreditLimit'], 10) / 100;
                        resolve(res);
                    } else {
                        resolve(res);
                    }
                }, (error) => {
                    this.errorMessageService['mtPayBuyerCreditLimit'] = error.message;
                    console.log("err>>", error);
                    this.msgService.clear();
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during getting mtPay buyer credit limit') });
                    this.newMTPayretaileravilablecl = 0;
                    reject(error);
                });
        });
    }

    loadRetailerTerms() {
        this.cardPresent = true;
        this.retailerPaymentTerms = [];
        this.destroyRetailerTerms.next();
        this.retailerService.getPaymentTermsForRetailer(this.auth.getCompanyID())
            .pipe(takeUntil(this.destroyRetailerTerms)).subscribe((paymentInfoList: RetailerPaymentInfo[]) => {
                if (paymentInfoList.length ){
                    this.cardPresent = true;
                    paymentInfoList.forEach(info => {
                        this.retailerPaymentTerms.push({ value: info, label: this.getTermLabel(info) });
                    });
                } else{
                    this.cardPresent = false;
                }
                console.log("this.retailerPaymentTerms", this.retailerPaymentTerms)
            });
    }
    private getTermLabel(info) {
        return `${info.paymentType} * ${info.cardNumber ? info.cardNumber : ''} <b>${info.expirationMonth} / ${info.expirationYear}</b>`
    }
    getCradInfo(cardInfo,op: OverlayPanel,event) {
        this.cardInfoView= true;
        this.fbCreditCard.reset();
        this.fbCreditCard.get('cardNumber').enable();
        this.fbCreditCard.get('cardCVC').enable();
        this.fbCreditCard.get('cardMonth').enable();
        this.fbCreditCard.get('cardYear').enable();
        if (_.isEmpty(this.newMTPaygetBuyerRegisterData)) {  
            setTimeout(() => {
                const getretailer = this.orderArr[this.activeIndex].retailer;
                this.fbCreditCard.get('email').patchValue(getretailer.email);
                this.onFocusOutEvent();
            });
        }
        this.retailerService.getPaymentTermsForRetailerCard(cardInfo.recordID)
            .pipe(takeUntil(this.destroyRetailerTerms)).subscribe((paymentInfoList: any) => {
                this.cardInfoView= false;
                this.clickOnAddNewCard = true;
                op.toggle(event);
                if(paymentInfoList.success){
                    this.seletedCreditCard = {};
                    if (paymentInfoList.data && !_.isEmpty(paymentInfoList)) {
                        const cardData = paymentInfoList.data;
                        const cardNumber: any = atob(cardData.cardNumber);
                        this.fbCreditCard.controls.cardNumber.patchValue(cardNumber);
                        // this.fbCreditCard.controls.cardCVC.patchValue(this.seletedCreditCard.cvc);
                        // this.fbCreditCard.controls.cardMonth.patchValue(
                        //     (Number(cardData.expirationMonth) <= 9) ? _.toString(0 + cardData.expirationMonth) : _.toString(cardData.expirationMonth));
                        this.fbCreditCard.controls.cardMonth.patchValue(_.toString(cardData.expirationMonth));
                        this.fbCreditCard.controls.cardYear.patchValue('20'+_.toString(cardData.expirationYear));
                        // this.fbCreditCard.get('cardNumber').disable();
                        // this.fbCreditCard.get('cardCVC').disable();
                        // this.fbCreditCard.get('cardMonth').disable();
                        // this.fbCreditCard.get('cardYear').disable();
            
                    } else {
                        this.fbCreditCard.reset();
                        this.fbCreditCard.get('cardNumber').enable();
                        this.fbCreditCard.get('cardCVC').enable();
                        this.fbCreditCard.get('cardMonth').enable();
                        this.fbCreditCard.get('cardYear').enable();
                    }

                }
            });
    }


    getRepGroupRetailers() {
        const rg: any = this.domainData.repGroup;
        const getRepGroupRetailer: any = _.find(this.retailerObj.repGroupRetailers, (o) =>
        o.repGroupID === (rg && rg.recordID));
        if (getRepGroupRetailer) {
            getRepGroupRetailer['retailerIntegrationServices'] = { "mtPay": true}
            if (getRepGroupRetailer && getRepGroupRetailer.retailerIntegrationServices && getRepGroupRetailer.retailerIntegrationServices.mtPay) {
                this.mtPayAvilableForRetailer = true;
            } else {
                this.mtPayAvilableForRetailer = false;
            }
            return getRepGroupRetailer;
        } else {
            return null;
        }
    }
    getmtPayMFRRetailerEnabledSetting(order: any) {
        const rg: any = this.domainData.repGroup;
        if (order && order.manufacturer.manufacturerIntegrationServices && order.retailer.repGroupRetailers && order.retailer.repGroupRetailers.length) {
            const getRepGroupRetailer: any = _.find(order.retailer.repGroupRetailers, (o) =>
            o.repGroupID === (rg && rg.recordID));
            const getMfrIntegrationServices = order.manufacturer.manufacturerIntegrationServices.mtPay;
            if (getRepGroupRetailer && getRepGroupRetailer.retailerIntegrationServices && getRepGroupRetailer.retailerIntegrationServices.mtPay && getMfrIntegrationServices) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
