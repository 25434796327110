import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();

    if (window) {
        window.console.log = window.console.warn = window.console.info = window.console.error = window.console.group = function () { };
    }
}

/*RC-2222 (RK 09-Nov-2021): Not need to load mtpay js because now sandbox logic coming from backend side*/
/*const scriptEl = window.document.createElement('script');
scriptEl.src = environment.mtPayJS;
scriptEl.setAttribute('async', '');
scriptEl.setAttribute('defer', '');
window.document.body.appendChild(scriptEl);*/ 

// This code run before bootstrap angular app for check domain(origin) available or not
// [B2B-92] (MG 26-FEB-2020) modified to warn on min order
// [B2B-223] (MG 02-APR-2020) modified to set default values
// [B2B-227] (MG 03-APR-2020) modified to make custom button dynamic
// [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase
// [B2B-198] (MG-13-APR-2020): modified to save default order code
// [B2B-241] (MG 14-MAY-2020) modified to make custom shop url
const xhttp = new XMLHttpRequest();
xhttp.onload = function (data) {
    if (this.readyState === 4 && this.status === 200) {
        // tslint:disable-next-line:max-line-length
        const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        const res = JSON.parse(this.responseText);
        // declare as global variable and use by 'declare var domainData: any;' syntax
        (window as any).domainData = res.data;
        const themeSettings = res.data.repGroupThemeSettings;
        const repgroupObj = res.data.repGroup;

        // set default values
        themeSettings.fixedCart = validateAndSetValue(themeSettings, 'fixedCart', 0);
        themeSettings.isMinOrderRequired = validateAndSetValue(themeSettings, 'isMinOrderRequired', false);
        themeSettings.siteFontFamily = validateAndSetValue(themeSettings, 'siteFontFamily', 'Roboto');
        themeSettings.daysLastModified = validateAndSetValue(themeSettings, 'daysLastModified', 15);
        themeSettings.isBestSeller = validateAndSetValue(themeSettings, 'isBestSeller', 1);
        themeSettings.allowParentItemShopping = validateAndSetValue(themeSettings, 'allowParentItemShopping', true);
        themeSettings.showNewArrivals = validateAndSetValue(themeSettings, 'showNewArrivals', false);
        themeSettings.enableBestSellersInB2b = validateAndSetValue(themeSettings, 'enableBestSellersInB2b', false);
        themeSettings.einNumberRequired = validateAndSetValue(themeSettings, 'einNumberRequired', false);
        themeSettings.einPlace = validateAndSetValue(themeSettings, 'einPlace', null);
        themeSettings.defaultOrderCode = validateAndSetValue(themeSettings, 'defaultOrderCode', 'W');
        themeSettings.shopNow = validateAndSetValue(themeSettings, 'shopNow', 'shop');
        themeSettings.categoryGroupType = validateAndSetValue(themeSettings, 'categoryGroupType', 'category');
        themeSettings.filterByRetailerTerritory = validateAndSetValue(themeSettings, 'filterByRetailerTerritory', false);
        themeSettings.useDefaultPromotions = validateAndSetValue(themeSettings, 'useDefaultPromotions', true);
        themeSettings.customPromotions = validateAndSetValue(themeSettings, 'customPromotions', {});
        themeSettings.otherManufacturerItems = validateAndSetValue(themeSettings, 'otherManufacturerItems', false);
        themeSettings.showLogin = validateAndSetValue(themeSettings, 'showLogin', true);
        themeSettings.enableProfileEditing = validateAndSetValue(themeSettings, 'enableProfileEditing', true);
        themeSettings.showSignup = validateAndSetValue(themeSettings, 'showSignup', true);
        themeSettings.b2bSignupUrl = validateAndSetValue(themeSettings, 'b2bSignupUrl', {});
        themeSettings.hideBrowseAllButton = validateAndSetValue(themeSettings, 'hideBrowseAllButton', false);
        themeSettings.hideDiscontinuedItems = validateAndSetValue(themeSettings, 'hideDiscontinuedItems', false);
        themeSettings.loginWidgetSwap = validateAndSetValue(themeSettings, 'loginWidgetSwap', true);
        themeSettings.signUpWidget = validateAndSetValue(themeSettings, 'signUpWidget', {});
        
        if (themeSettings.showSignup === false) {
            themeSettings.b2bSignupUrl.url = validateAndSetValue(themeSettings.b2bSignupUrl, 'url', '');
            themeSettings.b2bSignupUrl.text = validateAndSetValue(themeSettings.b2bSignupUrl, 'text', '');

            if (regexp.test(themeSettings.b2bSignupUrl.url) === true) {
                if (!(themeSettings.b2bSignupUrl.url.startsWith('http://') ||
                    themeSettings.b2bSignupUrl.url.startsWith('https://'))) {
                    themeSettings.b2bSignupUrl.url = 'http://' + themeSettings.b2bSignupUrl.url;
                }
            }
        }

        if (themeSettings.useDefaultPromotions === false) {
            themeSettings.customPromotions.url = validateAndSetValue(themeSettings.customPromotions, 'url', '');
            themeSettings.customPromotions.text = validateAndSetValue(themeSettings.customPromotions, 'text', '');
            themeSettings.customPromotions.iconClass = validateAndSetValue(themeSettings.customPromotions, 'iconClass', '');

            if (!(themeSettings.customPromotions.url && themeSettings.customPromotions.text)) {
                themeSettings.useDefaultPromotions = true;
            } else {
                if (regexp.test(themeSettings.customPromotions.url) === true) {
                    if (!(themeSettings.customPromotions.url.startsWith('http://') ||
                        themeSettings.customPromotions.url.startsWith('https://'))) {
                        themeSettings.customPromotions.url = 'http://' + themeSettings.customPromotions.url;
                    }
                }
            }
        }

        themeSettings.useDefaultCatalogs = validateAndSetValue(themeSettings, 'useDefaultCatalogs', true);
        themeSettings.customCatalogs = validateAndSetValue(themeSettings, 'customCatalogs', {});

        if (themeSettings.useDefaultCatalogs === false) {
            themeSettings.customCatalogs.url = validateAndSetValue(themeSettings.customCatalogs, 'url', '');
            themeSettings.customCatalogs.text = validateAndSetValue(themeSettings.customCatalogs, 'text', '');
            themeSettings.customCatalogs.iconClass = validateAndSetValue(themeSettings.customCatalogs, 'iconClass', '');

            if (!(themeSettings.customCatalogs.url && themeSettings.customCatalogs.text)) {
                themeSettings.useDefaultCatalogs = true;
            } else {
                if (regexp.test(themeSettings.customCatalogs.url) === true) {
                    if (!(themeSettings.customCatalogs.url.startsWith('http://') || themeSettings.customCatalogs.url.startsWith('https://'))) {
                        themeSettings.customCatalogs.url = 'http://' + themeSettings.customCatalogs.url;
                    }
                }
            }
        }

        themeSettings.magazine = res.data.contentMagazineSettings;
        if (themeSettings.magazine !== undefined && themeSettings.magazine !== null) {
            themeSettings.magazine.comingUpImages = validateAndSetValue(themeSettings.magazine, 'comingUpImages', []);
            themeSettings.magazine.magazineImageSectioneOne = validateAndSetValue(themeSettings.magazine, 'magazineImageSectioneOne', null);
            themeSettings.magazine.magazineImageSectionTwo = validateAndSetValue(themeSettings.magazine, 'magazineImageSectionTwo', null);
            themeSettings.magazine.magazineSlider = validateAndSetValue(themeSettings.magazine, 'magazineSlider', null);
            themeSettings.magazine.magazineImages = validateAndSetValue(themeSettings.magazine, 'magazineImages', []);
            themeSettings.magazine.socialPost = validateAndSetValue(themeSettings.magazine, 'socialPost', []);
            themeSettings.magazine.trendingNowText = validateAndSetValue(themeSettings.magazine, 'trendingNowText', null);
        }

        themeSettings.headerSlider = validateAndSetValue(res.data, 'marketTimeEventsSettings', null);
        themeSettings.marketTimeEventsSettings = validateAndSetValue(res.data, 'marketTimeEventsSettings', null);
        themeSettings.comingSoonLandingPage = validateAndSetValue(res.data, 'comingSoonLandingPage', null);
        themeSettings.islandingPageVisible = getLandingPage(themeSettings.comingSoonLandingPage);
        themeSettings.colorSelectionSettings = validateAndSetValue(res.data, 'colorSelectionSettings', null);

        if (JSON.stringify(themeSettings.signUpWidget) !== '{}' && themeSettings.signUpWidget !== undefined && themeSettings.signUpWidget !== null){
            themeSettings.signUpWidget.title = validateAndSetValue(themeSettings.signUpWidget, 'title', null);
            themeSettings.signUpWidget.desc = validateAndSetValue(themeSettings.signUpWidget, 'desc', null);
            themeSettings.signUpWidget.showSignupBtn = validateAndSetValue(themeSettings.signUpWidget, 'showSignupBtn', true);
            themeSettings.signUpWidget.btnName = validateAndSetValue(themeSettings.signUpWidget, 'btnName', null);
            themeSettings.signUpWidget.btnLink = validateAndSetValue(themeSettings.signUpWidget, 'btnLink', null);
        }
        // themeSettings.isMinOrderRequired = false;
        console.log('B2Btheme-set');
        try {
            sessionStorage.setItem('B2Btheme', JSON.stringify(themeSettings));
            sessionStorage.setItem('repgroupObj', JSON.stringify(repgroupObj));  
        } catch (e) {
            alert('Session Storage is disabled. Cannot log in because third-party cookies are blocked.');
        }
        // bootstrap angular app if domain(origin) available
        // platformBrowserDynamic().bootstrapModule(AppModule)
        //     .catch(err => console.error(err));
    } else {
        window.location.replace(environment.marketTimeURL);
    }
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
};
xhttp.open('GET', `${environment.apiUrl}reptime/b2baccess/checkdomain`, true);
xhttp.send();

function validateAndSetValue(validateObj, validateKey, defaultReturn) {
    if (validateObj !== undefined && validateObj !== null) {
        if (validateObj.hasOwnProperty(validateKey) && validateObj[validateKey] !== undefined && validateObj[validateKey] !== null) {
            return validateObj[validateKey];
        } else {
            return defaultReturn;
        }
    } else {
        return defaultReturn;
    }
}
function getLandingPage(domainData) {
    if (domainData && domainData.isVisible &&
        domainData.timestamp) {
        const x = new Date(domainData.timestamp);
        // x.setHours(0);
        // x.setMinutes(0);
        // x.setSeconds(0);
        // const date = new Date(domainData.timestamp).getTime();
        const date = x.getTime();
        const currentdate = new Date().getTime();
        if (currentdate < date) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;

    }
}


