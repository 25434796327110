<div class="reptime-theme">
    <p-card class="card">
        <p-table [autoLayout]="true" [columns]="shipToCols" [loading]="loading"
                 [scrollable]="true" scrollHeight="300px"
                 [responsive]="false" [rows]="25" [value]="shipToLocations" dataKey="recordID"
                 exportFilename="ShipToLocations" sortField="name" #shippingLocationsTable>
            <ng-template let-columns pTemplate="header">
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field" [pSortableColumn]="col.field"
                        [style.width]="col.width">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template let-columns="columns" let-rowData pTemplate="body">
                <tr [pSelectableRow]="rowData" (click)="selectShipTo(rowData)"
                    [style.background]="selectedShipTo && (selectedShipTo.recordID === rowData.recordID) ? '#fad961': ''">
                    <td *ngFor="let col of columns" [style.width]="col.width" style="word-break: break-all;">
                        <div [ngSwitch]="col.field">
                            <!-- B2B-287 (RM 06-07-2020): Added, Set as a primary shipping locations icon -->
                            <div *ngSwitchCase="'name'">{{rowData[col.field]}}
                                <span style="color: darkorange;" *ngIf="rowData['isPrimary'] === true">
                                    <i pTooltip="This is primary shipping locations" tooltipPosition="right" tooltipEvent="hover"
                                       class="fas fa-star"></i></span>
                            </div>
                            <!-- END -->
                            <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                            <div *ngSwitchCase="'phone'">{{rowData[col.field] | phone}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="!loading" class="table-empty">
                    <td [attr.colspan]="shipToCols && shipToCols.length + 3">You have no ship to locations yet. Use the
                        Add
                        Ship To Location button to add one.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</div>
