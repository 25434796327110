import { Component, OnInit } from '@angular/core';
import { ConfirmationService, DialogService, DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng/api';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import { BasketItemService } from '../../services/middleware/basket-item/basket-item.service';
import { OrderService } from '../../services/middleware/order/order.service';
import { ISCDialogComponent } from '../isc-dialog/isc-dialog.component';
import { ManufacturerService } from '../../services/api/manufacturer.service';
import { ItemsAPIService } from '../../services/api/items-api/items-api.service';
import { RTSharedService } from '../../services/rtshared/rtshared.service';
import { ItemsListModalComponent } from '../../modals/items-list-modal/items-list-modal.component';
import { RtDatePipe } from '../../pipes/rt-date.pipe';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { RetailerService } from '../../services/middleware/retailer/retailer.service';
@Component({
    selector: 'rs-browse-items-dialog',
    templateUrl: './browse-items-dialog.component.html',
    styleUrls: ['./browse-items-dialog.component.scss']
})
export class BrowseItemsDialogComponent implements OnInit {

    public searchText: string;
    public selectedCustomer: string;
    public selectedManufacturer: any;
    public repGroupID: string;
    public retailer: any;
    public fromPage: any;
    public orderID: any;
    public manufacturerID: any;
    public sequenceID: any;
    public itemMfgIDs: any;
    public existItemsData: any;
    public manufacturersArr: any;
    public onlyOneAdd = false;

    constructor(
        public config: DynamicDialogConfig,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        private msgService: MessageService,
        private orderService: OrderService,
        private confirmationService: ConfirmationService,
        private manufacturerService: ManufacturerService,
        private rtSharedService: RTSharedService,
        private itemsAPIService: ItemsAPIService,
        private basketItemService: BasketItemService,
        private retailerService: RetailerService,
        public dp: DatePipe) {
    }

    ngOnInit() {
        console.log('this.config >>>', this.config);
        this.searchText = this.config.data.searchText;
        this.repGroupID = this.config.data.repGroupID;
        this.retailer = this.config.data.retailer;
        this.fromPage = this.config.data.fromPage;
        this.orderID = this.config.data.orderID;
        this.manufacturerID = this.config.data.manufacturerID;
        this.sequenceID = this.config.data.sequenceID;
        this.selectedCustomer = this.config.data.selectedCustomer;
        this.selectedManufacturer = this.config.data.selectedManufacturer;
        this.manufacturersArr = this.config.data.manufacturersArr;
        this.itemMfgIDs = this.config.data.itemMfgIDs;
        this.existItemsData = this.config.data.itemMfgIDs;
        console.log('BrowseItemsDialogComponent >>> itemMfgIDs >> ', this.existItemsData);

        if (this.searchText && !this.fromPage) {
            this.onlyOneAdd = true;
        }
    }

    async updateOrderDetail(detailObj) {
        const updateDetailObj: any = _.cloneDeep(detailObj);
        console.log('6999 1 ', updateDetailObj);

        const updateDetail: any = await this.orderService.updateOrderDetail(this.orderID, updateDetailObj, this.manufacturerID);
        if (updateDetail && updateDetail.recordID) {
            const updateOrderDetailJSON = {
                itemNumber: updateDetail.itemNumber,
                manufacturerID: updateDetail.manufacturerID ? updateDetail.manufacturerID : this.manufacturerID,
                recordDeleted: updateDetail.recordDeleted,
                size: updateDetail.size,
                color: updateDetail.color,
                style: updateDetail.style,
                recordID: updateDetail.recordID,
                quantity: updateDetail.quantity,
                sequenceID: updateDetail.sequenceID,
                itemID: updateDetail.itemID,
                itemColorStyleID: updateDetail.itemSCSID
            };
            const index = _.findIndex(this.itemMfgIDs, (o) => updateDetailObj.recordID === o.recordID);
            if (index === -1) {
                this.sequenceID = updateDetail.sequenceID;
                this.itemMfgIDs.push(updateOrderDetailJSON);
            } else {
                this.itemMfgIDs[index] = updateOrderDetailJSON;
            }
            updateDetail.picturePath = updateDetailObj.picturePath;
            updateDetail.fullPicturePath = updateDetailObj.fullPicturePath;
            const eventObj = {
                itemObj: _.cloneDeep(updateDetail),
                orderID: this.orderID,
                isDelete: true  // this parameter pass 'true' beacuse referesh suggested items list
            };
            this.orderService.setAddOrderItemSub(eventObj);
        }
    }

    async addToCart(item: any) {
        console.log('>>> item >>> ', item);
        if (this.validateDateBetweenTwoDates(item)) {
            item['itemDiscount'] = item.discountPercent;
        }
        console.log('>>> Add To Cart Finalize Screen >>> ', this.fromPage);
        console.log('>>> itemMfgIDs >>> ', this.itemMfgIDs);
        if (item.isc && item.isc.length && this.fromPage === 'ORDER_PAGE') {
            console.log('addToCart function start');
            const index = 0;
            // subject for disable add button when item adding
            this.orderService.setAddBtnDisable(`${item.recordID}`);
            await this.addToCartVariantItems(item, index);
            // subject for enable add button when item adding done
            this.orderService.removeAddBtnDisable(`${item.recordID}`);
            console.log('addToCart function end');
        } else {
            // const existFind = this.itemMfgIDs.find(o => (item.itemNumber === o.itemNumber &&
            //      item.manufacturerID === o.manufacturerID && !o.recordDeleted));
            // if (existFind) {
            //     this.confirmationService.confirm({
            //         message: 'Selected item already on order. Do you want to update its quantity? ',
            //         header: 'Item Already Available',
            //         icon: 'pi pi-exclamation-triangle',
            //         accept: async () => {
            //             this.ref.close({type: 'EXIST', itemMfgObj: existFind});
            //         },
            //         reject: () => {
            //         },
            //         key: 'unfinishedOrder' // [B2B-294] (RM 17-07-2020): Bug Fixed
            //     });
            // }
            const existFind = this.itemMfgIDs.find(o => item.recordID === o.itemID && item.manufacturerID === o.manufacturerID && !o.recordDeleted);
            if (item.quantity || existFind) {
                const msgServiceJson = {
                    key: 'addItem', severity: 'info',
                    summary: 'Item Added to Cart',
                    detail: `${item.itemNumber} was saved successfully.`
                };
                this.orderService.setAddBtnDisable(`${item.recordID}`); // subject for disable add button when item adding
                if ((item.quantity < item.minimumQuantity) || item.quantity === null) {
                    item.quantity = item.minimumQuantity;
                }
                // item.quantity = item.quantity ? item.quantity : item.minimumQuantity;
                if (this.fromPage === 'ORDER_PAGE') {
                    const updateObj = {
                        b2bPriceCode: item.b2bPriceCode ? item.b2bPriceCode : null,
                        color: null,
                        commissionPercent: null,
                        description: item.description,
                        inventoryAtMoment: null,
                        invoiceQuantity: null,
                        invoiceUnitPrice: null,
                        itemDiscount: item.itemDiscount ? item.itemDiscount : 0,
                        itemNumber: item.itemNumber,
                        itemStatus: null,
                        listPrice: item.retailPrice,
                        name: item.name,
                        notes: null,
                        orderInvoiceID: null,
                        QAComments: null,
                        QATestReport: null,
                        quantity: item.quantity,
                        retailerItemNumber: null,
                        size: null,
                        style: null,
                        unitPrice: item.unitPrice,
                        unitQty: item.unitQty,
                        sequenceID: this.sequenceID + 1,
                        priceCode: item.selectedPriceCode,
                        picturePath: item.picturePath,
                        fullPicturePath: item.fullPicturePath,
                        itemID: item.recordID,
                        itemSCSID: null,
                        recordID: null,
                    };
                    if (existFind && existFind.recordID) {
                        updateObj.recordID = existFind.recordID;
                        updateObj.sequenceID = existFind.sequenceID;
                        msgServiceJson.summary = 'Item Update to Cart';
                    } else {
                        delete updateObj.recordID;
                    }

                    const datediscontinue = [new Date(item.discountStartDate).getTime(), new Date(item.discountEndDate).getTime()];
                    const date = new Date().getTime();
                    if (datediscontinue[0] <= date && datediscontinue[1] >= date
                        && (updateObj.itemDiscount === undefined || updateObj.itemDiscount === null || updateObj.itemDiscount === 0)) {
                        updateObj.itemDiscount = item.discountPercent ? item.discountPercent : updateObj.itemDiscount;
                    }
                    this.retailerService.setRetailerDetailsEvent(true);
                    const resObj = await this.updateOrderDetail(updateObj);
                } else {
                    await this.basketItemService.saveItemToBasket(item, this.retailer);
                    this.itemMfgIDs.push({
                        itemNumber: item.itemNumber,
                        manufacturerID: item.manufacturerID
                    });
                    this.basketItemService.setCartEvent(this.retailer);
                }
                this.msgService.clear();
                this.msgService.add(msgServiceJson);

                if (this.fromPage !== 'ORDER_PAGE') {
                    if (this.onlyOneAdd) {
                        this.onlyOneAdd = false;
                        this.ref.close();
                    }
                }

                // subject for enable add button when item adding done
                this.orderService.removeAddBtnDisable(`${item.recordID}`);

                // focus and select advance search input field
                const textFiled = document.getElementById('adv-search-input');
                if (textFiled) {
                    textFiled.focus();
                }
            }
        }
    }

    // B2B-294 (RM 07-08-2020): Added new function used to add variant items
    async addToCartVariantItems(item: any, i: any) {
        console.log(i);
        if (item.isc && item.isc.length > i) {
            const index = i;
            const existFind = this.itemMfgIDs.find(o => item.recordID === o.itemID && item.manufacturerID === o.manufacturerID
                && item.isc[i].recordID === o.itemColorStyleID && !o.recordDeleted);
            if (item.isc[i].quantity || existFind) {
                const updateObj = {
                    b2bPriceCode: item.b2bPriceCode ? item.b2bPriceCode : null,
                    color: item.isc[i].color ? item.isc[i].color : null,
                    commissionPercent: null,
                    description: item.description,
                    inventoryAtMoment: null,
                    invoiceQuantity: null,
                    invoiceUnitPrice: null,
                    itemDiscount: item.itemDiscount ? item.itemDiscount : 0,
                    itemNumber: item.itemNumber,
                    itemStatus: null,
                    listPrice: item.retailPrice,
                    name: item.name,
                    notes: null,
                    orderInvoiceID: null,
                    QAComments: null,
                    QATestReport: null,
                    quantity: item.isc[i].quantity ? item.isc[i].quantity : item.minimumQuantity,
                    retailerItemNumber: null,
                    size: item.isc[i].size ? item.isc[i].size : null,
                    style: item.isc[i].style ? item.isc[i].style : null,
                    unitPrice: item.unitPrice,
                    unitQty: item.unitQty,
                    sequenceID: this.sequenceID + 1,
                    priceCode: item.selectedPriceCode,
                    picturePath: item.picturePath,
                    fullPicturePath: item.fullPicturePath,
                    itemID: item.isc[i].itemID,
                    itemSCSID: item.isc[i].recordID,
                    recordID: null,
                };

                // MG:28-SEP-2021
                // if SCS has price then overwrite it
                updateObj.unitPrice = item.unitPrice;
                if (item.isc[i].price !== undefined && item.isc[i].price !== null) {
                    updateObj.unitPrice = item.isc[i].price;
                }

                const msgServiceJson = {
                    key: 'addItem',
                    severity: 'info',
                    summary: 'Item Added to Cart',
                    detail: `${item.itemNumber} was saved successfully.`
                };
                if (existFind && existFind.recordID) {
                    updateObj.recordID = existFind.recordID;
                    updateObj.sequenceID = existFind.sequenceID;
                    msgServiceJson.summary = 'Item Update to Cart';
                } else {
                    delete updateObj.recordID;
                }

                const datediscontinue = [new Date(item.discountStartDate).getTime(), new Date(item.discountEndDate).getTime()];
                const date = new Date().getTime();
                if (datediscontinue[0] <= date && datediscontinue[1] >= date
                    && (updateObj.itemDiscount === undefined || updateObj.itemDiscount === null || updateObj.itemDiscount === 0)) {
                    updateObj.itemDiscount = item.discountPercent ? item.discountPercent : updateObj.itemDiscount;
                }

                const resObj = await this.updateOrderDetail(updateObj);
                this.msgService.clear();
                this.msgService.add(msgServiceJson);
            }
            await this.addToCartVariantItems(item, index + 1);
        }
    }

    openItemStyleColorModal(itemObj: any) {
        const ref: any = this.dialogService.open(ISCDialogComponent, {
            header: 'Item Size, Color And Style',
            width: '50%',
            height: 'auto',
            closable: true,
            styleClass: 'overflow-auto',
            data: {
                isc: itemObj.isc,
                item: itemObj,
                retailer: this.retailer,
                fromPage: this.fromPage,
                sequenceID: this.sequenceID,
                orderID: this.orderID,
                manufacturerID: this.manufacturerID,
                itemMfgIDs: this.itemMfgIDs
            }
        });

        ref.onClose.subscribe((data) => {
            if (data) {
                this.ref.close(data);
                // patch for close item modal
                const ele: any = document.getElementsByClassName('ui-dialog-titlebar-close')[0];
                ele.click();
            }
        });
    }

    /* RC-343 (RK 31st JAN 2020) Show Item History Popup without ISC in cloud*/
    openHistoryItem(itemObj: any) {
        let getentityId = null;
        let queryStringParam = {};
        console.log('BrowseItemsDialogComponent:', itemObj, this.retailer);
        switch (this.rtSharedService.getEntityType()) {
            case 'Repgroup':
                getentityId = this.rtSharedService.getRepGroupID();
                queryStringParam = {};
                break;
            case 'Salesperson':
                getentityId = this.rtSharedService.getSalesPersonID();
                queryStringParam = { repGroupID: itemObj.managedBy };
                break;
            default:
                /*This subject for B2B*/
                getentityId = this.rtSharedService.getRetailerID();
                queryStringParam = { repGroupID: this.rtSharedService.getRepGroupID() };
        }
        const ref = this.dialogService.open(ItemsListModalComponent, {
            header: `${itemObj.manufacturerID}'s Items History`,
            width: '97%',
            height: 'auto',
            styleClass: 'overflow-auto cut-dialog-height',
            data: {
                selectedItem: itemObj, retailer: this.retailer.buyer ? this.retailer.buyer : this.retailer, itemsHistory: true,
                entityId: getentityId, stringParam: queryStringParam
            }
        });
    }

    // B2B-116 (RM 02-03-2020): Finalize screen >> add to order item in orders from item view model
    OrderDataEvent(itemObj: any) {
        this.addToCart(_.cloneDeep(itemObj.activeViewItem));
        if (itemObj.linkedItemsData && itemObj.linkedItemsData.length) {
            itemObj.linkedItemsData.forEach(element => {
                this.addToCart(_.cloneDeep(element));
            });
        }
    }

    validateDateBetweenTwoDates(data) {
        const from = new Date(new RtDatePipe(this.dp).transform(data.discountStartDateString));
        const to = new Date(new RtDatePipe(this.dp).transform(data.discountEndDateString));
        const check = new Date(new RtDatePipe(this.dp).transform(moment(new Date()).format('MM-DD-YYYY')));
        return check <= to && check >= from;
    }
}
