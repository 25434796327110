export class RetailerPaymentInfo {
    recordID: number;
    cardHolderName: string;
    cardNumber: string;
    expirationMonth: string;
    expirationYear: string;
    paymentType: string;
    paymentToken: string;
    billAddress: string;
    billCity: string;
    billState: string;
    billZip: string;
    recordDeleted: boolean;
}
