import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {MessageService, SelectItem} from 'primeng/api';
import {DataAPIService} from '../../services/api/data-api/data-api.service';
import {ManufacturerCarrierType} from '../../models/manufacturer-carrier-type';
import {CarrierType} from '../..//models/carrier-type';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import * as objectPath from 'object-path';

import {HtmlToolsService} from '../../services/other/html-tools/html-tools.service';
import {RetailerService} from '../../services/api/retailer.service';
import {APIResponse} from '../../models/api-response';
import { CarrierAccount } from '../../models/carrier-account';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-edit-carrier-account-dialog',
    templateUrl: './edit-carrier-account-dialog.component.html',
    styleUrls: ['./edit-carrier-account-dialog.component.css']
})
export class EditCarrierAccountDialogComponent implements OnInit, OnChanges, OnDestroy {
    @Input() retailerID;
    @Input() displayCarrierAccountDialog;
    @Input() selectedCarrierAccount;
    @Input() selectedManufacturerCarrierType: ManufacturerCarrierType;
    @Input() manufacturerCarrierTypes: ManufacturerCarrierType[];
    @Input() openFrom;
    @Input() shipObjMatch;
    @Input() activeOrderObj;
    @Output() saveEvent = new EventEmitter<string>();
    @Output() closeEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() OnCloseDialog: EventEmitter<any> = new EventEmitter<any>();
    carrierTypeOptions: SelectItem[] = [{label: 'Select Shipping Method', value: null}];
    carrierAccounts = [];
    collectAccountInfo: boolean;
    editCarrierAccountsForm: FormGroup;
    selectionCarrierAccount: CarrierAccount;
    loading: boolean = false;
    isDisabledForm = false;
    private readonly destroyCarrierAcc = new Subject<void>();

    constructor(private msgService: MessageService, private retailerService: RetailerService,
                private htmlTools: HtmlToolsService, private fb: FormBuilder, private dataService: DataAPIService) {
        this.createCarrierAccountForm();
    }

    ngOnInit() {
        console.log('openFrom:', this.openFrom);
        if (this.openFrom !== 'FINALIZE') {  // RC-401 (RK 06-APRIL-2020): condition for in finalize screen already have a carrierTypeOptions getting from manufacturercarriertypes API
            this.dataService.getDefaultCarrierTypes().subscribe((result: CarrierType[]) => {
                if (result) {
                    result = _.filter(result, (c: any) => {
                        return c.collectAccountInformation = true;
                    });
                    result.forEach(cType => {
                        this.carrierTypeOptions.push({label: cType.shippingMethod, value: cType.shippingMethod});
                    });
                }
            });
        } else {
            this.carrierAccounts = [];
            this.carrierAccounts.push({value: null, label: '--Select Carrier Account--'});
            this.getCarrierAccounts();
        }
    }
    onCarrierAccountChange($event) {
        this.selectionCarrierAccount = $event.value;
        if (this.selectionCarrierAccount && this.selectionCarrierAccount.recordID) {
            this.editCarrierAccountsForm.controls.accountHolderName.patchValue(this.selectionCarrierAccount.accountHolderName);
            this.editCarrierAccountsForm.controls.accountNumber.patchValue(this.selectionCarrierAccount.accountNumber);
            this.editCarrierAccountsForm.controls.shippingMethod.patchValue(this.selectionCarrierAccount.shippingMethod);
            this.isDisabledForm = true;
            this.editCarrierAccountsForm.controls.accountHolderName.disable();
            this.editCarrierAccountsForm.controls.accountNumber.disable();
            this.editCarrierAccountsForm.controls.shippingMethod.disable();
        } else {
            this.isDisabledForm = false;
            this.editCarrierAccountsForm.controls.accountHolderName.enable();
            this.editCarrierAccountsForm.controls.accountNumber.enable();
            this.editCarrierAccountsForm.controls.shippingMethod.enable();
            this.editCarrierAccountsForm.reset();
        }
    }
    saveCarrierAccounts() {
        if (this.editCarrierAccountsForm.valid || this.isDisabledForm) {
            // RC-401 (RK 06-APRIL-2020): For order FINALIZE screen edit and add carrier account
            if (this.openFrom === 'FINALIZE' && this.selectionCarrierAccount) {
                this.selectedCarrierAccount = this.selectionCarrierAccount;
                if (this.selectionCarrierAccount.recordID) {
                    let obj: any = {
                        data : this.selectedCarrierAccount
                    };
                    this.saveEvent.emit(obj);
                } else {
                    this.setObjectFromForm();
                    this.saveSelectedCarrierAccount();
                }
            } else {
                this.setObjectFromForm();
                this.saveSelectedCarrierAccount();
            }
        } else {
            this.htmlTools.validateAllFields(this.editCarrierAccountsForm);
        }
    }

    createCarrierAccountForm() {
        this.editCarrierAccountsForm = this.fb.group({
            accountHolderName: ['', Validators.required],
            accountNumber: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9-_]+$')]],
            shippingMethod: ['', Validators.required]
        });
    }

    private setObjectFromForm() {
        _.forEach(this.editCarrierAccountsForm.controls, (key, value) => {
            this.selectedCarrierAccount[value] = this.editCarrierAccountsForm.controls[value].value;
        });
    }

    get validateChk() {
        return this.editCarrierAccountsForm && this.editCarrierAccountsForm.controls;
    }

    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        } else if (field.errors.pattern) {
            return ' Please enter numeric value';
        }
    }

    ngOnChanges(changes: any) {
        console.log('changes', changes);
        if (changes && changes.selectedCarrierAccount && !_.isEmpty(changes.selectedCarrierAccount.currentValue)) {
            this.selectedCarrierAccount = changes.selectedCarrierAccount.currentValue;
            this.editCarrierAccountsForm.reset();
            this.setFormValues();
        }
        if (this.openFrom === 'FINALIZE') {
            this.selectedCarrierAccount = changes.selectedCarrierAccount.currentValue;
            this.editCarrierAccountsForm.reset();
            this.setFormValues();
            const findObj = [];
            findObj.push({label: 'Select Shipping Method', value: null});
            Object.keys(this.activeOrderObj.shippingMethodOptions).forEach(k => {
                if (objectPath.get(this.activeOrderObj.shippingMethodOptions[k], 'value.collectAccountInformation')) {
                    findObj.push({label: this.activeOrderObj.shippingMethodOptions[k].label, value: objectPath.get(this.activeOrderObj.shippingMethodOptions[k], 'value.shippingMethod')});
                }
            });
            this.carrierTypeOptions = _.cloneDeep(findObj);
            this.editCarrierAccountsForm.patchValue({shippingMethod: objectPath.get(this.shipObjMatch, 'value.shippingMethod')});
        }
    }

    onHide($event: any) {
        this.closeEvent.emit(this.displayCarrierAccountDialog);
    }

    setFormValues() {
        this.editCarrierAccountsForm.patchValue(this.selectedCarrierAccount);
    }

    private saveSelectedCarrierAccount() {
        this.loading = true;
        this.retailerService.saveRetailerCarrierAccounts(this.selectedCarrierAccount, this.retailerID).subscribe((response: any) => {
            this.loading = false;
            if (response) {
                this.saveEvent.emit(response);
            } else {
                this.msgService.add({severity: 'error', summary: 'Error', detail: 'Error saving carrier account'});
            }
        }, (err) => {
            this.loading = false;
            this.msgService.add({severity: 'error', summary: 'Error', detail: 'Error saving carrier account'});
        });
    }

    ngOnDestroy() {
        this.destroyCarrierAcc.next();
    }
    private getCarrierAccounts() {
        this.loading = true;
        this.destroyCarrierAcc.next();

        this.retailerService.getRetailerCarrierAcccount(this.retailerID).pipe(takeUntil(this.destroyCarrierAcc)).subscribe((response: APIResponse) => {
            this.loading = false;
            if (response.success) {
                this.carrierAccounts = [];
                this.carrierAccounts.push({value: null, label: '--Select Carrier Account--'});
                response.data.forEach(info => {
                    this.carrierAccounts.push({value: info, label: this.getCarrierLabel(info)});
                });
                this.carrierAccounts.push({value: new CarrierAccount(), label: '--Add Account--'});
            } else {
                this.msgService.add({severity: 'error', summary: 'Error', detail: 'Error fetching carrier account'});
            }
        }, (err) => {
            this.loading = false;
            this.msgService.add({severity: 'error', summary: 'Error', detail: 'Error fetching carrier account'});
        });
    }
    private getCarrierLabel(info) {
        return info.shippingMethod + ' ' + info.accountHolderName + ' *' + (info.accountNumber ? info.accountNumber : '');
    }

}
