import {Component, OnInit} from '@angular/core';
import { PhoneNumberPipe } from 'src/app/pipes/phone-number.pipe';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        PhoneNumberPipe
    ],
})
export class FooterComponent implements OnInit {

    public domainData: any = (<any>window).domainData;

    constructor(private phone: PhoneNumberPipe) {
    }

    ngOnInit() {
        const rg = this.domainData.repGroup;
        if (rg) {
            this.domainData.rgName = rg.name;
            this.domainData.rgWebsite = rg.website;
            console.log('website>>>>>>>', this.domainData.rgWebsite);
            const rgAddress: any[] = [];
            if (rg.address1) {
                rgAddress.push(rg.address1);
            }
            if (rg.city) {
                rgAddress.push(rg.city);
            }
            if (rg.state) {
                rgAddress.push(rg.state);
            }
            if (rg.country) {
                rgAddress.push(rg.country);
            }
            if (rg.zip) {
                rgAddress.push(rg.zip);
            }
            this.domainData.rgAddress = rgAddress.join(', ');
            const rgContact: any[] = [];
            if (rg.phone) {
                rgContact.push('Tel. ' + this.phone.transform(rg.phone));
                this.domainData.rgPhone = rg.phone ;
            }
            if (rg.fax) {
                rgContact.push('Fax. ' + this.phone.transform(rg.fax));
                this.domainData.rgFax = rg.fax;
            }
            if (rg.email) {
                // rgContact.push(rg.email);
                this.domainData.rgEmail = rg.email;
            }
            this.domainData.rgContact = rgContact.join('  •  ');
        }
    }

}
