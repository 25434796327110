export class ItemSearch {
    itemNumber: string;
    upc: string;
    name: string;
    description: string;
    uiDateModifiedFrom: Date;
    uiDateModifiedTo: Date;
    dateModifiedFrom: number;
    dateModifiedTo: number;
    isAvailable: boolean;
    manufacturerId: string;

    constructor() {
    }
}
