import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { AuthService } from '../../../services/middleware/auth/auth.service';
import { Login } from '../../../models/login';
import { Constant } from '../../../constant';
import { CompanyService } from '../../../services/api/company.service';
import { AppComponent } from 'src/app/app.component';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { AuthAPIService } from '../../../services/api/auth-api/auth-api.service';
import { takeUntil } from 'rxjs/operators';
import * as objectPath from 'object-path';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { RetailerService } from '../../../services/middleware/retailer/retailer.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    public loginForm: FormGroup;
    public submitted = false;
    public isLoading = false;
    public domainData: any = (window as any).domainData;
    public promo: string;

    ipAddress: any;
    storeCompanyID: any[] = [];
    private readonly onDestroy = new Subject<void>();

    notUsingChrome = false;
    loginDashboardCustomSignupWidget: any = {};
    constructor(
        private formBuilder: FormBuilder,
        public authService: AuthService,
        private router: Router,
        private appComponent: AppComponent,
        private messageService: MessageService,
        private companyService: CompanyService,
        private authAPI: AuthAPIService,
        private http: HttpClient,
        private retailerService: RetailerService,
    ) {
        this.promo = this.companyService.getPromoPath() + '?' + new Date().getTime();
    }

    ngOnInit() {
        this.getIPAddress();
        this.notUsingChrome = navigator.userAgent.indexOf('Chrome') === -1;
        console.log('loggedIn loginInit>>', this.notUsingChrome);
        this.loginDashboardCustomSignupWidget = this.authService.getLoginDashboardCustomSignupWidget();
        this.createLoginForm();
    }

    ngOnDestroy() {
        this.onDestroy.next();
    }

    createLoginForm() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    async onSubmit() {
        this.submitted = true;
        // check form validation
        if (this.loginForm.valid) {
            const formData: Login = this.loginForm.getRawValue();
            this.isLoading = true;
            const res = await this.authService.localLogin(formData);
            this.isLoading = false;
            console.log('login Successs', res);
            if (res.error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Login failed',
                    detail: Constant.LOGIN_ERROR
                });
            } else {
                this.storeCompanyID = this.authService.setDataToLocal(res, this.loginForm.value.username);

                if (this.storeCompanyID && this.storeCompanyID.length) {
                    sessionStorage.setItem('allLoginCompanyID', JSON.stringify(this.storeCompanyID));
                    let i = 0;
                    for (i; i < this.storeCompanyID.length; i++) {
                        this.saveUserLoginInfo(this.storeCompanyID[i], 'login');
                    }
                }

                this.messageService.add({
                    severity: 'success',
                    summary: 'Login success',
                    detail: Constant.LOGIN_SUCCESS
                });
                this.retailerService.setRetailerDetailsEvent(true);

                const abandedCart = JSON.parse(sessionStorage.getItem('abandoned_carts'));
                if (this.storeCompanyID[0] === abandedCart.buyerID && abandedCart && abandedCart.buyerID && abandedCart.retailerShipToLocationID && this.domainData && this.domainData.repGroup && this.domainData.repGroup.recordID) {
                    this.router.navigate(['/shop']);  return;
                }
                // RC-2222 (RK 13-OCT-2020): mtPay payment decline time retailer login step [start]
                const hittedUrl = JSON.parse(sessionStorage.getItem('connectionLostUrlData'));
                if (hittedUrl && _.includes(this.storeCompanyID, hittedUrl.queryParams.buyerID)) {
                        this.router.navigate([hittedUrl.path], { queryParams: hittedUrl.queryParams}).then(res => {
                            sessionStorage.removeItem('connectionLostUrlData');
                        });
                } else {
                    this.router.navigate(['/home']);
                }
                // RC-2222 (RK 13-OCT-2020): mtPay payment decline time retailer login step [end]
                // this.appComponent.updateWishListCount();
            }
        }
    }

    saveUserLoginInfo(res, type) {
        console.log('session data', res);
        const gtRepgroupID = this.domainData ? this.domainData.repGroup.recordID : null;
        let obj: any;

        obj = {
            companyID: res ? res : null,
            repGroupID: gtRepgroupID ? gtRepgroupID : null,
            type,
            source: 'B2B',
            ip: this.ipAddress ? this.ipAddress : null
        };

        // this.onDestroy.next();
        this.authAPI.saveLoginInfo(obj).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
            this.messageService.clear();
            if (response && response.success) {
                // this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Login Detail Save Successfully' });
            } else {
                // this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Login Detail Not Save' });
            }
        }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
        });
    }

    getIPAddress() {
        this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }
    getSignupUrl() {
        if (this.loginDashboardCustomSignupWidget['showSignupBtn'] &&  !this.loginDashboardCustomSignupWidget['btnLink']) {
            this.router.navigate(['/signup']);
        } else if (this.loginDashboardCustomSignupWidget['showSignupBtn'] && _.startsWith(this.loginDashboardCustomSignupWidget['btnLink'], '/')) {
            this.router.navigate([this.loginDashboardCustomSignupWidget['btnLink']]);
        } else {
            window.open(this.loginDashboardCustomSignupWidget['btnLink'], '_blank');
        }
    }
}
