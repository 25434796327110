<div class="reptime-theme my-20 w-100 sidebar-block sticky" [ngStyle]="{'top': stickyTop?stickyTop.offsetHeight+'px' : headerHeight}">
    <a href="javascript:;" id="close_sidebar" (click)="close_sidebar();"></a>
    <div class="sidebar-block-inner">
        <!-- B2B-102 (NL 25-FEB-2020) add shimmer effect HTML    -->
        <!-- shimmer effect -->
        <!-- categories shimmer effect -->
        <!-- <div class="sidebar-cat-block mb-10 " *ngIf="sidebarShimmer">
            <p-card class="card">
                <p-header class="card-header">
                    <div class="shimmer-effect p-10 Wid100"></div>            
                </p-header>
                <div class="py-10 px-10"> 
                    <div class="shimmer-effect p-10 Wid100 mb-5"></div>
                    <div class="shimmer-effect p-10 Wid70 mb-5"></div>                    
                    <div class="shimmer-effect p-10 Wid100 mb-5"></div>
                    <div class="shimmer-effect p-10 Wid60 mb-5"></div>
                    <div class="shimmer-effect p-10 Wid100"></div>
                </div>
            </p-card>
        </div> -->
        <!-- brands shimmer effect -->
        <!-- <div class="sidebar-cat-block mb-10 " *ngIf="sidebarmanufacturerShimmer">
            <p-card class="card">
                <p-header class="card-header">
                    <div class="shimmer-effect p-10 Wid100"></div>            
                </p-header>
                <div class="py-10 px-10"> 
                    <div class="Wid100 mb-5">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid80"></div>
                    </div>
                    <div class="Wid100 mb-5">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid70"></div>
                    </div>
                    <div class="Wid100 mb-5">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid80"></div>
                    </div>
                    <div class="Wid100 mb-5">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid60"></div>
                    </div>
                    <div class="Wid100">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid80"></div>
                    </div>                                        
                </div>
            </p-card>
        </div> -->
        <!-- categories shimmer effect -->
        <!-- <div class="sidebar-cat-block mb-10 " *ngIf="sidebarShimmer">
            <p-card class="card">
                <p-header class="card-header">
                    <div class="shimmer-effect p-10 Wid100"></div>            
                </p-header>
                <div class="py-10 px-10"> 
                    <div class="Wid100 mb-10 d-flex justify-content-between">
                        <div class="shimmer-effect p-20 Wid40"></div>
                        <div class="shimmer-effect p-20 Wid40"></div>
                    </div>
                    <div class="shimmer-effect p-5 Wid100 mb-10"></div>
                    <div class="Wid100 mb-10 d-flex justify-content-between align-items-center">
                        <div class="shimmer-effect p-5 Wid40"></div>
                        <div class="shimmer-effect p-15 Wid30"></div>
                    </div>
                </div>
            </p-card>
        </div> -->
        <!-- End -->
        <!-- B2B-224 (CB 02-APR-2020) Design of New Arival -->
        <div class="sidebar-cat-block mb-7"
            *ngIf="isQuickOrder !== 'true' && whichPage == 'shop' && auth.getShowNewArrivals()">
            <p-card class="card">
                <p-header class="card-header justify-content-between Wid100 padd-new-arrival">
                    <p-checkbox class="set-mfr-textwidth Wid100 font-bold" name="New Arrival"
                        (click)="updateNewArrival()" value="newArrival" label="New Arrivals" [(ngModel)]="newArrival"
                        binary="true"></p-checkbox>
                </p-header>
            </p-card>
        </div>
        <!-- RC-1653 (RK 03-MARCH-2020 ) Remove Show Best Seller MFG wise -->
        <!-- <div class="sidebar-cat-block mb-7"
            *ngIf="isQuickOrder !== 'true' && whichPage == 'shop' && auth.getShowBestSeller()">
            <p-card class="card">
                <p-header class="card-header justify-content-between Wid100 padd-new-arrival">
                    <p-checkbox class="set-mfr-textwidth Wid100 font-bold" name="Show Best Seller"
                        (click)="updateBestSeller()" value="newBestSeller" label="Show Best Seller" [(ngModel)]="newBestSeller"
                        binary="true"></p-checkbox>
                </p-header>
            </p-card>
        </div> -->
        <!-- categories shimmer effect -->
        <div class="sidebar-cat-block mb-7 " *ngIf="sidebarCategoryShimmer">
            <p-card class="card">
                <p-header class="card-header">
                    <div class="shimmer-effect p-10 Wid100"></div>
                </p-header>
                <div class="py-10 px-10">
                    <div class="shimmer-effect p-10 Wid100 mb-5"></div>
                    <div class="shimmer-effect p-10 Wid70 mb-5"></div>
                    <div class="shimmer-effect p-10 Wid100 mb-5"></div>
                    <div class="shimmer-effect p-10 Wid60 mb-5"></div>
                    <div class="shimmer-effect p-10 Wid100"></div>
                </div>
            </p-card>
        </div>
        <!-- B2B-161 (CB 12-MAR-2020) Dynamic Category design -->
        <!-- "main-active" main category active, "arrow-active" active arrow, and "cust-sub-active" sub caegory active -->
        <div class="sidebar-cat-block mb-7"
            *ngIf="!isVenderSelectFromRepgroup && items && items.length && isQuickOrder !== 'true' && showCategoryFilterBox">
            <p-card class="card">
                <p-header class="card-header justify-content-between Wid100">
                    <h3 class="my-0 p-cat-theme" style="font-weight: normal; font-size: 17px;">Categories</h3>
                    <a href="javascript:;" class="view-all-btn" [routerLink]="auth.getCategoryViewAllRedirectionLink()" style="color:#585958"
                        *ngIf="!pageType && !isIframeWindow" (click)="view_all_btn();">View All</a>
                    <a href="javascript:;" class="view-all-btn" style="color:#585958"
                        *ngIf="isIframeWindow" (click)="view_all_btn();">View All</a>
                </p-header>

                <div class="py-5 px-5">
                    <p-scrollPanel [ngClass]="[whichPage === 'vendor' ? 'vendor-detail-page':'']"
                        [style]="{width: '100%', height: whichPage === 'vendor' ? '375px' : '228px'}" id='categoryDiv'
                        styleClass="sidebar-category-scroll">
                        <div class="cust-category-main">
                            <ul id="mylist">
                                <li class="cust-category-list" *ngFor='let sideItem of items; let i = index;'
                                    id='main{{i}}{{sideItem.id}}'>
                                    <span class="cust-main-cat-list" [class.main-active]='sideItem.id == mainCat'>
                                        <a href="javascript:;" class="cat-namelist"
                                            (click)='!isIframeWindow ? filer(sideItem.id, "", $event) : ""'>{{sideItem.label}}</a>
                                        <a href="javascript:;"
                                            [ngClass]="[sideItem.id == selectedMenu?'arrow-active':'']"
                                            *ngIf='sideItem.items.length'
                                            class="cust-cat-arrow pi pi-fw pi-chevron-right"
                                            (click)='openSubMenu(sideItem.id)'></a>
                                    </span>
                                    <ul class="cust-sub-cat-main">
                                        <span *ngFor='let subItem of sideItem.items'>
                                            <li [ngClass]="[sideItem.id == selectedMenu?'show':'hide']"
                                                id='main{{i}}{{subItem.id}}'
                                                (click)='!isIframeWindow ? filer(sideItem.id, subItem.id, $event) : ""'>
                                                <a href="javascript:;"
                                                    [class.cust-sub-active]='subItem.id == subCat'>{{subItem.label}}</a>
                                            </li>
                                        </span>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </p-scrollPanel>
                </div>
            </p-card>
        </div>

        <!-- End -->
        <!-- <div class="sidebar-cat-block mb-10" *ngIf="items && items.length">
            <p-card class="card">
                <p-header class="card-header">
                    <h3 class="my-0"><strong>Categories</strong></h3>
                </p-header> -->
        <!-- B2B-102 (NL 24-FEB-2020)  add scrollPanel in Categories and vendor block-->
        <!-- <div class="py-10 px-5">
                    <p-scrollPanel [style]="{width: '100%', height: '200px'}" styleClass="sidebar-category-scroll">
                        <p-panelMenu [model]="items" class="b2bcat-menu-list" [multiple]="false"></p-panelMenu>
                    </p-scrollPanel>
                </div>
            </p-card>
        </div> -->
        <!-- brands shimmer effect -->
        <div class="sidebar-cat-block mb-7 " *ngIf="sidebarmanufacturerShimmer">
            <p-card class="card">
                <p-header class="card-header">
                    <div class="shimmer-effect p-10 Wid100"></div>
                </p-header>
                <div class="py-10 px-10">
                    <div class="Wid100 mb-5">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid80"></div>
                    </div>
                    <div class="Wid100 mb-5">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid70"></div>
                    </div>
                    <div class="Wid100 mb-5">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid80"></div>
                    </div>
                    <div class="Wid100 mb-5">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid60"></div>
                    </div>
                    <div class="Wid100">
                        <div class="shimmer-effect p-10 Wid10 mr-7"></div>
                        <div class="shimmer-effect p-10 Wid80"></div>
                    </div>
                </div>
            </p-card>
        </div>
        <div class="sidebar-cat-block mb-7 static-mfr" *ngIf="whichPage &&  whichPage !== 'vendor' && pageAllType"
            [class.quick-order-mfr]='isQuickOrder'>
            <p-card class="card" *ngIf="!sidebarmanufacturerShimmer">
                <p-header class="card-header">
                    <div class="d-flex align-items-center justify-content-between Wid100">
                        <h3 class="my-0 p-cat-theme" style="font-weight: normal; font-size: 17px;">Manufacturers</h3>
                        <a href="javascript:;" class="view-all-btn" [routerLink]="'/vendors'" style="color:#585958" *ngIf="!pageType" (click)="view_all_btn();">View All</a>
                    </div>
                </p-header>
                <div class="py-5 px-8">
                    <p-scrollPanel [style]="{width: '100%', height: '375px'}" styleClass="sidebar-category-scroll">
                        <ng-container *ngIf="!manufacturersArr?.length">
                            <div class="ui-g-12 item-brand-chk b2b-brand-list align-center">
                                There are no brands to shop from.
                            </div>
                        </ng-container>
                        <ng-container *ngIf="manufacturersArr?.length">
                            <div class="ui-g-12 item-brand-chk b2b-brand-list" *ngIf="manufacturersArr?.length">
                                <p-checkbox class="set-mfr-textwidth" name="Uncheck All"
                                    [disabled]="isIframeWindow"
                                    (onChange)="!isIframeWindow ? unCheckAllMfg($event) : ''" value="unCheckAll" label="Uncheck All"
                                    [(ngModel)]="unChkAllMfg"></p-checkbox>
                            </div>
                            <div *ngFor="let mfg of manufacturersArr;let index =index"
                                class="ui-g-12 item-brand-chk b2b-brand-list">
                                <p-checkbox class="set-mfr-textwidth" 
                                name="{{rtSharedService.getSeletedLanguage(mfg.repGroupManufacturers[0].name, 
                                    mfg.repGroupManufacturerName,
                                    mfg.repGroupManufacturers[0].name_json)}}"
                                    [disabled]="isIframeWindow"
                                    (onChange)="!isIframeWindow ? updateChangesMade($event) : ''" [(value)]="mfg.recordID"
                                    *ngIf="+mfg.showlock===0 || !isLogin" 
                                    label="{{rtSharedService.getSeletedLanguage(mfg.repGroupManufacturers[0].name, 
                                        mfg.repGroupManufacturerName,
                                        mfg.repGroupManufacturers[0].name_json)}}"
                                    [(ngModel)]="chkMFG" 
                                    pTooltip="{{rtSharedService.getSeletedLanguage(mfg.repGroupManufacturers[0].name, 
                                    mfg.repGroupManufacturerName,
                                    mfg.repGroupManufacturers[0].name_json)}}"
                                    tooltipPosition="top"></p-checkbox>
                                <div (click)="!isIframeWindow ? openOverlay($event, request_overlay,mfg) : ''" class="p-grid  p-align-center"
                                    style="margin: 0px;cursor: pointer;" *ngIf="+mfg.showlock===1 && isLogin">
                                    <i class="fa fa-lock p-col p-0" style="font-size:27px;max-width: 17.36px;"></i>
                                    <div class="p-col p-0" style="padding-left: 0.7em !important;">
                                        {{rtSharedService.getSeletedLanguage(mfg.repGroupManufacturers[0].name, 
                                    mfg.repGroupManufacturerName,
                                    mfg.repGroupManufacturers[0].name_json)}} 
                                        
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </p-scrollPanel>
                    <p-overlayPanel #request_overlay [appendTo]="'body'">
                        <p class="requestsend-btn  p-10 px-10" (click)="sendRequest()" style="cursor: pointer;">Send Request for Approval</p>
                    </p-overlayPanel>
                    <!-- <div class="b2b-brand-list"><p-checkbox name="group1" value="Vendor 1" label="Vendor 1"></p-checkbox></div>
                    <div class="b2b-brand-list"><p-checkbox name="group1" value="Vendor 2" label="Vendor 2"></p-checkbox></div>
                    <div class="b2b-brand-list"><p-checkbox name="group1" value="Vendor 3" label="Vendor 3"></p-checkbox></div> -->
                </div>
            </p-card>
        </div>
        <div class="sidebar-cat-block mb-7" *ngIf="((showVendorPriceCard && isQuickOrder !== 'true') || isIframeWindow) && colorOptions && colorOptions?.colorsList?.length">
            <p-card class="card">
                <p-header class="card-header">
                    <h3 class="my-0 p-cat-theme" style="font-weight: normal; font-size: 17px;">Color Filter</h3>
                </p-header>
                <div class="p-grid py-10 px-15 color-custom">
                    <p-scrollPanel [style]="{width: '100%', height: 'auto'}" styleClass="sidebar-color-scroll">
                            <div class="d-flex flex-wrap ui-g-12 ">
                                <div *ngFor="let colorItem of colorOptions?.colorsList;let index =index" class="b2b-brand-list mr-10 mb-5 mt-5">
                                    <p-checkbox [style]="{background: colorItem.colorcode}" class="set-mfr-textwidth set-dynamic-bgcolor" name="{{colorItem.colorlabel}}"
                                        [disabled]="isIframeWindow"
                                        (onChange)="!isIframeWindow ? colorOnChange($event) : ''" [(value)]="colorItem.colorlabel"
                                        [pTooltip]="colorItem.colorlabel"
                                        [(ngModel)]="colorModel"
                                        tooltipPosition="top"></p-checkbox>
                                </div>
                            </div>
                    </p-scrollPanel>
                </div>
            </p-card>
        </div>
        <div class="sidebar-cat-block" *ngIf="(showVendorPriceCard && isQuickOrder !== 'true' && !pageType) || isIframeWindow">
            <p-card class="card">
                <p-header class="card-header">
                    <h3 class="my-0 p-cat-theme" style="font-weight: normal; font-size: 17px;">Price Filter</h3>
                </p-header>
                <div class="p-grid py-10 px-15 price-custom">
                    <input class="p-col-5" type="text" [(ngModel)]="rangeMinMaxArray[0]" pInputText pKeyFilter="money"
                        (change)="fromPrice($event)" [disabled]="isIframeWindow ? true: false" placeholder="Min Price" />
                    <div class="p-col-2">
                        <p>-</p>
                    </div>
                    <input class="p-col-5" type="text" [(ngModel)]="rangeMinMaxArray[1]" pInputText pKeyFilter="money"
                        (change)="toPrice($event)" [disabled]="isIframeWindow ? true: false" placeholder="Max Price" />
                </div>
                <div class="py-10 px-15">
                    <p-slider #rangeSlider [(ngModel)]="rangeValues" [min]="rangeMinMaxArray[0]"
                        [max]="rangeMinMaxArray[1]" [range]="true" class="price-range-slider"></p-slider>
                    <div class="p-grid slider-custom">
                        <h3 class="p-col-7" style="margin: 0px;margin-top: 20px;font-size: 15px;">Range:
                            {{currencySymbol}}{{rangeValues[0]}} - {{currencySymbol}}{{rangeValues[1]}}</h3>
                        <p-button class="p-col-5 p-align-center btn-custom" (click)="!isIframeWindow ? priceButton($event) : ''"
                            [styleClass]="'ui-button-info btn-theme-color'" [disabled]="isIframeWindow ? true: false" icon="fa fa-fw fa-search" label="Apply">
                        </p-button>
                    </div>
                </div>
            </p-card>
        </div>
    </div>
</div>