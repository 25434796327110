<!-- resized image -->
<!-- <img *ngIf="!resizedImageError && !ogImageError" (load)="loaded(true)" src="{{resizedPicturePath}}" (error)="resizedImageError=true" class="{{imgTagClass}}" /> -->

<!-- original image -->
<!-- <img *ngIf="resizedImageError && !ogImageError" (load)="loaded(true)" src="{{ogPicturePath}}" (error)="ogImageError=true" class="{{imgTagClass}}" /> -->

<!-- no image -->
<!-- <img *ngIf="ogImageError && resizedImageError" (load)="loaded(true)" src="assets/images/no-image-available.png"  class="{{imgTagClass}}" /> -->


<img (load)="loaded(true)" src="{{ogPicturePath}}" (error)="ogImageError=true" class="{{imgTagClass}}" onerror="this.src='assets/images/no-image-available.png'"/>
