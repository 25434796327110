<p-toast key="addItem" position="top-right" [baseZIndex]="110000"></p-toast>
<p-card class="card" [ngClass]="isLogin ? 'login-card-header': 'logout-card-header'">
    <div [ngStyle]="{'top': stickyTop?stickyTop.offsetHeight+'px' : headerHeight}">
        <p-header class="card-header search-tool-box">
            <!-- B2B-102 (NL 25-FEB-2020) add shimmer effect HTML    -->
            <!-- shimmer effect -->
            <div class="Wid100 d-flex align-items-center" style="display: none !important;">
                <div class="flex-1 search-box p-col-6 p-0">
                    <div class="shimmer-effect p-20 Wid100"></div>
                </div>
                <div
                    class=" p-col-6 flex-1 search-actions mfg-dropdown rt-brows-center d-flex align-items-center justify-content-between p-0">
                    <div class="callout-dropdown-part p-col-4">
                        <div class="shimmer-effect p-20 Wid100"></div>
                    </div>
                    <div class="">
                        <span class="shimmer-effect p-15 mr-2"></span>
                        <span class="shimmer-effect p-15"></span>
                    </div>
                </div>
            </div>
            <!-- End -->
            <div class="search-wrap">
                    <!-- <a href="javascript:;" id="show_sidebar" (click)="show_sidebar();"><i class="fa fa-bars"
                            aria-hidden="true"></i> Filter</a> -->
                    <rs-advanced-search [placeholderForSearchBar]="placeholderForSearchBar"
                        [advanceOptions]="advanceOptions" [dateRangeOptions]="dateRangeOptions"
                        [dateRangeLabel]="'Item Modified Date Range'" [isStatusActive]="false" [isAvailable]="true"
                        [isResetLoading]="isResetLoading" [typeList]="typeList" [isSubmitLoading]="isSubmitLoading"
                        [defaultSearchBy]='isQuickOrder? "" : ""'
                        (submitEvent)="search($event)" (resetEvent)="reset($event)" [searchFormData]='searchFormData'
                        (ready)="initSearchObj($event)">
                    </rs-advanced-search>
            </div>

        </p-header>
    </div>

    <p-table id="items-table" [columns]="cols" [value]="items" dataKey="recordID" [loading]="loadingTableData"
        (onLazyLoad)="itemload($event, searchForm, 'TableView')" #itemListingTable [lazy]="true" [autoLayout]="true"
        [totalRecords]="totalItemCount" [paginator]="true" sortField="itemNumber"
        [responsive]="false" [rowsPerPageOptions]="[rows, rows*2, rows*3]" [(first)]="tableEvent.first"
        [rows]="tableEvent.rows" [(selection)]="selecteditems" [style]="{'table-layout':'auto'}"
        (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" scrollHeight="calc(100vh - 470px)"
        [scrollable]="true">
        <ng-template pTemplate="paginatorright">
            <div class="per-page-text">Per page :</div>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
            <div class="per-page-text px-10">Total :</div>
            {{totalItemCount}}
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 150px;">
                    Image
                </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" [style.width]="col.width">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" (click)="selectItemData(rowData)"
                [style.background]="(selectedItemObj && selectedItemObj.recordID === rowData.recordID) ? '#fad961': ''">
                <td style="width: 150px;">
                    <img [src]="rowData.fullPicturePath64px" class="img-cover"
                        style="max-width: 100%;object-fit: contain;" width="50" height="50"
                        onerror="this.src='assets/images/no-image-available.png'">
                </td>
                <td *ngFor="let col of columns" [style.width]="col.width">
                    <div [ngSwitch]="col.field">
                        <div *ngSwitchCase="'itemnumber'">
                            <a style="color:#333;"> {{rowData[col.field]}}</a>
                        </div>
                        <div *ngSwitchCase="'name'" class="Bold">
                            <a style="color:#333;"> {{rowData[col.field]}} </a>
                        </div>
                        <!-- <div *ngSwitchCase="'nextAvailableDate'">
                            {{rowData[col.field] | rtDate : 'UTC'}}
                        </div> -->
                        <!-- <div *ngSwitchCase="'minimumquantity'">{{(rowData[col.field] ? rowData[col.field] :
                            null) | number}}
                        </div> -->
                        <div *ngSwitchCase="'unitPrice'" [class]="col.class">
                            <ng-container
                                *ngIf="!(auth.getParentItemShoppingFlag() === false && rowData.isParentItem === true) && !rowData.b2bItemPricingPolicyFlag">
                                {{(rowData[col.field] ? rowData[col.field] : 0) | rtCurrency}}
                            </ng-container>
                        </div>
                        <div *ngSwitchCase="'upc1'">
                            <a style="color:#333;">{{rowData[col.field]}}</a>
                        </div>
                        <!-- <div *ngSwitchCase="'upc2'">
                            <a style="color:#333;">{{rowData[col.field]}}</a>
                        </div>
                        <div *ngSwitchCase="'upc3'">
                            <a style="color:#333;">{{rowData[col.field]}}</a>
                        </div> -->
                        <!-- <div *ngSwitchCase="'isavailable'" style="text-align:center;">
                            <i *ngIf="rowData[col.field] === 1" class="fa fa-check green"></i>
                            <div *ngIf="rowData[col.field] === 0 && rowData['nextavailabledate'] !== null">
                                {{rowData['nextavailabledate'] | rtDate : 'UTC'}}
                            </div>
                            <i *ngIf="rowData[col.field] === 0 && rowData['nextavailabledate'] === null"
                                class="fa fa-times red"></i>
                        </div> -->
                        <!-- <div *ngSwitchCase="'qtyavailable'">{{(rowData[col.field] ? rowData[col.field] : null) |
                            number}}
                        </div>
                        <div *ngSwitchCase="'datemodified'">{{rowData[col.field] | rtDate: 'UTC'}}
                        </div> -->
                        <!-- <div *ngSwitchCase="'itemDiscount'">
                            <input type="number" pInputText [(ngModel)]="rowData[col.field]" min="0"
                                (ngModelChange)="applyDiscount(rowData)" onClick="this.select();"
                                style="width: 75px;"
                                *ngIf="rowData.isc && !rowData.isc.length" />
                        </div> -->
                        <!-- <div *ngSwitchCase="'quantity'">
                            <ng-container
                                *ngIf="!(auth.getParentItemShoppingFlag() === false && rowData.isParentItem === true) && !rowData.b2bItemPricingPolicyFlag">
                                <div
                                    *ngIf="!rowData.discontinued || (rowData.discontinued === true && rowData.allowDiscontinuedItemsShopping === true)">
                                    <input type="text" pInputText [(ngModel)]="rowData[col.field]" pKeyFilter="pint"
                                        (ngModelChange)="applyVolumePrice(rowData)" (blur)="setQuantity(rowData)"
                                        (click)="onclickSaveOldQty(rowData)" onClick="this.select();"
                                        style="width: 75px;" step="rowData.quantityIncrement"
                                        [min]="rowData.minimumQuantity" *ngIf="rowData.isc && !rowData.isc.length" />
                                </div>
                                <div *ngIf="rowData.discontinued && (rowData.allowDiscontinuedItemsShopping === null || rowData.allowDiscontinuedItemsShopping === false)"
                                    pTooltip="Discounted item are restricted for order.">
                                    <button pButton type="button" [disabled]="true"
                                        class="ui-button-secondary ui-button" label="Discontinued"></button>
                                </div>
                            </ng-container>
                        </div> -->
                        <!-- <div *ngSwitchCase="'total'" [class]="col.class">
                            {{((rowData['quantity'] * rowData['unitPrice']) -
                            ((rowData['quantity'] * rowData['unitPrice']) *
                            (rowData['itemDiscount'] / 100))) | rtCurrency}}
                        </div>
                        <div *ngSwitchCase="'itemPriceCodes'">
                            <div *ngIf="rowData.isc && !rowData.isc.length">
                                <select [(ngModel)]="rowData.selectedPriceCode" class="price-code-select"
                                    (change)="setPriceCode(rowData)"
                                    *ngIf="rowData[col.field] && rowData[col.field].length" style="width: 85px;">
                                    <option [value]="''">Select</option>
                                    <option *ngFor="let obj of rowData[col.field]" [value]="obj.priceCode">
                                        {{obj.manufacturerPriceCode ? obj.manufacturerPriceCode + ' (' + obj.priceCode +
                                        ')' : obj.priceCode}}
                                    </option>
                                </select>
                            </div>
                        </div> -->
                        <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr class="table-empty" *ngIf="!loadingTableData">
                <td class="text-center" [attr.colspan]="cols.length + 3">No items found. Please check back later.</td>
            </tr>
        </ng-template>
    </p-table>

    <div class="gatDiv">
        <button class="theme-maroon-btn btn" label="Select Data" pButton title="Select Data" type="button"
            [class.cursor-default]="(selecteditems && !selecteditems.length) && !selectedItemObj"
            [icon]="'fas fa-check-square'" style="width: 150px;" (click)="getData()"
            [disabled]="(selecteditems && !selecteditems.length) && !selectedItemObj"></button>
    </div>
</p-card>
<!-- <app-item-view-model [activeViewIndex]="activeViewIndex" [activeViewItem]="this.activeViewItem"
    [manufacturersArr]="manufacturersArr" [selectedCustomer]="this.selectedCustomer"
    [existCartData]="existCartData" [fromPage]="fromPage"
    [(isOpen)]="displayItemViewDialog" *ngIf="displayItemViewDialog">
</app-item-view-model> -->

<!-- *ngIf="auth.getCartStyle() !== 2"
<p-sidebar [(visible)]="cartSidebar" position="right" [baseZIndex]="10000" [dismissible]="true" [showCloseIcon]="false"
    class="cart-prime-sidebar shop-cart-static">
    <div class="cart-right-sidebar">
        <app-cartbar [confirmkey]="'item-table-confirmkey'"
            [existCartData]="existCartData"
            [pageType]="pageType"
            [cartBarPosition]="cartBarPosition"></app-cartbar>
    </div>
</p-sidebar>
<app-download-image-zip *ngIf="visibledownload" [listOfItems]="selecteditems" (hide)="visibledownload=$event">
</app-download-image-zip> -->
<!-- <RtCustom-confirmDialog key="breckincrement2" [baseZIndex]="1000" [appendTo]="'body'" #breckincrement2
    header="Drop database" acceptLabel="Yes" rejectLabel="No">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" id="YesButton" label="Yes"
            (click)="breckincrement2.accept()"></button>
        <button type="button" class="ui-button-secondary" pButton icon="pi pi-times" label="No"
            (click)="breckincrement2.close($event)"></button>
    </p-footer>
</RtCustom-confirmDialog> -->