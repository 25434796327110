<div class="p-grid m-0 p-col-align-center">
    <div class="print-right">
      <div class="p-grid m-0 p-col-align-center">
        <div class="p-col-fixed p-col-align-center">
          <button pButton type="button" label="Go Back" icon="fa fa-chevron-left" iconPos="left" class="ui-button-info theme-maroon-btn"
            (click)="close.emit()"></button>
        </div>
      
        <div class="p-col-fixed p-col-align-center p-col-fixed" *ngIf="screen === 'finalize'">
          <p-dropdown class="width100 custom-dropdown" [(ngModel)]="sortorder.sort" [filter]="true" appendTo="body"
            [options]="sortbuyoption" (onChange)="changesorting(0)">
          </p-dropdown>
        </div>
        <div class="p-col-fixed p-col-align-center p-col-fixed" *ngIf="screen === 'finalize'">
          <button (click)="changesorting(1)" type="button" pButton class="ui-button-secondary custom-button"
            [icon]="sortorder.order === 'ASC' ? 'pi pi-sort-alpha-down-alt' : 'pi pi-sort-alpha-up-alt'"></button>
        </div>
        <div class="p-col-fixed p-col-align-center p-col-fixed" *ngIf="screen === 'finalize'">
          <p-dropdown class=" custom-dropdown" [style]="{'width':'195px'}" [(ngModel)]="itemwithimage" [filter]="true"
            appendTo="body" placeholder="Print images" [options]="itemwithimageoption"
            (onChange)="previewOrder('pdf')">
            <ng-template let-item pTemplate="selectedItem">
              <i class="{{item?.icon}}" style="float:left;font-size: 1.3em; margin-right: 8px;" *ngIf="item?.icon"></i>
              <div style="height: 18px;line-height: 18px;">
                {{item.label}}</div>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <div class="ui-helper-clearfix">
                <i class="{{option?.icon}}" style="float:left;font-size: 1.3em ;margin-right: 10px"
                  *ngIf="option?.icon"></i>
                <div style="font-size:14px;">{{option.label}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="p-col-fixed p-col-align-center  margin-left-auto">
          <div class="" style="display: inline-block;font-weight: bold;">
            {{math.round(zoom*100)}}%
          </div>
          <button type="submit" [disabled]="loading || zoom>=1.5" (click)="zoominout(0)" class="ui-button-info ml-10 theme-maroon-btn"
            icon="fas fa-plus" pButton title="Zoom In"></button>
          <button type="submit" [disabled]="loading || zoom<=0.5" (click)="zoominout(1)" class="ui-button-info ml-5 theme-maroon-btn"
            icon="fas fa-minus" pButton title="Zoom Out"></button>
        </div>
        <div class="p-col-fixed p-col-align-center">
          <p-dropdown appendTo="body" [disabled]="loading" [options]="Export" (onChange)="selectedExportOption($event)">
            <ng-template let-item pTemplate="selectedItem">
              <i class="{{item?.value?.icon}}" style="float:left;font-size: 1.3em; margin-right: 8px;"
                *ngIf="item.value"></i>
              <div style="height: 18px;line-height: 18px;">
                {{item.label}}</div>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <div class="ui-helper-clearfix">
                <i class="{{option?.value?.icon}}" style="float:left;font-size: 1.3em ;margin-right: 10px"
                  *ngIf="option.value"></i>
                <div style="font-size:14px;">{{option.label}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="p-col-fixed p-col-align-center">
          <button type="submit" [disabled]="loading || loadingprint" (click)="printFile(pdfSrc)"
            class="ui-button-success" [icon]="loadingprint?'fas fa-spinner fa-pulse':'fas fa-print'" label="Print"
            pButton title="Print"></button>
        </div>
      </div>
      <div class="custom-pdf-viewer-dialog" [ngClass]="{'hidescroll': showPdfDialogLoader}">
        <div class="printloader" *ngIf="showPdfDialogLoader">
          <h1 style="font-size: 20px;">Loading Data...</h1>
          <p-progressSpinner></p-progressSpinner>
        </div>
        <pdf-viewer *ngIf="pdfSrc" [original-size]="false" [autoresize]="true" [fit-to-page]="true" [zoom]="zoom"
          [show-borders]="true" [src]="pdfSrc" (on-progress)="onProgressPdfViewer($event)"
          (after-load-complete)="callBackFnPdfViewer($event)" [ngStyle]="{'max-height': '100%', 'width': '100%'}">
        </pdf-viewer>
      </div>
    </div>
    <!-- <div class="print-left">
    </div> -->
  </div>
  
  
  <p-dialog styleClass="overflow-auto cut-dialog-height" [(visible)]="isOpenExportFile" [closable]="true" appendTo="body"
    [draggable]="false" [blockScroll]="true" header="Export options" [closeOnEscape]="true" [showHeader]="true"
    [style]="{'width': '400px'}" [positionTop]="120" [maximizable]="false" [responsive]="true" [modal]="true"
    [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'" (onHide)="hideExportFileName()">
    <form [formGroup]="exportFileForm">
      <div class="p-grid p-nogutter mt-5">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-float-label file-control">
            <input id="filename" class="control" [(ngModel)]="filename" maxlength="50" type="text" name="filename"
              formControlName="exportFileFormControl" pInputText autocomplete="off">
            <span>{{_exportFileExtension}}</span>
            <label for="email">File name</label>
          </div>
        </div>
      </div>
    </form>
    <p-footer class="clearfix">
      <button type="submit" (click)="eventExportFile();" [icon]="loading ? 'fas fa-spinner fa-pulse' : 'pi pi-home'"
        [disabled]="loading" class="ui-button-info" pButton label="Export"></button>
    </p-footer>
  </p-dialog>
 