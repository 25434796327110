import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Retailer} from '../../../models/retailer';
import {Table} from 'primeng/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompanyAPIService} from '../../../services/api/company-api/company-api.service';
import {SalespersonService} from '../../../services/api/salesperson.service';
import {MessageService} from 'primeng/api';
import {RepgroupService} from '../../../services/api/repgroup.service';
import {ReptimeEntity} from '../../../models/reptime-entity';
import {APIResponse} from '../../../models/api-response';
import {Salesperson} from '../../../models/salesperson';
import * as _ from 'lodash';
import * as objectPath from 'object-path';

@Component({
    selector: 'rs-salesperson-assign-table',
    templateUrl: './salesperson-assign-table.component.html',
    styleUrls: ['./salesperson-assign-table.component.scss']
})
export class SalespersonAssignTableComponent implements OnInit, OnChanges {
    @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() selectedRetailer: Retailer;
    @Input() repGroupID: string;
    @Input() tableError: boolean;
    @Output() tableErrorChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() selectedRepGroupRetailer: any;
    @Input() selectedRepGroupSalespersonRetailer: any;
    @ViewChild('salespersonTableEle', {static: true}) salespersonTableEle: Table;
    @ViewChild('salespersonFormEle', {static: false}) salespersonFormEle: any;
    @Input() salesPersonTable: any[] = [];
    @Output() salesPersonTableChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() isLoading: boolean;
    @Output() isLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    salesPersonList: Salesperson[] = [];
    salespersonDetail: FormGroup;
    salespersonError: boolean;
    secondaryError: boolean;
    salesPersonTypeList: any[] = [];
    filteredSalesPersonTypeList: any[] = [];
    filteredSalesPersonList: any[] = [];
    cols: any[];

    constructor(private fb: FormBuilder, private companyService: CompanyAPIService, private repgroupService: RepgroupService,
                private salespersonService: SalespersonService, private msgService: MessageService) {
        this.cols = [
            {field: 'salesPersonName', header: 'Sales People'},
            {field: 'typeName', header: 'Type'},
            {field: 'action', header: ''}
        ];
        this.salesPersonTypeList = [
            {
                label: ReptimeEntity.SALESPERSON_TYPE_PRIMARY,
                value: 'Primary'
            },
            {
                label: ReptimeEntity.SALESPERSON_TYPE_SECONDARY,
                value: 'Secondary'
            },
            {
                label: ReptimeEntity.SALESPERSON_TYPE_KEY,
                value: 'Key'
            }
        ];

    }

    get validateChk() {
        return this.salespersonDetail && this.salespersonDetail.controls;
    }

    get getTotalLength() {
        return this.salesPersonTable.filter((d) => !d.recordDeleted).length;
    }

    initForm() {
        this.salespersonDetail = this.fb.group({
            salespersonType: ['', Validators.required],
            salesperson: [this.filteredSalesPersonTypeList[0].value, Validators.required]
        });
    }

    searchSalesperson() {
        this.isLoadingChange.emit(true);
        this.salespersonService.getSalespeopleByRepGroup(this.repGroupID, null, null, null, null, {}, true)
            .subscribe((response: APIResponse) => {
                if (response.success) {
                    this.salesPersonList = response.data.map((d) => {
                        const c: any = {};
                        c.label = `${d.name} [${d.recordID}]`;
                        c.value = d;
                        return c;
                    });
                } else {
                    this.salesPersonList = [];
                    this.msgService.add({
                        severity: 'error',
                        summary: 'error',
                        detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                    });
                }
                this.setFilteredSalesPersonList();
                this.isLoadingChange.emit(false);
            }, () => {
                this.isLoadingChange.emit(false);
            });
    }

    ngOnChanges(changes: any) {
        if (changes && ((changes.salesPersonTable && !_.isEmpty(changes.salesPersonTable.currentValue))
         || (changes.salesPersonTable.firstChange))) {
            console.log(changes.salesPersonTable.currentValue);
            this.setFilteredTypeList();
            this.setFilteredSalesPersonList();
            this.initForm();
        }
    }

    ngOnInit() {
        this.setFilteredTypeList();
        this.initForm();
        this.searchSalesperson();
    }

    deleteRow(rowData) {
        this.secondaryError = false;
        if (this.salesPersonTable.find(d => !d.recordDeleted && d.salespersonType.value === 'Secondary' &&
         rowData.salespersonType.value === 'Primary')) {
            this.secondaryError = true;
        } else if (rowData.recordID) {
            const filteredData: any = this.salesPersonTable;
            filteredData.forEach(d => {
                if (d.recordID === rowData.recordID) {
                    d.recordDeleted = true;
                }
            });
            this.salesPersonTableChange.emit(filteredData);
        } else if (rowData) {
            const filteredData: any = this.salesPersonTable.filter(d => d.salesPersonID !== rowData.salesPersonID);
            this.salesPersonTableChange.emit(filteredData);
            this.setFilteredTypeList();
            this.setFilteredSalesPersonList();
            this.salespersonTableEle.reset();
        }
    }

    addRow($event) {
        this.secondaryError = false;
        $event.preventDefault();
        $event.stopImmediatePropagation();
        $event.stopPropagation();
        this.salespersonError = false;
        if (this.salespersonDetail.valid) {
            if (this.salespersonDetail['controls'].salesperson.value.recordID &&
                this.salespersonDetail['controls'].salespersonType.value) {
                if (this.tableErrorChange) {
                    this.tableErrorChange.emit(false);
                }
                const data = this.salesPersonTable;
                const salespersonIndex = data.findIndex((d) => {
                    return d.recordDeleted && d.salespersonType.value === this.salespersonDetail['controls'].salespersonType.value.value;
                });
                if (salespersonIndex === -1) {
                    data.push({
                        salesPersonID: this.salespersonDetail['controls'].salesperson.value.recordID,
                        salesPersonName: this.salespersonDetail['controls'].salesperson.value.name,
                        typeName: this.salespersonDetail['controls'].salespersonType.value.label,
                        salespersonType: this.salespersonDetail['controls'].salespersonType.value
                    });
                } else if (salespersonIndex > -1) {
                    data[salespersonIndex].salesPersonName = this.salespersonDetail['controls'].salesperson.value.name;
                    data[salespersonIndex].salesPersonID = this.salespersonDetail['controls'].salesperson.value.recordID;
                    data[salespersonIndex].recordDeleted = false;
                }
                this.salesPersonTableChange.emit(data);
                this.setFilteredTypeList();
                this.setFilteredSalesPersonList();
                this.salespersonDetail.get('salesperson').setValue('');
                if (this.filteredSalesPersonTypeList.length) {
                    this.salespersonDetail.get('salespersonType').setValue(this.filteredSalesPersonTypeList[0].value);
                }
                this.salespersonTableEle.reset();
            }
        } else {
            this.salespersonError = true;
        }
    }

    setFilteredSalesPersonList() {
        const usedValues = this.salesPersonTable.filter((d) => !d.recordDeleted).map(c => c.salesPersonID);
        if (this.salesPersonList.length) {
            this.filteredSalesPersonList = this.salesPersonList.filter((d: any) => usedValues.indexOf(d.value.recordID) === -1);
        } else {
            this.filteredSalesPersonList = [];
        }
    }

    setFilteredTypeList() {
        const usedValues = this.salesPersonTable.filter(c => c.salespersonType.value && !c.recordDeleted).map(c => c.salespersonType.value);
        if (this.salesPersonTypeList.length) {
            this.filteredSalesPersonTypeList = this.salesPersonTypeList.filter((d: any) => {
                if (d.value !== 'Secondary') {
                    return usedValues.indexOf(d.value) === -1;
                } else if (usedValues.indexOf('Primary') !== -1) {
                    return usedValues.indexOf(d.value) === -1;
                }
            }).map(d => {
                const c: any = {};
                c.label = d.label;
                c.value = d;
                return c;
            });
        }
    }
}
