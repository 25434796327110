import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import { ShippingLocationNotFoundComponent } from './shipping-location-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/primeng';
import {SelectButtonModule} from 'primeng/selectbutton';
import {InputMaskModule} from 'primeng/inputmask';
import {TooltipModule} from 'primeng/tooltip';
import {CheckboxModule} from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
const routes = [{path: '', component: ShippingLocationNotFoundComponent}];

@NgModule({
  declarations: [ShippingLocationNotFoundComponent],
  imports: [
    CommonModule,
    CardModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    ButtonModule,
    DropdownModule,
    SelectButtonModule,
    InputMaskModule,
    TooltipModule,
    CheckboxModule,
    FormsModule,
    InputTextModule
]
})
export class ShippingLocationNotFoundModule { }
