import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/middleware/auth/auth.service';
import { UrlToolsService } from '../url-tools.service';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    win: any = (window);
    public domainData: any = this.win.domainData;
    public isIframeWindow: any = (window as any).iframeData;
    public logoBucketUrl = environment.logoBucketUrl;
    baseUrl = environment.apiUrl;
    getReptimeEntityUrl = this.baseUrl + 'reptime/api/company/{companyId}';
    saveReptimeEntityUrl = this.baseUrl + 'reptime/api/company/{companyId}/save';
    companyCategoryUrl = this.baseUrl + 'reptime/company/categories';
    companyCategoriesByRepGroupIdUrl = this.baseUrl + 'reptime/repgroup/{repGroupID}/retailerCategory/all';
    saveCompanyCategoriesByRepGroupIdUrl = this.baseUrl + 'reptime/repgroup/{repGroupID}/retailerCategory/assign';
    getCompanyCategoriesByRetailerIdUrl = this.baseUrl + 'reptime/api/repgroup/{repGroupID}/retailerCategory/{retailerID}/assignments';
    logoPath = this.logoBucketUrl + 'logo/{companyID}.png';
    promoPath = this.logoBucketUrl + 'promo/{companyID}.png';
    uploadCompanyLogoURL = this.baseUrl + 'reptime/api/bucket/{companyID}/uploadlogo';
    sendContactUsEmailURL = this.baseUrl + 'reptime/b2baccess/sendContactUsEmail';
    getWhatsTrendingB2BUrl = this.baseUrl + 'reptime/markettime/repGroup/{repGroupPublicAccessToken}/getWhatsTrendingB2B';
    getMarkethighlightB2BUrl = this.baseUrl + 'reptime/markettime/repgroup/{repGroupPublicAccessToken}/markethighlights/public/all';
    getRepGroupBrands = this.baseUrl + 'reptime/markettime/repGroup/{repGroupPublicAccessToken}/getRepGroupBrands';
    getRepGroupCategoryGroups = this.baseUrl + 'reptime/markettime/repGroup/{repGroupPublicAccessToken}/repgroups/groups';
    defaultBusinessDocumentTypeURL = this.baseUrl + 'reptime/b2baccess/defaultBusinessDocumentType';
    businessProofDocumentUploadURL = this.baseUrl + 'reptime/retailer/{buyertokenid}/businessProofDocument/upload';
    getSelectedManufacturersURL = this.baseUrl + 'reptime/markettime/repgroup/{repGroupPublicAccessToken}/manufacturer/{manufacturerID}';
    getCategoriesListURL = this.baseUrl + 'reptime/markettime/repgroup/{repGroupPublicAccessToken}/categories';
    downloadImagesUrl = this.baseUrl + 'reptime/api/item/{repGroupID}/getImages';
    imagesFilename = '{companyName}_Images.zip';
    // teaserURL = this.baseUrl + 'reptime/item/{manufacturerID}/{repGroupPublicAccessToken}/top/teaser/all';
    teaserRandom = this.baseUrl + 'reptime/item/{repGroupPublicAccessToken}/top/teaser/random/all';
    teaserURL = this.baseUrl + 'reptime/{repGroupPublicAccessToken}/item/all';
    mfrPreviewURL = this.baseUrl + 'reptime/{repGroupPublicAccessToken}/item/preview/all';
    getPItemDetails = this.baseUrl + 'reptime/item/{repGroupPublicAccessToken}/item/{id}';
    public retailerSelectedAddress: any = {};
    constructor(private httpClient: HttpClient, private urlTools: UrlToolsService, public auth: AuthService) {
    }

    addTokens(url: string, params) {
        Object.keys(params).forEach(key => {
            url = url.replace('{' + key + '}', params[key]);
        });
        return url;
    }
    // [B2B-262] (SG 18-JUN-2020) Save And Get company Information.
    getReptimeEntity() {
        this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        return this.httpClient.get(this.addTokens(this.getReptimeEntityUrl, { companyId: this.retailerSelectedAddress.retailerID }));
    }

    saveUpdateReptimeEntity(re) {
        this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            observe: 'response' as 'response'
        };
        const populatedUrl = this.addTokens(this.saveReptimeEntityUrl, { companyId: this.retailerSelectedAddress.retailerID });
        return this.httpClient.put(populatedUrl, re, httpOptions).pipe(map(res => {
            return res;
        }));
    }
    // End

    saveReptimeEntity(re) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            observe: 'response' as 'response'
        };
        const populatedUrl = this.addTokens(this.saveReptimeEntityUrl, {});
        return this.httpClient.put(populatedUrl, re, httpOptions).pipe(map(res => {
            return res;
        }));
    }

    getCompanyCategories() {
        return this.httpClient.get(this.companyCategoryUrl);
    }

    getCompanyCategoriesByRepGroupID(repGroupID) {
        return this.httpClient.get(this.addTokens(this.companyCategoriesByRepGroupIdUrl, { repGroupID }));
    }

    saveCompanyCategoriesByRepGroupID(repGroupRetailerCategoryData, repGroupID) {
        return this.httpClient.post(this.addTokens(this.saveCompanyCategoriesByRepGroupIdUrl, { repGroupID }), repGroupRetailerCategoryData);
    }
    getRepGroupBrandsList(displayRandomBrandsOnLandingPage?) {

        let params;
        if (displayRandomBrandsOnLandingPage === true) {
            params = new HttpParams()
                .append('displayRandomBrandsOnLandingPage', displayRandomBrandsOnLandingPage);
        }
        const repGroupPublicAccessToken = this.auth.getRepGroupPublicAccessToken();
        // const repGroupPublicAccessToken = JSON.parse(this.domainData.eventsMTSettings) && JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken ?
        //  JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken : this.auth.getRepGroupPublicAccessTokenSettings();
        return this.httpClient.get(this.addTokens(this.getRepGroupBrands , { repGroupPublicAccessToken: repGroupPublicAccessToken }), {params: params});
    }
    getRepGroupCategoryGroupsList() {
        const repGroupPublicAccessToken = this.auth.getRepGroupPublicAccessToken();
        // const repGroupPublicAccessToken = JSON.parse(this.domainData.eventsMTSettings) && JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken ?
        //  JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken : this.auth.getRepGroupPublicAccessTokenSettings();
        return this.httpClient.get(this.addTokens(this.getRepGroupCategoryGroups, { repGroupPublicAccessToken: repGroupPublicAccessToken }), {});
    }
    getPublicItemDetails(id: any) {
        const repGroupPublicAccessToken = this.auth.getRepGroupPublicAccessToken();
        // const repGroupPublicAccessToken = JSON.parse(this.domainData.eventsMTSettings) && JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken ?
        // JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken : this.auth.getRepGroupPublicAccessTokenSettings();
        return this.httpClient.get(this.addTokens(this.getPItemDetails, { repGroupPublicAccessToken, id }));
    }
    getCompanyCategoriesByRetailerID(repGroupID) {
        this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        return this.httpClient.get(this.addTokens(this.getCompanyCategoriesByRetailerIdUrl, { repGroupID, retailerID: this.retailerSelectedAddress.retailerID }));
    }

    getRepGroupID() {
        return this.domainData && this.domainData.repGroup && this.domainData.repGroup.recordID ? this.domainData.repGroup.recordID : '';
    }

    getLogoPath() {
        return this.addTokens(this.logoPath, { companyID: this.domainData.repGroup.recordID });
    }

    getPromoPath() {
        return this.addTokens(this.promoPath, { companyID: this.domainData.repGroup.recordID });
    }
    // [B2B-262] (SG 18-JUN-2020) Get Logo path and Upload company logo.
    getCompanyLogoPath() {
        this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        return this.addTokens(this.logoPath, { companyID: this.retailerSelectedAddress.retailerID });
    }

    uploadCompanyLogo(file) {
        this.retailerSelectedAddress = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
        const formData = new FormData();
        formData.append('logo-file', file);
        return this.httpClient.post(this.urlTools.addTokens(this.uploadCompanyLogoURL, { companyID: this.retailerSelectedAddress.retailerID }), formData);
    }
    // End

    // Request Demo API
    sendContactUsEmail(data) {
        console.log('data', data);
        return this.httpClient.post<any>(this.sendContactUsEmailURL, data);
    }

    getWhatsTrendingB2B(repGroupPublicAccessToken: any, entityIdParam?) {
        return this.httpClient.get(this.addTokens(this.getWhatsTrendingB2BUrl, { repGroupPublicAccessToken }) + entityIdParam);
    }
    getMarkethighlightB2B(repGroupPublicAccessToken: any) {
        return this.httpClient.get(this.addTokens(this.getMarkethighlightB2BUrl, { repGroupPublicAccessToken }));
    }

    defaultBusinessDocumentType() {
        return this.httpClient.get(this.addTokens(this.defaultBusinessDocumentTypeURL, {}));
    }

    saveBusinessProofDocumentUpload(requestPayload: any, data) {
        return this.httpClient.post(this.urlTools.addTokens(this.businessProofDocumentUploadURL, { buyertokenid: data }), requestPayload);
    }

    getSelectedManufacturersPublic(manufacturerID) {
        const repGroupPublicAccessToken = this.auth.getRepGroupPublicAccessToken();
        // const repGroupPublicAccessToken = JSON.parse(this.domainData.eventsMTSettings) && JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken ?
        //  JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken : this.auth.getRepGroupPublicAccessTokenSettings();
        return this.httpClient.get(this.addTokens(this.getSelectedManufacturersURL, { repGroupPublicAccessToken: repGroupPublicAccessToken, manufacturerID: manufacturerID }), {});
    }
    getRepgroupCategoriesListPublic(manufacturerID) {
        const repGroupPublicAccessToken = this.auth.getRepGroupPublicAccessToken();
        // const repGroupPublicAccessToken = JSON.parse(this.domainData.eventsMTSettings) && JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken ?
        //  JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken : this.auth.getRepGroupPublicAccessTokenSettings();
        let url = this.addTokens(this.getCategoriesListURL, { repGroupPublicAccessToken: repGroupPublicAccessToken });
        const params: any = new URLSearchParams();
        if (manufacturerID) {
            params.set('manufacturerID', manufacturerID);
        }
        url = url + (params ? '?' + params.toString() : '' );
        return this.httpClient.get(url, {});
    }

    getTopTeaserORPreviewItems(payload: any, queryStringParam: any, isIframeWindow: any) {
        // payload.retailerID = this.rtSharedService.getRetailerID();
        // payload.allowParentItemShopping = this.auth.getParentItemShoppingFlag();
        // payload.filterByTerritory = this.auth.getRetailerTerritoryFilterFlag();
        payload.retailerID = '';
        payload.allowParentItemShopping = '';
        payload.filterByTerritory = '';
        if (!isIframeWindow) {
            payload.showTeaser = true;
        }
        const params: any = new URLSearchParams();
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
        }
        const repGroupPublicAccessToken = this.auth.getRepGroupPublicAccessToken();
        // const repGroupPublicAccessToken = JSON.parse(this.domainData.eventsMTSettings) && JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken ?
        // JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken : this.auth.getRepGroupPublicAccessTokenSettings();
        return this.httpClient.post(this.urlTools.addTokens(payload.mfrPreview ? this.mfrPreviewURL + '?' + params.toString() :
                this.teaserURL + '?' + params.toString(), { repGroupPublicAccessToken: repGroupPublicAccessToken }), payload);
        //return this.httpClient.post(this.urlTools.addTokens(this.teaserURL + '?' + params.toString(), { manufacturerID: payload.manufacturerIDSet[0],repGroupPublicAccessToken: repGroupPublicAccessToken }), payload);
    }
    getTopTeaserRandomItems(queryStringParam?: any) {
        const params: any = new URLSearchParams();
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
        }
        const repGroupPublicAccessToken = this.auth.getRepGroupPublicAccessToken();
        // const repGroupPublicAccessToken = JSON.parse(this.domainData.eventsMTSettings) && JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken ?
        // JSON.parse(this.domainData.eventsMTSettings).repGroupPublicAccessToken : this.auth.getRepGroupPublicAccessTokenSettings();
        return this.httpClient.get(this.addTokens(this.teaserRandom + (params.toString() ? '?' + params.toString() : ''),
         { repGroupPublicAccessToken: repGroupPublicAccessToken }));
    }
    public downloadImages(ImageList: any, repGroupID, filename, size) {
        let query = '';
        if (size !== 'originalsize') {
            query = '?size=' + size;
        }
        return this.httpClient.post(this.urlTools.addTokens(this.downloadImagesUrl + query, { repGroupID: repGroupID }), ImageList, { responseType: 'blob' as 'blob' }).pipe(
            map(res => {
                return {
                    filename: this.addTokens(this.imagesFilename, { companyName: filename }),
                    data: res
                };
            }));
    }

    checkUndefinedNullEmpty(value) {
        return this.auth.checkUndefinedNullEmpty(value);
    }

    checkUndefinedNull(value) {
        return this.auth.checkUndefinedNull(value);
    }
}
