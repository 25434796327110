// SETTING DEFAULT VALUES TO MKT Staging
let apiUrl = 'https://api.markettimestg.com/';
let bucket = 'https://s3.us-east-2.amazonaws.com/';
let countryAbrv = 'US';
let domainExt = window.location.host.split('.').pop();
const vaultURL = 'https://vault02.auricsystems.com/corp/reptime/v10/';

const marketTimeURL = 'https://markettime.com/';
const eventURL = 'https://lightovation.b2baccess.com/';
const portalURL = 'https://reptime.com/';
const appsPortalURL = 'https://apps.markettime.com/';
const b2bAccessURL = 'https://markettime.com/';
const shopURL = 'https://shop.markettime.com/';
const mtPayJS = 'https://checkout.getbalance.com/blnceSDK.js';
const mtPayCheckout = 'https://checkout.getbalance.com/checkout.html';

let rtDateFormat = 'MM-dd-yyyy'; // Available Format : MM-dd-yyyy || yyyy-dd-MM || dd-MM-yyyy || dd/MM/yyyy
let currencyDecimalPoint = 2;
let currencyFormat = 'X,XXX,XXX.XX'; // Available Formats : X,XXX,XXX.XX | X XXX XXX,XX | X.XXX.XXX,XX
let currencySymbol = '$';
let rtDateCalender = 'mm/dd/yy'; // Available Format : mm/dd/yy || yy/dd/MM || dd/mm/yy
let onlyDateMonthGlobal = 'MM-DD'; // DD-MM || MM-DD

let appBucketsURL = {
    logoBucketUrl: bucket + 'reptime-prod-logos/',
    magazineComingUpImagesFolder: 'coming-images',
    magazineSliderImagesFolder: 'magazine-images',
    mfgDocBucketUrl: bucket + 'reptime-prod-mfg-docs/',
    magazineBucket: bucket + 'markettime-magazine-prod/',
    imageBucketUrl: bucket + 'reptime-prod-item-images/',
    documetFolderImage: bucket + 'reptime-prod-mfg-docs/',
    coverImgBucketUrl: bucket + 'reptime-prod-cover-images/',
    image360BucketUrl: bucket + 'reptime-prod-item-images/',
    // documentBucketUrl: bucket + 'markettime-prod-support-docs/',
    eventLandingImgUrl: bucket + 'markettime-prod-events-landing-page/',
    // tradeshowBucketUrl: bucket + 'markettime-prod-tradeshow/tradeshow/',
    imageCategoryGroupUrl: bucket + 'reptime-prod-categorygroup-images/',
    // labalLanguageURL: bucket + 'markettime-prod-public/masterlable.json',
    marketTimeLandingBucketUrl: bucket + 'markettime-prod-events-landing-page/',
    integrationServiceImg: bucket + 'markettime-prod-public/IntegrationService/',
};

// LOCALHOST CONNECTS TO MKT Staging
if (window.location.host.includes('localhost')) {
    domainExt = 'com';
} else {
    // BITM PROD CONFIG
    if (domainExt === 'be') {
        apiUrl = 'https://api.markettimestg.be/';
        bucket = 'https://s3.us-east-2.amazonaws.com/';
        countryAbrv = 'BE';

        rtDateFormat = 'dd/MM/yyyy'; // Available Format : MM-dd-yyyy || yyyy-dd-MM || dd-MM-yyyy || dd/MM/yyyy
        currencyDecimalPoint = 2;
        currencyFormat = 'X XXX XXX,XX'; // Available Formats : X,XXX,XXX.XX | X XXX XXX,XX | X.XXX.XXX,XX
        currencySymbol = '€';
        rtDateCalender = 'dd/mm/yy'; // Available Format : mm/dd/yy || yy/dd/MM || dd/mm/yy
        onlyDateMonthGlobal = 'DD-MM'; // DD-MM || MM-DD

        appBucketsURL = {
            logoBucketUrl: bucket + 'reptime-be-qa-logos/',
            magazineComingUpImagesFolder: 'coming-images',
            magazineSliderImagesFolder: 'magazine-images',
            mfgDocBucketUrl: bucket + 'reptime-be-qa-mfg-docs/',
            magazineBucket: bucket + 'markettime-be-magazine-qa/',
            imageBucketUrl: bucket + 'reptime-be-qa-item-images/',
            documetFolderImage: bucket + 'reptime-be-qa-mfg-docs/',
            coverImgBucketUrl: bucket + 'reptime-be-qa-cover-images/',
            image360BucketUrl: bucket + 'reptime-be-qa-item-images/',
            // documentBucketUrl: bucket + 'markettime-be-qa-support-docs/',
            eventLandingImgUrl: bucket + 'markettime-be-qa-events-landing-page/',
            // tradeshowBucketUrl: bucket + 'markettime-be-qa-tradeshow/tradeshow/',
            imageCategoryGroupUrl: bucket + 'reptime-be-qa-categorygroup-images/',
            // labalLanguageURL: bucket + 'markettime-be-qa-public/masterlable.json',
            marketTimeLandingBucketUrl: bucket + 'markettime-be-qa-events-landing-page/',
            integrationServiceImg: bucket + 'markettime-be-qa-public/IntegrationService/',
        };
    }
}

export const environment = {
    apiUrl, countryAbrv, eventURL, portalURL, marketTimeURL,
    bucketUrl: bucket, appsPortalURL, b2bAccessURL, currencyFormat, shopURL,
    currencySymbol, rtDateCalender, onlyDateMonthGlobal, rtDateFormat, currencyDecimalPoint,
    mtPayJS, mtPayCheckout,
    production: true,
    vaultURL: vaultURL + 'auricenc.html',
    ...appBucketsURL
};
