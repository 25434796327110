import { CalloutApiService } from 'src/app/services/api/callout-api/callout-api.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConfirmationService, DialogService, MessageService } from 'primeng/api';

import { RetailerService } from '../../../services/middleware/retailer/retailer.service';
import { AuthService } from '../../../services/middleware/auth/auth.service';
import { OrderService } from '../../../services/middleware/order/order.service';
import { EditBillToDialogComponent } from '../../../modals/edit-bill-to-dialog/edit-bill-to-dialog.component';
import { EditShipToDialogComponent } from '../../../modals/edit-ship-to-dialog/edit-ship-to-dialog.component';
import { RepgroupCalloutList } from '../../../models/repgroup-callouts-list';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import { environment } from '../../../../environments/environment';
import { SalespersonService } from 'src/app/services/api/salesperson.service';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import { RetailerShipToLocationTableComponent } from 'src/app/components/common/retailer-ship-to-location-table/retailer-ship-to-location-table.component';
import { Order } from 'src/app/models/order';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { APIResponse } from '../../../models/api-response';
import { ManufacturerService } from 'src/app/services/api/manufacturer.service';
import { BasketItemService } from 'src/app/services/middleware/basket-item/basket-item.service';
import { IntegrationService } from '../../../services/api/integration.service';
import { OverlayPanel } from 'primeng/primeng';
@Component({
    selector: 'app-my-orders',
    templateUrl: './my-orders.component.html',
    styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit, OnDestroy {

    public domainData: any = (<any>window).domainData;
    public retailerObj: any;
    public rShipToObj: any;
    public loggedInUser: any;
    public parentRetailerID: any;
    public retailerOrders: any[];
    public retailerUnfinishedOrders: any[];
    public total: any = 0;
    public calloutData: any;
    public loading: any = true;
    public tableEvent: any = { rows: 20, first: 0 };
    public cols: any[];
    public orderTrackingCols: any[];
    public salesperson = [];
    public bucketUrl = environment.bucketUrl;
    public integrationServiceImg = environment.integrationServiceImg;
    containerRetailer = true; // B2B-57 (MG 30-JAN-2020): added loader
    showBillLocationDialog: boolean;
    showShipLocationDialog: boolean;
    showsalepersonDialog: boolean;
    showEmailDialogs: boolean;
    // B2B-119 (MJ 03-03-2020 ) Added for Implement buyer to salesperson email functionality
    salespersonEmail: any;
    emailBody = '';
    retailerID: any;
    salesRepID: any;
    errorMsg = false;
    public disableBtn = false;
    // END
    manufacturerOrderStatuses = Order.MANUFACTURER_DROPDOWN_STATUSES;
    repGroupOrderStatuses = Order.REPGROUP_DROPDOWN_STATUSES;
    public saveForShowAddress: Subscription;
    public getRetailerDataSubscription: Subscription;

    // [B2B-127] (MG 15-APR-2020) modified to duplicate order
    displayOrderDuplicate = false;
    selectedOrderToDuplicate: any;
    selectedOrderToDuplicatePoNumber = '';
    saveLocationId: any;
    public salesPersonLoading = false;

    orderDuplicateForm: FormGroup;
    newOrderDate = new Date();
    newShipDate = new Date();
    newCancelDate = new Date();
    isValidateDuplicate = false;
    isSavingData = false;
    orderDateBlank: any;
    shipDateBlank: any;
    cancelDateBlank: any;
    entityType: any;
    public calloutDataLoader: any = false;
    projectType: any;
    orderTrackingDialog = false;
    orderTrackingData: any;
    totalTrackingOrder = 0;
    public orderTrackingLoading: any = true;

    private readonly onDestroy = new Subject<void>();
    private readonly onDestroyIntegrationService = new Subject<void>();
    private readonly onDestroyCompanyIntegration = new Subject<void>();
    private readonly onDestroygetBuyerDetails = new Subject<void>();
    private readonly onDestroygetShipmentDetails = new Subject<void>();
    showWidget = false;
    buyerQickReport: any;
    buyerNameID: any;
    showGetBalancePopup = false;
    getIntegrationData: any;
    getUserIntegrationData: any;
    getBuyerDetailsData: any;
    shippingNotes: any;
    shippingSpinner: boolean;
    shippingCols: any [];
    shippingValue: any = [];
    iconLoadStates = {};
    activerowdata: any;
    isOpenOverlay: boolean;
    @ViewChild('shippingNotesOverlay', { static: false }) shippingNotesOverlay: OverlayPanel;
    selectedShippingRow: any;
    mtPayAvilableForRetailer = false;
    constructor(
        public dialogService: DialogService,
        private msgService: MessageService,
        public dp: DatePipe,
        private retailerService: RetailerService,
        private integrationService: IntegrationService,
        private orderService: OrderService,
        private calloutApiService: CalloutApiService,
        public rtSharedService: RTSharedService,
        private salesPersonService: SalespersonService,
        private auth: AuthService,
        private router: Router,
        private fb: FormBuilder,
        private confirmationService: ConfirmationService,
        private manufacturerService: ManufacturerService,
        private basketItemService: BasketItemService,
        route: ActivatedRoute
    ) {
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.entityType = route.root.firstChild.snapshot.data.entityType;
            console.log('sss', this.entityType)
        });
    }
    // B2B-57 (MG 30-JAN-2020): added loader
    ngOnInit() {
        this.projectType = this.auth.getProjectType();
        this.showWidget = this.auth.getShowWidgetsFlag();
        this.containerRetailer = true;
        this.cols = [
            { field: 'repGroupManufacturerName', header: 'Manufacturer', spinner: false },
            { field: 'poNumber', header: 'PO #', sortField: 'pONumber', spinner: true },
            { field: 'manufacturerOrderStatus', header: 'Status', sortField: 'manufacturerOrderStatus', spinner: true },
            { field: 'orderCode', header: 'Code', sortField: 'orderCode', spinner: true },
            { field: 'salesperson1.name', header: 'Salesperson', sortField: 'salesperson1.name', spinner: true },
            { field: 'orderDate', header: 'OrderDate', sortField: 'orderDate', spinner: true },
            { field: 'requestDate', header: 'ShipDate', sortField: 'requestDate', spinner: true },
            { field: 'paymentTerm', header: 'Term', sortField: 'paymentTerm', spinner: true },
            { field: 'orderTotal', header: 'Total', sortField: 'orderTotal', spinner: true }];
        this.shippingCols = [
            { field: 'invoiceNumber', header: 'Invoice #', sortField: 'invoiceNumber', spinner: true, class: 'center', width: '140px'},
            { field: 'invoiceAmount', header: 'Inv Amount', sortField: 'invoiceAmount', spinner: true, class: 'price center', width: '140px'},
            { field: 'estimatedShippingCost', header: 'Shipping Cost', sortField: 'estimatedShippingCost', spinner: true, class: 'price center', width: '140px'},
            { field: 'estimatedHandlingCost', header: 'Handling Cost', sortField: 'estimatedHandlingCost', spinner: true, class: 'price center', width: '140px'},
            { field: 'estimatedMiscellaneousCost', header: 'Misc Cost', sortField: 'estimatedMiscellaneousCost', spinner: true, class: 'price center', width: '140px'},
            { field: 'sentTrackingNumber', header: 'Track #', sortField: 'sentTrackingNumber', spinner: true, class: 'center', width: '140px'},
            { field: 'trackinglink', header: 'Tracking Link', sortField: 'trackinglink', spinner: true, class: 'center', width: '140px'},
            { field: 'shipDate', header: 'Ship Date', sortField: 'shipDate', spinner: true, class: 'price center', width: '140px'},
            { field: 'shippingMethodForTracking', header: 'Ship Method', sortField: 'shippingMethodForTracking', spinner: true, class: 'center', width: '140px'},
            { field: 'status', header: 'Shipping Status', sortField: 'status', spinner: true, class: 'center', width: '140px'},
            { field: 'mtPayTransactionsChargeDate', header: 'Transaction Date', sortField: 'mtPayTransactionsChargeDate', spinner: true, class: 'center', width: '140px' }
        ];
        this.auth.getAuthRetailer.subscribe((userInfo: any) => {
            if (userInfo) {
                this.loggedInUser = userInfo.child ? userInfo.child : userInfo.parent;
                this.parentRetailerID = userInfo.parent && userInfo.parent.entityId;
            } else {
                this.loggedInUser = null;
            }
        });
        // B2B-286 (MK 04-07-2020 )subscribe data
        this.getRetailerDataSubscription = this.auth.getRetailerData.subscribe(async (getRetailerRes) => {
            this.retailerObj = getRetailerRes;
            this.getRepGroupRetailers();
            this.getRetailer();
        });
        if (this.showWidget) {
            this.getRetailerQickReport();
        }
        this.retailerID = this.rtSharedService.getRetailerID();
        this.rtSharedService.setRepgroupOrderStatus(false);
        this.createDuplicateOrderForm();
        this.getCartData(this.retailerObj);
        this.getCalloutsData();
        this.getIntegrationServices();
        // this.showGetBalancePopup = true;
    }

    getCalloutsData() {
        const payloadObj: any = {
            repGroupID: this.domainData.repGroup.recordID,
        };
        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
            try {
                payloadObj.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
            } catch (e) { }
        }
        this.calloutDataLoader = true;
        this.calloutApiService.getCalloutsData(payloadObj).then((res: RepgroupCalloutList) => {
            if (res.success) {
                this.calloutData = res.data;

            } else {
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: objectPath.get(res, 'error.message', 'Error occurred during services request')
                });
            }
            this.calloutDataLoader = false;
        }, (err) => {
            this.calloutDataLoader = false;
            this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
        });
    }

    ngOnDestroy() {
        if (this.saveForShowAddress !== undefined) {
            this.saveForShowAddress.unsubscribe();
        }
        this.getRetailerDataSubscription.unsubscribe();
        this.onDestroy.next();
        this.onDestroyIntegrationService.next();
        this.onDestroyCompanyIntegration.next();
        this.onDestroygetBuyerDetails.next();
        this.onDestroygetShipmentDetails.next();
    }
    // B2B-51 (MG 28-JAN-2020): modified to filter MFR
    // B2B-57 (MG 30-JAN-2020): added loader
    async getRetailer() {
        if (this.loggedInUser && this.loggedInUser.entityId) {
            // B2B-286 (MK 04-07-2020)remove api call
            console.log('111');
            if (this.retailerObj && this.retailerObj.shipToLocations && this.retailerObj.shipToLocations.length > 0) {
                console.log('151');
                // B2B-148 (MJ 12-03-2020 condition Added) for Ability to switch shipping location from dashboard
                this.rShipToObj = this.rtSharedService.getSelectedShipTo();
                if (this.router.url === '/home') {
                    if (JSON.parse(sessionStorage.getItem('retailerShipToLocationID'))) {
                        const payload: any = {
                            repGroupID: this.rtSharedService.getRepGroupID(),
                        };
                        if (this.auth.getRetailerTerritoryFilterFlag() === true) {
                            payload.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
                        }
                        this.getCalloutsData();
                    }
                }
                this.containerRetailer = false;
                this.salesPersonLoading = true;
                this.salesperson = await this.getSalesPersonOfShipTo(this.retailerObj, this.rShipToObj);
                this.salesPersonLoading = false;
            } else if (this.retailerObj.shipToLocations.length === 0) {
                console.log('home lenght 0');

            }
            // this.containerRetailer = false;
        }
        this.containerRetailer = false;
    }
    async loadOrdersLazy(e: any) {
        this.tableEvent = e;
        const size = e.rows ? e.rows : 25;
        const offset = e.first ? e.first : 0;
        const sort = e.sortField ? e.sortField : 'orderDate';
        const sortOrder = e.sortOrder ? e.sortOrder : -1;
        if (this.loggedInUser && this.loggedInUser.entityType && this.parentRetailerID) {
            const rg: any = this.domainData.repGroup;
            const qp: any = { size, offset, sort, sortOrder };
            const orderPayload: any = {};
            orderPayload.retailerID = this.parentRetailerID;
            orderPayload.userCompanyType = this.loggedInUser.entityType;
            orderPayload.searchType = 'startswith';
            orderPayload.repGroupID = rg && rg.recordID ? rg.recordID : '';
            orderPayload.repGroupOrderStatuses = (Order.REPGROUP_DROPDOWN_STATUSES.map(a => a.value)).filter(o => o !== 'CANCELLED'); // MG RC-1768
            if (this.auth.getRetailerTerritoryFilterFlag() === true) {
                orderPayload.retailerShipToLocationID = (JSON.parse(sessionStorage.getItem('retailerShipToLocationID'))) ?
                    JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID : null;
            }
            try {
                this.loading = true;
                const roRes: any = await this.orderService.getRetailerOrdersData(qp, orderPayload);
                this.retailerOrders = roRes.data ? roRes.data.orders : [];
                _.forEach(this.retailerOrders, (res: any, index: any) => {
                    this.retailerOrders[index]['mtPayOrderPaymentDetailsJson'] = _.cloneDeep(this.getmtPayPaymentDetails(res));
                });
                console.log('Rahul....this.retailerOrders', this.retailerOrders);
                this.retailerUnfinishedOrders = roRes.data.totalB2BIncompleteOrders;
                this.total = roRes.total ? roRes.total : 0;
                this.loading = false;
            } catch (e) {
                this.loading = false;
            }
        }
    }
    getmtPayPaymentDetails(order: any) {
        if (order && order.mtPayOrderPaymentDetailsString) {
            const x = JSON.parse(order.mtPayOrderPaymentDetailsString);
            if (x  && _.isNumber(x['paymentTerms'])) {
                x['paymentTerms'] = x['paymentTerms'].toString();
                return x;
            } else {
                return x;
            }
        } else {
            return null;
        }
    }
    async getSalesPersonOfShipTo(retailer: any, rShipToObj: any) {
        const searchParams: any = {
            retailerID: retailer.recordID,
            repGroupID: this.domainData.repGroup.recordID,
            assigned: true,
            primary: false,
            secondary: false,
            key: false
        };
        // if (this.auth.getRetailerTerritoryFilterFlag() === true) {
        // (RK 14 MAY 2021) Fix selected shipto location SP show issue in my orders screen
        searchParams.retailerShipToLocationID = (JSON.parse(sessionStorage.getItem('retailerShipToLocationID'))) ?
            JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID : null;
        // }
        const res: any = await this.retailerService.getRetailerAssignmentsByRetailerID(searchParams);
        let salespersons = [];
        if (res && res.data) {
            if (res.data.retailers && res.data.retailers.length) {
                const retailer = res.data.retailers[0];
                if (retailer && retailer.shipToLocations.length) {
                    const shipToLocation: any = retailer.shipToLocations[0];
                    if (shipToLocation && shipToLocation.repGroupSalespersonRetailers
                        && shipToLocation.repGroupSalespersonRetailers.length) {
                        salespersons = shipToLocation.repGroupSalespersonRetailers.filter(o => !o.recordDeleted);
                    }
                }
            }
        }
        return salespersons;
    }

    removeInvalidChars(str) {
        // Remove characters that are invalid for file names
        return str.replace(/([^a-zA-Z0-9\-_]+)/gi, '');
    }
    isOrderEditableByRepGroup(rowData) {
        return this.repGroupOrderStatuses.findIndex(d => d.value === rowData.repGroupOrderStatus) < 6;
    }
    isOrderViewByRepGroup(rowData) {
        return this.repGroupOrderStatuses.findIndex(d => d.value === rowData.repGroupOrderStatus) >= 6;
    }

    // [B2B-67] (MG 25-FEB-2020) modified to fix download in firefox
    downloadFile(data, order, mimeType, ext) {
        const repGroupName = order['repGroup'] && order['repGroup']['name'] ? order['repGroup']['name'] : 'NoRepGroupName';
        const poNumber = order['poNumber'] ? order['poNumber'] : 'NoPoNumber';
        // const orderDate = order['orderDate'] ? this.dp.transform(order['orderDate'], 'MM-dd-yyyy') : 'NoOrderDate';
        let orderDate = 'NoOrderDate';
        if (order['orderDate']) {
            const [month, date, year] = order['orderDate'].split('-');
            const x = new Date(year, (+month - 1), date);
            orderDate = ('0' + (x.getMonth() + 1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + '-' + (x.getFullYear());
        }
        const filename = repGroupName + '_' + poNumber + '_' + orderDate;
        const url = window.URL.createObjectURL(new Blob([data], { type: mimeType }));

        // Directly download the file by adding a temporary link with blob file data
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.removeInvalidChars(filename) + ext;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
    }

    previewOrder(orderObj) {
        const order = this.retailerOrders.find(o => o.recordID === orderObj.recordID);
        console.groupCollapsed('Downloading PDF :', order);
        this.orderService.downloadOrder(order.recordID, 'pdf')
            .then(data => {
                console.groupEnd();
                this.downloadFile(data, order, 'application/pdf', '.pdf');
                // Update order status(es) in batch group if Manufacturer opens order
            }, () => {
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'An error occurred downloading order ' + order.recordID
                });
            });
    }

    createEditQueryParams(order) {
        // B2B-96 (MJ 25-FEB-2020): View Button in Orders
        // RC-1179 (MK 08 AUG 2020): single time api call
        let qParams: any = { orderIDs: order.recordID };
        if (order.orderDate && order.retailerShipTo) {
            qParams = { orderDate: order.orderDate.split('T')[0], retailerShipToLocationID: order.retailerShipTo.recordID, selectedID: order.recordID };
        }
        return qParams;
        // return {
        //     orderDate: order.orderDate && order.orderDate.split('T')[0],
        //     retailerShipToLocationID: order.retailerShipTo.recordID,
        //     selectedID: order.recordID,
        // };
    }

    openEditRetailerBillToLocationModal(retailer, e?: any) {
        const ref = this.dialogService.open(EditBillToDialogComponent, {
            header: 'Edit Bill To Location',
            width: '50%',
            height: 'auto',
            closable: true,
            styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
            data: {
                selectedRetailer: retailer
            }
        });

        ref.onClose.subscribe((billToLocation: any) => {
            if (billToLocation) {
                retailer = billToLocation;
            }
        });

        event.stopPropagation();
    }

    openAddEditRetailerShipToLocationModal(rShipToObj: any, type?: string, e?: any) {
        const data: any = {
            retailerID: this.retailerObj.recordID,
        };
        let header = 'Add New Ship To Location';
        if (type === 'EDIT') {
            data.selectedShipToLocation = rShipToObj;
            header = 'Edit Ship To Location';
        }
        const ref = this.dialogService.open(EditShipToDialogComponent, {
            header,
            width: '50%',
            height: 'auto',
            styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
            closable: true,
            data
        });

        ref.onClose.subscribe((updateData: any) => {
            if (updateData && !updateData.isOrderOnly) {
                this.rShipToObj = updateData.data;
            }
        });

        // tslint:disable-next-line: deprecation
        event.stopPropagation();
    }

    showBillLocation() {
        this.showBillLocationDialog = true;
    }
    showShipLocation() {
        this.showShipLocationDialog = true;
    }
    showSalesperson() {
        this.showsalepersonDialog = true;
    }
    showEmailDialog(sp) {
        this.emailBody = '';
        this.salespersonEmail = sp.salesperson.email;
        this.salesRepID = sp.salesperson.recordID;
        this.showEmailDialogs = true;
    }
    // B2B-119 (MJ 03-03-2020 ) Added for Implement buyer to salesperson email functionality
    async reachOut() {
        if (this.emailBody.length > 0) {
            this.disableBtn = true;
            const searchParams = {
                email: this.salespersonEmail,
                message: this.emailBody
            };
            await this.salesPersonService.mailToSalesperson(this.retailerID, this.salesRepID, searchParams).subscribe(
                data => {
                    this.msgService.add({
                        severity: 'info',
                        summary: 'Email sent successfully',
                    });
                    this.showEmailDialogs = false;
                    this.disableBtn = false;
                },
                error => {
                    this.msgService.add({
                        severity: 'error', summary: 'Error',
                        detail: objectPath.get(error, 'message', 'Error occurred during services request')
                    });
                }
            );

        } else {
            this.errorMsg = true;
        }
    }
    checkMessage(emailBody) {
        if (emailBody.length > 0) {
            this.errorMsg = false;
        }
    }
    // END
    // B2B-148 (MJ 12-03-2020 Added) for Ability to switch shipping location from dashboard
    openChangeShipToModal() {
        console.log('rShipToObj', this.rShipToObj);
        const ref = this.dialogService.open(RetailerShipToLocationTableComponent, {
            header: 'Change Ship To Location',
            width: '97%',
            height: 'auto',
            styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height',
            data: {
                retailerID: this.retailerObj.recordID,
                selectedShipTo: this.rShipToObj
            }
        });
        ref.onClose.subscribe((shipTo: any) => {
            if (shipTo) {
                this.rShipToObj = shipTo;
                this.rtSharedService.setSelectedShipTo(this.rShipToObj);
                this.getRetailer().then(() => {
                });
                this.getCartData(this.retailerObj);
            }
        });
    }
    // END
    createDuplicateOrderForm() {
        // Create company-info-form form using FormBuilder
        this.orderDuplicateForm = this.fb.group({
            orderDateD: ['', [Validators.required]],
            shipDateD: ['', [Validators.required]],
            cancelDateD: ['', []],
            selectedOrderToDuplicatePoNumber: ['', [Validators.required]]
        });
    }
    // [B2B-127] (MG 15-APR-2020) modified to duplicate order
    initiateOrderDuplicate(rowData) {
        this.selectedOrderToDuplicate = _.cloneDeep(rowData);
        console.log('initiateOrderDuplicate', this.selectedOrderToDuplicate);
        this.selectedOrderToDuplicate['orderDetails'].forEach(orderInner => {
            orderInner.total = orderInner.unitPrice * orderInner.quantity * (1 - orderInner.itemDiscount / 100);
        });
        this.selectedOrderToDuplicate['orderGrossTotal'] = this.countOrderTotalFromItems(this.selectedOrderToDuplicate['orderDetails']);
        this.selectedOrderToDuplicate.poNumber = this.selectedOrderToDuplicate.poNumber + '-D';
        this.selectedOrderToDuplicate.accountNoDeactive = false;
        this.selectedOrderToDuplicatePoNumber = this.selectedOrderToDuplicate.poNumber;
        this.selectedOrderToDuplicate.shipToID = this.selectedOrderToDuplicate.retailerShipTo.recordID;
        this.displayOrderDuplicate = true;
        // this.resetForm();
        // tslint:disable-next-line: max-line-length
        // this.orderDuplicateForm.patchValue({
        //     orderDateD: this.selectedOrderToDuplicate.orderDate ?
        //         this.rtSharedService.getStringtToDate(this.selectedOrderToDuplicate.orderDate) : new Date()
        // });
        this.orderDuplicateForm.patchValue({ orderDateD: new Date() });
        // tslint:disable-next-line: max-line-length
        // this.orderDuplicateForm.patchValue({
        //     shipDateD: this.selectedOrderToDuplicate.requestDate ?
        //         this.rtSharedService.getStringtToDate(this.selectedOrderToDuplicate.requestDate) : new Date()
        // });
        this.orderDuplicateForm.patchValue({ shipDateD: new Date() });
        this.orderDuplicateForm.patchValue({
            cancelDateD: this.selectedOrderToDuplicate.cancelDate ?
                this.rtSharedService.getStringtToDate(this.selectedOrderToDuplicate.cancelDate) : ''
        });
        this.orderDuplicateForm.patchValue({ selectedOrderToDuplicatePoNumber: this.selectedOrderToDuplicatePoNumber });
        this.isValidateDulicate();
    }
    // RC-1233 (MK 28-SEPT-2020): check po number
    saveDuplicateOrderCheckPO() {
        this.isSavingData = true;
        this.manufacturerService.CheckValidpPoNumber(this.selectedOrderToDuplicate.manufacturer.recordID,
            this.orderDuplicateForm.value.selectedOrderToDuplicatePoNumber).subscribe((res) => {
                if (res === true) {
                    this.confirmationService.confirm({
                        message: 'PO number already exists.<br>Are you sure you want to duplicate order?',
                        header: 'Duplicate Order',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Yes',
                        rejectLabel: 'No',
                        accept: async () => {
                            this.saveDuplicateOrder();
                        },
                        reject: () => {
                            this.isSavingData = false;
                        },
                        key: 'duplicate'
                    });
                } else {
                    this.saveDuplicateOrder();
                }
                console.log(res);
            }, error => {
                this.isSavingData = false;
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Failed To Check PO Number' });
            });
    }
    // [B2B-127] (SG 05-AUG-2020) modified to duplicate order
    saveDuplicateOrder() {
        this.isSavingData = true;
        const orderID = this.selectedOrderToDuplicate['recordID'];
        const requestPayload = {
            orderDate: this.formatDate(this.orderDuplicateForm.value.orderDateD),
            shipDate: this.formatDate(this.orderDuplicateForm.value.shipDateD),
            cancelDate: this.formatDate(this.orderDuplicateForm.value.cancelDateD),
            retailerID: this.rtSharedService.getRetailerID(),
            retailerShipToID: this.selectedOrderToDuplicate.shipToID,
            poNumber: this.orderDuplicateForm.value.selectedOrderToDuplicatePoNumber
        };
        console.log(requestPayload)
        this.orderService.saveOrderDuplicate(orderID, requestPayload).subscribe(async (response: APIResponse) => {
            if (await response.success) {
                this.isSavingData = false;
                this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Order has been Duplicate Successfully.', life: 5000 });
                this.router.navigate(['/finalize-order'], { queryParams: this.createEditQueryParams(response.data) });
                this.resetForm();
            } else {
                this.isSavingData = false;
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
            }
        }, error => {
            this.isSavingData = false;
            if (error.error.error.status === 400) {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
            } else {
                this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
            }
        });
    }

    updateChangesMade($event?: any, fieldName?: string) {
        if (fieldName === 'orderDateD') {
            if ($event > this.orderDuplicateForm.value.shipDateD) {
                this.orderDuplicateForm.patchValue({ orderDateD: $event, shipDateD: $event });
            } else {
                this.orderDuplicateForm.patchValue({ orderDateD: $event });
            }
            if (this.orderDuplicateForm.value.cancelDateD && this.orderDuplicateForm.value.shipDateD > this.orderDuplicateForm.value.cancelDateD) {
                this.orderDuplicateForm.patchValue({ shipDateD: this.orderDuplicateForm.value.shipDateD, cancelDateD: this.orderDuplicateForm.value.shipDateD });
            }
        } else if (fieldName === 'shipDateD') {
            if (this.orderDuplicateForm.value.cancelDateD && $event > this.orderDuplicateForm.value.cancelDateD) {
                this.orderDuplicateForm.patchValue({ shipDateD: $event, cancelDateD: $event });
            } else {
                this.orderDuplicateForm.patchValue({ shipDateD: $event });
            }
        } else if (fieldName === 'cancelDateD') {
            this.orderDuplicateForm.patchValue({ cancelDateD: $event });
        }
        this.isValidateDulicate();
    }

    private formatDate(dateValue) {
        if (dateValue !== '' && dateValue !== null) {
            return moment(dateValue).format('MM-DD-YYYY');
        }
        return '';
    }

    private resetForm() {
        this.orderDuplicateForm.reset();
        this.orderDuplicateForm.patchValue({ orderDateD: '' });
        this.orderDuplicateForm.patchValue({ shipDateD: '' });
        this.orderDuplicateForm.patchValue({ cancelDateD: '' });
    }

    isValidateDulicate() {
        if (this.orderDuplicateForm.status === 'VALID') {
            this.isValidateDuplicate = true;
        } else {
            this.isValidateDuplicate = false;
        }
    }
    // [B2B-127] (MG 15-APR-2020) modified to duplicate order
    countOrderTotalFromItems(orderDetails) {
        console.log('orderDetails', orderDetails);
        return _.sumBy(orderDetails, (o: any) => {
            return (!o.recordDeleted) ? o.total : 0;
        });
    }

    async getCartData(customer) {
        customer.selectedShipTo = this.rShipToObj;
        const cartData = await this.basketItemService.getBasketItemData(customer);
        if (cartData && cartData.totalItems) {
            this.auth.setCartItemCount(cartData.totalItems);
        }
    }

    orderTracking(data) {
        this.totalTrackingOrder = 0;
        this.orderTrackingData = data;
        this.orderTrackingDialog = true;
    }

    isOpenChange() {
        this.orderTrackingDialog = false;

    }

    getRetailerQickReport() {
        this.onDestroy.next();
        this.salesPersonLoading = true;
        const repGroupID = this.domainData.repGroup.recordID;
        const retailerID = this.loggedInUser.entityId;
        console.log('qick report repGroupID', repGroupID);
        console.log('qick report retailerId', this.loggedInUser.entityId);
        this.retailerService.getRetailerQickReport([retailerID], repGroupID).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
            if (response && response.success) {
                this.buyerQickReport = response.data[0];
                this.buyerNameID = this.buyerQickReport.name + ' - ' + this.buyerQickReport.retailerID;
                this.salesPersonLoading = false;
            }
        }, (error) => {
            this.salesPersonLoading = false;
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
        });
    }
    eventSkipDialog($event) {
        this.showGetBalancePopup = false;
    }
    loadBackgroundServicesOnSuccess($event) {

    }
    openIntegrationDailog(data) {
        if (data.toLowerCase() === 'mtpay' && this.mtPayAvilableForRetailer) {
            this.getCompanyIntegration(_.toUpper(data)); // Temporary remove mtPay reg flow
            /* sessionStorage.setItem('openMtPayFromDD', 'true');
            this.router.navigateByUrl('/manage-company'); */
        }
    }

    getIntegrationServices() {
        this.onDestroyIntegrationService.next();
        this.integrationService.getIntegrationServices().pipe(takeUntil(this.onDestroyIntegrationService)).subscribe((response: any) => {
            if (response && response.success) {
                this.getIntegrationData = response.data;
                this.getIntegrationData =   _.filter(this.getIntegrationData, (data, index) => {
                    return data.showInRetailer;
                 });
            }
        }, (error) => {
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
        });
    }

    getCompanyIntegration(data) {
        this.onDestroyCompanyIntegration.next();
        this.integrationService.getCompanyIntegration(data, this.auth.getCompanyID()).pipe(takeUntil(this.onDestroyCompanyIntegration)).subscribe((response: any) => {
            if (response && response.success) {
                this.getUserIntegrationData = response.data[0];
            }
            if (this.getUserIntegrationData) {
                sessionStorage.setItem('openMtPayFromDD', 'true');
                this.router.navigateByUrl('/manage-company');
            }
            console.log('DSP720', this.getUserIntegrationData );

            if (!this.getUserIntegrationData) {
                this.getBuyerDetails();
                this.showGetBalancePopup = true;
            }
            if (this.getUserIntegrationData && this.getUserIntegrationData.isApproved) {
                this.eventSkipDialog(this.getUserIntegrationData.isApproved);
            }
        }, (error) => {
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
        });
    }

    getBuyerDetails() {
        this.onDestroygetBuyerDetails.next();
        this.integrationService.getBuyerDetails(this.auth.getCompanyID()).pipe(takeUntil(this.onDestroygetBuyerDetails)).subscribe((response: any) => {
            if (response && response.success) {
                this.getBuyerDetailsData = response.data;
            }
            console.log('DSP727', this.getBuyerDetailsData);
        }, (error) => {
            this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
        });
    }
    openShippingOP($event, rowdata) {
        this.shippingSpinner = true;
        this.activerowdata = rowdata;
        this.onDestroygetShipmentDetails.next();
        const repGroupID = this.rtSharedService.getRepGroupID();
        this.integrationService.getShipmentDetail(repGroupID, rowdata.recordID).pipe(takeUntil(this.onDestroygetBuyerDetails)).subscribe((response: any) => {
            this.shippingSpinner = false;
            if (response && response.success) {
                this.shippingValue = response.data.map((el) => {
                    if (el && el.mtPayTransactions && el.mtPayTransactions.length) {
                        el.mtPayTransactionsChargeDate = el.mtPayTransactions[0]['chargeDate'];
                    }
                    const o = Object.assign({}, el);
                    o.payPath = window.location.origin + '/mtpay/' + o.paymentApprovalLinkToken;
                    return o;
                  });
                // this.shippingValue = response.data;
            }
        }, (error) => {
            this.shippingSpinner = false;
            this.msgService.clear();
        });
        this.isOpenOverlay = true;
    }
    processRequest(rowData, bool) {
        // if (!rowData.status || rowData.status === 'CLOSE') {
        //     this.msgService.clear();
        //     this.msgService.add({ severity: 'error', summary: 'Error', detail: `${!rowData.status ? 'You can not take any action' :
        //      'You can not take any action because the shipment is already CLOSED' }` });
        //     return;
        // }
        if (bool && !this.iconLoadStates['approve-' + rowData.recordID]) {
            console.log('Rahul....', bool, rowData, this.activerowdata);
            this.confirmationService.confirm({
                message: 'Are you ready for capture this transaction?',
                header: 'Capture Transaction',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                key: 'shippingDetails',
                accept: async () => {
                  this.iconLoadStates['approve-' + rowData.recordID] = true;
                //   this.integrationService.captureTransaction('').pipe(takeUntil(this.onDestroygetBuyerDetails)).subscribe((response: any) => {
                //     this.iconLoadStates['approve-' + rowData.recordID] = true;
                //     if (response && response.success) {
                //         this.shippingValue = response.data;
                //     } else {
                //         this.msgService.add({
                //             severity: 'error',
                //             summary: 'Error',
                //             detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
                //         });
                //     }
                //     }, (error) => {
                //         this.iconLoadStates['approve-' + rowData.recordID] = true;
                //         this.msgService.clear();
                //         this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'error.error.message', 'Error occurred during services request') });
                //     });
                },
                reject: () => {
                    this.iconLoadStates['approve-' + rowData.recordID] = false;
                }
            });
        } else if (!bool && !this.iconLoadStates['approve-' + rowData.recordID]) {
            this.confirmationService.confirm({
                message: 'Are you sure you want to delete this transaction?',
                header: 'Reject Transaction',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                key: 'shippingDetails',
                accept: async () => {
                  this.iconLoadStates['reject-' + rowData.recordID] = true;
                },
                reject: () => {
                  this.iconLoadStates['reject-' + rowData.recordID] = false;
                }
              });
        }
    }
    hideShippingOp($event) {
        this.isOpenOverlay = false;
    }
    openShippingNote(rowData, $event) {
        this.selectedShippingRow = rowData;
        if (this.selectedShippingRow.notes) {
            this.shippingNotesOverlay.toggle($event);
        }
    }
    openTxn(rowData) {
        console.log('stxn>>>>>', rowData);
        
    }
    getRepGroupRetailers() {
        const rg: any = this.domainData.repGroup;
        const getRepGroupRetailer: any = _.find(this.retailerObj.repGroupRetailers, (o) =>
        o.repGroupID === (rg && rg.recordID));
        if (getRepGroupRetailer) {
            if (getRepGroupRetailer && getRepGroupRetailer.retailerIntegrationServices && getRepGroupRetailer.retailerIntegrationServices.mtPay) {
                this.mtPayAvilableForRetailer = true;
            } else {
                this.mtPayAvilableForRetailer = false;
            }
            return getRepGroupRetailer;
        } else {
            return null;
        }
    }
}
