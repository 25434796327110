<h3 class="mt-10 mb-10 ml-5 mr-5" *ngIf="fromPage !== 'manageCompany'">Please Enter Your Buyer Details</h3>
<div class="reptime-theme">
    <form (submit)="saveRetailerGetBalanceInfo($event)" [formGroup]="retailerGetBalanceForm" autocomplete="off">
        <div class="p-grid retailer-details-form" style="padding: 5px;">
            <div class="p-col-6">
                <label for="firstName">First Name</label>
                <div class="input-error-container">
                    <input pInputText class="control" formControlName="firstName" id="firstName" maxlength="50"
                        appCustomValidation [allowStartWithSpace]="false" type="text" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}" [readonly]="registerFormDisable || isReadonlyField" />
                    <i *ngIf="validateChk.firstName.invalid && (validateChk.firstName.dirty || validateChk.firstName.touched)"
                        class="error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.firstName,'First Name')" tooltipPosition="left"
                        tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="lastName">Last Name</label>
                <div class="input-error-container">
                    <input class="control" formControlName="lastName" id="lastName" maxlength="50" pInputText
                        appCustomValidation [allowStartWithSpace]="false" type="text" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}"  [readonly]="registerFormDisable || isReadonlyField " />
                    <i *ngIf="validateChk.lastName.invalid && (validateChk.lastName.dirty || validateChk.lastName.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.lastName,'Last Name')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6"><label for="address1">Address 1</label>
                <div class="input-error-container">
                    <input class="control" formControlName="address1" id="address1" pInputText
                        autocomplete="new-password" appCustomValidation [allowStartWithSpace]="false" type="text"
                        maxlength="100" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}"  [readonly]="registerFormDisable || isReadonlyField" />
                    <i *ngIf="validateChk.address1.invalid && (validateChk.address1.dirty || validateChk.address1.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.address1,'Address1')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <!-- <div class="p-col-6"> <label for="address2">Address 2</label>
                <div class="input-error-container">
                    <input class="control" formControlName="address2" id="address2" pInputText
                        autocomplete="new-password" appCustomValidation [allowStartWithSpace]="false" type="text"
                        maxlength="50" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}"  [readonly]="registerFormDisable || isReadonlyField" />
                    <i *ngIf="validateChk.address2.invalid && (validateChk.address2.dirty || validateChk.address2.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.address2,'Address2')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div> -->
            <div class="p-col-6">
                <label for="country">Country</label> <br />
                <div class="input-error-container">
                    <p-dropdown (onChange)="countrySpecific()" [filter]="true" [options]="countries"
                        [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}"  [readonly]="registerFormDisable || isReadonlyField" [style]="{'width':'100%'}" class="control-dropdown"
                        formControlName="country" id="country">
                    </p-dropdown>
                    <i *ngIf="validateChk.country.invalid && (validateChk.country.dirty || validateChk.country.touched)"
                        class="error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.country,'country Name')" tooltipPosition="left"
                        tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="zip">{{validateChk['country'].value === 'US' ? 'Zip Code' : 'Zip Code'}}</label>
                <div class="input-error-container">
                    <input class="control" formControlName="zip" id="zip" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}" [readonly]="registerFormDisable || isReadonlyField"
                        maxlength="{{validateChk['country'].value === 'US'?5:20}}" pInputText appCustomValidation
                        [allowStartWithSpace]="false" autocomplete="new-password" type="text" (blur)="updateChange()">
                    <i *ngIf="validateChk.zip.invalid && (validateChk.zip.dirty || validateChk.zip.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.zip,'Zip')"
                        tooltipPosition="left" tooltipEvent="hover">
                    </i>
                </div>
            </div>
            <div class="p-col-6">
                <div
                    *ngIf="countriesStateJSON && countriesStateJSON[validateChk['country'].value] && countriesStateJSON[validateChk['country'].value].length;then other_content else content">
                </div>
                <ng-template #content>
                    <label for="non-us-state">State</label>
                    <input styleClass="control" formControlName="state" appCustomValidation [allowStartWithSpace]="false"
                        autocomplete="new-password" id="non-us-state" maxlength="50" name="state" pInputText
                        [readonly]="registerFormDisable || isReadonlyField" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}" type="text">
                </ng-template>

                <ng-template #other_content>
                    <label for="us-state">State</label>
                    <p-dropdown [filter]="true" [options]="countriesStateJSON[validateChk['country'].value]"
                        [style]="{'width':'100%'}" class="control-dropdown" [readonly]="registerFormDisable || isReadonlyField "
                        formControlName="state" id="us-state" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}">
                    </p-dropdown>
                </ng-template>
            </div>
            <div class="p-col-6">
                <label for="city">City</label>
                <input class="control" formControlName="city" id="city" maxlength="50" pInputText appCustomValidation
                    [allowStartWithSpace]="false" autocomplete="new-password" type="text"
                    [readonly]="registerFormDisable || isReadonlyField" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}">
            </div>
            <div class="p-col-6"><label for="phone">Phone</label>
                <div class="input-error-container">
                    <p-inputMask [unmask]="true" formControlName="phone" styleClass="control" minlength="10"
                        [readonly]="registerFormDisable || isReadonlyField" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}" maxlength="12" placeholder="Phone" id="phone"
                        mask="999-999-9999?99" autocomplete="off" type="text"></p-inputMask>
                    <i *ngIf="validateChk.phone.invalid && (validateChk.phone.dirty || validateChk.phone.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.phone,'Phone')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6">
                <label for="businessName">Business Name (DBA)*</label>
                <div class="input-error-container">
                    <input class="control" formControlName="businessName" id="businessName" maxlength="50" pInputText
                        appCustomValidation [allowStartWithSpace]="false" type="text" [ngStyle]="{'opacity': registerFormDisable ? '0.5': '1'}"  [readonly]="registerFormDisable" />
                    <i *ngIf="validateChk.businessName.invalid && (validateChk.businessName.dirty || validateChk.businessName.touched)"
                        class="error-icon pi pi-info-circle"
                        [pTooltip]="getErrorMsg(validateChk.businessName,'Business Name')" tooltipPosition="left"
                        tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6"><label for="email">Email*</label>
                <div class="input-error-container">
                    <input class="control" formControlName="email" id="email" pInputText autocomplete="new-password" (focusout)="mtPayEmailCheck()"
                        appCustomValidation [allowStartWithSpace]="false" type="text" [ngStyle]="{'opacity': registerFormDisable ? '0.5': '1'}"  [readonly]="registerFormDisable" />
                    <i *ngIf="validateChk.email.invalid && (validateChk.email.dirty || validateChk.email.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.email,'Email')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
            <div class="p-col-6"><label for="federalTaxNumber">Resale ID/Federal Tax Number (EIN)*</label>
                <div class="input-error-container">
                    <input class="control" formControlName="federalTaxNumber" id="federalTaxNumber" pInputText
                        appCustomValidation [allowStartWithSpace]="false" type="text" [ngStyle]="{'opacity': registerFormDisable ? '0.5': '1'}"  [readonly]="registerFormDisable" />
                    <i *ngIf="validateChk.federalTaxNumber.invalid && (validateChk.federalTaxNumber.dirty || validateChk.federalTaxNumber.touched)"
                        class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.federalTaxNumber,'EIN')"
                        tooltipPosition="left" tooltipEvent="hover"></i>
                </div>
            </div>
        </div>
        <!-- <div class="p-col-12 ">
            <div class="p-col-6">
                <form class="d-inline-block">
                    <p-checkbox name="copyBillToAddress" binary="true" (onChange)="copyAddress($event)"
                        [readonly]="registerFormDisable || isReadonlyField" [ngStyle]="{'opacity': registerFormDisable || isReadonlyField ? '0.5': '1'}" label="Copy Address From Bill To" [(ngModel)]="copyToRetailer">
                    </p-checkbox>
                </form>
            </div>
            <div class="p-col-6 res-w100">
                <form class="d-inline-block">
                    <p-checkbox name="" binary="true" (onChange)="termAndConditionDisabled = $event" label="">
                    </p-checkbox>
                    <a class="ml-8" href="https://markettime.com/privacy-policy/term" target="_blank">Accept Terms and Conditions</a>
                </form>
            </div>
        </div> -->
        <div class="p-col-12 d-flex pl-0 pr-0"
            [ngClass]="(fromPage !== 'manageCompany')?'justify-content-between':'justify-content-end'">
            <button type="button" class="ui-btn ui-button-primary ui-button" style="border-radius: 4px;"
                (click)="backToStep(1)" pButton label="Back" icon="fas fa-backward"
                *ngIf="fromPage !== 'manageCompany'"></button>
            <div class="d-flex"
                [ngClass]="(fromPage !== 'manageCompany')?'justify-content-between':'justify-content-end'">
                <button type="button" class="ui-btn ui-button btn-theme-color" style="border-radius: 4px;" pButton
                    label="Next" [icon]="'fas fa-forward'" *ngIf="registerFormDisable" (click)="backToStep(2)"></button>

                <button *ngIf="!registerFormDisable" type="submit" class="ui-btn ui-button btn-theme-color" style="border-radius: 4px;" pButton
                    label="Register my Details" [icon]=" saveFormLoader ? 'pi pi-spin pi-spinner' :'fa fa-save'"
                    [disabled]="saveFormLoader || mtpayBuyeremailcCheckLoading"></button>
            </div>
        </div>
    </form>
</div>