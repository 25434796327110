import {RepgroupSalesperson} from './repgroup-salesperson';

export class Salesperson {
    recordID: string;
    abbreviation: string;
    active: boolean;
    approved: boolean;
    address1: string;
    address2: string;
    cell: string;
    cellCountryCode: string;
    city: string;
    country: string;
    dateAdded: number;
    dateModified: number;
    email: string;
    fax: string;
    faxCountryCode: string;
    faxExtension: string;
    name: string;
    notes: string;
    phone: string;
    poPrefix: string;
    phoneCountryCode: string;
    phoneExtension: string;
    recordDeleted: boolean;
    state: string;
    status: string;
    userAdded: number;
    userModified: number;
    zip: string;
    repGroupSalespersons: RepgroupSalesperson[];
}
