import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HtmlToolsService } from '../../services/html-tools/html-tools.service';
import { ReptimeEntity } from '../../models/reptime-entity';
import { AuthService } from '../../services/middleware/auth/auth.service';
import { UserpoolService } from '../../services/api/userpool/userpool.service';
import { OrderService } from '../../services/middleware/order/order.service';
import { OrderAPIService } from '../../services/api/order-api/order-api.service';
import { RtDatePipe } from '../../pipes/rt-date.pipe';

@Component({
  selector: 'app-print-preview-dialog',
  templateUrl: './print-preview-dialog.component.html',
  styleUrls: ['./print-preview-dialog.component.scss']
})
export class PrintPreviewDialogComponent implements OnInit, OnChanges, OnDestroy {

  Export: any;
  @Input() request;
  @Input() screen;
  @Input() repgroupName;
  @Input() needReArrageBoxs;
  @Input() itemwithimage = false;

  @Input() sortorder = {
    sort: 'sequenceID',
    order: 'DESC'
  };
  pdfSrc;
  visibleprintpreview = false;
  @Output() public close = new EventEmitter<any>();
  @Output() public updateStatus = new EventEmitter<any>();
  exportFileForm: FormGroup;
  zoom = 1;
  loading = false;
  isOpenExportFile: any = false;
  _exportFileExtension: any;
  _exportMimeType: any;
  _exportReportData: any = {
    filename: '',
    data: ''
  };
  responsedata;
  filename = '';
  showPdfDialogLoader = true;
  isOpenEmail: any = false;
  loadingprint = false;

  sortbuyoption = [
    { label: 'Sequence ID', value: 'sequenceID' },
    { label: 'Item Number', value: 'itemNumber' },
    { label: 'Unit Price', value: 'unitPrice' },
  ];
  math = Math;
  itemwithimageoption = [
    { label: 'Item With Image', value: true, icon: 'fas fa-picture-o' },
    { label: 'Item Without Image', value: false, icon: 'fa fa-window-close' },
  ];
  readonly re = ReptimeEntity;
  oldpdfdata;

  defaultLeft = 5.68;
  defaultRight = 5.55;
  defaultTop = 3.30;
  defaultBottom = 3.17;

  constructor(
    private messageService: MessageService,
    private fb: FormBuilder,
    private htmlTools: HtmlToolsService,
    private orderApi: OrderAPIService,
    public dp: DatePipe,
    // private reportService: ReportService,
    private orderService: OrderService,
    public authService: AuthService,
    private userpoolService: UserpoolService,
  ) { }

  ngOnInit() {
    this.Export = [
      { label: 'Select Export', value: null },
      { label: 'PDF', value: { id: 3, name: 'PDF', icon: 'fas fa-file-pdf' } },
      { label: 'CSV', value: { id: 2, name: 'CSV', icon: 'fas fa-file' } },
    ];
    this.initForm();
    console.log('request', this.request, this.pdfSrc);
    if (this.screen === 'finalize') {
      this.previewOrder('pdf');
    }
  }
  changesorting(i) {
    this.showPdfDialogLoader = true;
    if (i === 1) {
      this.sortorder.order = this.sortorder.order === 'ASC' ? 'DESC' : 'ASC';
    }
    this.previewOrder('pdf');
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  initForm() {
    this.exportFileForm = this.fb.group({
      exportFileFormControl: ['', [Validators.min(0), Validators.required]]
    });
  }
  zoominout(i) {
    this.zoom = i === 0 ? (this.zoom + 0.100) : (this.zoom - 0.100);
  }
  loaderclose() {
    this.loadingprint = false;
  }
  printFile(data) {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = data;
    this.loadingprint = true;
    iframe.addEventListener('load', this.loaderclose.bind(this));
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
  async onProgressPdfViewer($event) { }
  async callBackFnPdfViewer($event) {
    setTimeout(() => {
      this.showPdfDialogLoader = false;
      this.loading = false;
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }
  hideExportFileName() {
    this.isOpenExportFile = false;
  }
  removeInvalidChars(str) {
    return str.replace(/([^a-zA-Z0-9\-_]+)/gi, '');
  }
  previewOrder(type) {
    this.loading = true;
    const repGroupName = this.request['repGroup'] ? this.request['repGroup']['name'] : 'NoRepGroupName';
    const poNumber = this.request['poNumber'] ? this.request['poNumber'] : 'NoPoNumber';
    const orderDate = this.request['orderDate'] ? new RtDatePipe(this.dp).transform(this.request['orderDate']) : 'NoOrderDate';
    this.filename = this.removeInvalidChars(repGroupName + '_' + poNumber + '_' + orderDate);
    console.log('filename', this.filename);
    this.showPdfDialogLoader = true;
    this.orderApi.downloadPrintOrder(this.request.recordID, type, this.sortorder, this.itemwithimage)
      .then(async data => {
        this.responsedata = data;
        if (type !== 'pdf') {
          this.loading = false;
        } else {
          this.oldpdfdata = data;
          this.pdfSrc = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        }
        this.showPdfDialogLoader = false;
      }, () => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred downloading order ' + this.request.recordID
        });
      });
  }
  selectedExportOption(e) {
    if (e && e.value) {
      const _exportType = e.value.name;
      this.exportFileForm.controls.exportFileFormControl.setValue(this.filename);
      if (_exportType === 'EMAIL') {
        this.isOpenEmail = true;
      } else if (_exportType === 'PDF' || _exportType === 'EXCEL' || _exportType === 'CSV') {
        this.isOpenExportFile = true;
        if (_exportType === 'PDF') {
          this._exportFileExtension = '.pdf';
          this._exportMimeType = 'application/pdf';
        } else if (_exportType === 'EXCEL') {
          this._exportFileExtension = '.xls';
          this._exportMimeType = 'application/vnd.ms-excel';
        } else if (_exportType === 'CSV') {
          this._exportFileExtension = '.csv';
          this._exportMimeType = 'text/csv';
        }
        if (this.screen === 'finalize') {
          if (_exportType === 'PDF') {
            this.responsedata = this.oldpdfdata;
          } else {
            this.previewOrder(_exportType.toLowerCase());
          }
        }
      }
    }
  }

  eventExportFile() {
    this.exportFileForm = this.fb.group({
      exportFileFormControl: [this.exportFileForm.value.exportFileFormControl.trim(), [Validators.min(0), Validators.required]]
    });
    this.htmlTools.validateAllFields(this.exportFileForm);
    if (this.exportFileForm.valid) {
      this.messageService.add({ severity: 'info', summary: 'Information', detail: `Exporting as ${this._exportFileExtension} file` });
      this._exportReportData.data = this.responsedata;
      this._exportReportData.filename = `${this.exportFileForm.value.exportFileFormControl}${this._exportFileExtension}`;
      console.log('name', this._exportReportData);
      this.downloadFiles(this._exportReportData, this._exportMimeType);
      this.isOpenExportFile = false;
    }
  }
  private downloadFiles(res: any, mimeType: string) {
    const url = window.URL.createObjectURL(new Blob([res.data], { type: mimeType }));
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
  }

  hideEmailPopUp() {
    this.isOpenEmail = false;
  }

  ngOnDestroy() {

  }
}
