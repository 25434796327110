import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HtmlToolsService } from '../../services/html-tools/html-tools.service';
import { DynamicDialogConfig, DynamicDialogRef, MessageService, SelectItem } from 'primeng/api';
import { RetailerAPIService } from '../../services/api/retailer-api/retailer-api.service';
import * as _ from 'lodash';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';

@Component({
    selector: 'rs-edit-contact-dialog',
    templateUrl: './edit-contact-dialog.component.html',
    styleUrls: ['./edit-contact-dialog.component.scss']
})
export class EditContactDialogComponent implements OnInit {

    public selectedContact: any;
    public retailerID?: string;
    editContactsForm: FormGroup;
    primaryOptions: SelectItem[];
    loadingSave = false;
    isAbleToSaveNote = true;
    notes = '';
    constructor(
        private fb: FormBuilder,
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private htmlTools: HtmlToolsService,
        private retailerAPIService: RetailerAPIService,
        private msgService: MessageService,
        private rtSharedService: RTSharedService,
    ) {
    }

    ngOnInit() {
        this.selectedContact = this.config.data.selectedContact;
        this.retailerID = this.config.data.retailerID;
        console.log('config', this.selectedContact, this.retailerID);
        this.initForm();
        this.primaryOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
        if (this.retailerID) { // for add new
            // this.selectedContact = {retailerID: this.retailerID};
            console.log('aaaa', this.selectedContact);
            if (this.selectedContact !== undefined) {
                this.selectedContact.retailerID = this.retailerID;
            }
            this.setFormFromObject();
        } else { // for edit existing
            this.setFormFromObject();
        }

    }

    initForm() {
        this.editContactsForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: [''],
            middleName: [''],
            title: [''],
            cell: [''],
            phone: [''],
            phoneExtension: [''],
            fax: ['', Validators.pattern('^[0-9]*$')],
            faxExtension: [''],
            notes: [''],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            isPrimary: ['']
        });
    }


    setFormFromObject() {
        this.editContactsForm.reset();

        if (this.selectedContact) {
            this.editContactsForm.patchValue(this.selectedContact);
            if (this.selectedContact.retailerID && !this.selectedContact.recordID) {
                this.editContactsForm.patchValue({ isPrimary: false });
            }
        }
        console.log('CB..: ', this.editContactsForm);
        console.log('editContactsForm', this.selectedContact);
    }

    get validateChk() {
        // Convenience getter for easy access to form fields
        return this.editContactsForm && this.editContactsForm.controls;
    }

    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
    }

    private setObjectFromForm() {
        _.forEach(this.editContactsForm.controls, (key, value) => {
            this.selectedContact[value] = this.editContactsForm.controls[value].value;
        });
    }

    saveContact() {
        console.log('editContactsFormvalid 121', this.editContactsForm);
        console.log('editContactsFormvalid', this.editContactsForm.valid, this.selectedContact);
        if (this.editContactsForm.valid) {
            if (this.selectedContact) {
                this.setObjectFromForm();
            }
            if (!this.selectedContact.retailerID) {
                this.selectedContact.retailerID = this.rtSharedService.getRetailerID();
            }
            if (this.selectedContact && this.selectedContact.retailerID) {
                this.saveSelectedRetailerContact();
            }
        } else {
            this.htmlTools.validateAllFields(this.editContactsForm);
        }
    }

    saveSelectedRetailerContact() {
        this.loadingSave = true;
        this.retailerAPIService.saveRetailerContact(this.selectedContact, this.selectedContact.retailerID)
            .subscribe((data: any) => {
                this.loadingSave = false;
                if (data) {
                    data.retailerID = this.selectedContact.retailerID;
                    console.log('dataaaa??a', data);
                    this.ref.close(data);
                } else {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error saving ship to location' });
                }
            });
    }

    checkCCNumberInString() {
        let matches;
        this.notes =  this.editContactsForm.controls.notes.value;
        matches = this.editContactsForm.controls.notes.value.split(/\s+/);
        if (matches) {
            for (const o of matches) {
                // tslint:disable-next-line:max-line-length
                const ccRegex = new RegExp('^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$');
                const str = o.split('-').join('');
                const isMatch = ccRegex.test(str);
                if (isMatch) {
                    this.isAbleToSaveNote = false;
                    this.editContactsForm.controls['notes'].setErrors({ 'incorrect': true });

                    break;
                } else {
                    this.isAbleToSaveNote = true;
                }
            }
        }
    }
}
