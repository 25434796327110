<p-toast position="top-right"></p-toast>
<div class="finalize-item-list ui-g">
    <div class="reptime-theme my-20 w-100 sidebar-block  checkout-block sticky"
        [ngStyle]="{'top': stickyTop?stickyTop.offsetHeight+'px' : headerHeight}">
        <!-- <div class="sidebar-block-inner mb-10">
            <div class="sidebar-cat-block mb-10">
                <p-card class="card">
                    <p-header class="card-header">
                        <h3 class="my-0"><strong>Steps</strong></h3>            
                    </p-header>
                    <div class="py-10 px-5">  
                        <p>Select Ship Method and Paymen t Terms below to continue with your order</p>                                  
                    </div>
                </p-card>
            </div>
        </div> -->
        <div class="sidebar-block-innerr checkout-block-inner">
            <a href="javascript:;" id="close_checkout" (click)="close_checkout();"></a>
            <div class="sidebar-cat-block">
                <p-card class="card">
                    <p-header class="card-header" style="flex-direction: column;align-items: flex-start;">
                        <h3 class="my-0" style="display: block;text-align: left;font-size: 20px;">Checkout</h3>
                        <div class="" style="display: block;font-weight: normal;font-size: 14px;margin-top: 5px;">Select
                            <b>shipping</b> and <b>payment</b> terms to complete order.
                        </div>
                    </p-header>
                    <!-- B2B-95 (RM 24-FEB-2020) Current order list tabing -->

                    <!-- B2B-110 (NL 29-FEB-2020) add and implement Shimmer effect -->
                    <div class="py-10 px-5" *ngIf="checkoutListShimmer">
                        <p-scrollPanel [style]="{width: '100%', height: '650px'}" styleClass="sidebar-category-scroll">
                            <a href="javascript:;" class="fnlz-left-sidebar"
                                *ngFor="let checkoutShimmer of checkoutShimmerCnt">
                                <span class="shimmer-effect p-10 Wid100 mb-5"></span>
                                <span class="fnlz-small-text">
                                    <span class="shimmer-effect p-5 Wid30"></span>
                                    <span class="shimmer-effect p-5 Wid30"></span>
                                </span>
                            </a>
                        </p-scrollPanel>
                    </div>

                    <div class="py-10 px-5" *ngIf="orderArr && orderArr.length">
                        <p-scrollPanel [style]="{width: '100%', height: '650px'}" styleClass="sidebar-category-scroll">
                            <a href="javascript:;" class="fnlz-left-sidebar"
                                *ngFor="let order of orderArr; let i = index"
                                [ngClass]="(i == activeIndex)?'fn-active':''" (click)="onTabChange($event, i)">
                                <span class="fnlz-big-text">
                                    <!-- {{order.repGroupManufacturerName}} -->
                                    {{rtSharedService.getSeletedLanguage(order.manufacturerName,
                                        order.repGroupManufacturerName,
                                       order.repGroupManufacturerName_json )}}
                                </span>
                                <span class="fnlz-small-text">
                                    <span>{{order.countOrderItems}} Items</span>
                                    <span>{{ (order.orderGrossTotal - (order.orderDiscount / 100 *
                                        order.orderGrossTotal)) | rtCurrency }}</span>
                                </span>
                            </a>
                        </p-scrollPanel>
                        <!-- <p-panelMenu [model]="items" class="b2bcat-menu-list" [multiple]="false"></p-panelMenu> -->
                    </div>
                </p-card>
            </div>
        </div>
    </div>
    <div class="fn-main-right my-20">
        <div class="finalize-order-page w-75 ui-g-9">
            <!-- B2B-110 (NL 29-FEB-2020) add and implement shimmer effect -->
            <div *ngIf="orderArr && !orderArr.length; let i = index" class="flz-main-panel">
                <div class="d-flex py-5 align-items-center">
                    <div class="p-col-5 pb-0 pt-0 d-flex align-items-center">
                        <div class="d-inline-block">
                            <span class="shimmer-effect mr-5" style="width: 28px;height: 28px;"></span>
                            <span class="shimmer-effect mr-5" style="width: 28px;height: 28px;"></span>
                        </div>
                        <span class="shimmer-effect p-10 Wid50"></span>
                    </div>
                    <div class="p-col-7  pb-0 pt-0">
                        <div class="p-col-12 p-0 d-flex justify-content-end">
                            <div class="top-right-info">
                                <b class="shimmer-effect mb-3" style="width: 120px;height: 15px;"></b>
                                <br />
                                <span class="shimmer-effect" style="width: 80px;height: 15px;"></span>
                            </div>
                            <div class="top-right-info">
                                <b class="shimmer-effect mb-3" style="width: 120px;height: 15px;"></b>
                                <br />
                                <span class="shimmer-effect" style="width: 80px;height: 15px;"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-grid py-5">
                    <p-card class="p-col-12 p-sm-6 p-md-4 fnlz-info-box fnlz-info-top">
                        <div class="fnlz-br-btm">
                            <span class="shimmer-effect p-10 Wid100"></span>
                        </div>
                        <div class="LIndent p-10">
                            <div class="shimmer-effect p-5 Wid100 mr-5"></div>
                            <div class="shimmer-effect p-5 Wid50 mr-5"></div>
                            <div class="shimmer-effect p-5 Wid70"></div>
                        </div>
                    </p-card>
                    <p-card class="p-col-12 p-sm-6 p-md-4 fnlz-info-box fnlz-info-top">
                        <div class="fnlz-br-btm">
                            <span class="shimmer-effect p-10 Wid100"></span>
                        </div>
                        <div class="LIndent p-10">
                            <div class="shimmer-effect p-5 Wid100 mr-5"></div>
                            <div class="shimmer-effect p-5 Wid50 mr-5"></div>
                            <div class="shimmer-effect p-5 Wid70"></div>
                        </div>
                    </p-card>
                    <p-card class="p-col-12 p-sm-6 p-md-4 fnlz-info-box card-style required-card fnlz-info-top"
                        id="retailerContact{{i}}">
                        <div class="fnlz-br-btm">
                            <span class="shimmer-effect p-10 Wid100"></span>
                        </div>
                        <div class="LIndent p-10">
                            <div class="shimmer-effect p-5 Wid100 mr-5"></div>
                            <div class="shimmer-effect p-5 Wid50 mr-5"></div>
                            <div class="shimmer-effect p-5 Wid70"></div>
                        </div>
                    </p-card>
                </div>
                <div class="p-grid py-5">
                    <p-card class="p-col-12">
                        <div class="p-grid">
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                            <div class="p-col-4 mb-10">
                                <label class="shimmer-effect p-5 Wid30"></label> <br />
                                <div class="shimmer-effect p-10 Wid100"></div>
                            </div>
                        </div>
                    </p-card>
                </div>

                <div class="p-grid py-5">
                    <p-card class="p-col-12">
                        <div class="">
                            <div class="" style="text-align: right;">
                                <div class="shimmer-effect" style="width: 100px;height: 30px;"></div>
                            </div>
                            <div class="" style="border: 1px solid #ccc;">
                                <div class="d-flex" style="border-bottom: 1px solid #ccc;">
                                    <div class="p-col-3">
                                        <div class="shimmer-effect p-10 Wid100"></div>
                                    </div>
                                    <div class="p-col-3">
                                        <div class="shimmer-effect p-10 Wid100"></div>
                                    </div>
                                    <div class="p-col-3">
                                        <div class="shimmer-effect p-10 Wid100"></div>
                                    </div>
                                    <div class="p-col-3">
                                        <div class="shimmer-effect p-10 Wid100"></div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="d-flex" style="border-bottom: 1px solid #ccc;">
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="border-bottom: 1px solid #ccc;">
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="border-bottom: 1px solid #ccc;">
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="border-bottom: 1px solid #ccc;">
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                    </div>
                                    <div class="d-flex" style="border-bottom: 1px solid #ccc;">
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                        <div class="p-col-3">
                                            <div class="shimmer-effect p-5 Wid100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>

            </div>

            <!-- <p-tabView [activeIndex]="activeIndex" (onChange)="onTabChange($event)" *ngIf="orderArr && orderArr.length">
                <p-tabPanel *ngFor="let order of orderArr; let i = index"
                            [header]="'PO#'+ (i+1) + ': ' + order.manufacturer.name"
                            [selected]="i == 0"> -->
            <div class="flz-main-panel" *ngFor="let order of orderArr; let i = index"
                [ngClass]="(i == activeIndex)?'show-order-tab':'hide-order-tab'">
                <div class="p-grid py-5 align-items-center">
                    <!-- Row 1 -->
                    <div class="fnlz-order-header-btn-wpr">
                        <div class="p-col-12">
                            <div class="fnlz-order-header-btn justify-between">
                                <a href="javascript:;" class="checkout_btn" (click)="show_checkout();">Checkout</a>
                                <a href="javascript:;" class="suggested_btn" (click)="show_suggested_items();"><i
                                        class="fa fa-tags"></i> Suggested Items</a>
                            </div>
                        </div>
                    </div>
                    <div class="fnlz-order-header p-xl-5 pb-0 pt-0 d-flex align-items-center">
                        <!--<button routerLink="/repgroups/orders" class="ui-button-info FLeft"
                                                icon="fa fa-long-arrow-left" pButton title="Go Back To List"></button>-->

                        <div class="d-inline-block" *ngIf="orderArr.length > 1">
                            <button icon="fa fa-arrow-left" class="ui-btn ui-button-info ui-button btn-theme-color"
                                pButton (click)="openPrev()" [disabled]="!activeIndex"></button>

                            <button icon="fa fa-arrow-right"
                                class="ui-btn ui-button-info ui-button mx-5 btn-theme-color" pButton
                                (click)="openNext()" [disabled]="activeIndex === (orderArr.length - 1)"></button>
                        </div>
                        <div class="finalize-order-name">
                            <b class="theme-grayclr finalscreen-title"
                                style="font-size:24px;margin-left:5px;font-weight: normal;"
                                pTooltip="{{order?.repGroupManufacturerName}}">
                                <!-- {{order?.repGroupManufacturerName}} -->
                                {{rtSharedService.getSeletedLanguage(order.manufacturerName,
                                   order.repGroupManufacturerName,
                                   order.repGroupManufacturerName_json )}}
                            </b>
                            <img class="support-mt-tag align-self-center"
                                src="../../../../assets/images/mtpay/mtpay.png" *ngIf="mtPayMFRServiceEnableOrNot && enabledmtPayService"
                                onError="this.src='assets/images/no-image-available.png'"
                                pTooltip="This Manufacturer Supports mtPay">
                        </div>

                    </div>
                    <div class="fnlz-order-header p-xl-7 pb-0 pt-0">
                        <div class="p-col-12 top-info-wpr d-flex justify-content-end">
                            <div class="top-right-info">
                                <b class="theme-grayclr">
                                    <label>Order Status</label><br />
                                </b>
                                <span class="status-value">{{order.repGroupOrderStatus ? order.repGroupOrderStatus :
                                    'N/A'}}</span>
                            </div>
                            <!-- <div class="top-right-info">
                                <b class="theme-grayclr">
                                    <label>Manufacturer Status</label><br />
                                </b>
                                <span class="status-value">{{order.manufacturerOrderStatus ?
                                    order.manufacturerOrderStatus : 'N/A'}}</span>
                            </div>
                            <div class="top-right-info">
                                <b class="theme-grayclr">
                                    <label>Salesperson</label><br />
                                </b>
                                <span class="status-value">{{order.salesperson1 ? order.salesperson1.name : 'No
                                    Salesperson Assigned'}}</span>
                            </div> -->
                            <div class="top-right-info">
                                <b class="theme-grayclr">
                                    <label>Order Total</label><br />
                                </b>
                                <span class="status-value">{{ (order.orderGrossTotal - (order.orderDiscount / 100 *
                                    order.orderGrossTotal)) | rtCurrency }}</span>
                                <span *ngIf="order?.orderDiscount"> ({{order.orderDiscount}}% Off)</span>
                            </div>
                        </div>
                    </div>

                    <!--<div class="p-col-1">-->
                    <!--<label>Invoiced</label><br/>-->
                    <!--{{getInvoicedAmount() | rtCurrency}}-->
                    <!--</div>-->
                    <!-- <div [ngClass]="projectName !== 'B2B' ? 'p-col-2' : 'p-col-2'" *ngIf="projectName !== 'B2B'">
                                        <label>Manufacturer Status</label> <br/>
                                        {{order ? order.manufacturerOrderStatus : 'Open'}}
                                    </div> -->
                    <!-- <div [ngClass]="projectName !== 'B2B' ? 'p-col-2' : 'p-col-2'" *ngIf="projectName !== 'B2B'">
                                        <label>Rep Group Status</label> <br/>
                                        <rs-order-status-dropdown
                                            [(ngModel)]="order.repGroupOrderStatus"
                                            *ngIf="order.repGroupOrderStatus !== 'TRANSMITTED' && order.repGroupOrderStatus !== 'INCOMPLETE'"
                                            (changeEvent)="updateRepgroupStatus($event)"
                                            [orderStatuses]="getRepGroupOrderStatuses()" class="wid-100 bordered">
                                        </rs-order-status-dropdown>
                                        <span class="issue-status-lozenge"
                                            [ngClass]="order.repGroupOrderStatus.toLowerCase()"
                                            *ngIf="order.repGroupOrderStatus === 'TRANSMITTED' || order.repGroupOrderStatus == 'INCOMPLETE'">{{order.repGroupOrderStatus}}</span>
                                    </div> -->
                    <!-- <div [ngClass]="projectName === 'B2B' ? 'p-col-4' : 'p-col-2'" *ngIf="projectName === 'B2B'">
                                        <label><b>STEPS: </b>Select Ship Method and Paymen t Terms below to continue with your order</label>
                                        <br/>
                    
                                    </div> -->
                </div>
                <!-- RC-759 (SG 09 APR 2020): add  word-break-all class-->
                <div class="retailerOriginId" *ngIf="order?.repGroup?.name && order?.originCompanyID">
                    <b>{{order?.repGroup?.name}} Retailer ID : {{order?.originCompanyID}}</b>
                </div>
                <div class="p-grid py-5">
                    <p-card class="p-col-12 p-sm-6 p-md-4 fnlz-info-box fnlz-info-top">
                        <div class="fnlz-br-btm word-break-all posrel">
                            <div class="fnlz-info-label-wpr">
                                <div class="fnlz-info-label">
                                    <div class="label"><i class="fa fa-fw fa-map-marker"></i>&nbsp;Bill To:</div>
                                    <span class="LAlign card-header theme-grayclr">
                                        <p-inplace class="d-inline-block pt-2 inplace-main"
                                            (onDeactivate)="updateChangesMade()" [disabled]="!editable"
                                            (onActivate)="onAcActive(order, 'accountNoDeactive', i); order.preAccountNumber = order.repGroupRetailerManufacturers.accountNumber"
                                            [active]="order.accountNoDeactive" id="accountNumber{{i}}">
                                            <span pInplaceDisplay>
                                                <a href="javaScript:void(0)">{{order.repGroupRetailerManufacturers.accountNumber
                                                    ?
                                                    order.repGroupRetailerManufacturers.accountNumber :
                                                    "MFR Acct #"}}</a>
                                            </span>
                                            <span pInplaceContent>
                                                <input id="accountNoDeactive{{i}}" type="text" value="PrimeNG"
                                                    pInputText
                                                    (blur)="order.accountNoDeactive = false; updateAccountNumber('BILL_TO')"
                                                    [(ngModel)]="order.repGroupRetailerManufacturers.accountNumber"
                                                    maxlength="30">
                                            </span>
                                        </p-inplace>
                                    </span>
                                </div>
                                <span class="RAlign card-action editbtn-icon" *ngIf="editable">
                                    <a href="javascript:;" class="icon-theme-color" pTooltip="Edit Bill To"
                                        tooltipPosition="bottom" (click)="openEditRetailerBillToLocationModal(order)"><i
                                            class="fa fa-edit"></i></a>
                                    <!-- <button class="" pTooltip="Edit Bill To" tooltipPosition="bottom" icon="fa fa-edit"
                                                    (click)="openEditRetailerBillToLocationModal(order)" pButton></button> -->
                                    <!-- <a title="Click here to Edit" (click)="openEditRetailerBillToLocationModal(order)">
                                                        <i class="fa fa-pencil"></i></a> -->
                                </span>
                            </div>
                        </div>
                        <div class="LIndent p-10">
                            <div class="Bold word-break-all">{{order.billToName}}</div>
                            <div class="word-break-all">{{order.billToAddress1}} {{order.billToAddress2}}</div>
                            <div>{{order.billToCity}}, {{order.billToState}} {{order.billToZip}}</div>
                            <div *ngIf="order && order.retailer">{{order.billToPhone | phone}}</div>
                        </div>
                    </p-card>
                    <p-card class="p-col-12 p-sm-6 p-md-4 fnlz-info-box fnlz-info-top">
                        <div class="fnlz-br-btm word-break-all posrel">
                            <div class="fnlz-info-label-wpr">
                                <div class="fnlz-info-label">
                                    <div class="label"><i class="fa fa-fw fa-truck"></i>&nbsp;&nbsp;Ship To:</div>
                                    <span class="LAlign card-header theme-grayclr">
                                        <p-inplace class="d-inline-block pt-2 inplace-main" [disabled]="!editable"
                                            (onActivate)="onAcActive(order, 'shipAccountNoDeactive', i); order.preShipToAccountNumber = order.repGroupRetailerManufacturers.shipToAccountNumber"
                                            [active]="order.shipAccountNoDeactive" id="shipToAccountNumber{{i}}">
                                            <span pInplaceDisplay>
                                                <a href="javaScript:void(0)">{{order.repGroupRetailerManufacturers.shipToAccountNumber
                                                    ?
                                                    order.repGroupRetailerManufacturers.shipToAccountNumber :
                                                    "MFR Ship To Acct #"}} </a>
                                            </span>
                                            <span pInplaceContent>
                                                <input id="shipAccountNoDeactive{{i}}" type="text" value="PrimeNG"
                                                    pInputText
                                                    (blur)="order.shipAccountNoDeactive = false; updateAccountNumber('SHIP_TO')"
                                                    [(ngModel)]="order.repGroupRetailerManufacturers.shipToAccountNumber"
                                                    maxlength="30">
                                            </span>
                                        </p-inplace>
                                    </span>
                                </div>
                                <span class="RAlign card-action editbtn-icon" *ngIf="editable">
                                    <a href="javascript:;" class="icon-theme-color" pTooltip="Add Ship To"
                                        tooltipPosition="bottom"
                                        (click)="openEditRetailerShipToLocationModal(order,'ADD')"><i
                                            class="fa fa-plus"></i></a>
                                    <a href="javascript:;" class="icon-theme-color" pTooltip="Edit Ship To"
                                        tooltipPosition="bottom"
                                        (click)="openEditRetailerShipToLocationModal(order,'EDIT')"><i
                                            class="fa fa-edit"></i></a>
                                    <a href="javascript:;" class="icon-theme-color" pTooltip="Exchange Ship To Location"
                                        tooltipPosition="bottom" (click)="openChangeShipToModal(order)"><i
                                            class="fas fa-exchange-alt"></i></a>
                                </span>
                            </div>
                        </div>
                        <div class="LIndent p-10">
                            <div class="Bold word-break-all">{{order.shipToName}}</div>
                            <div class="word-break-all">{{order.shipToAddress1}} {{order.shipToAddress2}}</div>
                            <div>{{order.shipToCity}}, {{order.shipToState}} {{order.shipToZip}}</div>
                            <div *ngIf="order.retailerShipTo">{{order.retailerShipTo.phone | phone}}
                            </div>
                        </div>
                        <!-- RC-1346 (RK 06-Nov-2020): Remove OOT message -->
                        <!-- <div class="LIndent p-10" *ngIf="hasOOTCustomer">
                            <p class="m-0" style="color: red;">{{orderArr[activeIndex].shipToName}} is out of your
                                territory.</p>
                        </div> -->
                    </p-card>
                    <p-card class="p-col-12 p-sm-6 p-md-4 fnlz-info-box card-style required-card fnlz-info-top"
                        id="retailerContact{{i}}">
                        <div class="fnlz-br-btm">
                            <span class="LAlign card-header theme-grayclr">Buyer Info:</span>
                            <span class="RAlign card-action" *ngIf="editable">
                                <a href="javascript:;" class="icon-theme-color" pTooltip="Add Contact"
                                    tooltipPosition="bottom" (click)="openEditRetailerContactModal(order, 'NEW')"><i
                                        class="fa fa-plus"></i></a>
                                <a href="javascript:;" class="icon-theme-color" pTooltip="Edit Contact"
                                    tooltipPosition="bottom" (click)="openEditRetailerContactModal(order)"><i
                                        class="fa fa-edit"></i></a>
                                <a href="javascript:;" class="icon-theme-color" pTooltip="Exchange Contact"
                                    tooltipPosition="bottom" (click)="ChangeOrderContactModal(order)"><i
                                        class="fas fa-exchange-alt"></i></a>
                            </span>
                            <div class="clear"></div>
                        </div>
                        <span *ngIf="order.retailerContact" class="p-10" style="display: block;">
                            <div class="LIndent">
                                <div class="Bold word-break-all">{{order.retailerContact.firstName}}
                                    {{order.retailerContact.middleName}} {{order.retailerContact.lastName}}</div>
                                <div class="word-break-all">{{order.retailerContact.title}}</div>
                                <div><a class="one-line"
                                        href="mailto:{{order.retailerContact.email}}">{{order.retailerContact.email}}</a>
                                </div>
                                <div>{{order.retailerContact.phone | phone}}</div>
                            </div>
                        </span>
                    </p-card>
                </div>
                <div class="p-grid py-5 finalize-middle-from">
                    <p-card class="p-col-12">
                        <p class="p-col-12 error-message-services p-2 m-2" *ngIf="errorMessageService && errorMessageService?.mtPayBuyerDetails">
                            {{errorMessageService?.mtPayBuyerDetails}}
                        </p>
                        <p class="p-col-12 error-message-services p-2 m-2" *ngIf="errorMessageService && errorMessageService?.mtPayBuyerCreditLimit">
                            {{errorMessageService?.mtPayBuyerCreditLimit}}
                        </p>
                        <div class="p-grid">
                            <!-- RC-1179 (MK 08 AUG 2020): single time api call -->
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'">
                                <label for="orderDate{{i}}">Order Date*</label> <br />
                                <rs-calendar
                                    (DateChangeOnBlur)="order.orderDate=$event;datesUpdate(order, 'ORDER_DATE')"
                                    [date]="order.orderDate" [(olddate)]="order.orderDate" [required]="true"
                                    [disabled]="!editable" [maxDate]="re.limitedOrderDate" id="orderDate{{i}}"
                                    class="input-datepicker-btn"></rs-calendar>
                            </div>
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'">
                                <label for="requestDate{{i}}">Request Ship Date*</label> <br />
                                <rs-calendar
                                    (DateChangeOnBlur)="order.requestDate=$event;datesUpdate(order, 'REQUEST_SHIP_DATE')"
                                    [(olddate)]="order.requestDate" [date]="order.requestDate"
                                    [minDate]="order.orderDate" [required]="true" [disabled]="!editable"
                                    id="requestDate{{i}}" class="input-datepicker-btn">
                                </rs-calendar>
                            </div>
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'">
                                <label for="cancel-date">Cancel Date</label> <br />
                                <rs-calendar
                                    (DateChangeOnBlur)="order.cancelDate=$event; checkIfValueChanged(order, 'Cancel Date', 'cancelDate'); updateChangesMade();"
                                    [date]="order.cancelDate" [(olddate)]="order.cancelDate"
                                    [minDate]="order.requestDate" id="cancel-date" [disabled]="!editable"
                                    class="input-datepicker-btn"></rs-calendar>
                            </div>
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'"
                                *ngIf="projectName !== 'B2B'">
                                <label for="season">Season</label> <br />
                                <input id="season" pInputText class="wid-100" disabled />
                                <!--<app-calendar (dateChange)="updateChangesMade($event)" [(date)]="selectedOrder.requestDate" id="request-date"></app-calendar>-->
                            </div>
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'">
                                <label for="po-number">PO Number</label> <br />
                                <!-- RC-1233 (MK 28-SEPT-2020): check po number -->
                                <input class="wid-100" (blur)="checkponumber(order,$event)" [(ngModel)]="order.poNumber"
                                    [disabled]="!editable" id="po-number" pInputText />
                            </div>
                            <!--<div class="p-col-3">-->
                            <!--<label for="order-type">Order Status</label> <br/>-->
                            <!--<p-dropdown (ngModelChange)="updateChangesMade($event)"-->
                            <!--[(ngModel)]="order.orderType"-->
                            <!--[options]="orderTypeOptions" id="order-type">-->
                            <!--</p-dropdown>-->
                            <!--</div>-->
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'"
                                *ngIf="projectName !== 'B2B'">
                                <label for="orderCode{{i}}">Order Code*</label> <br />
                                <p-dropdown (ngModelChange)="updateChangesMade($event, 'ORDER_CODE')" required="true"
                                    placeholder="Select a Code" [(ngModel)]="order.orderCode" id="orderCode{{i}}"
                                    [disabled]="!editable" [options]="order.orderCodeOptions">
                                </p-dropdown>
                            </div>
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'"
                                class="posrel">
                                <label for="shippingMethod{{i}}"><strong class="theme-grayclr"> Ship
                                        Method*</strong></label>
                                <span class="apply-font" *ngIf='order.retailerCarrierAccount' tooltipPosition="top"
                                    pTooltip="Applied Acct x{{order.retailerCarrierAccount.accountNumber.substring(order.retailerCarrierAccount.accountNumber.length - 4)}}">
                                    Applied Acct
                                    x{{order.retailerCarrierAccount.accountNumber.substring(order.retailerCarrierAccount.accountNumber.length
                                    - 4)}}</span><br />
                                <!-- RC-401 (RK 08 APRIL 2020): modified -->
                                <p-dropdown (ngModelChange)="updateShipMethod($event, order.shippingMethodOptions)"
                                    placeholder="Select a Method" required="true" [(ngModel)]="order.shippingMethod"
                                    [disabled]="!editable"
                                    [options]="order.shippingMethodOptions | selectItemPipe:'shippingMethod'"
                                    id="shippingMethod{{i}}">
                                </p-dropdown>
                            </div>
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'"
                                *ngIf="(!mtPayMFRServiceEnableOrNot || !enabledmtPayService) || (orderArr[activeIndex].paymentTerm && orderArr[activeIndex].paymentTerm !== 'mtPay')" class="posrel">
                                <label for="paymentTerm{{i}}"><strong class="theme-grayclr">Payment Terms*</strong>
                                    <span class="apply-font" tooltipPosition="top"
                                        pTooltip="{{getPaymentToken(i, order.paymentTerm, order.paymentTermOptions)}}">{{getPaymentToken(i,
                                        order.paymentTerm, order.paymentTermOptions)}}</span>
                                </label>
                                <br />
                                <!-- <input class="wid-100" type="text" *ngIf="orderArr[activeIndex].paymentTerm === 'mtPay'" name="mtPay" id="paymentTerm{{i}}" value="mtPay {{orderArr[activeIndex].mtPayOrderPaymentDetailsJson?.paymentTerms ?  ' (Terms: '+ orderArr[activeIndex].mtPayOrderPaymentDetailsJson?.paymentTerms +')' : '' }}" [disabled]="true" pInputText> -->
                                <input class="wid-100" type="text" *ngIf="orderArr[activeIndex].paymentTerm === 'mtPay'" name="mtPay" id="paymentTerm{{i}}" value="{{orderArr[activeIndex].paymentTerm}} {{orderArr[activeIndex]['mtPayOrderPaymentDetailsJson']?.paymentTerms === '0' ? '(Pay Upon Shipment via '+ (orderArr[activeIndex]['mtPayOrderPaymentDetailsJson']?.paymentMethod === 'creditCard' ? 'CC' : 'Bank') +')' : '(Net ' + orderArr[activeIndex]['mtPayOrderPaymentDetailsJson']?.paymentTerms + ' Days via ' + (orderArr[activeIndex]['mtPayOrderPaymentDetailsJson']?.paymentMethod === 'creditCard' ? 'CC' : 'Bank') + ')'}}" [disabled]="true" pInputText>
                                <p-dropdown *ngIf="orderArr[activeIndex].paymentTerm !== 'mtPay'" (ngModelChange)="updatePaymentTerms($event, order.paymentTermOptions)"
                                    placeholder="Select a Term" required="true" [(ngModel)]="order.paymentTerm"
                                    [options]="order.paymentTermOptions | selectItemPipe:'paymentTerm'"
                                    [disabled]="!editable || !mtPayEditableItemsDetails" id="paymentTerm{{i}}">
                                </p-dropdown>
                            </div>
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-4'"
                                *ngIf="mtPayMFRServiceEnableOrNot && enabledmtPayService && (!orderArr[activeIndex].paymentTerm || orderArr[activeIndex].paymentTerm === 'mtPay')" class="posrel">
                                <label for="paymentMethod{{i}}"> <strong class="theme-grayclr">Payment Method*</strong></label>
                                <span class="apply-font" *ngIf="appliedBankDetail" tooltipPosition="top"
                                    pTooltip="Applied Bank {{appliedBankDetail?.institutionName}} ({{appliedBankDetail?.accountName}})">Applied Bank {{appliedBankDetail?.institutionName}}</span>
                                <span class="apply-font" *ngIf="(finalSelectedCard | json) !== '{}'" tooltipPosition="top"
                                    pTooltip="Applied Acct x{{finalSelectedCard?.last4}}">
                                    Applied Acct
                                    x{{finalSelectedCard?.last4}}</span><br />
                                <div style="position: relative;">
                                    <p-dropdown
                                        placeholder="Select Payment Method" required="true"
                                        [options]="newMTPayPaymentMethods" [disabled]="(!editable || !mtPayEditableItemsDetails || mtPayLoading || newMTPayCreditCardDetailsSpinner || errorMessageService?.mtPayBuyerDetails) && !mtPayPaymentDeclined" [(ngModel)]="newMTPaySelectedPaymentMethods" id="paymentMethod{{i}}">
                                        <ng-template let-item pTemplate="selectedItem">
                                            <img src="{{item.value.icon}}" style="width:16px;vertical-align:middle" />
                                            <span style="vertical-align:middle; margin-left: .5em" *ngIf="(item?.value?.type === 'creditCard' && (finalSelectedCard | json) === '{}') || (item?.value?.type === 'bank' && !appliedBankDetail)">{{item.label}} </span>
                                            <span style="vertical-align:middle; margin-left: .5em" *ngIf="item?.value?.type === 'creditCard' && (finalSelectedCard | json) !== '{}'">Applied Acct x{{finalSelectedCard?.last4}}</span>
                                            <span style="vertical-align:middle; margin-left: .5em" *ngIf="item?.value?.type === 'bank' && appliedBankDetail">Applied Bank {{appliedBankDetail?.institutionName}}</span>
                                        </ng-template>
                                        <ng-template let-car pTemplate="item">
                                            <div class="ui-helper-clearfix d-flex" style="position: relative;" (click)="newMTPayclickPaymentMethod(car)">
                                                <img src="{{car.value.icon}}" style="width:24px;margin-right: 5px;" />
                                                <div style="font-size:14px;margin-top:4px">{{car.label}}<span *ngIf="car?.value?.type === 'creditLimit'" style="color: green;" class="fs-11"> ({{newMTPayretaileravilablecl | rtCurrency}})</span></div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <span *ngIf="newMTPayCreditCardDetailsSpinner || mtPayLoading" style="position: absolute;top: 50%;transform: translate(0px,-50%);right: 8px;">
                                            <i class="fa fa-circle-o-notch fa-spin" style="font-size: 1em"></i>
                                    </span>
                                </div>
                            </div>

                            <div class="posrel" *ngIf="mtPayMFRServiceEnableOrNot && enabledmtPayService && (!orderArr[activeIndex].paymentTerm || orderArr[activeIndex].paymentTerm === 'mtPay')" [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-6 p-md-5'">
                                <!-- *ngIf="mtPayMFRServiceEnableOrNot && mtPayRetailerServiceEnableOrNot" -->
                                <div *ngIf="!getApprovedForNetTermsButton">
                                    <label for="paymentTerms{{i}}"> <strong class="theme-grayclr">Payment Terms*</strong></label>
                                    <span class="apply-font" *ngIf="newMTPayretaileravilablecl" tooltipPosition="top"
                                        pTooltip="Available Credit Limit {{newMTPayretaileravilablecl | rtCurrency}}">
                                        Avail CL: {{newMTPayretaileravilablecl | rtCurrency}}</span><br />
                                    <div style="position: relative;">
                                        <p-dropdown (onChange)="newMTPayPaymentTermDropDownOnChange($event)" [filter]="false"
                                        [required]="true" placeholder="Select Your Credit Terms {{newMTPayretaileravilablecl ? '(Avail: ' + (newMTPayretaileravilablecl | rtCurrency) + ')' : ''}}"
                                        [options]="newMTPayPaymentTermDropDownArray" #dropDownPaymentTerms
                                        [(ngModel)]="newMTPayPaymentTermDDModel" class="mt-10"
                                        appendTo="body" [disabled]="!editable || !mtPayEditableItemsDetails || mtPayLoading || errorMessageService?.mtPayBuyerDetails"
                                        id="paymentTerms{{i}}">
                                        </p-dropdown>
                                        <span *ngIf="mtPayLoading" style="position: absolute;top: 50%;transform: translate(0px,-50%);right: 8px;">
                                            <i class="fa fa-circle-o-notch fa-spin" style="font-size: 1em"></i>
                                        </span>
                                    </div>
                                </div>
                                <p-button type="button" *ngIf="getApprovedForNetTermsButton" [styleClass]="'ui-btn ui-button btn-theme-color'" (click)="getApprovedForNetTermsClick()" label="Get Approved for Net Terms" [disabled]="!editable || !mtPayEditableItemsDetails || mtPayLoading || errorMessageService?.mtPayBuyerDetails" [icon]="mtPayLoading ? 'fa fa-fw fa-spinner fa-spin' : 'fas fa-paper-plane'"></p-button>
                            </div>



                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-3' : 'p-col-12 p-sm-12 p-md-12'">
                                <div class="pb-5 pt-10">
                                    <p-checkbox (ngModelChange)="updateChangesMade($event)"
                                        [(ngModel)]="order.acceptBackOrder" binary="true" [disabled]="!editable"
                                        label="Allow to Accept Back Orders for this sale"></p-checkbox>
                                </div>
                                <div *ngIf="projectName !== 'B2B'" class="py-5">
                                    <p-checkbox (ngModelChange)="updateChangesMade($event)"
                                        [(ngModel)]="order.attachDocuments" [disabled]="!editable" binary="true"
                                        label="Attach Credit App">
                                    </p-checkbox>
                                </div>
                            </div>
                            <div class="p-col-5" *ngIf="projectName !== 'B2B'">
                                <label for="notes">Notes</label>
                                <textarea (blur)="updateChangesMade($event ,'defaultEnable')" [(ngModel)]="order.notes"
                                    id="notes" pInputTextarea rows="5" maxlength="250"></textarea>
                            </div>
                            <div [ngClass]="projectName !== 'B2B' ? 'p-col-4' : 'p-col-8 p-sm-12'">
                                <label for="special-instructions">Special Order Instructions <span class="cc-error"
                                        *ngIf="!validationArrayForCC[activeIndex].isValidSpecialInstruction"> (You can't
                                        enter CC number)</span></label>
                                <textarea
                                    (blur)="validationArrayForCC[activeIndex].isValidSpecialInstruction ? updateChangesMade($event ,'defaultEnable') : ''"
                                    [class.isnotvalid]="!validationArrayForCC[activeIndex].isValidSpecialInstruction"
                                    [(ngModel)]="order.specialInstructions"
                                    (input)="checkCCNumberInString(order.specialInstructions)" id="special-instructions"
                                    pInputTextarea rows="2" maxlength="500"></textarea>
                            </div>
                        </div>
                    </p-card>
                    <!-- <p-card class="p-col-4">
                                        <label><b>Salesperson: </b></label>
                                        <p-inplace [closable]="true" class="inplace-dropDown"
                                                *ngIf="!salespersonID && this.projectName !== 'B2B'">
                                        <span pInplaceDisplay>
                                            <a href="javaScript:void(0)">{{order.salesperson1 ? order.salesperson1.name : 'No Salesperson Assigned'}}</a>
                                        </span>
                                            <span pInplaceContent>
                                                <rs-salesperson-autocomplete
                                                    [attr.id]="'assigned-salesperson'"
                                                    [currentSalesperson]="order.salesperson1"
                                                    (salespersonSelectedEvent)="salespersonSelectedEvent($event)"
                                                    [placeholder]="'Assigned Salesperson'">
                                                </rs-salesperson-autocomplete>
                                            </span>
                                        </p-inplace>
                                        <div class="LIndent" *ngIf="salespersonID || this.projectName === 'B2B'">
                                            {{order.salesperson1 ? order.salesperson1.name : 'No Salesperson Assigned'}} <br/>
                                        </div>
                    
                                        <label><b>Order Writer:</b></label><br/>
                                        <div class="LIndent">
                                            {{order.orderWriter ?
                                            order.orderWriter.firstName + ' ' + order.orderWriter.lastName :
                                            'No Salesperson To Pay'}} <br/>
                                        </div>
                                    </p-card> -->
                    <div class="p-col-12">
                        <rs-order-detail-table [(items)]="order.orderDetails" (itemsUpdatedObj)="getItemsUpdatedObj($event)" (getOrder)="getOrderTotal($event)"
                            [disabled]="!editable" [editableItemsDetails]="mtPayEditableItemsDetails" [order]="order" class="order-tblmain-scroll">
                        </rs-order-detail-table>
                    </div>
                    <div class="p-col-12 custom-ui-btn-group">
                        <div class="custom-ui-btn-group-inner">
                            <button [label]="'Cancel Order'" (click)="deleteOrder()"
                                class=" ui-button-danger ui-button ui-btn theme-line-btndg" icon="fa fa-trash"
                                [disabled]="!editable" pButton></button>
                            <button *ngIf="auth.getshowPromotionsButtonFlag()" [label]="'Promotions'"
                                (click)="showSpecialsAvailableDialog()"
                                class="ui-btn  ui-button-warning ui-button theme-line-btnpr btn-with-icon"
                                [disabled]="!editable" pButton>
                                <span class="btn-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32.812"
                                        viewBox="0 0 40 32.812">
                                        <g id="Group_1" data-name="Group 1" transform="translate(-122 -177)">
                                            <path class="fill-current" id="Path_1" data-name="Path 1"
                                                d="M440.765,226h-3.593a1.172,1.172,0,1,0,0,2.343h3.593a1.172,1.172,0,1,0,0-2.343Z"
                                                transform="translate(-279.936 -34.937)" />
                                            <path class="fill-current" id="Path_2" data-name="Path 2"
                                                d="M425.344,288.686,423,286.343A1.172,1.172,0,0,0,421.344,288l2.343,2.343a1.172,1.172,0,0,0,1.657-1.657Z"
                                                transform="translate(-266.108 -90.249)" />
                                            <path class="fill-current" id="Path_3" data-name="Path 3"
                                                d="M425.343,136.343a1.171,1.171,0,0,0-1.657,0l-2.343,2.343A1.172,1.172,0,0,0,423,140.343L425.343,138A1.171,1.171,0,0,0,425.343,136.343Z"
                                                transform="translate(-266.108 48.032)" />
                                            <path class="fill-current" id="Path_4" data-name="Path 4"
                                                d="M8.28,136a3.509,3.509,0,0,0-3.3,2.343H4.765a4.687,4.687,0,1,0,0,9.373h.216a3.509,3.509,0,0,0,3.3,2.343h5.858V136Z"
                                                transform="translate(122 48.032)" />
                                            <path class="fill-current" id="Path_5" data-name="Path 5"
                                                d="M333.343,46A2.344,2.344,0,0,0,331,48.344V71.782a2.343,2.343,0,1,0,4.687,0V48.344A2.344,2.344,0,0,0,333.343,46Z"
                                                transform="translate(-183.14 131)" />
                                            <path class="fill-current" id="Path_6" data-name="Path 6"
                                                d="M92.172,344.6A5.856,5.856,0,0,1,91,344.482v5.977a3.515,3.515,0,0,0,7.03,0V344.6Z"
                                                transform="translate(38.11 -144.161)" />
                                            <path class="fill-current" id="Path_7" data-name="Path 7"
                                                d="M217,85.819a10.032,10.032,0,0,1-6,2.856v14.289a10.031,10.031,0,0,1,6,2.856l1.03,1.03V84.789Z"
                                                transform="translate(-73.229 95.241)" />
                                        </g>
                                    </svg>
                                </span>
                            </button>
                            <!--<button [label]="'Duplicate Order'" class="ui-btn ui-button-secondary ui-button disabled"-->
                            <!--pButton></button>-->
                            <button [label]="'Discount'" (click)="onDiscountDialogShow()" *ngIf="projectName !== 'B2B'"
                                [disabled]="!editable" class="ui-btn  ui-button-secondary ui-button"
                                icon="fa fa-percent" pButton>
                            </button>
                            <button [label]="'Print/Preview'" (click)="previewOrder()"
                                class="ui-btn  ui-button-primary ui-button theme-line-btnprnt" icon="fa fa-print"
                                pButton type="button"></button>
                            <button [label]="'Duplicate Order'" (click)="initiateOrderDuplicate()"
                                class="ui-btn  ui-button-primary ui-button theme-line-btnprnt" icon="fa fa-fw fa-copy"
                                pButton type="button"></button>
                            <button [label]="'Email Order'" (click)="showEmailDialog()"
                                *ngIf="this.projectName !== 'B2B'" [disabled]="!editable"
                                class="ui-btn  ui-button-primary ui-button" icon="fa fa-envelope" pButton></button>
                            <!--<button [label]="'Track Payments'" class="ui-btn ui-button-primary ui-button disabled"-->
                            <!--pButton></button>-->
                            <button class="ui-btn ui-button-success ui-button FRight theme-maroon-btn"
                                (click)="saveOrder(order, false, true, false)"
                                *ngIf="order.repGroupOrderStatus === 'INCOMPLETE' && orderArr.length > (activeIndex + 1) && editable"
                                icon="{{loadingSave? 'fa fa-fw fa-spinner fa-spin': 'fa fa-arrow-circle-right'}}"
                                [disabled]='(order.orderGrossTotal - (order.orderDiscount / 100 * order.orderGrossTotal)) < order.manufacturer.minimumOrderAmount && isMinOrderRequired'
                                [label]="mtPayMFRServiceEnableOrNot && enabledmtPayService && (!orderArr[activeIndex].paymentTerm || orderArr[activeIndex].paymentTerm === 'mtPay')? 'Next Order' : 'Next Order'" pButton>
                            </button>
                            <button class="ui-btn ui-button-success ui-button FRight theme-maroon-btn"
                                (click)="openNext()"
                                *ngIf="order.repGroupOrderStatus !== 'INCOMPLETE' && orderArr.length > (activeIndex + 1)"
                                icon="fa fa-arrow-circle-right" label="Next Order" pButton>
                            </button>
                            <!-- // B2B-49 (MG-03-MAR-2020) modified to confirm when complete finalize order -->
                            <!-- (click)="saveOrder(order, true, true, true)" -->
                            <button class="ui-btn ui-button-success ui-button FRight theme-maroon-btn"
                                (click)="confirmAndFinish(order, true, true, true)"
                                icon="{{loadingSave? 'fa fa-fw fa-spinner fa-spin': 'fa fa-check-square'}} "
                                *ngIf="orderArr.length === (activeIndex+ 1) && (editable || isEditableNotSave)"
                                [disabled]='(order.orderGrossTotal - (order.orderDiscount / 100 * order.orderGrossTotal)) < order.manufacturer.minimumOrderAmount && isMinOrderRequired || loadingSave'
                                [label]="mtPayMFRServiceEnableOrNot && enabledmtPayService && (!orderArr[activeIndex].paymentTerm || orderArr[activeIndex].paymentTerm === 'mtPay')? 'Submit & Complete' : 'Submit & Complete'" pButton>
                            </button>
                            <button class="ui-btn ui-button-success ui-button FRight theme-maroon-btn"
                                (click)="confirmAndFinishBypass(order, true, true, true)"
                                icon="{{loadingSave? 'fa fa-fw fa-spinner fa-spin': 'fa fa-check-square'}} "
                                *ngIf="orderArr.length === (activeIndex+ 1) && (!editable && !isEditableNotSave)"
                                [disabled]='(order.orderGrossTotal - (order.orderDiscount / 100 * order.orderGrossTotal)) < order.manufacturer.minimumOrderAmount && isMinOrderRequired || loadingSave'
                                label="Submit & Complete" pButton>
                            </button>
                            <button class="ui-btn ui-button-success ui-button FRight"
                                (click)="transmitOrder(order, false, true)" icon="fa fa-paper-plane"
                                *ngIf="orderArr.length > (activeIndex + 1) && this.projectName !== 'B2B' && editable"
                                label="{{order.repGroupOrderStatus !== 'TRANSMITTED' ? 'Transmit' : 'Re transmit'}}  and Next"
                                pButton>
                            </button>
                            <button class="ui-btn ui-button-success ui-button FRight" icon="fa fa-paper-plane"
                                (click)="transmitOrder(order, true, true, true)"
                                *ngIf="orderArr.length === (activeIndex+ 1) && this.projectName !== 'B2B' && editable"
                                label="{{order.repGroupOrderStatus !== 'TRANSMITTED' ? 'Transmit' : 'Re transmit'}} and Finish"
                                pButton>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </p-tabPanel>
            </p-tabView> -->
        </div>
        <!-- Suggested Items right bar start -->
        <div class="ui-g-3 item-cart-list reptime-theme fin-sticky suggested-items-block">
            <div class="suggested-items-block-inner">
                <a href="javascript:;" id="close_suggested_items" (click)="close_suggested_items();"></a>
                <p-card class="card sticky sticky-basket">
                    <p-header class="card-header d-block">
                        <h2 class="m-0 mb-0 theme-grayclr">
                            <i class="fa fa-tags "></i> <span style="font-size: 20px; color:#3f3f3f;"> Suggested
                                Items</span>
                            <i *ngIf="basketSyncLoader" class="fa fa-spinner fa-spin" style="float: right;"></i>
                        </h2>
                        <!-- <span style="font-weight: normal;">Here are some more items you may want to order!</span> -->
                    </p-header>
                    <div class="cart-content-box" style="padding-bottom: 5px;">
                        <!-- <div class="ui-selectbutton suggest-dropdown-block p-10">
                            <p-dropdown [options]="suggestItemList" filter="true" class="callout-mfrlist">
                                <ng-template let-car pTemplate="item">
                                    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                        <div style="font-size:14px;margin-top:4px">{{car.label}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div> -->
                        <p-scrollPanel [style]="{width: '100%', height: '77vh'}">
                            <!-- B2B-80 (NL 15-FEB-2020) Current order list html -->
                            <!-- B2B-95 (RM 25-FEB-2020) Suggested list dynamic -->
                            <!-- cart item list -->
                            <ng-container>
                                <!-- B2B-110 (NL 29-FEB-2020) add and implement Shimmer effect -->
                                <div *ngIf="suggestListShimmer">
                                    <div class="cart-item-list-main" *ngFor="let suggestShimmer of suggestShimmerCnt">
                                        <div class="cart-item-img shimmer-effect"></div>
                                        <div class="cart-item-detail">
                                            <div class="cart-item-desc shimmer-effect p-10 Wid100"></div>
                                            <div class="cart-item-num-block justify-content-between suggested-item-btn">
                                                <a href="javascript:;" class="mr-10 shimmer-effect p-5 Wid30"></a>
                                                <a href="javascript:;" class="mr-10  shimmer-effect p-5 Wid30"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!suggestedItemsCount && suggestListShimmer === false">
                                    <div class="cart-item-list-main no-item-found">
                                        <h3 class="theme-grayclr">Suggested items not found</h3>
                                    </div>
                                </div>
                                <div *ngIf="suggestedItemsCount">
                                    <div class="cart-item-list-main"
                                        *ngFor="let suggestedItems of suggestedItemsArr; let i = index">
                                        <div class="cart-item-img">
                                            <a href="javascript:;" class="mr-10"
                                                [class.disabled]='suggestedItems.item.isDisabled || !editable || !mtPayEditableItemsDetails'
                                                (click)="viewItemDetails(suggestedItems.item)">
                                                <!-- <img src="{{suggestedItems.item.fullPicturePath}}"
                                                    onError="this.src='assets/images/no-image-available.png'"> -->
                                                <!-- <img *ngIf="suggestedItems.item.imageError" src="{{suggestedItems.item.profilePic}}" onerror="this.src='assets/images/no-image-available.png'">
                                                <img *ngIf="!suggestedItems.item.imageError"
                                                        (error)="suggestedItems.item.fullPicturePath = null;suggestedItems.item.imageError=true"
                                                        src="{{suggestedItems.item.fullPicturePath}}" /> -->
                                                <!-- <object [data]="suggestedItems.item.fullPicturePath64PX">
                                                    <img [src]="suggestedItems.item.picturePath ? suggestedItems.item.fullPicturePath : suggestedItems.item.profilePic" onerror="this.src='assets/images/no-image-available.png'">
                                                </object> -->
                                                <app-resized-image
                                                    [resizedPicturePath]="suggestedItems.item.fullPicturePath64PX"
                                                    [ogPicturePath]="suggestedItems.item.picturePath ? suggestedItems.item.fullPicturePath : suggestedItems.item.profilePic">
                                                </app-resized-image>
                                            </a>
                                        </div>
                                        <div class="cart-item-detail">
                                            <div class="cart-item-desc pr-0 pt-7 mb-8" style="color:#585958;"
                                                tooltipPosition="top" pTooltip="{{suggestedItems.item.description}}">
                                                <a href="javascript:;" class="mr-10"
                                                    [class.disabled]='suggestedItems.item.isDisabled || !editable || !mtPayEditableItemsDetails'
                                                    (click)="viewItemDetails(suggestedItems.item)"
                                                    style="color:#585958;">{{suggestedItems.item.name}}</a>
                                            </div>
                                            <div class="cart-item-num-block justify-content-between suggested-item-btn">
                                                <a href="javascript:;" class="mr-10 p-cat-theme"
                                                    *ngIf="!suggestedItems.item.b2bItemPricingPolicyFlag"
                                                    [class.disabled]='suggestedItems.item.isDisabled || !editable || !mtPayEditableItemsDetails'
                                                    (click)="addToOrderCart(suggestedItems.item)"
                                                    style="font-size: 12px;">Add to Order</a>
                                                <!-- <a href="javascript:;" class="mr-10" [class.disabled]='suggestedItems.item.isDisabled || !editable' (click)="viewItemDetails(suggestedItems.item)" style="font-size: 12px; color:#585958;"><i class="fa fa-tag "></i> View Item</a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- End -->
                        </p-scrollPanel>
                    </div>
                    <!-- <p-footer class="card-footer">
                        <div class="wid-100 d-flex justify-content-between p-5">
                            <button class="ui-button-info" pButton type="button" label="Promotions"
                                    icon="fa fa-fw fa-commenting" ></button>
                            <button class="ui-button-info"
                                    pButton type="button" label=" Minimum Met"
                                    icon="fa fa-fw fa-exclamation"></button>
                        </div>
                        <div class="m-5 d-flex justify-content-between align-items-center">
                            <h2 class="mb-0"><strong>10 Items/$100</strong></h2>
                            <button pButton type="button" label=" CHECKOUT NOW" class="ui-button-success" icon="fa fa-fw fa-shopping-basket"
                                    ></button>
                        </div>
                    </p-footer> -->
                </p-card>
            </div>
        </div>
        <!-- Suggested Items right bar end -->
    </div>
</div>

<div *ngIf="orderArr[activeIndex]">
    <!-- <p-confirmDialog header="Confirmation"
                      icon="pi pi-exclamation-triangle"
                      [style.width]="'425px'"
                      appendTo="body"></p-confirmDialog>-->
    <p-confirmDialog header="Confirmation" *ngIf="projectName === 'B2B'" icon="pi pi-exclamation-triangle"
        [style.width]="'425px'" appendTo="body" [baseZIndex]="12002"></p-confirmDialog>
    <p-dialog header="Email Not Available" [modal]="true" [blockScroll]="true" [draggable]="false"
        [(visible)]="isNoMfgEmail" [resizable]="false">
        <div class="p-5">Manufacturer email not available. Please contact admin.</div>
        <div class="text-center m-5">
            <button (click)="isNoMfgEmail = false" [label]="'OK'" class="ui-button-raised" pButton
                type="button"></button>
        </div>
    </p-dialog>
    <p-dialog (onHide)="hideDialog(displaySpecialsAvailableDialog)" [(visible)]="displaySpecialsAvailableDialog"
        [closable]="true" [blockScroll]="true" [modal]="true" [draggable]="false" [closeOnEscape]="true"
        [header]="'Promotions/Special Offers Available'" [maximizable]="false" [positionTop]="40"
        [style]="{'width': '89vw'}" [resizable]="false" styleClass="overflow-auto cut-dialog-height">

        <p-table #dt [alwaysShowPaginator]="false" [autoLayout]="true" [columns]="promotionCols" [paginator]="true"
            [responsive]="false" [rowsPerPageOptions]="[25, 50, 75]" [rows]="25" [value]="availablePromotions"
            dataKey="recordID" paginatorPosition="both" sortField="name" [scrollable]="true">
            <ng-template let-columns pTemplate="header">
                <tr>
                    <th   *ngFor="let col of columns" [ngSwitch]="col.field" [pSortableColumn]="col.field" 
                    [style.width]="col.width">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                    </th>
                    <!-- <th style="width: 50px"></th> -->

                    <th style="width: 50px"></th>
                </tr>
            </ng-template>
            <!-- RC-459 (MG 16 JAN 2020): modified -->
            <ng-template let-columns="columns" let-rowData pTemplate="body" let-rowIndex="rowIndex">
                <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="isDisabledTableRow"
                    [ngClass]="isDisabledTableRow ? 'disabled-row' : ''">
                    <td *ngFor="let col of columns"
                        (click)="(isOnOrder(rowData)) ? removePromotionFromOrder(rowData, rowIndex) : addPromotionToOrder(rowData, rowIndex)"
                        style="cursor: pointer;" [style.width]="col.width">
                        <div [ngSwitch]="col.field">
                            <div *ngSwitchCase="'startDate'" class="center">{{rowData[col.field] | rtDate}}
                            </div>
                            <div *ngSwitchCase="'endDate'" class="center">{{rowData[col.field] | rtDate}}
                            </div>
                            <div *ngSwitchCase="'amountToMeet'" class="center">{{rowData[col.field] | rtCurrency}}
                            </div>
                            <div *ngSwitchCase="'name'" class="center">
                                {{ rowData[col.field] }}
                                <!-- <span class="pull-right" *ngIf="!isOrderAmountMeet(rowData)" [pTooltip]="isOrderAmountMeet(rowData, true)"
                                    style="background-color: #2c83c4; color: white; cursor: pointer;padding: 3px 10px;border-radius: 25px;">
                                    <i class="fa fa-info" aria-hidden="true"></i>
                                </span> -->
                            </div>
                            <div *ngSwitchCase="'discount'" class="center">
                                <div *ngIf="rowData['discountPercent']>0">
                                    {{rowData['discountPercent'] / 100 | percent}}
                                </div>
                                <div *ngIf="rowData['discountAmount']>0">
                                    {{rowData['discountAmount'] | rtCurrency}}
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                        </div>
                    </td>
                    <td class="center" style="width:50px;">
                        <div *ngIf="isOnOrder(rowData) && !promotionLoader[rowIndex]">
                            <a (click)="removePromotionFromOrder(rowData, rowIndex)"
                                title="Remove Promotion From Order"><i class="fa fa-remove FsRed"></i></a>
                        </div>
                        <div *ngIf="!isOnOrder(rowData) && !promotionLoader[rowIndex]">
                            <a (click)="addPromotionToOrder(rowData, rowIndex)"
                                [title]="isOrderAmountMeet(rowData) ? 'Add Promotion To Order' : 'Please add more items to avail this promotion' ">
                                <i
                                    [class]="'fas fa-' + (isOrderAmountMeet(rowData) ? 'plus FsGreen' : 'ban FsRed')"></i>
                            </a>
                        </div>
                        <div *ngIf="promotionLoader[rowIndex]" class="loader-icon "></div>
                    </td>
                </tr>
            </ng-template>
            <!-- RC-459 (MG 16 JAN 2020): modified -->
            <ng-template pTemplate="emptymessage">
                <tr class="table-empty">
                    <td [attr.colspan]="promotionCols.length + 3">{{ emptyPromotionMsg }}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>
    <p-dialog (onHide)="hideDialog(displayEmailDialog)" [(visible)]="displayEmailDialog" [closable]="true"
        [closeOnEscape]="true" [header]="'Email Order'" [maximizable]="false" [positionTop]="40" [blockScroll]="true"
        [resizable]="false">
        <p-card [style]="{width: '360px'}" [subheader]="orderArr[activeIndex].retailer.email" header="Email To Customer"
            styleClass="ui-card-shadow">
            <button (click)="sendOrder('pdf', orderArr[activeIndex].retailer.email)" [label]="'Send PDF'"
                class="ui-button-raised" icon="fa fa-file-pdf-o" pButton style="margin: 3px;" type="button"></button>
            <button (click)="sendOrder('csv', orderArr[activeIndex].retailer.email)" [label]="'Send CSV'"
                class="ui-button-raised" icon="fa fa-file-excel-o" pButton style="margin: 3px;" type="button"></button>
        </p-card>
        <p-card *ngIf="orderArr[activeIndex].retailerContact" [style]="{width: '360px'}"
            [subheader]="orderArr[activeIndex].retailerContact.email" header="Send To Contact"
            styleClass="ui-card-shadow">
            <button (click)="sendOrder('pdf', orderArr[activeIndex].retailerContact.email)" [label]="'Send PDF'"
                class="ui-button-raised" icon="fa fa-file-pdf-o" pButton style="margin: 3px;" type="button"></button>
            <button (click)="sendOrder('csv', orderArr[activeIndex].retailerContact.email)" [label]="'Send CSV'"
                class="ui-button-raised" icon="fa fa-file-excel-o" pButton style="margin: 3px;" type="button"></button>
        </p-card>
        <p-card *ngIf="orderArr[activeIndex].salesperson1" [style]="{width: '360px'}"
            [subheader]="salespersonToEmail.email" header="Email To Salesperson" styleClass="ui-card-shadow">
            <p-dropdown [(ngModel)]="salespersonToEmail" [options]="getOrderSalespeople()" appendTo="body"></p-dropdown>
            <button (click)="sendOrder('pdf', salespersonToEmail.email)" [label]="'Send PDF'" class="ui-button-raised"
                icon="fa fa-file-pdf-o" pButton style="margin: 3px;" type="button"></button>
            <button (click)="sendOrder('csv', salespersonToEmail.email)" [label]="'Send CSV'" class="ui-button-raised"
                icon="fa fa-file-excel-o" pButton style="margin: 3px;" type="button"></button>
        </p-card>
    </p-dialog>
    <div *ngIf="displayCarrierAccountDialog">
        <!-- RC-401 (RK 08 APRIL 2020) Add carrier-account popup -->
        <app-edit-carrier-account-dialog (closeEvent)="onCarrierAccountsDialogClose()"
            (saveEvent)="onCarrierAccountsCreated($event)" [selectedCarrierAccount]="selectedCarrierAccount"
            [shipObjMatch]="shipObjMatch" [activeOrderObj]="orderArr[this.activeIndex]"
            [retailerID]="orderArr[activeIndex].retailer.recordID"
            [displayCarrierAccountDialog]="displayCarrierAccountDialog" [openFrom]="'FINALIZE'"
            (OnCloseDialog)="closeDialogShippingMethod($event)">
        </app-edit-carrier-account-dialog>
    </div>
    <div *ngIf="displayPaymentInfoDialog">
        <!-- RC-444 (RK 16 JAN 2020) Add onPaymentType emit event -->
        <rs-add-payment-info-dialog [orderID]="orderArr[activeIndex].recordID" [orderObj]="orderArr[activeIndex]"
            [isOrderPayment]="true" [(isOpen)]="displayPaymentInfoDialog"
            [repGroupID]="orderArr[activeIndex].repGroup.recordID"
            [selectedPaymentTerm]="orderArr[activeIndex].paymentTerm"
            (savePTEvent)="onPaymentType($event, orderArr[activeIndex])" (saveEvent)="onSaveCreditCardInfo($event)"
            [fRetailerID]="orderArr[activeIndex].retailer.recordID" (OnCloseDialog)="closeDialogPaymentInfo($event)">
        </rs-add-payment-info-dialog>
    </div>
    <rs-discount-dialog (hideEvent)="hideDiscountDialog()" (saveEvent)="onDiscountPercentSave($event)"
        *ngIf="orderArr[activeIndex]" [displayNumberDialog]="displayDiscountPercentDialog" [header]="'Edit Discount'"
        [instructions]="'Enter the discount for your order'" [label]="'Discount(%)'" [order]="orderArr[activeIndex]">
    </rs-discount-dialog>
    <!--<p-dialog header="Order Code Error" [draggable]="false"
              [(visible)]="displayError">
        <h4 *ngIf="this.displayShipViaError">{{this.orderCodeErrorMsg}}</h4>
        <h4 *ngIf="this.displayPaymentTermError">{{this.paymentTermErrorMsg}}</h4>
        <h4 *ngIf="this.displayShipViaError">{{this.shipViaErrorMsg}}</h4>
        <a [label]="'Ok'" [routerLink]="['/' + urlPrefix + '/orders']"
           class="ui-btn  ui-button-primary ui-button FRight"
           pButton type="button"></a>
    </p-dialog>-->
</div>
<app-item-view-model [activeViewIndex]="activeViewIndex" [activeViewItem]="this.activeViewItem"
    [itemMfgIDs]="itemMfgIDs" [manufacturersArr]="manufacturersArr" [selectedCustomer]="this.selectedCustomer"
    [existCartData]="existCartData" [fromPage]="fromPage" (addToCartEvent)="addToCartEvent($event)"
    [(isOpen)]="displayItemViewDialog" *ngIf="displayItemViewDialog && editable">
</app-item-view-model>

<p-dialog header="Duplicate Order" [(visible)]="displayOrderDuplicate" [closable]="true" [draggable]="false"
    [blockScroll]="true" [closeOnEscape]="true" [showHeader]="true" [style]="{'max-width': '650px','width': '96%'}"
    [positionTop]="60" [maximizable]="false" [modal]="true"
    [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'"
    class="responsive-ship-loc cut-dialog-height add-cat-dialog dup-order-dialog" [resizable]="false">
    <form (ngSubmit)="saveDuplicateOrder()" [formGroup]="orderDuplicateForm">
        <!-- <div class="p-col-12"> -->

        <div class="p-col-4" style="float: left;">
            <label for="">Contact: <br><b
                    class="res-reorder-fval">{{selectedOrderToDuplicate?.retailerContact?.firstName}}
                    {{selectedOrderToDuplicate?.retailerContact?.middleName}}
                    {{selectedOrderToDuplicate?.retailerContact?.lastName}}</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">BillTo: <br><b class="res-reorder-fval">{{ selectedOrderToDuplicate?.billToName }}</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">ShipTo: <br><b class="res-reorder-fval">{{ selectedOrderToDuplicate?.shipToName }}</b></label>
        </div>
        <div style="clear: both;"></div>
        <div class="p-col-4" style="float: left;">
            <label for="">Salesperson:
                <br><b class="res-reorder-fval">{{selectedOrderToDuplicate?.salesperson1 ?
                    selectedOrderToDuplicate?.salesperson1?.name :
                    'N/A'}}</b></label>
        </div>


        <!-- {{selectedOrderToDuplicate?.}} -->

        <div class="p-col-4" style="float: left;">
            <label for="">PO Number: <br>
                <input id="accountNoDeactiveDup" type="text" (input)="updateDuplicateChangesMade()"
                    formControlName="selectedOrderToDuplicatePoNumber" pInputText class="wid-100 h-30">
            </label>
            <!-- <label for="">PO Number: <br><b>{{selectedOrderToDuplicate?.poNumber}}-D</b></label> -->
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Order Total:
                <br><b class="res-reorder-fval">{{ (selectedOrderToDuplicate?.orderGrossTotal -
                    (selectedOrderToDuplicate?.orderDiscount / 100 *
                    selectedOrderToDuplicate?.orderGrossTotal)) | rtCurrency }}</b></label>
        </div>
        <div style="clear: both;"></div>
        <div class="p-col-4" style="float: left;">
            <label for="">Discount:
                <br><b class="res-reorder-fval">{{(selectedOrderToDuplicate?.orderDiscount) ?
                    selectedOrderToDuplicate?.orderDiscount :
                    '0'}}%</b></label>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for=""># of Items: <br><b
                    class="res-reorder-fval">{{selectedOrderToDuplicate?.orderDetails?.length}}</b></label>
        </div>



        <div class="p-col-4" style="float: left;">
            <label for="">Order Code:
                <br><b class="res-reorder-fval">{{(selectedOrderToDuplicate?.orderCode) ?
                    selectedOrderToDuplicate?.orderCode :
                    'N/A'}}</b></label>
        </div>
        <!-- <div class="p-col-4" style="float: left;" >
                <label for="">Ship Method: <br><b>{{(selectedOrderToDuplicate?.shippingMethod) ? selectedOrderToDuplicate?.shippingMethod : 'N/A'}}</b></label>
            </div>
            <div class="p-col-4" style="float: left;" >
                <label for="">Payment Method: <br><b>{{(selectedOrderToDuplicate?.paymentTerm) ? selectedOrderToDuplicate?.paymentTerm : 'N/A'}}</b></label>
            </div> -->

        <div style="clear: both;"></div>

        <div class="p-col-4" style="float: left;">
            <label for="">Order Date*</label>
            <rs-calendar (dateChange)="updateDuplicateChangesMade($event, 'orderDateD')" id="orderDateD"
                formControlName="orderDateD" [required]="true"></rs-calendar>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Request Ship Date*</label>
            <rs-calendar (dateChange)="updateDuplicateChangesMade($event, 'shipDateD')" id="shipDateD"
                formControlName="shipDateD" [required]="true" [minDate]="orderDuplicateForm.value.orderDateD">
            </rs-calendar>
        </div>
        <div class="p-col-4" style="float: left;">
            <label for="">Cancel Date</label>
            <rs-calendar (dateChange)="updateDuplicateChangesMade($event, 'cancelDateD')" formControlName="cancelDateD"
                id="cancelDateD" [minDate]="orderDuplicateForm.value.shipDateD"></rs-calendar>
        </div>
        <!-- <div class="p-col-6" style="float: left;">
                <label for="">Order Code*</label> <br />
                <p-dropdown required="true" placeholder="Select a Code"></p-dropdown>
            </div> -->
        <!-- </div> -->
        <!-- (dateChange)="datesUpdate(order, 'ORDER_DATE')" [disabled]="!editable" [maxDate]="dateConvert(re.limitedOrderDate)" id="orderDate{{i}}" [(date)]="order.orderDate" -->
        <div style="clear: both"></div>
        <br /><br />
        <div class="" style="text-align: right;">

            <p-button type="submit" [styleClass]="'ui-button-success theme-maroon-btn'" label="Duplicate Order"
                [disabled]="!isValidateDuplicate" [icon]="isSavingData ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-save'">
            </p-button>
            <!-- <button pButton icon="fa fa-save" class="theme-maroon-btn" label="Duplicate Order" [disabled]="!isValidateDuplicate"  type="submit" ></button> -->
            <!-- <app-async-button [icon]="'fas fa-save'" [disabled]="!isValidateDuplicate" [loading]="isSavingData"
            type="submit" class="ui-button ui-button-success" [label]="'Duplicate Order'"></app-async-button> -->
        </div>
    </form>
</p-dialog>

<RtCustom-confirmDialog key="breckincrement5" #breckincrement5 header="Drop database" acceptLabel="Yes" rejectLabel="No"
    styleClass="test">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" id="YesButton" label="Yes"
            (click)="breckincrement5.accept()"></button>
        <button type="button" class="ui-button-secondary" pButton icon="pi pi-times" label="No"
            (click)="breckincrement5.close($event)"></button>
    </p-footer>
</RtCustom-confirmDialog>
<p-confirmDialog key="warn-mfr-details" [style]="{'width':'90%', 'max-width':'600px'}" icon="pi pi-exclamation-triangle"
    appendTo="body" [baseZIndex]="10000"></p-confirmDialog>


<p-dialog [(visible)]="visibleprintpreview" styleClass="print-dialog" [modal]="true" appendTo="body" [closable]="false"
    [closeOnEscape]="false" [positionTop]="'center'" [blockScroll]="true" [showHeader]="false">
    <app-print-preview-dialog [(request)]="pdfSrc" [screen]="'finalize'" [sortorder]="sortorder"
        [(itemwithimage)]="itemwithimage" *ngIf="visibleprintpreview" (close)="visibleprintpreview=false">
    </app-print-preview-dialog>
</p-dialog>

<p-dialog [(visible)]="newMTPayPaymentMethodsPopup" [modal]="true" [closable]="true" [blockScroll]="true"
    [responsive]="true" [style]="{'max-width': '100%', 'width': '600px', 'padding-left': '10px', 'padding-right': '10px'}" [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'"
    class="responsive-mt-pay-loc res-edit-mt-pay" [showHeader]="true"
    header="Select your {{ (newMTPaySelectedPaymentMethods?.value === 1) ? 'bank detail' : 'card' }}"
    (onShow)="openPaymentMethodPopup()" (onHide)="closePaymentMethodPopup()">

    <div *ngIf="newMTPayPaymentMethodsPopup && newMTPaySelectedPaymentMethods?.value === 1">
        <!-- Bank -->

        <div class="p-grid">
            <div class="p-col-12 pt-20">
                <div class="pos-rel">
                    <img src="{{newMTPaySelectedPaymentMethods?.icon}}" class="placeholder-icon" />
                    <p-dropdown (onChange)="mtPaySelectBankDetail($event)" [filter]="false"
                        [(ngModel)]="selectedBankDetailModel" appendTo="body" [options]="newMTPayBankDetails"
                        class="control-dropdown mt-10 card-dd" [style]="{'width':'100px'}" placeholder="Select a bank">
                        <ng-template let-item pTemplate="selectedItem">
                            <span style="vertical-align:middle; margin-left: .5em">{{item.label}}
                            </span>
                        </ng-template>
                        <ng-template let-car pTemplate="item">
                            <div class="ui-helper-clearfix d-flex" *ngIf="car.value" style="position: relative;">
                                <div style="font-size:14px;margin-top:4px">{{car.label}}
                                </div>
                            </div>
                            <div style="font-size:14px;" *ngIf="!car.value" style="position: relative">
                                <button  class="ui-button ui-button-info btn-theme-color"
                                    pButton icon="fas fa-plus-circle" title="Add New Bank Details"></button>
                                    <span style="margin-left: 5px;">Add New Bank Details</span>
                            </div>
                        </ng-template>

                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="ui-g d-flex justify-content-center" style="text-align: center;" *ngIf="mtpayShowBankConnectionLoading">
            <div class="reptime-table-loader" >
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="2"></p-progressSpinner>
                <p>{{mtpayShowBankDataFetching ? 'Bank Data Fetching...' : 'Loading Data...'}}</p>
            </div>
        </div>
        <div *ngIf="!selectedBankDetailModel && !mtpayShowBankConnectionLoading">
            <div class="p-grid bank-text">
                Please select your bank account
            </div>
        </div>
        <div *ngIf="selectedBankDetailModel && !mtpayShowBankConnectionLoading">
            <div class="p-grid">
                <div class="p-col-6">
                    <label for="companyName">Account Name</label>
                    <div class="bank-detail-value">{{selectedBankDetailModel?.accountName ? selectedBankDetailModel.accountName : 'N/A'}}</div>
                </div>

                <div class="p-col-6">
                    <label for="ein">Institution Name</label>
                    <div class="bank-detail-value">{{selectedBankDetailModel?.institutionName ?  selectedBankDetailModel.institutionName : 'N/A'}}</div>
                </div>

                <div class="p-col-12">
                    <div class="d-flex align-items-center justify-content-end res-chk-list">
                        <button type="submit"
                            class="ui-button ui-button-info ml-10 btn-theme-color"
                            pButton [disabled]="bankDetailApplyloading" (click)="onPaymentMethodChangesConfirmation('cc')"
                            [icon]="bankDetailApplyloading ? 'pi pi-spin pi-spinner' : 'fas fa-check'" [label]="mtPayPaymentDeclined ? 'Update' : 'Apply'"
                            title="Apply"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="newMTPayPaymentMethodsPopup && newMTPaySelectedPaymentMethods?.value === 2">
        <!-- Credit Card -->

        <div class="p-grid">
            <div class="p-col-12 pt-20">
                <div class="pos-rel">
                    <img src="{{newMTPaySelectedPaymentMethods?.icon}}" class="placeholder-icon" />
                    <p-dropdown (onChange)="mtPaySelectCreditCardDD($event)" [filter]="false"
                        [(ngModel)]="seletedCreditCard" appendTo="body" [options]="newMTPayCreditCardDetails"
                        class="control-dropdown mt-10 card-dd" panelStyleClass="card-new-btn" placeholder="Select a card">
                        <ng-template let-item pTemplate="selectedItem">
                            <span style="vertical-align:middle; margin-left: .5em" *ngIf="item.value">{{item.value.brand}} * {{item.label}} 
                                <b>{{item.value.expiredMonth}}/{{item.value.expiredYear}}</b></span>
                            <span style="vertical-align:middle; margin-left: .5em" *ngIf="!item.value">{{item.label}} </span>
                        </ng-template>
                        <ng-template let-car pTemplate="item">
                            <div class="ui-helper-clearfix d-flex" style="position: relative">
                                <div style="font-size:14px;margin-top:4px" *ngIf="car.value">{{car.value.brand}} * {{car.label}}
                                    <b>{{car.value.expiredMonth}}/{{car.value.expiredYear}}</b>
                                </div>
                                <div style="font-size:14px;" *ngIf="!car.value" style="position: relative">
                                    <button  class="ui-button ui-button-info btn-theme-color"
                                        pButton icon="fas fa-plus-circle" title="Add New Card"></button>
                                        <span style="margin-left: 5px;">Add New Card</span>
                                </div>
                            </div>
                        </ng-template>

                    </p-dropdown>
                </div>
            </div>
        </div>

        <form [formGroup]="fbCreditCard" (submit)="onPaymentMethodChangesConfirmation('bank')" autocomplete="off">

            <div class="p-grid">

                <div class="p-col-12 p-md-6" *ngIf=" (newMTPaygetBuyerRegisterData | json) === '{}'">
                    <label for="email">Email Id*</label>
                    <div class="input-error-container">
                        <input class="control" formControlName="email" id="email" maxlength="50" pInputText type="text" (focusout)="onFocusOutEvent()" />
                        <i *ngIf="validateChk.email.invalid && (validateChk.email.dirty || validateChk.email.touched)"
                            class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.email,'Email')"
                            tooltipPosition="left" tooltipEvent="hover"></i>
                    </div>
                </div>

                <div class="p-col-12 p-md-6">
                    <label for="cardNumber">Card Number*</label>
                    <div class="input-error-container">
                        <p-inputMask styleClass="control" [unmask]="true"  formControlName="cardNumber" id="cardNumber" maxlength="50"
                            type="text" mask="?9999-9999-9999-9999"></p-inputMask>
                        <i *ngIf="validateChk.cardNumber.invalid && (validateChk.cardNumber.dirty || validateChk.cardNumber.touched)"
                            class="error-icon pi pi-info-circle"
                            [pTooltip]="getErrorMsg(validateChk.cardNumber,'Credit Card')" tooltipPosition="left"
                            tooltipEvent="hover"></i>
                    </div>
                </div>

                <div class="p-col-12 p-md-6" *ngIf=" (newMTPaygetBuyerRegisterData | json) === '{}' || clickOnAddNewCard">
                    <label for="cardCVC">CVC*</label>
                    <div class="input-error-container">
                        <input class="control" formControlName="cardCVC" id="cardCVC" maxlength="50" pInputText
                            type="text" />
                        <i *ngIf="validateChk.cardCVC.invalid && (validateChk.cardCVC.dirty || validateChk.cardCVC.touched)"
                            class="error-icon pi pi-info-circle" [pTooltip]="getErrorMsg(validateChk.cardCVC,'CVC')"
                            tooltipPosition="left" tooltipEvent="hover"></i>
                    </div>
                </div>


                <div class="p-col-12 p-md-6">
                    <label>Expiration Date*</label>
                    <div class="d-flex res-exp-date">
                        <div class="input-error-container d-inline-block p-col-6"
                            style="padding: 0px;padding-right: 0.5em;">
                            <p-dropdown formControlName="cardMonth" title="Expiration Month" name="cardMonth"
                                class="control-dropdown" appendTo="body" [options]="monthOptions"
                                [style]="{'width':'100%'}">
                            </p-dropdown>
                            <i class="error-icon pi pi-info-circle multiSelect-error"
                                *ngIf="validateChk.cardMonth.invalid && (validateChk.cardMonth.dirty || validateChk.cardMonth.touched)"
                                [pTooltip]="getErrorMsg(validateChk.cardMonth,'Expiration Month')"
                                tooltipPosition="left" tooltipEvent="hover"></i>
                        </div>
                        <div class="input-error-container d-inline-block p-col-6"
                            style="padding: 0px;padding-left: 0.5em;">
                            <p-dropdown formControlName="cardYear" title="Expiration Year" class="control-dropdown"
                                autocomplete="off" appendTo="body" name="cardYear" [options]="yearOptions"
                                [style]="{'width':'100%'}"></p-dropdown>
                            <i class="error-icon pi pi-info-circle multiSelect-error"
                                *ngIf="validateChk.cardYear.invalid && (validateChk.cardYear.dirty || validateChk.cardYear.touched)"
                                [pTooltip]="getErrorMsg(validateChk.cardYear,'Expiration Year')" tooltipPosition="left"
                                tooltipEvent="hover"></i>
                        </div>
                    </div>
                </div>

                <div class="p-col-12">
                    <div class="d-flex align-items-center flex-wrap res-chk-list" style="margin-top: -5px;">
                        <div class="category-filter position-relative mr-auto pr-10 mt-5" style="margin-right: auto;"  #attachHere>
                            <a class="btn-theme-color ui-button-info" label="Load Previous card(s)"  *ngIf="cardPresent"
                                icon="fa fa-credit-card" (click)="category_content.toggle($event)" pButton
                                style="position: relative;"></a>
                            <i class="fa fa-spinner fa-spin icon-loader" *ngIf="cardInfoView"></i>
                            <p-overlayPanel #category_content [styleClass]="'card-other-info-sec'" 
                                [dismissable]="true" [appendTo]="'body'">
                                <div class="load-card-panel">
                                <div class="card-info" *ngFor="let paymentTerms of retailerPaymentTerms; let i = index" [innerHtml]="paymentTerms.label" (click)="getCradInfo(paymentTerms.value,category_content,$event)"></div>
                                </div>
                            </p-overlayPanel>
                        </div>
                        <button type="submit"
                            class="ui-button ui-button-info btn-theme-color mt-5"
                            pButton [disabled]="(creditCardApplyloading || mtpayBuyeremailcCheckLoading)"
                            [icon]="(creditCardApplyloading) ? 'pi pi-spin pi-spinner' : 'fas fa-check'" [label]="applyOrSaveBtnLabel"></button>
                    </div>
                </div>

            </div>
        </form>
    </div>


</p-dialog>

<p-dialog header="mtPay Retailer OnBoarding" [(visible)]="showGetBalancePopup" [closable]="true" [draggable]="false"
    [blockScroll]="true" [closeOnEscape]="true" [showHeader]="true" [style]="{'width': '650px'}" [positionTop]="60"
    [maximizable]="false" [modal]="true" [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'" [contentStyle]="{'overflow':'auto'}"
    class="responsive-ship-loc cut-dialog-height add-cat-dialog dup-order-dialog" (onHide)="hideRegistrationgPopup()" [resizable]="false">
    <app-retailer-onboarding *ngIf="showGetBalancePopup" [getIntegrationData]="mtpaygetIntegrationData" [selectedRetailer]="orderArr[activeIndex]?.retailer"
        [getBuyerDetailsData]="newMTPaygetBuyerRegisterData" [getUserIntegrationData]="newMTPayretailercompanyIntegrations" [activeIndex]="newMTPayretailerStepFrom"
        (skipDialog)="eventSkipDialog($event)" (loadBackgroundServices)="loadBackgroundServicesOnSuccess($event)" [fromPage]="'finalize-order'"></app-retailer-onboarding>
</p-dialog>
<p-dialog [(visible)]="mtpayShowBankConnectionDialog" [baseZIndex]="10001" [closeOnEscape]="false"
    [contentStyle]="{'width': '500px', 'overflow': 'hidden'}" header="Link your bank with plaid"
    modal="true" [maximizable]="false" (onHide)="onHideBankConnectionDialog($event)" [blockScroll]="true" [closeOnEscape]="true" [resizable]="false" styleClass="resp-bill-info overflow-auto showQualificationBankDialogClass" [appendTo]="'body'">
    <div class="reptime-theme">
        <iframe style="width: 100%;height: calc(100vh - 190px);border-width: 0px;" [src]="mtpaylinkBankIframeSrc"></iframe>
    </div>
</p-dialog>
<p-confirmDialog key="warn-transaction" [style]="{'width':'90%', 'max-width':'600px'}" icon="pi pi-exclamation-triangle"
    appendTo="body" [baseZIndex]="10000"></p-confirmDialog>