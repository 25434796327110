import { Component, Input, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RetailerService } from '../../services/api/retailer.service';
import { APIResponse } from '../../models/api-response';
import { CarrierAccount } from '../../models/carrier-account';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/middleware/auth/auth.service';
@Component({
  selector: 'app-carrier-accounts-table',
  templateUrl: './carrier-accounts-table.component.html',
  styleUrls: ['./carrier-accounts-table.component.scss']
})
export class CarrierAccountsTableComponent implements OnInit, OnDestroy {
  selectedCarrierAccount = new CarrierAccount();
  displayCarrierAccountDialog: boolean;
  cols: any[];
  checkAllLabel = 'Select All';
  @Input() retailerID: string;
  carrierAccounts: any[];
  selectedCarrierAccountArray: CarrierAccount[];
  totalRecords: any;
  loading = true;
  private readonly onDestroy = new Subject<void>();

  constructor(
    private msgService: MessageService,
    private retailerService: RetailerService,
    private confirmationService: ConfirmationService,
    public auth: AuthService,
  ) {

  }
  ngOnInit() {
    this.cols = [
      { field: 'shippingMethod', header: 'Shipping Method', width: '200px', spinner: true, sortField: 'shippingMethod' },
      { field: 'accountHolderName', header: 'Account Holder Name', width: '200px', spinner: true, sortField: 'accountHolderName'  },
      { field: 'accountNumber', header: 'Account Number', width: '200px', spinner: true , sortField: 'accountNumber' }
    ];
    this.loadCarrierAccountsLazy();
  }
  ngOnDestroy() {
    this.onDestroy.next();
  }
  onCarrierAccountsDialogClose() {
    this.displayCarrierAccountDialog = false;
  }

  addNewCarrierAccount() {
    this.selectedCarrierAccount = new CarrierAccount();
    this.selectedCarrierAccount.retailerID = this.retailerID;
    this.showNewCarrieAccountDialog();
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete ' +
        this.selectedCarrierAccountArray.length + ' carrierAccount' +
        (this.selectedCarrierAccountArray.length === 1 ? '' : 's') + '?',
      header: 'Delete Selected',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.deleteSelectedCarrierAccounts();
      },
      reject: () => {
      },
      key: 'carrierDialog'
    });
  }

  private deleteSelectedCarrierAccounts() {
    this.selectedCarrierAccountArray.forEach(s => {
      console.log('s', s);
      s.recordDeleted = true;
      this.selectedCarrierAccount = s;
      this.deleteSelectedCarrierAccount(this.selectedCarrierAccount);
    });
  }


  loadCarrierAccountsLazy() {
    this.loading = true;
    this.retailerService.getRetailerCarrierAcccount(this.retailerID).pipe(takeUntil(this.onDestroy)).subscribe((response: APIResponse) => {
      if (response.success) {
        this.loading = false;
        this.carrierAccounts = response.data;
        this.totalRecords = response.total;
      } else {
        this.loading = false;
      }
    }, () => {
      this.loading = false;
    });
  }

  onCarrierAccountsCreated($event) {
    if (!this.selectedCarrierAccount.recordID) {
      this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Carrier account Saved' });
      this.carrierAccounts.push($event.data);
    } else {
      this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Carrier account Saved' });
      this.carrierAccounts[this.carrierAccounts.indexOf(this.carrierAccounts)] = $event.data;
    }
    this.onCarrierAccountsDialogClose();
  }

  editCarrierAccount($event: MouseEvent, rowData) {
    this.selectedCarrierAccount = rowData;
    this.showNewCarrieAccountDialog();
  }

  showNewCarrieAccountDialog() {
    this.displayCarrierAccountDialog = true;
  }

  removeCarrierAccount($event, rowData) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete confirmation',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.selectedCarrierAccount = rowData;
        this.selectedCarrierAccount.recordDeleted = true;
        this.deleteSelectedCarrierAccount(this.selectedCarrierAccount);
      },
      reject: () => {
      },
      key: 'carrierDialog'
    }
    );
  }

  private deleteSelectedCarrierAccount(selectedCarrierAccount) {
    this.loading = true;
    this.retailerService.saveRetailerCarrierAccounts(selectedCarrierAccount, this.retailerID).subscribe((data: any) => {
      if (data) {
        this.msgService.clear();
        if (selectedCarrierAccount.recordDeleted === true) {
          this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Carrier Account Deleted' });
          this.loading = false;
          this.carrierAccounts.splice(this.carrierAccounts.indexOf(selectedCarrierAccount), 1);
          const index = this.selectedCarrierAccountArray.indexOf(selectedCarrierAccount);
          if (index !== -1) {
            this.selectedCarrierAccountArray.splice(this.selectedCarrierAccountArray.indexOf(selectedCarrierAccount), 1);
          }
        }
        this.onCarrierAccountsDialogClose();
      } else {
        this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error saving carrier account' });
        this.loading = false;
      }
    });

  }

  // RC-783 (MJ 29-APRIL-2020) Added for get retailerID on prev and next button
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes>>', changes);
    if (changes.retailerID.currentValue !== changes.retailerID.previousValue && changes.retailerID.previousValue !== undefined) {
      this.loadCarrierAccountsLazy();
    }
  }
  // END


}
