<div class="header-height" [ngStyle]="{'height': stickyTop?stickyTop.offsetHeight+'px' : headerHeight}"></div>
<div class="container-fluid p-0 shadow-sm top-nav bg-white" id="b2bheader" [ngClass]="loggedInUser || isIframeWindow ? 'login-user' : 'logout-user'">
    <div class="container">
        <div class="ui-g b2b-header-bar">
            <!-- B2B-222 (NL 17-APR-2020) -->
            <div class="mob-bar-menu" *ngIf="loggedInUser && !notApprovedByRepGroup || isIframeWindow">
                <a href="javascript:;" (click)="mobmenu.toggle($event)">
                    <i class="fas fa-bars"></i>
                </a>
                <p-menu #mobmenu [popup]="true" [model]="mobMenuList" styleClass="m-bar-menu"></p-menu>
            </div>
            <div class="mob-spacer" *ngIf="loggedInUser || isIframeWindow"></div>
            <div class="p-col-3 b2b-top-logo">
                <a href="javascript:void(0);" (click)="!isIframeWindow ? allBtnClick($event,null,null, null): ''">
                    <!-- <a href="javascript:void(0);" [routerLink]="auth.getIDToken() ? '/home' : '/'"> -->
                    <img class="logo logo-auto" [src]="logo" alt="{{domainData.domainName}}"
                        onError="this.src='https://via.placeholder.com/125x50?text=NO LOGO';" />
                </a>
            </div>
            <div class="mob-spacer"></div>
            <!-- // B2B-53 (MG 04-FEB-2020): modified to save filtered documents -->
            <!-- // B2B-71 (NL 11-FEB-2020): Add Promotions button and  "Catalogs" instead of "Document List" Button -->
            <!-- // [B2B-227] (MG 03-APR-2020) modified to make custom button dynamic -->
            <!-- // [B2B-241] (MG 14-MAY-2020) modified to make custom shop url -->
            <!-- // [B2B-270] (SG 24-JAN-2020) Added two button shop by brand & shop by items -->
            <div class="p-col-9 b2b-top-menu" [ngClass]="{'with-currency-menu': auth.getshowDiscountTickerButtonFlag() && loggedInUser && !notApprovedByRepGroup || isIframeWindow}">
                <p-menubar [styleClass]="'nav-bar'" [model]="items" class="dyn-menu icon-pointer-event-none">
                    <!-- <button pButton class="'ui-button-info my-3 mr-7 btn-theme-color'" icon="fa fa-heart" label="Like"
                               *ngIf="loggedInUser" (click)="likeListDialog()"></button>      -->

                    <!-- default promotion button -->
                    <p-button
                        *ngIf="auth.getshowPromotionsButtonFlag() && auth.getdefaultPromotionButtonFlag() && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && auth.getdefaultPromotionButtonFlag())"
                        [disabled]="(isIframeWindow && auth.getdefaultPromotionButtonFlag())" icon="fas fa-bullhorn"
                        label="Promotions" (click)="!isIframeWindow ? allBtnClick($event, null, null, null) : ''"
                        [styleClass]="'ui-button-info my-3 mr-7 btn-theme-color'"></p-button>

                    <!-- custom promotion button -->
                    <p-button
                        *ngIf="auth.getshowPromotionsButtonFlag() && (!auth.getdefaultPromotionButtonFlag()) && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && !auth.getdefaultPromotionButtonFlag())"
                        [disabled]="(isIframeWindow && !auth.getdefaultPromotionButtonFlag())"
                        [icon]="'fas ' + auth.getCustomPromotionButtonData()['iconClass']"
                        [label]="auth.getCustomPromotionButtonData()['text']"
                        [styleClass]="'ui-button-info my-3 mr-7 btn-theme-color'"
                        (click)="!isIframeWindow ? allBtnClick($event,auth.getCustomPromotionButtonData(), 'promotion', null) : ''">
                    </p-button>

                    <!-- default catalog button -->
                    <p-button
                        *ngIf="auth.getdefaultCatalogButtonFlag() && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && auth.getdefaultCatalogButtonFlag())"
                        icon="fas fa-swatchbook" label="Catalogs"
                        (click)="!isIframeWindow ? allBtnClick($event, null, null, null) : ''"
                        [styleClass]="'ui-button-info my-3 mr-7 btn-theme-color'"
                        [disabled]="(isIframeWindow && auth.getdefaultCatalogButtonFlag())"></p-button>

                    <!-- custom catalog button -->
                    <p-button
                        *ngIf="(!auth.getdefaultCatalogButtonFlag()) && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && !auth.getdefaultCatalogButtonFlag())"
                        [disabled]="(isIframeWindow && !auth.getdefaultCatalogButtonFlag())"
                        [icon]="'fas ' + auth.getCustomCatalogButtonData()['iconClass']"
                        [label]="auth.getCustomCatalogButtonData()['text']"
                        [styleClass]="'ui-button-info my-3 mr-7 btn-theme-color'"
                        (click)="!isIframeWindow ? allBtnClick($event,auth.getCustomCatalogButtonData(), 'catalog', null) : ''">
                    </p-button>

                    <!-- <p-button [styleClass]="'ui-button-success my-3 mr-7 theme-maroon-btn'" label="Shop Now"
                              icon="fa fa-fw fa-pencil-square-o"
                              [routerLink]="'/' + auth.getShopURL() " *ngIf="loggedInUser && !notApprovedByRepGroup"></p-button> -->

                    <!-- default Shop by Brand button -->
                    <p-button [styleClass]="'ui-button-success my-3 mr-7 theme-maroon-btn'" label="Shop by Brand"
                        [disabled]="(isIframeWindow && auth.getdefaultShopbyBrandsButtonFlag())"
                        icon="fa fa-fw fa-shopping-basket"
                        (click)="!isIframeWindow ? allBtnClick($event, null, null, null) : ''"
                        *ngIf="auth.getdefaultShopbyBrandsButtonFlag() && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && auth.getdefaultShopbyBrandsButtonFlag())">
                    </p-button>
                    <!-- default Shop by Brand button -->


                    <!-- custom Shop by Brand button -->
                    <p-button [styleClass]="'ui-button-success my-3 mr-7 theme-maroon-btn'"
                        [label]="auth.getCustomShopbyBrandsButtonData()['text']"
                        [disabled]="(isIframeWindow && !auth.getdefaultShopbyBrandsButtonFlag())"
                        [icon]="'fas ' + auth.getCustomShopbyBrandsButtonData()['iconClass']"
                        (click)="!isIframeWindow ? allBtnClick($event,auth.getCustomShopbyBrandsButtonData(), 'Shop by Brand', null) : ''"
                        *ngIf="(!auth.getdefaultShopbyBrandsButtonFlag()) && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && !auth.getdefaultShopbyBrandsButtonFlag())">
                    </p-button>
                    <!-- custom Shop by Brand button -->

                    <!-- default Shop by Items button -->
                    <p-button [styleClass]="'ui-button-success my-3 mr-7 theme-maroon-btn'" label="Shop by Items"
                        [disabled]="(isIframeWindow && auth.getdefaultShopbyItemButtonFlag())" icon="fa fa-fw fa-tags"
                        (click)="!isIframeWindow ? allBtnClick($event, null, null, null) : ''"
                        *ngIf="auth.getdefaultShopbyItemButtonFlag() && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && auth.getdefaultShopbyItemButtonFlag())">
                    </p-button>
                    <!-- default Shop by Items button -->

                    <!-- custom Shop by Items button -->
                    <p-button [styleClass]="'ui-button-success my-3 mr-7 theme-maroon-btn'"
                        [label]="auth.getCustomShopbyItemButtonData()['text']"
                        [disabled]="(isIframeWindow && !auth.getdefaultShopbyItemButtonFlag())"
                        [icon]="'fas ' + auth.getCustomShopbyItemButtonData()['iconClass']"
                        (click)="!isIframeWindow ? allBtnClick($event,auth.getCustomShopbyItemButtonData(), 'Shop by Items', null) : ''"
                        *ngIf="(!auth.getdefaultShopbyItemButtonFlag()) && loggedInUser && !notApprovedByRepGroup || (isIframeWindow && !auth.getdefaultShopbyItemButtonFlag())">
                    </p-button>
                    <!-- custom Shop by Items button -->

                    <!-- <p-button [styleClass]="'ui-button-success my-3 mr-5 theme-maroon-btn'" label="Quick Order"
                        icon="fa fa-fw fa-pencil-square-o" (click)="allBtnClick($event, null, null, null)"
                        *ngIf="loggedInUser && !notApprovedByRepGroup"></p-button> -->
                </p-menubar>

                <!-- select Address detail -->
                <div class="select-add-main ">
                    <!-- add-active -->
                    <div class="select-add-inner">
                        <div class="select-detail d-flex">
                            <span class="select-label">Address:</span>
                            <span class="select-value">Loc1 - Adajan, surat, tx, 395009</span>
                        </div>
                    </div>
                </div>
                <div class="currency-menu" [ngClass]="isIframeWindow ? 'custom-disable' : ''"
                    *ngIf="auth.getshowDiscountTickerButtonFlag() && loggedInUser && !notApprovedByRepGroup || isIframeWindow">
                    <span class="currency-icon">
                        <!-- <img src="/assets/images/lightovation/map-pin.png" style="width: 24px;"> -->
                        <svg style id="fill-dollar" xmlns="http://www.w3.org/2000/svg" width="35" height="35"
                            viewBox="0 0 35 35">
                            <path id="Path_2746" data-name="Path 2746"
                                d="M29.874,5.126A17.5,17.5,0,0,0,5.126,29.874,17.5,17.5,0,0,0,29.874,5.126ZM17.5,16.406a4.921,4.921,0,0,1,1.345,9.655.343.343,0,0,0-.252.329v.922a1.114,1.114,0,0,1-1.041,1.123,1.094,1.094,0,0,1-1.147-1.093v-.953a.343.343,0,0,0-.251-.329,4.93,4.93,0,0,1-3.577-4.7,1.112,1.112,0,0,1,1.062-1.124,1.094,1.094,0,0,1,1.126,1.093A2.734,2.734,0,1,0,17.5,18.594a4.921,4.921,0,0,1-1.345-9.655.343.343,0,0,0,.252-.329V7.687a1.114,1.114,0,0,1,1.041-1.123,1.094,1.094,0,0,1,1.147,1.093v.953a.343.343,0,0,0,.251.329,4.93,4.93,0,0,1,3.577,4.7,1.112,1.112,0,0,1-1.062,1.124,1.094,1.094,0,0,1-1.126-1.093A2.734,2.734,0,1,0,17.5,16.406Z" />
                        </svg>
                    </span>
                    <div class="currency-dropdown-menu">
                        <div class="saving-sec">
                            <div class="saving-info">
                                <!-- <div class="saving-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25.075"
                                        viewBox="0 0 27 25.075">
                                        <g id="coins" transform="translate(0 -18.25)">
                                            <path id="Path_2694" data-name="Path 2694"
                                                d="M11.18,378.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,381.636,0,379.832V378.25Z"
                                                transform="translate(0 -341.016)" fill="#fea832" />
                                            <path id="Path_2695" data-name="Path 2695"
                                                d="M111.59,378.25v1.582c0,1.8-2.426,3.164-5.59,3.164V378.25Z"
                                                transform="translate(-100.41 -341.016)" fill="#fe9923" />
                                            <path id="Path_2696" data-name="Path 2696"
                                                d="M11.18,348.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,351.636,0,349.832V348.25Z"
                                                transform="translate(0 -312.598)" fill="#fea832" />
                                            <path id="Path_2697" data-name="Path 2697"
                                                d="M111.59,348.25v1.582c0,1.8-2.426,3.164-5.59,3.164V348.25Z"
                                                transform="translate(-100.41 -312.598)" fill="#fe9923" />
                                            <path id="Path_2698" data-name="Path 2698"
                                                d="M11.18,348.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,351.636,0,349.832V348.25Z"
                                                transform="translate(0 -312.598)" fill="#fedb41" />
                                            <path id="Path_2699" data-name="Path 2699"
                                                d="M111.59,348.25v1.582c0,1.8-2.426,3.164-5.59,3.164V348.25Z"
                                                transform="translate(-100.41 -312.598)" fill="#fc3" />
                                            <path id="Path_2700" data-name="Path 2700"
                                                d="M11.18,318.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,321.636,0,319.832V318.25Z"
                                                transform="translate(0 -284.18)" fill="#fea832" />
                                            <path id="Path_2701" data-name="Path 2701"
                                                d="M111.59,318.25v1.582c0,1.8-2.426,3.164-5.59,3.164V318.25Z"
                                                transform="translate(-100.41 -284.18)" fill="#fe9923" />
                                            <path id="Path_2702" data-name="Path 2702"
                                                d="M11.18,288.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,291.636,0,289.832V288.25Z"
                                                transform="translate(0 -255.762)" fill="#fedb41" />
                                            <path id="Path_2703" data-name="Path 2703"
                                                d="M111.59,288.25v1.582c0,1.8-2.426,3.164-5.59,3.164V288.25Z"
                                                transform="translate(-100.41 -255.762)" fill="#fc3" />
                                            <path id="Path_2704" data-name="Path 2704"
                                                d="M11.18,258.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,261.636,0,259.832V258.25Z"
                                                transform="translate(0 -227.344)" fill="#fea832" />
                                            <path id="Path_2705" data-name="Path 2705"
                                                d="M111.59,258.25v1.582c0,1.8-2.426,3.164-5.59,3.164V258.25Z"
                                                transform="translate(-100.41 -227.344)" fill="#fe9923" />
                                            <path id="Path_2706" data-name="Path 2706"
                                                d="M11.18,228.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,231.636,0,229.832V228.25Z"
                                                transform="translate(0 -198.926)" fill="#fedb41" />
                                            <path id="Path_2707" data-name="Path 2707"
                                                d="M111.59,228.25v1.582c0,1.8-2.426,3.164-5.59,3.164V228.25Z"
                                                transform="translate(-100.41 -198.926)" fill="#fc3" />
                                            <path id="Path_2708" data-name="Path 2708"
                                                d="M11.18,198.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,201.636,0,199.832V198.25Z"
                                                transform="translate(0 -170.508)" fill="#fea832" />
                                            <path id="Path_2709" data-name="Path 2709"
                                                d="M111.59,198.25v1.582c0,1.8-2.426,3.164-5.59,3.164V198.25Z"
                                                transform="translate(-100.41 -170.508)" fill="#fe9923" />
                                            <path id="Path_2710" data-name="Path 2710"
                                                d="M11.18,168.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,171.636,0,169.832V168.25Z"
                                                transform="translate(0 -142.09)" fill="#fedb41" />
                                            <path id="Path_2711" data-name="Path 2711"
                                                d="M111.59,168.25v1.582c0,1.8-2.426,3.164-5.59,3.164V168.25Z"
                                                transform="translate(-100.41 -142.09)" fill="#fc3" />
                                            <path id="Path_2712" data-name="Path 2712"
                                                d="M11.18,138.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,141.636,0,139.832V138.25Z"
                                                transform="translate(0 -113.672)" fill="#fea832" />
                                            <path id="Path_2713" data-name="Path 2713"
                                                d="M111.59,138.25v1.582c0,1.8-2.426,3.164-5.59,3.164V138.25Z"
                                                transform="translate(-100.41 -113.672)" fill="#fe9923" />
                                            <path id="Path_2714" data-name="Path 2714"
                                                d="M11.18,108.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,111.636,0,109.832V108.25Z"
                                                transform="translate(0 -85.254)" fill="#fedb41" />
                                            <path id="Path_2715" data-name="Path 2715"
                                                d="M111.59,108.25v1.582c0,1.8-2.426,3.164-5.59,3.164V108.25Z"
                                                transform="translate(-100.41 -85.254)" fill="#fc3" />
                                            <path id="Path_2716" data-name="Path 2716"
                                                d="M11.18,78.25v1.582c0,1.8-2.426,3.164-5.59,3.164S0,81.636,0,79.832V78.25Z"
                                                transform="translate(0 -56.836)" fill="#fea832" />
                                            <path id="Path_2717" data-name="Path 2717"
                                                d="M111.59,78.25v1.582c0,1.8-2.426,3.164-5.59,3.164V78.25Z"
                                                transform="translate(-100.41 -56.836)" fill="#fe9923" />
                                            <path id="Path_2718" data-name="Path 2718"
                                                d="M5.59,18.25C2.426,18.25,0,19.611,0,21.414s2.426,3.164,5.59,3.164,5.59-1.361,5.59-3.164S8.754,18.25,5.59,18.25Z"
                                                fill="#fedb41" />
                                            <path id="Path_2719" data-name="Path 2719"
                                                d="M111.59,21.414c0,1.8-2.426,3.164-5.59,3.164V18.25C109.164,18.25,111.59,19.611,111.59,21.414Z"
                                                transform="translate(-100.41)" fill="#fc3" />
                                            <path id="Path_2720" data-name="Path 2720"
                                                d="M223.074,378.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,381.636,212,379.832V378.25Z"
                                                transform="translate(-200.82 -341.016)" fill="#fea832" />
                                            <path id="Path_2721" data-name="Path 2721"
                                                d="M322.537,378.25v1.582c0,1.8-2.373,3.164-5.537,3.164V378.25Z"
                                                transform="translate(-300.283 -341.016)" fill="#fe9923" />
                                            <path id="Path_2722" data-name="Path 2722"
                                                d="M223.074,348.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,351.636,212,349.832V348.25Z"
                                                transform="translate(-200.82 -312.598)" fill="#fedb41" />
                                            <path id="Path_2723" data-name="Path 2723"
                                                d="M322.537,348.25v1.582c0,1.8-2.373,3.164-5.537,3.164V348.25Z"
                                                transform="translate(-300.283 -312.598)" fill="#fc3" />
                                            <path id="Path_2724" data-name="Path 2724"
                                                d="M223.074,318.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,321.636,212,319.832V318.25Z"
                                                transform="translate(-200.82 -284.18)" fill="#fea832" />
                                            <path id="Path_2725" data-name="Path 2725"
                                                d="M322.537,318.25v1.582c0,1.8-2.373,3.164-5.537,3.164V318.25Z"
                                                transform="translate(-300.283 -284.18)" fill="#fe9923" />
                                            <path id="Path_2726" data-name="Path 2726"
                                                d="M223.074,288.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,291.636,212,289.832V288.25Z"
                                                transform="translate(-200.82 -255.762)" fill="#fedb41" />
                                            <path id="Path_2727" data-name="Path 2727"
                                                d="M322.537,288.25v1.582c0,1.8-2.373,3.164-5.537,3.164V288.25Z"
                                                transform="translate(-300.283 -255.762)" fill="#fc3" />
                                            <path id="Path_2728" data-name="Path 2728"
                                                d="M223.074,258.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,261.636,212,259.832V258.25Z"
                                                transform="translate(-200.82 -227.344)" fill="#fea832" />
                                            <path id="Path_2729" data-name="Path 2729"
                                                d="M322.537,258.25v1.582c0,1.8-2.373,3.164-5.537,3.164V258.25Z"
                                                transform="translate(-300.283 -227.344)" fill="#fe9923" />
                                            <path id="Path_2730" data-name="Path 2730"
                                                d="M223.074,228.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,231.636,212,229.832V228.25Z"
                                                transform="translate(-200.82 -198.926)" fill="#fedb41" />
                                            <path id="Path_2731" data-name="Path 2731"
                                                d="M322.537,228.25v1.582c0,1.8-2.373,3.164-5.537,3.164V228.25Z"
                                                transform="translate(-300.283 -198.926)" fill="#fc3" />
                                            <path id="Path_2732" data-name="Path 2732"
                                                d="M223.074,198.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,201.636,212,199.832V198.25Z"
                                                transform="translate(-200.82 -170.508)" fill="#fea832" />
                                            <path id="Path_2733" data-name="Path 2733"
                                                d="M322.537,198.25v1.582c0,1.8-2.373,3.164-5.537,3.164V198.25Z"
                                                transform="translate(-300.283 -170.508)" fill="#fe9923" />
                                            <path id="Path_2734" data-name="Path 2734"
                                                d="M223.074,168.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,171.636,212,169.832V168.25Z"
                                                transform="translate(-200.82 -142.09)" fill="#fedb41" />
                                            <path id="Path_2735" data-name="Path 2735"
                                                d="M322.537,168.25v1.582c0,1.8-2.373,3.164-5.537,3.164V168.25Z"
                                                transform="translate(-300.283 -142.09)" fill="#fc3" />
                                            <path id="Path_2736" data-name="Path 2736"
                                                d="M223.074,138.25v1.582c0,1.8-2.373,3.164-5.537,3.164S212,141.636,212,139.832V138.25Z"
                                                transform="translate(-200.82 -113.672)" fill="#fea832" />
                                            <path id="Path_2737" data-name="Path 2737"
                                                d="M322.537,138.25v1.582c0,1.8-2.373,3.164-5.537,3.164V138.25Z"
                                                transform="translate(-300.283 -113.672)" fill="#fe9923" />
                                            <path id="Path_2738" data-name="Path 2738"
                                                d="M217.537,78.25c-3.164,0-5.537,1.361-5.537,3.164s2.373,3.164,5.537,3.164,5.537-1.361,5.537-3.164S220.7,78.25,217.537,78.25Z"
                                                transform="translate(-200.82 -56.836)" fill="#fedb41" />
                                            <path id="Path_2739" data-name="Path 2739"
                                                d="M322.537,81.414c0,1.8-2.373,3.164-5.537,3.164V78.25C320.164,78.25,322.537,79.611,322.537,81.414Z"
                                                transform="translate(-300.283 -56.836)" fill="#fc3" />
                                            <path id="Path_2740" data-name="Path 2740"
                                                d="M278.328,253.75a6.328,6.328,0,1,0,6.328,6.328A6.326,6.326,0,0,0,278.328,253.75Z"
                                                transform="translate(-257.656 -223.081)" fill="#fea832" />
                                            <path id="Path_2741" data-name="Path 2741"
                                                d="M398.328,260.078A6.326,6.326,0,0,1,392,266.406V253.75A6.326,6.326,0,0,1,398.328,260.078Z"
                                                transform="translate(-371.328 -223.081)" fill="#fe9923" />
                                            <path id="Path_2742" data-name="Path 2742"
                                                d="M306.746,283.75a4.746,4.746,0,1,0,4.746,4.746A4.76,4.76,0,0,0,306.746,283.75Z"
                                                transform="translate(-286.074 -251.499)" fill="#fedb41" />
                                            <path id="Path_2743" data-name="Path 2743"
                                                d="M396.746,288.5A4.76,4.76,0,0,1,392,293.242V283.75A4.76,4.76,0,0,1,396.746,288.5Z"
                                                transform="translate(-371.328 -251.499)" fill="#fc3" />
                                        </g>
                                    </svg>
                                </div> -->
                            </div>
                            <div class="currency-value">You’ve saved {{auth.getTickerText()}} % shopping with {{domainData.repGroup.name}}</div>
                        </div>
                        <ul class="timeline-list" *ngIf="buyerQickReport">
                            <li>
                                <span>Year to Date</span>
                                <span class="currency-value">{{buyerQickReport?.orderSummary?.yearToDate | rtCurrency}} (Saved {{(yourYTDSaving ? yourYTDSaving : '0') | rtCurrency}})</span>
                            </li>
                            <li>
                                <span>Last Year</span>
                                <span class="currency-value">{{buyerQickReport?.orderSummary?.lastYearTotal | rtCurrency}} (Saved {{(yourLYSaving ? yourLYSaving : '0' ) | rtCurrency}})</span>
                            </li>
                            <li>
                                <span>Lifetime</span>
                                <span class="currency-value">{{buyerQickReport?.orderSummary?.lifetimeTotal | rtCurrency}} (Saved {{(yourLTTaving ? yourLTTaving : '0' ) | rtCurrency}})</span>
                            </li>
                        </ul>
                        <ul class="timeline-list" *ngIf="!buyerQickReport">
                            <li>
                                <span>Year to Date</span>
                                <span class="currency-value">-</span>
                            </li>
                            <li>
                                <span>Last Year</span>
                                <span class="currency-value">-</span>
                            </li>
                            <li>
                                <span>Lifetime</span>
                                <span class="currency-value">-</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- test code -->
    <!-- {{ customStyles.length }}
    <style *ngFor="let style of customStyles" >
        {{ style }}
    </style> -->
    <!-- test code end -->

    <!-- working code -->
    <!-- [ngStyle]="{'background-color': primaryColor}" -->
    <!-- [ngStyle]="box" -->
    <!-- working code end -->
    <!-- <div class="sec-menubar dynamic-theme"  > ABC </div> -->

    <div class="sec-menubar Wid100" *ngIf="menuShimmerAction">
        <div class="shimmer-effect p-20 Wid100"></div>
    </div>

    <div class="sec-menubar dynamic-theme"
        *ngIf="loggedInUser && groups && !notApprovedByRepGroup && !menuShimmerAction">
        <div class="container">
            <div class="hr-category-menu">
                <ul style="padding: 0px;">
                    <li #getmenu_width class="main-group-list" [class.hidemenuli]="grplist.isHide"
                        *ngFor="let grplist of groups; let i = index">

                        <a href="javascript:;" class="main-group-clr" *ngIf="auth.getCategoryGroupType() === 'vendors'"
                            (click)="!isIframeWindow ? allBtnClick($event, grplist, 'group', null) : ''">
                            {{grplist?.groupName}}
                            <i class="fas fa-angle-down menu-down-arrow ml-5"
                                *ngIf="grplist?.repGroupCategoryManufacturers.length"></i>
                        </a>
                        <!-- B2B-268 (MJ 23-Jun-2020) Groups category filter added -->
                        <a href="javascript:;" class="main-group-clr" *ngIf="auth.getCategoryGroupType() === 'category'"
                            (click)="!isIframeWindow ? allBtnClick($event, grplist, 'group', null) : ''">
                            {{grplist?.groupName}}
                            <i class="fas fa-angle-down menu-down-arrow ml-5"
                                *ngIf="grplist?.repGroupCategoryResponse.length"></i>
                        </a>

                        <div class="main-category-block"
                            *ngIf="auth.getCategoryGroupType() === 'category' && grplist?.repGroupCategoryResponse.length"
                            [ngClass]="{'rightalign-menu': i > 3}">
                            <div class="main-category-inner">
                                <ul>
                                    <li class="p-col-12" *ngFor="let catlist of grplist?.repGroupCategoryResponse"
                                        (mouseenter)="mouseEnterMainList($event)">
                                        <a href="javascript:;"
                                            (click)="!isIframeWindow ? allBtnClick($event, catlist, 'category', null) : ''"
                                            class="m-category-list "
                                            [class.sub-category-arrow]="catlist?.repGroupSubCategories.length">{{catlist?.categoryName}}</a>
                                        <div class="sub-category-block pr-0" *ngIf="catlist?.repGroupSubCategories.length"
                                            [class.sub-cat-min-height]="catlist?.repGroupSubCategories.length > 1">
                                            <a href="javascript:;"
                                                (click)="!isIframeWindow ? allBtnClick($event, catlist, 'subcategory', subcatlist) : ''"
                                                class="m-subcategory-list"
                                                *ngFor="let subcatlist of catlist?.repGroupSubCategories">{{subcatlist?.defaultSubCategory?.name}}
                                                <!-- <div class="sub-sub-category-block"
                                                    *ngIf="subcatlist?.repGroupSubSubCategory.length"
                                                    [class.sub-cat-min-height]="subcatlist?.repGroupSubSubCategory.length > 1">
                                                    <a href="javascript:;" *ngFor="let subsubcatlist of subcatlist?.repGroupSubSubCategory"
                                                        (click)="!isIframeWindow ? allBtnClick($event, catlist, 'subsubcategory', subcatlist, subsubcatlist) : ''"
                                                        class="m-subsubcategory-list">
                                                        {{subsubcatlist?.defaultSubSubCategory?.name}}</a>
                                                </div> -->
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                                <div class="clear"></div>
                            </div>
                        </div>

                        <div class="main-category-block"
                            *ngIf="auth.getCategoryGroupType() === 'vendors' && grplist?.repGroupCategoryManufacturers.length"
                            [ngClass]="{'rightalign-menu': i > 3}">
                            <div class="main-category-inner">
                                <ul>
                                    <li class="p-col-12" *ngFor="let mfr of grplist?.repGroupCategoryManufacturers">
                                        <a href="javascript:;"
                                            (click)="!isIframeWindow ? allBtnClick($event, mfr, 'vendors', null) : ''"
                                            class="m-category-list">
                                            {{mfr?.repGroupManufacturerName}}
                                        </a>
                                    </li>
                                </ul>
                                <div class="clear"></div>
                            </div>
                        </div>

                    </li>

                    <li #menubutton [class.hidemenuli]="hidemenutoggle" class="main-group-list"><a href="javascript:;"
                            (click)="!isIframeWindow ? allBtnClick($event, '', 'categorylist', null) : ''"
                            class="main-group-clr"><i class="fas fa-bars"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>