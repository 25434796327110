import {Salesperson} from './salesperson';
import {Retailer} from './retailer';
import {RetailerShipToLocation} from './retailer-ship-to-location';

export class RepGroupSalespersonRetailer {
    shipToLocationName?: string;
    shipToLocationZipCode?: string;
    salespersonName?: string;
    recordID?: number;
    shipToID?: string;
    retailerID?: string;
    retailerShipToLocationID: number;
    salespersonID: string;

    retailer?: Retailer;
    retailerShipTo?: RetailerShipToLocation;
    salesperson?: Salesperson;

    salespersonType: string;

    recordDeleted?: boolean;

}
