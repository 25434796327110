export class RepgroupCategoryList {
    success: boolean;
    data: RepgroupCategory[];
}

export class RepgroupCategory {
    recordID: number;
    repGroupID: string;
    categoryID: number;
    categoryName: string;
    categoryItemCount: number;
    repGroupSubCategories: RepGroupSubCategory[];
}

export class RepGroupSubCategory {
    recordID: number;
    defaultSubCategory: DefaultSubCategory;
    defaultSubCategoryID: number;
    subcategoryItemCount: number;
    label?: string;
    value?: string;
    repGroupCategoryID?: number;
}

export class DefaultSubCategory {
    recordID: number;
    name: string;
}
