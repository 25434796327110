<div class=" reptime-theme">
    <p-card class="card">
        <p-header class="card-header p-5 search-tool-box ui-g">
            <div class="ui-md-12">
                <div class="btn-group float-right" >  <!-- *ngIf="auth.getEnableProfileEditing()" -->
                    <button (click)="addNewCarrierAccount()" class="ui-button-info" icon="fas fa-plus" label="New"
                        pButton title="Add New Carrier Account" type="button"></button>

                    <button class="ui-button-danger" icon="fas fa-trash-alt" (click)="confirmDelete()"
                        [disabled]="!selectedCarrierAccountArray || selectedCarrierAccountArray.length==0"
                        label="Delete{{selectedCarrierAccountArray && selectedCarrierAccountArray.length ? ' (' + selectedCarrierAccountArray.length + ')' : ''}}"
                        pButton title="Delete Carrier Account" type="button"></button>

                    <button class="ui-button-success carries-acc-success" (click)="dt.exportCSV({selectionOnly:true})"
                        icon="fas fa-download" iconPos="left" label="Export{{selectedCarrierAccountArray && selectedCarrierAccountArray.length ? ' ('
                            + selectedCarrierAccountArray.length + ')' : ' all'}}" pButton type="button" [disabled]="!selectedCarrierAccountArray || selectedCarrierAccountArray.length==0"></button>      
                </div>
            </div>
        </p-header>
        <p-table #dt [autoLayout]="true" [columns]="cols"
            scrollHeight="400px" [scrollable]="true" [dataKey]="'recordID'" [loading]="loading" [responsive]="false"
            [value]="carrierAccounts" exportFilename="carrier-accounts" sortField="name"
            [(selection)]="selectedCarrierAccountArray">

            <ng-template pTemplate="paginatorright">
                <div class="per-page-text">Per page :</div>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <div class="per-page-text px-10">Total:</div>
                {{totalRecords}}
            </ng-template>

            <ng-template let-columns pTemplate="header">
                <tr>
                    <th style="width:45px;" class="reptimesticky-possition reptimesticky-possition-left-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [width]="col.width">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                    </th>
                    <th style="width:80px;" class="reptimesticky-possition reptimesticky-possition-right-0"> <!--  *ngIf="auth.getEnableProfileEditing()" -->
                    </th>
                </tr>
            </ng-template>
            <ng-template let-columns="columns" let-rowData pTemplate="body">
                <tr [pSelectableRow]="rowData">
                    <td class="center reptimesticky-possition reptimesticky-possition-left-0" style="width:45px;">
                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td *ngFor="let col of columns" [style.width]="col.width">
                        <div>{{rowData[col.field]}}</div>
                    </td>
                    <td class="icon-cell reptimesticky-possition reptimesticky-possition-right-0" style="width:80px;" > <!-- *ngIf="auth.getEnableProfileEditing()" -->
                        <div class="action-btns d-flex" >
                            <a (click)="editCarrierAccount($event,rowData);" pTooltip="Edit" tooltipPosition="top"
                                title="Edit">
                                <i class="fas fa-pencil-alt"></i>
                            </a>
                            <a (click)="removeCarrierAccount($event,rowData);" class="ml-10 button-danger">
                                <i class="fas fa-trash-alt"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="table-empty">
                    <td [attr.colspan]="cols && cols.length + 3" class="text-center">
                        {{loading ?
                    'Loading carrier accounts' :
                    'You have no carrier accounts yet. Use the "New" button to add one.'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
    <p-confirmDialog key="carrierDialog" [appendTo]="'body'" [baseZIndex]="10000"></p-confirmDialog>
</div>

<app-edit-carrier-account-dialog (closeEvent)="onCarrierAccountsDialogClose()"
                                 (saveEvent)="onCarrierAccountsCreated($event)"
                                 [selectedCarrierAccount]="selectedCarrierAccount"
                                 [retailerID]="retailerID"
                                 [displayCarrierAccountDialog]="displayCarrierAccountDialog">
</app-edit-carrier-account-dialog>
