<p-dropdown #statusDropdown [hidden]="readonly" [options]="orderStatuses" [scrollHeight]="220"
            [style]="{'width': '100%'}" [placeholder]="placeholder" appendTo="body"
            (onChange)="change($event)" (onClick)="touched($event)" (onShow)="show($event)" (onHide)="hide($event)"
            [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'">
    <ng-template let-item pTemplate="selectedItem">
        <span class="issue-status-lozenge {{item.styleClass}}">{{item.label}}</span>
    </ng-template>
    <ng-template let-orderStatus pTemplate="item">
        <span class="issue-status-lozenge {{orderStatus.styleClass}}">{{orderStatus.label}}</span>
    </ng-template>
</p-dropdown>
<div [hidden]="!readonly">
    <span class="issue-status-lozenge {{getStyleClass(currentStatus)}}">{{currentStatus}}</span>
</div>
