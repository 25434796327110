<p-dialog [(visible)]="isOpen"
          [closable]="true" [draggable]="false" [blockScroll]="true"
          [closeOnEscape]="true" [showHeader]="false" (onHide)="isOpenChange.emit(false);"
          [style]="{'width': '800px'}" [positionTop]="60"
          [maximizable]="false" [modal]="true"
          [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'"  (onHide)="isOpenChange.emit(false)"  [resizable]="false">
    <div class="d-flex mt-5 mb-10">
        <h3 class="my-0 flex-grow-1 title-text">Add New Retailer</h3>
        <a class="text-black" (click)="closeRetailerWizard()"><i class="pi pi-times fs-22"></i></a>
    </div>
    <p-steps [activeIndex]="activeIndex" class="wizard-steps" [readonly]="false"
             [model]="stepItems"></p-steps>
    <div class="p-5 mt-15 search-manufacturer"
         *ngIf="activeIndex === 0">
        <label>Search a retailer</label>
        <p-autoComplete [(ngModel)]="selectedRetailer" [suggestions]="retailerOptions"
                        placeholder="Begin typing to find a retailer by name or id (min: 2 characters)"
                        [emptyMessage]="'No results found'"
                        [dataKey]="'recordID'"
                        (completeMethod)="searchRetailer($event)" [style]="{'width':'100%'}"
                        [minLength]="2" [delay]="500"
                        field="name" (onSelect)="onRetailerSelect($event)" [disabled]="isRetailerCreated">
            <ng-template let-retailer pTemplate="item">
                <div class="d-flex align-items-center text-disabled" *ngIf="retailer.recordID">
                    <img src="assets/images/no-image-available.png" width="45px" height="45px">

                    <div class="px-5 d-flex flex-grow-1 flex-column my-4" style="height:100%">
                        <div class="mb-2">{{retailer.name}} - {{retailer.recordID}}
                            <span class="dropdown-status-tags">ADDED</span>
                        </div>
                        <small class="mt-2">{{retailer.fullAddress}}</small>
                    </div>
                </div>
                <div class="d-flex align-items-center" *ngIf="!retailer.recordID">
                    <i class="fa fa-plus-circle add-icon-circle"></i>
                    <div class="px-5 d-flex align-items-center" style="height:100%">
                        <div> Add <b>{{retailer.name}}</b> retailer</div>
                    </div>
                </div>
            </ng-template>
        </p-autoComplete>
    </div>
    <div [ngClass]="{'search-retailer-note':!selectedRetailer.name, 'd-none':selectedRetailer.name}">
        <div class="fs-22">Search a retailer to proceed</div>
    </div>
    <div [ngSwitch]="stepItems[activeIndex].label" [ngClass]="{'form-active':selectedRetailer.name}"
         class="form-deactive py-10">
        <rs-retailer-billing
            *ngSwitchCase="'Billing'"
            (nextStepEvent)="onNextStep($event)"
            [selectedRetailer]="currentRetailer"
            [retailer]="selectedRetailer"
            [repGroupID]="repGroupID"
            [isRetailerCreated]="isRetailerCreated">
            <rs-wizard-buttons [activeIndex]="activeIndex" [stepsArr]="stepItems"
                               (previousEvent)="onPreviousStep()"></rs-wizard-buttons>
        </rs-retailer-billing>

        <rs-retailer-shipping
            *ngSwitchCase="'Shipping'"
            (nextStepEvent)="onNextStep($event)"
            [selectedRetailer]="currentRetailer"
            [retailerShipTo]="selectedShipToLocation"
            [isRetailerCreated]="isRetailerCreated">
            <rs-wizard-buttons [activeIndex]="activeIndex" [stepsArr]="stepItems"
                               (previousEvent)="onPreviousStep()"></rs-wizard-buttons>
        </rs-retailer-shipping>

        <rs-retailer-contact
            *ngSwitchCase="'Primary Contact'"
            [isPrimary]="true"
            (nextStepEvent)="onNextStep($event)"
            [selectedRetailer]="currentRetailer"
            [retailerContact]="selectedContact"
            [isRetailerCreated]="isRetailerCreated">
            <rs-wizard-buttons [activeIndex]="activeIndex" [stepsArr]="stepItems"
                               (previousEvent)="onPreviousStep()"></rs-wizard-buttons>
        </rs-retailer-contact>
        <rs-retailer-advanced
            *ngSwitchCase="'Advanced'"
            (nextStepEvent)="onNextStep($event)"
            [selectedRepGroupRetailer]="selectedRepGroupRetailer"
            [repGroupID]="repGroupID"
            [selectedRepGroupSalespersonRetailer]="selectedRepGroupSalespersonRetailer">
            <rs-wizard-buttons [activeIndex]="activeIndex" [stepsArr]="stepItems"
                               (previousEvent)="onPreviousStep()"></rs-wizard-buttons>
        </rs-retailer-advanced>
    </div>
</p-dialog>
