import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, OnChanges, AfterViewInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as objectPath from 'object-path';
import { Order } from '../../models/order';
import { ReptimeEntity } from '../../models/reptime-entity';
import * as _moment from 'moment';
import * as _ from 'lodash';
import {
    Router, ActivatedRoute, NavigationEnd, NavigationStart, GuardsCheckEnd, GuardsCheckStart,
    RouterPreloader, ActivationEnd, ResolveEnd
} from '@angular/router';
import { environment } from '../../../environments/environment';

const moment = _moment;

@Component({
    selector: 'rs-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() searchFormData: any;
    @Input() hideAdavanceBtn = true;
    @Input() advanceOptions: any[];
    @Input() placeholderForSearchBar: any[];
    @Input() territoriesList: any[];
    @Input() dateRangeOptions: any[];
    @Input() dateRangeLabel: string;
    @Input() isStatusActive = true;
    @Input() searchText: string;
    @Input() isSearchByActive = true;
    @Input() isAvailable = false;
    @Input() defaultSearchBy: string;
    @Input() isSubmitLoading: boolean;
    @Input() isAssignList: boolean;
    @Input() isTerritoriesList: boolean;
    @Input() isRepGroupStatusActive: boolean;
    @Input() isManufactureStatusActive: boolean;
    @Input() isSalespersonTypeList: boolean;
    @Input() isResetLoading: boolean;
    @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() resetEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() ready: EventEmitter<any> = new EventEmitter<any>();
    public isIframeWindow: any = (window as any).iframeData;
    repGroupOrderStatuses = Order.REPGROUP_DROPDOWN_STATUSES;
    manufacturerOrderStatuses = Order.MANUFACTURER_DROPDOWN_STATUSES;
    searchForm: FormGroup;
    resetedSearchForm: FormGroup;
    searchActive: boolean;
    placeHolderSearchBar: any;
    @ViewChild('op', { static: false }) overlayPanel;
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
    statusList = [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false }, { label: 'All', value: null }];
    assignList = [
        { label: 'Assigned', value: true },
        { label: 'Unassigned', value: false }
    ];
    salespersonTypeList = [
        {
            label: 'All',
            value: null
        }, {
            label: ReptimeEntity.SALESPERSON_TYPE_PRIMARY,
            value: 'Primary'
        },
        {
            label: ReptimeEntity.SALESPERSON_TYPE_SECONDARY,
            value: 'Secondary'
        },
        {
            label: ReptimeEntity.SALESPERSON_TYPE_KEY,
            value: 'Key'
        }
    ];

    availableList = [
        { label: 'Available', value: true },
        { label: 'Unavailable', value: false }, { label: 'All', value: null }];
    // RC-1243 (MK-2-OCT-2020) add exact search type
    @Input() typeList = [
        { label: 'Start', value: 'startswith' },
        { label: 'Contain', value: 'contains' }];
    @Input() defaultSearchType: string;
    env = environment;

    constructor(private fb: FormBuilder, private router: Router) {
    }
    ngOnInit() {
        this.searchForm =
            this.fb.group({
                searchType: [(this.defaultSearchType) ? this.defaultSearchType : 'contains'],
                active: [true],
                isAvailable: [null],
                assigned: [true],
                searchBy: [this.advanceOptions[0].value],
                territoriesIds: [''],
                searchText: [this.searchText],
                repGroupOrderStatus: [''],
                salespersonType: [''],
                manufacturerOrderStatus: [''],
                dateRange: ['']
            });
        this.resetedSearchForm = _.cloneDeep(this.searchForm);
        this.setDefaultSelected();
        this.autoFillSearchData();
     
        this.searchActive = !this.isMatchFormData() ? true : false;
        this.ready.emit(this.getSearchObj());

        console.log('this.router.url ', this.router.url);

        if (this.placeholderForSearchBar) {
            this.placeHolderSearchBar = this.placeholderForSearchBar;
        } else {
            this.placeHolderSearchBar = 'Search';
        }
    }
    ngAfterViewInit() {
        this.isIframeWindow = (window as any).iframeData;
    }
    ngOnChanges(changes) {
        try {
            if (changes && changes.searchText && changes.searchText.currentValue !== changes.searchText.previousValue) {
                this.searchForm.get('searchText').setValue(changes.searchText.currentValue);
                this.searchActive = !this.isMatchFormData() ? true : false;
            }
        } catch (error) {
            console.log('error >>', error);
        }
        setTimeout(() => {
            // this.setDefaultSelected();
        }, 0);
    }
    autoFillSearchData() {
        if (this.searchFormData) {
            const dateRange = [];
            Object.keys(this.searchFormData).forEach( key => {
                console.log(key + ' - ' + this.searchFormData[key], dateRange);
                if ((key === 'dateModifiedFrom' || key === 'dateModifiedTo') && this.searchFormData[key]) {
                    dateRange.push(new Date(this.searchFormData[key]));
                    this.searchForm.get('dateRange').setValue(dateRange);
                } else if (key === 'all' && this.searchFormData[key]) {
                    this.searchForm.get('searchText').setValue(this.searchFormData[key]);
                } else if (key && this.searchForm.get(key)) {
                    this.searchForm.get(key).setValue(this.searchFormData[key]);
                } else if (key && _.find(this.advanceOptions, o => (o.value.key === key)) && this.searchForm.get('searchBy')) {
                    const fetchSearchByValue = _.find(this.advanceOptions, o => (o.value.key === key));
                    this.searchForm.get('searchBy').setValue(fetchSearchByValue.value);
                    if (this.searchFormData[key]) {
                        this.searchForm.get('searchText').setValue(this.searchFormData[key]);
                    }
                }
            });
        }
    }
    isMatchFormData() {
        return _.isMatch(this.searchForm.value, this.resetedSearchForm.value);
    }
    getSearchObj() {
        const searchObj: any = {
            searchType: this.searchForm.value.searchType
        };

        this.advanceOptions.forEach((d) => {
            if (this.searchForm.value.searchBy.key === d.value.key) {
                searchObj[d.value.key] = this.searchForm.value.searchText;
            }
        });
        // TODO: Change date foprmat as it is taking date before one day inh case of order
        if (this.dateRangeOptions) {
            this.dateRangeOptions.forEach((d) => {
                if (d.value.type === 'startDate') {
                    searchObj[d.value.key] = moment(objectPath.get(this.searchForm, 'value.dateRange.0', null)).unix() * 1000;
                }
                if (d.value.type === 'endDate') {
                    searchObj[d.value.key] = moment(objectPath.get(this.searchForm, 'value.dateRange.1', null)).unix() * 1000;
                }
            });
        }
        // RC-1107 (RK-08-Aug-2020) passing filter date in YYYY-MM-DD formate
        searchObj['filterDateFromString'] = null;
        searchObj['filterDateToString'] = null;
        if (this.searchForm.value.dateRange && this.searchForm.value.dateRange !== '') {
            const fromDateString = objectPath.get(this.searchForm, 'value.dateRange.0', null);
            const toDateString = objectPath.get(this.searchForm, 'value.dateRange.1', null);
            if (fromDateString) {
                searchObj['filterDateFromString'] = moment(fromDateString).format('YYYY-MM-DD');
            }
            if (toDateString) {
                searchObj['filterDateToString'] = moment(toDateString).format('YYYY-MM-DD');
            }
        }
        if (this.isManufactureStatusActive && this.searchForm.value.manufacturerOrderStatus !== '') {
            searchObj['manufacturerOrderStatus'] = this.searchForm.value.manufacturerOrderStatus;
        }
        if (this.isStatusActive) {
            searchObj['active'] = this.searchForm.value.active;
        }

        if (this.isAvailable) {
            searchObj['isAvailable'] = this.searchForm.value.isAvailable;
        }
        if (this.isSalespersonTypeList) {
            searchObj['salespersonType'] = this.searchForm.value.salespersonType;
        }
        if (this.isRepGroupStatusActive && this.searchForm.value.repGroupOrderStatus !== '') {
            searchObj['repGroupOrderStatus'] = this.searchForm.value.repGroupOrderStatus;
        }
        if (this.isAssignList && this.searchForm.value.assigned !== '') {
            searchObj['assigned'] = this.searchForm.value.assigned;
        }
        if (this.isTerritoriesList && this.searchForm.value.territoriesIds !== '') {
            searchObj['territoriesIds'] = this.searchForm.value.territoriesIds;
        }
        return searchObj;
    }

    onSubmit() {
        if (!this.isIframeWindow) {
            this.searchActive = true;
            this.overlayPanel.hide();
            this.searchInput.nativeElement.focus();
            this.submitEvent.emit(this.getSearchObj());
        }
    }

    repGroupOrderStatusList() {
        return [{ label: 'Select', value: '', styleClass: '' }].concat(this.repGroupOrderStatuses);
    }

    manufacturerOrderStatusList() {
        return [{ label: 'Select', value: '', styleClass: '' }].concat(this.manufacturerOrderStatuses);
    }

    onReset() {
        this.searchActive = false;
        this.overlayPanel.hide();
        this.searchInput.nativeElement.focus();
        this.searchForm.reset({ searchType: 'startswith', active: true, searchBy: this.advanceOptions[0].value });
        this.setDefaultSelected();
        this.resetEvent.emit(this.getSearchObj());
    }

    setDefaultSelected() {
        if (this.defaultSearchBy === 'itemNumber') {
            this.searchForm.get('searchBy').setValue(this.advanceOptions[3].value);
            this.searchForm.get('searchType').setValue('startswith');
        } else {
            this.searchForm.get('searchBy').setValue(this.advanceOptions[0].value);
            this.searchForm.get('searchType').setValue('contains');
        }
    }

}
