import { RetailerContact } from 'src/app/models/retailerContact';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { DialogService, ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { APIResponse } from '../../models/api-response';
import * as objectPath from 'object-path';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { RetailerAPIService } from 'src/app/services/api/retailer-api/retailer-api.service';
import { EditBillToDialogComponent } from '../edit-bill-to-dialog/edit-bill-to-dialog.component';
import { EditContactDialogComponent } from '../edit-contact-dialog/edit-contact-dialog.component';
import { AuthService } from 'src/app/services/middleware/auth/auth.service';


@Component({
  selector: 'app-retailer-contact-table',
  templateUrl: './retailer-contact-table.component.html',
  styleUrls: ['./retailer-contact-table.component.scss']
})
export class RetailerContactTableComponent implements OnInit {

  @Input() retailerID: string;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateContactDataEvent: EventEmitter<any> = new EventEmitter<any>();
  contactCols: any[];
    selectedContact = new RetailerContact();
    contacts: Array<RetailerContact> = [];
    selectedContactArray: RetailerContact[];
    contactToView: RetailerContact;
    loading = true;
    displayContactsDialog = false;
    searchForm: any;
    isSubmitLoading: boolean;
    isResetLoading: boolean;
    advanceOptions = [];
    private readonly onDestroy = new Subject<void>();
    placeholderForSearchBar = 'Search';
    checkBoxPrimaryTrueOrFalse = false;
  constructor(
    private retailerAPIService: RetailerAPIService,public dialogService: DialogService,
    private confirmationService: ConfirmationService, private msgService: MessageService,
    public auth: AuthService,
  ) { 
    this.advanceOptions = [
      { label: 'All', value: { searchByName: 'all', key: 'all' } },
  ];
  }

  ngOnInit() {
    this.contactCols = [
      { field: 'firstName', header: 'First Name', width: '150px', spinner: true, sortField: 'firstName'  },
      { field: 'lastName', header: 'Last Name', width: '150px', spinner: true, sortField: 'lastName' },
      { field: 'email', header: 'Email', width: '150px', spinner: true, sortField: 'email' },
      { field: 'phone', header: 'Phone', width: '150px', spinner: true, sortField: 'phone' },
      { field: 'cell', header: 'Cell', width: '150px' , spinner: true, sortField: 'cell'}
  ];
  this.loadContacts(this.searchForm);
  }

  ngOnDestroy() {
    this.onDestroy.next();
}

initSearchObj($event) {
    this.searchForm = $event;
}

search($event) {
    this.searchForm = $event;
    this.isSubmitLoading = true;
    const searchType = $event.searchType;
    console.groupCollapsed('Searching items');
    console.log('Search type:', searchType);
    console.log('Search parameters:', $event);
    console.groupEnd();
    this.loadContacts(this.searchForm);
    console.groupEnd();
}

reset($event) {
    this.searchForm = $event;
    this.isResetLoading = true;
    this.loadContacts(this.searchForm);
}


loadContacts(searchParams) {
  console.log('>TTTTTTTTTT 2');
  this.onDestroy.next();
  this.retailerAPIService.getRetailerContactsByRetailerID(this.retailerID, searchParams)
  .pipe(takeUntil(this.onDestroy)).subscribe((response: APIResponse) => {
      if (response.success) {
          this.contacts = response.data;
          this.selectedContactArray = [];
          this.updateContactDataEvent.emit(this.contacts);
      } else {
          this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(response,
             'error.message', 'Error occurred during services request') });
      }
      this.loading = false;
      this.isSubmitLoading = false;
      this.isResetLoading = false;
  }, (error) => {
      this.loading = false;
      this.isSubmitLoading = false;
      this.isResetLoading = false;
      this.msgService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error,
         'message', 'Error occurred during services request') });
  });
}


addContact() {
  console.log('addContact open');

  this.selectedContact = new RetailerContact();
  const data: any = {
    selectedContact: this.selectedContact,
  };
  data.retailerID = this.retailerID;
  data.selectedContact.retailerID = this.retailerID;
  console.log('data.selectedContact', data.selectedContact);
  
  const ref = this.dialogService.open(EditContactDialogComponent, {
    header: 'Add Contact',
    width: '50%',
    height: 'auto',
    closable: true,
    styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
    data
  });

  ref.onClose.subscribe((billToLocation: any) => {
    if (billToLocation) {
      const defaultSerach = {
        all: null,
        searchType: 'contains'
      };
      this.loadContacts(defaultSerach);
    }
  });
}

getContactName(contact: RetailerContact) {
  let name = '';
  if (contact.firstName) {
      name += contact.firstName + ' ';
  }
  if (contact.middleName) {
      name += contact.middleName + ' ';
  }
  if (contact.lastName) {
      name += contact.lastName;
  }

  if (name) {
      return name.trim();
  }
  return '';
}



editContact($event, rowData, type?) {
  console.log('editContact??????????');
  
  const data: any = {
    selectedContact: rowData,
  };
  if (type === 'NEW') {
    data.retailerID = this.retailerID;
  }
  const ref = this.dialogService.open(EditContactDialogComponent, {
    header: 'Edit Contact',
    width: '50%',
    height: 'auto',
    closable: true,
    styleClass: 'resp-home-shipedit overflow-auto cut-dialog-height responsive-ship-loc',
    data
  });

  ref.onClose.subscribe((billToLocation: any) => {
    if (billToLocation) {
      // retailer = billToLocation;
      // const defaultSerach = {
      //   all: null,
      //   searchType: 'contains'
      // };
      this.msgService.add({severity: 'success', summary: 'Success', detail: 'Contact information set successfully'});
      this.saveSelectedContact();
      // this.loadContacts(defaultSerach);
    }
  });
}

removeContact($event, rowData) {
  this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete confirmation',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
          this.selectedContact = rowData;
          this.selectedContact.recordDeleted = true;
          this.deleteSelectedContact();
      },
      reject: () => {
      },
      key: 'ContactDialog'
  }
  );
}

saveSelectedContact() {
  this.saveEvent.emit();
  // this.loadContacts(this.searchForm);
  this.displayContactsDialog = false;
}


deleteSelectedContact() {
  this.loading = true;
  this.retailerAPIService.saveRetailerContact(this.selectedContact, this.retailerID).subscribe((data: RetailerContact) => {
      if (data) {
          this.msgService.add({severity: 'success', summary: 'Success', detail: 'Contact information saved'});
          this.selectedContactArray.splice(this.selectedContactArray.indexOf(this.selectedContact), 1);
          this.loading = false;
          this.saveEvent.emit();
          this.loadContacts(this.searchForm);
          this.displayContactsDialog = false;
      } else {
          this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error deleting contact' });
          this.loading = false;
      }
  });
}

onHeaderCheckboxToggle($event: any, rows: number) {
  console.log($event, 'Number of Rows To View = ' + rows);
  const isChecked = $event.checked;
  this.selectedContactArray = [];
  if (isChecked) {
      this.selectedContactArray = this.contacts;
  }
}

// RC-783 (MJ 28-APRIL-2020) Added for get retailerID on prev and next button
ngOnChanges(changes: SimpleChanges): void {
  console.log('changes>>', changes);
  if (changes.retailerID.currentValue !== changes.retailerID.previousValue && changes.retailerID.previousValue !== undefined) {
      this.loadContacts(this.searchForm);
  }
}
// END

deleteSelectedContacts() {
  this.selectedContactArray.forEach(s => {
      s.recordDeleted = true;
      this.selectedContact = s;
      this.deleteSelectedContact();
  });
}

confirmDelete() {
  this.confirmationService.confirm({
      message: 'Are you sure that you want to delete ' +
          this.selectedContactArray.length + ' contact' +
          (this.selectedContactArray.length === 1 ? '' : 's') + '?',
      header: 'Delete Selected',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
          this.deleteSelectedContacts();
      },
      reject: () => {
      },
      key: 'ContactDialog'
  });
}

changeTableCheckbox(e) {
  // checkBoxPrimaryTrueOrFalse
  // this.selectedShippingArray = [];
  const allcheck = [];
  this.selectedContactArray.forEach((o) => {
    allcheck.push( o['isPrimary']);
  });

  if (allcheck.some((item) => item === true)) {
    this.checkBoxPrimaryTrueOrFalse =  false;
  } else {
    this.checkBoxPrimaryTrueOrFalse = true;
  }

  console.log('allcheck >>???', allcheck, this.checkBoxPrimaryTrueOrFalse);
}


}
