import {Inject, Injectable, InjectionToken} from '@angular/core';

import {Options} from '../../models/options';
import {mergeOptions} from '../utils/utils.service';
import {environment} from '../../../environments/environment';

export const OPTIONS = new InjectionToken<Options>('OPTIONS');

@Injectable()
export class OptionsProviderService {

    private defaultOptions: Options;
    private _options: Options;

    constructor(@Inject(OPTIONS) options: Options) {
        this.defaultOptions = {
            apiUrl: environment.apiUrl,
            imageUrl: environment.imageBucketUrl,
            projectName: 'B2B'
        };
        this._options = mergeOptions(this.defaultOptions, options);
    }

    get options(): Options {
        return this._options;
    }
}
