import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { RTSharedService } from '../../rtshared/rtshared.service';

@Injectable({
  providedIn: 'root'
})
export class CanactiveRouterService implements CanDeactivate<any> {
  isredirect = true;
  constructor(
    private confirmationService: ConfirmationService,
    public rtSharedService: RTSharedService,
  ) {

  }
  async canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    this.isredirect = true;
    if (this.rtSharedService.getRepgroupOrderStatus()) {
      await this.conformdialog(nextState);
    }
    return this.isredirect;
  }
  conformdialog(nextState) {
    return new Promise(async (resolve, reject) => {
      this.confirmationService.confirm({
        message: 'Your order process is not yet finished. You are leaving unfinished order(s) in your account. Click ‘Yes’ to exit or ‘No’ to go back and finish. ',
        header: 'Alert: Unfinished Order',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: async () => {
          this.isredirect = true;
          // if (optionalparams !== 'catalog' && optionalparams !== 'promotion') {
          if (nextState.url === '/login') {
            this.rtSharedService.isRemoveToken.emit(true);
          }
          // this.rtSharedService.isRemoveToken.emit(true);
          this.rtSharedService.setRepgroupOrderStatus(false);
          // }
          resolve(true);
        },
        reject: () => {
          this.isredirect = false;
          // this.rtSharedService.isRemoveToken.emit(false);
          resolve(false);
        },
        key: 'unfinishedOrder'
      });
    });
  }
}