import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, DynamicDialogConfig, DynamicDialogRef, MessageService, SelectItem } from 'primeng/api';
import * as _ from 'lodash';

import { HtmlToolsService } from '../../services/html-tools/html-tools.service';
import { RetailerAPIService } from '../../services/api/retailer-api/retailer-api.service';
import { DataService } from '../../services/other/data/data.service';
import { APIResponse } from '../../models/api-response';
import { RTSharedService } from 'src/app/services/rtshared/rtshared.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';

@Component({
    selector: 'rs-edit-ship-to-dialog',
    templateUrl: './edit-ship-to-dialog.component.html',
    styleUrls: ['./edit-ship-to-dialog.component.css']
})
export class EditShipToDialogComponent implements OnInit, OnDestroy {
    public selectedShipToLocation: any;
    public retailerID: any;
    public countries: any[];
    public states: any[];
    public shipToLocationForm: FormGroup;
    public fromPage: string;
    primaryOptions: SelectItem[];
    saveLoading = false;
    repGroupID: any;
    newLocation = false;
    countriesStateJSON: any;
    private readonly destroyZipCodeList = new Subject<void>();

    constructor(
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private fb: FormBuilder,
        private msgService: MessageService,
        private retailerAPIService: RetailerAPIService,
        private htmlTools: HtmlToolsService,
        private rtSharedService: RTSharedService,
        private dataService: DataService,
        private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.selectedShipToLocation = this.config.data.selectedShipToLocation;
        this.fromPage = this.config.data.fromPage;
        this.retailerID = this.config.data.retailerID;
        this.initForm();
        this.primaryOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
        if (!this.selectedShipToLocation || !this.selectedShipToLocation.recordID) { // for add new
            this.selectedShipToLocation = { retailerID: this.retailerID };
        } else { // for edit existing
            this.setFormFromObject();
        }
        this.repGroupID = this.rtSharedService.getRepGroupID();
        if (this.selectedShipToLocation && this.selectedShipToLocation.retailerID && !this.selectedShipToLocation.recordID) {
            this.newLocation = true;
        } else {
            this.newLocation = false;
        }
    }

    initForm() {
        this.shipToLocationForm = this.fb.group({
            name: ['', Validators.required],
            address1: ['', Validators.required],
            address2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', Validators.required],
            country: [environment.countryAbrv, Validators.required],
            phone: [''],
            phoneExtension: [''],
            fax: ['', Validators.pattern('^[0-9]*$')],
            faxExtension: [''],
            email: [''],
            notes: [''],
            isPrimary: [true]
        });
        this.dataService.getCountryList().subscribe((data: any[]) => {
            this.countries = data;
        });
        // this.dataService.getStateList().subscribe((data: any[]) => {
        //     this.states = data;
        // });
        this.dataService.setCountriesState().then(res => {
            this.countriesStateJSON = res;
        });
    }

    setFormFromObject() {
        this.shipToLocationForm.reset();
        this.shipToLocationForm.patchValue(this.selectedShipToLocation);
        if (!this.selectedShipToLocation.recordID) {
            this.shipToLocationForm.patchValue({ country: environment.countryAbrv });
            this.shipToLocationForm.patchValue({ isPrimary: false });
        }
        // if (this.validateChk.country.value === 'US') {
        //     this.shipToLocationForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
        //     this.shipToLocationForm.get('zip').updateValueAndValidity();
        // } else {
        //     this.shipToLocationForm.get('zip').clearValidators();
        //     this.shipToLocationForm.get('zip').updateValueAndValidity();
        // }
        this.setValidatorOnCountry(this.shipToLocationForm);
    }

    get validateChk() {
        // Convenience getter for easy access to form fields
        return this.shipToLocationForm.controls;
    }

    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
        if (field.errors.minlength && field.errors.minlength.actualLength) {
            return `${name} must be minimum of ${field.errors.minlength.requiredLength} length`;
        }
        if (field.errors.maxlength && field.errors.maxlength.actualLength) {
            return `${name} must be maximum of ${field.errors.maxlength.requiredLength} length`;
        }
    }
    setValidatorOnCountry(formGroup: any) {
        if (formGroup.get('country').value === 'US') {
            formGroup.get('zip').setValidators([Validators.required, Validators.maxLength(5)]);
            formGroup.get('state').setValidators([Validators.required, Validators.maxLength(50)]);
            formGroup.get('zip').updateValueAndValidity();
            formGroup.get('state').updateValueAndValidity();
        } else {
            formGroup.get('zip').setValidators([Validators.maxLength(20)]);
            formGroup.get('state').clearValidators();
            formGroup.get('zip').updateValueAndValidity();
            formGroup.get('state').updateValueAndValidity();
        }
    }
    countrySpecific() {
        // if (this.validateChk.country.value === 'US') {
        //     this.shipToLocationForm.get('zip').setValidators([Validators.required, Validators.maxLength(20)]);
        //     this.shipToLocationForm.get('zip').updateValueAndValidity();
        // } else {
        //     this.validateChk.zip.setValue('');
        //     this.validateChk.city.setValue('');
        //     this.validateChk.state.setValue('');
        //     this.shipToLocationForm.get('zip').clearValidators();
        //     this.shipToLocationForm.get('zip').updateValueAndValidity();
        // }
        this.setValidatorOnCountry(this.shipToLocationForm);
        this.updateChange();
    }
    ngOnDestroy() {
        this.destroyZipCodeList.next();
    }
    // RC-588 (SG 25 MAR 2020): set state and city value
    updateChange() {
        const zipcode = this.validateChk.zip.value.trim();
        if (zipcode.length >= 5) {

            const searchParams: any = {};
            let size = 3000;
            let offset = 0;
            let sort = 'country';
            let sortOrder = 1;
            searchParams.country = this.validateChk.country.value;
            searchParams.zipCode = this.validateChk.zip.value.trim();
            this.destroyZipCodeList.next();

            this.dataService.getZipCodeList(offset, size, sort, sortOrder, searchParams)
                .pipe(takeUntil(this.destroyZipCodeList)).subscribe((res: APIResponse) => {
                    if (res.success) {
                        if (res.data[0]) {
                            this.validateChk.state.setValue(res.data[0].stateAbbreviation);
                            this.validateChk.city.setValue(res.data[0].city);
                        } else {
                            this.validateChk.state.setValue('');
                            this.validateChk.city.setValue('');
                        }
                    } else {
                        this.validateChk.state.setValue('');
                        this.validateChk.city.setValue('');
                    }
                }, () => {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error occurred during services request' });
                });
        }
    }

    saveRetailerShipToLocation() {
        this.saveLoading = true;
        this.retailerAPIService.saveRetailerShipTo(this.selectedShipToLocation.retailerID, this.selectedShipToLocation)
            .subscribe(async (data: any) => {
                if (data) {
                    const shipToLocationID = data.recordID;
                    const sessionLocationId = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
                    if (sessionLocationId.recordID === data.recordID) {
                        this.rtSharedService.setSelectedShipTo(data);
                    }
                    const updateData: any = { isOrderOnly: false, data };
                    if (this.newLocation) {
                        await this.getAutoAssignedSalesperson(shipToLocationID, updateData);
                    } else {
                        this.ref.close(updateData);
                    }
                    this.saveLoading = false;
                } else {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error saving ship to location' });
                    this.saveLoading = false;
                }
            });
    }

    getAutoAssignedSalesperson(shipToLocationID, updateData) {
        return new Promise((resolve, reject) => {
            this.retailerAPIService.autoassignedRetailerSalesperson(this.repGroupID, this.retailerID, shipToLocationID)
                .subscribe((response: APIResponse) => {
                    if (response.success && response.data && response.data.length) {
                        const spData = _.map(response.data, (d) => {
                            return '<b>' + d.salesperson.name + '</b> [' + d.salesperson.recordID + ']';
                        }).join('<br /> ');
                        this.confirmationService.confirm({
                            // tslint:disable-next-line:max-line-length
                            message:
                                'Customers in this zip code are currently assigned to the following Salespeople: <br /><br />' +
                                `${spData}`,
                            header: 'Auto-assign',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: 'OK',
                            accept: async () => {
                                await this.saveSalespersonAssignment(response.data);
                                this.ref.close(updateData);
                                resolve();
                            },
                            reject: async () => {
                                this.ref.close(updateData);
                                resolve();
                            },
                            acceptVisible: true,
                            rejectVisible: false,
                        });
                    } else {
                        this.msgService.add({ severity: 'warn', summary: 'Warning', detail: 'Unable to assign retailer. Please contact rep group admin' });
                        this.ref.close(updateData);
                        resolve();
                    }
                    resolve();
                }, () => {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Failed To Save Assignment' });
                    this.ref.close(updateData);
                    resolve();
                });
        });
    }

    saveSalespersonAssignment(assignmentArr) {
        return new Promise((resolve, reject) => {
            const assignmentList = _.cloneDeep(assignmentArr).map((o) => {
                return {
                    recordDeleted: false,
                    retailerID: o.retailerID,
                    retailerShipToLocationID: o.retailerShipToLocationID,
                    salespersonID: o.salespersonID,
                    salespersonType: o.salespersonType
                };
            });
            this.retailerAPIService.saveAssignmentList(assignmentList)
                .then((response: APIResponse) => {
                    if (response.success) {
                        this.msgService.clear();
                        this.msgService.add({ severity: 'success', summary: 'Success', detail: 'Retailer is assigned to ' + this.commaSeparatedString(assignmentArr) });
                        resolve();
                    } else {
                        this.msgService.add({ severity: 'error', summary: 'Error', detail: `Error while saving assignment` });
                        resolve();
                    }
                }, () => {
                    this.msgService.add({ severity: 'error', summary: 'Error', detail: `Error while saving assignment` });
                    resolve();
                });
        });
    }

    commaSeparatedString(responseArray) {
        let str = '';
        Object.keys(responseArray).forEach((index) => {
            const comma = (index !== (responseArray.length - 1).toString() ? ', ' : ' ');
            str = str.concat(responseArray[index].salesperson.name + '[' + responseArray[index].salesperson.recordID + ']' + comma);
        });
        return str;
    }

    saveSelectedShipTo() {
        if (this.shipToLocationForm.valid) {
            this.setObjectFromForm();
            this.saveRetailerShipToLocation();
        } else {
            this.htmlTools.validateAllFields(this.shipToLocationForm);
        }
    }

    saveOnlyForOrder() {
        if (this.shipToLocationForm.valid) {
            this.setObjectFromForm();
            const updateData: any = {
                isOrderOnly: true,
                data: this.shipToLocationForm.getRawValue()
            };
            this.ref.close(updateData);
        } else {
            this.htmlTools.validateAllFields(this.shipToLocationForm);
        }
    }

    private setObjectFromForm() {
        _.forEach(this.shipToLocationForm.controls, (key, value) => {
            this.selectedShipToLocation[value] = this.shipToLocationForm.controls[value].value;
        });
    }
}
