<p-toast key="addItem2" position="top-right" [baseZIndex]="100000"></p-toast>
<p-confirmDialog key="review-delete-dialog" [appendTo]="'body'" [baseZIndex]="10000" styleClass="delete-confo-dialog">
</p-confirmDialog>
<RtCustom-confirmDialog key="breckincrement1" id="breckincrement1" #breckincrement1 header="Drop database"
    acceptLabel="Yes" rejectLabel="No" [baseZIndex]="10000" [appendTo]="'body'">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="Yes" id="YesButton"
            (click)="breckincrement1.accept()"></button>
        <button type="button" class="ui-button-secondary" pButton icon="pi pi-times" label="No"
            (click)="breckincrement1.close($event)"></button>
    </p-footer>
</RtCustom-confirmDialog>
<p-dialog #pdialog [(visible)]="isOpen" (onHide)="isOpenChange.emit(false);" [closable]="false" [draggable]="true"
    [blockScroll]="true" [closeOnEscape]="false" [showHeader]="false" appendTo="body" [style]="{'width': '1050px'}"
    [baseZIndex]="10000" [positionTop]="50" [maximizable]="false" [modal]="true" [dismissableMask]='true'
    [transitionOptions]="'0ms cubic-bezier(0.250, 0.250, 0.750, 0.750)'" styleClass="itemdetailpopup"
    [resizable]="false">
    <div class="full-page-loader" *ngIf="showLoader">
        <div class="full-page-loadinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="2"></p-progressSpinner>
        </div>
    </div>
    <!-- B2B-194 (NL 24-MAR-2020) redesign -->
    <!-- // [B2B-232] (MG-08-APR-2020) modified to do ui hotfix -->
    <!-- <div *ngIf="visible" [@slideInOut]>This element will slide up and down when the value of 'visible' changes from true to false and vice versa.</div> -->
    <div class="d-flex justify-content-center" style="position: relative;">
        <div class="single-linked-item flex-column mr-5 single-item-height d-none"
            *ngIf="(linkedItemsData && linkedItemsData.length) && !linkedItemsLoader">
            <!-- <h3 class="linked-title">Linked Item</h3> -->
            <div class="single-linked-item single-item-height-inner">
                <p-scrollPanel [style]="{width: '100%', height: 'auto'}" styleClass="sidebar-category-scroll">
                    <div *ngFor="let linkedItems of linkedItemsData">
                        <div class="cart-small-img mb-5" (click)="selectlinkedItemsData(linkedItems, false)">
                            <div [ngClass]="{'show-loader': linkedItems.activeImglinkedLoader}" class="image-loader">
                                <div class="loader-icon"></div>
                            </div>
                            <img (load)="linkedItems.activeImglinkedLoader=false;" pTooltip="{{linkedItems.name}}"
                                tooltipPosition="right" tooltipEvent="hover" tooltipZIndex="10000000"
                                class="object-fit-cover wid-100" style="height:53px;max-width:100%;"
                                src="{{linkedItems.fullPicturePath}}"
                                onError="this.src='assets/images/no-image-available.png'">
                        </div>
                    </div>
                </p-scrollPanel>
            </div>
        </div>
        <!-- B2B-226 (NL 03-APR-2020) add shimmer effect html -->
        <!-- Start shimmer effect -->
        <div class="h-100 single-item-main" *ngIf="linkedItemsLoader">
            <div class="single-cart-item-main">
                <div class="single-cart-item-left mr-20">
                    <div class="single-cart-small-img mr-10">
                        <div class="mr-5 mt-5">
                            <div class="cart-small-img shimmer-effect">
                            </div>
                        </div>
                        <div class="mr-5 mt-5">
                            <div class="cart-small-img shimmer-effect">
                            </div>
                        </div>
                        <div class="mr-5 mt-5">
                            <div class="cart-small-img shimmer-effect">
                            </div>
                        </div>
                        <div class="mr-5 mt-5">
                            <div class="cart-small-img shimmer-effect">
                            </div>
                        </div>
                        <div class="mr-5 mt-5">
                            <div class="cart-small-img shimmer-effect">
                            </div>
                        </div>
                    </div>
                    <div class="single-cart-big-img shimmer-effect">

                    </div>
                </div>
                <div class="single-cart-item-right">
                    <strong class="mb-5 scart-size shimmer-effect p-7 Wid60"></strong>
                    <div class="mb-15 shimmer-effect p-5 Wid20"></div>
                    <div class="mb-10 scart-size sngl-item-size shimmer-effect p-15 Wid80"></div>
                    <div class="mb-15 d-flex align-items-end sngl-price-row">
                        <strong class="price-sngl-txt shimmer-effect p-7 Wid20"></strong>
                        <span class="price-unit-txt shimmer-effect p-2 Wid30"></span>
                    </div>
                    <div class="mb-10 shimmer-effect p-5 Wid80"></div>
                    <div class="mb-10 single-five-line">
                        <div class="mb-3 shimmer-effect p-5 Wid100"></div>
                        <div class="mb-3 shimmer-effect p-5 Wid100"></div>
                        <div class="shimmer-effect p-5 Wid100"></div>
                    </div>
                    <div class="mb-20 shimmer-effect p-5 Wid60"></div>
                    <div class="itemadd-action mt-10 d-flex align-items-center">
                        <div class="single-like mr-20 shimmer-effect mr-10" style="width: 25px; height: 25px;"> </div>
                        <div class="cart-item-inc-block tbl_act mr-20">
                            <div class="cart-item-inc-inner" style="justify-content: center;">
                                <div class="shimmer-effect mr-7" style="width: 25px; height: 25px;"></div>
                                <div class="shimmer-effect mr-7" style="width: 50px; height: 25px;"></div>
                                <div class="shimmer-effect" style="width: 25px; height: 25px;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Wid100 d-flex justify-content-end mt-20">
                <div class="shimmer-effect mr-10" style="width: 120px; height: 30px;"></div>
                <div class="shimmer-effect" style="width: 120px; height: 30px;"></div>
            </div>
        </div>
        <!-- End Shimmer effect -->
        <div class="h-100 py-10 pt-20 single-item-main" *ngIf="!linkedItemsLoader"
            [class.no-linked-itemss]="!(linkedItemsData && linkedItemsData.length) && !linkedItemsLoader">
            <!-- <strong class="mb-10 scart-size" style="display: block;">A-Z manufacturers</strong> -->
            <div class="single-cart-item-main">
                <div class="single-cart-item-left mr-20">
                    <div class="single-cart-small-img mr-10 overflow-custom-scroll">
                        <!-- <p-scrollPanel [style]="{width: '100%', height: '300px'}" styleClass="sidebar-category-scroll"> -->
                        <div *ngFor='let j of [1]' class="mr-5 mt-5 mb-5 last-child">
                            <div class="cart-small-img" (click)="selectThumbImageData(false)">
                                <div [ngClass]="{'show-loader':configData?.activeViewItem?.modelimageLoader}"
                                    class="image-loader">
                                    <div class="loader-icon"></div>
                                </div>
                                <img *ngIf="configData?.activeViewItem?.modelimageError"
                                    src="{{configData?.activeViewItem?.manufacturerPath}}"
                                    onerror="this.src='assets/images/no-image-available.png'"
                                    class="object-fit-cover wid-100" style="height:53px;max-width:100%;"
                                    alt="{{configData?.activeViewItem?.itemNumber}}">
                                <img *ngIf="!configData?.activeViewItem?.modelimageError"
                                    (load)="configData?.activeViewItem.modelimageLoader=false;"
                                    class="object-fit-cover wid-100" style="height:53px;max-width:100%;"
                                    (error)="configData?.activeViewItem.modelimageLoader=false;configData?.activeViewItem.fullPicturePath = null;activeViewItem.modelimageError=true"
                                    src="{{configData?.activeViewItem?.fullPicturePath}}"
                                    alt="{{configData?.activeViewItem?.itemNumber}}">
                            </div>
                        </div>
                        <div class="mr-5 mt-5 mb-5 last-child" *ngIf="imageAngles">
                            <div class="cart-small-img" (click)="view360Preview()">
                                <img src="assets/images/360-degrees.svg" class="object-fit-cover wid-100"
                                    style="height:53px;max-width:100%;">
                            </div>
                        </div>
                        <!-- [B2B-179] -->
                        <div *ngFor="let thumbImage of thumbImageData" class="mr-5 mb-5 mt-5 last-child">
                            <!-- *ngIf="thumbImage.activeImgThumbError" -->
                            <div class="cart-small-img" (click)="selectThumbImageData(thumbImage)">
                                <div class="play-icon-div" *ngIf="isVideo(thumbImage)">
                                    <i class="pi pi-play"></i>
                                </div>
                                <div [ngClass]="{'show-loader': thumbImage.activeImgThumbLoader}" class="image-loader">
                                    <div class="loader-icon"></div>
                                </div>
                                <img (load)="thumbImage.activeImgThumbLoader=false;" class="object-fit-cover wid-100"
                                    style="height:53px;max-width:100%;" src="{{thumbImage.fullPicturePath}}"
                                    onError="this.src='assets/images/no-image-available.png'">
                            </div>
                        </div>
                        <!-- [B2B-179] -->
                        <!-- </p-scrollPanel> -->
                    </div>
                    <div class="single-cart-big-img">
                        <div [ngClass]="{'show-loader':configData?.activeViewItem?.modelimageLoader}"
                            class="image-loader">
                            <div class="loader-icon"></div>
                        </div>
                        <img *ngIf="configData?.activeViewItem?.modelimageError"
                            src="{{configData?.activeViewItem?.manufacturerPath}}" class="object-fit-cover wid-100"
                            style="max-width:100%; background: #fff; z-index: 1;"
                            alt="{{configData?.activeViewItem?.itemNumber}}"
                            onerror="this.src='assets/images/no-image-available.png'">
                        <ngx-img-zoom (load)="configData?.activeViewItem.modelimageLoader=false;"
                            *ngIf="!isVideo(selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath)"
                            (error)="configData?.activeViewItem.modelimageLoader=false;configData?.activeViewItem.fullPicturePath = null;activeViewItem.modelimageError=true"
                            [zoomImageSrc]="selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath"
                            [previewImageSrc]="selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath"
                            [imgStyle]="'width:100%;'"
                            [resultStyle]="'width:374px; height:364px; background-repeat: no-repeat; z-index: 2; position:absolute;
                                        -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                                            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); top: 0;left: 100%;'"
                            [lensStyle]="'width:175px; height:175px;'" [enableZoom]="enableZoom"></ngx-img-zoom>
                        <video style="width: 100%; height: 100%;" controls controlsList="nodownload" autoplay
                            [muted]="'muted'" loop="true"
                            [src]="selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath"
                            *ngIf="isVideo(selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath)">
                        </video>
                        <!-- <img src="{{configData?.activeViewItem?.fullPicturePath}}" alt="Cart Image" /> -->
                        <!-- <div class="itemadd-action mt-10" >
                                <button pButton type="button" icon="fa fa-plus" label="Add Item" class="info ui-button-info mr-10"></button>
                                <a href="javascritp:;" class="single-like"><i class="far fa-heart item-heart"></i></a>
                            </div> -->
                    </div>
                </div>
                <!-- // [B2B-232] (MG-08-APR-2020) modified to do ui hotfix -->
                <div class="single-cart-item-right">
                    <!-- <strong class="mb-5 scart-size">A-Z manufacturers</strong> -->
                    <!-- <strong class="mb-5 scart-size" style="display: block;text-transform: uppercase;font-size: 16px;">{{configData?.activeViewItem?.repGroupManufacturerName</strong> -->
                    <div class="brand-info">
                        <div class="brand-text">
                            <strong class="mb-5 scart-size brand-name"
                                style="display: block;text-transform: uppercase;font-size: 16px;">
                                {{configData?.activeViewItem?.repGroupManufacturerName ?
                                configData?.activeViewItem?.repGroupManufacturerName
                                :configData?.activeViewItem?.manufacturerName}}
                            </strong>
                            <div class="item-number" *ngIf="configData?.activeViewItem?.itemNumber">
                                #{{configData?.activeViewItem?.itemNumber}}</div>
                        </div>
                        <img src="{{configData?.activeViewItem?.manufacturerPath}}" class="brand-image"
                            onError="this.src='assets/images/no-image-100x50.jpg';">
                    </div>
                    <div class="mb-10 scart-size sngl-item-size"
                        (click)="!isIframeWindow && fromPage !== 'digital-catalog' ? navigateItemDetails($event, configData?.activeViewItem?.recordID) : ''; isOpenChange.emit(false);">
                        {{configData?.activeViewItem?.name}}</div>
                    <div class="d-flex align-items-center flex-wrap">
                        <ng-container
                            *ngIf="!(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true) && (isLogin || isIframeWindow) && !b2bItemPricingPolicy">
                            <div class="mb-15 d-flex align-items-end sngl-price-row mr-15"
                                *ngIf="configData?.activeViewItem?.unitPrice">
                                <strong class="price-sngl-txt">{{configData?.activeViewItem?.unitPrice |
                                    rtCurrency}}</strong>
                                <span class="price-unit-txt">
                                    <ng-container
                                        *ngIf="configData?.activeViewItem?.b2bItemPricingPolicy === 'ShowRetailPrice'">
                                        RETAIL</ng-container>
                                    <ng-container
                                        *ngIf="configData?.activeViewItem?.b2bItemPricingPolicy === 'ShowWholeSalePrice'">
                                        WHOLESALE</ng-container> PRICE PER UNIT
                                </span>
                            </div>
                            <div class="d-flex discount-block mb-15"
                                *ngIf="configData?.activeViewItem?.discountPercent ">
                                <span class="off-item-per">{{configData?.activeViewItem?.discountPercent
                                    / 100 |
                                    percent:'0.0-2'}} off</span>
                                <!-- *ngIf="configData?.activeViewItem?.discountStartDateString || configData?.activeViewItem?.discountEndDateString" -->
                                <span class="discount-date"
                                    *ngIf="configData?.activeViewItem?.discountStartDateString || configData?.activeViewItem?.discountEndDateString">
                                    <ng-container
                                        *ngIf="configData?.activeViewItem?.discountStartDateString && !configData?.activeViewItem?.discountEndDateString">
                                        From
                                    </ng-container>
                                    <ng-container *ngIf="configData?.activeViewItem?.discountStartDateString">
                                        {{configData?.activeViewItem?.discountStartDateString | rtDate}}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="configData?.activeViewItem?.discountStartDateString && configData?.activeViewItem?.discountEndDateString">
                                        to
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!configData?.activeViewItem?.discountStartDateString && configData?.activeViewItem?.discountEndDateString">
                                        Up to
                                    </ng-container>
                                    <ng-container *ngIf="configData?.activeViewItem?.discountEndDateString">
                                        {{configData?.activeViewItem?.discountEndDateString | rtDate}}
                                    </ng-container>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="mb-10" style="font-size: 15px;">Minimum {{configData?.activeViewItem?.minimumQuantity}}
                        per item | Order in multiples of {{configData?.activeViewItem?.quantityIncrement}}</div>
                    <div class="d-flex align-items-center mb-10 flex-wrap"
                        *ngIf="configData?.activeViewItem?.qtyAvailable || configData?.activeViewItem?.nextAvailableDate">
                        <div class="d-flex align-items-center mr-15"
                            *ngIf="configData?.activeViewItem?.qtyAvailable">
                            <span class="price-unit-txt">Available QTY :</span>
                            <strong
                                style="font-size: 16px;margin-left: 5px;">{{configData?.activeViewItem?.qtyAvailable}}</strong>
                        </div>
                        <div class="d-flex align-items-center"
                            *ngIf="configData?.activeViewItem?.nextAvailableDate">
                            <span class="price-unit-txt">Next Available Date :</span>
                            <strong style="font-size: 16px;margin-left: 5px;">{{
                                configData?.activeViewItem?.nextAvailableDate | rtDate: 'UTC'}}</strong>
                        </div>
                    </div>
                    <div class="mb-10 single-five-line overflow-custom-scroll"
                        *ngIf="configData?.activeViewItem?.description">{{configData?.activeViewItem?.description}}
                    </div>
                    <div class="mb-20" style="font-size: 14px;"
                        *ngIf="configData?.activeViewItem?.UPCObjString && (isLogin || isIframeWindow)">
                        <strong>UPC </strong>#{{configData?.activeViewItem?.UPCObjString}}
                    </div>
                    <!-- <div class="mb-10"><strong>Min </strong>6 for $10.50</div> -->
                    <!-- <div class="mb-10"><strong>Buy </strong>24 for $8.10</div> -->
                    <!-- <div class="mb-10"><strong>Buy </strong>48 for $6.00</div> -->
                    <div class="d-flex justify-content-between align-items-center mb-10 qty-sec">
                        <div class="item-volume-price d-flex mr-10"
                            *ngIf="isLogin && configData?.activeViewItem?.itemVolumePricings && configData?.activeViewItem?.itemVolumePricings.length">
                            <div class="item-volume-price-block d-flex flex-column"
                                *ngFor="let item of configData?.activeViewItem?.itemVolumePricings">
                                <span class="volume-price-label">{{item?.volumeQuantity}}
                                    qty</span>
                                <span class="volume-price-value">{{(item?.unitPrice ?
                                    item?.unitPrice : 0) | rtCurrency }}</span>
                            </div>
                        </div>
                        <div class="itemadd-action align-items-center" *ngIf="isLogin || isIframeWindow"
                            [ngClass]="{'disabled-action-control':isIframeWindow}">
                            <strong class="on-order-text">On Order</strong>
                            <div class="d-flex">
                                <a href="javascript:;"
                                    (click)='!isIframeWindow ? heartClickEvent(configData?.activeViewItem?.isWishedItem) : ""'
                                    class="single-like mr-20">
                                    <i *ngIf="configData?.activeViewItem?.isWishedItem"
                                        class="fa-heart item-heart fa item-red"></i>
                                    <i *ngIf="!configData?.activeViewItem?.isWishedItem" class="fa-heart item-heart far"></i>
                                </a>
                                <!-- <a href="javascritp:;" class="single-like mr-20"><i class="fa fa-heart item-heart" style="color: red;"></i></a> -->
                                <div class="cart-item-inc-block tbl_act mr-20">
                                    <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
                                    <div *ngIf="configData?.activeViewItem?.discontinued && (configData?.activeViewItem?.allowDiscontinuedItemsShopping === null || configData?.activeViewItem?.allowDiscontinuedItemsShopping === false)"
                                        pTooltip="Discounted item are restricted for order." tooltipZIndex="1100000">
                                        <button pButton type="button" [disabled]="true" class="ui-button-secondary ui-button"
                                            label="Discontinued"></button>
                                    </div>
                                    <ng-container
                                        *ngIf="(!configData?.activeViewItem?.discontinued || (configData?.activeViewItem?.discontinued === true && configData?.activeViewItem?.allowDiscontinuedItemsShopping === true)) && !(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true) && !b2bItemPricingPolicy">
                                        <div class="cart-item-inc-inner" style="justify-content: center;">
                                            <button (mouseup)="decrQtyEvent(configData?.activeViewItem?.quantity)" pButton
                                                type="button" icon="fas fa-minus" class="cart-minus cart-btn mr-5 add-cart-btn"
                                                [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"></button>
                                            <div class="cart-item-inc-input">
                                                <input type="number" pInputText pKeyFilter="pint" style="width: 50px;"
                                                    [ngModel]="configData?.activeViewItem?.quantity"
                                                    (ngModelChange)="configData?.activeViewItem.quantity=$event"
                                                    step="configData?.activeViewItem?.quantityIncrement"
                                                    (mousedown)="onclickSaveOldQty(configData?.activeViewItem?.quantity)"
                                                    (blur)="updateItemQty(configData?.activeViewItem?.quantity, 'self')"
                                                    min="0" />
                                            </div>
                                            <button (mouseup)="incrQtyEvent(configData?.activeViewItem?.quantity)" pButton
                                                type="button" icon="fas fa-plus" class="cart-add  cart-btn ml-5 add-cart-btn"
                                                [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"></button>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isLogin && checkAvailability">
                        <div class="my-ratting-sec">
                            <div class="ratting active-ratting">
                                <span
                                    [ngClass]="(myItemsRating >= 5)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                    (click)="!isIframeWindow ? saveItemsRating('5') : ''"></span>
                                <span
                                    [ngClass]="(myItemsRating >= 4)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                    (click)="!isIframeWindow ?saveItemsRating('4') : ''"></span>
                                <span
                                    [ngClass]="(myItemsRating >= 3)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                    (click)="!isIframeWindow ? saveItemsRating('3') : ''"></span>
                                <span
                                    [ngClass]="(myItemsRating >= 2)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                    (click)="!isIframeWindow ?saveItemsRating('2') : ''"></span>
                                <span
                                    [ngClass]="(myItemsRating >= 1)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                    (click)="!isIframeWindow ?saveItemsRating('1'): ''"></span>
                            </div>
                            <span class="ratting-loader" *ngIf="ratingLoader">
                                <i class="fa fa-spinner fa-spin" style="float: right;"></i>
                            </span>
                        </div>
                        <div class="my-comment-sec">
                            <p class="my-comment">{{itemGetReview}}</p>
                            <div class="comment-btn-sec">
                                <a href="javascript:;" class="edit-comment-btn"
                                    [pTooltip]="(reviewRecordID) ? 'Edit' : 'Write a Review'"
                                    (click)="!isIframeWindow ?editCommetsDialog(): ''">
                                    <span *ngIf="!reviewRecordID">Write a Review</span>
                                    <span *ngIf="reviewRecordID">Edit</span>
                                </a>
                                <a href="javascript:;" class="comments-sec-btn delete-comment" pTooltip="Delete"
                                    (click)="!isIframeWindow ? removeReview(): ''" *ngIf="reviewRecordID">
                                    Delete
                                </a>
                                <span class="ratting-loader" *ngIf="reviewDeleteLoader">
                                    <i class="fa fa-spinner fa-spin" style="float: right;"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- // [B2B-232] (MG-08-APR-2020) modified to do ui hotfix -->
                    <div class="itemadd-action mt-10 d-flex align-items-center"
                        *ngIf="(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true)">
                        <br><br><br>
                        <p style="color: #999; font-weight: bold;">Select image to view.</p>
                    </div>

                    
                    <div class="ratting-sec" *ngIf="isLogin">
                        <div class="ratting active-ratting" (click)="ratting_content.toggle($event)">
                            <span
                                [ngClass]="(avgItemsRating > 4 && avgItemsRating < 5)? 'ratting-star ratting-star-half' : (avgItemsRating >= 5)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                            <span
                                [ngClass]="(avgItemsRating > 3 && avgItemsRating < 4)? 'ratting-star ratting-star-half' : (avgItemsRating >= 4)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                            <span
                                [ngClass]="(avgItemsRating > 2 && avgItemsRating < 3)? 'ratting-star ratting-star-half' : (avgItemsRating >= 3)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                            <span
                                [ngClass]="(avgItemsRating > 1 && avgItemsRating < 2)? 'ratting-star ratting-star-half' : (avgItemsRating >= 2)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                            <span
                                [ngClass]="(avgItemsRating > 0 && avgItemsRating < 1)? 'ratting-star ratting-star-half' : (avgItemsRating >= 1)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                        </div>
                        <a href="javascript:;" class="comment-icon" (click)="commetsDialog()"
                            pTooltip="Buyer Reviews"><i class="fas fa-comments"></i></a>
                        <p class="global-reviews" *ngIf="totalReview > 0">Reviews<span class="global-reviews-value">
                                ({{commafy(totalReview)}})</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="item-size-table pt-8"
                *ngIf="activeViewItem.height || activeViewItem.width || activeViewItem.length || activeViewItem.weight">
                <div class="reptime-theme">
                    <p-card class="card">
                        <p-header class="card-header">
                            <h2 class="m-0" style="font-weight: bold;">Specifications</h2>
                        </p-header>
                        <div class="specification-container">
                            <div class="spec-header">
                                <div>Height <span
                                        *ngIf="activeViewItem.sizeUnitOfMeasure">({{activeViewItem.sizeUnitOfMeasure}})</span>
                                </div>
                                <div>Width <span
                                        *ngIf="activeViewItem.sizeUnitOfMeasure">({{activeViewItem.sizeUnitOfMeasure}})</span>
                                </div>
                                <div>Length <span
                                        *ngIf="activeViewItem.sizeUnitOfMeasure">({{activeViewItem.sizeUnitOfMeasure}})</span>
                                </div>
                                <div>Weight <span
                                        *ngIf="activeViewItem.weightUnitOfMeasure">({{activeViewItem.weightUnitOfMeasure}})</span>
                                </div>
                            </div>
                            <div class="spec-data">
                                <div>{{activeViewItem.height ? activeViewItem.height : '-'}}</div>
                                <div>{{activeViewItem.width ? activeViewItem.width : '-'}}</div>
                                <div>{{activeViewItem.length ? activeViewItem.length : '-'}}</div>
                                <div>{{activeViewItem.weight ? activeViewItem.weight : '-'}}</div>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
            <div class="item-size-table pt-8" *ngIf="isLogin || isIframeWindow">
                <div class="item-size-table-inner reptime-theme"
                    *ngIf="configData?.activeViewItem?.isc && configData?.activeViewItem?.isc.length"
                    [class.d-variation-btm]="(linkedItemsData && linkedItemsData.length) && !linkedItemsLoader">
                    <p-card class="card">
                        <p-header class="card-header">
                            <h2 class="m-0" style="font-weight: bold;">Different Variations</h2>
                        </p-header>
                        <p-table [columns]="singleItemCols" [value]="configData?.activeViewItem?.isc"
                            [autoLayout]="true" [responsive]="true">

                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" [pSortableColumn]="col.sortField"
                                        style="text-align: center;">
                                        {{col.header}}
                                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th style="width: 200px;text-align: center;">Quantity</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-i="rowIndex" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData">
                                    <td *ngFor="let col of columns; let j = index;" style="text-align: center;">
                                        <div [ngSwitch]="col.field">
                                            <div *ngSwitchCase="'fullPicturePath'" class="item-tbl-thumb">
                                                <div [ngClass]="{'show-loader':configData?.activeViewItem?.modelimageLoader}"
                                                    class="image-loader">
                                                    <div class="loader-icon"></div>
                                                </div>
                                                <img *ngIf="configData?.activeViewItem?.modelimageError"
                                                    src="{{configData?.activeViewItem?.manufacturerPath}}"
                                                    class="object-fit-cover wid-100" style="height:50px;max-width:100%;"
                                                    alt="{{configData?.activeViewItem?.itemNumber}}"
                                                    onerror="this.src='assets/images/no-image-available.png'">
                                                <img *ngIf="!configData?.activeViewItem?.modelimageError"
                                                    class="object-fit-cover wid-100" style="height:50px;max-width:100%;"
                                                    (error)="configData?.activeViewItem.modelimageLoader=false;configData?.activeViewItem.fullPicturePath = null;activeViewItem.modelimageError=true"
                                                    src="{{configData?.activeViewItem?.fullPicturePath}}"
                                                    alt="{{configData?.activeViewItem?.itemNumber}}">
                                            </div>
                                            <div *ngSwitchCase="'size'" style="color:#333;font-size: 15px;">
                                                {{(rowData[col.field] ? rowData[col.field] : null)}}
                                            </div>
                                            <div *ngSwitchCase="'color'" style="color:#333;font-size: 15px;">
                                                {{(rowData[col.field] ? rowData[col.field] : null)}}
                                            </div>
                                            <div *ngSwitchCase="'style'" style="color:#333;font-size: 15px;">
                                                {{(rowData[col.field] ? rowData[col.field] : null)}}
                                            </div>
                                        </div>
                                        <!-- <div *ngSwitchDefault>{{rowData[col.field]}}</div> -->
                                    </td>
                                    <td>
                                        <div class="cart-item-inc-block tbl_act"
                                            [ngClass]="{'disabled-action-control':isIframeWindow}">
                                            <div *ngIf="configData?.activeViewItem?.discontinued && (configData?.activeViewItem?.allowDiscontinuedItemsShopping === null || configData?.activeViewItem?.allowDiscontinuedItemsShopping === false)"
                                                pTooltip="Discounted item are restricted for order."
                                                tooltipZIndex="1100000">
                                                <button pButton type="button" [disabled]="true"
                                                    class="ui-button-secondary ui-button" label="Discontinued"></button>
                                            </div>
                                            <div class="cart-item-inc-inner" style="justify-content: center;"
                                                *ngIf="!configData?.activeViewItem?.discontinued || (configData?.activeViewItem?.discontinued === true && configData?.activeViewItem?.allowDiscontinuedItemsShopping === true)">
                                                <button (click)="!isIframeWindow ? decrSCSEvent(rowData, i) : ''"
                                                    [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[rowData.recordID +''+ rowData.itemID]"
                                                    pButton type="button" icon="fas fa-minus"
                                                    class="cart-minus cart-btn mr-5 add-cart-btn"></button>
                                                <!-- <div class="cart-item-inc-input">
                                                    <input type="number" onClick="this.select();" pInputText pKeyFilter="pint" [(ngModel)]="rowData.quantity" [tabindex]="i + 1"
                                                    (blur)="saveItemVariantToBasket(rowData, i, 'self')" style="width: 50px;" step="configData?.activeViewItem?.quantityIncrement" min="0"/>
                                                </div> -->
                                                <div class="cart-item-inc-input">
                                                    <input (blur)="updateSCSEvent(rowData, i)" type="number"
                                                        onClick="this.select();" pInputText pKeyFilter="pint"
                                                        [(ngModel)]="rowData.quantity" [tabindex]="i + 1"
                                                        [ngStyle]="{'background-color':minimumQtyMeetToVariantItem.length && minimumQtyMeetToVariantItem[i] === true ? '#ffe2e2' : ''}"
                                                        style="width: 50px"
                                                        step="configData?.activeViewItem?.quantityIncrement" min="0" />
                                                    <!--  -->
                                                </div>
                                                <button (click)="!isIframeWindow ? incrSCSEvent(rowData, i): ''"
                                                    [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[rowData.recordID +''+ rowData.itemID]"
                                                    pButton type="button" icon="fas fa-plus"
                                                    class="cart-add  cart-btn ml-5 add-cart-btn"></button>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>

                        </p-table>
                    </p-card>
                </div>
                <!-- B2B-327 (NL 05-OCT-2020) add similar item table -->
                <div class="item-size-table-inner reptime-theme"
                    *ngIf="(linkedItemsData && linkedItemsData.length) && !linkedItemsLoader && isLogin">
                    <p-card class="card">
                        <p-header class="card-header">
                            <h2 class="m-0" style="font-weight: bold;">Similar Items</h2>
                        </p-header>
                        <p-table [columns]="linkItemCols" [value]="linkedItemsData" [autoLayout]="true"
                            [responsive]="true">

                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" [pSortableColumn]="col.sortField"
                                        style="text-align: center;" [style.width]="col.width">
                                        {{col.header}}
                                        <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th style="width: 200px;text-align: center;">Quantity</th>
                                    <th style="width: 120px;text-align: center;">Action</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-i="rowIndex" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData">
                                    <td *ngFor="let col of columns; let j = index;" style="text-align: center;">
                                        <div [ngSwitch]="col.field">
                                            <div *ngSwitchCase="'fullPicturePath'" class="item-tbl-thumb">
                                                <div [ngClass]="{'show-loader':configData?.activeViewItem?.modelimageLoader}"
                                                    class="image-loader">
                                                    <div class="loader-icon"></div>
                                                </div>
                                                <!-- <img *ngIf="configData?.activeViewItem?.modelimageError" src="assets/images/no-image-available.png"
                                                    class="object-fit-cover wid-100" style="height:50px;max-width:100%;"
                                                    alt="{{configData?.activeViewItem?.itemNumber}}"> -->
                                                <img *ngIf="rowData.activeImglinkedError"
                                                    src="{{configData?.activeViewItem?.manufacturerPath}}"
                                                    class="object-fit-cover wid-100" style="height:50px;max-width:100%;"
                                                    onerror="this.src='assets/images/no-image-available.png'">
                                                <img class="object-fit-cover wid-100"
                                                    *ngIf="!rowData.activeImglinkedError"
                                                    style="height:50px;max-width:100%;" src="{{rowData[col.field]}}"
                                                    (error)="rowData[col.field] = null;rowData.activeImglinkedError=true">
                                            </div>
                                            <div *ngSwitchCase="'name'" style="color:#333;font-size: 15px;"
                                                class="linkitemname" [style.width]="col.width"
                                                pTooltip="{{(rowData[col.field] ? rowData[col.field] : null)}}"
                                                tooltipPosition="top" tooltipZIndex="1000000">
                                                {{(rowData[col.field] ? rowData[col.field] : null)}}
                                            </div>
                                            <div *ngSwitchCase="'description'" style="color:#333;font-size: 15px;"
                                                class="linkitemdesc" [style.width]="col.width"
                                                pTooltip="{{rowData[col.field]}}" tooltipPosition="top"
                                                tooltipZIndex="1000000">
                                                {{(rowData[col.field] ? rowData[col.field] : null)}}
                                            </div>
                                            <div *ngSwitchCase="'minimumQuantity'" style="color:#333;font-size: 15px;">
                                                {{(rowData[col.field] ? rowData[col.field] : null)}}
                                            </div>
                                            <div *ngSwitchCase="'unitPrice'" style="color:#333;font-size: 15px;">
                                                <ng-container *ngIf="!rowData?.b2bItemPricingPolicyFlag">
                                                    {{(rowData[col.field] ? rowData[col.field] : null)}}
                                                </ng-container>
                                            </div>
                                        </div>
                                        <!-- <div *ngSwitchDefault>{{rowData[col.field]}}</div> -->
                                    </td>
                                    <td>
                                        <div class="cart-item-inc-block tbl_act" *ngIf="!rowData?.isc.length">
                                            <ng-container
                                                *ngIf="!(auth.getParentItemShoppingFlag() === false && rowData?.isParentItem === true)">
                                                <div *ngIf="rowData.discontinued && (rowData.allowDiscontinuedItemsShopping === null || rowData.allowDiscontinuedItemsShopping === false)"
                                                    pTooltip="Discounted item are restricted for order."
                                                    tooltipZIndex="1100000">
                                                    <button pButton type="button" [disabled]="true"
                                                        class="ui-button-secondary ui-button"
                                                        label="Discontinued"></button>
                                                </div>
                                                <div class="cart-item-inc-inner" style="justify-content: center;"
                                                    *ngIf="(!rowData.discontinued || (rowData.discontinued === true && rowData.allowDiscontinuedItemsShopping === true)) && !rowData?.b2bItemPricingPolicyFlag">
                                                    <button (click)="!isIframeWindow ? decrLinkedEvent(rowData, i): ''"
                                                        (mousedown)="onclickSaveOldQtysimillar(rowData.quantity)"
                                                        [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[rowData.recordID +''+ rowData.itemID]"
                                                        pButton type="button" icon="fas fa-minus"
                                                        class="cart-minus cart-btn mr-5 add-cart-btn"></button>
                                                    <!-- <div class="cart-item-inc-input">
                                                        <input type="number" onClick="this.select();" pInputText pKeyFilter="pint" [(ngModel)]="rowData.quantity" [tabindex]="i + 1"
                                                        (blur)="saveItemVariantToBasket(rowData, i, 'self')" style="width: 50px;" step="configData?.activeViewItem?.quantityIncrement" min="0"/>
                                                    </div> -->
                                                    <div class="cart-item-inc-input">
                                                        <input (mousedown)="onclickSaveOldQtysimillar(rowData.quantity)"
                                                            (blur)="updateLinkedEvent(rowData, i)" type="number"
                                                            onClick="this.select();" pInputText pKeyFilter="pint"
                                                            [(ngModel)]="rowData.quantity"
                                                            (ngModelChange)="rowData.quantity=$event" [tabindex]="i + 1"
                                                            style="width: 50px;"
                                                            step="linkedItemsData[i]?.quantityIncrement" min="0" />
                                                    </div>
                                                    <button (mousedown)="onclickSaveOldQtysimillar(rowData.quantity)"
                                                        (click)="!isIframeWindow ? incrLinkedEvent(rowData, i): ''"
                                                        [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[rowData.recordID +''+ rowData.itemID]"
                                                        pButton type="button" icon="fas fa-plus"
                                                        class="cart-add  cart-btn ml-5 add-cart-btn"></button>

                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="(auth.getParentItemShoppingFlag() === false && rowData?.isParentItem === true)">
                                                <div *ngIf="rowData?.isParentItem"
                                                    pTooltip="Parent item are restricted for order."
                                                    tooltipZIndex="1100000">
                                                    <button pButton type="button" [disabled]="true"
                                                        class="ui-button-secondary ui-button"
                                                        label="Parent Item"></button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="cart-item-inc-block tbl_act">
                                            <div class="cart-item-inc-inner" style="justify-content: center;">
                                                <button pButton type="button" label="View" icon="fa fa-eye"
                                                    (click)="selectlinkedItemsData(rowData, false)"
                                                    class="btn-theme-color"></button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>

                        </p-table>
                    </p-card>
                </div>
                <!-- B2B-327 (NL 05-OCT-2020) comment button -->
                <!-- <div style="text-align: right;margin-top: 10px;" class="d-none"> -->
                <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
                <!-- <ng-container *ngIf="!(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true)">
                        <p-button (onClick)="addOrder(configData?.activeViewItem)" type="button" [styleClass]="'btn-theme-color mr-5'" label="Add to  order" [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID] || isAddButtonDisable" [icon]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID] ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-plus'" ></p-button>
                    </ng-container>
                    <button pButton type="button" label="Close" icon="fa fa-close" [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]" (click)="isOpenChange.emit(false);" class="theme-maroon-btn"></button>
                </div> -->
            </div>

        </div>
    </div>
    <!-- B2B-327 (NL 05-OCT-2020) add button in footer -->
    <p-footer>
        <div class="itemdetailpopup-footer"
            *ngIf="!linkedItemsLoader">
            <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
            <ng-container
                *ngIf="!(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true) && !b2bItemPricingPolicy && (isLogin && !isIframeWindow)">
                <p-button (onClick)="addOrder(configData?.activeViewItem)" type="button" *ngIf="isLogin"
                    [styleClass]="'btn-theme-color mr-5'" label="Add to order" [ngClass]="{'disabled-action-control':isIframeWindow}"
                    [disabled]="(configData?.activeViewItem?.discontinued && (configData?.activeViewItem?.allowDiscontinuedItemsShopping === null || configData?.activeViewItem?.allowDiscontinuedItemsShopping === false)) ||  (disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID] || isAddButtonDisable"
                    [icon]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID] ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-plus'">
                </p-button>
                <!--  |Add to  order| button now remove from disabled for isAddButtonDisable - [B2B-364]  -->
            </ng-container>
            <ng-container *ngIf="isLogin && b2bItemPricingPolicy">
                <button pButton type="button"
                    [ngClass]="configData?.activeViewItem?.retailerRequestStatus === 0 ? 'sign-login-disable' : 'btn-theme-color'"
                    label="Send Request for Approval" [disabled]="isAddButtonDisable"
                    [icon]="isAddButtonDisable ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-paper-plane'"
                    (click)="isAddButtonDisable = false;sendRequest()"></button>
            </ng-container>
            <!-- [RC-1965] login/signup button hide/show start -->
            <a pButton type="button" label="Sign Up or Login for Pricing" icon="fa fa-user-plus" class="btn-theme-color"
                *ngIf="(!isLogin || isIframeWindow) && (auth.getdefaultSignupButtonFlag())" target="_blank" [routerLink]="['/signup']"></a>
            <a pButton type="button" label="Sign Up or Login for Pricing" icon="fa fa-user-plus" class="btn-theme-color"
                *ngIf="(!isLogin || isIframeWindow) && (!auth.getdefaultSignupButtonFlag() && auth.getCustomSignupButtonData().url)" target="_blank" href="{{auth.getCustomSignupButtonData().url}}"></a>
            <!-- [RC-1965] login/signup button hide/show end -->
            <button pButton type="button" label="Close" icon="fa fa-close"
                [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"
                (click)="isOpenChange.emit(false);" class="theme-maroon-btn"></button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Item already exist in basket." [modal]="true" [draggable]="false" [(visible)]="isItemAlreadyAvailable"
    [resizable]="false">
    <div class="p-5">Selected item already available. Please update from basket.</div>
    <div class="text-center m-5">
        <button (click)="isItemAlreadyAvailable = false" [label]="'OK'" class="ui-button-raised" pButton
            type="button"></button>
    </div>
</p-dialog>
<p-dialog header="Buyer Reviews" [(visible)]="commets_Dialog" [modal]="true" [styleClass]="'comments-dialog'"
    [draggable]="false" [baseZIndex]="11010" [appendTo]="'body'" [resizable]="false">
    <ng-container *ngIf="showReviewLoader">
        <div class="center main-loader">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
        </div>
    </ng-container>
    <div class="comments-sec" *ngIf="!showReviewLoader">
        <ng-container *ngFor="let reviews of getAllReviews;let i =index;">
            <div class="comment" *ngIf="getAllReviews.length">
                <div class="user-name">{{reviews.retailer.name}}
                    <span class="user-info">{{reviews.retailer.city}}, {{reviews.retailer.state}},
                        {{reviews.retailer.zip}}</span>
                    <span class="comment-date">{{formatDate(reviews.dateModified)}}</span>
                </div>
                <p class="comment-text">{{reviews.review}}</p>
            </div>
        </ng-container>
        <div *ngIf="!getAllReviews.length">
            <p class="text-center"><b>No reviews yet for this item. Please check back later.</b><br>Buyers who have
                previously purchased this item may write a review.</p>
        </div>
    </div>
</p-dialog>
<p-dialog header="Write a Review" [(visible)]="edit_commets" (onHide)="isCloseReviews(true)" [closeOnEscape]="false"
    [modal]="true" [draggable]="false" [styleClass]="'edit-dialog'" [baseZIndex]="11010" [appendTo]="'body'"
    [resizable]="false">
    <div class="edit-comments-sec">
        <textarea pInputTextarea appCustomValidation [allowStartWithSpace]="false" [(ngModel)]="itemReview"
            [maxlength]="1000"></textarea>
        <div style="text-align: right;">{{itemReview?itemReview.length:0}}/1000</div>
        <button pButton type="button" label="Save" [disabled]='saveLoading'
            icon="{{!saveLoading?'fa fa-save':'fa fa-spinner fa-spin'}}" class="theme-maroon-btn edit-save-btn"
            (click)="saveReviewMfg()"></button>
    </div>
</p-dialog>

<p-dialog header="360 Preview" [(visible)]="display360Preview" [modal]="true" [blockScroll]="true" [baseZIndex]="11010"
    [contentStyle]="{'max-width': '1310px'}" [responsive]="true" [dismissableMask]="true" [closeOnEscape]="true" *ngIf="display360Preview">
    <div id="controls">
        <mm-three-sixty #threeSixty [width]="(innerWidth > 1310) ? 1280 : innerWidth" [height]="innerHeight" [angles]="72" [anglesPerImage]="1" [speedFactor]="2"
            [startAngle]="1" [images]="images360Preview" [preload]="true" (preloaded)="onPreloaded()"></mm-three-sixty>
        <div class="d-360-spinner" *ngIf="!is360ViewLoaded">
            <i class="pi pi-spin pi-spinner"></i>
        </div>
    </div>
</p-dialog>

<p-overlayPanel #ratting_content [baseZIndex]="12001" [styleClass]="'ratting-info-sec'" [appendTo]="'body'">
    <div class="ratting-info-inner">
        <div class="ratting-avrg">
            <div class="ratting active-ratting">
                <!-- active-ratting class is for active ratting -->
                <!-- unactive-ratting class is for unactive ratting -->
                <!-- fa-star-half-alt -->
                <span
                    [ngClass]="(avgItemsRating > 4 && avgItemsRating < 5)? 'ratting-star ratting-star-half' : (avgItemsRating >= 5)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                <span
                    [ngClass]="(avgItemsRating > 3 && avgItemsRating < 4)? 'ratting-star ratting-star-half' : (avgItemsRating >= 4)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                <span
                    [ngClass]="(avgItemsRating > 2 && avgItemsRating < 3)? 'ratting-star ratting-star-half' : (avgItemsRating >= 3)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                <span
                    [ngClass]="(avgItemsRating > 1 && avgItemsRating < 2)? 'ratting-star ratting-star-half' : (avgItemsRating >= 2)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                <span
                    [ngClass]="(avgItemsRating > 0 && avgItemsRating < 1)? 'ratting-star ratting-star-half' : (avgItemsRating >= 1)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
            </div>
            <div class="out-of-text"><span class="out-of">{{avgItemsRating}}</span> out of 5</div>
        </div>
        <p class="global-ratting"><span class="global-ratting-value">{{commafy(totalRate)}}</span> global ratting</p>
        <ul class="ratting-info">
            <li>
                <span class="ratting-info-label">5 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="fiveStarPerc"></p-progressBar>
                </div>
            </li>
            <li>
                <span class="ratting-info-label">4 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="fourStarPerc"></p-progressBar>
                </div>
            </li>
            <li>
                <span class="ratting-info-label">3 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="threeStarPerc"></p-progressBar>
                </div>
            </li>
            <li>
                <span class="ratting-info-label">2 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="twoStarPerc"></p-progressBar>
                </div>
            </li>
            <li>
                <span class="ratting-info-label">1 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="oneStarPerc"></p-progressBar>
                </div>
            </li>
        </ul>
    </div>
</p-overlayPanel>