import {RepGroupRetailer} from './rep-group-retailer';

export class Retailer {
    recordID: string;
    active: boolean;
    address1: string;
    address2: string;
    approved: boolean;
    city: string;
    companyCategory: string;
    country: string;
    dateAdded: number;
    dateModified: number;
    email: string;
    fax: string;
    faxCountryCode: string;
    faxExtension: string;
    federalTaxNumber: string;
    hasCompanyLogo: boolean;
    name: string;
    notes: string;
    phone: string;
    phoneCountryCode: string;
    phoneExtension: string;
    recordDeleted: boolean;
    salesTaxNumber: string;
    state: string;
    stateTaxNumber: string;
    status: string;
    userAdded: number;
    userModified: number;
    website: string;
    zip: string;
    repGroupRetailer: RepGroupRetailer;
}
