import { async } from '@angular/core/testing';
import { Component, ElementRef, OnInit, OnChanges, Input, ViewChild, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ManufacturerService } from '../../../services/api/manufacturer.service';
import { RTSharedService } from '../../../services/rtshared/rtshared.service';
import * as objectPath from 'object-path';
import * as _ from 'lodash';
import { AuthService } from '../../../services/middleware/auth/auth.service';
import { SidebarApiService } from '../../../services/api/sidebar-api/sidebar-api.service';
import { RepgroupService } from 'src/app/services/api/repgroup.service';
import { RepgroupCategoryList, RepgroupCategory, RepGroupSubCategory, DefaultSubCategory } from 'src/app/models/reptime-category';
import { OverlayPanel, Slider } from 'primeng/primeng';
import $ from 'jquery';
import { RetailerService } from 'src/app/services/middleware/retailer/retailer.service';
import { CompanyService } from '../../../services/api/company.service';
import { takeUntil } from 'rxjs/operators';
import { APIResponse } from '../../../models/api-response';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  win: any = (window);
  public isIframeWindow: any = (window as any).iframeData;
  public domainData: any = this.win.domainData;
  rangeValues: number[] = [10, 1000];
  rangeMinMaxArray: number[] = [0, 1000];
  items: MenuItem[];
  public hideVendorPriceArray = ['/home', '/finalize-order'];
  public showVendorPriceCard = true;
  public repGroupID: string;
  public manufacturersArr: any = [];
  public chkMFG: any;
  public activeRouteQP: any;
  public qpObject: any;
  repgroupCategories: RepgroupCategory[] = [];
  @Input() navigatedURL: any;
  @Input() whichPage: any;
  public sidebarCategoryShimmer = false;
  public sidebarmanufacturerShimmer = false;
  public subMenuShow = false;
  public selectedMenu = '';
  public subCat: any;
  public mainCat: any;
  public isQuickOrder: any;
  public unChkAllMfg = false;
  public newArrival = '';
  public newBestSeller = '';
  public subscriptionRetailerData: Subscription;
  isVenderSelectFromRepgroup = false;
  showCategoryFilterBox = true;
  @ViewChild('rangeSlider', { static: false }) rangeSlider: Slider;
  @Input() pageType: any;
  @Input() pageAllType: any;
  private readonly onDestroy1 = new Subject<void>();
  repGroupManufacturers = []
  selectedmfrdetail = null;
  isLogin = false;
  manufacturerID: any;
  headerHeight: any;
  stickyTop;
  currencySymbol = environment.currencySymbol;
  public colorOptions = this.auth.getColorSelectionSettings();
  public colorModel = [];
  constructor(
    private router: Router, private activeRoute: ActivatedRoute, private manufacturerService: ManufacturerService, public auth: AuthService,
    private sidebarApiService: SidebarApiService, public rtSharedService: RTSharedService, private retailerService: RetailerService,
    private messageService: MessageService,
    private repGroupService: RepgroupService, private msgService: MessageService, public companyService: CompanyService, private elemRef: ElementRef) {
    // B2B-74 (RK 13-FEB-2020): VendorPriceCard show conditionally
    this.showSideWidgetMethod();
    this.isLogin = this.auth.getIDToken();
    this.repGroupID = this.rtSharedService.getRepGroupID();
    // B2B-75 (RK 15-FEB-2020): Get active route queryParams when navigate route
    this.activeRoute.queryParams.subscribe((qp) => {
      if (qp.new) {
        this.newArrival = 'newArrival';
      } else {
        this.newArrival = '';
      }

      if (qp.bestseller) {
        this.newBestSeller = 'newBestSeller';
      } else {
        this.newBestSeller = '';
      }

      if (qp.mfg && qp.mfg.length) {
        this.chkMFG = qp.mfg.split(',');
      } else {
        this.chkMFG = [];
      }

      if (qp.color && qp.color.length) {
        this.colorModel = qp.color.split(',');
        this.colorModel = this.colorModel.map((item) => {
          return item;
        });
      } else {
        this.colorModel = [];
      }

      this.activeRouteQP = qp;
    });
    this.subscriptionRetailerData = this.retailerService.getRetailerDataEvent().subscribe(async (retailer) => {
      console.log(this.router.url);
      console.log('retailerretailerretailer', retailer);
      if (this.router.url.indexOf('/shop') > -1 || this.router.url.indexOf('/item-detail') > -1) {
        this.getManufacturersByRepGroup(retailer);
      }
      if (this.router.url.indexOf('/store') > -1 || this.router.url.indexOf('/item-detail') > -1) {
        this.getManufacturersByRepGroupPublic();
      }
    });
    this.isVenderSelectFromRepgroup = (this.auth.getSiteTheme().categoryGroupType === 'vendors') ? true : false;
    this.showCategoryFilterBox = this.auth.getSiteTheme().showCategoryFilterBox;
  }
  ngOnInit() {
    if (this.isVenderSelectFromRepgroup === false) {
      this.manufacturerID = this.activeRoute.snapshot.queryParams.mfg ? this.activeRoute.snapshot.queryParams.mfg : null;
      let manufacturerID = _.cloneDeep(this.manufacturerID);
      if (this.whichPage === 'shop' || this.whichPage === 'store') { manufacturerID = null; }
      if (!this.pageType) {
        this.getCategoriesList(manufacturerID);
      } else {
        this.getCategoriesListPublic(manufacturerID);
      }
    }
    if (this.domainData && this.auth.getProjectType() === 2) {
        this.stickyTop = document.getElementById('lightovation-header');
        this.headerHeight = '80px';
    } else {
        this.stickyTop = document.getElementById('b2bheader');
        this.headerHeight = '123px';
    }

    // if (this.router.url.indexOf('/shop') > -1) {
    //   this.getManufacturersByRepGroup();
    // }
    this.setPriceFilterValue();
  }
  ngAfterViewInit() {
    this.isIframeWindow = this.win.iframeData;
  }
  ngOnDestroy() {
    this.subscriptionRetailerData.unsubscribe();
    this.onDestroy1.next();
  }
  ngOnChanges(changes: any) {
    if (changes) {
      const qpObject = Object.assign({}, this.activeRouteQP);
      if (!qpObject.fromprice && qpObject.toprice) {
        this.rangeValues = [this.rangeMinMaxArray[0], qpObject.toprice];
      }
      if (qpObject.fromprice && !qpObject.toprice) {
        this.rangeValues = [qpObject.fromprice, this.rangeMinMaxArray[1]];
      }
      if (!qpObject.fromprice && !qpObject.toprice) {
        this.rangeValues = [this.rangeMinMaxArray[0], this.rangeMinMaxArray[1]];
      }
      // B2B-159 (CB 12-MAR-2020): Filter sidemenu active and deactive
      this.subCat = this.activeRoute.snapshot.queryParams.s;
      this.mainCat = this.activeRoute.snapshot.queryParams.c;
      // B2B-74 (RK 13-FEB-2020): VendorPriceCard show conditionally when navigate url
      this.showSideWidgetMethod();
      this.sidebarApiService.setMfgEvent(this.manufacturersArr);
      this.sidebarApiService.setMenuEvent(this.repgroupCategories);
      this.isQuickOrder = this.activeRoute.snapshot.queryParams.quick;
      this.scrollCategoryBar('');
    }
  }
  openOverlay(event, op: OverlayPanel, data) {
    console.log('event, op', event, op, data);
    this.selectedmfrdetail = data;
    op.toggle(event);
  }
  sendRequest() {
    this.manufacturerService.sendMfrRequest(this.selectedmfrdetail).subscribe((res: any) => {
      this.messageService.add({
        severity: 'info',
        summary: 'Send Request',
        detail: `Send Request successfully.`
      });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    });
  }
  showSideWidgetMethod() {
    this.showVendorPriceCard = true;
    this.hideVendorPriceArray.forEach(k => {
      if (this.router.url.indexOf(k) > -1) {
        this.showVendorPriceCard = false;
        return;
      }
    });
  }
  checkActiveCatState(url) {
    if (this.router.url.indexOf(url) === -1) {
      return false;
    } else {
      return true;
    }
  }
  fromPrice($event) {
    if (this.rangeMinMaxArray[1] <= this.rangeMinMaxArray[0]) {
      this.rangeMinMaxArray[0] = 0;
    } else {
      this.rangeMinMaxArray[0] = Number($event.target.value);
    }
    this.rangeValues[0] = this.rangeMinMaxArray[0];
    this.rangeSlider['handleValues'][0] = 0;
  }
  toPrice($event) {
    if (this.rangeMinMaxArray[1] <= this.rangeMinMaxArray[0]) {
      this.rangeMinMaxArray[1] = 1000;
    } else {
      this.rangeMinMaxArray[1] = Number($event.target.value);
    }
    this.rangeValues[1] = this.rangeMinMaxArray[1];
    this.rangeSlider['handleValues'][1] = 100;
  }
  priceButton($event) {
    // B2B-75 (RK 15-FEB-2020): From and To price filter Navigator
    const qpObject = Object.assign({}, this.activeRouteQP);
    qpObject.fromprice = this.rangeValues[0];
    qpObject.toprice = this.rangeValues[1];
    if (this.whichPage) {
      if (qpObject && qpObject.itemdetails) {
        delete qpObject.itemdetails;
      }
      this.router.navigate([`/${this.whichPage}`], { queryParams: qpObject });
    }
  }
  updateNewArrival() {
      const qpObject = Object.assign({}, this.activeRouteQP);
      if (this.newArrival) {
        qpObject.new = true;
      } else {
        delete qpObject.new;
      }
      if (this.whichPage) {
        if (qpObject && qpObject.itemdetails) {
          delete qpObject.itemdetails;
        }
        this.router.navigate([`/${this.whichPage}`], { queryParams: qpObject });
      }
  }
  updateBestSeller() {
    const qpObject = Object.assign({}, this.activeRouteQP);
    if (this.newBestSeller) {
      qpObject.bestseller = true;
    } else {
      delete qpObject.bestseller;
    }
    if (this.whichPage) {
      if (qpObject && qpObject.itemdetails) {
        delete qpObject.itemdetails;
      }
      this.router.navigate([`/${this.whichPage}`], { queryParams: qpObject });
    }
  }
  getQueryParamsUrl(catId, subCatId, calloutId?: any) {
    const qpObject = Object.assign({}, this.activeRouteQP);
    if (calloutId) {
      qpObject.c = catId;
      qpObject.s = subCatId;
      qpObject.callout = calloutId;
      return { queryParams: qpObject };
    } else {
      qpObject.c = catId;
      qpObject.s = subCatId;
      return { queryParams: qpObject };
    }
  }
  updateChangesMade($event) {
    // B2B-75 (RK 15-FEB-2020): MFG filter navigate
    const qpObject = Object.assign({}, this.activeRouteQP);
    if (this.chkMFG && this.chkMFG.length) {
      qpObject.mfg = this.chkMFG.toString();
      this.unChkAllMfg = false;
    } else {
      delete qpObject.mfg;
    }
    if (this.whichPage) {
      if (qpObject && qpObject.itemdetails) {
        delete qpObject.itemdetails;
      }
      this.router.navigate([`/${this.whichPage}`], { queryParams: qpObject });
    }
  }
  colorOnChange($event) {
    const qpObject = Object.assign({}, this.activeRouteQP);
    if (this.colorModel && this.colorModel.length) {
      qpObject.color = this.colorModel.toString();
    } else {
      delete qpObject.color;
    }
    if (this.whichPage) {
      if (qpObject && qpObject.itemdetails) {
        delete qpObject.itemdetails;
      }
      this.router.navigate([`/${this.whichPage}`], { queryParams: qpObject });
    }
  }
  getManufacturersByRepGroup(retailerData?: any) {
    this.sidebarmanufacturerShimmer = true;
    this.manufacturerService.getManufacturersByRepGroup(retailerData).subscribe(async (res) => {
      this.sidebarmanufacturerShimmer = false;
      if (this.rtSharedService.getProjectName() === 'B2B') {
        this.manufacturersArr = res.data.filter(o => {
          if (o.repGroupManufacturers && o.repGroupManufacturers.length) {
            o.showlock = this.checkmfglock(o.repGroupManufacturers[0])
            if (o.repGroupManufacturers[0].showOnWebsite === true) {
              return { recordID: o.recordID, name: o.name, showlock: this.checkmfglock(o.repGroupManufacturers[0]) };
            }
          }
        });
      } else {
        this.manufacturersArr = res.data.map(o => {
          return { recordID: o.recordID, name: o.name };
        });
      }
      console.log('array1112', this.manufacturersArr );

      this.sidebarApiService.setMfgEvent(this.manufacturersArr);
    });
  }
  checkmfglock(data) {
    if (data && !data.showLockedB2bItems && data.b2bItemPricingPolicy === 'NeedBrandApproval') {
      return 1;
    }
    return 0;
  }
  getManufacturersByRepGroupPublic() {
    this.companyService.getRepGroupBrandsList().pipe(takeUntil(this.onDestroy1)).subscribe((res: APIResponse) => {
      this.sidebarmanufacturerShimmer = true;
      if (res.data) {
        this.sidebarmanufacturerShimmer = false;
        this.manufacturersArr = res.data.map(o => {
          o.repGroupManufacturers = [{ recordID: o.recordID, name: o.repGroupManufacturerName }];
          return o;
        });
      } else {
        this.sidebarmanufacturerShimmer = false;
      }
      console.log('array1111', this.manufacturersArr );
      this.sidebarApiService.setMfgEvent(this.manufacturersArr);
    }, (error) => {
      this.sidebarmanufacturerShimmer = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: objectPath.get(error, 'message', 'Error occurred during services request') });
    });
  }

  getCategoriesList(manufacturerID) {
    this.sidebarCategoryShimmer = true;
    this.repGroupService.getRepgroupCategories(this.domainData.repGroup.recordID, manufacturerID).subscribe(async (response: RepgroupCategoryList) => {

      this.sidebarCategoryShimmer = false;
      if (response.success) {
        this.repgroupCategories = response.data.map((d) => {
          return d;
        });
        this.updateSideBarMenu();
      } else {
        this.msgService.add({
          severity: 'error', summary: 'Error',
          detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
        });
      }
      this.sidebarApiService.setMenuEvent(this.repgroupCategories);
    }, (error) => {
      this.msgService.add({
        severity: 'error', summary: 'Error',
        detail: objectPath.get(error, 'message', 'Error occurred during services request')
      });
    });
  }
  setPriceFilterValue() {
    const qpObject = Object.assign({}, this.activeRouteQP);
    qpObject.fromprice ? this.rangeValues[0] = qpObject.fromprice : this.rangeValues;
    qpObject.toprice ? this.rangeValues[1] = qpObject.toprice : this.rangeValues;
  }
  updateSideBarMenu() {
    this.items = [];
    Object.keys(this.repgroupCategories).forEach((k) => {
      this.items.push({
        label: this.repgroupCategories[k].categoryName,
        id: this.repgroupCategories[k].recordID,
        // expanded: this.checkActiveCatState(`c=${this.repgroupCategories[k].recordID}`),
        items: []
      });
      if (!this.repgroupCategories[k].repGroupSubCategories.length) {
        this.items[k].styleClass = 'noSubMenu';
      }
      const subObjValue = this.repgroupCategories[k].repGroupSubCategories.map(o => {
        return {
          label: o.defaultSubCategory.name,
          id: o.recordID,
          // command: (event) => {
          //
          // this.router.navigate(['/shop'], {queryParams: this.getQpObject(this.repgroupCategories[k], o)});
          // }
        };
      });
      this.items[k].items = subObjValue;
    });
    this.items = this.items;

    this.scrollCategoryBar('');
  }
  // getQpObject(catlist, subcatlist?: any) {
  //   this.qpObject = Object.assign({}, this.activeRouteQP);
  //   // B2B-75 (RK 14-FEB-2020): Handle PanelMenuUrl callout with and without in shop page
  //   let calloutId;
  //   if (this.router.url.indexOf('callout=') > -1) {
  //     calloutId = this.activeRoute.snapshot.queryParams.callout;
  //   } else {
  //     calloutId = null;
  //   }
  //   if (catlist.recordID) {
  //       this.qpObject.c = catlist.recordID;
  //       delete this.qpObject.s;
  //   }
  //   if (subcatlist && subcatlist.recordID) {
  //       this.qpObject.c = catlist.recordID;
  //       this.qpObject.s = subcatlist.recordID;
  //   }
  //   if (!calloutId) {
  //     delete this.qpObject.callout;
  //   }
  //   return this.qpObject;
  // }

  // B2B-161 (CB 14-FEB-2020): Handle PanelMenuUrl callout with and without in shop page
  openSubMenu(index) {
    if (this.selectedMenu === index) {
      this.selectedMenu = '';
    } else {
      this.selectedMenu = index;
    }
  }

  filer(cId, sId, event) {
    if (this.whichPage) {
      this.router.navigate([`/${this.whichPage}`], { queryParams: this.getQpObject(cId, sId) }).then((res) => {
        this.scrollCategoryBar(event);
      });
    }
  }

  getQpObject(cId, sId) {
    this.mainCat = cId;
    this.subCat = sId;
    this.qpObject = Object.assign({}, this.activeRouteQP);
    // delete this.qpObject.group;
    let calloutId;
    if (this.router.url.indexOf('callout=') > -1) {
      calloutId = this.activeRoute.snapshot.queryParams.callout;
    } else {
      calloutId = null;
    }
    if (cId) {
      this.qpObject.c = cId;
      delete this.qpObject.s;
      // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
      delete this.qpObject.g;
    }
    if (sId) {
      this.qpObject.s = sId;
      // MM-138 (RK 06-APRIL-2021) => B2B and Marketplace Group Redirection
      delete this.qpObject.g;
    }
    if (this.qpObject && this.qpObject.itemdetails) {
      delete this.qpObject.itemdetails;
    }
    if (!calloutId) {
      delete this.qpObject.callout;
    }
    console.log('qpObjectqpObject', this.qpObject);
    return this.qpObject;
  }

  unCheckAllMfg(event) {
    if (event === true) {
      this.chkMFG = [];
    }
    this.updateChangesMade(0);
  }

  scrollCategoryBar(e) {
    setTimeout(() => {
      const index = _.findIndex(this.items, { id: +this.mainCat });
      try {
        if (this.mainCat && !this.subCat) {
          $('#categoryDiv .ui-scrollpanel-content').animate({
            scrollTop: document.getElementById(`main${index}${this.mainCat}`).offsetTop
          });
        } else if (this.mainCat && this.subCat) {
          this.selectedMenu = this.activeRoute.snapshot.queryParams.c;
          $('#categoryDiv .ui-scrollpanel-content').animate({
            scrollTop: document.getElementById(`main${index}${this.subCat}`).offsetTop
          });
        } else {
          this.selectedMenu = '';
          $('#categoryDiv .ui-scrollpanel-content').animate({
            scrollTop: 0
          });
        }
      } catch (e) {
      }
    }, 0);
  }
  // filter hide
  close_sidebar() {
      $('.b2b-sidebar').removeClass('show_sidebar');
      $('body,html').removeClass('overflow-hidden');
  }

  view_all_btn() {
      $('body,html').removeClass('overflow-hidden');
  }

  getCategoriesListPublic(manufacturerID) {
    this.sidebarCategoryShimmer = true;
    this.companyService.getRepgroupCategoriesListPublic(manufacturerID).subscribe(async (response: RepgroupCategoryList) => {

      this.sidebarCategoryShimmer = false;
      if (response.success) {
        this.repgroupCategories = response.data.map((d) => {
          return d;
        });
        this.updateSideBarMenu();
      } else {
        this.msgService.add({
          severity: 'error', summary: 'Error',
          detail: objectPath.get(response, 'error.message', 'Error occurred during services request')
        });
      }
      this.sidebarApiService.setMenuEvent(this.repgroupCategories);
    }, (error) => {
      this.msgService.add({
        severity: 'error', summary: 'Error',
        detail: objectPath.get(error, 'message', 'Error occurred during services request')
      });
    });
  }
}
