import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthAPIService {

    constructor(private http: HttpClient) {
    }

    async localSignIn(base64AuthCode: string): Promise<any> {
        const url = `${environment.apiUrl}reptime/b2baccess/userpool/login`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Basic ${base64AuthCode}`
            }),
            observe: 'response' as 'response'
        };
        return await this.http.post<any>(url, null, httpOptions).toPromise();
    }
    setCallLogs(requestPayload: any) {
        const url = `${environment.apiUrl}reptime/api/saveCallLogs`;
        return this.http.post(url, requestPayload);
    }

    saveLoginInfo(requestPayload: any) {
        const url = `${environment.apiUrl}reptime/api/company/loginTracking/save`;
        return this.http.post(url, requestPayload);
    }

    async authenticateGuestUser(token) {
        const url = `${environment.apiUrl}reptime/public/api/external/users/login`;
        const httpOptions = {
            headers: new HttpHeaders({
                'x-api-key': token
            }),
            observe: 'response' as 'response'
        };
        return await this.http.get<any>(url, httpOptions).toPromise();
    }
}
