<p-confirmDialog key="review-delete-dialog" [appendTo]="'body'" [baseZIndex]="10000" styleClass="delete-confo-dialog">
</p-confirmDialog>
<p-toast key="addItemFromDetailsPage" position="top-right" [baseZIndex]="100000"></p-toast>
<div class="individual-item-main d-flex page new-order-page reptime-theme item-shopstaticcart">
    <!-- [ngClass]="{'shopinfohun': auth.getCartStyle() === 0, 'shopstaticcart': auth.getCartStyle() === 2, 'shop-four-img': auth.getCartStyle() === 1}" -->
    <!-- <div class="b2b-sidebar individual-item-sidebar">
        <app-sidebar [navigatedURL]="navigatedURL" [whichPage]="'shop'" [pageType]="false" [pageAllType]="true"></app-sidebar>
    </div> -->
    <div class="b2b-shoppage vendor-detail-item item-details-main-page d-flex">
        <div class="individual-item-detail-block">
            <p-card class="card">
                <div [ngClass]="{'sticky-header-basket': fromPage !== 'ORDER_PAGE'}"
                    [ngStyle]="{'top': stickyTop?stickyTop.offsetHeight+'px' : headerHeight}">
                    <p-header class="card-header search-tool-box">
                        <!-- B2B-102 (NL 25-FEB-2020) add shimmer effect HTML    -->
                        <!-- shimmer effect -->
                        <div class="Wid100 d-flex align-items-center" style="display: none !important;">
                            <div class="flex-1 search-box p-col-6 p-0">
                                <div class="shimmer-effect p-20 Wid100"></div>
                            </div>
                            <div
                                class=" p-col-6 flex-1 search-actions mfg-dropdown rt-brows-center d-flex align-items-center justify-content-between p-0">
                                <div class="callout-dropdown-part p-col-4">
                                    <div class="shimmer-effect p-20 Wid100"></div>
                                </div>
                                <div class="">
                                    <span class="shimmer-effect p-15 mr-2"></span>
                                    <span class="shimmer-effect p-15"></span>
                                </div>
                            </div>
                        </div>
                        <!-- End -->
                        <div class="flex-1 search-box" [ngStyle]="!isLogin?{'display': 'block'} : {'display': 'block'}">
                            <a href="javascript:;" id="show_sidebar" (click)="show_sidebar();"><i class="fa fa-bars"
                                    aria-hidden="true"></i> Filter</a>
                            <rs-advanced-search [placeholderForSearchBar]="placeholderForSearchBar"
                                [advanceOptions]="advanceOptions" [dateRangeOptions]="dateRangeOptions"
                                [dateRangeLabel]="'Item Modified Date Range'" [isStatusActive]="false"
                                [isAvailable]="true" [isResetLoading]="isResetLoading" [typeList]="typeList"
                                [isSubmitLoading]="isSubmitLoading" [searchText]="searchText"
                                (submitEvent)="search($event)" [searchFormData]="searchFormData"
                                (resetEvent)="reset($event)" (ready)="initSearchObj($event)">
                            </rs-advanced-search>
                        </div>
                        <div class="search-actions items-details-search-option mfg-dropdown rt-brows-center" *ngIf="isLogin">

                            <!-- <label *ngIf="fromPage === 'ORDER_PAGE'" class="p-5">
                                <b>{{(selectedManufacturer.refrenceName)?selectedManufacturer.refrenceName:selectedManufacturer.name
                                    }}</b>
                            </label> -->
                            <div class="view-option d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center topbar-option mr-10">
                                    <!-- *ngIf="auth.getCartStyle() !== 2 -->
                                    <div class="cart-option-menu">
                                        <!-- <button class="icon-theme-color mr-5 grand-btn theme-grayclr" pTooltip="Promotions"
                                            (click)="promotionsClickCartEvent($event)" tooltipPosition="bottom"
                                            icon="fa fa-fw fa-commenting" pButton
                                            style="background: transparent; border: none; font-size: 20px;"
                                            *ngIf="!pageType"></button>
            
                                        <button class="icon-theme-color mr-5 grand-btn theme-grayclr" pTooltip="Minimum Met"
                                            (click)="metClickCartEvent($event)" tooltipPosition="bottom"
                                            icon="fa fa-fw fa-exclamation" pButton
                                            style="background: transparent; border: none; font-size: 20px;"
                                            *ngIf="!pageType"></button>
            
                                        <button class="icon-theme-color mr-10 grand-btn theme-grayclr" pTooltip="Checkout Now"
                                            tooltipPosition="bottom" icon="fa fa-fw fa-shopping-basket"
                                            [disabled]="!(existCartData?.totalItems && +existCartData?.totalItems > 0)"
                                            (click)="checkoutClickCartEvent($event)" pButton
                                            style="background: transparent; border: none; font-size: 20px;"
                                            *ngIf="!pageType"></button> -->
                                        <button class="btn-theme-color ui-button-info" pTooltip="Cart" label="Cart"
                                            tooltipPosition="bottom" icon="fa fa-shopping-cart"
                                            (click)="(auth.getCartStyle() === 3 || auth.getCartStyle() === 4)? router.navigate(['/cart']):openSidebarStatus = true;" pButton style="position: relative;">
                                            <span class="cart-btn-count"
                                                *ngIf="existCartData?.totalItems">{{existCartData?.totalItems}}</span>
                                        </button>
                                        <!-- <button class="ui-button ml-10 launch-ar-btn" id="launch-ar" label="Launch AR" *ngIf="!env?.production" icon="fas fa-vr-cardboard" pButton></button> -->
                                    </div>
                                </div>
                                <!-- *ngIf="auth.getCartStyle() !== 2 && !pageType" -->
                                <div class="total-sec">
                                    <h3 class="my-0 d-flex flex-column align-items-center">
                                        <span style="font-size: 19px; color:#535353; font-weight: normal;">Total
                                            {{(existCartData?.cartDiscountedTotal ? existCartData?.cartDiscountedTotal
                                            : 0) |
                                            rtCurrency }}</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </p-header>
                </div>
                <div class="individual-item-detail-infomain">
                    <div class="individual-item-detail-infomain-inner">
                        <div class="single-item-detail-block pb-30">
                            <!-- <app-item-view-model [activeViewIndex]="activeViewIndex" [activeViewItem]="activeViewItem"
                                [itemMfgIDs]="itemMfgIDs" [manufacturersArr]="" [selectedCustomer]="selectedCustomer"
                                [existCartData]="existCartData" [existItemsData]="existItemsData" [fromPage]="fromPage"
                                (addToCartEvent)="addToCartEvent($event)" >
                            </app-item-view-model> -->
                            <div class="full-page-loader" *ngIf="showLoader">
                                <div class="full-page-loadinner">
                                    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="2">
                                    </p-progressSpinner>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center" style="position: relative;">
                                <div class="single-linked-item flex-column mr-5 single-item-height d-none"
                                    *ngIf="(linkedItemsData && linkedItemsData.length) && !linkedItemsLoader">
                                    <!-- <h3 class="linked-title">Linked Item</h3> -->
                                    <div class="single-linked-item single-item-height-inner">
                                        <p-scrollPanel [style]="{width: '100%', height: 'auto'}"
                                            styleClass="sidebar-category-scroll">
                                            <div *ngFor="let linkedItems of linkedItemsData">
                                                <div class="cart-small-img mb-5"
                                                    (click)="selectlinkedItemsData(linkedItems, false)">
                                                    <div [ngClass]="{'show-loader': linkedItems.activeImglinkedLoader}"
                                                        class="image-loader">
                                                        <div class="loader-icon"></div>
                                                    </div>
                                                    <img (load)="linkedItems.activeImglinkedLoader=false;"
                                                        pTooltip="{{linkedItems.name}}" tooltipPosition="right"
                                                        tooltipEvent="hover" tooltipZIndex="10000000"
                                                        class="object-fit-cover wid-100"
                                                        style="height:53px;max-width:100%;"
                                                        src="{{linkedItems.fullPicturePath}}"
                                                        onError="this.src='assets/images/no-image-available.png'">
                                                </div>
                                            </div>
                                        </p-scrollPanel>
                                    </div>
                                </div>
                                <!-- B2B-226 (NL 03-APR-2020) add shimmer effect html -->
                                <!-- Start shimmer effect -->
                                <div class="single-item-main" *ngIf="linkedItemsLoader">
                                    <div class="single-cart-item-main">
                                        <div class="single-cart-item-left mr-30">
                                            <div class="single-cart-small-img mr-10">
                                                <div class="mr-5 mt-5">
                                                    <div class="cart-small-img shimmer-effect">
                                                    </div>
                                                </div>
                                                <div class="mr-5 mt-5">
                                                    <div class="cart-small-img shimmer-effect">
                                                    </div>
                                                </div>
                                                <div class="mr-5 mt-5">
                                                    <div class="cart-small-img shimmer-effect">
                                                    </div>
                                                </div>
                                                <div class="mr-5 mt-5">
                                                    <div class="cart-small-img shimmer-effect">
                                                    </div>
                                                </div>
                                                <div class="mr-5 mt-5">
                                                    <div class="cart-small-img shimmer-effect">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="single-cart-big-img shimmer-effect">

                                            </div>
                                        </div>
                                        <div class="single-cart-item-right">
                                            <strong class="mb-5 scart-size shimmer-effect p-7 Wid60"></strong>
                                            <div class="mb-15 shimmer-effect p-5 Wid20"></div>
                                            <div class="mb-10 scart-size sngl-item-size shimmer-effect p-15 Wid80">
                                            </div>
                                            <div class="mb-15 d-flex align-items-end sngl-price-row">
                                                <strong class="price-sngl-txt shimmer-effect p-7 Wid20"></strong>
                                                <span class="price-unit-txt shimmer-effect p-2 Wid30"></span>
                                            </div>
                                            <div class="mb-10 shimmer-effect p-5 Wid80"></div>
                                            <div class="mb-10 single-five-line">
                                                <div class="mb-3 shimmer-effect p-5 Wid100"></div>
                                                <div class="mb-3 shimmer-effect p-5 Wid100"></div>
                                                <div class="shimmer-effect p-5 Wid100"></div>
                                            </div>
                                            <div class="mb-20 shimmer-effect p-5 Wid60"></div>
                                            <div class="itemadd-action mt-10 d-flex align-items-center">
                                                <div class="single-like mr-20 shimmer-effect mr-10"
                                                    style="width: 25px; height: 25px;"> </div>
                                                <div class="cart-item-inc-block tbl_act mr-20">
                                                    <div class="cart-item-inc-inner" style="justify-content: center;">
                                                        <div class="shimmer-effect mr-7"
                                                            style="width: 25px; height: 25px;"></div>
                                                        <div class="shimmer-effect mr-7"
                                                            style="width: 50px; height: 25px;"></div>
                                                        <div class="shimmer-effect" style="width: 25px; height: 25px;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="Wid100 d-flex justify-content-between mt-20 pl-20 pr-20">
                                                <div class="shimmer-effect mr-10" style="width: 120px; height: 30px;">
                                                </div>
                                                <div class="shimmer-effect" style="width: 120px; height: 30px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Shimmer effect -->
                                <div class="single-item-main" *ngIf="!linkedItemsLoader"
                                    [class.no-linked-itemss]="!(linkedItemsData && linkedItemsData.length) && !linkedItemsLoader">
                                    <!-- <strong class="mb-10 scart-size" style="display: block;">A-Z manufacturers</strong> -->
                                    <div class="single-cart-item-main align-items-start">
                                        <div class="single-cart-item-left mr-30">
                                            <div class="single-cart-small-img mr-10 overflow-custom-scroll">
                                                <!-- <p-scrollPanel [style]="{width: '100%', height: '300px'}" styleClass="sidebar-category-scroll"> -->
                                                <div *ngFor='let j of [1]' class="mr-5 mt-5 mb-5 last-child">
                                                    <div class="cart-small-img" (click)="selectThumbImageData(false)">
                                                        <div [ngClass]="{'show-loader':configData?.activeViewItem?.modelimageLoader}"
                                                            class="image-loader">
                                                            <div class="loader-icon"></div>
                                                        </div>
                                                        <img *ngIf="configData?.activeViewItem?.modelimageError"
                                                            src="{{configData?.activeViewItem?.manufacturerPath}}"
                                                            onerror="this.src='assets/images/no-image-available.png'"
                                                            class="object-fit-cover wid-100"
                                                            style="height:53px;max-width:100%;"
                                                            alt="{{configData?.activeViewItem?.itemNumber}}">
                                                        <img *ngIf="!configData?.activeViewItem?.modelimageError"
                                                            (load)="configData?.activeViewItem.modelimageLoader=false;"
                                                            class="object-fit-cover wid-100"
                                                            style="height:53px;max-width:100%;"
                                                            (error)="configData?.activeViewItem.modelimageLoader=false;configData?.activeViewItem.fullPicturePath = null;activeViewItem.modelimageError=true"
                                                            src="{{configData?.activeViewItem?.fullPicturePath}}"
                                                            alt="{{configData?.activeViewItem?.itemNumber}}">
                                                    </div>
                                                </div>
                                                <div class="mr-5 mt-5 mb-5 last-child" *ngIf="imageAngles">
                                                    <div class="cart-small-img" (click)="view360Preview()">
                                                        <img src="assets/images/360-degrees.svg"
                                                            class="object-fit-cover wid-100"
                                                            style="height:53px;max-width:100%;">
                                                    </div>
                                                </div>
                                                <!-- [B2B-179] -->
                                                <div *ngFor="let thumbImage of thumbImageData"
                                                    class="mr-5 mb-5 mt-5 last-child">
                                                    <!-- *ngIf="thumbImage.activeImgThumbError" -->
                                                    <div class="cart-small-img"
                                                        (click)="selectThumbImageData(thumbImage)">
                                                        <div class="play-icon-div" *ngIf="isVideo(thumbImage)">
                                                            <i class="pi pi-play"></i>
                                                        </div>
                                                        <div [ngClass]="{'show-loader': thumbImage.activeImgThumbLoader}"
                                                            class="image-loader">
                                                            <div class="loader-icon"></div>
                                                        </div>
                                                        <img (load)="thumbImage.activeImgThumbLoader=false;"
                                                            class="object-fit-cover wid-100"
                                                            style="height:53px;max-width:100%;"
                                                            src="{{thumbImage.fullPicturePath}}"
                                                            onError="this.src='assets/images/no-image-available.png'">
                                                    </div>
                                                </div>
                                                <!-- [B2B-179] -->
                                                <!-- </p-scrollPanel> -->
                                            </div>
                                            <div class="single-cart-big-img">
                                                <div [ngClass]="{'show-loader':configData?.activeViewItem?.modelimageLoader}"
                                                    class="image-loader">
                                                    <div class="loader-icon"></div>
                                                </div>
                                                <img *ngIf="configData?.activeViewItem?.modelimageError && !isVideo(selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath)"
                                                    src="{{configData?.activeViewItem?.manufacturerPath}}"
                                                    class="object-fit-cover wid-100"
                                                    style="max-width:100%; background: #fff; z-index: 1;"
                                                    alt="{{configData?.activeViewItem?.itemNumber}}"
                                                    onerror="this.src='assets/images/no-image-available.png'">
                                                <ngx-img-zoom #imgElmRefZoom
                                                    *ngIf="!isVideo(selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath)"
                                                    (load)="configData?.activeViewItem.modelimageLoader=false;"
                                                    (error)="configData?.activeViewItem.modelimageLoader=false;configData?.activeViewItem.fullPicturePath = null;activeViewItem.modelimageError=true"
                                                    [zoomImageSrc]="selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath"
                                                    [previewImageSrc]="selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath"
                                                    [imgStyle]="'width:100%;'"
                                                    [resultStyle]="'width:500px; height:450px; background-repeat: no-repeat; z-index: 2; position:absolute;
                                                                -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0), 0 1px 2px rgba(0,0,0,0);
                                                                    box-shadow: 0 1px 3px rgba(0,0,0,0), 0 1px 2px rgba(0,0,0,0); top: 0;left: 100%;'"
                                                    [lensStyle]="'width:175px; height:175px;'"
                                                    [enableZoom]="enableZoom"></ngx-img-zoom>
                                                <video style="width: 100%; height: 100%;" controls
                                                    controlsList="nodownload" autoplay [muted]="'muted'" loop="true"
                                                    [src]="selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath"
                                                    *ngIf="isVideo(selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath)">
                                                </video>
                                                <!-- <img src="{{configData?.activeViewItem?.fullPicturePath}}" alt="Cart Image" /> -->
                                                <!-- <div class="itemadd-action mt-10" >
                                                        <button pButton type="button" icon="fa fa-plus" label="Add Item" class="info ui-button-info mr-10"></button>
                                                        <a href="javascritp:;" class="single-like"><i class="far fa-heart item-heart"></i></a>
                                                    </div> -->
                                            </div>
                                            <!-- below div is for mobile zoom image section ****START**** -->
                                            <div class="single-cart-big-img mobile_show">
                                                <a href="{{selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath}}"
                                                    target="_blank">
                                                    <img *ngIf="configData?.activeViewItem?.modelimageError && !isVideo(selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath)"
                                                        src="{{configData?.activeViewItem?.manufacturerPath}}"
                                                        onerror="this.src='assets/images/no-image-available.png'"
                                                        class="object-fit-cover wid-100" style="max-width:100%;"
                                                        alt="{{configData?.activeViewItem?.itemNumber}}">
                                                    <img *ngIf="!configData?.activeViewItem?.modelimageError && !isVideo(selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath)"
                                                        (load)="configData?.activeViewItem.modelimageLoader=false;"
                                                        class="object-fit-cover wid-100" style="max-width:100%;"
                                                        (error)="configData?.activeViewItem.modelimageLoader=false;configData?.activeViewItem.fullPicturePath = null;activeViewItem.modelimageError=true"
                                                        src="{{selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath}}"
                                                        alt="{{configData?.activeViewItem?.itemNumber}}">
                                                    <video style="width: 100%; height: 100%;" controls
                                                        controlsList="nodownload" autoplay [muted]="'muted'" loop="true"
                                                        [src]="selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath"
                                                        *ngIf="isVideo(selectedThumbImageData && selectedThumbImageData.fullPicturePath ? selectedThumbImageData.fullPicturePath : configData?.activeViewItem?.fullPicturePath)">
                                                    </video>
                                                </a>
                                            </div>
                                            <!-- below div is for mobile zoom image section ****END**** -->
                                        </div>
                                        <!-- // [B2B-232] (MG-08-APR-2020) modified to do ui hotfix -->
                                        <div class="single-cart-item-right">
                                            <!-- <strong class="mb-5 scart-size">A-Z manufacturers</strong> -->
                                            <!-- <strong class="mb-5 scart-size" style="display: block;text-transform: uppercase;font-size: 16px;">{{configData?.activeViewItem?.repGroupManufacturerName</strong> -->
                                            <div class="brand-info">
                                                <div class="brand-text">
                                                    <strong class="mb-5 scart-size brand-name"
                                                        style="display: block;text-transform: uppercase;font-size: 16px;">
                                                        <!-- {{configData?.activeViewItem?.repGroupManufacturerName ?
                                                        configData?.activeViewItem?.repGroupManufacturerName
                                                        :configData?.activeViewItem?.manufacturerName}} -->
                                                        {{rtSharedService.getSeletedLanguage(configData.activeViewItem.manufacturerName, 
                                                        configData.activeViewItem.repGroupManufacturerName,
                                                        configData.activeViewItem.repGroupManufacturerName_json )}}
                                                    </strong>
                                                    <div class="item-number"
                                                        *ngIf="configData?.activeViewItem?.itemNumber">
                                                        #{{configData?.activeViewItem?.itemNumber}}</div>
                                                    <div class="d-flex align-items-center mt-5"
                                                        *ngIf="configData?.activeViewItem?.defaultShipDate">
                                                        <span class="price-unit-txt">Ship Date :</span>
                                                        <strong
                                                            style="font-size: 16px;margin-left: 5px;">{{configData?.activeViewItem?.defaultShipDate
                                                            | rtDate : 'UTC'}}</strong>
                                                    </div>
                                                </div>
                                                <img src="{{configData?.activeViewItem?.manufacturerPath}}"
                                                    class="brand-image"
                                                    onError="this.src='assets/images/no-image-100x50.jpg';">
                                            </div>
                                            <div class="ratting-sec" *ngIf="isLogin">
                                                <div class="ratting active-ratting"
                                                    (click)="ratting_content.toggle($event)">
                                                    <span
                                                        [ngClass]="(avgItemsRating > 4 && avgItemsRating < 5)? 'ratting-star ratting-star-half' : (avgItemsRating >= 5)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                                                    <span
                                                        [ngClass]="(avgItemsRating > 3 && avgItemsRating < 4)? 'ratting-star ratting-star-half' : (avgItemsRating >= 4)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                                                    <span
                                                        [ngClass]="(avgItemsRating > 2 && avgItemsRating < 3)? 'ratting-star ratting-star-half' : (avgItemsRating >= 3)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                                                    <span
                                                        [ngClass]="(avgItemsRating > 1 && avgItemsRating < 2)? 'ratting-star ratting-star-half' : (avgItemsRating >= 2)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                                                    <span
                                                        [ngClass]="(avgItemsRating > 0 && avgItemsRating < 1)? 'ratting-star ratting-star-half' : (avgItemsRating >= 1)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                                                </div>
                                                <a href="javascript:;" class="comment-icon" (click)="commetsDialog()"
                                                    pTooltip="Buyer Reviews"><i class="fas fa-comments"></i></a>
                                                <p class="global-reviews" *ngIf="totalReview > 0">Reviews<span
                                                        class="global-reviews-value"> ({{commafy(totalReview)}})</span>
                                                </p>
                                            </div>
                                            <div class="mb-10 scart-size sngl-item-size">
                                                {{configData?.activeViewItem?.name}}</div>
                                            <div class="d-flex justify-content-between">
                                                <ng-container
                                                    *ngIf="!(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true) && (isLogin || isIframeWindow) && !b2bItemPricingPolicy">
                                                    <div class="d-flex align-items-center sngl-price-row flex-wrap">
                                                        <div class="d-flex align-items-center"
                                                            *ngIf="configData?.activeViewItem?.unitPrice">
                                                            <strong
                                                                class="mb-15 price-sngl-txt">{{configData?.activeViewItem?.unitPrice
                                                                | rtCurrency}}</strong>
                                                            <span class="price-unit-txt mr-15 mb-15">
                                                                <ng-container
                                                                    *ngIf="configData?.activeViewItem?.b2bItemPricingPolicy === 'ShowRetailPrice'">
                                                                    RETAIL</ng-container>
                                                                <ng-container
                                                                    *ngIf="configData?.activeViewItem?.b2bItemPricingPolicy === 'ShowWholeSalePrice'">
                                                                    WHOLESALE</ng-container> PRICE PER UNIT
                                                            </span>
                                                        </div>
                                                        <div class="d-flex discount-block mb-15"
                                                            *ngIf="configData?.activeViewItem?.discountPercent">
                                                            <span
                                                                class="off-item-per">{{configData?.activeViewItem?.discountPercent
                                                                / 100 | percent:'0.0-2'}} off</span>
                                                            <span class="discount-date"
                                                                *ngIf="configData?.activeViewItem?.discountStartDateString || configData?.activeViewItem?.discountEndDateString">
                                                                <ng-container
                                                                    *ngIf="configData?.activeViewItem?.discountStartDateString && !configData?.activeViewItem?.discountEndDateString">
                                                                    From
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="configData?.activeViewItem?.discountStartDateString">
                                                                    {{configData?.activeViewItem?.discountStartDateString
                                                                    | rtDate}}
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="configData?.activeViewItem?.discountStartDateString && configData?.activeViewItem?.discountEndDateString">
                                                                    to
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!configData?.activeViewItem?.discountStartDateString && configData?.activeViewItem?.discountEndDateString">
                                                                    Up to
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="configData?.activeViewItem?.discountEndDateString">
                                                                    {{configData?.activeViewItem?.discountEndDateString
                                                                    | rtDate}}
                                                                </ng-container>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                            </div>

                                            <div class="mb-10" style="font-size: 15px;">Minimum
                                                {{configData?.activeViewItem?.minimumQuantity}}
                                                per item | Order in multiples of
                                                {{configData?.activeViewItem?.quantityIncrement}}</div>

                                            <div class="d-flex align-items-center mb-10 flex-wrap"
                                                *ngIf="configData?.activeViewItem?.qtyAvailable || configData?.activeViewItem?.nextAvailableDate">
                                                <div class="d-flex align-items-center mr-15"
                                                    *ngIf="configData?.activeViewItem?.qtyAvailable">
                                                    <span class="price-unit-txt">Available QTY :</span>
                                                    <strong
                                                        style="font-size: 16px;margin-left: 5px;">{{configData?.activeViewItem?.qtyAvailable}}</strong>
                                                </div>
                                                <div class="d-flex align-items-center"
                                                    *ngIf="configData?.activeViewItem?.nextAvailableDate">
                                                    <span class="price-unit-txt">Next Available Date :</span>
                                                    <strong style="font-size: 16px;margin-left: 5px;">{{
                                                        configData?.activeViewItem?.nextAvailableDate | rtDate: 'UTC'}}</strong>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-center mb-10 qty-sec"
                                                *ngIf="isLogin">
                                                <div class="item-volume-price d-flex mr-10"
                                                    *ngIf="configData?.activeViewItem?.itemVolumePricings && configData?.activeViewItem?.itemVolumePricings.length">
                                                    <div class="item-volume-price-block d-flex flex-column"
                                                        *ngFor="let item of configData?.activeViewItem?.itemVolumePricings">
                                                        <span class="volume-price-label">{{item?.volumeQuantity}}
                                                            qty</span>
                                                        <span class="volume-price-value">{{(item?.unitPrice ?
                                                            item?.unitPrice : 0) | rtCurrency }}</span>
                                                    </div>
                                                </div>
                                                <div class="itemadd-action mt-0 d-flex flex-column align-items-start"
                                                    *ngIf="isLogin">
                                                    <strong class="on-order-text">On Order</strong>
                                                    <div class="d-flex">
                                                        <a href="javascript:;"
                                                            (click)='heartClickEvent(configData?.activeViewItem?.isWishedItem)'
                                                            class="single-like mr-15">
                                                            <i *ngIf="configData?.activeViewItem?.isWishedItem"
                                                                class="fa-heart item-heart fa item-red"></i>
                                                            <i *ngIf="!configData?.activeViewItem?.isWishedItem"
                                                                class="fa-heart item-heart far"></i>
                                                        </a>
                                                        <!-- <a href="javascritp:;" class="single-like mr-20"><i class="fa fa-heart item-heart" style="color: red;"></i></a> -->
                                                        <div class="cart-item-inc-block tbl_act">
                                                            <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
                                                            <div *ngIf="configData?.activeViewItem?.discontinued"
                                                                pTooltip="Discounted item are restricted for order."
                                                                tooltipZIndex="1100000">
                                                                <button pButton type="button" [disabled]="true"
                                                                    class="ui-button-secondary ui-button"
                                                                    label="Discontinued"></button>
                                                            </div>
                                                            <ng-container
                                                                *ngIf="!configData?.activeViewItem?.discontinued && !(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true) && !b2bItemPricingPolicy">
                                                                <div class="cart-item-inc-inner"
                                                                    style="justify-content: center;">
                                                                    <button
                                                                        (mousedown)="onclickSaveOldQty(configData?.activeViewItem?.quantity)"
                                                                        (click)="decrQtyEvent(configData?.activeViewItem?.quantity)"
                                                                        pButton type="button" icon="fas fa-minus"
                                                                        class="cart-minus cart-btn mr-5 add-cart-btn"
                                                                        [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"></button>
                                                                    <div class="cart-item-inc-input">
                                                                        <input type="number" pInputText
                                                                            pKeyFilter="pint" style="width: 50px;"
                                                                            [ngModel]="configData?.activeViewItem?.quantity"
                                                                            (ngModelChange)="configData?.activeViewItem.quantity=$event"
                                                                            step="configData?.activeViewItem?.quantityIncrement"
                                                                            (mousedown)="onclickSaveOldQty(configData?.activeViewItem?.quantity)"
                                                                            (blur)="updateItemQty(configData?.activeViewItem?.quantity, 'self')"
                                                                            min="0" />
                                                                    </div>
                                                                    <button
                                                                        (mousedown)="onclickSaveOldQty(configData?.activeViewItem?.quantity)"
                                                                        (click)="incrQtyEvent(configData?.activeViewItem?.quantity)"
                                                                        pButton type="button" icon="fas fa-plus"
                                                                        class="cart-add  cart-btn ml-5 add-cart-btn"
                                                                        [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"></button>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mb-10 single-five-line overflow-custom-scroll"
                                                *ngIf="configData?.activeViewItem?.description">
                                                {{configData?.activeViewItem?.description}}
                                            </div>
                                            <!-- <div class="mb-10 single-five-line overflow-custom-scroll"
                                                *ngIf="configData?.activeViewItem?.detailedDescription">
                                                <strong class="mr-5" style="color: #777;">Additional Details
                                                    :</strong>{{configData?.activeViewItem?.detailedDescription}}
                                            </div> -->
                                            <div class="mb-20" style="font-size: 14px;"
                                                *ngIf="configData?.activeViewItem?.UPCObjString && (isLogin || isIframeWindow)">
                                                <strong>UPC </strong>#{{configData?.activeViewItem?.UPCObjString}}
                                            </div>
                                            <!-- <div class="mb-10"><strong>Min </strong>6 for $10.50</div> -->
                                            <!-- <div class="mb-10"><strong>Buy </strong>24 for $8.10</div> -->
                                            <!-- <div class="mb-10"><strong>Buy </strong>48 for $6.00</div> -->
                                            <div class="itemadd-action mt-10 d-flex align-items-center" *ngIf="isLogin"
                                                style="display: none !important;">
                                                <a href="javascript:;"
                                                    (click)='heartClickEvent(configData?.activeViewItem?.isWishedItem)'
                                                    class="single-like mr-20">
                                                    <i *ngIf="configData?.activeViewItem?.isWishedItem"
                                                        class="fa-heart item-heart fa item-red"></i>
                                                    <i *ngIf="!configData?.activeViewItem?.isWishedItem"
                                                        class="fa-heart item-heart far"></i>
                                                </a>
                                                <!-- <a href="javascritp:;" class="single-like mr-20"><i class="fa fa-heart item-heart" style="color: red;"></i></a> -->
                                                <div class="cart-item-inc-block tbl_act mr-20">
                                                    <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
                                                    <div *ngIf="configData?.activeViewItem?.discontinued"
                                                        pTooltip="Discounted item are restricted for order."
                                                        tooltipZIndex="1100000">
                                                        <button pButton type="button" [disabled]="true"
                                                            class="ui-button-secondary ui-button"
                                                            label="Discontinued"></button>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="!configData?.activeViewItem?.discontinued && !(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true) && !b2bItemPricingPolicy">
                                                        <div class="cart-item-inc-inner"
                                                            style="justify-content: center;">
                                                            <button
                                                                (click)="decrQtyEvent(configData?.activeViewItem?.quantity)"
                                                                pButton type="button" icon="fas fa-minus"
                                                                class="cart-minus cart-btn mr-5 add-cart-btn"
                                                                [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"></button>
                                                            <div class="cart-item-inc-input">
                                                                <input type="number" pInputText pKeyFilter="pint"
                                                                    style="width: 50px;"
                                                                    [ngModel]="configData?.activeViewItem?.quantity"
                                                                    (ngModelChange)="configData?.activeViewItem.quantity=$event"
                                                                    step="configData?.activeViewItem?.quantityIncrement"
                                                                    (mousedown)="onclickSaveOldQty(configData?.activeViewItem?.quantity)"
                                                                    (blur)="updateItemQty(configData?.activeViewItem?.quantity, 'self')"
                                                                    min="0" />
                                                            </div>
                                                            <button
                                                                (click)="incrQtyEvent(configData?.activeViewItem?.quantity)"
                                                                pButton type="button" icon="fas fa-plus"
                                                                class="cart-add  cart-btn ml-5 add-cart-btn"
                                                                [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"></button>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <div *ngIf="isLogin && checkAvailability">
                                                <div class="my-ratting-sec">
                                                    <div class="ratting active-ratting">
                                                        <span
                                                            [ngClass]="(myItemsRating >= 5)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                                            (click)="saveItemsRating('5')"></span>
                                                        <span
                                                            [ngClass]="(myItemsRating >= 4)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                                            (click)="saveItemsRating('4')"></span>
                                                        <span
                                                            [ngClass]="(myItemsRating >= 3)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                                            (click)="saveItemsRating('3')"></span>
                                                        <span
                                                            [ngClass]="(myItemsRating >= 2)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                                            (click)="saveItemsRating('2')"></span>
                                                        <span
                                                            [ngClass]="(myItemsRating >= 1)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"
                                                            (click)="saveItemsRating('1')"></span>
                                                    </div>
                                                    <span class="ratting-loader" *ngIf="ratingLoader">
                                                        <i class="fa fa-spinner fa-spin" style="float: right;"></i>
                                                    </span>
                                                </div>
                                                <div class="my-comment-sec">
                                                    <p class="my-comment">{{itemGetReview}}</p>
                                                    <div class="comment-btn-sec">
                                                        <a href="javascript:;" class="edit-comment-btn"
                                                            [pTooltip]="(reviewRecordID) ? 'Edit' : 'Write a Review'"
                                                            (click)="editCommetsDialog()">
                                                            <span *ngIf="!reviewRecordID">Write a Review</span>
                                                            <span *ngIf="reviewRecordID">Edit</span>
                                                        </a>
                                                        <a href="javascript:;" class="comments-sec-btn delete-comment"
                                                            pTooltip="Delete" (click)="removeReview()"
                                                            *ngIf="reviewRecordID">
                                                            Delete
                                                        </a>
                                                        <span class="ratting-loader" *ngIf="reviewDeleteLoader">
                                                            <i class="fa fa-spinner fa-spin" style="float: right;"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- // [B2B-232] (MG-08-APR-2020) modified to do ui hotfix -->
                                            <div class="itemadd-action mt-10 d-flex align-items-center"
                                                *ngIf="(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true)">
                                                <br><br><br>
                                                <p style="color: #999; font-weight: bold;">Select image to view.</p>
                                            </div>
                                            <div style="text-align: right;" class="pt-15" *ngIf="!linkedItemsLoader">
                                                <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
                                                <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                    <button pButton type="button" label="Go back"
                                                        icon="fas fa-arrow-left"
                                                        [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"
                                                        (click)="isOpenChange.emit(false);backToShop($event);"
                                                        class="theme-maroon-btn mt-5"></button>
                                                    <div class="d-flex align-items-center mt-5">
                                                        <ng-container
                                                            *ngIf="!(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true) && !b2bItemPricingPolicy">
                                                            <p-button (onClick)="addOrder(configData?.activeViewItem)"
                                                                type="button" *ngIf="isLogin"
                                                                [styleClass]="'btn-theme-color mr-5'"
                                                                label="Add to order"
                                                                [disabled]="configData?.activeViewItem?.discontinued ||  (disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID] || isAddButtonDisable"
                                                                [icon]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID] ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-plus'">
                                                            </p-button>
                                                            <!--  |Add to order| button now remove from disabled for isAddButtonDisable - [B2B-364]  -->
                                                        </ng-container>
                                                        <ng-container *ngIf="isLogin && b2bItemPricingPolicy">
                                                            <button pButton type="button"
                                                                [ngClass]="configData?.activeViewItem?.retailerRequestStatus === 0 ? 'sign-login-disable' : 'btn-theme-color'"
                                                                label="Send Request for Approval"
                                                                [disabled]="requestPending"
                                                                [icon]="requestPending ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-paper-plane'"
                                                                (click)="requestPending = false;sendRequest()"></button>
                                                        </ng-container>
                                                        <!-- [RC-1965] login/signup button hide/show start -->
                                                        <a pButton type="button" label="Sign Up or Login for Pricing"
                                                            icon="fa fa-user-plus" class="btn-theme-color"
                                                            *ngIf="!isLogin && !isIframeWindow && auth.getdefaultSignupButtonFlag()" target="_blank"
                                                            [routerLink]="['/signup']"></a>
                                                        <a pButton type="button" label="Sign Up or Login for Pricing"
                                                            icon="fa fa-user-plus" class="btn-theme-color"
                                                            *ngIf="!isLogin && !isIframeWindow && !auth.getdefaultSignupButtonFlag() && auth.getCustomSignupButtonData().url" target="_blank"
                                                            href="{{auth.getCustomSignupButtonData().url}}"></a>
                                                        <!-- [RC-1965] login/signup button hide/show end -->
                                                        <a pButton type="button" label="Sign Up or Login for Pricing"
                                                            icon="fa fa-user-plus"
                                                            class="btn-theme-color disabled-action-control"
                                                            *ngIf="!isLogin && isIframeWindow" target="_blank"></a>
                                                    </div>
                                                </div>

                                                <!-- <button pButton type="button" label="Close" icon="fa fa-close"
                                                    [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]"
                                                    (click)="isOpenChange.emit(false);" class="theme-maroon-btn"></button> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-size-table pt-20 pl-30 pr-30 pb-0"
                                        *ngIf="configData?.activeViewItem && (configData?.activeViewItem?.dateIntroduced || configData?.activeViewItem?.defaultShipDate || configData?.activeViewItem?.discountStartDateString || configData?.activeViewItem?.discountEndDateString || configData?.activeViewItem?.discountPercent || configData?.activeViewItem?.detailedDescription)">
                                        <div class="item-size-table-inner reptime-theme">
                                            <p-card class="card">
                                                <p-header class="card-header">
                                                    <h2 class="m-0" style="font-weight: bold;">Additional Details</h2>
                                                </p-header>
                                                <p-table [columns]="additionalDetailCols"
                                                    [value]="[configData?.activeViewItem]" [autoLayout]="true">

                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr>
                                                            <th *ngFor="let col of columns"
                                                                [pSortableColumn]="col.sortField"
                                                                style="text-align: center;" [style.width]="col.width">
                                                                {{col.header}}
                                                                <p-sortIcon *ngIf="col.spinner" [field]="col.field">
                                                                </p-sortIcon>
                                                            </th>
                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="body" let-i="rowIndex" let-rowData
                                                        let-columns="columns">
                                                        <tr [pSelectableRow]="rowData">
                                                            <td *ngFor="let col of columns; let j = index;"
                                                                style="text-align: center;">
                                                                <div [ngSwitch]="col.field">
                                                                    <div *ngSwitchCase="'dateIntroduced'">
                                                                        {{rowData[col.field] ? (rowData[col.field] |
                                                                        rtDate : 'UTC') : '-'}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'defaultShipDate'">
                                                                        {{rowData[col.field] ? (rowData[col.field] |
                                                                        rtDate : 'UTC') : '-'}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'discountStartDateString'">
                                                                        {{rowData[col.field] ? (rowData[col.field] |
                                                                        rtDate) : '-'}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'discountEndDateString'">
                                                                        {{rowData[col.field] ? (rowData[col.field] |
                                                                        rtDate) : '-'}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'discountPercent'">
                                                                        {{rowData[col.field] ? (rowData[col.field] / 100
                                                                        | percent:'0.0-2') : '-'}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'detailedDescription'"
                                                                        style="color:#333;font-size: 15px;"
                                                                        class="additional-desc"
                                                                        pTooltip="{{rowData[col.field]}}"
                                                                        tooltipPosition="top">
                                                                        {{(rowData[col.field] ? rowData[col.field] :
                                                                        '-')}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>

                                                </p-table>
                                            </p-card>
                                        </div>
                                    </div>
                                    <div class="item-size-table pt-20 pl-30 pr-30 pb-0"
                                        *ngIf="(activeViewItem.height || activeViewItem.width || activeViewItem.length || activeViewItem.weight) && auth.getItemDetailPage()">
                                        <div class="reptime-theme">
                                            <p-card class="card">
                                                <p-header class="card-header">
                                                    <h2 class="m-0" style="font-weight: bold;">Specifications</h2>
                                                </p-header>
                                                <div class="specification-container">
                                                    <div class="spec-header">
                                                        <div>Height <span
                                                                *ngIf="activeViewItem.sizeUnitOfMeasure">({{activeViewItem.sizeUnitOfMeasure}})</span>
                                                        </div>
                                                        <div>Width <span
                                                                *ngIf="activeViewItem.sizeUnitOfMeasure">({{activeViewItem.sizeUnitOfMeasure}})</span>
                                                        </div>
                                                        <div>Length <span
                                                                *ngIf="activeViewItem.sizeUnitOfMeasure">({{activeViewItem.sizeUnitOfMeasure}})</span>
                                                        </div>
                                                        <div>Weight <span
                                                                *ngIf="activeViewItem.weightUnitOfMeasure">({{activeViewItem.weightUnitOfMeasure}})</span>
                                                        </div>
                                                    </div>
                                                    <div class="spec-data">
                                                        <div>{{activeViewItem.height ? activeViewItem.height : '-'}}
                                                        </div>
                                                        <div>{{activeViewItem.width ? activeViewItem.width : '-'}}</div>
                                                        <div>{{activeViewItem.length ? activeViewItem.length : '-'}}
                                                        </div>
                                                        <div>{{activeViewItem.weight ? activeViewItem.weight : '-'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </p-card>
                                        </div>
                                    </div>
                                    <div class="item-size-table pt-20 pl-30 pr-30 pb-0" *ngIf="isLogin">
                                        <div class="item-size-table-inner reptime-theme"
                                            *ngIf="configData?.activeViewItem?.isc && configData?.activeViewItem?.isc.length"
                                            [class.d-variation-btm]="(linkedItemsData && linkedItemsData.length) && !linkedItemsLoader">
                                            <p-card class="card">
                                                <p-header class="card-header">
                                                    <h2 class="m-0" style="font-weight: bold;">Different Variations</h2>
                                                </p-header>
                                                <p-table [columns]="singleItemCols"
                                                    [value]="configData?.activeViewItem?.isc" [autoLayout]="true"
                                                    [responsive]="true">

                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr>
                                                            <th *ngFor="let col of columns"
                                                                [pSortableColumn]="col.sortField"
                                                                style="text-align: center;">
                                                                {{col.header}}
                                                                <p-sortIcon *ngIf="col.spinner" [field]="col.field">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th style="width: 200px;text-align: center;">Quantity</th>
                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="body" let-i="rowIndex" let-rowData
                                                        let-columns="columns">
                                                        <tr [pSelectableRow]="rowData">
                                                            <td *ngFor="let col of columns; let j = index;"
                                                                style="text-align: center;">
                                                                <div [ngSwitch]="col.field">
                                                                    <div *ngSwitchCase="'fullPicturePath'"
                                                                        class="item-tbl-thumb">
                                                                        <div [ngClass]="{'show-loader':configData?.activeViewItem?.modelimageLoader}"
                                                                            class="image-loader">
                                                                            <div class="loader-icon"></div>
                                                                        </div>
                                                                        <img *ngIf="configData?.activeViewItem?.modelimageError"
                                                                            src="{{configData?.activeViewItem?.manufacturerPath}}"
                                                                            class="object-fit-cover wid-100"
                                                                            style="height:50px;max-width:100%;"
                                                                            alt="{{configData?.activeViewItem?.itemNumber}}"
                                                                            onerror="this.src='assets/images/no-image-available.png'">
                                                                        <img *ngIf="!configData?.activeViewItem?.modelimageError"
                                                                            class="object-fit-cover wid-100"
                                                                            style="height:50px;max-width:100%;"
                                                                            (error)="configData?.activeViewItem.modelimageLoader=false;configData?.activeViewItem.fullPicturePath = null;activeViewItem.modelimageError=true"
                                                                            src="{{configData?.activeViewItem?.fullPicturePath}}"
                                                                            alt="{{configData?.activeViewItem?.itemNumber}}">
                                                                    </div>
                                                                    <div *ngSwitchCase="'size'"
                                                                        style="color:#333;font-size: 15px;">
                                                                        {{(rowData[col.field] ? rowData[col.field] :
                                                                        null)}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'color'"
                                                                        style="color:#333;font-size: 15px;">
                                                                        {{(rowData[col.field] ? rowData[col.field] :
                                                                        null)}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'style'"
                                                                        style="color:#333;font-size: 15px;">
                                                                        {{(rowData[col.field] ? rowData[col.field] :
                                                                        null)}}
                                                                    </div>
                                                                </div>
                                                                <!-- <div *ngSwitchDefault>{{rowData[col.field]}}</div> -->
                                                            </td>
                                                            <td>
                                                                <div class="cart-item-inc-block tbl_act">
                                                                    <div *ngIf="configData?.activeViewItem?.discontinued"
                                                                        pTooltip="Discounted item are restricted for order."
                                                                        tooltipZIndex="1100000">
                                                                        <button pButton type="button" [disabled]="true"
                                                                            class="ui-button-secondary ui-button"
                                                                            label="Discontinued"></button>
                                                                    </div>
                                                                    <div class="cart-item-inc-inner"
                                                                        style="justify-content: center;"
                                                                        *ngIf="!configData?.activeViewItem?.discontinued">
                                                                        <button (click)="decrSCSEvent(rowData, i)"
                                                                            [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[rowData.recordID +''+ rowData.itemID]"
                                                                            pButton type="button" icon="fas fa-minus"
                                                                            class="cart-minus cart-btn mr-5 add-cart-btn"></button>
                                                                        <!-- <div class="cart-item-inc-input">
                                                                            <input type="number" onClick="this.select();" pInputText pKeyFilter="pint" [(ngModel)]="rowData.quantity" [tabindex]="i + 1"
                                                                            (blur)="saveItemVariantToBasket(rowData, i, 'self')" style="width: 50px;" step="configData?.activeViewItem?.quantityIncrement" min="0"/>
                                                                        </div> -->
                                                                        <div class="cart-item-inc-input">
                                                                            <input (blur)="updateSCSEvent(rowData, i)"
                                                                                type="number" onClick="this.select();"
                                                                                pInputText pKeyFilter="pint"
                                                                                [(ngModel)]="rowData.quantity"
                                                                                [tabindex]="i + 1" style="width: 50px;"
                                                                                step="configData?.activeViewItem?.quantityIncrement"
                                                                                min="0" />
                                                                        </div>
                                                                        <button (click)="incrSCSEvent(rowData, i)"
                                                                            [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[rowData.recordID +''+ rowData.itemID]"
                                                                            pButton type="button" icon="fas fa-plus"
                                                                            class="cart-add  cart-btn ml-5 add-cart-btn"></button>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>

                                                </p-table>
                                            </p-card>
                                        </div>
                                        <!-- B2B-327 (NL 05-OCT-2020) add similar item table -->
                                        <div class="item-size-table-inner reptime-theme"
                                            *ngIf="(linkedItemsData && linkedItemsData.length) && !linkedItemsLoader">
                                            <p-card class="card">
                                                <p-header class="card-header">
                                                    <h2 class="m-0" style="font-weight: bold;">Similar Items</h2>
                                                </p-header>
                                                <p-table [columns]="linkItemCols" [value]="linkedItemsData"
                                                    [autoLayout]="true" [responsive]="true">

                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr>
                                                            <th *ngFor="let col of columns"
                                                                [pSortableColumn]="col.sortField"
                                                                style="text-align: center;" [style.width]="col.width">
                                                                {{col.header}}
                                                                <p-sortIcon *ngIf="col.spinner" [field]="col.field">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th style="width: 200px;text-align: center;">Quantity</th>
                                                            <th style="width: 120px;text-align: center;">Action</th>
                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="body" let-i="rowIndex" let-rowData
                                                        let-columns="columns">
                                                        <tr [pSelectableRow]="rowData">
                                                            <td *ngFor="let col of columns; let j = index;"
                                                                style="text-align: center;">
                                                                <div [ngSwitch]="col.field">
                                                                    <div *ngSwitchCase="'fullPicturePath'"
                                                                        class="item-tbl-thumb">
                                                                        <div [ngClass]="{'show-loader':configData?.activeViewItem?.modelimageLoader}"
                                                                            class="image-loader">
                                                                            <div class="loader-icon"></div>
                                                                        </div>
                                                                        <!-- <img *ngIf="configData?.activeViewItem?.modelimageError" src="assets/images/no-image-available.png"
                                                                            class="object-fit-cover wid-100" style="height:50px;max-width:100%;"
                                                                            alt="{{configData?.activeViewItem?.itemNumber}}"> -->
                                                                        <img *ngIf="rowData.activeImglinkedError"
                                                                            src="{{configData?.activeViewItem?.manufacturerPath}}"
                                                                            class="object-fit-cover wid-100"
                                                                            style="height:50px;max-width:100%;"
                                                                            onerror="this.src='assets/images/no-image-available.png'">
                                                                        <img class="object-fit-cover wid-100"
                                                                            *ngIf="!rowData.activeImglinkedError"
                                                                            style="height:50px;max-width:100%;"
                                                                            src="{{rowData[col.field]}}"
                                                                            (error)="rowData[col.field] = null;rowData.activeImglinkedError=true">
                                                                    </div>
                                                                    <div *ngSwitchCase="'name'"
                                                                        style="color:#333;font-size: 15px;"
                                                                        class="linkitemname" [style.width]="col.width"
                                                                        pTooltip="{{(rowData[col.field] ? rowData[col.field] : null)}}"
                                                                        tooltipPosition="top" tooltipZIndex="1000000">
                                                                        {{(rowData[col.field] ? rowData[col.field] :
                                                                        null)}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'description'"
                                                                        style="color:#333;font-size: 15px;"
                                                                        class="linkitemdesc" [style.width]="col.width"
                                                                        pTooltip="{{rowData[col.field]}}"
                                                                        tooltipPosition="top" tooltipZIndex="1000000">
                                                                        {{(rowData[col.field] ? rowData[col.field] :
                                                                        null)}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'minimumQuantity'"
                                                                        style="color:#333;font-size: 15px;">
                                                                        {{(rowData[col.field] ? rowData[col.field] :
                                                                        null)}}
                                                                    </div>
                                                                    <div *ngSwitchCase="'unitPrice'"
                                                                        style="color:#333;font-size: 15px;">
                                                                        <ng-container
                                                                            *ngIf="!rowData?.b2bItemPricingPolicyFlag">
                                                                            {{(rowData[col.field] ? rowData[col.field] :
                                                                            null)}}
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <!-- <div *ngSwitchDefault>{{rowData[col.field]}}</div> -->
                                                            </td>
                                                            <td>
                                                                <div class="cart-item-inc-block tbl_act"
                                                                    *ngIf="!rowData?.isc.length">
                                                                    <ng-container
                                                                        *ngIf="!(auth.getParentItemShoppingFlag() === false && rowData?.isParentItem === true)">
                                                                        <div *ngIf="rowData?.discontinued"
                                                                            pTooltip="Discounted item are restricted for order."
                                                                            tooltipZIndex="1100000">
                                                                            <button pButton type="button"
                                                                                [disabled]="true"
                                                                                class="ui-button-secondary ui-button"
                                                                                label="Discontinued"></button>
                                                                        </div>
                                                                        <div class="cart-item-inc-inner"
                                                                            style="justify-content: center;"
                                                                            *ngIf="!rowData?.discontinued && !rowData?.b2bItemPricingPolicyFlag">
                                                                            <button
                                                                                (mousedown)="onclickSaveOldQtysimillar(rowData.quantity)"
                                                                                (click)="decrLinkedEvent(rowData, i)"
                                                                                [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[rowData.recordID +''+ rowData.itemID]"
                                                                                pButton type="button"
                                                                                icon="fas fa-minus"
                                                                                class="cart-minus cart-btn mr-5 add-cart-btn"></button>
                                                                            <!-- <div class="cart-item-inc-input">
                                                                                <input type="number" onClick="this.select();" pInputText pKeyFilter="pint" [(ngModel)]="rowData.quantity" [tabindex]="i + 1"
                                                                                (blur)="saveItemVariantToBasket(rowData, i, 'self')" style="width: 50px;" step="configData?.activeViewItem?.quantityIncrement" min="0"/>
                                                                            </div> -->
                                                                            <div class="cart-item-inc-input">
                                                                                <input
                                                                                    (mousedown)="onclickSaveOldQtysimillar(rowData.quantity)"
                                                                                    (blur)="updateLinkedEvent(rowData, i)"
                                                                                    type="number"
                                                                                    onClick="this.select();" pInputText
                                                                                    pKeyFilter="pint"
                                                                                    [(ngModel)]="rowData.quantity"
                                                                                    (ngModelChange)="rowData.quantity=$event"
                                                                                    [tabindex]="i + 1"
                                                                                    style="width: 50px;"
                                                                                    step="linkedItemsData[i]?.quantityIncrement"
                                                                                    min="0" />
                                                                            </div>
                                                                            <button
                                                                                (mousedown)="onclickSaveOldQtysimillar(rowData.quantity)"
                                                                                (click)="incrLinkedEvent(rowData, i)"
                                                                                [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[rowData.recordID +''+ rowData.itemID]"
                                                                                pButton type="button" icon="fas fa-plus"
                                                                                class="cart-add  cart-btn ml-5 add-cart-btn"></button>

                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="(auth.getParentItemShoppingFlag() === false && rowData?.isParentItem === true)">
                                                                        <div *ngIf="rowData?.isParentItem"
                                                                            pTooltip="Parent item are restricted for order."
                                                                            tooltipZIndex="1100000">
                                                                            <button pButton type="button"
                                                                                [disabled]="true"
                                                                                class="ui-button-secondary ui-button"
                                                                                label="Parent Item"></button>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="cart-item-inc-block tbl_act">
                                                                    <div class="cart-item-inc-inner"
                                                                        style="justify-content: center;">
                                                                        <button pButton type="button" label="View"
                                                                            icon="fa fa-eye"
                                                                            (click)="selectlinkedItemsData(rowData, false)"
                                                                            class="btn-theme-color"></button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>

                                                </p-table>
                                            </p-card>
                                        </div>
                                        <!-- B2B-327 (NL 05-OCT-2020) comment button -->
                                        <!-- <div style="text-align: right;margin-top: 10px;" class="d-none"> -->
                                        <!-- // [B2B-229] (MG 03-APR-2020) modified to prevent parent item purchase -->
                                        <!-- <ng-container *ngIf="!(auth.getParentItemShoppingFlag() === false && configData?.activeViewItem?.isParentItem === true)">
                                                <p-button (onClick)="addOrder(configData?.activeViewItem)" type="button" [styleClass]="'btn-theme-color mr-5'" label="Add to order" [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID] || isAddButtonDisable" [icon]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID] ? 'fa fa-fw fa-spinner fa-spin' : 'fa fa-plus'" ></p-button>
                                            </ng-container>
                                            <button pButton type="button" label="Close" icon="fa fa-close" [disabled]="(disableBtnObj | json) != '{}' && disableBtnObj[configData?.activeViewItem?.recordID]" (click)="isOpenChange.emit(false);" class="theme-maroon-btn"></button>
                                        </div> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="shop-more-item-main pb-30 px-15" *ngFor="let itemObj of relatedItemsObj">
                            <!-- Start Shimmer effect -->
                            <div class="shop-more-item-inner d-flex flex-column" *ngIf="itemObj?.value?.loading">
                                <div class="individual-shop-title shimmer-effect p-15"
                                    style="width: 200px;max-width: 100%;margin-left: auto;margin-right: auto;"></div>
                                <div class="shop-more-item-block">
                                    <div class="ui-g or-item-dataview">
                                        <div class="responsive-grid-item grid-item-style">
                                            <div class="position-relative item-main-block">
                                                <div class="item-inner-block">
                                                    <div class="item-detail-block">
                                                        <div
                                                            class="p-5 d-flex justify-content-center position-relative">
                                                            <div class="list-items-image shimmer-effect"
                                                                style="height: 166px;"></div>
                                                        </div>
                                                        <div class="px-5 py-6 mt-5 shimmer-effect"
                                                            style="width: 75%;margin-left: auto;margin-right: auto;display: block;">
                                                        </div>
                                                        <div class="px-5 mt-10 shimmer-effect"
                                                            style="width: 90%;margin-left: auto;margin-right: auto;display: block;height: 20px;">
                                                        </div>
                                                        <div class="px-5 mt-5 shimmer-effect"
                                                            style="width: 60%;margin-left: auto;margin-right: auto;display: block;height: 20px;">
                                                        </div>
                                                    </div>
                                                    <div class="py-10" style="display: flex;">
                                                        <div class="ml-15 py-7 shimmer-effect"
                                                            style="margin-right: auto;width: 60px;"></div>
                                                        <div class="mr-15 py-7 shimmer-effect"
                                                            style="width: 50px;margin-left: auto;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="responsive-grid-item grid-item-style">
                                            <div class="position-relative h-282-px item-main-block">
                                                <div class="item-inner-block">
                                                    <div class="item-detail-block">
                                                        <div
                                                            class="p-5 d-flex justify-content-center position-relative">
                                                            <div class="list-items-image shimmer-effect"
                                                                style="height: 166px;"></div>
                                                        </div>
                                                        <div class="px-5 py-6 mt-5 shimmer-effect"
                                                            style="width: 75%;margin-left: auto;margin-right: auto;display: block;">
                                                        </div>
                                                        <div class="px-5 mt-10 shimmer-effect"
                                                            style="width: 90%;margin-left: auto;margin-right: auto;display: block;height: 20px;">
                                                        </div>
                                                        <div class="px-5 mt-5 shimmer-effect"
                                                            style="width: 60%;margin-left: auto;margin-right: auto;display: block;height: 20px;">
                                                        </div>
                                                    </div>
                                                    <div class="py-10" style="display: flex;">
                                                        <div class="ml-15 py-7 shimmer-effect"
                                                            style="margin-right: auto;width: 60px;"></div>
                                                        <div class="mr-15 py-7 shimmer-effect"
                                                            style="width: 50px;margin-left: auto;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="responsive-grid-item grid-item-style">
                                            <div class="position-relative h-282-px item-main-block">
                                                <div class="item-inner-block">
                                                    <div class="item-detail-block">
                                                        <div
                                                            class="p-5 d-flex justify-content-center position-relative">
                                                            <div class="list-items-image shimmer-effect"
                                                                style="height: 166px;"></div>
                                                        </div>
                                                        <div class="px-5 py-6 mt-5 shimmer-effect"
                                                            style="width: 75%;margin-left: auto;margin-right: auto;display: block;">
                                                        </div>
                                                        <div class="px-5 mt-10 shimmer-effect"
                                                            style="width: 90%;margin-left: auto;margin-right: auto;display: block;height: 20px;">
                                                        </div>
                                                        <div class="px-5 mt-5 shimmer-effect"
                                                            style="width: 60%;margin-left: auto;margin-right: auto;display: block;height: 20px;">
                                                        </div>
                                                    </div>
                                                    <div class="py-10" style="display: flex;">
                                                        <div class="ml-15 py-7 shimmer-effect"
                                                            style="margin-right: auto;width: 60px;"></div>
                                                        <div class="mr-15 py-7 shimmer-effect"
                                                            style="width: 50px;margin-left: auto;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="responsive-grid-item grid-item-style">
                                            <div class="position-relative h-282-px item-main-block">
                                                <div class="item-inner-block">
                                                    <div class="item-detail-block">
                                                        <div
                                                            class="p-5 d-flex justify-content-center position-relative">
                                                            <div class="list-items-image shimmer-effect"
                                                                style="height: 166px;"></div>
                                                        </div>
                                                        <div class="px-5 py-6 mt-5 shimmer-effect"
                                                            style="width: 75%;margin-left: auto;margin-right: auto;display: block;">
                                                        </div>
                                                        <div class="px-5 mt-10 shimmer-effect"
                                                            style="width: 90%;margin-left: auto;margin-right: auto;display: block;height: 20px;">
                                                        </div>
                                                        <div class="px-5 mt-5 shimmer-effect"
                                                            style="width: 60%;margin-left: auto;margin-right: auto;display: block;height: 20px;">
                                                        </div>
                                                    </div>
                                                    <div class="py-10" style="display: flex;">
                                                        <div class="ml-15 py-7 shimmer-effect"
                                                            style="margin-right: auto;width: 60px;"></div>
                                                        <div class="mr-15 py-7 shimmer-effect"
                                                            style="width: 50px;margin-left: auto;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Shimmer effect -->
                            <div class="shop-more-item-inner d-flex flex-column"
                                *ngIf="!itemObj?.value?.loading && itemObj?.value?.data && itemObj?.value?.data.length">
                                <h2 class="individual-shop-title">{{itemObj?.key === 'sameMFR' ? 'Shop Related Items from this Brand' :
                                    'Shop Related Items from Other Brands'}}</h2>
                                <div class="shop-more-item-block">
                                    <div>
                                        <p-dataView [value]="itemObj?.value?.data" layout="grid"
                                            class="or-item-dataview" [loading]="itemObj?.value?.loading"
                                            emptyMessage="{{itemObj?.value?.loading ?
                                                (itemObj?.key === 'sameMFR' ? 'Loading Shop Related Items' : 'Loading Shop Items from Other Brands') : 
                                                (itemObj?.key === 'sameMFR' ? 'No Shop Related Items list yet.' : 'No Shop Items from Other Brands list yet.') }}">
                                            <ng-template let-shopitems let-i='rowIndex' pTemplate="gridItem">
                                                <div class="responsive-grid-item grid-item-style"
                                                    [ngClass]="{'disabled-action-control': !isLogin ? (shopitems?.manufacturerID !== activeViewItem.manufacturerID && isIframeWindow): (shopitems.item['manufacturerID'] !== activeViewItem.manufacturerID && isIframeWindow)}">
                                                    <p-panel [showHeader]="false">
                                                        <div class="position-relative h-282-px item-main-block">
                                                            <div class="item-inner-block">
                                                                <div class="item-detail-block">
                                                                    <div
                                                                        class="p-5 d-flex justify-content-center position-relative">
                                                                        <div class="list-items-image"
                                                                            *ngIf="!shopitems.imageError">
                                                                            <!-- <object class="img-object" data="https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28772/01772.jpg" >
                                                                                <img  src="https://s3.us-east-2.amazonaws.com/reptime-dev-item-images/M28772/01772.jpg" class="img-cover" onerror="this.src='assets/images/no-image-available.png'" alt="shop item image">
                                                                            </object>      -->
                                                                            <app-resized-image
                                                                                [resizedPicturePath]="shopitems.fullPicturePath256PX"
                                                                                [ogPicturePath]="shopitems.picturePath ? shopitems.fullPicturePath : shopitems.profilePic"
                                                                                [imgTagClass]="'img-cover'"
                                                                                (imageLoadChange)="shopitems.imageLoader=!$event">
                                                                            </app-resized-image>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-center pt-5 px-5 item-vendor-name"
                                                                        *ngIf="isLogin">
                                                                        <!-- {{shopitems?.item['repGroupManufacturerName'] ?
                                                                        shopitems?.item['repGroupManufacturerName'] :
                                                                        shopitems?.item['manufacturerName']}} -->
                                                                        {{rtSharedService.getSeletedLanguage(shopitems.item.manufacturerName, 
                                                                            shopitems.item.repGroupManufacturerName,
                                                                            shopitems.item.repGroupManufacturerName_json )}}
                                                                    </div>
                                                                    <div class="text-center pt-5 px-5 item-vendor-name"
                                                                        *ngIf="!isLogin">
                                                                        <!-- {{shopitems?.repGroupManufacturerName ?
                                                                        shopitems?.repGroupManufacturerName :
                                                                        shopitems?.manufacturerName}} -->
                                                                        {{rtSharedService.getSeletedLanguage(shopitems.item.manufacturerName, 
                                                                            shopitems.item.repGroupManufacturerName,
                                                                            shopitems.item.repGroupManufacturerName_json )}}
                                                                    </div>
                                                                    <div class="text-center pt-5 px-5 list-itemdesc"
                                                                        style="font-size: 16px;color: #333333;"
                                                                        [pTooltip]="shopitems?.item['name']"
                                                                        *ngIf="isLogin">
                                                                        {{shopitems?.item['name'] &&
                                                                        shopitems?.item['name'].length >= 40 ?
                                                                        shopitems?.item['name']?.slice(0, 40) + '...'
                                                                        : shopitems?.item['name']}}
                                                                    </div>
                                                                    <div class="text-center pt-5 px-5 list-itemdesc"
                                                                        style="font-size: 16px;color: #333333;"
                                                                        [pTooltip]="shopitems?.item['name']"
                                                                        *ngIf="!isLogin">
                                                                        {{shopitems?.name &&
                                                                        shopitems?.name.length >= 40 ?
                                                                        shopitems?.name?.slice(0, 40) + '...'
                                                                        : shopitems?.name}}
                                                                    </div>
                                                                    <div class="text-center pt-5 px-5 item-next-date"
                                                                        *ngIf="isLogin">
                                                                        <div
                                                                            *ngIf="shopitems?.item['nextAvailableDate']">
                                                                            <b>Next Available :
                                                                            </b>{{shopitems?.item['nextAvailableDate'] |
                                                                            rtDate : 'UTC'}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="item-layer"
                                                                        (click)="viewItemDetail(shopitems.item, i)">
                                                                        <a href="javascript:;"><i
                                                                                class="fa fa-eye"></i></a>
                                                                    </div>
                                                                </div>
                                                                <div class="p-8">
                                                                    <div class="p-grid">
                                                                        <div class="p-md-6 item-min-label-val text-left pb-5 pl-15"
                                                                            style="font-size: 14px; color: #585958;margin-right: auto;word-break: break-all;">
                                                                            #{{isLogin ? shopitems?.item['itemNumber'] :
                                                                            shopitems?.itemNumber}}
                                                                        </div>
                                                                        <!-- {{shopitems | json}} -->
                                                                        <div class="p-md-6 item-min-label-val text-right pb-5"
                                                                            style="font-size: 14px; color: #585958;word-break: break-all;"
                                                                            *ngIf="isLogin && (shopitems?.item?.b2bItemPricingPolicy === 'ShowRetailPrice' || shopitems?.item?.b2bItemPricingPolicy  === 'ShowWholeSalePrice')">
                                                                            {{(shopitems.item['unitPrice'] ?
                                                                            shopitems.item['unitPrice'] : 0 ) |
                                                                            rtCurrency}}
                                                                        </div>
                                                                        <!-- <div class="p-md-6 item-min-label-val text-right pb-5"
                                                                            style="font-size: 14px; color: #585958;word-break: break-all;"
                                                                            *ngIf="!isLogin">
                                                                            {{(shopitems.item['retailPrice'] ?
                                                                            shopitems.item['retailPrice'] : 0 ) |
                                                                            currency}}
                                                                        </div> -->
                                                                        <!-- <div class="p-md-2 item-min-label-val"> -->
                                                                        <!-- [binary]="true" [(ngModel)]="item.isChecked" *ngIf="!pageType && isShowDownload"
                                                                                (onChange)="SelectItemsChange(item,$event)" -->
                                                                        <!-- <p-checkbox ></p-checkbox> -->
                                                                        <!-- </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-panel>
                                                </div>
                                            </ng-template>
                                        </p-dataView>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="shop-other-brand-main pb-10 px-15">
                            <!-- Start Shimmer effect -->
                            <div class="shop-other-brand-inner d-flex flex-column" *ngIf="loadingOtherBrandData">
                                <div class="individual-shop-title shimmer-effect p-15"
                                    style="width: 200px;max-width: 100%;margin-left: auto;margin-right: auto;"></div>
                                <div class="shop-more-item-block">
                                    <div class="ui-g or-item-dataview">
                                        <div class="responsive-grid-item grid-item-style">
                                            <div class="position-relative item-main-block p-10 text-center">
                                                <div class="catalog-img-block shimmer-effect"></div>
                                                <div class="mt-5 shimmer-effect"
                                                    style="height: 13px;width: 75%;margin-left: auto;margin-right: auto;display: block;max-width: 140px;">
                                                </div>
                                                <div class="mt-10 shimmer-effect"
                                                    style="height: 10px;width: 80%;margin-left: auto;margin-right: auto;display: block;max-width: 160px;">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="responsive-grid-item grid-item-style">
                                            <div class="position-relative item-main-block p-10 text-center">
                                                <div class="catalog-img-block shimmer-effect"></div>
                                                <div class="mt-5 shimmer-effect"
                                                    style="height: 13px;width: 75%;margin-left: auto;margin-right: auto;display: block;max-width: 140px;">
                                                </div>
                                                <div class="mt-10 shimmer-effect"
                                                    style="height: 10px;width: 80%;margin-left: auto;margin-right: auto;display: block;max-width: 160px;">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="responsive-grid-item grid-item-style">
                                            <div class="position-relative item-main-block p-10 text-center">
                                                <div class="catalog-img-block shimmer-effect"></div>
                                                <div class="mt-5 shimmer-effect"
                                                    style="height: 13px;width: 75%;margin-left: auto;margin-right: auto;display: block;max-width: 140px;">
                                                </div>
                                                <div class="mt-10 shimmer-effect"
                                                    style="height: 10px;width: 80%;margin-left: auto;margin-right: auto;display: block;max-width: 160px;">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="responsive-grid-item grid-item-style">
                                            <div class="position-relative item-main-block p-10 text-center">
                                                <div class="catalog-img-block shimmer-effect"></div>
                                                <div class="mt-5 shimmer-effect"
                                                    style="height: 13px;width: 75%;margin-left: auto;margin-right: auto;display: block;max-width: 140px;">
                                                </div>
                                                <div class="mt-10 shimmer-effect"
                                                    style="height: 10px;width: 80%;margin-left: auto;margin-right: auto;display: block;max-width: 160px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Shimmer effect -->
                            <div class="shop-other-brand-inner d-flex flex-column"
                                *ngIf="!loadingOtherBrandData && otherBrandList && otherBrandList.length">
                                <h2 class="individual-shop-title">Shop Other Brands</h2>
                                <div class="shop-more-item-block">
                                    <div class="ui-g or-item-dataview">
                                        <p-dataView [value]="otherBrandList" [loading]="loadingOtherBrandData"
                                            emptyMessage="{{loadingOtherBrandData ?
                                            'Loading Shop Other Brands' : 'No Shop Other Brands list yet.'}}"
                                            layout="grid" class="promotion-datview wid-100">
                                            <ng-template let-vendor pTemplate="gridItem">
                                                <div class="responsive-grid-item grid-item-style"
                                                    [ngClass]="{'disabled-action-control': isIframeWindow}"
                                                    (click)="!isIframeWindow ? goToBrandPage(vendor) : ''">
                                                    <div
                                                        class="position-relative item-main-block p-10 text-center overlay-links">
                                                        <div class="catalog-img-block">
                                                            <img alt="vendor logo" src="{{vendor['profilePic']}}"
                                                                onerror="this.onerror=null;this.src='assets/images/no-image-available.png';">
                                                        </div>
                                                        <div class="mb-5" style="color:#585958;">
                                                            <b class="catalogs-nametxt" pTooltip="Manufacturers"
                                                                tooltipPosition="top">
                                                                {{isLogin? rtSharedService.getSeletedLanguage(vendor.repGroupManufacturers[0].name) :
                                                                    rtSharedService.getSeletedLanguage(vendor.repGroupManufacturers[0].name, 
                                                                    vendor.repGroupManufacturerName)}}
                                                            </b>
                                                        </div>
                                                        <div class="text-center d-flex flex-column mb-2"
                                                            style="font-size: 13px;color:#585958;" *ngIf="isLogin">
                                                            <span class="mb-5">Minimum Order
                                                                {{(vendor['minimumOrderAmount'] ?
                                                                vendor['minimumOrderAmount'] : 0 ) | rtCurrency}} </span>
                                                        </div>
                                                        <a pTooltip="View Manufacturer" class="overlay"
                                                            *ngIf="vendor.showlock===1 && isLogin">
                                                            <i class="fa fa-lock"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr class="table-empty">
                                                </tr>
                                            </ng-template>
                                        </p-dataView>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <!-- <div class="item-detail-cart-block">
            <app-cartbar [selectedCustomer]="selectedCustomer" [existCartData]="cartData"
            (promotionsClickEvent)="promotionsClickEvent($event)" (checkoutClickEvent)="checkoutClickEvent($event)"
            (metClickEvent)="metClickE
            vent($event)" (updateExistCardEvent)="updatedCartDataEvent($event)"
            (viewItemDetailEvent)="openItemDetails($event)" class="hide-basket" [pageType]="pageType"></app-cartbar>
        </div>         -->
    </div>
</div>
<p-sidebar [(visible)]="openSidebarStatus" position="right" [baseZIndex]="10000" [dismissible]="true"
    [showCloseIcon]="false" class="cart-prime-sidebar item-detail-sidebar">
    <div class="cart-right-sidebar">
        <app-cartbar [selectedCustomer]="selectedCustomer" [confirmkey]="'item-details-confirmkey'"
            [existCartData]="existCartData"
            (promotionsClickEvent)="promotionsClickCartEvent($event)" (metClickEvent)="metClickCartEvent($event)"
            (checkoutClickEvent)="checkoutClickCartEvent($event)"
            (updateExistCardEvent)="updateExistCardCartEvent($event)"
            (viewItemDetailEvent)="viewItemDetailTileData($event)" [cartBarPosition]="cartBarPosition"
            [pageType]="isLogin === null?true:false" (closeSidebar)="openSidebarStatus=!$event"></app-cartbar>
    </div>
</p-sidebar>
<p-dialog header="Buyer Reviews" [(visible)]="commets_Dialog" [modal]="true" [styleClass]="'comments-dialog'"
    [draggable]="false" [baseZIndex]="11010" [resizable]="false">
    <ng-container *ngIf="showReviewLoader">
        <div class="center main-loader">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
        </div>
    </ng-container>
    <div class="comments-sec" *ngIf="!showReviewLoader">
        <ng-container *ngFor="let reviews of getAllReviews;let i =index;">
            <div class="comment" *ngIf="getAllReviews.length">
                <div class="user-name">{{reviews.retailer.name}}
                    <span class="user-info">{{reviews.retailer.city}}, {{reviews.retailer.state}},
                        {{reviews.retailer.zip}}</span>
                    <span class="comment-date">{{formatDate(reviews.dateModified)}}</span>
                </div>
                <p class="comment-text">{{reviews.review}}</p>
            </div>
        </ng-container>
        <div *ngIf="!getAllReviews.length">
            <p class="text-center"><b>No reviews yet for this item. Please check back later.</b><br>Buyers who have
                previously purchased this item may write a review.</p>
        </div>
    </div>
</p-dialog>
<p-dialog header="Write a Review" [(visible)]="edit_commets" (onHide)="isCloseReviews(true)" [closeOnEscape]="false"
    [modal]="true" [draggable]="false" [styleClass]="'edit-dialog'" [baseZIndex]="11010" [resizable]="false">
    <div class="edit-comments-sec">
        <textarea pInputTextarea appCustomValidation [allowStartWithSpace]="false" [(ngModel)]="itemReview"
            [maxlength]="1000"></textarea>
        <div style="text-align: right;">{{itemReview?itemReview.length:0}}/1000</div>
        <button pButton type="button" label="Save" [disabled]='saveLoading'
            icon="{{!saveLoading?'fa fa-save':'fa fa-spinner fa-spin'}}" class="theme-maroon-btn edit-save-btn"
            (click)="saveReviewMfg()"></button>
    </div>
</p-dialog>
<p-dialog header="360 Preview" [(visible)]="display360Preview" [modal]="true" [blockScroll]="true"
    [contentStyle]="{'max-width': '1310px'}" [dismissableMask]="true" [closeOnEscape]="true" *ngIf="display360Preview">
    <div>
        <mm-three-sixty #threeSixty [width]="(innerWidth > 1310) ? 1280 : innerWidth" [height]="innerHeight" [angles]="72" [anglesPerImage]="1" [speedFactor]="2"
            [startAngle]="1" [images]="images360Preview" [preload]="true" (preloaded)="onPreloaded()"></mm-three-sixty>
        <div class="d-360-spinner" *ngIf="!is360ViewLoaded">
            <i class="pi pi-spin pi-spinner"></i>
        </div>
    </div>
</p-dialog>

<RtCustom-confirmDialog key="breckincrementitemDetail" #breckincrementitemDetail header="Drop database"
    acceptLabel="Yes" rejectLabel="No" [appendTo]="'body'">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" id="YesButton" label="Yes"
            (click)="breckincrementitemDetail.accept()"></button>
        <button type="button" class="ui-button-secondary" pButton icon="pi pi-times" label="No"
            (click)="breckincrementitemDetail.close($event)"></button>
    </p-footer>
</RtCustom-confirmDialog>

<p-overlayPanel #ratting_content [baseZIndex]="12001" [styleClass]="'ratting-info-sec'" [appendTo]="'body'">
    <div class="ratting-info-inner">
        <div class="ratting-avrg">
            <div class="ratting active-ratting">
                <!-- active-ratting class is for active ratting -->
                <!-- unactive-ratting class is for unactive ratting -->
                <!-- fa-star-half-alt -->
                <span
                    [ngClass]="(avgItemsRating > 4 && avgItemsRating < 5)? 'ratting-star ratting-star-half' : (avgItemsRating >= 5)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                <span
                    [ngClass]="(avgItemsRating > 3 && avgItemsRating < 4)? 'ratting-star ratting-star-half' : (avgItemsRating >= 4)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                <span
                    [ngClass]="(avgItemsRating > 2 && avgItemsRating < 3)? 'ratting-star ratting-star-half' : (avgItemsRating >= 3)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                <span
                    [ngClass]="(avgItemsRating > 1 && avgItemsRating < 2)? 'ratting-star ratting-star-half' : (avgItemsRating >= 2)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
                <span
                    [ngClass]="(avgItemsRating > 0 && avgItemsRating < 1)? 'ratting-star ratting-star-half' : (avgItemsRating >= 1)?'ratting-star ratting-star-full':'ratting-star ratting-star-empty'"></span>
            </div>
            <div class="out-of-text"><span class="out-of">{{avgItemsRating}}</span> out of 5</div>
        </div>
        <p class="global-ratting"><span class="global-ratting-value">{{commafy(totalRate)}}</span> global ratting</p>
        <ul class="ratting-info">
            <li>
                <span class="ratting-info-label">5 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="fiveStarPerc"></p-progressBar>
                </div>
            </li>
            <li>
                <span class="ratting-info-label">4 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="fourStarPerc"></p-progressBar>
                </div>
            </li>
            <li>
                <span class="ratting-info-label">3 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="threeStarPerc"></p-progressBar>
                </div>
            </li>
            <li>
                <span class="ratting-info-label">2 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="twoStarPerc"></p-progressBar>
                </div>
            </li>
            <li>
                <span class="ratting-info-label">1 Star</span>
                <div class="ratting-bar">
                    <p-progressBar [value]="oneStarPerc"></p-progressBar>
                </div>
            </li>
        </ul>
    </div>
</p-overlayPanel>