// B2B-53 (MG 04-FEB-2020): modified to save filtered documents
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TableModule} from 'primeng/table';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {DataViewModule} from 'primeng/dataview';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { DocumentListComponent } from './document-list.component';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedModule } from 'src/app/shared/shared.module';

const routes = [{path: '', component: DocumentListComponent}];

@NgModule({
  declarations: [DocumentListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ProgressSpinnerModule,
    TableModule,
    CardModule,
    ButtonModule,
    TooltipModule,
    DataViewModule,
    ToastModule,
    DropdownModule,
    DialogModule,
    PdfViewerModule,
    SharedModule
  ],
  exports: [DocumentListComponent],
})
export class DocumentListModule { }
