<div class="reptime-theme">
    <div class="position-relative action-container text-left my-5">
        <button *ngIf="!disabled && editableItemsDetails" [label]="'Add More Items'"
            class="ui-btn ui-button-info ui-button mx-5 btn-theme-color" icon="fa fa-plus"
            (click)="openItemSearchModal(order)" pButton></button>
        <!--<button pButton
                label="Delete{{!(selectedTableItemArray == null || selectedTableItemArray.length == 0) ? ' (' + selectedTableItemArray.length + ')' : ''}}"
                (click)="deleteItem()"
                [disabled]="selectedTableItemArray == null || selectedTableItemArray.length == 0"
                class="ui-button-danger ui-button"
                icon="fa fa-trash"></button>-->
    </div>
    <p-card class="card">
        <div class="table-responsive-wpr">
            <p-table id="items-table" [columns]="order.itemTable.orderDetailCols" [value]="items" dataKey="recordID"
                #itemListingTable [totalRecords]="items.length" [paginator]="false"
                [rowsPerPageOptions]="[10, 20, 50, 100]" [rows]="10" [(selection)]="selectedTableItemArray"
                class="order-tbl-scroll">
                <ng-template pTemplate="paginatorright">
                    <div class="per-page-text">Per page :</div>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    <div class="per-page-text px-10">Total :</div>
                    {{items.length}}
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <!--<th style="width: 40px">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>-->
                        <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" [style.width]="col.width"
                            [class]="col.class">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.spinner" [field]="col.field"></p-sortIcon>
                        </th>
                        <th style="width:110px" class="reptimesticky-possition reptimesticky-possition-right-0"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr [pSelectableRow]="rowData" *ngIf="!rowData.recordDeleted">
                        <!--<td style="width:40px;">
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </td>-->
                        <!-- B2B-151 (NL 09-MAR-2020) add image thumb column -->
                        <td *ngFor="let col of columns">
                            <div [ngSwitch]="col.field" class="text-ellipsis">
                                <div *ngSwitchCase="'picturePath'" class="item-img-thumb">
                                    <!-- <img src="{{rowData['fullPicturePath']}}" alt="item image thumb" onError="this.src='assets/images/no-image-available.png';">   -->

                                    <!-- <img *ngIf="rowData.imageError" src="{{rowData.profilePic}}" onerror="this.src='assets/images/no-image-available.png'">
                                    <img *ngIf="rowData.imageFullError"
                                                (error)="rowData.imageError=true;rowData.imageFullError=false"
                                                src="{{rowData.fullPicturePath}}" />
                                    <img *ngIf="!rowData.imageFullError && !rowData.imageError"
                                                (error)="rowData.imageFullError=true"
                                                src="{{rowData.fullPicturePath64PX}}" /> -->
                                    <app-resized-image [resizedPicturePath]="rowData.fullPicturePath64PX"
                                        [ogPicturePath]="rowData.fullPicturePath ? rowData.fullPicturePath : rowData.profilePic">
                                    </app-resized-image>
                                    <!-- <object [data]="rowData.fullPicturePath64PX">
                                            <img [src]="rowData.picturePath ? rowData.fullPicturePath : rowData.profilePic" alt="item image thumb"  onerror="this.src='assets/images/no-image-available.png'">
                                    </object>                                -->
                                </div>
                                <div *ngSwitchCase="'itemnumber'">
                                    <a style="color:#333;"> {{rowData[col.field]}}</a>
                                </div>
                                <div *ngSwitchCase="'name'" class="">
                                    <a style="color:#333;"> {{rowData[col.field]}} </a>
                                </div>
                                <div *ngSwitchCase="'description'">
                                    <a style="color:#333;" [ngClass]="{'twolinedesc' : col.field === 'description'}"
                                        pTooltip="{{rowData[col.field]}}" tooltipPosition="top">
                                        {{rowData[col.field]}}
                                    </a>
                                    <a style="color:#333;" *ngIf="col.field !=='description'">
                                        {{rowData[col.field]}}
                                    </a>
                                    <!-- <a style="color:#333;"> {{rowData[col.field]}} </a> -->
                                    <div class="" style="color:#333;" *ngIf="rowData['notes']"> <b
                                            style="font-size: 15px;font-weight: normal;">Note: </b> {{rowData['notes']}}
                                    </div>
                                </div>
                                <div *ngSwitchCase="'minimumQuantity'">
                                    {{(rowData[col.field] ? rowData[col.field] : null) | number}}
                                </div>
                                <div *ngSwitchCase="'unitPrice'" [class]="col.class">
                                    {{rowData[col.field] | rtCurrency}}
                                </div>
                                <div *ngSwitchCase="'upc1'">
                                    <a style="color:#333;">{{rowData[col.field]}}</a>
                                </div>
                                <div *ngSwitchCase="'isavailable'" style="text-align:center;">
                                    <i *ngIf="rowData[col.field] === 1" class="fa fa-check green"></i>
                                    <div *ngIf="rowData[col.field] === 0 && rowData['nextavailabledate'] !== null">
                                        {{rowData['nextavailabledate'] | rtDate: 'UTC'}}
                                    </div>
                                    <i *ngIf="rowData[col.field] === 0 && rowData['nextavailabledate'] === null"
                                        class="fa fa-times red"></i>
                                </div>
                                <div *ngSwitchCase="'qtyavailable'">{{(rowData[col.field] ? rowData[col.field] : null) |
                                    number}}
                                </div>
                                <div *ngSwitchCase="'datemodified'">{{rowData[col.field] | rtDate: 'UTC'}}
                                </div>
                                <div *ngSwitchCase="'itemDiscount'">
                                    <input type="number" pInputText [(ngModel)]="rowData[col.field]" max="100" min="0"
                                        [disabled]="disabled || !editableItemsDetails" onClick="this.select();"
                                        (blur)="updateOrderDetail(rowData, rowIndex)" style="width: 75px;" />
                                </div>
                                <div *ngSwitchCase="'priceCodes'">
                                    <select [(ngModel)]="rowData.priceCode" class="price-code-select"
                                        (change)="updateOrderDetail(rowData, rowIndex)" [disabled]="disabled || !editableItemsDetails"
                                        *ngIf="rowData[col.field] && rowData[col.field].length" style="width: 85px;">
                                        <option [value]="''">Select</option>
                                        <option *ngFor="let obj of rowData[col.field]" [value]="obj.priceCode">
                                            <!-- {{obj.priceCode}} -->
                                            {{obj.manufacturerPriceCode ? obj.manufacturerPriceCode + ' (' +
                                            obj.priceCode + ')' : obj.priceCode}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngSwitchCase="'quantity'">
                                    <input type="text" pInputText [(ngModel)]="rowData[col.field]" pKeyFilter="pint"
                                        onClick="this.select();" [disabled]="disabled || !editableItemsDetails"
                                        (blur)="setQuantity(rowData, rowIndex)" id="qty{{rowData.recordID}}"
                                        (mousedown)="onclickSaveOldQty(rowData)" step="rowData.quantityIncrement"
                                        style="width: 75px;" [min]="rowData.minimumQuantity" />
                                </div>
                                <div *ngSwitchCase="'total'" [class]="col.class">
                                    {{rowData[col.field] | rtCurrency}}
                                </div>
                                <div *ngSwitchDefault>{{rowData[col.field]}}</div>
                            </div>
                        </td>
                        <td width="" class="reptimesticky-possition reptimesticky-possition-right-0">
                            <!-- <button (click)="updateOrderDetail(rowData, rowIndex)" label="Save"
                                     class="ui-button-info ui-button mr-2"
                                     icon="fa fa-save" pButton></button>-->
                            <button (click)="openOrderItemNote(rowData, rowIndex)"
                                pTooltip="{{rowData.notes ? rowData.notes : 'Add note'}}" tooltipPosition="left"
                                class="ui-button " [disabled]="disabled"
                                [ngClass]="{'ui-button-warning': rowData.notes, '': !rowData.notes}"
                                icon="fa fa-sticky-note" pButton></button>
                            <button (click)="deleteOrderDetail(rowData, rowIndex)" pTooltip="Delete"
                                tooltipPosition="left" [disabled]="disabled || !editableItemsDetails" class="ui-button-danger ui-button ml-5"
                                icon="fa fa-trash" pButton></button>
                            <!-- theme-deleteicon-tbl -->
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-card>
</div>