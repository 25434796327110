import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from 'src/app/shared/shared.module';
import {HomeComponent} from './home.component';
import { MyOrdersModule } from '../my-orders/my-orders.module';
const routes = [{path: '', component: HomeComponent}];
@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
        MyOrdersModule
    ],
    providers: [
        // {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
        // InterceptorService,
        // DatePipe,
    ],
    exports: [HomeComponent],
})
export class HomeModule {
}
