import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { NotAuthGuardService } from './services/other/not-auth-guard/not-auth-guard.service';
import { AuthGuardService } from './services/other/auth-guard/auth-guard.service';
import { NewOrderComponent } from './modules/pages/new-order/new-order.component';
import { CanactiveRouterService } from './services/other/canactive-router/canactive-router.service';
import { MyOrdersComponent } from './modules/pages/my-orders/my-orders.component';
import { AuthService } from './services/middleware/auth/auth.service';
import * as _ from 'lodash';
// import {FinalizeOrderComponent, NewOrderComponent} from 'reptime-shared-modules';
// B2B-53 (MG 04-FEB-2020): modified to save filtered documents
// B2B-260 (MJ 26-Jun-2020) document module added


const routes: Routes = [
    {
        path: '404', data: { entityType: '404' }, loadChildren: () => import('./modules/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
    },
    { path: '**', data: { entityType: '**' }, redirectTo: '/404', pathMatch: 'full' },
];
const lightovationRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        data: {
            index: 0,
            entityType: 'lightovation-home',
        },
        loadChildren: () => import('./modules/pages/lightovation-home/lightovation-home.module').then(m => m.LightovationHomeModule)
    },
    {
        path: 'home',
        canActivate: [AuthGuardService],
        data: {
            index: 0,
            entityType: 'lightovation-home',
        },
        loadChildren: () => import('./modules/pages/lightovation-home/lightovation-home.module').then(m => m.LightovationHomeModule)
    },
    {
        path: 'login',
        data: { entityType: 'login' },
        loadChildren: () => import('./modules/pages/login/login.module').then(m => m.LoginModule),
        canActivate: [NotAuthGuardService],
    },
    {
        path: 'signup',
        data: { entityType: 'signup' },
        loadChildren: () => import('./modules/pages/signup/signup.module').then(m => m.SignupModule),
        canActivate: [NotAuthGuardService],

    },
    {
        path: 'magazine',
        canActivate: [AuthGuardService],
        data: {
            entityType: 'magazine'
        },
        loadChildren: () => import('./modules/pages/lightovation-magazine/lightovation-magazine.module').then(m => m.LightovationMagazineModule)
    },
    {
        path: 'landing-page',
        canActivate: [AuthGuardService],
        data: {
            entityType: 'landing-page'
        },
        loadChildren: () => import('./modules/pages/event-landing/event-landing.module').then(m => m.EventLandingModule)
    },
    { path: 'my-orders', data: { entityType: 'my-orders' }, component: MyOrdersComponent, canActivate: [AuthGuardService] },

    {
        path: 'documentlist', data: { entityType: 'documentlist' },
        loadChildren: () => import('./modules/pages/document-list/document-list.module').then(m => m.DocumentListModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'document', data: { entityType: 'document' },
        loadChildren: () => import('./modules/pages/document/document.module').then(m => m.DocumentModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'promotions', data: { entityType: 'promotions' },
        loadChildren: () => import('./modules/pages/promotions/promotions.module').then(m => m.PromotionsModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'vendors', data: { entityType: 'vendors' },
        loadChildren: () => import('./modules/pages/vendors-listing/vendors-listing.module').then(m => m.VendorsListingModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'vendor', data: { entityType: 'vendor' },
        loadChildren: () => import('./modules/pages/vendor-item/vendor-item.module').then(m => m.VendorItemModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'category', data: { entityType: 'category' },
        loadChildren: () => import('./modules/pages/category/category.module').then(m => m.CategoryModule),
        canActivate: [AuthGuardService],
    },
    { path: 'shop', data: { entityType: 'shop' }, component: NewOrderComponent, canActivate: [AuthGuardService] },
    {
        path: 'finalize-order', data: { entityType: 'finalize-order' },
        loadChildren: () => import('./modules/pages/finalize-order/finalize-order.module').then(m => m.FinalizeOrderModule),
        canActivate: [AuthGuardService], canDeactivate: [CanactiveRouterService]
    },
    {
        path: 'rep-group-msg', data: { entityType: 'rep-group-msg' },
        loadChildren: () => import('./modules/pages/rep-group-msg/rep-group-msg.module').then(m => m.RepGroupMsgModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'rep-retailer-notfound', data: { entityType: 'rep-retailer-notfound' },
        loadChildren: () => import('./modules/pages/rep-retailer-notfound/rep-retailer-notfound.module').then(m => m.RepRetailerNotfoundModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'shipping-location-not-found', data: { entityType: 'shipping-location-not-found' },
        loadChildren: () => import('./modules/pages/shipping-location-not-found/shipping-location-not-found.module').then(m => m.ShippingLocationNotFoundModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'rep-group-msg-pending', data: { entityType: 'rep-group-msg-pending' },
        loadChildren: () => import('./modules/pages/rep-group-msg/rep-group-msg.module').then(m => m.RepGroupMsgModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'rep-group-access-denied', data: { entityType: 'rep-group-access-denied' },
        loadChildren: () => import('./modules/pages/rep-group-access-denied/rep-group-access-denied.module').then(m => m.RepGroupAccessDeniedModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'forgot-password', data: { entityType: 'forgot-password' },
        loadChildren: () => import('./modules/pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
        canActivate: [NotAuthGuardService]
    },
    {
        path: 'forgot-username', data: { entityType: 'forgot-username' },
        loadChildren: () => import('./modules/pages/forgot-username/forgot-username.module').then(m => m.ForgotUsernameModule),
        canActivate: [NotAuthGuardService]
    },
    {
        path: 'profile', data: { entityType: 'profile' },
        loadChildren: () => import('./modules/pages/company-info/company-info.module').then(m => m.CompanyInfoModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'change-password', data: { entityType: 'change-password' },
        loadChildren: () => import('./modules/pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'manage-company', data: { entityType: 'manage-company' },
        loadChildren: () => import('./modules/pages/manage-company/manage-company.module').then(m => m.ManageCompanyModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'vendors-list', data: { entityType: 'vendors-list' },
        loadChildren: () => import('./modules/pages/vendors-public/vendors-public.module').then(m => m.VendorsPublicModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'vendor-details', data: { entityType: 'vendor-details' },
        loadChildren: () => import('./modules/pages/vendor-item-public/vendor-item-public.module').then(m => m.VendorItemPublicModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'store', data: { entityType: 'store' },
        loadChildren: () => import('./modules/pages/shop-details/shop-details.module').then(m => m.ShopDetailsModule),
        canActivate: [AuthGuardService],
    },
    // {
    //     path: 'item-detail/:recordId', data: { entityType: 'item-detail' },
    //     loadChildren: () => import('./modules/pages/item-details/item-details.module').then(m => m.ItemDetailsModule),
    //     canActivate: [AuthGuardService],
    // },
    {
        path: 'auth/:token', data: { entityType: 'auth' },
        loadChildren: () => import('./modules/pages/authenticate-user/authenticate-user.module').then(m => m.AuthenticateUserModule),
        canActivate: [NotAuthGuardService],
    },
    {
        path: 'mtpay/:token', data: { entityType: 'auth' },
        loadChildren: () => import('./modules/pages/mtpay-payment-verification/mtpay-payment-verification.module').then(m => m.MtpayPaymentVerificationModule),
        // canActivate: [NotAuthGuardService],
    },
    {
        path: 'cart', data: { entityType: 'full-cart' },
        loadChildren: () => import('./modules/pages/full-cart/full-cart.module').then(m => m.FullCartModule),
        canActivate: [AuthGuardService],
    },
    {
        path: '404', data: { entityType: '404' }, loadChildren: () => import('./modules/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
    },
    { path: '**', data: { entityType: '**' }, redirectTo: '/404', pathMatch: 'full' }
];
const b2baccessRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        data: {
            index: 0,
            entityType: 'home',
        },
        loadChildren: () => import('./modules/pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'home',
        canActivate: [AuthGuardService],
        data: {
            index: 0,
            entityType: 'home',
        },
        loadChildren: () => import('./modules/pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'login',
        data: { entityType: 'login' },
        loadChildren: () => import('./modules/pages/login/login.module').then(m => m.LoginModule),
        canActivate: [NotAuthGuardService],
    },
    {
        path: 'signup',
        data: { entityType: 'signup' },
        loadChildren: () => import('./modules/pages/signup/signup.module').then(m => m.SignupModule),
        canActivate: [NotAuthGuardService],

    },
    {
        path: 'documentlist', data: { entityType: 'documentlist' },
        loadChildren: () => import('./modules/pages/document-list/document-list.module').then(m => m.DocumentListModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'document', data: { entityType: 'document' },
        loadChildren: () => import('./modules/pages/document/document.module').then(m => m.DocumentModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'promotions', data: { entityType: 'promotions' },
        loadChildren: () => import('./modules/pages/promotions/promotions.module').then(m => m.PromotionsModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'vendors', data: { entityType: 'vendors' },
        loadChildren: () => import('./modules/pages/vendors-listing/vendors-listing.module').then(m => m.VendorsListingModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'vendor', data: { entityType: 'vendor' },
        loadChildren: () => import('./modules/pages/vendor-item/vendor-item.module').then(m => m.VendorItemModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'category', data: { entityType: 'category' },
        loadChildren: () => import('./modules/pages/category/category.module').then(m => m.CategoryModule),
        canActivate: [AuthGuardService],
    },
    { path: 'shop', data: { entityType: 'shop' }, component: NewOrderComponent, canActivate: [AuthGuardService] },
    {
        path: 'finalize-order', data: { entityType: 'finalize-order' },
        loadChildren: () => import('./modules/pages/finalize-order/finalize-order.module').then(m => m.FinalizeOrderModule),
        canActivate: [AuthGuardService], canDeactivate: [CanactiveRouterService]
    },
    {
        path: 'rep-group-msg', data: { entityType: 'rep-group-msg' },
        loadChildren: () => import('./modules/pages/rep-group-msg/rep-group-msg.module').then(m => m.RepGroupMsgModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'rep-retailer-notfound', data: { entityType: 'rep-retailer-notfound' },
        loadChildren: () => import('./modules/pages/rep-retailer-notfound/rep-retailer-notfound.module').then(m => m.RepRetailerNotfoundModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'shipping-location-not-found', data: { entityType: 'shipping-location-not-found' },
        loadChildren: () => import('./modules/pages/shipping-location-not-found/shipping-location-not-found.module').then(m => m.ShippingLocationNotFoundModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'rep-group-msg-pending', data: { entityType: 'rep-group-msg-pending' },
        loadChildren: () => import('./modules/pages/rep-group-msg/rep-group-msg.module').then(m => m.RepGroupMsgModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'rep-group-access-denied', data: { entityType: 'rep-group-access-denied' },
        loadChildren: () => import('./modules/pages/rep-group-access-denied/rep-group-access-denied.module').then(m => m.RepGroupAccessDeniedModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'forgot-password', data: { entityType: 'forgot-password' },
        loadChildren: () => import('./modules/pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
        canActivate: [NotAuthGuardService]
    },
    {
        path: 'forgot-username', data: { entityType: 'forgot-username' },
        loadChildren: () => import('./modules/pages/forgot-username/forgot-username.module').then(m => m.ForgotUsernameModule),
        canActivate: [NotAuthGuardService]
    },
    {
        path: 'profile', data: { entityType: 'profile' },
        loadChildren: () => import('./modules/pages/company-info/company-info.module').then(m => m.CompanyInfoModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'change-password', data: { entityType: 'change-password' },
        loadChildren: () => import('./modules/pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'manage-company', data: { entityType: 'manage-company' },
        loadChildren: () => import('./modules/pages/manage-company/manage-company.module').then(m => m.ManageCompanyModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'vendor-details', data: { entityType: 'vendor-details' },
        loadChildren: () => import('./modules/pages/vendor-item-public/vendor-item-public.module').then(m => m.VendorItemPublicModule),
        canActivate: [NotAuthGuardService],
    },
    {
        path: 'cart', data: { entityType: 'full-cart' },
        loadChildren: () => import('./modules/pages/full-cart/full-cart.module').then(m => m.FullCartModule),
        canActivate: [AuthGuardService],
    },
    // {
    //     path: 'item-detail/:recordId', data: { entityType: 'item-detail' },
    //     loadChildren: () => import('./modules/pages/item-details/item-details.module').then(m => m.ItemDetailsModule),
    //     canActivate: [AuthGuardService],
    // },
    {
        path: 'auth/:token', data: { entityType: 'auth' },
        loadChildren: () => import('./modules/pages/authenticate-user/authenticate-user.module').then(m => m.AuthenticateUserModule),
        canActivate: [NotAuthGuardService],
    },
    {
        path: 'mtpay/:token', data: { entityType: 'auth' },
        loadChildren: () => import('./modules/pages/mtpay-payment-verification/mtpay-payment-verification.module').then(m => m.MtpayPaymentVerificationModule),
        // canActivate: [NotAuthGuardService],
    },
    {
        path: '404', data: { entityType: '404' }, loadChildren: () => import('./modules/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
    },
    { path: '**', data: { entityType: '**' }, redirectTo: '/404', pathMatch: 'full' },
];
const b2baccessUserRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        data: {
            index: 0,
            entityType: '',
        },
        loadChildren: () => import('./modules/b2baccess-user/b2baccess-user.module').then(m => m.B2baccessUserModule)
    },
    {
        path: 'mtpay/:token', data: { entityType: 'auth' },
        loadChildren: () => import('./modules/pages/mtpay-payment-verification/mtpay-payment-verification.module').then(m => m.MtpayPaymentVerificationModule),
        // canActivate: [NotAuthGuardService],
    },
    {
        path: '404', data: { entityType: '404' }, loadChildren: () => import('./modules/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
    },
    { path: '**', data: { entityType: '**' }, redirectTo: '/404', pathMatch: 'full' },
];
const landingPageRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        data: {
            entityType: 'landing-page'
        },
        loadChildren: () => import('./modules/pages/event-landing/event-landing.module').then(m => m.EventLandingModule)
    },
    {
        path: 'mtpay/:token', data: { entityType: 'auth' },
        loadChildren: () => import('./modules/pages/mtpay-payment-verification/mtpay-payment-verification.module').then(m => m.MtpayPaymentVerificationModule),
        // canActivate: [NotAuthGuardService],
    },
    {
        path: 'signup',
        data: { entityType: 'signup' },
        loadChildren: () => import('./modules/pages/signup/signup.module').then(m => m.SignupModule),

    },
    { path: '**', data: { entityType: '**' }, redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
    entryComponents: [NewOrderComponent],
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled'
    })],
    providers: [],
    exports: [RouterModule]
})
export class AppRoutingModule {
    public domainData: any = (<any>window).domainData;

    constructor(private router: Router, public _AuthService: AuthService) {
        this.resetRouter();
    }
    async resetRouter() {
        if (!this.domainData) {
            this.router.resetConfig(b2baccessUserRoutes);
        } else if (this.domainData && (this.domainData.themeStyle && this.domainData.themeStyle === 'awesome')) {
            if (this.domainData.repGroupThemeSettings.islandingPageVisible) {
                this.router.resetConfig(landingPageRoutes);
            } else {
                this.router.resetConfig(lightovationRoutes);
            }
        } else {
            const isIframeWindow: any = (window as any).iframeData;
            console.log('isIframeWindow >>', isIframeWindow, b2baccessRoutes, window.self !== window.top);
            if (!isIframeWindow && window.self === window.top) {
                // const filterb2baccessRoutes = _.filter(b2baccessRoutes, (el) => {
                //     return el.path !== 'vendor-details';
                // });
                _.remove(b2baccessRoutes, (el) => {
                    return el.path === 'vendor-details';
                });
            }
            this.router.resetConfig(b2baccessRoutes);
        }
    }
}
