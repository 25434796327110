import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams , HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {RTSharedService} from '../rtshared/rtshared.service';


@Injectable()
export class RetailerService {

    public baseUrl = environment.apiUrl;
    constructor(private httpClient: HttpClient, private rtSharedService: RTSharedService) {
    }

    getRetailerCarrierAcccount(retailerID) {
        const url = `${environment.apiUrl}reptime/api/retailer/${retailerID}/carrierAccount/all`;
        return this.httpClient.get<any>(url);
    }

    saveRetailerCarrierAccounts(selectedCarrierAccount, retailerId) {
        const url = `${environment.apiUrl}reptime/api/retailer/${retailerId}/retailerCarrierAccount/save`;
        return this.httpClient.post<any>(url, selectedCarrierAccount);
    }

}
