import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, DynamicDialogConfig, DynamicDialogRef, MessageService, DialogService } from 'primeng/api';

import { BasketItemVariantService } from '../../services/middleware/basket-item-variant/basket-item-variant.service';
import { BasketItemService } from '../../services/middleware/basket-item/basket-item.service';
import * as _ from 'lodash';
import { OrderService } from '../../services/middleware/order/order.service';
import { Subscription } from 'rxjs';
import { RTSharedService } from '../../services/rtshared/rtshared.service';
import { ItemsListModalComponent } from '../../modals/items-list-modal/items-list-modal.component';
import { RtDatePipe } from '../../pipes/rt-date.pipe';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
@Component({
    selector: 'rs-isc-dialog',
    templateUrl: './isc-dialog.component.html',
    styleUrls: ['./isc-dialog.component.scss']
})
export class ISCDialogComponent implements OnInit, OnDestroy {
    public iscArr: any[];
    public cols: any[];
    public itemObj: any;
    public itemMfgIDs: any;
    public existCartData: any;
    public retailerObj: any;
    public fromPage: string;
    public orderID: number;
    public manufacturerID: string;
    public sequenceID: any;
    public addDisbaleObj: any = {};
    addBtnDisableSub: Subscription;
    oldQty = 0;
    constructor(
        public config: DynamicDialogConfig,
        public dialogService: DialogService,
        private basketItemService: BasketItemService,
        private orderService: OrderService,
        private msgService: MessageService,
        public ref: DynamicDialogRef,
        private confirmationService: ConfirmationService,
        private rtSharedService: RTSharedService,
        private bivService: BasketItemVariantService,
        public dp: DatePipe) {

        this.addBtnDisableSub = this.orderService.getAddBtnDisableSub()
            .subscribe(async (eventObj) => {
                this.addDisbaleObj = eventObj; // subject for disbale/enable add button
            });
    }

    ngOnInit() {
        this.iscArr = this.config.data.isc;
        this.itemObj = this.config.data.item;
        this.retailerObj = this.config.data.retailer;
        this.fromPage = this.config.data.fromPage;
        this.sequenceID = this.config.data.sequenceID;
        this.manufacturerID = this.config.data.manufacturerID;
        this.orderID = this.config.data.orderID;
        this.itemMfgIDs = this.config.data.itemMfgIDs;
        this.existCartData = this.config.data.cartData;
        console.log('>> this.itemMfgIDs', this.itemMfgIDs);
        console.log('>> this.itemMfgIDs', this.existCartData);
        if (this.iscArr && this.iscArr.length) {
            if (this.fromPage === 'ORDER_PAGE') {
                this.iscArr = this.iscArr.map((o) => {
                    if (this.itemMfgIDs && this.itemMfgIDs.length) {
                        const existFind = this.itemMfgIDs.find(v => o.itemID === v.itemID && o.recordID === v.itemColorStyleID && !v.recordDeleted);
                        o.quantity = existFind && existFind.quantity ? existFind.quantity : 0;
                    } else {
                        o.quantity = 0;
                    }
                    return o;
                });
            }
        }
        this.cols = [{ field: 'size', header: 'Size', width: '120px' },
        { field: 'color', header: 'Color', width: '120px' },
        { field: 'style', header: 'Style', width: '120px' },
        { field: 'quantity', header: 'Quantity', width: '120px' }];
    }

    async updateOrderDetail(detailObj) {
        const updateDetailObj: any = _.cloneDeep(detailObj);
        if (this.validateDateBetweenTwoDates(updateDetailObj)) {
            updateDetailObj['itemDiscount'] = updateDetailObj.discountPercent;
        }
        const updateDetail: any = await this.orderService.updateOrderDetail(this.orderID, updateDetailObj, this.manufacturerID);
        if (updateDetail && updateDetail.recordID) {
            const updateOrderDetailJSON = {
                itemNumber: updateDetail.itemNumber,
                manufacturerID: updateDetail.manufacturerID ? updateDetail.manufacturerID : this.manufacturerID,
                recordDeleted: updateDetail.recordDeleted,
                size: updateDetail.size,
                color: updateDetail.color,
                style: updateDetail.style,
                recordID: updateDetail.recordID,
                quantity: updateDetail.quantity,
                sequenceID: updateDetail.sequenceID,
                itemID: updateDetail.itemID,
                itemColorStyleID: updateDetail.itemSCSID
            };
            const index = _.findIndex(this.itemMfgIDs, (o) => updateDetailObj.recordID === o.recordID);
            if (index === -1) {
                this.sequenceID = updateDetail.sequenceID;
                this.itemMfgIDs.push(updateOrderDetailJSON);
            } else {
                this.itemMfgIDs[index] = updateOrderDetailJSON;
            }
            const eventObj = {
                itemObj: updateDetail,
                orderID: this.orderID
            };
            this.orderService.setAddOrderItemSub(eventObj);
        }
    }

    incrQtyVariantEvent(rowData, index) {
        rowData.quantity = rowData.quantity + this.itemObj.quantityIncrement;
        this.commonQtyManageFunVariant(rowData, index, 'plusBtn');
        // this.saveItemVariantToBasket(rowData);
    }

    async saveItemVariantToBasket(iscObj: any) {
        // console.log(iscObj);
        // let existFind = this.itemMfgIDs.find(o => (this.itemObj.itemNumber === o.itemNumber &&
        //      this.itemObj.manufacturerID === o.manufacturerID && o.itemColorStyleID === iscObj.recordID.toString()));
        // if (this.fromPage === 'ORDER_PAGE') {
        //     existFind = this.itemMfgIDs.find(o => (this.itemObj.itemNumber === o.itemNumber &&
        //          this.itemObj.manufacturerID === o.manufacturerID && o.size === iscObj.size &&
        //           o.color === iscObj.color && o.style === iscObj.style && !o.recordDeleted));
        // }
        // if (existFind) {
        //     this.confirmationService.confirm({
        //         message: 'Selected item already on order. Do you want to update its quantity? ',
        //         header: 'Item Already Available',
        //         icon: 'pi pi-exclamation-triangle',
        //         accept: async () => {
        //             this.ref.close({type: 'EXIST', itemMfgObj: existFind});
        //         },
        //         reject: () => {
        //         },
        //         key: 'unfinishedOrder'
        //     });
        // } else {
        this.orderService.setAddBtnDisable(`${iscObj.recordID}${iscObj.itemID}`); // subject for disable add button when item adding
        if (this.fromPage !== 'ORDER_PAGE') {
            const existFind = this.existCartData.items.find(o => this.itemObj.recordID === o.item.recordID);
            const existIscObj = existFind && existFind.basketItemVariants.find((v) => iscObj.itemID === v.itemID && iscObj.recordID.toString() === v.itemColorStyleID);
            if (iscObj.quantity || existIscObj) {
                const msgServiceJson = {
                    key: 'addItem',
                    severity: 'info',
                    summary: 'Item Added to Cart',
                    detail: `${this.itemObj.itemNumber} was saved successfully.`
                };
                const retailerID: string = this.retailerObj.recordID;
                const selectedShipTo: string = this.retailerObj.selectedShipTo.recordID;
                if (existIscObj) {
                    existIscObj.quantity = iscObj.quantity = iscObj.quantity ? iscObj.quantity : this.itemObj.minimumQuantity;
                    await this.bivService.saveItemVariantToBasket(retailerID, selectedShipTo, this.itemObj, existIscObj);
                    msgServiceJson.summary = 'Item Updated to Cart';
                } else {
                    iscObj.quantity = iscObj.quantity ? iscObj.quantity : this.itemObj.minimumQuantity;
                    await this.bivService.saveItemVariantToBasket(retailerID, selectedShipTo, this.itemObj, iscObj);
                }
                this.itemMfgIDs.push({
                    itemNumber: this.itemObj.itemNumber,
                    manufacturerID: this.itemObj.manufacturerID,
                    itemColorStyleID: iscObj.recordID.toString()
                });
                this.basketItemService.setCartEvent(this.retailerObj);
                this.msgService.clear();
                this.msgService.add(msgServiceJson);
                this.existCartData = await this.basketItemService.getBasketItemData(this.retailerObj);
            }
        } else {
            const msgServiceJson = {
                key: 'addItem',
                severity: 'info',
                summary: 'Item Added to Order',
                detail: `${this.itemObj.itemNumber} was saved successfully.`
            };
            const existFind = this.itemMfgIDs.find(v => iscObj.itemID === v.itemID && iscObj.recordID === v.itemColorStyleID && !v.recordDeleted);
            if (iscObj.quantity || existFind) {
                iscObj.quantity = iscObj.quantity ? iscObj.quantity : this.itemObj.minimumQuantity;
                const updateObj = {
                    b2bPriceCode: this.itemObj.b2bPriceCode ? this.itemObj.b2bPriceCode : null,
                    color: iscObj.color,
                    commissionPercent: null,
                    description: this.itemObj.description,
                    inventoryAtMoment: null,
                    invoiceQuantity: null,
                    invoiceUnitPrice: null,
                    itemDiscount: this.itemObj.itemDiscount,
                    itemNumber: this.itemObj.itemNumber,
                    itemStatus: null,
                    listPrice: this.itemObj.retailPrice,
                    name: this.itemObj.name,
                    notes: null,
                    orderInvoiceID: null,
                    QAComments: null,
                    QATestReport: null,
                    quantity: iscObj.quantity,
                    retailerItemNumber: null,
                    size: iscObj.size,
                    style: iscObj.style,
                    unitPrice: this.itemObj.unitPrice,
                    unitQty: this.itemObj.unitQty,
                    sequenceID: this.sequenceID + 1,
                    priceCode: this.itemObj.selectedPriceCode,
                    picturePath: this.itemObj.picturePath,
                    fullPicturePath: this.itemObj.fullPicturePath,
                    itemID: iscObj.itemID,
                    itemSCSID: iscObj.recordID,
                    recordID: null
                };
                if (existFind && existFind.recordID) {
                    updateObj.recordID = existFind.recordID;
                    updateObj.sequenceID = existFind.sequenceID;
                    msgServiceJson.summary = 'Item Update to Order';
                } else {
                    delete updateObj.recordID;
                }
                const resObj = await this.updateOrderDetail(updateObj);
                this.msgService.clear();
                this.msgService.add(msgServiceJson);
            }
        }
        // subject for enable add button when item adding done
        this.orderService.removeAddBtnDisable(`${iscObj.recordID}${iscObj.itemID}`);
        // }
    }
    /* RC-343 (RK 2020-JAN-31) Show Item History Popup with ISC*/
    async openHistoryItem(iscObj: any, event: any) {
        console.log('ISCDialogComponent:', this.itemObj);
        let getentityId = null;
        let queryStringParam = {};
        switch (this.rtSharedService.getEntityType()) {
            case 'Repgroup':
                getentityId = this.rtSharedService.getRepGroupID();
                queryStringParam = {};
                break;
            case 'Salesperson':
                getentityId = this.rtSharedService.getSalesPersonID();
                // queryStringParam = {repGroupID: this.itemObj.managedBy};
                queryStringParam = { repGroupID: this.rtSharedService.getRepGroupID() };
                break;
            default:
                /*This subject for B2B*/
                getentityId = this.rtSharedService.getRetailerID();
                queryStringParam = { retailerID: this.rtSharedService.getRetailerID() };
        }
        const ref = this.dialogService.open(ItemsListModalComponent, {
            header: `${this.itemObj.manufacturerID}'s Items History`,
            width: '70%',
            height: 'auto',
            styleClass: 'overflow-auto cut-dialog-height',
            data: {
                selectedISC: iscObj, selectedItem: this.itemObj, retailer: this.retailerObj.buyer ?
                    this.retailerObj.buyer : this.retailerObj, itemsHistory: true,
                entityId: getentityId, stringParam: queryStringParam
            }
        });
    }

    ngOnDestroy() {
        this.addBtnDisableSub.unsubscribe();
    }

    onclickSaveOldQty(itemObj: any) {
        console.log('mousedown', itemObj);
        this.oldQty = 0;
        this.oldQty = itemObj.quantity;
    }

    async commonQtyManageFunVariant(itemVariant, index, from?) {
        console.log('2700', itemVariant.quantity);
        const nearsetItemObj = Math.round(itemVariant.quantity / this.itemObj.quantityIncrement) * this.itemObj.quantityIncrement;
        if (itemVariant.quantity === 0) {
            itemVariant.quantity = 0;
            this.iscArr[index].quantity = 0;
            this.saveItemVariantToBasket(itemVariant);
        } else if (itemVariant.quantity > 0 && itemVariant.quantity < this.itemObj.minimumQuantity) {
            console.log('258');
            itemVariant.quantity = this.itemObj.minimumQuantity;
            this.iscArr[index].quantity = this.itemObj.minimumQuantity;
            this.saveItemVariantToBasket(itemVariant);
        } else if (itemVariant.quantity > 0 &&
            nearsetItemObj.toString() !== itemVariant.quantity.toString() &&
            itemVariant.quantity.toString() !== this.itemObj.minimumQuantity.toString()) {
            console.log('46888');
            setTimeout(() => {
                if (document.getElementById('YesButton')) {
                    document.getElementById('YesButton').focus();
                }
            }, 0);
            this.confirmationService.confirm({
                message: `This process will break the manufacturer set increment quantity for this item.
            <br><br>Click 'Yes' to accept quantity of ${nearsetItemObj}.
            <br>Click 'No' to go back.`,
                header: 'Add Cart Item',
                icon: 'pi pi-exclamation-triangle',
                key: 'breckincrement2',
                accept: async () => {
                    itemVariant.quantity = nearsetItemObj;
                    this.iscArr[index].quantity = nearsetItemObj;
                    this.saveItemVariantToBasket(itemVariant);
                },
                reject: async (type) => {
                    console.log('type', type);
                    if (type === 1) {
                    } else {
                        itemVariant.quantity = this.oldQty;
                        this.iscArr[index].quantity = this.oldQty;
                        this.saveItemVariantToBasket(itemVariant);
                    }
                },
            });
        } else {
            console.log('258');
            if (from === 'plusBtn') {
                this.oldQty = itemVariant.quantity;
            }
            itemVariant.quantity = this.oldQty;
            this.iscArr[index].quantity = this.oldQty;
            this.saveItemVariantToBasket(itemVariant);
        }
    }

    validateDateBetweenTwoDates(data) {
        const from = new Date(new RtDatePipe(this.dp).transform(data.discountStartDateString));
        const to = new Date(new RtDatePipe(this.dp).transform(data.discountEndDateString));
        const check = new Date(new RtDatePipe(this.dp).transform(moment(new Date()).format('MM-DD-YYYY')));
        return check <= to && check >= from;
    }
}
