import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'rs-order-detail-note-dialog',
    templateUrl: './order-detail-note-dialog.component.html',
    styleUrls: ['./order-detail-note-dialog.component.scss']
})
export class OrderDetailNoteDialogComponent implements OnInit {
    public detailObj: any;
    public itemNoteForm: FormGroup;

    constructor(public config: DynamicDialogConfig,
                public ref: DynamicDialogRef,
                private fb: FormBuilder) {
        this.detailObj = this.config.data.detailObj;
        console.log('this.detailObj ---> ', this.detailObj)
    }

    ngOnInit() {
        this.itemNoteForm = this.fb.group({
            notes: ['']
        });
        console.log('this.detailObj', this.detailObj)
        this.setFormFromObject();
    }

    setFormFromObject() {
        this.itemNoteForm.reset();
        this.itemNoteForm.patchValue({notes: this.detailObj.notes});
    }

    saveItemNote() {
        const detailObj = this.itemNoteForm.getRawValue();
        this.ref.close(detailObj.notes);
    }
}
