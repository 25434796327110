import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {RTSharedService} from '../../rtshared/rtshared.service';
// B2B-137 (MG 05-MAR-2020): modified to manage PO# prefix as per user
import {AuthService} from '../../middleware/auth/auth.service';

@Injectable()
export class BasketItemAPIService {

    // B2B-137 (MG 05-MAR-2020): modified to manage PO# prefix as per user
    constructor(
        private http: HttpClient,
        private rtSharedService: RTSharedService,
        private auth: AuthService,
    ) { }

    getBasketItems(queryStringParam: any) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/basketitem/all`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.get<any>(url).toPromise();
    }

    saveItemToBasket(payload) {
        payload.repGroupID = this.rtSharedService.getRepGroupID();
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/basketitem/save`;
        return this.http.post<any>(url, payload).toPromise();
    }

    updateMultipleBasketItem(queryStringParam: any, payload: any) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/basketitem/update/retailer`;
        if (queryStringParam && !_.isEmpty(queryStringParam)) {
            const params: any = new URLSearchParams();
            params.set('repGroupID', this.rtSharedService.getRepGroupID());
            for (const key in queryStringParam) {
                if (queryStringParam.hasOwnProperty(key)) {
                    queryStringParam[key] = encodeURIComponent(queryStringParam[key]);
                    params.set(key, queryStringParam[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.put<any>(url, payload).toPromise();
    }

    deleteBasketItem(IDs: any[]) {
        const url = `${this.rtSharedService.getApiUrl()}reptime/api/basketitem/delete`;
        return this.http.post<any>(url, IDs).toPromise();
    }

    // B2B-137 (MG 05-MAR-2020): modified to manage PO# prefix as per user
    checkout(payloads: any, salesPersonRecordID?) {
        payloads.repGroupID = this.rtSharedService.getRepGroupID();
        const salesPersonID = salesPersonRecordID ? salesPersonRecordID : this.rtSharedService.getSalesPersonID();
        if (salesPersonID) {
            payloads.salespersonID = salesPersonID;
        }
        payloads.sortOption = 'sequenceID';
        payloads.sortOrder = 'ASC';
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/basketitem/checkout`;
        url += '?companyID=' + this.auth.getAuthRetailer.value.loggedInRetailerID;
        return this.http.post<any>(url, payloads).toPromise();
    }

    // [B2B-115] (RM 04-03-2020): Save & Delete item from wish list
    saveItemToWishlist(repGroupID, retailerID, itemIDs, removeFlag = false) {
        itemIDs.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        let url = '';
        if (removeFlag) {
            url = `${this.rtSharedService.getApiUrl()}reptime/api/${repGroupID}/wishList/${retailerID}/save?removeFlag=${removeFlag}`;
        } else {
            url = `${this.rtSharedService.getApiUrl()}reptime/api/${repGroupID}/wishList/${retailerID}/save`;
        }
        return this.http.post<any>(url, itemIDs).toPromise();
    }
    // [B2B-115] (RM 04-03-2020): Get item from wish list
    getWishListItems(repGroupID: any, retailerID: any, payload) {
        let url = `${this.rtSharedService.getApiUrl()}reptime/api/${repGroupID}/${retailerID}/wishlist/all`;
        if (payload && !_.isEmpty(payload)) {
            const params: any = new URLSearchParams();
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) {
                    payload[key] = encodeURIComponent(payload[key]);
                    params.set(key, payload[key]);
                }
            }
            url = url + '?' + params.toString();
        }
        return this.http.get<any>(url).toPromise();
    }
}
