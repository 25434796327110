<div class="page-not-found-page h-100 py-30">
    <div class="p-grid">
        <div class="ui-g p-col-12 p-justify-center">
            <p-card styleClass="text-center">
                <h1 class="m-0 oops">OOPS!</h1>
                <h2>404 - Page not found</h2>
                <h4>
                    The page you are looking for might have been removed, had its name changed, or is temporarily
                    unavailable.
                </h4>
                <p-button type="button" label="GO BACK" icon="fa fa-fw fa-mail-reply" [routerLink]="'/'"></p-button>
            </p-card>
        </div>
    </div>
</div>
