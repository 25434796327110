import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../../services/other/data/data.service';
import {HtmlToolsService} from '../../../services/html-tools/html-tools.service';
import * as _ from 'lodash';

@Component({
  selector: 'rs-retailer-contact',
  templateUrl: './retailer-contact.component.html',
  styleUrls: ['./retailer-contact.component.scss']
})
export class RetailerContactComponent implements OnInit, OnChanges {

    @Input() isNewCustomerWizard = false;
    @Output() previousStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() selectedRetailer: any;
    @Input() retailerContact: any;
    @Input() isRetailerCreated: boolean;
    @Input() isPrimary: boolean = false;
    editRetailerContactForm: FormGroup;
    countries: any[];
    states: any[];

    constructor(private fb: FormBuilder, private dataService: DataService, private htmlTools: HtmlToolsService) {
        this.initForm();
    }

    ngOnChanges(changes: any) {
        if (changes && changes.retailerContact && !_.isEmpty(changes.retailerContact.currentValue)) {
            this.retailerContact = changes.retailerContact.currentValue;
            this.setFormFromRetailerContact();
        }
        if (changes && changes.isRetailerCreated && changes.isRetailerCreated.currentValue) {
            this.isRetailerCreated = changes.isRetailerCreated.currentValue;
            if (this.isRetailerCreated) {
                this.editRetailerContactForm.disable();
            }
        }
    }

    ngOnInit() {
        if (!this.retailerContact.email) {
            this.editRetailerContactForm.patchValue({email: this.selectedRetailer.email});
        }
    }

    initForm() {
        this.editRetailerContactForm = this.fb.group({
            firstName: ['', Validators.required],
            middleName: [''],
            lastName: [''],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            primary: [this.isPrimary],
            cell: [''],
        });
    }

    getErrorMsg(field, name) {
        if (field.errors.required) {
            return name + ' is required';
        }
        if (field.errors.pattern && field.errors.pattern.requiredPattern === '^[0-9]*$') {
            return 'Enter numeric value';
        } else if (field.errors.pattern) {
            return 'Enter valid value';
        }
        if (field.errors.email) {
            return 'Enter valid email';
        }
        if (field.errors.maxlength) {
            return 'Maximum length exceed for ' + name;
        }
    }

    get validateChk() {
        // Convenience getter for easy access to form fields
        return this.editRetailerContactForm.controls;
    }

    setFormFromRetailerContact() {
        this.editRetailerContactForm.reset();
        this.editRetailerContactForm.patchValue(this.retailerContact);
    }


    private setRetailerContactFromForm() {
        _.forEach(this.editRetailerContactForm.controls, (key, value) => {
            this.retailerContact[value] = this.editRetailerContactForm.controls[value].value;
        });
    }

    saveRetailerContactInfo() {
        if (this.editRetailerContactForm.valid || this.isRetailerCreated) {
            this.setRetailerContactFromForm();
            this.nextStepEvent.next({retailerContact: this.editRetailerContactForm.value});
        } else {
            this.htmlTools.validateAllFields(this.editRetailerContactForm);
        }
    }


}
