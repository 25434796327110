import {AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {APIResponse} from '../../models/api-response';
import {Retailer} from '../../models/retailer';
import {RetailerShipToLocation} from '../../models/retailer-ship-to-location';
import {RetailerContact} from '../../models/retailerContact';
import {RepGroupRetailer} from '../../models/rep-group-retailer';
import * as _ from 'lodash';
import * as objectPath from 'object-path';
import {MenuItem, MessageService} from 'primeng/api';
import {FormBuilder} from '@angular/forms';
import {ReptimeEntity} from '../../models/reptime-entity';
import {RetailerAPIService} from '../../services/api/retailer-api/retailer-api.service';
import {RepGroupSalespersonRetailer} from '../../models/rep-group-salesperson-retailer';
import {RTSharedService} from '../../services/rtshared/rtshared.service';

@Component({
    selector: 'rs-add-retailer',
    templateUrl: './add-retailer.component.html',
    styleUrls: ['./add-retailer.component.scss']
})
export class AddRetailerComponent implements OnInit, OnChanges {
    @Input() isOpen: boolean;
    @Input() repGroupID: string;
    @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() loadListEvent: EventEmitter<any> = new EventEmitter<any>();
    retailerOptions = [];
    isRetailerCreated: boolean;
    stepItems: MenuItem[] = [
        {label: 'Billing'},
        {label: 'Shipping'},
        {label: 'Primary Contact'},
        {label: 'Advanced'}
    ];
    activeIndex = 0;
    currentRetailer = new Retailer();
    selectedRetailer = new Retailer();
    selectedContact = new RetailerContact();
    selectedShipToLocation = new RetailerShipToLocation();
    selectedRepGroupRetailer = new RepGroupRetailer();
    selectedRepGroupSalespersonRetailer = new RepGroupSalespersonRetailer();
    addressArray = ['address1', 'address2', 'city', 'country', 'zip'];
    retailerID: string;

    constructor(private retailerService: RetailerAPIService,
                private rtSharedService: RTSharedService,
                private fb: FormBuilder, private msgService: MessageService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: any) {
        if (changes && changes.isOpen && changes.isOpen.currentValue !== changes.isOpen.previousValue) {
            this.activeIndex = 0;
            this.resetRetailerValues();
            setTimeout(() => {
                if (document.querySelector('.ui-dialog-content')) {
                    document.querySelector('.ui-dialog-content').scrollTop = 0;
                }
            }, 100);
        }
    }

    resetRetailerValues() {
        this.isRetailerCreated = false;
        this.selectedRetailer = new Retailer();
        this.currentRetailer = new Retailer();
        this.selectedShipToLocation = new RetailerShipToLocation();
        this.selectedContact = new RetailerContact();
        this.selectedRepGroupRetailer = new RepGroupRetailer();
        this.selectedRepGroupSalespersonRetailer = new RepGroupSalespersonRetailer();
    }


    searchRetailer(event) {
        if (event && event.query) {
            this.retailerService.getRepGroupRetailerByIdNameAndEmail(event.query, this.repGroupID)
                .subscribe((r: any) => {
                    const re = new Retailer();
                    re.name = event.query;
                    if (r && r.length) {
                        this.retailerOptions = r.map(d => {
                            d.fullAddress = this.getRetailerFullAddress(d);
                            return d;
                        });
                        this.retailerOptions.push(re);
                    } else {
                        this.retailerOptions = [re];
                    }
                }, () => {
                    const re = new Retailer();
                    re.name = event.query;
                    this.retailerOptions = [re];
                });
        }
    }

    getRetailerFullAddress(retailer) {
        let address = '';
        this.addressArray.forEach(field => {
            if (retailer[field]) {
                address = address + retailer[field] + ', ';
            }
        });
        return address.slice(0, -2);
    }

    onRetailerSelect(event: any) {
        if (!event.recordID) {
            this.selectedRetailer = event;
            this.currentRetailer = this.selectedRetailer;
        } else {
            this.selectedRetailer = new Retailer();
            this.currentRetailer = this.selectedRetailer;
        }
    }

    saveRetailer() {
        this.retailerService.saveRetailer(this.selectedRetailer).subscribe((data: Retailer) => {
            this.selectedRetailer.recordID = data.recordID;
            this.retailerID = this.currentRetailer.recordID;
            this.createRepgroupRetailerObject(this.selectedRetailer);
            this.saveRepGroupRetailer(true);
            this.saveRetailerShipTo();
            this.saveRetailerContact();
            this.msgService.clear();
            this.msgService.add({severity: 'success', summary: 'Success', detail: 'Customer Info Saved'});
            this.saveEvent.emit(data);
            this.loadListEvent.emit(data);
        });
    }

    createRepgroupRetailerObject(retailer) {
        this.selectedRepGroupRetailer.retailerID = retailer.recordID;
        this.selectedRepGroupRetailer.name = retailer.name;
        this.selectedRepGroupRetailer.active = true;
        this.selectedRepGroupRetailer.retailerStatus = this.selectedRetailer['retailerStatus'];
    }

    saveRetailerContact() {
        this.selectedContact.isPrimary = true;
        this.retailerService.saveRetailerContact(this.selectedContact, this.selectedRetailer.recordID)
            .subscribe((result: RetailerContact) => {
                if (result) {
                    this.isRetailerCreated = true;
                    this.msgService.clear();
                    this.msgService.add({severity: 'success', summary: 'Success', detail: 'Contact Saved'});
                }
            });
    }

    saveRetailerShipTo() {
        this.selectedShipToLocation.retailerID = this.selectedRetailer.recordID;
        this.retailerService.saveRetailerShipTo(this.selectedShipToLocation.retailerID, this.selectedShipToLocation)
            .subscribe((result: RetailerShipToLocation) => {
                if (result) {
                    this.selectedShipToLocation.recordID = result.recordID;
                    this.msgService.add({severity: 'success', summary: 'Success', detail: 'Ship To Location Saved'});
                }
            });
    }

    callAutoAssign() {
        this.retailerService.autoAssigned(this.repGroupID, this.selectedRetailer.recordID, this.selectedShipToLocation.recordID)
            .subscribe((response: APIResponse) => {
                if (response.success && response.data && response.data.recordID && response.data.name) {
                    this.msgService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Retailer is assigned to ' + response.data.name + '[' + response.data.recordID + ']'
                    });
                } else {
                    this.msgService.add({
                        severity: 'warn',
                        summary: 'Warning',
                        detail: 'Unable to assign retailer. Please contact rep group admin'
                    });
                }
            }, () => {
                this.msgService.add({severity: 'error', summary: 'Error', detail: 'Failed To Save Assignment'});
            });
    }


    onNextStep(event) {
        // Next
        if (this.activeIndex < this.stepItems.length - 1) {
            if (this.activeIndex === this.stepItems.length - 4) {
                // Save Billing
                if (event && event.retailer) {
                    this.selectedRetailer = event.retailer;
                    this.currentRetailer = event.selectedRetailer;
                    this.activeIndex++;
                }
            } else if (this.activeIndex === this.stepItems.length - 3) {
                // Save Shipping
                if (event && event.retailerShipTo) {
                    this.selectedShipToLocation = event.retailerShipTo;
                    this.activeIndex++;
                }
            } else if (this.activeIndex === this.stepItems.length - 2) {
                // Save Contact
                if (event && event.retailerContact) {
                    this.selectedContact = event.retailerContact;
                    if (!this.isRetailerCreated) {
                        this.saveRetailer();
                    }
                    this.activeIndex++;
                }
            } else {
                this.activeIndex++;
            }
            // Finish
        } else if (this.activeIndex === this.stepItems.length - 1) {
            this.selectedRepGroupSalespersonRetailer = event.selectedRepGroupSalespersonRetailer;
            this.selectedRepGroupRetailer.retailerCategory = event.selectedRepGroupRetailer.
            retailerCategory ? event.selectedRepGroupRetailer.retailerCategory : undefined;
            this.selectedRepGroupRetailer.repGroupDivision = event.selectedRepGroupRetailer.
            repGroupDivision ? event.selectedRepGroupRetailer.repGroupDivision : undefined;
            this.saveRepGroupRetailer(false);
            if (!this.isSalesPerson()) {
                this.saveAssignment();
            } else if (this.isSalesPerson()) {
                this.callAutoAssign();
            }
            this.closeRetailerWizard();
        }

    }

    isSalesPerson() {
        return this.rtSharedService.getEntityType() === 'Salesperson';
    }

    saveAssignment() {
        if (!_.isEmpty(this.selectedRepGroupSalespersonRetailer)) {
            const sales = _.map(this.selectedRepGroupSalespersonRetailer, (salesperson: any) => {
                return {
                    salespersonID: salesperson.salesPersonID,
                    retailerShipToLocationID: this.selectedShipToLocation.recordID,
                    salespersonType: salesperson.salespersonType.value,
                    recordDeleted: false,
                    retailerID: this.selectedRetailer.recordID
                };
            });
            this.retailerService.saveAssignmentList(sales)
                .then((response: APIResponse) => {
                    if (response.success) {
                        this.msgService.add({severity: 'success', summary: 'Success', detail: 'Assignment Saved'});
                    } else {
                        this.msgService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: objectPath.get(response, 'error.message', 'Failed To Save Assignment')
                        });
                    }
                }, () => {
                    this.msgService.add({severity: 'error', summary: 'Error', detail: 'Failed To Save Assignment'});
                });
        }
    }

    onPreviousStep() {
        if (this.activeIndex >= 1) {
            this.activeIndex--;
        }
    }

    closeRetailerWizard() {
        this.activeIndex = 0;
        this.isOpenChange.emit(false);
        this.resetRetailerValues();
    }

    private saveRepGroupRetailer(manageBy) {
        if (manageBy) {
            this.selectedRepGroupRetailer['managedBy'] = true;
        }
        const repGroupID = this.rtSharedService.getRepGroupID();
        this.retailerService.saveRepGroupRetailer(this.selectedRepGroupRetailer, this.selectedRepGroupRetailer.retailerID, repGroupID)
            .subscribe((data: RepGroupRetailer) => {
                this.selectedRepGroupRetailer.recordID = data.recordID;
                this.msgService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Repgroup Customer Info Saved Successfully'
                });
                this.loadListEvent.emit(data);
            });
    }


}
