import { Injectable } from '@angular/core';
import { RTSharedService } from '../../rtshared/rtshared.service';
import { BasketItemAPIService } from '../../api/basket-item-api/basket-item-api.service';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class BasketItemService {
    private cartEvent = new Subject<any>();
    private cartClickEvent = new Subject<any>();
    private itemSearchEvent = new Subject<any>();
    private cartQtyUpdateEvent = new Subject<any>();
    private wishListClickEvent = new Subject<any>();
    private wishListDataEvent = new Subject<any>();
    private wishListItems: any;

    constructor(private basketItemAPIService: BasketItemAPIService,
        private rtSharedService: RTSharedService) {
    }

    setCartEvent(retailer: any) {
        this.cartEvent.next(retailer);
    }

    getCartEvent(): Observable<any> {
        return this.cartEvent.asObservable();
    }

    setCartClickEvent(clickFlag) {
        this.cartClickEvent.next(clickFlag);
    }

    getCartClickEvent(): Observable<any> {
        return this.cartClickEvent.asObservable();
    }

    setItemSearchEvent(data) {
        this.itemSearchEvent.next(data);
    }

    getItemSearchEvent(): Observable<any> {
        return this.itemSearchEvent.asObservable();
    }

    setCartQtyUpdateEvent(data) {
        this.cartQtyUpdateEvent.next(data);
    }

    getCartQtyUpdateEvent(): Observable<any> {
        return this.cartQtyUpdateEvent.asObservable();
    }

    async saveItemToBasket(item: any, retailer: any) {
        const newBasketItem: any = {};
        if (!item.item) {
            newBasketItem.itemID = item.recordID;
            const datediscontinue = [new Date(item.discountStartDate).getTime(), new Date(item.discountEndDate).getTime()];
            const date = new Date().getTime();
            if (datediscontinue[0] <= date && datediscontinue[1] >= date &&
                (item.itemDiscount === undefined || item.itemDiscount === null || item.itemDiscount === 0)) {
                item.itemDiscount = item.discountPercent ? item.discountPercent : item.itemDiscount;
            }
        } else {
            newBasketItem.itemID = item.item.recordID;
            newBasketItem.recordID = item.recordID;
        }
        newBasketItem.b2bPriceCode = item.b2bPriceCode ? item.b2bPriceCode : null;
        newBasketItem.manufacturerID = item.manufacturerID;
        newBasketItem.quantity = item.quantity ? item.quantity : (item.minimumQuantity ? item.minimumQuantity : 1);
        newBasketItem.unitPrice = item.item ? item.item.unitPrice : item.unitPrice;
        newBasketItem.itemDiscount = item.itemDiscount ? item.itemDiscount : 0;
        newBasketItem.priceCode = item.selectedPriceCode;
        newBasketItem.retailerID = retailer.recordID;
        // B2B-148 (MJ 13-03-2020 condition Added) for Ability to switch shipping location from dashboard
        const getSelectedShipTo = this.rtSharedService.getSelectedShipTo();
        newBasketItem.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
        // END
        // newBasketItem.shipDate = Date.now(); TODO: remove
        try {
            await this.basketItemAPIService.saveItemToBasket(newBasketItem);
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async getBasketItemData(retailerObj: any, sort?) {
        const queryParamObj: any = {};
        if (retailerObj.selectedShipTo && retailerObj.selectedShipTo.recordID) {
            // B2B-148 (MJ 12-03-2020 condition Added) for Ability to switch shipping location from dashboard
            const sessionRetailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID'));
            queryParamObj.retailerShipToLocationID = sessionRetailerShipToLocationID.recordID;
            if (!sessionRetailerShipToLocationID) {
                sessionStorage.setItem('retailerShipToLocationID', JSON.stringify(retailerObj.selectedShipTo));
                queryParamObj.retailerShipToLocationID = retailerObj.selectedShipTo.recordID;
            }

            if (sort && sort.length !== 0) {
                queryParamObj.sortOption = sort.sort;
                queryParamObj.sortOrder = sort.order;
            }

            // END
            queryParamObj.retailerID = retailerObj.recordID;
            if (!queryParamObj.repGroupID) {
                queryParamObj.repGroupID = await this.rtSharedService.getRepGroupID();
            }
            try {
                const res = await this.basketItemAPIService.getBasketItems(queryParamObj);
                return Promise.resolve(res.data);
            } catch (e) {
                return Promise.reject(e);
            }
        }
    }

    async deleteBasketItemItem(IDs: any[]) {
        return await this.basketItemAPIService.deleteBasketItem(IDs);
    }

    async updateMultipleBasketItem(retailerObj: any, payload: any) {
        const queryParamObj: any = {};
        if (retailerObj.selectedShipTo && retailerObj.selectedShipTo.recordID) {
            // B2B-148 (MJ 12-03-2020 condition Added) for Ability to switch shipping location from dashboard
            queryParamObj.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
            // END
            queryParamObj.retailerID = retailerObj.recordID;
            try {
                await this.basketItemAPIService.updateMultipleBasketItem(queryParamObj, payload);
                return Promise.resolve();
            } catch (e) {
                return Promise.reject(e);
            }
        }
    }

    async checkoutBasketItem(retailerObj: any, selectedItems?) {
        const payloads: any = {};
        let salespersonID: string;
        console.log('Here >>', retailerObj, selectedItems);
        if (retailerObj.buyer && retailerObj.buyer.recordID) {
            payloads.retailerContactID = retailerObj.buyer.recordID;
        }
        if (retailerObj.selectedShipTo && retailerObj.selectedShipTo.recordID) {
            // B2B-148 (MJ 12-03-2020 condition Added) for Ability to switch shipping location from dashboard

            payloads.retailerShipToLocationID = JSON.parse(sessionStorage.getItem('retailerShipToLocationID')).recordID;
            // END
            payloads.retailerID = retailerObj.recordID;

            payloads.companyID = this.rtSharedService.getRetailerID();
            payloads.systemDateString = moment(new Date()).format('YYYY-MM-DD'); // RC-971 (RK 08 Aug 2020): Passing system date
            payloads.origin = 'B2B';
            payloads.basketItemIDs = selectedItems;
            payloads.splitOrder = true;
            if (retailerObj.selectedShipTo.salesperson) {
                salespersonID = retailerObj.selectedShipTo.salesperson.recordID;
            }
            try {
                const checkout = await this.basketItemAPIService.checkout(payloads, salespersonID);
                return Promise.resolve(checkout.data);
            } catch (e) {
                return Promise.reject(e);
            }
        }
    }

    // [B2B-115] (RM 04-03-2020): Save & Delete item from wish list
    async saveItemToWishList(item: any, removeFlag = false) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const retailerID = this.rtSharedService.getRetailerID();
        const itemIDs = [];
        if (item.recordID) {
            itemIDs.push(item.recordID);
            try {
                const res = await this.basketItemAPIService.saveItemToWishlist(repGroupID, retailerID, itemIDs, removeFlag);
                this.setWishListClickEvent(res);
                return Promise.resolve();
            } catch (e) {
                return Promise.reject(e);
            }
        } else {
            return Promise.reject();
        }
    }

    // [B2B-271] (SG 24-06-2020): Remove all items from wish list
    async saveItemToAllWishList(item: any, removeFlag = false) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const retailerID = this.rtSharedService.getRetailerID();
        const itemIDs = [];
        item.forEach((d: any) => {
            itemIDs.push(d.recordID);
        });
        if (itemIDs.length) {
            try {
                const res = await this.basketItemAPIService.saveItemToWishlist(repGroupID, retailerID, itemIDs, removeFlag);
                this.setWishListClickEvent(res);
                return Promise.resolve();
            } catch (e) {
                return Promise.reject(e);
            }
        } else {
            return Promise.reject();
        }
    }


    // [B2B-115] (RM 04-03-2020): get all item from wish list
    async getWishListData(payload?) {
        const repGroupID = this.rtSharedService.getRepGroupID();
        const retailerID = this.rtSharedService.getRetailerID();
        if (retailerID) {
            try {
                const res = await this.basketItemAPIService.getWishListItems(repGroupID, retailerID, payload);
                this.wishListItems = res.data;
                this.wishListDataEvent.next(res.data);
                return Promise.resolve(res.data);
            } catch (e) {
                return Promise.reject(e);
            }
        }
    }

    getWishListDataEvent(): Observable<any> {
        return this.wishListDataEvent.asObservable();
    }

    // [B2B-115] (RM 06-03-2020): wish list events
    setWishListClickEvent(data) {
        this.wishListClickEvent.next(data);
    }

    getWishListClickEvent(): Observable<any> {
        return this.wishListClickEvent.asObservable();
    }

    getWishListItems() {
        return this.wishListItems;
    }
}
