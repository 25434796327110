import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from 'src/app/shared/shared.module';
import {FinalizeOrderComponent} from './finalize-order.component';
import { DynamicDialogConfig } from 'primeng/api';
import { RtCustomConfirmDialogModule } from '../rt-custom-confirmdialog/confirmdialog';
import { PrintPreviewDialogModule } from '../../../modals/print-preview-dialog/print-preview-dialog.module';
import { InputSwitchModule } from 'primeng/inputswitch';
const routes = [{path: '', component: FinalizeOrderComponent}];
@NgModule({
    declarations: [FinalizeOrderComponent],
    imports: [
        CommonModule,
        SharedModule,
        InputSwitchModule,
        RouterModule.forChild(routes),
        RtCustomConfirmDialogModule,
        PrintPreviewDialogModule
    ],
    providers: [
        // {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
        // InterceptorService,
        // DatePipe,
        DynamicDialogConfig
    ],
    exports: [FinalizeOrderComponent],
})
export class FinalizeOrderModule { }
